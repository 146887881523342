import React from 'react';
import { StyleProps } from '@uss/react-components/lib/components/NotFound/NotFoundSVG';
import { format } from 'date-fns';
import CalendarIcon from 'components/svgs/my-pto/CalendarIconSVG';

interface SellBackPeriodEndProps extends StyleProps {
  startDate: Date;
  endDate: Date;
}

const SellBackPeriodEnd = ({
  startDate,
  endDate,
  width,
  height,
}: SellBackPeriodEndProps) => {
  return (
    <div
      className="uss-flex uss-flex-col uss-items-center p-8"
      data-testid="sellback-end-msg"
    >
      <div data-testid="calendar-icon">
        <CalendarIcon width={width} height={height} />
      </div>
      <p className="uss-text-sm uss-text-gray-700 pt-6 text-center lg:text-left">
        {`Election period was
        ${format(startDate, 'LLL d, yyyy')}
        through   ${format(endDate, 'LLL d, yyyy')} at ${format(
          endDate,
          'h:m aaa'
        )} CT`}
      </p>
    </div>
  );
};

export default SellBackPeriodEnd;
