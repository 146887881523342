import NoResults from 'components/NoResults/NoResults';
import MonthCard from '../Card/DayCard';
import { CalendarBlock } from '../types';

interface MonthViewProps {
  blocks: CalendarBlock[];
  renderItem: (item: CalendarBlock) => React.ReactNode;
  handleClick?: (date: Date) => void;
}
export const MonthView = ({
  blocks,
  renderItem,
  handleClick,
}: MonthViewProps) => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  return (
    <div>
      {blocks.length ? (
        <div className="grid gap-2 grid-cols-7">
          {days.map((day, _index) => (
            <div
              key={_index}
              className="bg-white h-8 sm:p-2 2xl:p-4 flex flex-wrap text-base justify-center"
            >
              <span className="hidden sm:inline-block">{day}</span>
              <span className="sm:hidden inline-block font-semibold">
                {day.charAt(0)}
              </span>
            </div>
          ))}
          {blocks.map((item, index) => (
            <MonthCard
              key={index}
              calendardata={item}
              {...(handleClick ? { handleClick: handleClick } : {})}
              as={handleClick ? 'button' : 'div'}
            >
              <>{renderItem(item)}</>
            </MonthCard>
          ))}
        </div>
      ) : (
        <NoResults
          title="PTO Detail"
          description="No records available"
          width="300"
          height="300"
        />
      )}
    </div>
  );
};
