import { Button, Input } from '@uss/react-components';
import useModal from 'components/Modals/use-modal';
import OrgFilters from 'components/OrgFilters/OrgFilters';
import { CrewSchema } from 'features/crew-maintenance/schemas';
import { useAddCrewStore } from 'features/crew-maintenance/state/addcrewStore';
import { Org, OrgList } from 'features/org';
import useValidateForm from 'hooks/useValidateForm';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ZodErrorType } from 'types/common';
import _ from 'lodash';
import { ConfirmationModal } from './ConfirmationModal';
import { QueryStatus } from '@tanstack/react-query';
import {
  CrewButtonRef,
  CREW_BUTTON_REF,
} from 'features/crew-maintenance/constants';
import Alert from 'components/Alert/Alert';
import useNotification from 'components/Notification/useNotification';
import useUserRoles from 'hooks/useUserRoles';
import displayButton from 'features/crew-maintenance/utilities/displayButton';
import { ROLES } from 'features/roles';

interface AddCrewProps {
  plants: OrgList | undefined;
  plantStatus: QueryStatus;
  orgsStatus: QueryStatus;
  orgs: OrgList | undefined;
  crew?: Org | null;
  crewId: string;
  approvedTimeCardExists?: boolean;
}
export const AddCrew = ({
  crew,
  plants,
  plantStatus,
  orgsStatus,
  orgs,
  crewId,
  approvedTimeCardExists,
}: AddCrewProps) => {
  const modal = useModal();

  const navigate = useNavigate();
  const store = useAddCrewStore();
  const notify = useNotification();
  const [crewDescription, setCrewDescription] = useState<string>(
    crew?.crewDescription ?? ''
  );
  const [selectedCrew, setSelectedCrew] = useState<string>(crew?.crew ?? '');
  const [department, setDepartment] = useState<Org | null>(null);
  const { roles, uss_id } = useUserRoles();

  const {
    approveButton,
    archiveButton,
    cancelButton,
    denyButton,
    restoreButton,
    saveButton,
  } = displayButton({
    crew,
    roles,
  });

  useEffect(() => {
    if (crew) {
      setDepartment(
        orgs?.items.filter(
          (e) =>
            e.type === 'department' &&
            e.department === crew.department &&
            e.plantCode === crew.plantCode
        )[0] ?? null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crew, orgs?.items]);

  const selectedPlant = store.selectedPlant
    ? store.selectedPlant
    : plants?.items.filter((e) => e.plant === crew?.plant)[0];
  const selectedLocation = store.selectedPayrollLocation
    ? store.selectedPayrollLocation
    : orgs?.items.filter(
        (e) =>
          e.type === 'location' &&
          e.location === crew?.location &&
          e.plantCode === crew.plantCode
      )[0];
  const selectedDivision = store.selectedDivision
    ? store.selectedDivision
    : orgs?.items.filter(
        (e) =>
          e.type === 'division' &&
          e.division === crew?.division &&
          e.plantCode === crew.plantCode
      )[0];

  const selectedDepartment = store.selectedDepartment
    ? store.selectedDepartment
    : department;
  const handleDivision = (value: Org) => {
    store.setDivision(value);
    setDepartment(null);
  };

  const handleCrewDescription = (value: string) => {
    setCrewDescription(value);
    store.setCrewDescription(value);
  };
  const handleCrew = (value: string) => {
    const alphaNumericValue = value.replace(/[^a-zA-Z0-9]/g, '');

    const truncatedValue = alphaNumericValue.slice(0, 6);
    setSelectedCrew(truncatedValue);

    store.setCrew(truncatedValue);
  };
  const validateField = (crew: string): boolean => {
    return (
      !_.isEmpty(errors) &&
      typeof errors[crew] === 'string' &&
      errors[crew].length > 0
    );
  };

  const handleCancel = () => {
    navigate('/admin/crew-maintenance/crew');
    store.clearSearch();
  };

  const [errors, setErrors] = useState<ZodErrorType>({});
  const { validateForm, trimValues } = useValidateForm(CrewSchema, setErrors);

  const getModalTitle = (buttonRef: CrewButtonRef) => {
    if (buttonRef === 'Save') return 'Save Changes?';
    else return `${buttonRef}  Crew`;
  };
  const checkCrewMember = (buttonRef: CrewButtonRef) => {
    if (buttonRef === CREW_BUTTON_REF.ARCHIVE) {
      if (crew?.childOrgsCount !== 0) {
        notify.danger({
          message: 'All employees must be removed before archiving Crew.',
        });
        return false;
      } else return true;
    } else return true;
  };
  const handleConfirmation = (buttonRef: CrewButtonRef) => {
    const payloadData = {
      plant: store.selectedPlant?.id ? store.selectedPlant.id : crew?.plant,
      payrollLocation: store.selectedPayrollLocation?.id
        ? store.selectedPayrollLocation.id
        : crew?.location,
      division: store.selectedDivision?.id
        ? store.selectedDivision.id
        : crew?.division,
      department: store.selectedDepartment?.id
        ? store.selectedDepartment.id
        : department?.id,
      crew: store.crew ? store.crew : selectedCrew,
      crewDescription: store.crewDescription
        ? store.crewDescription
        : crewDescription,
    };

    if (
      (checkCrewMember(buttonRef) && validateForm(trimValues(payloadData))) ||
      buttonRef === 'Restore'
    ) {
      modal.openModal({
        title: getModalTitle(buttonRef),
        type: 'regular',
        children: (
          <ConfirmationModal
            handleCancel={handleCancel}
            closeModal={modal.closeModal}
            crew={crew}
            buttonRef={buttonRef}
          />
        ),
      });
    }
  };
  const isDisabled = () => {
    if (
      roles.includes(ROLES.PAY_APPROVER) &&
      uss_id !== crew?.createdBy &&
      crewId
    )
      return true;
    else
      return crew?.status.code === 'denied'
        ? true
        : crew?.status.code === 'pending'
        ? false
        : crew?.status.code === 'archived'
        ? true
        : approvedTimeCardExists;
  };
  return (
    <>
      <div className="bg-white mt-1 sm:px-36 pl-4 py-4 px-6 justify-center rounded-md ">
        {approvedTimeCardExists && (
          <div className=" w-fit text-xs">
            <Alert variant={'info'}>
              Only Crew Description can be modified because one or more Approved
              timecards exist.
            </Alert>
          </div>
        )}

        <div className="w-full lg:m-auto sm:m-auto">
          <OrgFilters
            plants={plantStatus === 'success' && plants ? plants.items : []}
            orgs={orgs?.items ? orgs.items : []}
            selectedOrgs={{
              selectedPlant: selectedPlant,
              selectedPayrollLocation: selectedLocation,
              selectedDivision: store.selectedDivision,
              selectedDepartment: store.selectedDepartment,
            }}
            status={orgsStatus}
            plantStatus={plantStatus}
            itemsPerRow={1}
          >
            <div className=" grid sm:grid-cols-2 gap-4 sm:gap-x-4 sm:gap-y-4 w-full grid-cols-1">
              <OrgFilters.Plant
                selectedItem={selectedPlant ?? null}
                handleSelect={store.setPlant}
                showLabel={true}
                showSelectAll={false}
                disabled={isDisabled()}
              />
              <OrgFilters.Location
                selectedItem={selectedLocation ?? null}
                handleSelect={store.setPayrollLocation}
                showLabel={true}
                showSelectAll={false}
                disabled={isDisabled()}
              />
              <OrgFilters.Division
                selectedItem={selectedDivision ?? null}
                handleSelect={handleDivision}
                showLabel={true}
                showSelectAll={false}
                disabled={isDisabled()}
              />
              <OrgFilters.Department
                selectedItem={selectedDepartment ?? null}
                handleSelect={store.setDepartment}
                showLabel={true}
                showSelectAll={false}
                disabled={isDisabled()}
              />
              <Input
                id="form_input_crew"
                label="Crew"
                name="crew"
                type="text"
                placeholder="Enter"
                value={selectedCrew}
                onChange={(e) => handleCrew(e.target.value)}
                disabled={isDisabled()}
                error={validateField('crew')}
                maxLength={6}
                errorMessage={errors.crew}
              />
              <Input
                id="form_input_crew_description"
                label="Crew Description"
                name="crew_description"
                type="text"
                placeholder="Enter"
                value={crewDescription}
                onChange={(e) => handleCrewDescription(e.target.value)}
                disabled={
                  crew?.status.code === 'denied' ||
                  crew?.status.code === 'archived'
                }
              />
            </div>
          </OrgFilters>
          <div className="text-red-400 text-lg pt-2">
            {!_.isEmpty(errors)
              ? 'Please populate all six fields before Adding'
              : ''}
          </div>
        </div>
      </div>

      <div className="flex justify-end flex-wrap mt-4">
        {(!crewId || cancelButton) && (
          <>
            <Button
              onClick={handleCancel}
              variant="outline"
              className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
              type="button"
              disabled={false}
            >
              Cancel
            </Button>
          </>
        )}
        {restoreButton && (
          <>
            <Button
              onClick={() => handleConfirmation(CREW_BUTTON_REF.RESTORE)}
              variant="primary"
              className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
              type="button"
            >
              Restore
            </Button>
          </>
        )}
        {!crewId && (
          <>
            <Button
              onClick={() =>
                handleConfirmation(
                  roles.includes(ROLES.PAY_APPROVER)
                    ? CREW_BUTTON_REF.REQUEST
                    : CREW_BUTTON_REF.ADD
                )
              }
              variant="primary"
              className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
              type="submit"
            >
              {roles.includes(ROLES.PAY_APPROVER) ? 'Request' : 'Add'}
            </Button>
          </>
        )}

        {archiveButton && (
          <>
            <Button
              onClick={() => handleConfirmation(CREW_BUTTON_REF.ARCHIVE)}
              variant="outline"
              className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
              type="submit"
            >
              Archive
            </Button>
          </>
        )}
        {saveButton && (
          <>
            <Button
              onClick={() => handleConfirmation(CREW_BUTTON_REF.SAVE)}
              variant="primary"
              className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
              type="submit"
              disabled={false}
            >
              Save
            </Button>
          </>
        )}

        {denyButton && (
          <>
            <Button
              onClick={() => handleConfirmation(CREW_BUTTON_REF.DENY)}
              variant="outline"
              className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
              type="submit"
            >
              Deny
            </Button>
          </>
        )}
        {approveButton && (
          <>
            <Button
              onClick={() => handleConfirmation(CREW_BUTTON_REF.APPROVE)}
              variant="primary"
              className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
              type="submit"
            >
              Approve
            </Button>
          </>
        )}
      </div>
    </>
  );
};
