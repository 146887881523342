import { parseISO } from 'date-fns';
import { Holiday } from 'features/holidays';
import { CalendarHoliday } from 'components/CalendarV2/types';

//create events object for calendar
export const usePtoCalendarHolidays = (holidays: Holiday[]) => {
  const holidayList: CalendarHoliday[] = [];
  //create holiday list
  holidays.forEach((holiday) => {
    holidayList.push({
      date: new Date(parseISO(holiday.date)),
      text: holiday.description,
    });
    return true;
  });

  return holidayList;
};
