/**
 * @description (Save, Save & Approve) Cannot have more than 24 hours on a turn
 * @param value Object containing the Hours and the turn for the day
 * @returns boolean value to determine if the validation is
 */
import { TimecardEfforts } from 'features/timecards/types';

export const totalTurnHours = (value: TimecardEfforts) => {
  if (value.hours === null) return true;
  if (value.hours <= 24) return true;

  return false;
};
