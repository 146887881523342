import { useQuery } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { SortBy } from 'types/common';
import { OrgMemberList } from 'features/org';

interface GetOrgMembersOptions {
  role?: string;
  sortBy?: 'firstName' | 'lastName' | 'userId' | 'ussId';
  sortType?: SortBy;
  pageSize?: number;
  page?: number;
}

const getOrgMembers = <T = GetOrgMembersOptions>(
  orgId: string,
  options?: T
): Promise<OrgMemberList> => {
  return axios.get(`/v1/orgs/${orgId}/members`, { params: options });
};

interface UseOrgMembersProps {
  orgId: string;
  options?: GetOrgMembersOptions;
}

export const useOrgMembers = ({ orgId, options }: UseOrgMembersProps) => {
  return useQuery({
    queryFn: () => getOrgMembers(orgId, options),
    queryKey: ['org-members', orgId],
    enabled: orgId !== '',
  });
};
