import React from 'react';

interface TitleProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  > {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const Title = ({ as: Component = 'h1', children, ...props }: TitleProps) => {
  return (
    <Component
      {...props}
      className="text-gray-700 text-lg font-semibold text-left line-clamp-1"
    >
      {children}
    </Component>
  );
};

export default Title;
