import { CalendarEventColor } from 'components/CalendarV2/types';
import { PtoStatus } from '../types';
import { PTO_STATUS } from '../constants';

//create badge color according to status
export const getColors = (value: PtoStatus): CalendarEventColor => {
  switch (value) {
    case PTO_STATUS.ADJUSTMENT: {
      return 'yellow';
    }
    case PTO_STATUS.APPROVED: {
      return 'green';
    }
    case PTO_STATUS.COMPANY_BLACKOUT: {
      return 'gray';
    }
    case PTO_STATUS.REQUESTED: {
      return 'purple';
    }
    case PTO_STATUS.DENIED: {
      return 'red';
    }
    case PTO_STATUS.TAKEN: {
      return 'blue';
    }
    default:
      return 'gray';
  }
};
