import { useQueries, useQuery } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { SortDirection } from 'types/common';
import { HolidayList, HolidayType } from '../types';
import { parse } from 'date-fns';

type SortByType = 'type' | 'year' | 'country';

interface HolidayListParams {
  year?: string;
  country?: string;
  sortBy?: SortByType;
  sortType?: SortDirection;
  page?: string;
  pageSize?: string;
}

const get_holidays = (
  holidayType: HolidayType,
  params?: HolidayListParams
): Promise<HolidayList> => {
  return axios.get(`/v1/holidays/${holidayType}`, { params });
};

export const useHolidays = (
  holidayType: HolidayType,
  params?: HolidayListParams
) => {
  return useQuery({
    queryFn: () => get_holidays(holidayType, params),
    queryKey: ['holidays', { ...params, holidayType }],
    gcTime: 3000000,
    enabled: !!holidayType,
  });
};

// holiday hook to fetch a variable number of queries
export const useHoliday = (
  holidayType: HolidayType[],
  params?: HolidayListParams
) => {
  return useQueries({
    queries: holidayType.map((id) => ({
      queryFn: () => get_holidays(id, params),
      queryKey: ['holidays', { ...params, id }],
    })),
    combine: (results) => {
      return {
        data: results.flatMap((result) => result.data),
        pending: results.some((result) => result.isPending),
      };
    },
  });
};

export const useHolidayDates = (
  holidayType: HolidayType,
  params?: HolidayListParams
) => {
  return useQuery({
    queryFn: () => get_holidays(holidayType, params),
    queryKey: ['holiday-dates', { ...params, holidayType }],
    gcTime: 3000000,
    select: (element) => {
      return element.items.map((item) =>
        parse(item.date, 'yyyy-MM-dd', new Date())
      );
    },
    enabled: !!holidayType,
  });
};
