import { ROLES } from 'features/roles/types';
import { PaycodeStatus } from '../types';
import { PAYCODE_STATUS } from '../constants';

export const paycodeFormVisible = (
  paycodeId: string,
  roles: string[],
  isPaycodeCreator: boolean,
  statusCode: PaycodeStatus
) => {
  const isSuperAdmin =
    roles.includes(ROLES.SUPER_PAYROLL_ADMIN) ||
    roles.includes(ROLES.SUPER_ADMIN);
  //disable actions from paycode status (TA & PA)
  const isRequestRole =
    roles.includes(ROLES.PAYROLL_ADMIN) ||
    roles.includes(ROLES.T_A_PLANT_COORDINATOR);

  //check when show view only form
  const canViewPaycode = () => {
    //check status is rejected or archived
    if (
      statusCode !== PAYCODE_STATUS.DENIED &&
      statusCode !== PAYCODE_STATUS.ARCHIVED
    ) {
      //check if user has request role (TA & PA) and user is not creator of paycode
      return isRequestRole && !isPaycodeCreator ? true : false;
    } else {
      return true;
    }
  };

  //paycode actions
  //in case of new paycode creation form should be always editable
  const canEditForm = paycodeId === '' || !canViewPaycode();
  //save button will visible for active status and new paycode creation or paycode creator
  const canSave =
    (statusCode === PAYCODE_STATUS.ACTIVE && !canViewPaycode()) ||
    paycodeId === '' ||
    (isPaycodeCreator && !canViewPaycode());
  //approve button will be visible for pending status
  const canApprove =
    (statusCode === PAYCODE_STATUS.PENDING &&
      !canViewPaycode() &&
      !isPaycodeCreator) ||
    (statusCode === PAYCODE_STATUS.DENIED && isSuperAdmin);

  //reject button will be visible for pending status
  const canReject =
    statusCode === PAYCODE_STATUS.PENDING &&
    !canViewPaycode() &&
    !isPaycodeCreator;

  //   All users cannot edit the code and pay code type for pay codes in active status
  //   Super Payroll Admin and Payroll Admin roles can edit the payroll type for pay codes in pending status
  //   Payroll Admin and T&A Coordinator can edit the payroll type for pay codes in pending status if they requested the pay code
  //   Payroll Admin and T&A Coordinator cannot edit the payroll type or any field in pending status if they DID NOT request the pay code

  const enableCodeType = () => {
    if (
      paycodeId === '' ||
      (isSuperAdmin && statusCode === PAYCODE_STATUS.PENDING) ||
      isPaycodeCreator
    ) {
      return true;
    } else {
      return false;
    }
  };

  return {
    canEditForm: canEditForm,
    canSave: canSave,
    canApprove: canApprove,
    canReject: canReject,
    enableCodeType: enableCodeType(),
  };
};
