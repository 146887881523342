import Page from 'components/Layouts/Page';
import { NavLink, useParams } from 'react-router-dom';
import Tabs from 'components/Tabs/Tabs';
import { SelectedPaycodeCard } from 'features/paycode';
import Details from 'features/paycode/components/Tabs/Details';
import DetailsSkeleton from 'components/Skeleton/DetailsSkeleton';
import { usePaycodeById } from 'features/paycode/api/getPaycodeById';
import { History, HISTORY_ENTITY } from 'features/history';
const PaycodeDetails = () => {
  const { id = '' } = useParams<'id'>();
  const { data, status } = usePaycodeById(id);

  const tabLinks = [
    {
      label: 'Details',
      element: <Details />,
    },
    {
      label: 'History',
      element: (
        <History
          id={id}
          entity={HISTORY_ENTITY.PAY_CODES}
          noRecordError="No history records found for the selected pay code"
        />
      ),
    },
  ];

  return (
    <Page
      breadcrumbs={[
        <NavLink key="bc-1" to="/admin">
          Admin
        </NavLink>,
        <NavLink key="bc-2" to="/admin/paycodes">
          Pay Code Maintenance
        </NavLink>,
      ]}
      heading="Pay Code Details"
    >
      <Page.Section>
        {status === 'pending' && <DetailsSkeleton />}
        {status === 'success' && (
          <>
            <SelectedPaycodeCard data={data} />
          </>
        )}
      </Page.Section>
      <Page.Section>
        <Tabs tabLinks={tabLinks} />
      </Page.Section>
    </Page>
  );
};

export default PaycodeDetails;
