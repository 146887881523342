import { useQuery } from '@tanstack/react-query';
import { HistoryItemList, HistoryOptions } from '../types';
import { axios } from 'services/api/axios';

/**
 *
 * @param entity name of the entity
 * @returns historylist of the entity
 *
 * @description /api/v1/history/{entity}
 */

const getEntityHistory = (
  options: HistoryOptions
): Promise<HistoryItemList> => {
  return axios.get(`/v1/history/${options.entity}`, { params: options });
};

/**
 * Hook that can fetch history for given entity
 *
 * @param entity name of the entity you are fetching history for
 *
 * @example
 *
 * // Fetches history for the entity type 'crew'
 * const {data, status} = useEntityHistory('crew')
 *
 * // Fetches history  for the entity type 'employee-timecard'
 * const {data, status} = useEntityHistory('employee-timecard')
 *
 */
export const useEntityHistory = (options: HistoryOptions) => {
  return useQuery({
    queryFn: () => getEntityHistory(options),
    queryKey: [`${options.entity}-history`, { ...options }],
  });
};
