/**
 * Creates a new empty timecard
 *
 * @param ussId USS ID of the employee
 * @param crewId Crew ID of the crew you want to create the timecard for
 * @param week Start date of the week
 * @returns React Query useMutation result
 * @link https://cappsq.psc.uss.com/SBWM-DOCUMENT/swagger-ui/index.html?urls.primaryName=sbwm-schedulepay#/schedule-pay-controller/saveEmployeeTimecard
 */

import useNotification from 'components/Notification/useNotification';
import { useMutation } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { queryClient } from 'services/api/react-query';
import { EmployeeTimecard, TimecardPayload } from 'features/timecards/types';

/**
 *
 * @param ussId USS ID of the employee
 * @param crewId Crew ID of the crew you wish to create the timecard for
 * @param week Start date of the week
 * @returns Promise
 */
const create_timecard = (
  ussId: string,
  crewId: string,
  week: string,
  timecard: TimecardPayload[]
): Promise<EmployeeTimecard> => {
  return axios.post(`/v1/people/${ussId}/timecard-details`, {
    crewId,
    week,
    timecard: timecard,
  });
};

export const useCreateTimecard = (
  ussId: string,
  crewId: string,
  week: string
) => {
  const notify = useNotification();
  return useMutation({
    mutationFn: (timecard: TimecardPayload[]) =>
      create_timecard(ussId, crewId, week, timecard),

    onSuccess: async () => {
      notify.success({
        message: 'You have successfully created the timecard.',
      });
      await queryClient.invalidateQueries({
        queryKey: ['employee-timecard'],
        type: 'active',
      });
      await queryClient.resetQueries({
        queryKey: ['employee-timecard'],
        type: 'active',
      });
      await queryClient.refetchQueries({
        queryKey: ['pto-summary'],
        type: 'active',
      });
      queryClient.removeQueries({
        queryKey: [`${crewId}-crew-timecards`],
      });
    },
  });
};
