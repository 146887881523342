import { PAY_PERIOD_STATUS, PayPeriodStatus } from 'features/pay-periods/types';

const getFormattedPayPeriodStatus = (payPeriodStatus: PayPeriodStatus) => {
  switch (payPeriodStatus) {
    case PAY_PERIOD_STATUS.NOT_OPEN:
      return 'Not Open';
    case PAY_PERIOD_STATUS.LOCKED:
      return 'Locked';
    case PAY_PERIOD_STATUS.PROCESSED:
      return 'Processed';
    case PAY_PERIOD_STATUS.OPEN:
      return 'Open';
    case PAY_PERIOD_STATUS.CLOSED:
      return 'Closed';
    case PAY_PERIOD_STATUS.IN_PROGRESS:
      return 'In Progress';
  }
};
export default getFormattedPayPeriodStatus;
