import { Accordion, Avatar } from '@uss/react-components';
import LabeledText from 'components/Typography/LabeledText';
import SeparatedLabels from 'components/SeparatedLabels/SeparatedLabels';
import Title from 'components/Typography/Title';
import useWindowSize from 'hooks/useWindowSize';
import getFormattedShiftAssignment from 'utils/getFormattedShiftAssignment';
import { getInitials } from 'utils';
import { Person } from 'features/people/types';

type DetailscardPerson = Pick<
  Person,
  | 'ussId'
  | 'firstName'
  | 'lastName'
  | 'empClass'
  | 'displayName'
  | 'empClassDesc'
  | 'unionInd'
  | 'corpServDate'
  | 'plantServDate'
  | 'jobTitle'
  | 'shiftAssignment'
>;

const Detailscard = ({ employeeData }: { employeeData: DetailscardPerson }) => {
  const { width } = useWindowSize();
  const DetailsHeader = () => {
    const empClass =
      employeeData.empClass && employeeData.empClassDesc
        ? `${employeeData.empClass}-${employeeData.empClassDesc}`
        : '---';

    const displayName = employeeData.displayName
      ? employeeData.displayName
      : 'No Display Name';
    return (
      <div className="flex items-center relative h-full w-full lg:w-2/5 2xl:w-1/4 lg:pr-8 lg:after:w-0.5 lg:after:absolute lg:after:right-0 lg:after:h-full lg:after:bg-gray-200">
        <div className="flex items-center flex-grow ">
          <>
            <span>
              <Avatar color={2} size="medium">
                {getInitials(employeeData.firstName, employeeData.lastName)}
              </Avatar>
            </span>
            <div className="ml-4 flex-grow">
              <Title as="h2" aria-label="Employee Name">
                {displayName}
              </Title>
              <SeparatedLabels
                items={[
                  {
                    label: 'Employee Type',
                    value:
                      employeeData.unionInd === 'y' ? 'Union' : 'Non Union',
                  },
                  {
                    label: 'USS ID',
                    value: employeeData.ussId,
                  },
                  {
                    label: 'Employee Class',
                    value: empClass,
                  },
                ]}
              />
            </div>
          </>
        </div>
      </div>
    );
  };
  const DetailsBody = () => {
    return (
      <>
        <div className="flex flex-wrap sm:flex-nowrap w-full lg:w-3/5 2xl:w-3/4 lg:pl-8">
          <LabeledText
            className="w-1/2 sm:w-auto sm:flex-grow pr-0.5"
            label="Corp Svc Date"
          >
            {employeeData.corpServDate}
          </LabeledText>
          <LabeledText
            className="w-1/2 sm:w-auto sm:flex-grow pr-0.5"
            label="Plant Svc Date"
          >
            {employeeData.plantServDate}
          </LabeledText>
          <LabeledText
            className="w-1/2 sm:w-auto sm:flex-grow pr-0.5"
            label="Job Title"
          >
            {employeeData.jobTitle}
          </LabeledText>
          <LabeledText
            className="w-1/2 sm:w-auto sm:flex-grow pr-0.5"
            label="Shift Assignment"
          >
            {employeeData.shiftAssignment
              ? getFormattedShiftAssignment(employeeData.shiftAssignment)
              : ''}
          </LabeledText>
        </div>
      </>
    );
  };
  return (
    <>
      {width &&
        (width < 1024 ? (
          <Accordion
            variant="default"
            className="shadow-md bg-white rounded-lg border border-blue-600"
          >
            <Accordion.Button variant="default">
              <DetailsHeader />
            </Accordion.Button>
            <Accordion.Panel variant="default">
              <div className="flex flex-row flex-wrap w-full  items-center border-t border-gray-200 pt-4">
                <DetailsBody />
              </div>
            </Accordion.Panel>
          </Accordion>
        ) : (
          <div className="flex items-center shadow-md w-full p-4 bg-white rounded-lg h-24 border border-blue-600">
            <DetailsHeader />
            <DetailsBody />
          </div>
        ))}
    </>
  );
};

export default Detailscard;
