import {
  Button,
  DropdownMenu,
  Drawer,
  TextArea,
  //Modal,
} from '@uss/react-components';
import React, { useState, useRef, memo } from 'react';
import { format, parseISO, parse } from 'date-fns';
import {
  BsChatLeft,
  BsThreeDotsVertical,
  BsListNested,
  BsTrash,
  BsInfoCircle,
  BsPlus,
  BsPencil,
} from 'react-icons/bs';
import useWindowSize from 'hooks/useWindowSize';
import { Timecard, EmployeeTimecardList } from 'features/timecards/types';
import { useCreateTimecard } from 'features/timecards/api/createTimecard';
import { useUpdateEmployeeTimecard } from 'features/timecards/api/updateTimecard';
import { NavLink, useParams } from 'react-router-dom';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import AddNoteDrawerLoader from '../Loaders/AddNoteDrawerLoader';
import { RequestPTODrawer } from './RequestPTODrawer';
import { Person } from 'features/people/types';
import { formatCrewKey } from 'utils/formatCrewKey';
import { PLANT_TYPE, SHIFT_ASSIGNMENT } from 'features/paycode';
import useModal from 'components/Modals/use-modal';
import { useDeletePtoRequest } from 'features/paid-time-off/api/mutations';

interface TimecardCommentProps {
  timecardInstance: Timecard[];
  rowData: Timecard;
  employeeTimecardObj: EmployeeTimecardList;
  handleClearShift: () => void;
  date: string;
  isMoreOptionsEnabled: boolean;
  handleCommentUpdate: (comment: string) => void;
  employee: Person;
  isPtoPaycodeSelected: boolean;
}

const TimecardComment = ({
  timecardInstance,
  rowData,
  employeeTimecardObj,
  handleClearShift,
  isMoreOptionsEnabled,
  date,
  handleCommentUpdate,
  employee,
  isPtoPaycodeSelected,
}: TimecardCommentProps) => {
  const { width } = useWindowSize();
  const [openDrawer, setOpenDrawer] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [openPTODrawer, setOpenPTODrawer] = useState(false);
  // Getting id from the URL
  const { ussId: id = '' } = useParams<'ussId'>();
  // Getting the crewId and Week out of the state
  const crew = useNonUnionStore((state) => state.crew);
  const week = useNonUnionStore((state) => state.params.week);

  //Getting current screen feature out of the state
  const feature = useNonUnionStore((state) => state.feature);

  const formattedWeek = week
    ? format(parse(week, 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd').toString()
    : '';

  const createTimecard = useCreateTimecard(
    id,
    crew ? crew.id : '',
    formattedWeek
  );
  const updateEmployeeTimecard = useUpdateEmployeeTimecard(
    id,
    employeeTimecardObj.items[0].id
  );
  let isDevice = false;

  if (width && width > 768) {
    isDevice = true;
  }
  const onClose = () => {
    setOpenPTODrawer(false);
    setOpenDrawer(false);
  };

  const handleAddEditNote = async (mode: string) => {
    const timecardPayLoad: Timecard[] = timecardInstance.filter((data) => {
      return data.id !== 'temp';
    });
    setOpenDrawer(true);
    if (employeeTimecardObj.items[0].id !== 'temp') {
      await updateEmployeeTimecard.mutateAsync({ timecard: timecardPayLoad });
    } else {
      await createTimecard.mutateAsync(timecardPayLoad);
    }
  };
  const isUpdatingTimecard =
    createTimecard.isPending || updateEmployeeTimecard.isPending;

  //checking if employee primaryCrew is equals to selected crew from filter
  const isPrimaryCrewValid =
    employee.primaryCrew?.toString() ===
    (crew &&
      formatCrewKey(crew.location, crew.division, crew.department, crew.crew));

  //Check if User is - Integrated & Office (Plant Type) & Exempt (Emp Class 4)
  const isExemptEmployee =
    (employee.plantType === PLANT_TYPE.INTEGRATED_EXEMPT ||
      employee.plantType === PLANT_TYPE.OFFICE) &&
    employee.empClass === '4' &&
    (employee.shiftAssignment === SHIFT_ASSIGNMENT.EIGHT_HOUR ||
      employee.shiftAssignment === SHIFT_ASSIGNMENT.TWELVE_HOUR);

  // Check if User is - Mini Mill (Plant Type) & Part-time (Emp Class 0),
  // Check if User is - Integrated & Office (Plant Type) & Part-time (Emp Class 0),
  const isPartTimeEmployee =
    (employee.plantType === PLANT_TYPE.MINI_MILL ||
      employee.plantType === PLANT_TYPE.INTEGRATED_EXEMPT ||
      employee.plantType === PLANT_TYPE.OFFICE) &&
    employee.empClass === '0';

  const { mutateAsync: removePtoRequest } = useDeletePtoRequest(
    rowData.ptoDetails ? rowData.ptoDetails.pkWfmEmpPtoDetailsId : '',
    employee.ussId.toString()
  );

  const confirm = async () => {
    if (isPtoPaycodeSelected) handleClearShift();
    modal.closeModal();
    await removePtoRequest();
  };

  const modal = useModal();

  const openModal = ({
    title,
    message,
    type,
  }: {
    title: string;
    message: string;
    type: 'danger';
  }) => {
    modal.openModal({
      title: title,
      type: type,
      children: <span>{message}</span>,
      labels: {
        confirm: 'Remove',
        cancel: 'Cancel',
      },
      confirmVariant: 'danger',
      onCancel: () => modal.closeModal(),
      onConfirm: () => confirm(),
    });
  };

  const ShowButton = () => {
    return (
      <>
        <span>
          {feature === 'my-schedule'
            ? `PTO must be requested within the timecard for the employee's primary crew ${
                employee.primaryCrew ?? ''
              }.`
            : `PTO must be added within the timecard for the employee's primary crew ${
                employee.primaryCrew ?? ''
              }.`}
        </span>
        <div className="flex justify-end">
          <Button
            onClick={() => modal.closeModal()}
            variant={'primary'}
            className="ml-0 sm:ml-4 w-full sm:w-auto mt-3 sm:mt-0 "
            data-testid="modal-confirm"
          >
            {'Okay'}
          </Button>
        </div>
      </>
    );
  };

  const handleRemove = () => {
    openModal({
      title: 'Remove PTO?',
      message: `Are you sure you want to Remove PTO for
        ${format(parseISO(rowData.date), 'MMMM dd, yyyy')}?`,
      type: 'danger',
    });
  };

  const handlePTO = () => {
    const title = feature === 'my-schedule' ? 'Request PTO' : 'Add PTO';
    const type = 'regular';
    modal.openModal({
      title: title,
      type: type,
      children: <ShowButton />,
    });
  };

  const fnClearShift = () => {
    //PTO is already present for the user on this day
    if (rowData.ptoDetails !== null && isPtoPaycodeSelected) {
      //Ask before removing pto and call remove-pto API
      handleRemove();
    } else {
      //Clear row data locally
      handleClearShift();
    }
  };

  return (
    <>
      {rowData.comments && (
        <Button
          variant="icon"
          startIcon={
            <BsChatLeft
              className="text-primary-400"
              size="1rem"
              aria-label="Comments"
            />
          }
          onClick={() => {
            setOpenDrawer(true);
          }}
        ></Button>
      )}
      {isMoreOptionsEnabled ? (
        <DropdownMenu>
          <DropdownMenu.Button>
            <BsThreeDotsVertical
              size="1rem"
              aria-label="More Options"
              className="text-primary-400"
            />
          </DropdownMenu.Button>
          <DropdownMenu.Items
            renderAs="ul"
            className="w-40 h-58 pt-3 pb-2 shadow-md"
          >
            {!isExemptEmployee && !isPartTimeEmployee && (
              <>
                <DropdownMenu.Item
                  renderAs="li"
                  key="Add PTO"
                  className="text-gray-700 text-sm flex item-center"
                >
                  <Button
                    variant="icon"
                    className="text-sm text-primary-400 "
                    startIcon={<BsPlus className="px-1" size="2rem" />}
                    onClick={(e) => {
                      isPrimaryCrewValid ? setOpenPTODrawer(true) : handlePTO();
                    }}
                    disabled={
                      isPtoPaycodeSelected || rowData.ptoDetails !== null
                    }
                  >
                    {feature === 'my-schedule' ? 'Request PTO' : 'Add PTO'}
                  </Button>
                </DropdownMenu.Item>

                <DropdownMenu.Item
                  renderAs="li"
                  key="Edit PTO"
                  className="text-gray-700 text-sm flex item-center"
                >
                  <Button
                    variant="icon"
                    className="text-sm text-primary-400 "
                    startIcon={<BsPencil className="px-2" size="2rem" />}
                    onClick={(e) => {
                      setOpenPTODrawer(true);
                    }}
                    disabled={
                      !isPtoPaycodeSelected || rowData.ptoDetails === null
                    }
                  >
                    Edit PTO
                  </Button>
                </DropdownMenu.Item>

                <DropdownMenu.Item
                  renderAs="li"
                  key="View PTO"
                  className="text-gray-700 text-sm flex item-center"
                >
                  <NavLink
                    to={
                      feature === 'my-schedule'
                        ? `/my-pto-nu/${
                            rowData.ptoDetails
                              ? rowData.ptoDetails.pkWfmEmpPtoDetailsId
                              : ''
                          }`
                        : `/paid-time-off/employees/${employee.ussId}/${
                            rowData.ptoDetails
                              ? rowData.ptoDetails.pkWfmEmpPtoDetailsId
                              : ''
                          }`
                    }
                    className={`flex w-full focus-visible:outline-dotted ${
                      !isPtoPaycodeSelected || rowData.ptoDetails === null
                        ? 'pointer-events-none cursor-not-allowed text-gray-500'
                        : 'text-primary-400 cursor-pointer'
                    }`}
                  >
                    <span className="flex items-center px-2 mr-2">
                      <BsInfoCircle size="1rem" />
                    </span>
                    <span className="flex items-center">View</span>
                  </NavLink>
                </DropdownMenu.Item>

                <DropdownMenu.Item
                  renderAs="li"
                  key="Remove PTO"
                  className="text-gray-700 text-sm flex item-center"
                >
                  <Button
                    variant="icon"
                    className="text-sm text-red-500"
                    startIcon={<BsTrash className="px-2" size="2rem" />}
                    onClick={(e) => {
                      handleRemove();
                    }}
                    disabled={!isPtoPaycodeSelected}
                  >
                    Remove PTO
                  </Button>
                </DropdownMenu.Item>
                <hr className="mx-1.5 my-1" />
              </>
            )}

            <DropdownMenu.Item
              renderAs="li"
              key="Clear Shift"
              className="text-gray-700 text-sm flex item-center"
            >
              <Button
                variant="icon"
                className="text-sm"
                startIcon={
                  <BsListNested className="text-primary-400 px-2" size="2rem" />
                }
                onClick={fnClearShift}
              >
                Clear Shift
              </Button>
            </DropdownMenu.Item>
            <DropdownMenu.Item
              renderAs="li"
              key={rowData.comments ? 'Edit Note' : 'Add Note'}
              className="text-gray-700 text-sm flex item-center"
            >
              <Button
                variant="icon"
                className="text-sm"
                startIcon={
                  <BsChatLeft className="text-primary-400 px-2" size="2rem" />
                }
                onClick={(e) => {
                  setOpenDrawer(true);
                }}
              >
                {rowData.comments ? 'Edit Note' : 'Add Note'}
              </Button>
            </DropdownMenu.Item>
          </DropdownMenu.Items>
        </DropdownMenu>
      ) : (
        <span>
          <Button
            variant="icon"
            iconLabel="Add Note"
            className="text-primary-400"
            disabled
          >
            <BsThreeDotsVertical size="1rem" aria-label="More Options" />
          </Button>
        </span>
      )}
      <Drawer
        placement={isDevice ? 'right' : 'bottom'}
        title={rowData.comments ? 'Edit Note' : 'Add Note'}
        isOpen={openDrawer}
        onClose={onClose}
        closeButton={isDevice ? false : true}
        className="max-w-sm md:top-0 top-18 md:rounded-t-none rounded-t-md"
      >
        {isUpdatingTimecard ? (
          <AddNoteDrawerLoader />
        ) : (
          <div data-testid="drawer-content">
            <div className="mb-4">
              <div className="grid grid-cols-2 gap-4">
                <div
                  className="flex flex-col justify-start"
                  data-testid="selected-row-date"
                >
                  <span className="text-xs text-gray-600 ">Date</span>
                  <span className="text-sm w-3/4">
                    <span> {format(parseISO(date), 'MMM d, E')} </span>
                  </span>
                </div>
                <div
                  className="flex flex-col justify-start"
                  data-testid="selected-row-elapsedTime"
                >
                  <span className="text-xs text-gray-600 ">Elapsed Time</span>
                  <span className="text-sm w-3/4">
                    <span>
                      {rowData.elapsedTime ? rowData.elapsedTime : '-'}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="grid grid-cols-2 gap-4">
                <div
                  className="flex flex-col justify-start"
                  data-testid="selected-row-scheduledTurn"
                >
                  <span className="text-xs text-gray-600 ">Scheduled Turn</span>
                  <span className="text-sm w-3/4">
                    <span>
                      {rowData.scheduled.turn ? rowData.scheduled.turn : '-'}
                    </span>
                  </span>
                </div>
                <div
                  className="flex flex-col justify-start"
                  data-testid="selected-row-scheduledHours"
                >
                  <span className="text-xs text-gray-600 ">
                    Scheduled Hours
                  </span>
                  <span className="text-sm w-3/4">
                    <span>
                      {rowData.scheduled.hours ? rowData.scheduled.hours : '-'}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="grid grid-cols-2 gap-4">
                <div
                  className="flex flex-col justify-start"
                  data-testid="selected-row-workedTurn"
                >
                  <span className="text-xs text-gray-600 ">Worked Turn</span>
                  <span className="text-sm w-3/4">
                    <span>
                      {rowData.worked.turn ? rowData.worked.turn : '-'}
                    </span>
                  </span>
                </div>
                <div
                  className="flex flex-col justify-start"
                  data-testid="selected-row-workedHours"
                >
                  <span className="text-xs text-gray-600 ">Worked Hours</span>
                  <span className="text-sm w-3/4">
                    <span>
                      {rowData.worked.hours ? rowData.worked.hours : '-'}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <span>
                <TextArea
                  ref={inputRef}
                  label="Notes"
                  id="1"
                  type="text"
                  rows={3}
                  value={rowData.comments ?? ''}
                  onChange={(e): void => {
                    if (isMoreOptionsEnabled) {
                      handleCommentUpdate(e.target.value);
                    }
                  }}
                  disabled={!isMoreOptionsEnabled}
                />
              </span>
            </div>
          </div>
        )}
        <Drawer.Controls>
          <Button
            className="md:mb-0 mb-2 w-full"
            variant="outline"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="md:ml-4 w-full"
            disabled={
              !openDrawer || !isMoreOptionsEnabled || isUpdatingTimecard
            }
            onClick={async () => {
              await handleAddEditNote('add-mode');
            }}
          >
            {rowData.comments ? 'Save' : 'Add'}
          </Button>
        </Drawer.Controls>
      </Drawer>

      <RequestPTODrawer
        rowData={rowData}
        openPTODrawer={openPTODrawer}
        onClose={onClose}
        setOpenPTODrawer={setOpenPTODrawer}
        employee={employee}
      />
    </>
  );
};

export default memo(TimecardComment);
