import React from 'react';
import ComingSoon from 'views/ComingSoon';
import {
  Detailscard,
  CrewDetailsContextProvider,
} from 'features/crew-maintenance';
import Assignments from './assignments';
import PayApprovers from './payApprovers';
import { NavLink, useParams } from 'react-router-dom';
import { useOrgDetails } from 'features/org/api';
import DetailsSkeleton from 'components/Skeleton/DetailsSkeleton';
import Page from 'components/Layouts/Page';
import { History, HISTORY_ENTITY } from 'features/history';
import { Details } from './details';
import Tabs from 'components/Tabs/Tabs';

const CrewDetails = () => {
  const { crewId = '' } = useParams<'crewId'>();
  const { data, status } = useOrgDetails(crewId);

  const tabLinks = [
    {
      label: 'Assigned Employees',
      element: <Assignments />,
    },
    {
      label: 'Details',
      element: <Details crew={data} crewId={crewId} />,
    },
    {
      label: 'Pay Approvers',
      element: <PayApprovers />,
    },
    {
      label: 'Scheduler',
      element: <ComingSoon />,
    },
    {
      label: 'Safety',
      element: <ComingSoon />,
    },
    {
      label: 'History',
      element: (
        <History
          id={crewId}
          entity={HISTORY_ENTITY.CREW_BY_CREW}
          noRecordError="No history records found for the selected crew"
        />
      ),
    },
  ];

  return (
    <Page
      heading={'Crew Details'}
      breadcrumbs={[
        <NavLink key="bc-1" to="/admin">
          Admin
        </NavLink>,
        <NavLink key="bc-2" to="/admin/crew-maintenance/crew">
          Crew Maintenance
        </NavLink>,
      ]}
    >
      <Page.Section>
        {status === 'pending' && <DetailsSkeleton />}
        {status === 'success' && <Detailscard crewData={data} />}
      </Page.Section>
      <Page.Section>
        <CrewDetailsContextProvider>
          <div className="mt-4">
            {status === 'success' && (
              <Tabs
                tabLinks={tabLinks}
                selectedIndexNumber={data.status.code !== 'active' ? 1 : 0}
              />
            )}
          </div>
        </CrewDetailsContextProvider>
      </Page.Section>
    </Page>
  );
};

export default CrewDetails;
