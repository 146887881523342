import { OrgStatusCode } from 'features/org';

export const getOrgStatusText = (status: OrgStatusCode) => {
  switch (status) {
    case 'active': {
      return 'Active';
    }
    case 'pending': {
      return 'Pending';
    }
    case 'archived': {
      return 'Archived';
    }
    case 'rejected': {
      return 'Rejected';
    }
    case 'denied': {
      return 'Denied';
    }
  }
};
