import React, { useId } from 'react';
import { Select } from '@uss/react-components';

import { Org } from 'features/org/types';
import { formatCrewKeyFromCode } from 'utils/formatCrewKey';
import { QueryStatus } from '@tanstack/react-query';

type OrgTypes =
  | 'plant'
  | 'payroll_location'
  | 'division'
  | 'department'
  | 'crew';

interface Props {
  items: Org[];
  selectedItem: Org | 'all' | null;
  status: QueryStatus;
  handleSelect: ((value: Org | 'all' | null) => void) | ((value: Org) => void);
  type: OrgTypes;
  displayCrewKey?: boolean;
  disabled?: boolean;
  showSelectAll?: boolean;
  showLabel?: boolean;
}

const OrgSelect = ({
  items,
  status,
  handleSelect,
  type,
  selectedItem,
  displayCrewKey = false,
  showSelectAll = false,
  disabled = false,
  showLabel = false,
}: Props) => {
  const selectId = useId();
  function typeLabel(type: OrgTypes) {
    switch (type) {
      case 'plant':
        return showSelectAll ? 'All Plants' : 'Select Plant';
      case 'payroll_location':
        return showSelectAll
          ? 'All Payroll Locations'
          : 'Select Payroll Location';
      case 'division':
        return showSelectAll ? 'All Divisions' : 'Select Division';
      case 'department':
        return showSelectAll ? 'All Departments' : 'Select Department';
      case 'crew':
        return showSelectAll ? 'All Crews' : 'Select Crew';
      default:
        return showSelectAll ? 'All Orgs' : 'Select Org';
    }
  }

  function noRecordText(type: OrgTypes) {
    switch (type) {
      case 'plant':
        return 'No Plants Available';
      case 'payroll_location':
        return 'No Payroll Location Available';
      case 'division':
        return 'No Division Available';
      case 'department':
        return 'No Department Available';
      case 'crew':
        return 'No Crew Available';
      default:
        return 'No Org Available';
    }
  }

  function optionLabel(item: Org, type: OrgTypes) {
    if (type === 'crew') {
      if (!!displayCrewKey) return formatCrewKeyFromCode(item.code);
      return (
        (item.name ? item.name : '') +
        ' - ' +
        (item.description ? item.description : '')
      );
    }
    if (type === 'plant') {
      return item.description ? item.description : '';
    }

    return item.code + ' - ' + (item.description ? item.description : '');
  }
  function labelText(type: OrgTypes) {
    switch (type) {
      case 'plant':
        return 'Plant';
      case 'payroll_location':
        return 'Payroll Location';
      case 'division':
        return 'Division';
      case 'department':
        return 'Department';
      case 'crew':
        return 'Crew';
      default:
        return 'Org';
    }
  }
  return (
    <div>
      <>
        <label
          htmlFor={selectId}
          className={`text-xs text-gray-700 mt-1 ${showLabel ? '' : 'sr-only'}`}
        >
          {labelText(type)}
        </label>
        <Select
          selected={selectedItem}
          onSelect={handleSelect}
          className="w-full"
          disabled={status !== 'success' || items.length === 0 || disabled}
          id={selectId}
          data-testid={`${type}-filter`}
        >
          <Select.Button
            label={
              status === 'pending'
                ? 'Loading'
                : status === 'error' || items.length === 0
                ? noRecordText(type)
                : selectedItem !== 'all' &&
                  selectedItem &&
                  selectedItem.description
                ? optionLabel(selectedItem, type)
                : typeLabel(type)
            }
          />
          <Select.Options>
            {showSelectAll && items.length > 0 && (
              <Select.Option value="all">{typeLabel(type)}</Select.Option>
            )}
            {status === 'success' && items.length ? (
              items.map((item, index) => (
                <Select.Option truncate key={index} value={item}>
                  <span>{optionLabel(item, type)}</span>
                </Select.Option>
              ))
            ) : (
              <span className="mx-4 py-2">No Options available</span>
            )}
          </Select.Options>
        </Select>
      </>
    </div>
  );
};

export default OrgSelect;
