import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { create } from 'zustand';
import { SwipeTimesParams } from '../types/swipeTimes';

interface SwipeTimeState {
  params: SwipeTimesParams;
  setPage: (value: string) => void;
  setPageSize: (value: string) => void;
}

export const useSwipeTimeStore = create<SwipeTimeState>()(
  devtools(
    immer((set) => ({
      params: {
        page: '1',
        pageSize: '9',
      },

      setPage: (value) =>
        set(
          (state) => {
            state.params.page = value;
          },
          false,
          'set-page'
        ),

      setPageSize: (value) =>
        set(
          (state) => {
            state.params.pageSize = value;
            state.params.page = '1';
          },
          false,
          'set-pageSize'
        ),
    }))
  )
);
