import { format } from 'date-fns';
import { useEmployeePtoSummary } from 'features/paid-time-off/api/queries';
import { Person } from 'features/people';

export const RemainingPTO = ({
  date,
  personDetails,
}: {
  date: string;
  personDetails: Person;
}) => {
  const { data: ptoSummary } = useEmployeePtoSummary(
    personDetails.ussId.toString(),
    {
      year: format(new Date(date), 'yyyy'),
    }
  );

  const unitOfMeasure: string =
    ptoSummary?.ptoClass.unitOfMeasure === 'days' ? ' Days' : ' Hours';
  return (
    <>
      <div className="text-sm text-gray-700 font-normal ">Remaining PTO</div>
      <div className="flex justify-between border-b border-gray-300 pb-4">
        <div className="text-xs flex-col mt-1">
          <div className="text-gray-700 font-normal">Vacation</div>
          <div className="text-sm font-medium">
            {ptoSummary?.ptoClass.unitOfMeasure === 'days'
              ? ptoSummary.remaining.toString() + ' Days'
              : ptoSummary && ptoSummary.remaining.toString() + ' Hours'}
          </div>
        </div>
        {personDetails.plantType !== 'mini_mill' && (
          <div className="text-xs flex-col">
            <div className="text-gray-700 font-normal">
              Personal{' '}
              {ptoSummary?.ptoClass.unitOfMeasure === 'days' ? 'Day' : 'Hour'}
            </div>
            <div className="text-sm font-medium">
              {ptoSummary &&
                ptoSummary.personal.remaining.toString() + unitOfMeasure}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
