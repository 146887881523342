import {
  CopyParamAction,
  CopyParamActions,
  CopyScheduleDifferentEmpPayload,
} from 'features/timecards/types';

export const copyParams = (
  state: CopyScheduleDifferentEmpPayload,
  action: CopyParamAction
): CopyScheduleDifferentEmpPayload => {
  switch (action.type) {
    case CopyParamActions.FROM_START_WEEK: {
      state.source.firstWeek = action.payload;
      return { ...state };
    }
    case CopyParamActions.FROM_END_WEEK: {
      if (action.payload.length > 0) {
        state.source.lastWeek = action.payload;
      }
      return { ...state };
    }
    case CopyParamActions.TO_START_WEEK: {
      state.target.firstWeek = action.payload;
      return { ...state };
    }
    case CopyParamActions.TO_END_WEEK: {
      state.target.lastWeek = action.payload;
      return { ...state };
    }
    case CopyParamActions.DELETE_FROM_END_WEEK: {
      delete state.source.lastWeek;
      delete state.target.lastWeek;
      return { ...state };
    }

    case CopyParamActions.DELETE_TO_END_WEEK: {
      delete state.target.lastWeek;
      return { ...state };
    }

    case CopyParamActions.DELETE_FROM_START_WEEK: {
      state.source.firstWeek = '';
      return { ...state };
    }
    case CopyParamActions.DELETE_TO_START_WEEK: {
      delete state.target.firstWeek;
      return { ...state };
    }
  }
};
