const LOCAL_HOST = 'localhost';
const DEV_HOST = 'cappsd.psc.uss.com';
const QA_HOST = 'cappsq.psc.uss.com';

export function getApplicationStartDate() {
  const host = window.location.hostname;
  if (host.includes(LOCAL_HOST) || host === DEV_HOST || host === QA_HOST) {
    return '2021-10-30';
  } else {
    return '2021-12-25';
  }
}
