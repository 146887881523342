import clsx from 'clsx';
import { Org, ORG_TYPE, OrgType } from 'features/org';

interface OrgInfoCardProps {
  data: Org | undefined;
  type: OrgType;
}
export const OrgInfoCard = ({ data, type }: OrgInfoCardProps) => {
  return (
    <div
      data-testid="info-card"
      className="flex items-center shadow-md w-full p-4 bg-white rounded-lg md:h-24 border border-blue-600"
    >
      <div className="flex flex-wrap flex-grow justify-between md:justify-start  sm:flex-nowrap w-full">
        <div className="w-5/12 md:w-3/12 flex items-center">
          <span>
            <p className="text-gray-600 text-sm text-left">Location</p>
            <p className="text-gray-900 text-base md:text-xl text-left font-semibold line-clamp-1">
              {data?.location} - {data?.locationDescription}
            </p>
          </span>
        </div>
        {(type === ORG_TYPE.DEPARTMENT || type === ORG_TYPE.CREW) && (
          <div
            className={clsx(
              'w-5/12 flex items-center',
              type === ORG_TYPE.DEPARTMENT
                ? 'md:w-2/5'
                : 'md:w-1/5 mb-4 lg:mb-0'
            )}
          >
            <span>
              <p className="text-gray-600 text-sm text-left">Division</p>
              <p className="text-gray-900 text-base md:text-xl text-left font-semibold line-clamp-1">
                {data?.division} - {data?.divisionDescription}
              </p>
            </span>
          </div>
        )}
        {type === ORG_TYPE.CREW && (
          <div className={clsx('w-5/12 flex items-center md:w-2/6')}>
            <span>
              <p className="text-gray-600 text-sm text-left">Department</p>
              <p className="text-gray-900 text-base md:text-xl text-left font-semibold line-clamp-1">
                {data?.department} - {data?.departmentDescription}
              </p>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
