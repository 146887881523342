import { SearchInput } from '@uss/react-components';
import React from 'react';
import { PEOPLE_SORT, PeopleSort } from 'features/people/types';
import Sort from 'components/Sort/Sort';
import { SortDirection } from 'types/common';
import { useCrewMaintenanceEmployeeStore } from 'features/crew-maintenance/state/store';

export const SearchSortFilter = () => {
  const { setSort, sortBy, sortType, setSearch, search, clearSearch } =
    useCrewMaintenanceEmployeeStore((state) => ({
      setSort: state.setSort,
      sortBy: state.params.sortBy,
      sortType: state.params.sortType,
      setSearch: state.setSearch,
      search: state.params.search,
      clearSearch: state.clearSearch,
    }));

  const sortOptions: {
    label: string;
    name: PeopleSort;
    direction: SortDirection;
  }[] = [
    {
      label: 'Recently Updated',
      name: PEOPLE_SORT.RECENTLYUPDATED,
      direction: SortDirection.DESC,
    },
    {
      label: 'Last Name',
      name: PEOPLE_SORT.LASTNAME,
      direction: SortDirection.ASC,
    },
    {
      label: 'Last Name',
      name: PEOPLE_SORT.LASTNAME,
      direction: SortDirection.DESC,
    },
    {
      label: 'Plant Svc Date',
      name: PEOPLE_SORT.PLANTSERVDATE,
      direction: SortDirection.ASC,
    },
    {
      label: 'Plant Svc Date',
      name: PEOPLE_SORT.PLANTSERVDATE,
      direction: SortDirection.DESC,
    },
  ];

  return (
    <div className="flex flex-row justify-between flex-wrap">
      <div className="w-full sm:w-10/12">
        <div className="w-full mb-2 sm:mb-0 sm:w-4/12 ">
          <SearchInput
            id="search-input"
            label="search"
            value={search ?? ''}
            placeholder="Search Name / USSID"
            autoCompleteFlag={search ? true : false}
            selectedFlag={search ? true : false}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onClose={() => {
              clearSearch();
            }}
          />
        </div>
      </div>
      <div className="flex items-center justify-end w-full sm:w-1/6">
        <Sort<PeopleSort>
          selectedOption={
            sortOptions.filter(
              (value) => sortBy === value.name && sortType === value.direction
            )[0]
          }
          options={sortOptions}
          onChange={(value) =>
            setSort({
              name: value.name,
              direction: value.direction,
            })
          }
        />
      </div>
    </div>
  );
};
