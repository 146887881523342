import { AreaManagerTabs, HrLrTabs, ReviewerTabs } from '../types';
import getCurrentView from './getCurrentView';

export const getCurrentTab = (
  areaManagerCurrentTab: AreaManagerTabs | undefined,
  reviewerCurrentTab: ReviewerTabs | undefined,
  hrLrCurrentTab: HrLrTabs | undefined,
  pathname: string
) => {
  const { isAreaManagerView, isReviewerView, isHrLrView } =
    getCurrentView(pathname);
  if (isAreaManagerView) {
    return areaManagerCurrentTab;
  } else if (isReviewerView) {
    return reviewerCurrentTab;
  } else if (isHrLrView) {
    return hrLrCurrentTab;
  }
};
