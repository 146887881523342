import { OrgSecurityRecord } from 'features/org/types';
import { useMutation } from '@tanstack/react-query';
import { WMSError } from 'types/common';
import { axios } from '../../../services/api/axios';
import { OrgMemberCreateBody, TempOrgMemberCreateBody } from '../types';
import { queryClient } from 'services/api/react-query';
import { notify } from 'components/Notification/useNotification';

const addCrewMember = (
  orgId: string,
  body: OrgMemberCreateBody | TempOrgMemberCreateBody
): Promise<OrgSecurityRecord> => {
  return axios.post(`/v1/orgs/${orgId}/security`, body);
};

/**
 * @description Hook that provides functionality to add a crew member or temp member
 *
 * @param orgId ID of the org you wish to add a crew member too
 *
 * @example
 *
 * const { mutate, mutateAsync } = useAddCrewMember('12345');
 * // Call the mutate function to create a crew member for the given crew
 * mutate({ ussId: 12345, role: 'crew_member' });
 * //Call the mutateAsync function to call the async version of mutate
 * mutateAsync({ ussId: 12345, role: 'crew_temp_member', effectiveDate: '2022-02-13' });
 */

export const useAddCrewMember = (orgId: string) => {
  return useMutation<
    OrgSecurityRecord,
    WMSError,
    OrgMemberCreateBody | TempOrgMemberCreateBody
  >({
    mutationFn: (body: OrgMemberCreateBody | TempOrgMemberCreateBody) =>
      addCrewMember(orgId, body),
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['crew-members'] });
      queryClient.removeQueries({ queryKey: ['employee-securities'] });
      notify.success({ message: 'Crew member is added successfully' });
    },
    meta: {
      errorMessage: 'Unabled to add crew member',
    },
  });
};

// eslint-disable-next-line react-hooks/rules-of-hooks
// const { mutate } = useAddCrewMember('12345');

// mutate({ ussId: 12345, role: 'crew_member' });
