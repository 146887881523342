import { PayrollGroups } from '../types';

export const PayrollGroupRequiredCheck = (data: PayrollGroups[]) => {
  let isPayrollGroupChecked = true;
  if (!data.some((e) => e.active)) {
    isPayrollGroupChecked = false;
  }

  return isPayrollGroupChecked;
};
