import { Link } from '@uss/react-components';
import Card from 'components/Card/Card';
import Title from 'components/Typography/Title';
import { BsInfoSquare, BsListCheck } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import { Org, ORG_TYPE, OrgType } from 'features/org';
import { OrgCardBadge } from './OrgStatusBadge';

interface Props {
  org: Org;
  type: OrgType;
}

export const OrgCard = ({ org, type }: Props) => {
  const description =
    type === ORG_TYPE.DIVISION
      ? `${org.division ?? ''} - ${org.divisionDescription ?? ''}`
      : `${org.department ?? ''} - ${org.departmentDescription ?? ''}`;
  const childOrgs = type === ORG_TYPE.DIVISION ? 'Departments' : 'Crews';
  const detailsPath =
    type === ORG_TYPE.DIVISION ? 'divisions-details' : 'departments-details';
  const childOrgsListPath =
    type === ORG_TYPE.DIVISION ? `departments` : 'crews';
  return (
    <Card>
      <Card.Header>
        <Title as="h3">
          <span className="pr-3">{description}</span>
        </Title>
        {
          <OrgCardBadge value={org.status.code}>
            <span className="capitalize">{org.status.code}</span>
          </OrgCardBadge>
        }
      </Card.Header>
      <Card.Footer>
        <div className="flex w-full">
          <div className="flex w-1/2 justify-center relative after:w-0.5 after:absolute after:right-0 after:h-full after:bg-gray-300">
            <Link
              as={NavLink}
              className="text-sm text-primary-400 cursor-pointer flex items-center justify-center"
              to={`/admin/org-structure/${org.id}/${childOrgsListPath}/`}
            >
              <BsListCheck id="childOrg" className="text-base" />
              <span className="text-xs ml-2">{`${childOrgs} (${
                org.childOrgsCount ?? ''
              })`}</span>
            </Link>
          </div>
          <div className="flex w-1/2 justify-center">
            <Link
              as={NavLink}
              className="text-sm text-primary-400 cursor-pointer flex items-center justify-center"
              to={`/admin/org-structure/${org.id}/${detailsPath}`}
            >
              <BsInfoSquare id="details" className="text-sm" />
              <span className="text-xs ml-2"> Details</span>
            </Link>
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
};
