import { useEffect } from 'react';
import { useEmployeeTimecardWithoutCrew } from '../api/getTimecardDetails';
import { PrimaryCrewPtoType } from '../types';
import { getPrimaryCrewPtos } from '../utilities/getPrimaryCrewPtos';
import { useNonUnionStore } from './useNonUnionStore';

export const useSetPrimaryCrewPtos = (ussId: string) => {
  const state = useNonUnionStore();
  //calling timecard-details API wihtout crew
  const { data: primaryCrewPtoDetails } = useEmployeeTimecardWithoutCrew(
    ussId,
    {
      week: state.params.week ?? '',
    }
  );
  //saving Primary-crew pto-details to store
  useEffect(() => {
    if (primaryCrewPtoDetails) {
      const tempArr: PrimaryCrewPtoType[] = getPrimaryCrewPtos(
        primaryCrewPtoDetails
      );
      //setting val to store
      state.setPrimaryCrewPtos(tempArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryCrewPtoDetails]);
};
