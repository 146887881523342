import Page from 'components/Layouts/Page';
import Tabs, { TabLinkType } from 'components/Tabs/Tabs';
import ProbationaryEmployeesSummary from 'features/probationary-tracking/components/reviewer/Summary';
import { ReviewerTabs } from 'features/probationary-tracking/types';
import ReviewDue from 'features/probationary-tracking/components/reviewer/tabs/ReviewDue';
import ReEvaluate from 'features/probationary-tracking/components/reviewer/tabs/ReEvaluate';
import Counseling from 'features/probationary-tracking/components/reviewer/tabs/Counseling';
import FinalReview from 'features/probationary-tracking/components/reviewer/tabs/FinalReview';
import { ErrorBoundary } from 'react-error-boundary';
import Error from 'components/Error/Error';
import { useProbationaryEmployeesStore } from 'features/probationary-tracking/state/store';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Assigned from 'features/probationary-tracking/components/reviewer/tabs/Assigned';

const ReviewerView = () => {
  return (
    <Page heading={'Probationary Employees'}>
      <ErrorBoundary
        fallbackRender={({ error }) => {
          return (
            <Error
              width="300"
              height="300"
              title="Something went wrong!"
              description={error.message}
            />
          );
        }}
      >
        <Content />
      </ErrorBoundary>
    </Page>
  );
};
const Content = () => {
  // Tabs
  const { reviewer } = useProbationaryEmployeesStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = reviewer.currentTab;
  const setCurrentTab = reviewer.setCurrentTab;

  const handleTabClick = (label: ReviewerTabs) => {
    //Setting current tab selection to ref on tab click
    setCurrentTab(label);
  };

  useEffect(() => {
    if (currentTab) {
      const updatedSearchParams = new URLSearchParams(searchParams.toString());
      updatedSearchParams.set('tab', currentTab);
      setSearchParams(updatedSearchParams.toString());
    } else {
      setCurrentTab(ReviewerTabs.ASSIGNED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabLinks: TabLinkType[] = [
    {
      label: ReviewerTabs.ASSIGNED,
      element: <Assigned />,
      customOnChange: () => handleTabClick(ReviewerTabs.ASSIGNED),
    },
    {
      label: ReviewerTabs.REVIEW_DUE,
      element: <ReviewDue />,
      customOnChange: () => handleTabClick(ReviewerTabs.REVIEW_DUE),
    },
    {
      label: ReviewerTabs.RE_EVALUATE,
      element: <ReEvaluate />,
      customOnChange: () => handleTabClick(ReviewerTabs.RE_EVALUATE),
    },
    {
      label: ReviewerTabs.COUNSELING,
      element: <Counseling />,
      customOnChange: () => handleTabClick(ReviewerTabs.COUNSELING),
    },
    {
      label: ReviewerTabs.FINAL_REVIEW,
      element: <FinalReview />,
      customOnChange: () => handleTabClick(ReviewerTabs.FINAL_REVIEW),
    },
  ];
  return (
    <>
      <Page.Section>
        <ProbationaryEmployeesSummary />
      </Page.Section>
      <Page.Section>
        <Tabs tabLinks={tabLinks} />
      </Page.Section>
    </>
  );
};
export default ReviewerView;
