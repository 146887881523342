import { CalendarBlock } from '../types';
import { ComponentType, ElementType } from 'react';

type MonthCardProps<P = Record<string, unknown>> = {
  calendardata: CalendarBlock;
  handleClick?: (date: Date) => void;
  children: React.ReactNode;
  as?: ComponentType<P> | ElementType;
} & P;

const MonthCard = ({
  as: Component = 'div',
  calendardata,
  handleClick,
  children,
}: MonthCardProps) => {
  return (
    <Component
      className={`bg-gray-50 h-12 sm:h-40 p-2 2xl:p-4 flex flex-wrap flex-col ${
        calendardata.selected
          ? 'border rounded-lg border-accent1-400 shadow-md bg-white'
          : ''
      }`}
      data-testid={`${calendardata.dateText}-card`}
      {...(handleClick
        ? { onClick: () => handleClick(calendardata.date) }
        : {})}
    >
      <span
        className={`w-full text-sm sm:text-lg ${
          calendardata.selected ? 'font-semibold' : ''
        }`}
      >
        {calendardata.dateText}
      </span>

      {children}
    </Component>
  );
};

export default MonthCard;
