import { Select } from '@uss/react-components';
import { SHIFT_ASSIGNMENT, ShiftAssignment } from 'features/people/types';
import { TurnTypes } from 'features/timecards/types';
import { useEffect, useState } from 'react';

interface Props {
  initialSelected: string | null;
  turnType: TurnTypes;
  update: (turn: string, columnName: TurnTypes) => void;
  shift: ShiftAssignment | null;
  editable?: boolean;
  futureDay?: boolean;
}

const TurnSelect = ({
  shift,
  update,
  initialSelected,
  turnType,
  editable = false,
  futureDay = false,
}: Props) => {
  const [selected, setSelected] = useState(String(initialSelected));

  useEffect(() => {
    setSelected(String(initialSelected));
  }, [initialSelected]);

  const disabled = (turnType: TurnTypes) => {
    if (turnType === 'scheduledTurn') {
      return false;
    } else if (futureDay) {
      return true;
    } else {
      return false;
    }
  };

  const handleSelect = (value: string) => {
    setSelected(() => value);
    update(value, turnType);
  };

  if (editable) {
    return (
      <Select
        selected={selected}
        onSelect={handleSelect}
        disabled={disabled(turnType)}
      >
        <Select.Button label={selected} />
        <Select.Options isWidthAuto={true}>
          {(shift === SHIFT_ASSIGNMENT.EIGHT_HOUR ||
            shift === SHIFT_ASSIGNMENT.EIGHT_DAY_HOUR) && (
            <Select.Option key={1} value="1" truncate>
              <span>1 - Night</span>
            </Select.Option>
          )}

          <Select.Option key={2} value="2" truncate>
            <span>2 - Day</span>
          </Select.Option>

          <Select.Option key={3} value="3" truncate>
            <span>
              {shift === SHIFT_ASSIGNMENT.EIGHT_HOUR ||
              shift === SHIFT_ASSIGNMENT.EIGHT_DAY_HOUR
                ? '3 - Afternoon'
                : '3 - Night'}
            </span>
          </Select.Option>
        </Select.Options>
      </Select>
    );
  } else {
    return <span data-testid="select-read-only">{selected || '-'}</span>;
  }
};

export default TurnSelect;
