import { Configuration } from '@azure/msal-browser';
import { AUTH_CONFIG } from 'config';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: AUTH_CONFIG.CLIENT_ID,
    authority:
      'https://login.microsoftonline.com/056be435-b129-4e1b-bba0-26a9c0ed6b2f',
    postLogoutRedirectUri: '/wms/app/',
  },
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
