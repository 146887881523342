import { add, format } from 'date-fns';

const getDateRangeText = (startDate: Date): string => {
  const formattedStartDate = format(startDate, 'MMM dd, yyyy');
  const formattedEndDate = format(add(startDate, { days: 6 }), 'MMM dd, yyyy');

  return `${formattedStartDate} - ${formattedEndDate}`;
};

export default getDateRangeText;
