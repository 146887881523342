import { PAYROLL_INDICATORS } from '../constants';
import { PayrollIndicators } from '../types';

const getPayrollIndicatorText = (value: PayrollIndicators) => {
  switch (value) {
    case PAYROLL_INDICATORS.PTO:
      return 'PTO';
    case PAYROLL_INDICATORS.PRODUCTION_BONUS:
      return 'Production Bonus';
    case PAYROLL_INDICATORS.INC_ELIGIBLE:
      return 'Incentive';
    case PAYROLL_INDICATORS.PAYABLE:
      return 'Payable';
    case PAYROLL_INDICATORS.REPORT:
      return 'Reportable';
    case PAYROLL_INDICATORS.VACATION:
      return 'Vacation';
    case PAYROLL_INDICATORS.INCENTIVE:
      return 'Incentive';
    default:
      return '';
  }
};
export default getPayrollIndicatorText;
