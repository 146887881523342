import { Select } from '@uss/react-components';
import { TIMECARD_STATUS, TimecardStatusCode } from 'features/timecards/types';
import getFormattedCrewFilterStatus from 'features/timecards/utilities/getFormattedCrewFilterStatus';

interface TimecardStatusSelectProps {
  selectedStatus: TimecardStatusCode | undefined;
  setStatus: (value: TimecardStatusCode) => void;
  disabled?: boolean;
}

export const TimecardStatusSelect = ({
  selectedStatus,
  setStatus,
  disabled = false,
}: TimecardStatusSelectProps) => {
  return (
    <Select
      selected={selectedStatus ? selectedStatus : 'all'}
      onSelect={(value: TimecardStatusCode) => {
        setStatus(value);
      }}
      className="w-full"
      disabled={disabled}
      data-testid="status-select-filter"
    >
      <Select.Button
        label={
          selectedStatus === undefined
            ? 'Select Timecard Status'
            : getFormattedCrewFilterStatus(selectedStatus)
        }
      />
      <Select.Options>
        {selectedStatus && (
          <Select.Option value={'all'}>All Timecard Statuses</Select.Option>
        )}
        {Object.values(TIMECARD_STATUS).map((item, index) => {
          return (
            <Select.Option key={index} value={item}>
              {getFormattedCrewFilterStatus(item)}
            </Select.Option>
          );
        })}
      </Select.Options>
    </Select>
  );
};
