import {
  Actions,
  AreaManagerRetainModalOptions,
  HrLrRetainModalOptions,
  ReviewActionLabel,
} from '../types';

export const getActionParamForReqBody = (
  btnLabel: ReviewActionLabel,
  isAreaManagerView: boolean,
  isReviewerView: boolean,
  isHrLrView: boolean,
  isEmployeeView: boolean,
  HrLrRetainModalOptions?: HrLrRetainModalOptions,
  selectedRetentionOption?: string
): Actions => {
  const getAreaManagerActions = () => {
    switch (btnLabel) {
      case ReviewActionLabel.RETAIN:
        if (
          selectedRetentionOption === AreaManagerRetainModalOptions.RE_EVALUATE
        ) {
          return Actions.SUPERVISOR_RETAINED;
        } else {
          return Actions.COUNSELLING;
        }
      case ReviewActionLabel.TERMINATION:
        return Actions.SUPERVISOR_TERMINATED;
      case ReviewActionLabel.ACKNOWLEDGE:
        return Actions.SUPERVISOR_TERM_ACKNOWLEDGEMENT;
      default:
        return Actions.ADD_COMMENT;
    }
  };

  const getReviewerActions = () => {
    switch (btnLabel) {
      //Review Due, Revaluate, Counselling tabs
      case ReviewActionLabel.RETAIN:
        return Actions.REVIEWER_RETAINED;
      case ReviewActionLabel.TERMINATION:
        return Actions.REVIEWER_TERMINATED;
      //Final Review tab
      case ReviewActionLabel.COMPLETE:
        return Actions.FINAL_REVIEW;
      default:
        return Actions.ADD_COMMENT;
    }
  };
  const getHrLrActions = () => {
    switch (btnLabel) {
      //Review Performance tab
      case ReviewActionLabel.RETAIN:
        if (
          selectedRetentionOption === HrLrRetainModalOptions?.UNTIL_NEXT_REVIEW
        ) {
          return Actions.HR_LR_RETAINED;
        } else {
          return Actions.COUNSELLING;
        }
      case ReviewActionLabel.TERMINATION:
        return Actions.HR_LR_TERMINATED;
      //Prob Employees, Low Rating tab
      case ReviewActionLabel.ADD:
        return Actions.ADD_COMMENT;
      default:
        return Actions.ADD_COMMENT;
    }
  };
  const getEmployeeActions = () => {
    return Actions.EMP_ACKNOWLEDGEMENT;
  };
  if (isAreaManagerView) {
    return getAreaManagerActions();
  } else if (isReviewerView) {
    return getReviewerActions();
  } else if (isHrLrView) {
    return getHrLrActions();
  } else if (isEmployeeView) {
    return getEmployeeActions();
  }
  return Actions.ADD_COMMENT;
};
