import { Datepicker } from '@uss/react-components';
import { parse } from 'date-fns';
import useCurrentDate from 'hooks/useCurrentDate';
import useWindowSize from 'hooks/useWindowSize';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import { WeekStart } from 'types/common';
import Loader from 'components/Skeleton/Loader';
interface WeekSelectProps {
  disabled?: boolean;
  startDayOfWeek: WeekStart | undefined;
}
const WeekSelect = ({ disabled = false, startDayOfWeek }: WeekSelectProps) => {
  const state = useNonUnionStore();
  const { width } = useWindowSize();
  const { cutOffDate, nextYearCutOff } = useCurrentDate({
    weekStart: startDayOfWeek ?? 0,
  });
  const startWeek = !!state.params.week
    ? parse(state.params.week, 'yyyy-MM-dd', new Date())
    : null;

  return (
    <div className="flex-grow" data-testid="week-select-filter">
      {!!startWeek && startDayOfWeek !== undefined ? (
        <Datepicker
          startDate={startWeek}
          onRangeChange={(value) => state.setWeek(value)}
          onClickLeft={() => state.decrementWeek()}
          onClickRight={() => state.incrementWeek()}
          onClear={() => state.setWeek([startWeek, null])}
          onCancel={() => state.setWeek([startWeek, null])}
          calendarType="week"
          id="date-picker"
          startDayOfWeek={startDayOfWeek}
          controls
          isMobile={width && width < 1024 ? true : false}
          minDate={cutOffDate}
          maxDate={nextYearCutOff}
          disabled={disabled}
        />
      ) : (
        <Loader isFilter={true} />
      )}
    </div>
  );
};

export default WeekSelect;
