const CalendarLoader = () => {
  const days = Array.from(Array(7)).map((e, i) => i + 1);
  const blocks = Array.from(Array(35)).map((e, i) => i + 1);
  return (
    <div className="rounded-md p-2 lg:p-4 bg-white my-4">
      <div className="grid gap-2 grid-cols-7">
        {days.map((day) => (
          <div
            key={day}
            className="flex flex-col rounded-[6px] sm:rounded-lg h-8 w-full sm:p-2 2xl:p-4 bg-gray-100 animate-pulse"
          >
            <span className="sr-only">Loading...</span>
          </div>
        ))}
        {blocks.map((item) => (
          <div
            key={item}
            className="flex flex-col rounded-[6px] sm:rounded-lg w-10 sm:w-auto h-8 sm:h-36 p-[2px] sm:p-2 bg-gray-100 animate-pulse"
          >
            <span className="sr-only">Loading...</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarLoader;
