import Card from 'components/Card/Card';
import Loader from 'components/Skeleton/Loader';
import { SectionType } from 'features/timecards/types';
const CopyCardLoader = ({ type }: { type: SectionType }) => {
  return (
    <Card>
      <Card.Body>
        <div
          className="flex items-center justify-center sm:mb-28 "
          data-testid="from-card"
        >
          <div className="w-11/12 md:w-3/4 xl:3/4 2xl:3/4">
            <p className=" font-semibold text-lg text-gray-700">
              {type === SectionType.FROM ? 'From' : 'To'}
            </p>
            <div className="bg-white border-t border-gray-300  my-2"></div>
            <div className=" flex flex-col ">
              <Loader rows={2} />
            </div>
            <div className=" flex flex-col my-4">
              <p className="text-xs text-gray-700">Crew</p>
              <Loader isFilter={true} />
            </div>
            <div className=" flex flex-col gap-y-4 my-4">
              <div>
                <p className="text-xs text-gray-700">Start Week</p>
                <Loader isFilter={true} />
              </div>
              <div>
                <p className="text-xs text-gray-700">End Week</p>
                <Loader isFilter={true} />
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
const CopyScheduleLoader = () => {
  return (
    <div className="grid md:grid-cols-2  gap-4  ">
      <CopyCardLoader type={SectionType.FROM} />
      <CopyCardLoader type={SectionType.TO} />
    </div>
  );
};
export default CopyScheduleLoader;
