import LabeledText from 'components/Typography/LabeledText';
import { Person } from 'features/people/types';

interface SelectEmployeeDetailsProps {
  employee?: Pick<
    Person,
    | 'displayName'
    | 'supervisorId'
    | 'emailAddress'
    | 'empClass'
    | 'empClassDesc'
    | 'jobTitle'
    | 'payrollLocation'
    | 'hrLocation'
  > | null;
}

export const SelectedEmployeeDetails = ({
  employee,
}: SelectEmployeeDetailsProps) => {
  return (
    <div>
      {employee && (
        <div className="py-2 ">
          <div className=" col-span-2 flex flex-col">
            <span className="text-base font-semibold text-gray-700">
              {employee.displayName}
            </span>
            <span className="text-xs text-gray-700 flex">
              {employee.supervisorId}
              <span className="bg-gray-300 w-0.5 mx-2"></span>
              {employee.emailAddress}
            </span>
          </div>
          <div>
            <LabeledText label="Employee Class">
              {employee.empClass}
              {'-'}
              {employee.empClassDesc}
            </LabeledText>
          </div>

          <div>
            <LabeledText label="Job Title">{employee.jobTitle}</LabeledText>
          </div>
          <div>
            <LabeledText label="Payroll Location">
              {employee.payrollLocation} {'- '}
              {employee.hrLocation}
            </LabeledText>
          </div>
        </div>
      )}
    </div>
  );
};
