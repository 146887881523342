import { Avatar } from '@uss/react-components';
import { getInitials } from 'utils';
import SeparatedLabels from 'components/SeparatedLabels/SeparatedLabels';
import Title from 'components/Typography/Title';
import getFormattedPersonStatus from 'features/people/utilities/getFormattedPersonStatus';
import { usePersonDetails } from '../api';

const EmployeeProfile = ({ id }: { id?: string }) => {
  const { data: employeeData, status } = usePersonDetails(id ?? 'me');
  const displayName = employeeData?.displayName
    ? employeeData.displayName
    : 'No Display Name';
  const getSeperators = () => {
    const empStatus = employeeData?.status;
    const empClass = employeeData?.empClass;
    const empClassDesc = employeeData?.empClassDesc;
    const primaryCrew = employeeData?.primaryCrew;

    return [
      {
        label: 'USS ID',
        value: employeeData?.ussId ?? '-',
      },
      {
        label: 'Employee Status',
        value: empStatus ? getFormattedPersonStatus(empStatus) : '-',
      },
      {
        label: 'Employee Class Description',
        value: `${empClass ?? '-'} - ${empClassDesc ?? '-'}`,
      },
      {
        label: 'Primary Crew',
        value: primaryCrew ?? '-',
      },
    ];
  };
  return (
    <>
      {status === 'pending' ? (
        <Loader />
      ) : (
        <div className="flex items-center flex-grow ">
          <span>
            <Avatar color={1} size="medium">
              {getInitials(employeeData?.firstName, employeeData?.lastName)}
            </Avatar>
          </span>
          <div className="ml-4 flex-grow">
            <Title as="h2" aria-label="Employee Name">
              {displayName}
            </Title>
            <SeparatedLabels size="md" items={getSeperators()} />
          </div>
        </div>
      )}
    </>
  );
};

const Loader = () => (
  <span
    className="flex items-center flex-grow animate-pulse"
    data-testid="employee-profile-loader"
  >
    <span className="uss-h-10 uss-w-10 uss-text-base uss-bg-gray-200 uss-rounded-full uss-items-center uss-justify-center uss-flex uss-flex-shrink-0"></span>
    <div className="flex-1 space-y-6 py-1 ml-4">
      <div className="space-y-3">
        <div className="grid grid-cols-3 gap-4">
          <div className="h-2 bg-gray-200 rounded col-span-1"></div>
          <div className="col-span-2"></div>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div className="h-2 bg-gray-200 rounded col-span-1"></div>
          <div className="col-span-2"></div>
        </div>
      </div>
    </div>
  </span>
);

export default EmployeeProfile;
