import { useQuery } from '@tanstack/react-query';

import { PaginationResponse, SortDirection } from 'types/common';
import { SecurityRecord } from 'features/security';
import { axios } from 'services/api/axios';
import { ROLES } from 'features/roles';

interface SecurityOptions {
  role?: string;
  orgId?: number;
  primary?: 'y' | null;
  week?: string;
  sortBy?: string;
  sortType?: SortDirection;
  page?: number;
  pageSize?: number;
  retrieveFutureDatedRecords?: string;
}

export interface EmployeeSecurityList extends PaginationResponse {
  items: SecurityRecord[];
}
const getEmployeeSecurity = (
  ussId: string,
  options?: SecurityOptions
): Promise<EmployeeSecurityList> => {
  return axios.get(`/v1/people/${ussId}/security`, {
    params: options,
  });
};
/**
 * @returns Axios Promise
 *
 * @link https://cappsq.psc.uss.com/SBWM-DOCUMENT/swagger-ui/index.html?urls.primaryName=sbwm-people#/security-controller/getPeoples_1
 */
export const useEmployeeSecurity = (
  ussId: string,
  options?: SecurityOptions
) => {
  return useQuery({
    queryKey: ['employee-securities'],
    queryFn: () => getEmployeeSecurity(ussId, options),

    refetchOnMount: true,
  });
};

export const useEmployeeSecurityForPayAaprover = (
  ussId: string,
  options?: SecurityOptions
) => {
  return useQuery({
    queryKey: ['pay-approver-employee-securities'],
    queryFn: () => getEmployeeSecurity(ussId, options),
    refetchOnMount: true,
    enabled: options?.role === ROLES.PAY_APPROVER,
  });
};
