import { OrgList } from "features/org";
import { Plant } from "../types";

export const getPlantList = (plantList:OrgList) => {
  const plantArr: Plant[] = [];
  if (plantList.items.length > 0) {
      plantList.items.forEach(data => {
        const obj: Plant = { id: data.id, plant: data.plant, weekBeginDayIndex: data.weekBeginDayIndex };
        plantArr.push(obj);
      })
  }
  return plantArr
}
