import { useContext } from 'react';
import { ModalOptions, ModalUpdateContext } from './ModalsContext';

const useModal = () => {
  const dispatch = useContext(ModalUpdateContext);
  const openModal = (options: Omit<ModalOptions, 'open'>) => {
    dispatch({ type: 'OPEN', payload: options });
  };
  const closeModal = () => {
    dispatch({ type: 'CLOSE' });
  };
  return { openModal, closeModal };
};

export default useModal;
