import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { create } from 'zustand';
import {
  PeopleOptions,
  PEOPLE_SORT,
  PERSON_STATUS,
  PeopleSort,
} from 'features/people/types';
import { SortDirection } from 'types/common';
import { Org, OrgsOptions, OrgsSortByType, ORG_TYPE } from 'features/org';

interface CrewMaintenanceEmployeeState {
  params: PeopleOptions;
  crewParams: OrgsOptions;
  selectedPlant: Org | 'all' | null;
  selectedPayrollLocation: Org | 'all' | null;
  employeeId: string;
  setPage: (value: string) => void;
  setCrewPage: (value: string) => void;
  setPageSize: (value: string) => void;
  setCrewPageSize: (value: string) => void;
  setSearch: (value: string) => void;
  setCrewSearch: (value: string) => void;
  setPlant: (value: Org | 'all') => void;
  setPayrollLocation: (value: Org | 'all') => void;
  setExcludeEmpClass: (value: string) => void;
  setEmployeeId: (value: string) => void;
  removePlant: () => void;
  setSort: (value: {
    name: PeopleSort | undefined;
    direction: SortDirection | undefined;
  }) => void;
  setCrewSort: (value: {
    name: OrgsSortByType | undefined;
    direction: SortDirection | undefined;
  }) => void;
  clearSearch: () => void;
  clearCrewSearch: () => void;
}

export const useCrewMaintenanceEmployeeStore =
  create<CrewMaintenanceEmployeeState>()(
    devtools(
      immer((set) => ({
        params: {
          page: '1',
          pageSize: '9',
          sortBy: PEOPLE_SORT.RECENTLYUPDATED,
          sortType: SortDirection.DESC,
          crewEligible: 'y',
          status: `${PERSON_STATUS.ACTIVE},${PERSON_STATUS.INACTIVE},${PERSON_STATUS.PENDING},${PERSON_STATUS.TERMINATEASSIGNMENT}`,
        },
        crewParams: {
          page: '1',
          pageSize: '9',
          sortBy: 'crew',
          sortType: SortDirection.ASC,
          type: ORG_TYPE.CREW,
          parentOrgs: 'y',
          role: 'pay_approver,t_a_plant_coordinator,payroll_admin',
        },
        selectedPlant: null,
        selectedPayrollLocation: null,
        employeeId: '',
        setPage: (value) =>
          set(
            (state) => {
              state.params.page = value;
            },
            false,
            'set-page'
          ),
        setCrewPage: (value) =>
          set(
            (state) => {
              state.crewParams.page = value;
            },
            false,
            'set-crew-page'
          ),
        setPageSize: (value) =>
          set(
            (state) => {
              state.params.pageSize = value;
              state.params.page = '1';
            },
            false,
            'set-pageSize'
          ),
        setCrewPageSize: (value) =>
          set(
            (state) => {
              state.crewParams.pageSize = value;
              state.crewParams.page = '1';
            },
            false,
            'set-crew-pageSize'
          ),
        setSearch: (value) =>
          set(
            (state) => {
              if (value.length > 0) {
                state.params.search = value;
              } else {
                delete state.params.search;
              }

              state.params.page = '1';
            },
            false,
            'set-search'
          ),

        setCrewSearch: (value) =>
          set(
            (state) => {
              if (value.length > 0) {
                state.crewParams.search = value;
              } else {
                delete state.crewParams.search;
              }

              state.crewParams.page = '1';
            },
            false,
            'set-crew-search'
          ),
        clearSearch: () =>
          set(
            (state) => {
              state.params.page = '1';
              delete state.params.search;
            },
            false,
            'clear-search'
          ),
        clearCrewSearch: () =>
          set(
            (state) => {
              state.crewParams.page = '1';
              delete state.crewParams.search;
            },
            false,
            'clear-crew-search'
          ),
        setPayrollLocation: (value) => {
          set(
            (state) => {
              if (value === 'all') {
                if (state.selectedPlant !== 'all' && state.selectedPlant) {
                  state.crewParams.parentOrgId = state.selectedPlant.id;
                } else {
                  delete state.crewParams.parentOrgId;
                  delete state.params.payrollLocations;
                }
                state.selectedPayrollLocation = 'all';
              } else {
                state.crewParams.parentOrgId = value.id;
                state.params.payrollLocations = value.code;
                state.selectedPayrollLocation = value;
                state.crewParams.page = '1';
              }
            },
            false,
            'set-plant'
          );
        },
        setPlant: (value) => {
          set(
            (state) => {
              if (value === 'all') {
                delete state.params.plant;
                delete state.crewParams.parentOrgId;
                state.selectedPlant = 'all';
              } else {
                state.params.plant = value.code;
                state.crewParams.parentOrgId = value.id;
                state.selectedPlant = value;
                state.params.page = '1';
                state.crewParams.page = '1';
              }
              state.selectedPayrollLocation = 'all';
              delete state.params.payrollLocations;
            },
            false,
            'set-plant'
          );
        },
        removePlant: () => {
          set(
            (state) => {
              delete state.params.plant;
              delete state.crewParams.parentOrgId;
              state.selectedPlant = 'all';
            },
            false,
            'remove-plant'
          );
        },
        setSort: (value) => {
          set(
            (state) => {
              state.params.sortBy = value.name;
              state.params.sortType = value.direction;
              state.params.page = '1';
            },
            false,
            'set-sort'
          );
        },
        setCrewSort: (value) => {
          set(
            (state) => {
              state.crewParams.sortBy = value.name;
              state.crewParams.sortType = value.direction;
              state.crewParams.page = '1';
            },
            false,
            'set-crew-sort'
          );
        },
        setEmployeeId: (value) => {
          set(
            (state) => {
              state.employeeId = value;
            },
            false,
            'set-employee-id'
          );
        },
        setExcludeEmpClass: (value) =>
          set(
            (state) => {
              if (value.length > 0) {
                state.params.excludeEmpClass = value;
              } else {
                delete state.params.excludeEmpClass;
              }
            },
            false,
            'set-excludeEmpClass'
          ),
      }))
    )
  );
