import { SHIFT_ASSIGNMENT } from "features/paycode";
import { ShiftAssignment } from "features/people";

const getShiftAssignmentNumber = (shift: ShiftAssignment | '') => {
  switch (shift) {
    case SHIFT_ASSIGNMENT.EIGHT_DAY_HOUR:
      return 8;
    case SHIFT_ASSIGNMENT.EIGHT_HOUR:
      return 8;
    case SHIFT_ASSIGNMENT.TEN_HOUR:
      return 10;
    case SHIFT_ASSIGNMENT.TWELVE_HOUR:
      return 12;
    default:
      return 0;
  }
};
export default getShiftAssignmentNumber;