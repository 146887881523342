import { Person } from 'features/people/types';
import { WeekStart } from 'types/common';

export interface CopyScheduleDifferentEmpPayload {
  source: {
    crewId: string;
    firstWeek: string;
    lastWeek?: string;
  };
  target: {
    ussId: string;
    crewId: string;
    firstWeek?: string;
    lastWeek?: string;
  };
}

export interface StartDateErrorType {
  source: boolean;
  target: boolean;
}

export type UpdateFromDateError = React.Dispatch<
  React.SetStateAction<StartDateErrorType>
>;

type setWeekRangeChecked = React.Dispatch<
  React.SetStateAction<{
    from: boolean;
    to: boolean;
  }>
>;

interface WeekRange {
  from: boolean;
  to: boolean;
}

export interface CopySectionProps {
  weeksState: CopyScheduleDifferentEmpPayload;
  data: Person | undefined;
  sourceCrewKey: string | undefined;
  id: string;
  weekRangeChecked: WeekRange;
  setWeekRangeChecked: setWeekRangeChecked;
  updateWeekState: React.Dispatch<CopyParamAction>;
  errorState: StartDateErrorType;
  updateErrorState: UpdateFromDateError;
  isMySchedule: boolean;
  startDayOfWeek: WeekStart | undefined;
}

export enum CopyParamActions {
  FROM_START_WEEK = 'fromStartweek',
  FROM_END_WEEK = 'fromEndWeek',
  TO_START_WEEK = 'toStartWeek',
  TO_END_WEEK = 'toEndWeek',
  DELETE_FROM_START_WEEK = 'deleteFromStartWeek',
  DELETE_FROM_END_WEEK = 'deleteFromEndWeek',
  DELETE_TO_START_WEEK = 'deleteToStartWeek',
  DELETE_TO_END_WEEK = 'deleteToEndWeek',
}

export type CopyParamAction =
  | { type: CopyParamActions.FROM_START_WEEK; payload: string }
  | { type: CopyParamActions.FROM_END_WEEK; payload: string }
  | { type: CopyParamActions.TO_START_WEEK; payload: string }
  | { type: CopyParamActions.TO_END_WEEK; payload: string }
  | { type: CopyParamActions.DELETE_TO_END_WEEK }
  | { type: CopyParamActions.DELETE_FROM_END_WEEK }
  | { type: CopyParamActions.DELETE_TO_START_WEEK }
  | { type: CopyParamActions.DELETE_FROM_START_WEEK };

export enum SectionType {
  FROM = 'from',
  TO = 'to',
}

export enum CopyType {
  SAME_EMPLOYEE = 'sameEmployee',
  DIFFERENT_EMPLOYEE = 'differentEmp',
}
