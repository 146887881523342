import Page from 'components/Layouts/Page';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { ORG_TYPE } from 'features/org';
import { useOrgDetails } from 'features/org/api';
import useUserRoles from 'hooks/useUserRoles';
import { ROLES } from 'features/roles';
import Tabs from 'components/Tabs/Tabs';
import { DivisionDetails, OrgDetailsCard } from 'features/org/components';
import { History } from 'features/history/components/History';
import { HISTORY_ENTITY } from 'features/history';

const Division = () => {
  //get id from params
  const { id = '' } = useParams<'id'>();
  //get selected division details
  const { data, status } = useOrgDetails(id);

  const { roles } = useUserRoles();

  const tabLinks = [
    {
      label: `Details`,
      element: <DivisionDetails />,
    },
  ];

  //only Super_Admin, Super_Payroll_Admin, Payroll_Admin and T&A Coordinator are able to view Division History
  if (
    roles.includes(ROLES.SUPER_ADMIN) ||
    roles.includes(ROLES.SUPER_PAYROLL_ADMIN) ||
    roles.includes(ROLES.PAYROLL_ADMIN) ||
    roles.includes(ROLES.T_A_PLANT_COORDINATOR)
  ) {
    tabLinks.push({
      label: 'History',
      element: (
        <History
          id={id}
          entity={HISTORY_ENTITY.DIVISION}
          noRecordError="No history records found for the selected division"
          descLabel="Comments"
        />
      ),
    });
  }

  return (
    <Page
      breadcrumbs={[
        <NavLink key="bc-1" to="/admin">
          Admin
        </NavLink>,
        <NavLink key="bc-2" to="/admin/org-structure/divisions">
          Org Structure Divisions
        </NavLink>,
      ]}
      heading="Division Details"
    >
      {status === 'success' && (
        <>
          <Page.Section>
            <OrgDetailsCard data={data} type={ORG_TYPE.DIVISION} />
          </Page.Section>
          <Page.Section>
            <Tabs tabLinks={tabLinks} />
            <Outlet />
          </Page.Section>
        </>
      )}
    </Page>
  );
};

export default Division;
