export type UnitOfMeasure = 'days' | 'hours';

export default function getUnitOfMeasure(uom: UnitOfMeasure, value: number) {
  switch (uom) {
    case 'days':
      if ((value > 0 && value <= 1) || (value >= -1 && value < 0)) {
        return 'Day';
      } else {
        return 'Days';
      }
    case 'hours':
      if ((value > 0 && value <= 1) || (value >= -1 && value < 0)) {
        return 'Hr';
      } else {
        return 'Hrs';
      }
  }
}
