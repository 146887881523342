/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

declare global {
  interface Window {
    _paq: any[][];
  }
}

const _paq: any[][] = window._paq;

// const TrackEvent = (
//   category: string,
//   action: string,
//   name: string,
//   value?: any
// ) => {
//   const _paq: any[][] = window._paq;

//   _paq.push([
//     'trackEvent',
//     category.toString(), //As `Category` (Required Parameter)
//     action.toString(), //As `Action`    (Required Parameter)
//     name, //As `Name`
//     value, //As `Value`
//   ]);
// };

export const trackPage = () => {
  _paq.push(['setCustomUrl', window.location.href]);
  _paq.push(['trackPageView']);
};
