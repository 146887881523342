import React from 'react';
import { Button } from '@uss/react-components';
import useModal from 'components/Modals/use-modal';

import {
  usePayPeriodStatusUpdate,
  PAY_PERIOD_STATUS,
  PayPeriodStatus,
} from 'features/pay-periods';
import { RunPulloffBody, useRunPulloff } from 'features/paypulloff/api';
import { YesNo } from 'types/common';

interface Props {
  payrollName: string;
  payPeriodStatus: PayPeriodStatus;
  payPeriodId: string;
}

const ActionsContainer = ({
  payrollName,
  payPeriodId,
  payPeriodStatus,
}: Props) => {
  const { mutate: runPulloffRequest } = useRunPulloff(
    {
      dryRun: YesNo.NO,
    } as RunPulloffBody,
    payrollName
  );
  const { mutate: updatePayPeriodStatus } =
    usePayPeriodStatusUpdate(payPeriodId);

  const modal = useModal();

  const handleLockPayPeriod = () => {
    const lock = () => {
      updatePayPeriodStatus({ status: PAY_PERIOD_STATUS.LOCKED });
      modal.closeModal();
    };

    modal.openModal({
      title: 'Lock Pay Period',
      type: 'danger',
      children: (
        <div className="w-full">
          <p className="text-sm text-gray-700 w-full">
            Do you want to lock this pay period?
          </p>
          <br />
          <p className="text-sm text-gray-700 w-full">
            This action will lock all timecards for data entry.
          </p>
        </div>
      ),
      labels: { confirm: 'Lock', cancel: 'Cancel' },
      onCancel: () => modal.closeModal(),
      onConfirm: () => lock(),
    });
  };
  const handleUnlockPayPeriod = () => {
    const unlock = () => {
      updatePayPeriodStatus({ status: PAY_PERIOD_STATUS.OPEN });
      modal.closeModal();
    };

    modal.openModal({
      title: 'Unlock Pay Period',
      type: 'danger',
      children: (
        <div className="w-full">
          <p className="text-sm text-gray-700 w-full">
            Do you want to unlock this pay period?
          </p>
          <br />
          <p className="text-sm text-gray-700 w-full">
            This action will unlock all timecards for data entry.
          </p>
        </div>
      ),
      labels: { confirm: 'Unlock', cancel: 'Cancel' },
      onCancel: () => modal.closeModal(),
      onConfirm: () => unlock(),
    });
  };
  const handlePulloff = () => {
    const run = () => {
      runPulloffRequest();
      modal.closeModal();
    };

    modal.openModal({
      title: 'Run Process?',
      type: 'danger',
      children: (
        <p className="text-sm text-gray-700 w-full">
          Are you sure want to run weekly pulloff process for all approved hours
          in current pay peroid?
        </p>
      ),
      labels: { confirm: 'Run Pulloff', cancel: 'Cancel' },
      onCancel: () => modal.closeModal(),
      onConfirm: () => run(),
    });
  };

  return (
    <Actions
      payrollName={payrollName}
      payPeriodStatus={payPeriodStatus}
      handleLockPayPeriod={handleLockPayPeriod}
      handleUnlockPayPeriod={handleUnlockPayPeriod}
      handlePulloff={handlePulloff}
    />
  );
};

interface ActionsProps extends Omit<Props, 'data' | 'payPeriodId'> {
  handleLockPayPeriod: () => void;
  handleUnlockPayPeriod: () => void;
  handlePulloff: () => void;
  payPeriodStatus: PayPeriodStatus;
}

export const Actions = ({
  payPeriodStatus,
  handleLockPayPeriod,
  handleUnlockPayPeriod,
  handlePulloff,
}: ActionsProps) => {
  const disabledRunPullOff = payPeriodStatus !== PAY_PERIOD_STATUS.LOCKED;
  const disabledLockUnlock =
    payPeriodStatus === PAY_PERIOD_STATUS.PROCESSED ||
    payPeriodStatus === PAY_PERIOD_STATUS.IN_PROGRESS ||
    payPeriodStatus === PAY_PERIOD_STATUS.CLOSED ||
    payPeriodStatus === PAY_PERIOD_STATUS.NOT_OPEN;

  return (
    <div
      id="actions-container"
      className="mt-4 sm:mt-0 lg:flex flex-row justify-between"
    >
      <div className="flex w-full mt-4">
        {payPeriodStatus === PAY_PERIOD_STATUS.OPEN && (
          <Button
            data-testid="lock-pay-period-btn"
            onClick={handleLockPayPeriod}
            variant="outline"
            className="w-full sm:w-auto"
            disabled={disabledLockUnlock}
          >
            Lock Pay Period
          </Button>
        )}
        {payPeriodStatus !== PAY_PERIOD_STATUS.OPEN && (
          <Button
            data-testid="unlock-pay-period-btn"
            onClick={handleUnlockPayPeriod}
            variant="outline"
            className="w-full sm:w-auto"
            disabled={disabledLockUnlock}
          >
            Unlock Pay Period
          </Button>
        )}
      </div>
      <div className="flex sm:justify-end w-full mt-4">
        <Button
          data-testid="run-pulloff-btn"
          onClick={handlePulloff}
          variant="primary"
          className="w-full sm:w-auto"
          disabled={disabledRunPullOff}
        >
          Run Pulloff
        </Button>
      </div>
    </div>
  );
};

export default ActionsContainer;
