import { ObjectValues } from 'types/utils';

export const ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  SUPER_PAYROLL_ADMIN: 'SUPER_PAYROLL_ADMIN',
  PAYROLL_ADMIN: 'PAYROLL_ADMIN',
  T_A_PLANT_COORDINATOR: 'T_A_PLANT_COORDINATOR',
  SCHEDULING_ADMIN: 'SCHEDULING_ADMIN',
  PAY_APPROVER: 'PAY_APPROVER',
  SCHEDULE_PAY_VIEWER: 'SCHEDULE_PAY_VIEWER',
  NONUNION_EMPLOYEE: 'NONUNION_EMPLOYEE',
  UNION_EMPLOYEE: 'UNION_EMPLOYEE',
  HR_ROLE: 'HR_ROLE',
  HR_LR_ROLE: 'HR_LR_ROLE',
  PROB_EMP_REVIEWER: 'PROB_EMP_REVIEWER',
  AREA_MANAGER: 'AREA_MANAGER',
} as const;

export type RolesType = ObjectValues<typeof ROLES>;

export const ROLECODES = {
  PAYROLL_ADMIN: 'payroll_admin',
  T_A_PLANT_COORDINATOR: 't_a_plant_coordinator',
  PAY_APPROVER: 'pay_approver',
  SCHEDULING_ADMIN: 'scheduling_admin',
  SCHEDULE_PAY_VIEWER: 'schedule_pay_viewer',
  CREW_MEMBER: 'crew_member',
  CREW_TEMP_MEMBER: 'crew_temp_member',
} as const;

export type RoleCodes = ObjectValues<typeof ROLECODES>;

export interface RoleItem {
  id: string;
  orgId: string;
  orgCode: string;
  plant: string;
  location: string | null;
  division: string | null;
  department: string | null;
  crew: string | null;
  roleId: string;
  roleName: string;
  roleCode: RoleCodes;
}
