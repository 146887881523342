import { z } from "zod";
import { MODEL_TYPE } from "../constants";


export const CreateModelSchema = z.object({
  modelType: z.union([z.literal('global'), z.literal('plant')]),
  modelName: z.string().nonempty({ message: 'Model name is required' })
    .min(5, 'Model name must be at least 5 alphanumeric characters')
    .max(10, 'Model name should not exceed 10 alphanumeric characters'),
  modelDescription: z.string().nonempty({ message: 'Model description is required' })
    .max(100, 'Model description cannot be more than 100 characters'),
  weekBeginIndex: z.union([z.literal(0), z.literal(6)]),
  plantOrgId: z.string().optional(),
  locationOrgId: z.string().optional(),
  totalWeeks: z.coerce.number()
    .int()
    .positive()
    .gt(0)
  .lte(52),
  totalCrewRotations: z.coerce.number({
    required_error: 'Total crew rotations required',
  })
  .min(1, 'Total crew rotations required'),
})
  .superRefine((val, ctx) => {
    if (val.modelType === MODEL_TYPE.PLANT && val.plantOrgId === "") {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message : 'Plant is required.',
        path: ['plantOrgId'],
      fatal:true
      })
    }
    },
  ).refine(
    (val) => {
      if (val.modelType === MODEL_TYPE.PLANT
        && val.locationOrgId === '') 
      return false;
    return true;
    },
    {
      message: 'Payroll location is required.',
      path: ['locationOrgId'],
    }
  )