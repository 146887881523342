import Page from 'components/Layouts/Page';
import PerformanceCriteriaHelp from 'features/probationary-tracking/components/common/Help/Help';
import { NavLink, useParams } from 'react-router-dom';

const Help = () => {
  const { ussId = '', id = '' } = useParams<'ussId' | 'id'>();

  const breadCrumbNodes = [
    <NavLink
      to={`/probationary-tracking/employee-acknowledgement/${ussId}`}
      key="bc-1"
    >
      Employee Details
    </NavLink>,
    <NavLink
      to={`/probationary-tracking/employee-acknowledgement/${ussId}/performance-review/${id}`}
      key="bc-2"
    >
      Performance Review
    </NavLink>,
  ];
  return (
    <Page
      heading={'Defining the 8 performance criteria'}
      breadcrumbs={breadCrumbNodes}
    >
      <Page.Section>
        <PerformanceCriteriaHelp />
      </Page.Section>
    </Page>
  );
};
export default Help;
