import { QueryStatus } from '@tanstack/react-query';
import { Select } from '@uss/react-components';
import { Org, OrgList, ORG_TYPE, OrgType } from 'features/org';

interface Props {
  selectedValue: Org | null | undefined;
  selectData: OrgList | undefined;
  dataStatus: QueryStatus;
  onSelect: (value: Org) => void;
  type: OrgType;
}

const SecuritySelectInput = ({
  selectData,
  selectedValue,
  dataStatus,
  onSelect,
  type,
}: Props) => {
  const getItemString = (item: Org) => {
    switch (type) {
      case ORG_TYPE.PLANT:
        return <span>{item.plant}</span>;
      case ORG_TYPE.LOCATION:
        return (
          <span>
            {item.location} - {item.description}
          </span>
        );
      case ORG_TYPE.DIVISION:
        return (
          <span>
            {item.division} - {item.description}
          </span>
        );
      case ORG_TYPE.DEPARTMENT:
        return (
          <span>
            {item.department} - {item.description}
          </span>
        );
      case ORG_TYPE.CREW:
        return (
          <span>
            {item.crew} - {item.description}
          </span>
        );
    }
  };

  const getItemLabel = () => {
    switch (type) {
      case ORG_TYPE.PLANT:
        return selectedValue ? selectedValue.plant : 'Select';
      case ORG_TYPE.LOCATION:
        return selectedValue
          ? `${selectedValue.location ?? ''} - ${
              selectedValue.description ?? ''
            }`
          : 'All';
      case ORG_TYPE.DIVISION:
        return selectedValue
          ? `${selectedValue.division ?? ''}  -  ${
              selectedValue.description ?? ''
            }`
          : 'All';
      case ORG_TYPE.DEPARTMENT:
        return selectedValue
          ? `${selectedValue.department ?? ''}  - ${
              selectedValue.description ?? ''
            }`
          : 'All';
      case ORG_TYPE.CREW:
        return selectedValue
          ? `${selectedValue.crew ?? ''} - ${selectedValue.description ?? ''}`
          : 'All';
      default:
        return 'All';
    }
  };

  return (
    <Select
      selected={selectedValue}
      onSelect={onSelect}
      disabled={dataStatus !== 'success'}
    >
      <Select.Button label={getItemLabel()} />
      <Select.Options>
        {type !== ORG_TYPE.PLANT && (
          <Select.Option value={''}> All </Select.Option>
        )}

        {selectData?.items.map((item, itemIdx) => (
          <Select.Option key={itemIdx} value={item}>
            {getItemString(item)}
          </Select.Option>
        ))}
      </Select.Options>
    </Select>
  );
};

export default SecuritySelectInput;
