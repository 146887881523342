import { Select } from '@uss/react-components';
import React from 'react';
import { OrgFilterStatus, OrgType } from 'features/org';
import { getOrgStatusList } from '../utilities/getOrgStatusList';
import { getOrgStatusText } from '../utilities/getOrgStatusText';

export const StatusSelect = ({
  status,
  onSelect,
  type,
}: {
  status: OrgFilterStatus | undefined;
  onSelect: (value: OrgFilterStatus) => void;
  type: OrgType;
}) => {
  const statusValues = getOrgStatusList(type);
  const handleStatusChange = (value: OrgFilterStatus) => {
    onSelect(value);
  };
  return (
    <Select
      selected={status ? status : 'all'}
      onSelect={(value: OrgFilterStatus) => {
        handleStatusChange(value);
      }}
      className="w-full"
      data-testid="status-filter"
    >
      <Select.Button
        label={status && status !== 'all' ? getOrgStatusText(status) : 'Status'}
      />
      <Select.Options>
        <Select.Option value="all">All</Select.Option>
        {statusValues.map((item, index) => {
          return (
            <Select.Option key={index} value={item}>
              {getOrgStatusText(item)}
            </Select.Option>
          );
        })}
      </Select.Options>
    </Select>
  );
};
