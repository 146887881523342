import CardSkeleton from 'components/Skeleton/CardSkeleton';
import _ from 'lodash';

export const CardsLoader = () => {
  const card: React.ReactElement[] = [];
  _.times(6, (n) => {
    card.push(<CardSkeleton key={`loader-${n}`} />);
  });
  return (
    <div className="grid md:grid-cols-2 xl:grid-cols-3  gap-4 py-4">
      {card.map((item: React.ReactElement) => {
        return item;
      })}
    </div>
  );
};
