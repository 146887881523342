import { OrgsOptions, ORG_TYPE } from 'features/org';
import { useEmployeeOrgs } from 'features/org/api';
import { ROLECODES } from 'features/roles';
import { useEmployeeSecurity } from 'features/security/api';

const useAllowedCrews = (ussId: string) => {
  const crewParams: OrgsOptions = {
    sortBy: 'code',
    type: ORG_TYPE.CREW,
  };

  const { data: crews, status: loggedInUserStatus } = useEmployeeOrgs(
    'me',
    crewParams
  );

  // Fetching the employee crews
  const { data: employeeCrews, status: employeeCrewsStatus } =
    useEmployeeSecurity(ussId, {
      role: ROLECODES.CREW_MEMBER,
    });

  // Employee Crew Ids
  const employeeCrewIds = employeeCrews?.items.map((sr) => sr.orgId);

  const crewOptions = crews
    ? crews.items.filter((c) => !employeeCrewIds?.includes(c.id))
    : [];

  const status =
    loggedInUserStatus === 'success' && employeeCrewsStatus === 'success';

  return { crewOptions, status };
};

export default useAllowedCrews;
