import { PLANT_TYPE } from '../constants';
import { PlantType } from '../types';

const getPlantTypeText = (value: PlantType) => {
  switch (value) {
    case PLANT_TYPE.MINI_MILL:
      return 'Mini Mill';
    case PLANT_TYPE.INTEGRATED_EXEMPT:
      return 'Integrated Mill';
    case PLANT_TYPE.INTEGRATED_NON_EXEMPT:
      return 'Integrated Non Exempt';
    case PLANT_TYPE.PART_TIME_PAID_SICK:
      return 'Part Time Paid Sick';
  }
};
export default getPlantTypeText;
