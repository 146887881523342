import { useMutation } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { WMSError } from 'types/common';
import { Org, OrgPost } from 'features/org';

const addOrg = (body: OrgPost): Promise<Org> => {
  return axios.post('/v1/orgs', body);
};

export const useAddOrg = () => {
  return useMutation<Org, WMSError, OrgPost>({
    mutationFn: (body: OrgPost) => addOrg(body),
    meta: {
      errorMessage: 'Unable to add org',
    },
  });
};
