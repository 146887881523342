import { PersonStatus } from 'features/people/types';
import { EmployeeDetail, ReviewStatus, ReviewerDetail } from './employeeList';
import { Comment } from './employeeReviewDetails';
interface WorkDetails {
  totalWorkHours: number | null;
  approvedWorkedHours: number | null;
  remainingWorkHours: number | null;
  schedHoursToDate: number | null;
  swipeTimeToDate: number | null;
}
export interface ReviewDetails {
  id: string;
  reviewNumber: number | null;
  reviewStatus: ReviewStatus | null;
  workedHours: number | null;
  reviewPeriodStartDt: string | null;
  reviewPeriodEndDt: string | null;
  approvedDate: string | null;
}

export interface PerformanceReviewDetails {
  id: string;
  ussId: number;
  lastName: string;
  firstName: string;
  displayName: string | null;
  middleName: string | null;
  suffix: string | null;
  status: PersonStatus;
  reviewStatus: ReviewStatus | null;
  activeReviewNumber: number;
  isBreached: 'y' | 'n' | null;
  startDate: string;
  estEndDate: string | null;
  lastPayDate: string | null;
  bargUnit: string | null;
  job: string | null;
  department: string | null;
  workDetails: WorkDetails;
  reviewerDetails: ReviewerDetail;
  supervisorDetails: EmployeeDetail;
  reviewDetails: ReviewDetails[];
  comments: Comment[] | null;
}
export enum PerfReviewButton {
  REVIEW_PERFORMANCE = 'Review Performance',
  ACKNOWLEDGE_TERMINATION = 'Acknowledge Termination',
  DOWNLOAD_REPORT = 'Download Report',
  VIEW_REPORT = 'View Report',
  COMPLETE_REVIEW = 'Complete Review',
  ACKNOWLEDGE_REPORT = 'Acknowledge Report',
  REPORT_UNAVAILABLE = 'Report Unavailable',
}
