import React from 'react';

import { Select } from '@uss/react-components';
import {
  BsArrowUp,
  BsArrowDown,
  BsSortDown,
  BsSortUpAlt,
} from 'react-icons/bs';
import { SortDirection } from 'types/common';

interface SortOption<T = string> {
  label: string;
  name: T;
  direction: SortDirection;
}
interface Props<T> {
  options: SortOption<T>[];
  onChange: (value: SortOption<T>) => void;
  selectedOption?: SortOption<T>;
}

const Sort = <T,>({ options, onChange, selectedOption }: Props<T>) => {
  const [selected, setSelected] = React.useState<SortOption<T>>(
    selectedOption ?? options[0]
  );

  const handleSortFilters = (value: SortOption<T>) => {
    setSelected(value);
    onChange(value);
  };

  return (
    <div className="w-full" data-testid="sort">
      <Select selected={selected.name} onSelect={handleSortFilters}>
        <Select.Button
          label={selected.name ? `${selected.label}` : 'Select'}
          filter
        >
          {selected.direction === SortDirection.ASC ? (
            <BsSortUpAlt color="#005288" size="1rem" />
          ) : (
            <BsSortDown color="#005288" size="1rem" />
          )}
        </Select.Button>

        <Select.Options>
          {options.map((item, itemIdx) => {
            return (
              <Select.Option key={itemIdx} value={item}>
                <span className="flex items-center justify-between w-full">
                  <span className="pr-6">{item.label}</span>
                  <span className="flex justify-end ">
                    {item.direction === SortDirection.ASC ? (
                      <BsArrowUp color="#005288" />
                    ) : (
                      <BsArrowDown color="#005288" />
                    )}
                  </span>
                </span>
              </Select.Option>
            );
          })}
        </Select.Options>
      </Select>
    </div>
  );
};

export default Sort;
