import { HOLIDAY_TYPE } from 'features/holidays/constants';
import { PTO_CLASS_NAME } from 'features/paid-time-off/constants';
import { SortDirection, WMSAPIResponse } from 'types/common';
import { ObjectValues } from 'types/utils';
import { z } from 'zod';

export const PERSON_STATUS = {
  ACTIVE: 'active',
  TERMINATEASSIGNMENT: 'terminate-assignment',
  INACTIVE: 'inactive',
  PENDING: 'pending',
} as const;

export type PersonStatus = ObjectValues<typeof PERSON_STATUS>;

const UnionIndicatorSchema = z
  .union([z.literal('y'), z.literal('n')])
  .nullable();
// export type PersonUnionIndicator = z.infer<typeof UnionIndicatorSchema>;

export const SHIFT_ASSIGNMENT = {
  EIGHT_HOUR: '8hr',
  EIGHT_DAY_HOUR: '8dh',
  TEN_HOUR: '10hr',
  TWELVE_HOUR: '12hr',
} as const;

export type ShiftAssignment = ObjectValues<typeof SHIFT_ASSIGNMENT>;

const ZodPtoClass = z.nativeEnum(PTO_CLASS_NAME);
export type PtoClassType = z.infer<typeof ZodPtoClass>;
const PersonSchema = z.object({
  ussId: z.number(),
  lastName: z.string(),
  firstName: z.string(),
  middleName: z.string().nullable(),
  suffix: z.string().nullable(),
  userId: z.string().nullable(),
  emailAddress: z.string().nullable(),
  hrLocation: z.string().nullable(),
  payrollLocation: z.string().nullable(),
  payrollLocationDescription: z.string().nullable(),
  status: z.nativeEnum(PERSON_STATUS),
  corpServDate: z.string().nullable(),
  plantServDate: z.string().nullable(),
  jobTitle: z.string().nullable(),
  unionInd: UnionIndicatorSchema,
  empClass: z.string().nullable(),
  empClassDesc: z.string().nullable(),
  costCenter: z.string().nullable(),
  costCenterDesc: z.string().nullable(),
  payrollName: z.string().nullable(),
  supervisorId: z.number().nullable(),
  supervisorLastName: z.string().nullable(),
  supervisorFirstName: z.string().nullable(),
  supervisorMiddleName: z.string().nullable(),
  supervisorSuffix: z.string().nullable().optional(),
  shiftAssignment: z.nativeEnum(SHIFT_ASSIGNMENT).nullable(),
  displayName: z.string().nullable(),
  supervisorDisplayName: z.string().nullable(),
  primaryCrew: z.string().nullable().optional(),
  primaryCrewName: z.string().nullable().optional(),
  holidayType: z.nativeEnum(HOLIDAY_TYPE),
  policyGroup: z.string().nullable(),
  payrollGroup: z.string().nullable(),
  payrollNameId: z.string().nullable(),
  paidSickTimeEligibility: z.string().nullable(),
  crewEligible: z.string().nullable(),
  ptoClass: ZodPtoClass,
  plantType: z.string().optional(),
  paidSickEligibilityDate: z.string().nullable().optional(),
});

export type Person = z.infer<typeof PersonSchema>;

export const PEOPLE_SORT = {
  RECENTLYUPDATED: 'recentlyUpdated',
  FIRSTNAME: 'firstName',
  LASTNAME: 'lastName',
  USERID: 'userId',
  USSID: 'ussId',
  JOBTITLE: 'jobTitle',
  EMPTYPE: 'empType',
  HRLOCATION: 'hrLocation',
  PAYROLLLOCATION: 'payrollLocation',
  PLANTSERVDATE: 'plantServDate',
};

export type PeopleSort = ObjectValues<typeof PEOPLE_SORT>;
export type PeopleContext = 'addTempEmp';

type IsCrewEligible = 'y' | 'n';
export interface PeopleOptions {
  employeeType?: 'union' | 'nonunion';
  status?: string;
  payrollLocations?: string | null;
  payrollName?: string;
  search?: string;
  ussids?: string;
  sortBy?: PeopleSort;
  sortType?: SortDirection;
  pageSize?: string;
  page?: string;
  plant?: string | null;
  week?: string;
  primaryCrew?: string;
  searchByRole?: string;
  crewEligible?: IsCrewEligible;
  excludeEmpClass?: string;
  context?: PeopleContext;
}
export type ReporteeOptions = Pick<
  PeopleOptions,
  'search' | 'pageSize' | 'page' | 'searchByRole'
>;

export type PeopleResponse = WMSAPIResponse<Person>;
