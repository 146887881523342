import { ROLECODES } from 'features/roles';
import { Org, OrgSecurityPrimary, OrgSecurityRecord } from 'features/org';
import { PaginationResponse } from 'types/common';
import { SecurityRecord } from 'features/security';
import { PayPeriodStatus } from 'features/pay-periods';

interface OrgSecurityBody {
  ussId: number;
  endDate?: string | null;
  sortOrder?: number | null;
  primary?: OrgSecurityPrimary | null;
}
export interface OrgMemberCreateBody extends OrgSecurityBody {
  role: typeof ROLECODES.CREW_MEMBER;
  effectiveDate?: string | null;
}
export interface TempOrgMemberCreateBody extends OrgSecurityBody {
  role: typeof ROLECODES.CREW_TEMP_MEMBER;
  effectiveDate: string | null;
}

export interface CancelMemberMoveResponse {
  success: boolean;
  message: string;
}

export interface CancelMemebrMoveRequest {
  ussId: number;
  crewId: string;
}

export interface ChangeCrewBody {
  targetCrewId: string;
  moveSchedules: boolean;
}

export interface ChangeCrewMutationObjType {
  ussId: string;
  crewId: string;
  body: ChangeCrewBody;
}

export interface DeleteCrewMemberWithUssId {
  ussId: number;
  role: typeof ROLECODES.CREW_MEMBER | typeof ROLECODES.CREW_TEMP_MEMBER;
}
export interface DeleteCrewMemberWithId {
  id: string;
  role: typeof ROLECODES.CREW_MEMBER | typeof ROLECODES.CREW_TEMP_MEMBER;
}

export interface DeleteCrewMemberResponse {
  success: boolean;
  message: string;
}

export interface UpdateCrewMembersResponse extends PaginationResponse {
  items: OrgSecurityRecord[];
}

export interface UpdatePayApproverBodyWithUssId {
  ussId: number;
  orgRoleId: string;
  primary?: OrgSecurityPrimary | null;
}
interface UpdatePayApproverBodyWithId {
  id: string;
  orgRoleId: string;
  primary?: OrgSecurityPrimary | null;
}

export type UpdatePayApproverBody =
  | UpdatePayApproverBodyWithId[]
  | UpdatePayApproverBodyWithUssId[];

export interface UpdateCrewMembersResponse extends PaginationResponse {
  items: OrgSecurityRecord[];
}

export type HandleMove = (
  fromCrew: SecurityRecord,
  moveSchedules: boolean,
  targetCrewId?: string
) => Promise<void> | void;

export type HandleMoveEmployee = (
  currentCrew: Org,
  moveFutureSchedules: boolean,
  targetCrewId?: string
) => Promise<void> | void;

export interface ShiftAssignmentParams {
  payPeriodId?: string | null;
}

export enum ShiftAssignmentType {
  EIGHT_HOUR = '8-HR',
  TWELVE_HOUR = '12-HR AWS',
}

export enum ShiftAssignmentStatus {
  AWAITING_APPROVAL = 'Awaiting Approval',
  AWAITING_PROCESSING = 'Awaiting Processing',
}

export interface ShiftAssignment {
  id: string;
  shift: ShiftAssignmentType;
  status: ShiftAssignmentStatus | null;
  payPeriodId: string;
  payPeriodStatus: PayPeriodStatus;
  startDate: string;
  endDate: string;
  approvedFlag: boolean;
  changeInitialized: boolean;
  adjustmentFlag: boolean;
}

export interface ShiftAssignmentList {
  items: ShiftAssignment[];
}

export interface ShiftAssignmentBody {
  shift: ShiftAssignmentType;
  payPeriodId: string;
}
