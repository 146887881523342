import React, { useState } from 'react';
import { Select, Accordion } from '@uss/react-components';
import { format, parseISO } from 'date-fns';
import clsx from 'clsx';
import { Person, ShiftAssignment } from 'features/people/types';
import useTimecardRowDetails from 'features/timecards/hooks/useTimecardRowDetails';
import TimecardComment from '../TimecardTable/TimecardComment';
import TurnSelect from '../FormInputs/TurnSelect';
import HoursInput from '../FormInputs/HoursInput';
import {
  TimecardActionType,
  EmployeeTimecardList,
  Timecard,
} from 'features/timecards/types';
import { PaycodeList, usePaycodes } from 'features/paycode';
import getTimecardPaycodeParams from 'features/timecards/utilities/getTimecardPaycodeParams';
import { PAY_PERIOD_STATUS } from 'features/pay-periods/types';
import Tooltip from 'components/Tooltip/Tooltip';
import { getPaycodesByEmpClass } from 'features/timecards/utilities/getPaycodesByEmpClass';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';

const TimecardCard = ({
  employeeTimecardObj,
  data,
  timecardInstance,
  rowIndex,
  updateTimecard,
  isTimecardDisabled,
  isHoliday,
  approvedStatus,
  requestedStatus,
  isBlackout,
  shiftAssignment,
  employee,
  roles,
}: {
  employeeTimecardObj: EmployeeTimecardList;
  data: Timecard;
  timecardInstance: Timecard[];
  rowIndex: number;
  updateTimecard: React.Dispatch<TimecardActionType>;
  isTimecardDisabled: boolean;
  isHoliday: boolean;
  approvedStatus: boolean;
  requestedStatus: boolean;
  isBlackout: boolean | undefined;
  shiftAssignment: ShiftAssignment;
  employee: Person;
  roles: string[];
}) => {
  const {
    handleInputChange,
    handleTurnUpdate,
    onSelectPaycode,
    scheduledVarianceHours,
    handleClearShift,
    handleCommentUpdate,
    isApprovedHoursInvalid,
    isScheduleHoursInvalid,
    isWorkedHoursInvalid,
    isMoreOptionsEnabled,
    isFutureDay,
    isWorkedEditable,
    isApprovedEditable,
    isScheduleEditable,
    isPtoPaycodeSelected,
  } = useTimecardRowDetails({
    employeeTimecardObj: employeeTimecardObj,

    updateTimecard,
    timecardRowData: data,
    rowIndex,
    isTimecardDisabled,
  });

  const { data: timeCardPaycode } = usePaycodes(
    getTimecardPaycodeParams(
      employee.plantType,
      employee.shiftAssignment,
      roles,
      employee.paidSickTimeEligibility,
      employee.empClass,
      scheduledVarianceHours,
      data.date,
      employee.paidSickEligibilityDate
    )
  );

  const state = useNonUnionStore();

  const filteredTimecardPayCodeArr: PaycodeList | undefined =
    getPaycodesByEmpClass(timeCardPaycode, employee);

  const [panelState, setPanelState] = useState(true);

  const checkPanelState = (): void => {
    setPanelState(!panelState);
  };

  const dayOfWeek = format(parseISO(data.date), 'eeee');

  //*** Logic to show Primary-crew PTOs on Secondary-crew timecard ***/
  let primaryPtoVal = '';
  if (state.primaryCrewPtos && state.primaryCrewPtos.length > 0) {
    const filteredCrewData = state.primaryCrewPtos.filter(
      (item) => item.date === data.date
    );
    if (filteredCrewData.length > 0)
      primaryPtoVal = filteredCrewData[0].paycode;
  }
  //checking if employee primaryCrew is equals to selected crew from filter
  const isPrimaryCrewValid = employee.primaryCrewName === state.crew?.crew;

  return (
    <>
      <div
        className={
          isHoliday ||
          isBlackout ||
          (!isPrimaryCrewValid && primaryPtoVal !== '')
            ? ' bg-gray-300 p-2 my-4 border-b block'
            : 'bg-white p-2 my-4 border-b block'
        }
      >
        <div className="flex flex-row justify-between mb-4">
          <div>
            <span className="text-lg">
              {format(parseISO(data.date), 'MMM d, E')}
            </span>
            {isHoliday && <p className="text-xs">Holiday</p>}
            {isBlackout && <p className="text-xs">Blackout</p>}
            {!isPrimaryCrewValid && primaryPtoVal !== '' && (
              <p className="text-xs">
                {primaryPtoVal}
                {' - '}
                {'Primary Crew'}
              </p>
            )}
          </div>
          <div className="flex flex-row justify-between">
            <span
              className={clsx([
                'flex flex-row items-center ',
                data.comments === '' || data.comments === null
                  ? 'justify-end'
                  : 'justify-between',
              ])}
            >
              <TimecardComment
                isMoreOptionsEnabled={isMoreOptionsEnabled}
                employeeTimecardObj={employeeTimecardObj}
                handleClearShift={handleClearShift}
                timecardInstance={timecardInstance}
                rowData={data}
                date={data.date}
                handleCommentUpdate={handleCommentUpdate}
                employee={employee}
                isPtoPaycodeSelected={isPtoPaycodeSelected}
              />
            </span>
          </div>
        </div>
        <Accordion variant="Link">
          {panelState && (
            <div className="grid grid-cols-2 gap-y-4 mb-4">
              <div className="flex flex-col justify-start">
                <span className="text-xs text-gray-600 ">Scheduled Hrs</span>
                <span className="text-sm">{data.scheduled.hours ?? '-'}</span>
              </div>
              <div className="flex flex-col justify-start">
                <span className="text-xs text-gray-600 ">Worked Hrs</span>
                <span className="text-sm">{data.worked.hours ?? '-'}</span>
              </div>
              <div className="flex flex-col justify-start">
                <span className="text-xs text-gray-600 ">Approved Hrs</span>
                <span className="text-sm">{data.approved.hours ?? '-'}</span>
              </div>
              <div className="flex flex-col justify-start">
                <span className="text-xs text-gray-600 ">
                  Paid Not Worked Hrs
                </span>
                <span className="text-sm">
                  {data.paidHoursNotWorked === null ||
                  data.paidHoursNotWorked === 0
                    ? '-'
                    : data.paidHoursNotWorked}
                </span>
              </div>
            </div>
          )}
          <Accordion.Panel variant="Link">
            <div>
              <div className="mb-4">
                <div className="text-sm font-semibold text-gray-600">
                  Scheduled
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-col justify-start">
                    <span className="text-xs text-gray-600 ">Turns</span>
                    <span className="text-sm w-3/4">
                      <TurnSelect
                        initialSelected={data.scheduled.turn ?? ''}
                        editable={isScheduleEditable}
                        futureDay={isFutureDay}
                        shift={shiftAssignment}
                        turnType={'scheduledTurn'}
                        update={handleTurnUpdate}
                      />
                    </span>
                  </div>
                  <div className="flex flex-col justify-start">
                    <span className="text-xs text-gray-600 ">Hours</span>
                    <span className="text-sm w-3/4">
                      <HoursInput
                        label={`Scheduled Hours ${dayOfWeek}`}
                        index={rowIndex}
                        editable={isScheduleEditable}
                        error={isScheduleHoursInvalid}
                        updateInput={handleInputChange}
                        hourType={'scheduledHours'}
                        initialValue={
                          data.scheduled.hours !== null
                            ? String(data.scheduled.hours)
                            : ''
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <div className="text-sm font-semibold text-gray-600">
                  Worked
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-col justify-start">
                    <span className="text-xs text-gray-600 ">Turns</span>
                    <span className="text-sm w-3/4">
                      <TurnSelect
                        initialSelected={data.worked.turn ?? ''}
                        editable={isWorkedEditable}
                        futureDay={isFutureDay}
                        shift={shiftAssignment}
                        turnType={'workedTurn'}
                        update={handleTurnUpdate}
                      />
                    </span>
                  </div>
                  <div className="flex flex-col justify-start">
                    <span className="text-xs text-gray-600 ">Hours</span>
                    <span className="text-sm w-3/4">
                      <HoursInput
                        label={`Worked Hours ${dayOfWeek}`}
                        index={rowIndex}
                        futureDay={isFutureDay}
                        editable={isWorkedEditable}
                        error={isWorkedHoursInvalid}
                        updateInput={handleInputChange}
                        hourType={'workedHours'}
                        initialValue={
                          data.worked.hours !== null
                            ? String(data.worked.hours)
                            : ''
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <div className="text-sm font-semibold text-gray-600">
                  Approved
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-col justify-start">
                    <span className="text-xs text-gray-600 ">Turns</span>
                    <span className="text-sm w-3/4">
                      <TurnSelect
                        initialSelected={data.approved.turn ?? ''}
                        editable={isApprovedEditable}
                        futureDay={isFutureDay}
                        shift={shiftAssignment}
                        turnType={'approvedTurn'}
                        update={handleTurnUpdate}
                      />
                    </span>
                  </div>
                  <div className="flex flex-col justify-start">
                    <span className="text-xs text-gray-600 ">Hours</span>
                    <span className="text-sm w-3/4">
                      <HoursInput
                        label={`Approved Hours ${dayOfWeek}`}
                        index={rowIndex}
                        futureDay={isFutureDay}
                        editable={isApprovedEditable}
                        error={isApprovedHoursInvalid}
                        updateInput={handleInputChange}
                        hourType={'approvedHours'}
                        initialValue={
                          data.approved.hours !== null
                            ? String(data.approved.hours)
                            : ''
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <div className="text-sm font-semibold text-gray-600">
                  Schedule Variance
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-col justify-start">
                    <span className="text-xs text-gray-600 ">Paycode</span>
                    <span className="text-sm w-3/4">
                      {isWorkedEditable ||
                      isApprovedEditable ||
                      isScheduleEditable ||
                      employeeTimecardObj.items[0].payPeriodStatus ===
                        PAY_PERIOD_STATUS.NOT_OPEN ? (
                        <>
                          <Select
                            selected={data.scheduleVariance.payCode ?? ''}
                            onSelect={(value: string) => onSelectPaycode(value)}
                            disabled={
                              (!isHoliday && scheduledVarianceHours === 0) ||
                              (isPtoPaycodeSelected &&
                                !(
                                  employee.plantType === 'integrated_mill' ||
                                  employee.plantType === 'office'
                                ))
                            }
                          >
                            <Select.Button
                              label={
                                data.scheduleVariance.payCode !== null
                                  ? String(data.scheduleVariance.payCode)
                                  : ''
                              }
                            />

                            <Select.Options isWidthAuto={true}>
                              <Select.Option truncate value="">
                                <span className="py-2 block"></span>
                              </Select.Option>
                              {isHoliday &&
                              scheduledVarianceHours === 0 &&
                              employee.plantType === 'mini_mill' ? (
                                <Select.Option value="HB">
                                  HB - Holiday Block - No Pay
                                </Select.Option>
                              ) : isHoliday &&
                                scheduledVarianceHours === 0 &&
                                employee.plantType !== 'mini_mill' ? (
                                <Select.Option value="HS">
                                  HS - Holiday Scheduled - Not Worked
                                </Select.Option>
                              ) : (
                                filteredTimecardPayCodeArr &&
                                filteredTimecardPayCodeArr.items.length > 0 &&
                                filteredTimecardPayCodeArr.items.map(
                                  (pc, id) => (
                                    <Select.Option
                                      truncate
                                      key={id}
                                      value={pc.code}
                                    >
                                      <span>
                                        {pc.code} - {pc.description}
                                      </span>
                                    </Select.Option>
                                  )
                                )
                              )}
                            </Select.Options>
                          </Select>
                          {approvedStatus && (
                            <p className="text-xs">Approved</p>
                          )}
                          {requestedStatus && (
                            <p className="text-xs">Requested</p>
                          )}
                        </>
                      ) : (
                        <span>{data.scheduleVariance.payCode ?? '-'}</span>
                      )}
                    </span>
                  </div>
                  <div className="flex flex-col justify-start">
                    <span className="text-xs text-gray-600 ">Hours</span>
                    <span className="text-sm">{scheduledVarianceHours}</span>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <div className="grid grid-cols-2 gap-y-4">
                  <div className="flex flex-col justify-start">
                    <span className="text-xs text-gray-600 ">
                      Paid Not Worked Hrs
                    </span>
                    <span className="text-sm">
                      {data.paidHoursNotWorked ?? '-'}
                    </span>
                  </div>
                  <div className="flex flex-col justify-start">
                    <span className="text-xs text-gray-600 ">Elapsed Time</span>
                    <span className="text-sm">{data.elapsedTime ?? '-'}</span>
                  </div>
                  <div className="flex flex-col justify-start">
                    <span className="text-xs text-gray-600 ">
                      Exception Code
                    </span>
                    {data.exceptionCode ? (
                      <Tooltip
                        placement="bottom"
                        tooltipContent={
                          <span aria-label="Exception Code Description">
                            {data.exceptionCodeDescription}
                          </span>
                        }
                        clickTrigger
                        className="w-max"
                      >
                        <span
                          className=" text-accent2-500 text-sm"
                          aria-label="Exception Code"
                        >
                          {data.exceptionCode}
                        </span>
                      </Tooltip>
                    ) : (
                      <span className="text-sm">{'-'}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Panel>
          <Accordion.Button
            openLabel="View Less"
            closeLabel="View More"
            variant="Link"
            onClick={checkPanelState}
          ></Accordion.Button>
        </Accordion>
      </div>
    </>
  );
};

export default TimecardCard;
