import { useMutation } from '@tanstack/react-query';
import { notify } from 'components/Notification/useNotification';
import { WMSError } from 'types/common';
import { axios } from '../../../services/api/axios';
import { SendEmailPayload, SendEmailResponse } from '../types';

const sendEmail = (body: SendEmailPayload): Promise<SendEmailResponse> => {
  return axios.post(`/v1/payperiods/email-annual-pay-cycles`, body);
};

export const useSendEmail = () => {
  return useMutation<SendEmailResponse, WMSError, SendEmailPayload>({
    mutationFn: (body: SendEmailPayload) => sendEmail(body),

    onSuccess: () => {
      notify.success({
        message: 'You have successfully sent the email.',
      });
    },
    meta: {
      errorMessage: 'Unable to send email.',
    },
  });
};
