import { Button, Switch } from '@uss/react-components';
import Card from 'components/Card/Card';
import Title from 'components/Typography/Title';
import { EffExpDate } from 'features/crew-maintenance/components/common/EffExpDate';
import { SecurityRecord } from 'features/security';
import { BsArrow90DegRight, BsTrash, BsXLg } from 'react-icons/bs';

interface CrewCardProps {
  crew: SecurityRecord;
  primary: boolean;
  handleRemove: (crewId: string, crew: string) => void;
  handleCancelMove: (value: SecurityRecord) => void;
  disabled: boolean;
  showCancelMove: boolean;
  onSwitch: (value: boolean, orgId: string) => void;
  openMoveModal: () => void;
  isFutureEffectiveDate: boolean;
}

export const AssignmentCrewCard = ({
  crew,
  primary = false,
  handleRemove,
  handleCancelMove,
  disabled = false,
  showCancelMove = false,
  onSwitch,
  openMoveModal,
  isFutureEffectiveDate,
}: CrewCardProps) => {
  const crewKey =
    crew.location && crew.division && crew.department && crew.crew
      ? `${crew.location}-${crew.division}-${crew.department}-${crew.crew}`
      : '';

  return (
    <>
      <Card>
        <Card.Header>
          <div className="flex items-center w-full">
            <div className="flex flex-col flex-wrap flex-grow">
              <Title as="h2">{crewKey}</Title>
              <div className="flex justify-between">
                <p
                  aria-label="Crew Plant"
                  className="text-gray-700 text-sm text-left line-clamp-1"
                >
                  {crew.plant ? crew.plant : '-'}
                </p>
                <EffExpDate
                  member={crew}
                  isFutureEffectiveDate={isFutureEffectiveDate}
                />
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="flex flex-wrap items-center w-full mb-4">
            <span className="w-1/2">
              <p className="text-gray-700 text-xs text-left">
                Set as Primary Crew
              </p>
            </span>
            <span className="w-1/2 flex justify-end">
              <Switch>
                <Switch.Label isDisabled={disabled}>
                  {primary ? 'Yes' : 'No'}
                </Switch.Label>
                <Switch.Handle
                  enabled={primary}
                  onChange={(value: boolean) => {
                    onSwitch(value, crew.orgId);
                  }}
                  isDisabled={disabled}
                ></Switch.Handle>
              </Switch>
            </span>
          </div>
        </Card.Body>
        <Card.Footer>
          {!showCancelMove ? (
            <div className="flex w-full">
              <div className="flex w-1/2 justify-center">
                <Button
                  variant="text"
                  className="text-sm text-primary-400 cursor-pointer"
                  onClick={() => {
                    openMoveModal();
                  }}
                  disabled={
                    disabled ||
                    isFutureEffectiveDate ||
                    (crew.expirationDate !== null && crew.newCrew === null)
                  }
                >
                  <BsArrow90DegRight className="text-sm" />
                  <span className="text-xs ml-2"> Move</span>
                </Button>
              </div>
              <div className="bg-gray-300 w-0.5 mx-6"></div>
              <div className="flex w-1/2  justify-center">
                <Button
                  variant="text"
                  className="text-sm text-red-600 cursor-pointer"
                  onClick={() => {
                    handleRemove(crew.id, crew.crew ? crew.crew : '--');
                  }}
                  disabled={
                    disabled ||
                    isFutureEffectiveDate ||
                    (crew.expirationDate !== null && crew.newCrew === null)
                  }
                >
                  <BsTrash id="delete" className="text-sm" />
                  <span className="text-xs ml-2"> Remove</span>
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex w-full justify-center">
              <Button
                variant="text"
                className="text-sm text-primary-400 cursor-pointer"
                onClick={() => {
                  handleCancelMove(crew);
                }}
                disabled={disabled}
              >
                <BsXLg
                  id="delete"
                  className="text-sm mx-auto my-0 text-red-600"
                />
                <span className="text-xs ml-2 text-red-600"> Cancel Move</span>
              </Button>
            </div>
          )}
        </Card.Footer>
      </Card>
    </>
  );
};
