import { OrgSecurityRecord } from 'features/org';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { axios } from './axios';
import {
  OrgSecurityList,
  OrgSecurityOptions,
  UseCrewMembersOptions,
  UsePrimaryPayApproverOptions,
  WMSError,
} from 'types/common';
import { ROLECODES } from 'features/roles';

/**
 * API Response when requesting a list of org members
 */

/**
 *
 * @param orgId The id of the org that you wish to get members for.
 *
 * This id must be an ID of a crew, if you send the id of a different type of org, such as a division or department, the list will return empty.
 *
 * @param options Option parameters that can be sent to the API
 *
 * @description Fetches the list of crew members for a given crew
 *
 * @example
 * // Get Default Members
 * getCrewMembers('c44523-d3t43')
 * // Get Default Members that have this is their primary crew
 * getCrewMembers('c44523-d3t43', {primary: 'y'})
 *
 *
 */

const getOrgSecurity = (
  orgId: string,
  options: OrgSecurityOptions
): Promise<OrgSecurityList> => {
  return axios.get(`/v1/orgs/${orgId}/security`, {
    params: options,
  });
};

/**
 * @description Hook used to return a list of crew members of a specific crew.
 *
 * @param orgId ID of the crew (org)
 * @param options API options
 * @example
 * // Returns list of crew members
 * const {data, status} = useCrewMembers('123-ABC');
 * // Returns list of crew members that have this crew set as their primary crew
 * const {data, status} = useCrewMembers('123-ABC', {primary: 'y' });
 * // Returns list of crew members that have 'Steve' in their name
 * const {data, status} = useCrewMembers('123-ABC', {search: 'Steve' });
 */

export const useCrewMembers = (
  orgId: string,
  options?: UseCrewMembersOptions
) => {
  return useQuery<OrgSecurityList>({
    queryFn: () =>
      getOrgSecurity(orgId, {
        ...options,
        role: ROLECODES.CREW_MEMBER,
      }),
    queryKey: ['crew-members', orgId, { ...options }],
    placeholderData: keepPreviousData,
  });
};

/**
 *
 * @param orgId ID of the Org
 * @param options API Options
 * @example
 *
 * // Returns list of pay approvers, this includes primary and secondary approvers for the org
 * const {data, status} = useOrgPayApprovers('123-ABC');
 * // Returns list of primary pay approvers for the org
 * const {data, status} = useOrgPayApprovers('123-ABC', {primary: 'y' });
 *
 */

export const useOrgPayApprovers = (
  orgId: string,
  options?: UseOrgPayApproversOptions
) => {
  return useQuery<OrgSecurityList>({
    queryFn: () =>
      getOrgSecurity(orgId, {
        ...options,
        role: ROLECODES.PAY_APPROVER,
      }),
    queryKey: [`org-pay-approvers`, orgId, { ...options }],
    enabled: options?.search?.length !== 0,
  });
};
type UseOrgPayApproversOptions = Omit<OrgSecurityOptions, 'role'>;

/**
 *
 * @param orgId orgId of the org that you wish to get the primary pay approver for
 * @param options
 * @example
 * // Returns the primary approver of the given org
 * const {data, status} = usePrimaryPayApprover('12345t')
 */

export const usePrimaryPayApprover = (
  orgId: string,
  options?: UsePrimaryPayApproverOptions
) => {
  return useQuery<OrgSecurityList, WMSError, OrgSecurityRecord>({
    queryKey: [`primary-approver-${orgId}`, orgId],
    queryFn: () =>
      getOrgSecurity(orgId, {
        ...options,
        primary: 'y',
        role: ROLECODES.PAY_APPROVER,
      }),
    select: (data) => data.items[0],
    enabled: !!orgId,
  });
};
