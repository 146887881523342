import { Org } from 'features/org';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface StoreState {
  selectedPlant: Org | null;
  selectedPayrollLocation: Org | null;
  selectedDivision: Org | null;
  selectedDepartment: Org | null;
  crew: string | null;
  crewDescription: string | null;
  comments: string;
  setPlant: (value: Org) => void;
  setPayrollLocation: (value: Org) => void;
  setDivision: (value: Org | null) => void;
  setDepartment: (value: Org | null) => void;
  setCrew: (value: string | null) => void;
  setCrewDescription: (value: string | null) => void;
  setComments: (value: string) => void;
  clearSearch: () => void;
}
export const useAddCrewStore = create<StoreState>()(
  devtools(
    immer((set) => ({
      selectedPlant: null,
      selectedPayrollLocation: null,
      selectedDivision: null,
      selectedDepartment: null,
      crew: '',
      crewDescription: '',
      comments: '',
      setPlant: (value) =>
        set(
          (state) => {
            state.selectedPlant = value;
            state.selectedPayrollLocation = null;
            state.selectedDivision = null;
            state.selectedDepartment = null;
          },
          false,
          'update-plant'
        ),
      setPayrollLocation: (value) =>
        set(
          (state) => {
            state.selectedPayrollLocation = value;
            state.selectedDivision = null;
            state.selectedDepartment = null;
          },
          false,
          'update-location'
        ),
      setDivision(value) {
        set(
          (state) => {
            state.selectedDivision = value;
            state.selectedDepartment = null;
          },
          false,
          'update-division'
        );
      },
      setDepartment(value) {
        set(
          (state) => {
            state.selectedDepartment = value;
          },
          false,
          'update-department'
        );
      },
      setCrew(value) {
        set(
          (state) => {
            state.crew = value;
          },
          false,
          'update-crew'
        );
      },
      setCrewDescription(value) {
        set(
          (state) => {
            state.crewDescription = value;
          },
          false,
          'update-crewdescription'
        );
      },
      setComments(value) {
        set(
          (state) => {
            state.comments = value;
          },
          true,
          'update-crew'
        );
      },
      clearSearch: () =>
        set(
          (state) => {
            state.selectedPlant = null;
            state.selectedPayrollLocation = null;
            state.selectedDivision = null;
            state.selectedDepartment = null;
            state.crew = '';
            state.crewDescription = '';
            state.comments = '';
          },
          false,
          'clear-search'
        ),
    }))
  )
);
