import { Org } from 'features/org';
import { SortDirection } from 'types/common';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { PtoSort, PTO_SORT } from '../constants';

import { PtoRequestsOptions } from '../types/pto-requests';

interface PtoRequestsState {
  params: PtoRequestsOptions;
  orgId: string;
  selectedPlant: Org | null;
  selectedPayrollLocation: Org | 'all' | null;
  selectedDivision: Org | 'all' | null;
  selectedDepartment: Org | 'all' | null;
  selectedCrew: Org | 'all' | null;
  CompletedPage: string;
  CompletedPageSize: string;
  setPage: (value: number) => void;
  setPageSize: (value: number) => void;
  setCompletedPage: (value: number) => void;
  setCompletedPageSize: (value: number) => void;
  setSort: (val: { type: PtoSort; direction: SortDirection }) => void;
  setPlant: (value: Org) => void;
  setPayrollLocation: (value: Org | 'all') => void;
  setDivision: (value: Org | 'all' | null) => void;
  setDepartment: (value: Org | 'all' | null) => void;
  setCrew: (value: Org | 'all' | null) => void;
  setOrgId: (value: string) => void;
  setDate: (value: string) => void;
}

export const usePtoRequestsStore = create<PtoRequestsState>()(
  devtools(
    immer((set, get) => ({
      params: {
        page: '1',
        pageSize: '9',
        date: '',
        status: 'requested',
        retrieveFuturePto: 'Y',
        sortBy: PTO_SORT.PTO_DATE,
        sortType: SortDirection.ASC,
      },
      orgId: '',
      selectedPlant: null,
      selectedPayrollLocation: null,
      selectedDivision: null,
      selectedDepartment: 'all',
      selectedCrew: 'all',
      CompletedPage: '1',
      CompletedPageSize: '9',
      setPlant: (value) =>
        set(
          (state) => {
            state.selectedPlant = value;
            state.selectedPayrollLocation = 'all';
            state.selectedDivision = 'all';
            state.selectedDepartment = 'all';
            state.selectedCrew = 'all';
            // state.ussids = [];
            state.orgId = value.id;
            state.params.date = '';
            state.CompletedPage = '1';
            state.params.page = '1';
          },
          false,
          'update-plant'
        ),
      setPayrollLocation: (value) =>
        set(
          (state) => {
            state.selectedPayrollLocation = value;
            state.selectedDivision = 'all';
            state.selectedDepartment = 'all';
            state.selectedCrew = 'all';
            state.CompletedPage = '1';
            state.params.page = '1';
            if (value !== 'all') {
              state.orgId = value.id;
            }
            // state.ussids = [];
          },
          false,
          'update-location'
        ),
      setDivision(value) {
        set(
          (state) => {
            state.selectedDivision = value;
            state.selectedDepartment = 'all';
            state.selectedCrew = 'all';
            state.CompletedPage = '1';
            state.params.page = '1';
            if (value && value !== 'all') {
              state.orgId = value.id;
            }

            // state.ussids = [];
          },
          false,
          'update-division'
        );
      },
      setDepartment(value) {
        set(
          (state) => {
            state.selectedDepartment = value;
            state.selectedCrew = 'all';
            state.CompletedPage = '1';
            state.params.page = '1';
            if (value && value !== 'all') {
              state.orgId = value.id;
            }

            // state.ussids = [];
          },
          false,
          'update-department'
        );
      },
      setCrew(value) {
        set(
          (state) => {
            state.selectedCrew = value;
            state.CompletedPage = '1';
            state.params.page = '1';
            if (value && value !== 'all') {
              state.orgId = value.id;
            }
            // state.ussids = [];
          },
          false,
          'set-crew'
        );
      },
      setOrgId: (value) =>
        set(
          (state) => {
            state.orgId = value;
          },
          false,
          'set-orgId'
        ),
      setPage: (value) =>
        set(
          (state) => {
            state.params.page = value.toString();
          },
          false,
          'setPage'
        ),
      setPageSize: (value: number) =>
        set(
          (state) => {
            state.params.pageSize = value.toString();
            state.params.page = '1';
          },
          false,
          'setPageSize'
        ),
      setCompletedPage: (value) =>
        set(
          (state) => {
            state.CompletedPage = value.toString();
          },
          false,
          'setCompletedPage'
        ),
      setCompletedPageSize: (value: number) =>
        set(
          (state) => {
            state.CompletedPageSize = value.toString();
            state.CompletedPage = '1';
          },
          false,
          'setCompletedPageSize'
        ),
      setSort: (value) =>
        set(
          (state) => {
            state.params.sortBy = value.type;
            state.params.sortType = value.direction;
            state.params.page = '1';
          },
          false,
          'set-sort'
        ),
      setDate: (value) =>
        set((state) => {
          state.params.date = value;
        }),
    }))
  )
);
