import React from 'react';
import Card from 'components/Card/Card';
import HelpfulHintsSVG from 'components/svgs/home/HelpfulHintsSVG';
import { HelpFulHintListType } from 'types/homepage';
import { NavLink } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from '@uss/react-components';

interface HelpfulHintsProps {
  helpfulHintsData: HelpFulHintListType | undefined;
}

const HelpfulHints = ({ helpfulHintsData }: HelpfulHintsProps) => {
  return (
    <Card>
      <Card.Header headerbgvariant="persiangreenblue">
        <h2 className="text-white text-lg flex items-center">Helpful Hints</h2>

        <Link
          as={NavLink}
          className="flex flex-row justify-center items-center"
          to="helpful-hints"
        >
          <span className="sr-only">View all helpful hints</span>
          <BsArrowRight className="text-white " size="2rem" />
        </Link>
      </Card.Header>
      <Card.Body>
        <div className="grid gap-4 grid-flow-row md:grid-flow-col">
          <div className="md:col-start-1 md:col-span-4  justify-center items-center md:flex hidden">
            <HelpfulHintsSVG />
          </div>
          <div className="md:col-start-5 md:col-span-8 flex  items-center md:px-6  md:border-t-0 md:border-l  border-gray-300 ">
            <ul className="marker:text-accent1-500 marker:text-lg list-outside list-disc ml-4 md:mt-4 sm:mb-4 text-sm text-gray-700">
              {helpfulHintsData?.items
                .filter((r) => r.active)
                .map((hint, index) => {
                  return (
                    <li key={`helpful-${index}`}>
                      {hint.new && (
                        <span className="mr-2 text-xs rounded-lg px-2 bg-accent1-50 text-gray-700">
                          New
                        </span>
                      )}
                      <span
                        dangerouslySetInnerHTML={{ __html: hint.hint }}
                      ></span>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default HelpfulHints;
