import { Datepicker } from '@uss/react-components';
import { addYears, endOfYear, parse, startOfYear, subYears } from 'date-fns';
import { useCrewPtoStore } from 'features/paid-time-off/state';
import useWindowSize from 'hooks/useWindowSize';
import React from 'react';
import { appNewDate } from 'utils/appNewDate';

const CrewDateFilter = () => {
  const { width } = useWindowSize();

  const { date, updateDate } = useCrewPtoStore((state) => ({
    date: state.date,
    updateDate: state.updateDate,
  }));

  const minDate = startOfYear(subYears(new Date(), 1));
  const maxDate = endOfYear(addYears(new Date(), 1));

  const parsedDate = parse(date, 'yyyy-MM', appNewDate());

  return (
    <Datepicker
      startDate={parsedDate}
      onDateChange={(date) => {
        updateDate(date);
      }}
      calendarType={'month'}
      id="pto-date-filter"
      minDate={minDate}
      maxDate={maxDate}
      data-testid="ptoDateFilter"
      isMobile={width && width < 1024 ? true : false}
    />
  );
};

export default CrewDateFilter;
