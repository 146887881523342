import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  SecurityRecord,
  useDeleteSecurityById,
  useEmployeeSecurity,
  AddSecurityRecord,
  SecurityRecordCard,
} from 'features/security';
import CardsLoader from 'components/Loaders/CardsLoader';
import useModal from 'components/Modals/use-modal';
import CardList from 'components/Card/CardList';
import { SortDirection } from 'types/common';
import { Person } from 'features/people';

interface Props {
  employeeData: Person | undefined;
}

const SecurityRecords = ({ employeeData }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { id: ussId = '' } = useParams<'id'>();
  const { data, status } = useEmployeeSecurity(ussId, {
    page: 1,
    pageSize: 100,
    sortBy: 'securityCreateDate',
    sortType: SortDirection.ASC,
  });

  const deleteSecurity = useDeleteSecurityById(ussId);
  const modal = useModal();

  const handleRemoveSecurity = async (id: string) => {
    await deleteSecurity.mutateAsync(id);
    modal.closeModal();
  };

  const handleRemoveRecord = (row: SecurityRecord) => {
    modal.openModal({
      title: 'Remove Record',
      type: 'danger',
      children: (
        <p className="text-sm text-gray-700">
          {`Are you sure you want to remove the ${row.roleName} role for ${
            row.location
              ? `${row.location}-${row.division ?? 'All'}-${
                  row.department ?? 'All'
                }-${row.crew ?? 'All'}`
              : `Plant ${row.plant ?? 'All'}`
          }?`}
        </p>
      ),
      labels: { confirm: 'Remove', cancel: 'Cancel' },
      onCancel: () => modal.closeModal(),
      onConfirm: () => handleRemoveSecurity(row.id),
    });
  };
  return (
    <div>
      <div className="flex items-center justify-end py-4">
        <AddSecurityRecord isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>

      {status === 'pending' && <CardsLoader count={9} />}
      {status === 'success' && (
        <div>
          <CardList<SecurityRecord>
            items={data.items}
            renderItem={(record) => (
              <SecurityRecordCard
                record={record}
                onRemove={() => handleRemoveRecord(record)}
                employeeData={employeeData}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

export default SecurityRecords;
