import { useEmployeeRemovedPTO } from 'features/paid-time-off/api/queries';
import { PTOCardList } from '../PTOCardList';

interface RemovedRecordsProps {
  ussId: string;
  year: string;
}

const PtoRemovedRecords = ({ ussId, year }: RemovedRecordsProps) => {
  const { data, isLoading } = useEmployeeRemovedPTO(ussId, { year: year });

  return (
    <div className="mt-4">
      <PTOCardList records={data?.items} loading={isLoading} />
    </div>
  );
};

export default PtoRemovedRecords;
