import { SearchInput } from '@uss/react-components';
import CardList from 'components/Card/CardList';
import Page from 'components/Layouts/Page';
import CardsLoader from 'components/Loaders/CardsLoader';
import Pagination from 'components/Pagination/Pagination';
import EmployeeSearchCard from 'features/paid-time-off/components/Cards/EmployeeSearchCard';
import { useEmployeeListStore } from 'features/paid-time-off/state/search-employee-store';
import { Person } from 'features/people';
import { ORG_TYPE } from 'features/org';
import { useEmployeeOrgs } from 'features/org/api';

import OrgFilters from 'components/OrgFilters/OrgFilters';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useUserCrewMembers } from 'features/user/api/queries';

const Employees = () => {
  //creating store
  const store = useEmployeeListStore();
  const params = store.params;

  //plant selection
  const { data: plantList, status: plantStatus } = useEmployeeOrgs('me', {
    type: ORG_TYPE.PLANT,
    sortBy: 'plant',
    parentOrgs: 'y',
    context: 'summary',
  });

  //location selection
  const { data: locationList, status: locationStatus } = useEmployeeOrgs('me', {
    type: ORG_TYPE.LOCATION,
    sortBy: 'location',
    parentOrgId:
      !store.params.plant || store.selectedPlant === 'all'
        ? undefined
        : store.selectedPlant?.id,
    parentOrgs: 'y',
    context: 'summary',
  });

  useEffect(() => {
    if (plantStatus === 'success' && plantList.items.length === 1) {
      store.setPlant(plantList.items[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantStatus]);
  const { data, status } = useUserCrewMembers('PTO', 'me', params);

  const [searchValue, setSearchValue] = useState<string>(
    params.search ? params.search : ''
  );

  const handleClose = () => {
    setSearchValue('');
    store.setClearSearch();
  };
  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    store.setSearch(value);
  };

  return (
    <Page
      heading="Employee Search (NU)"
      breadcrumbs={[
        <NavLink
          key="bc-1"
          className="flex flex-row justify-center items-center"
          to={`/paid-time-off`}
        >
          PTO - Vacation
        </NavLink>,
      ]}
    >
      <Page.Section bg="gray">
        <div>
          <div className="w-full">
            <div className="w-full md:grid grid-cols-3 gap-2  ">
              <SearchInput
                id="search-input"
                label="search"
                placeholder="Search Name / USS ID"
                value={searchValue}
                autoCompleteFlag={searchValue ? true : false}
                selectedFlag={searchValue ? true : false}
                onChange={(e) => {
                  handleSearchChange(e.currentTarget.value);
                }}
                onClose={handleClose}
              />
            </div>
          </div>

          <div className="mt-2 sm:mt-4 "></div>
          <OrgFilters
            plants={plantList?.items ? plantList.items : []}
            orgs={locationList?.items ? locationList.items : []}
            status={locationStatus}
            plantStatus={plantStatus}
            selectedOrgs={{
              selectedPlant: store.selectedPlant,
              selectedPayrollLocation: store.selectedPayrollLocation,
            }}
          >
            <OrgFilters.Plant
              selectedItem={store.selectedPlant}
              handleSelect={store.setPlant}
              showSelectAll={
                plantList?.items && plantList.items.length > 1 ? true : false
              }
            />
            <OrgFilters.Location
              selectedItem={store.selectedPayrollLocation}
              handleSelect={store.setPayrollLocation}
              showSelectAll={true}
            />
          </OrgFilters>
        </div>
      </Page.Section>
      <Page.Section>
        {status === 'pending' && (
          <CardsLoader variant="row-grid" count={9} hasAvatar />
        )}
        {status === 'success' && (
          <CardList<Person>
            items={data.items}
            variant="row-grid"
            renderItem={(employee) => <EmployeeSearchCard person={employee} />}
          />
        )}
      </Page.Section>
      <Page.Section bg="gray">
        {!!data?.totalCount && (
          <Pagination
            pageOptions={[9, 18, 27, 36, 45, 54]}
            totalCount={data.totalCount}
            page={data.page}
            pageSize={data.pageSize}
            onPageChange={(s) => {
              store.setPage((s.selected + 1).toString());
            }}
            onPageSizeChange={(ps) => {
              store.setPageSize(ps.toString());
            }}
          />
        )}
      </Page.Section>
    </Page>
  );
};

export default Employees;
