const getAdjustmentTitle = (value: number | null) => {
  switch (value) {
    case null:
      return 'No Adjustments Available';
    case 0:
      return 'Original';
    default:
      return `Adjustment ${value}`;
  }
};
export default getAdjustmentTitle;
