import { Autocomplete, Button, Drawer, Select } from '@uss/react-components';
import { format, parseISO } from 'date-fns';
import { useAddCrewMember } from 'features/crew-maintenance/api';
import { OrgMemberCreateBody } from 'features/crew-maintenance/types';
import { ORG_LOCATION_TYPE, ORG_SECURITY_PRIMARY } from 'features/org';
import { useOrgDetails } from 'features/org/api';
import { useSearchPeople } from 'features/people/api';
import { PeopleOptions, Person, PERSON_STATUS } from 'features/people/types';
import { ROLECODES } from 'features/roles';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SelectedEmployeeDetails } from './SelectedEmployeeDetails';
import { SuggestionsList } from './SuggestionList';

interface Props {
  crewMemberCount: number;
  effectiveDate: string | undefined;
  open: boolean;
  setOpen: (val: boolean) => void;
}
export const AddEmployee = ({
  crewMemberCount,
  effectiveDate,
  open,
  setOpen,
}: Props) => {
  const { crewId = '' } = useParams<'crewId'>();
  const { data: orgDetails } = useOrgDetails(crewId);

  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState<Person | null>(null);
  const sortOrderArr: number[] = [];

  const crewKey =
    orgDetails?.location &&
    orgDetails.division &&
    orgDetails.department &&
    orgDetails.crew
      ? orgDetails.location +
        '-' +
        orgDetails.division +
        '-' +
        orgDetails.department +
        '-' +
        orgDetails.crew
      : '-';

  if (crewMemberCount > 0) {
    for (let index = 0; index <= crewMemberCount; index++) {
      sortOrderArr.push(index);
    }
  } else {
    sortOrderArr.push(1);
  }

  const [sortOrder, setSortOrder] = useState<number | null>(null);
  const searchPayload: PeopleOptions = {
    search: inputValue,
    page: '1',
    pageSize: '50',
    plant: orgDetails?.plantCode,
    primaryCrew: ORG_SECURITY_PRIMARY.YES,
    status: `${PERSON_STATUS.ACTIVE},${PERSON_STATUS.INACTIVE},${PERSON_STATUS.PENDING}`,
    excludeEmpClass:
      orgDetails?.plantType === 'mini_mill'
        ? '4'
        : orgDetails?.crew === 'SPECOT'
        ? '0,3,6'
        : undefined,
  };

  const { mutateAsync: addEmployee } = useAddCrewMember(crewId);

  const { data: filteredOptions, status } = useSearchPeople(searchPayload);

  const clearAll = () => {
    setInputValue(() => '');
    setSelectedOption(null);
    setSortOrder(null);
  };
  const onCancelSearch = (): void => {
    clearAll();
  };
  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.currentTarget.value);
    if (e.currentTarget.value === '') {
      clearAll();
    }
  };
  const handleSelect = (option: Person): void => {
    setInputValue(option.lastName);
    setSelectedOption(option);
  };

  const payload = {
    ussId: selectedOption?.ussId,
    sortOrder: sortOrder,
    role: ROLECODES.CREW_MEMBER,
  };

  const handleAddButton = async (): Promise<void> => {
    await addEmployee(payload as OrgMemberCreateBody);
    clearAll();
    setOpen(false);
  };

  //   clearAll();
  //   setIsOpen(false);
  // };
  const handleSortOrder = (value: number | null) => {
    setSortOrder(value);
  };

  return (
    <>
      <Drawer
        placement={window.innerWidth > 768 ? 'right' : 'bottom'}
        title="Add Employee"
        isOpen={open}
        onClose={(): void => {
          clearAll();
          setOpen(false);
        }}
        closeButton={window.innerWidth > 768 ? false : true}
        className="w-full sm:w-1/2 md:w-1/3 xl:w-1/4 2xl:w-1/5 md:top-0 top-18 md:rounded-t-none rounded-t-md"
      >
        <div className="flex flex-col">
          <span aria-label="crew" className="text-xs text-gray-700 font-normal">
            Crew
          </span>
          <span
            aria-label="crew-key"
            className="text-gray-900 text-base font-semibold"
          >
            {crewKey}
          </span>
        </div>
        <div className="flex-grow">
          <div className="py-2 ">
            <Autocomplete
              onClose={onCancelSearch}
              label="Auto"
              value={inputValue}
              onChange={handleFilter}
              placeholder="Search Name / USSID"
            >
              <SuggestionsList
                numberOfSearchChars={inputValue.length}
                options={filteredOptions}
                onSelect={handleSelect}
                status={status}
              />
            </Autocomplete>
          </div>

          {orgDetails &&
            orgDetails.locationType !== ORG_LOCATION_TYPE.NONUNION && (
              <div className="w-1/2">
                <span className="text-xs text-gray-700 font-normal">
                  Sort Order
                </span>
                <Select selected={null} onSelect={handleSortOrder}>
                  <Select.Button
                    label={sortOrder ? sortOrder.toString() : ''}
                  />
                  <Select.Options>
                    <Select.Option value={null}>
                      <span className="py-2 block"></span>
                    </Select.Option>
                    {sortOrderArr.map((item: number, index: number) => (
                      <Select.Option truncate key={index + 1} value={index + 1}>
                        <span>{index + 1}</span>
                      </Select.Option>
                    ))}
                  </Select.Options>
                </Select>
              </div>
            )}
          <SelectedEmployeeDetails employee={selectedOption} />
          <div
            className="flex flex-col  w-full border-t border-gray-300 mt-6 "
            data-testid="effective-date-container"
          >
            <span className="text-xs text-gray-700 font-normal mt-4">
              Effective Date
            </span>
            <span className="text-sm text-gray-900 font-semibold">
              {effectiveDate
                ? format(parseISO(effectiveDate), 'MMM dd, yyyy')
                : '--'}
            </span>
          </div>
        </div>
        <Drawer.Controls>
          <Button
            className="md:mb-0 mb-2 w-full"
            variant="outline"
            onClick={(): void => {
              clearAll();
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="md:ml-4 w-full"
            disabled={!selectedOption}
            onClick={handleAddButton}
          >
            Add
          </Button>
        </Drawer.Controls>
      </Drawer>
    </>
  );
};
