import { PayrollGroups } from '../types';

export const ShiftAssignmentRequiredCheck = (data: PayrollGroups[]) => {
  let isShiftAssignmentChecked = true;
  data.map((e) => {
    if (e.active) {
      if (!e.shifts.some((option) => option.value)) {
        isShiftAssignmentChecked = false;
      }
    }
    return isShiftAssignmentChecked;
  });
  return isShiftAssignmentChecked;
};
