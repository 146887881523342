const getPolicySummaryHeaderTitle = (value: number, isTotalHoursCard: boolean | undefined) => {
  if (isTotalHoursCard) return 'Updated Timecard';
  switch (value) {
    case 0:
      return 'Original Timecard';
    default:
      return `Adjustment ${value} Changes`;
  }
};
export default getPolicySummaryHeaderTitle;
