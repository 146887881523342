import { ApiBasicStatusResponse, WMSError } from 'types/common';
import { mileageEntry } from './api';
import {
  CreateMileageRequest,
  MileageActionPayload,
  MileageActionsVal,
  MileageDetailsResponse,
  RemoveMileageQueryParams,
} from '../types/mileageEntry';
import { useMutation } from '@tanstack/react-query';
import { notify } from 'components/Notification/useNotification';
import { queryClient } from 'services/api/react-query';

export const useCreateMileage = () => {
  return useMutation<ApiBasicStatusResponse, WMSError, CreateMileageRequest>({
    mutationFn: (body: CreateMileageRequest) => mileageEntry.post(body),
  });
};

export const useRemoveMileage = () => {
  return useMutation<
    MileageDetailsResponse,
    WMSError,
    RemoveMileageQueryParams
  >({
    mutationFn: (options: RemoveMileageQueryParams) =>
      mileageEntry.delete(options.mileageDetailsId),
  });
};

export const useUpdateMileage = (
  ussId: string,
  action: MileageActionsVal | '',
  user: string,
  total?: number
) => {
  //Changing APPROVED to Approved
  let status = action.toLowerCase();
  status = status.charAt(0).toUpperCase() + status.slice(1);
  return useMutation<MileageDetailsResponse, WMSError, MileageActionPayload>({
    mutationFn: (body: MileageActionPayload) => mileageEntry.patch(body),
    onSuccess: async () => {
      //For Single User
      let successMessage = `You have ${status} mileage entry for ${user}.`;
      //For Approve All
      if (user === 'all') {
        successMessage = `You have approved all the ${
          total ?? 0
        } records in the page successfully`;
      }
      notify.success({
        message: successMessage,
      });
      await queryClient.resetQueries({
        queryKey: ['mileage-details'],
        type: 'active',
      });
    },
  });
};
