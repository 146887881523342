import _ from 'lodash';
import { ZodErrorType } from 'types/common';
import { getValidationErrors } from 'utils/getValidationError';

const useValidateForm = <T,>(
  zodSchema: unknown,
  setErrors: React.Dispatch<React.SetStateAction<ZodErrorType>>
) => {
  const trimValues = (data: T) => {
    const obj = data as Record<string, unknown>;
    const values = Object.values(obj);
    const keys = Object.keys(obj);
    values.forEach((value, index) => {
      if (typeof value === 'string') {
        const keyObj = keys[index];
        obj[keyObj] = value.trim();
      }
    });

    return obj;
  };
  /* The error thrown by ZOD contains all the validation failures that were encountered. */
  const validateForm = (data: T) => {
    setErrors({});

    const errors = getValidationErrors(data, zodSchema);
    setErrors(errors);
    return _.isEmpty(errors) ? true : false;
  };
  return { validateForm, trimValues };
};

export default useValidateForm;
