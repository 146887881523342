import { z } from 'zod';
import { appNewDate } from 'utils/appNewDate';
import { format } from 'date-fns';
import { updatePtoContext, ptoStatus } from './v2';

export const UpdatePtoRequestSchema = z.object({
  paycode: z
    .string()
    .nonempty({ message: 'Pay Code is required' })
    .min(1, 'Pay Code is required')
    .max(2, 'Pay Code cannot be more than 2 characters')
    .optional(),
  ptoDate: z
    .string({
      required_error: 'Date is required',
      invalid_type_error: 'Date is required',
    })
    .min(1, 'Date is required'),
  chargeYear: z.string().default(format(appNewDate(), 'yyyy')).optional(),
  notes: z
    .string()
    .max(100, 'Note cannot be more than 100 characters')
    .nullable()
    .default(null)
    .transform((v) => {
      if (v === '') {
        return null;
      } else {
        return v;
      }
    })
    .optional(),
  status: ptoStatus.default('requested'),
  context: updatePtoContext.optional()
});
