import MultiSelect from 'components/Multiselect/Multiselect';
import { Select } from '@uss/react-components';
import { SelectDataType } from 'features/paid-time-off/types';
import { QueryStatus } from '@tanstack/react-query';

export const EmployeeMultiSelect = ({
  employees,
  selectedEmployee,
  onSelect,
  enable,
  status,
}: {
  employees: SelectDataType[];
  selectedEmployee: string[];
  onSelect: (value: string[]) => void;
  enable: boolean;
  status: QueryStatus;
}) => {
  const handleSelect = (values: string[]) => {
    onSelect(values);
  };

  return (
    <>
      {status === 'success' && employees.length > 0 ? (
        <MultiSelect
          selected={selectedEmployee}
          onSelect={handleSelect}
          disabled={!enable}
          data-testid={'multi-select-employee'}
          isMulti
        >
          <MultiSelect.Button>
            {selectedEmployee.length ? (
              <>
                <span className="line-clamp-1 w-10/12">
                  {
                    <span className="line-clamp-1 w-10/12">
                      {
                        employees
                          .filter((person) =>
                            selectedEmployee.includes(person.value)
                          )
                          .map(({ label }) => label)[0]
                      }
                    </span>
                  }
                </span>
                <span>
                  {selectedEmployee.length > 1
                    ? '+' + (selectedEmployee.length - 1).toString()
                    : ''}
                </span>
              </>
            ) : (
              `All Employees ( ${employees.length} )`
            )}
          </MultiSelect.Button>
          <MultiSelect.Options>
            {employees.map((item, itemIdx) => (
              <MultiSelect.Option
                id={item.value}
                label={item.label}
                key={itemIdx}
                value={item.value}
              />
            ))}
          </MultiSelect.Options>
        </MultiSelect>
      ) : (
        <Select
          selected={selectedEmployee}
          onSelect={handleSelect}
          className="w-full"
          disabled={true}
        >
          <Select.Button
            label={status === 'pending' ? 'Loading' : 'No Employees Available'}
          />
        </Select>
      )}
    </>
  );
};
