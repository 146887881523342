import { Checkbox, CheckboxGroup, Switch } from '@uss/react-components';
import { useEffect, useReducer } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import useWindowSize from 'hooks/useWindowSize';
import {
  Paycode,
  PayrollGroups,
  PayrollIndicators,
  ShiftAssignment,
} from 'features/paycode/types';
import { BsInfoCircle } from 'react-icons/bs';
import { PAYROLL_INDICATORS } from 'features/paycode/constants';
import { updatePayrollGroupData } from 'features/paycode/utilities/updatePayrollGroupData';
import getFormattedShiftAssignment from 'features/paycode/utilities/getFormattedShiftAssignment';

interface PayrollGroupCardProps {
  data: PayrollGroups;
  setValue: UseFormSetValue<Paycode>;
  index: number;
}

export const PayrollGroupCard = ({
  index,
  data,
  setValue,
}: PayrollGroupCardProps) => {
  const { width } = useWindowSize();
  const [paycodeData, dispatch] = useReducer(updatePayrollGroupData, data);

  const handlePayableIndicatorChecked = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name = e.target.name as PayrollIndicators;
    const checked = e.target.checked;
    dispatch({
      type: 'payrollIndicators',
      payload: {
        name: name,
        value: checked,
      },
    });
  };
  const handleShiftsChecked = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name = e.target.name;
    const checked = e.target.checked;
    dispatch({
      type: 'shifts',
      payload: {
        name: name as ShiftAssignment,
        value: checked,
      },
    });
  };
  const handleActiveChecked = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch({ type: 'active', payload: e.target.checked });
  };
  const handleRestrictedChanged = (value: boolean): void => {
    dispatch({ type: 'restricted', payload: value });
  };
  useEffect(() => {
    setValue(`payrollGroups.${index}`, paycodeData);
  }, [index, paycodeData, setValue]);
  return (
    <>
      <div className="border-gray-300 border rounded-lg w-full mb-4 p-4">
        <div>
          <p className="text-sm  text-gray-700 font-semibold">
            {data.name.split(':')[0]}
          </p>
          <p className="text-sm  text-gray-700 font-semibold mb-2">
            {data.name.split(':')[1]}
          </p>
        </div>

        <div className="lg:grid lg:grid-cols-2 w-full">
          <div className="mb-4 lg:mb-0">
            <Checkbox
              label={'Active'}
              id={'active'}
              value={'active'}
              name="active"
              checked={paycodeData.active}
              onChange={handleActiveChecked}
            />
          </div>
          <div className="flex flex-col lg:flex-row lg:items-center lg:justify-end ">
            <p className="pr-3 text-gray-700 text-xs mb-2 lg:mb-0">
              Restricted Usage (HDQ Payroll Use Only)
            </p>
            <Switch key={`switch`}>
              <Switch.Label isDisabled={false}>
                {paycodeData.restricted ? 'Yes' : 'No'}
              </Switch.Label>
              <Switch.Handle
                enabled={paycodeData.restricted}
                onChange={handleRestrictedChanged}
              ></Switch.Handle>
            </Switch>
          </div>
        </div>

        {paycodeData.active ? (
          <>
            <p className="mt-3 text-gray-600 text-xs">
              Payroll Indicators (Select any that apply)
            </p>
            <div className="text-lg ">
              <CheckboxGroup
                direction={`${width && width > 768 ? 'row' : 'col'}`}
              >
                {paycodeData.payrollIndicators.map((indicator, itemIdx) => (
                  <span
                    key={itemIdx}
                    className={`${
                      indicator.name === 'pto' ? 'uppercase' : 'capitalize'
                    }`}
                  >
                    <Checkbox
                      id={indicator.name}
                      label={indicator.name}
                      checked={indicator.value}
                      onChange={handlePayableIndicatorChecked}
                      name={indicator.name}
                      data-testid={`payrollIndicator-${indicator.name}`}
                    />
                    {indicator.name === PAYROLL_INDICATORS.PAYABLE &&
                      indicator.value && (
                        <div
                          className="flex flex-row mb-2"
                          data-testid={`payrollIndicator-info-msg`}
                        >
                          <BsInfoCircle className="text-blue-500" />{' '}
                          <span className="ml-2 text-sm text-gray-700">
                            Consider Oracle Setup
                          </span>
                        </div>
                      )}
                    {indicator.name === PAYROLL_INDICATORS.REPORT &&
                      indicator.value && (
                        <div
                          className="flex flex-row mb-2"
                          data-testid={`payrollIndicator-info-msg`}
                        >
                          <BsInfoCircle className="text-blue-500" />{' '}
                          <span className="ml-2 text-sm text-gray-700">
                            Balance Setup Required
                          </span>
                        </div>
                      )}
                  </span>
                ))}
              </CheckboxGroup>
            </div>
            <p className="mt-2 text-gray-600 text-xs">
              Shift Assignment (Select any that apply)
            </p>
            <div className="">
              <CheckboxGroup
                direction={`${width && width > 768 ? 'row' : 'col'}`}
              >
                {paycodeData.shifts.map((shift, itemIdx) => (
                  <span key={itemIdx} className="h-10">
                    <Checkbox
                      id={shift.name}
                      label={getFormattedShiftAssignment(shift.name)}
                      checked={shift.value}
                      onChange={handleShiftsChecked}
                      name={shift.name}
                      data-testid={`payrollShift-${shift.name}`}
                    />
                  </span>
                ))}
              </CheckboxGroup>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
};
