import clsx from 'clsx';
import React from 'react';

type bgColorVariants = 'bg-white' | 'bg-gray-100' | 'bg-blue-50';
type textColorVariants = 'text-gray-700';
type borderVariants = 'border border-gray-700' | 'border border-blue-600';

/**Variants for card header background */
const headerbgvariants = {
  white: 'border-b border-gray-300 mx-4 mt-4 pb-3',
  persiangreenblue:
    'bg-gradient-to-r from-accent2-600 from-5% to-accent1-500 to-95% h-10 p-4',
  lightbluewhite:
    'bg-gradient-to-r from-white from-20% to-blue-50 to-40% m-1 p-3 border-b border-blue-600',
};

/** renders a Card component 
 * @param {bgColorVariants} bgColor This is used to provide custom background color for the card
 * @param {textColorVariants} textColor This is used to provide custom text color for the card
 * @param {borderVariants} border This is used to provide custom border for the card
 * @param {string} headerbgvariant variant type for card header background
 * @example <Card>
              <Card.Header headerbgvariant="persiangreenblue">
                Card header content goes here
              </Card.Header>
              <Card.Body>
                Card body content goes here
              </Card.Body>
              <Card.Footer>
                Card footer content goes here
              </Card.Footer>
            </Card>
 */
interface CardProps {
  children: React.ReactNode;
  bgColor?: bgColorVariants;
  textColor?: textColorVariants;
  border?: borderVariants;
}
const Card = ({
  children,
  bgColor = 'bg-white',
  textColor = 'text-gray-700',
  border,
}: CardProps) => {
  return (
    <div
      className={clsx(
        'rounded-lg h-full shadow-md flex flex-col',
        bgColor,
        textColor,
        border
      )}
    >
      {children}
    </div>
  );
};
interface CardHeaderProps {
  children: React.ReactNode;
  headerbgvariant?: keyof typeof headerbgvariants;
}
Card.Header = function Header({
  children,
  headerbgvariant = 'white',
}: CardHeaderProps): JSX.Element {
  return (
    <div
      className={clsx(
        'flex justify-between rounded-tl-lg rounded-tr-lg',
        headerbgvariants[headerbgvariant]
      )}
    >
      {children}
    </div>
  );
};

interface CardBodyProps {
  children: React.ReactNode;
}
Card.Body = function Body({ children }: CardBodyProps): JSX.Element {
  return <div className="p-4">{children}</div>;
};

interface CardFooterProps {
  children: React.ReactNode;
}

const CardFooter = ({ children }: CardFooterProps): JSX.Element => {
  return <div className=" border-t border-gray-300 mx-4 py-3">{children}</div>;
};

Card.Footer = CardFooter;

export default Card;
