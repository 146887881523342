import { Button, DropdownMenu } from '@uss/react-components';
import {
  useDeletePtoRequest,
  usePutCrewPtoRecord,
  useRemovePtoBlackout,
} from 'features/paid-time-off/api/mutations';
import { ActionButton, CrewPtoDetail } from 'features/paid-time-off/types';
import {
  GetCrewParamsBody,
  GetOrgDetailsParamsBody,
} from 'features/paid-time-off/types/crew-pto';
import {
  BsCheck2,
  BsInfoCircle,
  BsThreeDotsVertical,
  BsTrash,
  BsX,
  BsXLg,
} from 'react-icons/bs';
import { useRef } from 'react';
import { getButtonDisabled } from 'features/paid-time-off/utility/getButtonDisabled';
import useModal from 'components/Modals/use-modal';
import { Link } from 'react-router-dom';
import { PTO_UPDATE_CONTEXT } from 'features/paid-time-off/constants';

interface PtoRequestActionsProps {
  placement: 'header' | 'footer';
  detailsRequest?: GetOrgDetailsParamsBody;
  pto: CrewPtoDetail;
  totalsRequest?: GetCrewParamsBody;
}

export const CrewPtoActions = ({
  placement,
  pto,
  detailsRequest,
  totalsRequest,
}: PtoRequestActionsProps) => {
  const buttonRef = useRef<ActionButton>('approved');

  const { mutateAsync: putPtoRequest } = usePutCrewPtoRecord(
    pto.ptoDetails.details[0]?.pkWfmEmpPtoDetailsId,
    buttonRef.current,
    detailsRequest,
    totalsRequest
  );

  const handleAction = async (buttonRef: ActionButton) => {
    await putPtoRequest({
      status: buttonRef,
      ptoDate: pto.date,
      chargeYear: pto.ptoDetails.details[0].chargeYear.toString(),
      paycode: pto.ptoDetails.details[0].paycode,
      context: PTO_UPDATE_CONTEXT.CREW_PTO
    });
  };
  switch (placement) {
    case 'header':
      return (
        <Header
          pto={pto}
          totalsRequest={totalsRequest}
          detailsRequest={detailsRequest}
          buttonRef={buttonRef}
          handleAction={handleAction}
        />
      );
    case 'footer':
      return (
        <Footer
          pto={pto}
          totalsRequest={totalsRequest}
          detailsRequest={detailsRequest}
          buttonRef={buttonRef}
          handleAction={handleAction}
        />
      );
  }
};

interface Props extends Omit<PtoRequestActionsProps, 'placement'> {
  handleAction: (buttonRef: ActionButton) => Promise<void>;
  buttonRef: React.MutableRefObject<ActionButton>;
}

const Header = ({
  handleAction,
  pto,
  buttonRef,
  totalsRequest,
  detailsRequest,
}: Props) => {
  const modal = useModal();

  const deletePto = useDeletePtoRequest(
    pto.ptoDetails.details[0]?.pkWfmEmpPtoDetailsId,
    pto.ussId
  );

  const { mutateAsync: removePtoBlackout } = useRemovePtoBlackout(
    detailsRequest,
    totalsRequest
  );

  const handleDelete = () => {
    const confirm = async () => {
      modal.closeModal();
      if (pto.ptoDetails.details[0].status === 'blackout') {
        await removePtoBlackout({
          ussIds: [pto.ussId],
          date: pto.date,
        });
      } else {
        await deletePto.mutateAsync();
      }
    };

    modal.openModal({
      title: `Remove ${
        pto.ptoDetails.details[0].status === 'blackout' ? 'Blackout' : 'PTO'
      }`,
      type: 'danger',
      children: (
        <span>
          Are you sure you want to Remove
          {pto.ptoDetails.details[0].status === 'blackout'
            ? ' Blackout '
            : ' PTO '}
          for {pto.displayName}?
        </span>
      ),
      labels: { confirm: 'Remove', cancel: 'Cancel' },
      confirmVariant: 'danger',
      onCancel: () => modal.closeModal(),
      onConfirm: () => confirm(),
    });
  };
  const buttons = getButtonDisabled(pto);

  return (
    <div className="flex justify-between items-start sm:items-center sm:mt-0 mt-2">
      <Button
        variant="text"
        className="hidden sm:flex"
        onClick={() => {
          buttonRef.current = 'approved';
          void handleAction('approved');
        }}
        disabled={buttons.approve}
      >
        <BsCheck2
          id="right"
          size={'1.75rem'}
          className={`${
            buttons.approve
              ? ' text-gray-700'
              : 'text-primary-400 cursor-pointer'
          }`}
        />
      </Button>
      <Button
        variant="text"
        className="hidden sm:flex mx-6"
        onClick={() => {
          buttonRef.current = 'denied';
          void handleAction('denied');
        }}
        disabled={buttons.deny}
      >
        <BsXLg
          id="cross"
          size={'1.25rem'}
          className={`${
            buttons.deny ? ' text-gray-700' : 'text-primary-400 cursor-pointer'
          }`}
        />
      </Button>

      <DropdownMenu>
        <DropdownMenu.Button>
          <BsThreeDotsVertical
            size="1.5rem"
            aria-label="More Options"
            className="text-primary-400"
          />
        </DropdownMenu.Button>
        <DropdownMenu.Items renderAs="ul" className="w-44 pt-3 pb-2 shadow-md">
          <DropdownMenu.Item
            renderAs="li"
            key="Clear Shift"
            className="text-gray-700 text-sm flex item-center pb-2"
          >
            <Button
              variant="icon"
              className="text-sm"
              startIcon={
                <BsInfoCircle size="1rem" className="text-primary-400" />
              }
            >
              <Link
                to={`/paid-time-off/employees/${pto.ussId}/${pto.ptoDetails.details[0]?.pkWfmEmpPtoDetailsId}`}
              >
                <span>View</span>
              </Link>
            </Button>
          </DropdownMenu.Item>
          <DropdownMenu.Item
            renderAs="li"
            key={'Add Note'}
            className="text-gray-700 text-sm flex item-center"
          >
            <Button
              variant="icon"
              className="text-sm text-red-600"
              startIcon={<BsTrash size="1rem" />}
              onClick={() => {
                handleDelete();
              }}
              disabled={buttons.removed}
            >
              {'Remove'}
            </Button>
          </DropdownMenu.Item>
        </DropdownMenu.Items>
      </DropdownMenu>
    </div>
  );
};

const Footer = ({ handleAction, pto, buttonRef }: Props) => {
  const buttons = getButtonDisabled(pto);
  return (
    <div className="flex sm:hidden w-full ">
      <div className="flex w-1/2 justify-center">
        <Button
          variant="text"
          className="text-sm text-primary-400 cursor-pointer"
          onClick={() => {
            buttonRef.current = 'approved';
            void handleAction('approved');
          }}
          disabled={buttons.approve}
        >
          <BsCheck2 id="right" className="text-2xl" />
          <span className="text-base ml-2 ">Approve</span>
        </Button>
      </div>
      <div className="bg-gray-300 w-0.5 mx-6"></div>
      <div className="flex w-1/2 justify-center">
        <Button
          variant="text"
          className="text-sm text-primary-400 cursor-pointer"
          onClick={() => {
            buttonRef.current = 'denied';
            void handleAction('denied');
          }}
          disabled={buttons.deny}
        >
          <BsX id="cross" className="text-3xl" />
          <span className="text-base ml-2"> Deny</span>
        </Button>
      </div>
    </div>
  );
};
