import Sort from 'components/Sort/Sort';
import { PtoSort, PTO_SORT } from 'features/paid-time-off/constants';
import { usePtoRequestsStore } from 'features/paid-time-off/state';
import { SortDirection } from 'types/common';

export const RequestPtoSort = () => {
  const store = usePtoRequestsStore();

  const sortOptions: {
    label: string;
    name: PtoSort;
    direction: SortDirection;
  }[] = [
    {
      label: 'Name',
      name: PTO_SORT.NAME,
      direction: SortDirection.DESC,
    },
    {
      label: 'Name',
      name: PTO_SORT.NAME,

      direction: SortDirection.ASC,
    },
    {
      label: 'PTO Date',
      name: PTO_SORT.PTO_DATE,
      direction: SortDirection.DESC,
    },
    {
      label: 'PTO Date',
      name: PTO_SORT.PTO_DATE,
      direction: SortDirection.ASC,
    },

    {
      label: 'Requested On',
      name: PTO_SORT.REQUESTED_ON,
      direction: SortDirection.ASC,
    },
    {
      label: 'Requested On',
      name: PTO_SORT.REQUESTED_ON,
      direction: SortDirection.DESC,
    },
  ];
  return (
    <>
      <div className=" w-full ">
        <Sort<PtoSort>
          options={sortOptions}
          selectedOption={
            sortOptions.filter(
              (value) =>
                store.params.sortBy === value.name &&
                store.params.sortType === value.direction
            )[0]
          }
          onChange={(value) =>
            store.setSort({
              type: value.name,
              direction: value.direction,
            })
          }
        />
      </div>
    </>
  );
};
