import { useQuery } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { Org } from 'features/org';

/**
 *
 * @param orgId Org Id of the org
 * @returns
 *
 * @description /api/v1/orgs/{orgId}
 */

const getOrgDetails = (orgId: string): Promise<Org> => {
  return axios.get(`/v1/orgs/${orgId}`);
};

/**
 * Hook that can fetch org details for given orgId
 *
 * @param orgId orgId of the org you are fetching details for
 *
 * @example
 *
 * // Fetches crew details for the crewId '12345'
 * const {data, status} = useEmployeeOrgs('12345')
 *
 *
 */
export const useOrgDetails = (orgId: string) => {
  return useQuery({
    queryFn: () => getOrgDetails(orgId),
    queryKey: [`org-details-${orgId}`, orgId],
    enabled: !!orgId && orgId !== '0',
  });
};
