import React from 'react';
//TODO: Replace pixel based border width once Design System V3 is available
const Spinner = () => {
  return (
    <div
      className={`border-[6px] border-r-[transparent] 
        border-l-accent1-600 border-b-accent1-600 border-t-accent1-600 animate-spin inline-block 
        w-16 h-16  rounded-full`}
      role="progressbar"
    >
      <span className="visually-hidden sr-only">Loading...</span>
    </div>
  );
};
export default Spinner;
