import { OrgStatusCode, ORG_TYPE, OrgType } from '../';

export const getOrgStatusList = (status: OrgType): OrgStatusCode[] => {
  switch (status) {
    case ORG_TYPE.DIVISION: {
      return ['active', 'archived'];
    }
    case ORG_TYPE.DEPARTMENT: {
      return ['active', 'archived'];
    }
    default: {
      return ['active', 'archived', 'archived', 'pending'];
    }
  }
};
