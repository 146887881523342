import {
  PerformanceFormLabel,
  ReviewPerformanceDetail,
} from '../types/employeeReviewDetails';

export const getPerformanceFormLabel = (key: keyof ReviewPerformanceDetail) => {
  switch (key) {
    case PerformanceFormLabel.ABILITY_TO_WORK_SAFELY:
      return 'Ability to work safely';
    case PerformanceFormLabel.ABILITY_TO_WORK_WITH_OTHERS:
      return 'Ability to work with Others';
    case PerformanceFormLabel.QUANTITY_OF_WORK:
      return 'Quantity of Work';
    case PerformanceFormLabel.QUALITY_OF_WORK:
      return 'Quality of Work';
    case PerformanceFormLabel.UNDERSTANDING_OF_WRITTEN_AND_VERBAL_INST:
      return 'Understanding of Written and Verbal Instructions';
    case PerformanceFormLabel.WILLINGNESS_AND_COOPERATION:
      return 'Attitude: Willingness and Cooperation';
    case PerformanceFormLabel.OVERALL_EMP_VALUE:
      return 'Overall Value of Employee';
    case PerformanceFormLabel.OVERALL_RATING:
      return 'Overall rating';
    default:
      return '';
  }
};
