import React, { useState } from 'react';
import Page from 'components/Layouts/Page';
import Tabs, { TabLinkType } from 'components/Tabs/Tabs';
import { Datepicker, Select } from '@uss/react-components';
import PendingCard from '../../../features/mileage-approval/components/Tabs/PendingMileage';
import CompletedCard from '../../../features/mileage-approval/components/Tabs/CompletedMileage';
import { useEmployeeOrgs } from 'features/org/api';
import { Org, ORG_TYPE } from 'features/org';
import { useMileageApprovalStore } from 'features/mileage-approval/state/mileage-store';

const MileageApproval = () => {
  const store = useMileageApprovalStore();
  const [totalPendingRecords, setTotalPendingRecords] = useState<string | null>(
    null
  );
  const [totalCompletedRecords, setTotalCompletedRecords] = useState<
    string | null
  >(null);

  //Payroll Location
  const { data: payrollLocations, status: locationStatus } = useEmployeeOrgs(
    'me',
    {
      type: ORG_TYPE.LOCATION,
      sortBy: 'location',
      parentOrgs: 'y',
      parentOrgId: undefined,
    }
  );
  const tabLinks: TabLinkType[] = [
    {
      label: `Pending ${totalPendingRecords ? totalPendingRecords : ''}`,
      element: <PendingCard setRecords={setTotalPendingRecords} />,
    },
    {
      label: `Completed ${totalCompletedRecords ? totalCompletedRecords : ''}`,
      element: <CompletedCard setRecords={setTotalCompletedRecords} />,
    },
  ];

  const handleSelect = (option: Org) => {
    store.setPayrollLocation(option);
  };

  return (
    <Page heading={'Mileage Approval'}>
      <Page.Section>
        <Page.Filters>
          <h3 className="sr-only">Date and Payroll Location Filters</h3>
          <Page.FiltersSection className="w-full sm:w-1/4 2xl:w-4/12">
            <Select
              selected={store.selectedPayrollLocation === null}
              onSelect={handleSelect}
              className="w-full"
            >
              <Select.Button
                label={
                  store.selectedPayrollLocation !== 'all' &&
                  store.selectedPayrollLocation &&
                  store.selectedPayrollLocation.description
                    ? `${store.selectedPayrollLocation.code} - ${store.selectedPayrollLocation.description}`
                    : store.selectedPayrollLocation === 'all'
                    ? 'All Payroll Locations'
                    : 'Select Payroll Locations'
                }
              />
              <Select.Options>
                {locationStatus === 'success' &&
                payrollLocations.items.length ? (
                  <>
                    <Select.Option value="all">
                      All Payroll Locations
                    </Select.Option>
                    {payrollLocations.items.map((item: Org, index: number) => (
                      <Select.Option truncate key={`${index}`} value={item}>
                        <span>
                          {item.location} - {item.locationDescription}
                        </span>
                      </Select.Option>
                    ))}
                  </>
                ) : (
                  <span>No locations available</span>
                )}
              </Select.Options>
            </Select>
          </Page.FiltersSection>
          <Page.FiltersSection className="w-full sm:w-1/4 2xl:w-4/12">
            <Datepicker
              id="data"
              calendarType="date"
              ariaLabelledBy="Travel Date"
              onDateChange={(date) => {
                store.setTravelDate(date);
              }}
              onClear={() => store.onClearDate()}
            />
          </Page.FiltersSection>
        </Page.Filters>
      </Page.Section>

      <div className="px-4 sm:px-5 mt-4">
        <Tabs tabLinks={tabLinks} />
      </div>
    </Page>
  );
};

export default MileageApproval;
