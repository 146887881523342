import React from 'react';
import { SortDirection } from 'types/common';
import CardList from 'components/Card/CardList';
import Error from 'components/Error/Error';
import Card from 'components/Card/Card';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';
import { format } from 'date-fns';
import {
  HistoryOptions,
  HistoryItem,
  useEntityHistory,
  HistoryExpand,
  HistoryEntity,
  HistorySort,
} from 'features/history';
import CardsLoader from 'components/Loaders/CardsLoader';

interface HistoryProps {
  id: string;
  entity: HistoryEntity;
  sortBy?: HistorySort;
  sortType?: SortDirection;
  year?: string;
  expand?: HistoryExpand;
  noRecordError?: string;
  descLabel?: string;
}
export const History = ({
  id,
  entity,
  sortBy = 'action-taken-on',
  sortType = SortDirection.DESC,
  year,
  expand,
  noRecordError,
  descLabel,
}: HistoryProps): JSX.Element => {
  const params: HistoryOptions = {
    entityId: id,
    entity: entity,
    sortBy: sortBy,
    sortType: sortType,
    year: year,
    expand: expand,
  };

  const noResults = {
    title: 'No Records Found',
    description: noRecordError ?? `No history records found`,
  };

  const { data, status } = useEntityHistory({ ...params });
  return (
    <div className="mt-4">
      {status === 'pending' && <CardsLoader variant="row-grid" count={6} />}
      {status === 'success' && (
        <CardList<HistoryItem>
          variant="row-grid"
          items={data.items}
          renderItem={(item) => (
            <HistoryCard descLabel={descLabel} history={item} />
          )}
          noResults={noResults}
        />
      )}
      {status === 'error' && <Error />}
    </div>
  );
};

interface HistoryCardProps {
  history: HistoryItem;
  descLabel?: string;
}

export const HistoryCard = ({
  history,
  descLabel = 'Description',
}: HistoryCardProps): JSX.Element => {
  return (
    <Card>
      <Card.Body>
        <div data-testid="history-card">
          <div
            className="flex flex-col flex-wrap flex-grow"
            data-testid="history-card-title"
          >
            <Title as="h2">
              {history.event
                ? history.event.charAt(0).toUpperCase() + history.event.slice(1)
                : '-'}
            </Title>
          </div>
          <div className="flex flex-wrap justify-between md:flex-nowrap items-center w-full">
            <LabeledText
              label={descLabel}
              className="w-full md:w-7/12 mb-2 pr-4 md:mb-0"
              no_clamp
            >
              {history.comments ? history.comments : '-'}
            </LabeledText>
            <LabeledText
              label="Action taken By"
              className="w-full md:w-3/12 mb-2 md:mb-0"
              no_clamp
            >
              {history.actionTakenBy ? history.actionTakenBy : '-'}
            </LabeledText>
            <LabeledText
              label="Action taken On"
              className="w-full md:w-2/12"
              no_clamp
            >
              {history.actionTakenOn
                ? format(
                    new Date(history.actionTakenOn),
                    'MMM dd, yyyy, hh:mm aa'
                  )
                : '-'}
            </LabeledText>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
