import { Table } from '@uss/react-components';
import SkeletonElements from 'components/Skeleton/SkeletonElements';

const PaycodeListingLoader = () => {
  return (
      <Table.Body data-testid="paycode-listing-loader">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_n, i) => (
          <Table.BodyRow key={i}>
            {[1, 2, 3, 4, 5, 6, 7].map((_num, index) => (
              <Table.BodyCell className="px-2" key={index}>
                <SkeletonElements elementType="tableColumn" />
              </Table.BodyCell>
            ))}
          </Table.BodyRow>
        ))}
      </Table.Body>
  );
};

export default PaycodeListingLoader;
