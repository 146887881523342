import { useProbationaryEmployeesList } from 'features/probationary-tracking/api/getProbEmployees';
import Error from 'components/Error/Error';
import NoResults from 'components/NoResults/NoResults';
import Employees from '../Employees';
import { ErrorBoundary } from 'react-error-boundary';
import { SearchInput } from '@uss/react-components';
import { useProbationaryEmployeesStore } from 'features/probationary-tracking/state/store';
import CardsLoader from 'components/Loaders/CardsLoader';
import MassActions from '../../common/MassActions/MassActions';
import { BsCheck2 } from 'react-icons/bs';
import Pagination from 'components/Pagination/Pagination';
import Loader from 'components/Skeleton/Loader';
import AssignReassignReviewerDrawer from '../../common/AssignReassignReviewerDrawer/AssignReassignReviewerDrawer';
import { useState } from 'react';
import { useAssignReviewer } from 'features/probationary-tracking/api/updateReviewer';
import { Person } from 'features/people';
import useDebounceSearch from 'features/timecards/hooks/useDebounceSearch';

const Unassigned = () => {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => {
        return (
          <Error
            width="300"
            height="300"
            title="Something went wrong!"
            description={error.message}
          />
        );
      }}
    >
      <Content />
    </ErrorBoundary>
  );
};
const Content = () => {
  const { areaManager } = useProbationaryEmployeesStore();
  const page = areaManager.unassigned.page;
  const pageSize = areaManager.unassigned.pageSize;
  const setPage = areaManager.unassigned.setPage;
  const setPageSize = areaManager.unassigned.setPageSize;
  const search = areaManager.unassigned.search;
  const setSearch = areaManager.unassigned.setSearch;
  const clearSearch = areaManager.unassigned.clearSearch;
  const selectedEmployees = areaManager.unassigned.selectedEmployees;
  const clearEmployees = areaManager.unassigned.clearEmployees;
  const toggleEmployee = areaManager.unassigned.toggleEmployee;
  const toggleAllEmployees = areaManager.unassigned.toggleAllEmployees;
  const debounceSearch = useDebounceSearch(search, 500);
  const { data, status } = useProbationaryEmployeesList({
    params: {
      unassigned: 'y',
      search: debounceSearch,
      page: page,
      pageSize,
      context: 'supervisor',
    },
  });
  const { mutateAsync: assignReviewer, isPending: isUpdatingReviewer } =
    useAssignReviewer();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      clearSearch();
    } else {
      setSearch(e.target.value);
    }
  };
  const handleClearSearch = () => {
    clearSearch();
  };

  const handleAssignReviewer = async (selectedReviewer: Person | null) => {
    if (selectedReviewer) {
      const payload = {
        reviewerUssId: selectedReviewer.ussId.toString(),
        empUssIds: selectedEmployees,
      };

      await assignReviewer(payload);
      clearEmployees();
    }
  };
  const massActions = [
    {
      label: 'Cancel',
      handleClick: () => {
        clearEmployees();
      },
    },
    {
      label: 'Assign Reviewer',
      startIcon: <BsCheck2 className="text-white" />,
      handleClick: () => setIsOpen(true),
    },
  ];
  return (
    <>
      <div className="w-full md:w-[30%] my-4">
        <SearchInput
          id="search-input"
          label="search"
          placeholder="Search Name / USS ID"
          role="searchbox"
          autoCompleteFlag={search ? true : false}
          selectedFlag={search ? true : false}
          value={search ?? ''}
          onChange={handleSearchChange}
          onClose={handleClearSearch}
        />
      </div>
      {status === 'pending' && (
        <>
          <Loader rows={1} />
          <CardsLoader variant="row-grid" count={9} hasAvatar />
        </>
      )}
      {status === 'error' && <Error />}
      {status === 'success' &&
        (data.items.length > 0 ? (
          <>
            <Employees
              data={data}
              selectable
              selectedEmployees={selectedEmployees}
              params={{ search: search, page: page, pageSize: pageSize }}
              toggleEmployee={toggleEmployee}
              toggleAllEmployees={toggleAllEmployees}
              clearEmployees={clearEmployees}
            />
            <MassActions
              selectedEmployeesCount={selectedEmployees.length}
              actions={massActions}
            />
            <AssignReassignReviewerDrawer
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              selectionText={
                selectedEmployees.length === 1
                  ? '1 Probationary employee selected'
                  : `${selectedEmployees.length} Probationary employees selected`
              }
              handleUpdateReviewer={handleAssignReviewer}
              title="Assign Reviewer"
              actionTitle="Assign"
              isUpdating={isUpdatingReviewer}
            />
            <Pagination
              pageOptions={[10, 20, 30, 40, 50, 60]}
              totalCount={data.totalCount}
              page={data.page}
              pageSize={data.pageSize}
              onPageChange={(s) => setPage(s.selected + 1)}
              onPageSizeChange={(ps) => setPageSize(ps)}
            />
          </>
        ) : (
          <NoResults
            width={'300'}
            height={'300'}
            description="No Probationary employee records available"
          />
        ))}
    </>
  );
};
export default Unassigned;
