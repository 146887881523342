import { PTO_STATUS } from '../constants';
import { PtoStatus } from '../types';

export const getPtoStatusText = (status: PtoStatus) => {
  switch (status) {
    case PTO_STATUS.ADJUSTMENT: {
      return 'Adjustment';
    }
    case PTO_STATUS.APPROVED: {
      return 'Approved';
    }
    case PTO_STATUS.COMPANY_BLACKOUT: {
      return 'Blackout';
    }
    case PTO_STATUS.DENIED: {
      return 'Denied';
    }
    case PTO_STATUS.REQUESTED: {
      return 'Requested';
    }
    case PTO_STATUS.TAKEN: {
      return 'Taken';
    }
    case PTO_STATUS.REMOVED:
      return 'Removed';
  }
};
