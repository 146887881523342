import { PaycodeStatus } from '../types';
import { PAYCODE_STATUS } from '../constants';

const getPaycodeStatusText = (value: PaycodeStatus) => {
  switch (value) {
    case PAYCODE_STATUS.ACTIVE:
      return 'Active';
    case PAYCODE_STATUS.PENDING:
      return 'Pending';
    case PAYCODE_STATUS.ARCHIVED:
      return 'Archived';
    case PAYCODE_STATUS.DENIED:
      return 'Denied';
    default:
      return '';
  }
};
export default getPaycodeStatusText;
