const NoAccess = () => {
  // console.log(JSON.stringify(match));
  return (
    <div className="flex flex-col items-center my-24">
      <h1 className="text-7xl text-accent1-500">No Access</h1>
      <p className="text-4xl">
        You do not have any assigned roles for this application.
      </p>
    </div>
  );
};

export default NoAccess;
