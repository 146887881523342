interface ValidationErrorProps {
  msg: string;
  name: string;
}

const ValidationError = ({ msg, name }: ValidationErrorProps) => {
  return (
    <p className="text-xs text-red-600" data-testid={`validation-` + name}>
      {msg}
    </p>
  );
};
export default ValidationError;
