import { Button, Modal, UnstyledButton } from '@uss/react-core';
import { notify } from 'components/Notification/useNotification';
import { useEditModel } from 'features/model-listing/api/mutations';
import { useEditModelStore } from 'features/model-listing/state/edit-model-store';
import { useModelDetailStore } from 'features/model-listing/state/model-detail-store';
import { ModelWeeks } from 'features/model-listing/types';
import { getDaysFormat } from 'features/model-listing/utility/getDaysFormat';
import { getWeekFormat } from 'features/model-listing/utility/getWeekFormat';
import { useEffect, useState } from 'react';
import { BsExclamationCircle, BsFiles, BsTrash, BsX } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import { CopySchedule } from './CopySchedule';
import { ScheduleTurnInput } from './ScheduleTurnInput';

export interface calenderViewProps {
  weekData: ModelWeeks;
  weekBeginIndex: number;
  modelName: string;
  totalWeeks: number;
}
export const ModelWeekEditCard = ({
  weekData,
  modelName,
  weekBeginIndex,
  totalWeeks,
}: calenderViewProps) => {
  const navigate = useNavigate();

  const store = useEditModelStore();
  const modelDetailStore = useModelDetailStore();
  const [openCopySchedule, setOpenCopySchedule] = useState<boolean>(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);

  useEffect(() => {
    store.updateEachWeek(weekData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekData]);
  const { mutateAsync: editModelMutateAsync } = useEditModel(
    modelDetailStore.modelDetail?.id ? modelDetailStore.modelDetail.id : ''
  );
  const handleDelete = async () => {
    const remainingWeeks = modelDetailStore.modelDetail?.weeks.filter(
      (week) => week.weekNo !== weekData.weekNo
    );
    let counter = 0;
    const allWeeks = remainingWeeks?.map((week) => {
      counter++;
      return {
        weekNo: counter,
        crewRotations: week.crewRotations,
      };
    });
    const payload = {
      modelDescription: modelDetailStore.modelDetail?.modelDescription ?? '',
      action: 'save',
      weeks: allWeeks,
    };
    await editModelMutateAsync(payload, {
      onSuccess: () => {
        navigate(
          `/non-union-scheduling/modeling/${modelDetailStore.modelDetail?.id ?? ''
          }`
        );
        setOpenDeleteConfirmation(false);
        notify.success({
          message: `You have deleted the week.`,
        });
      },
    });
  };
  return (
    <>
      <div className="flex flex-col bg-white max-h-max w-full shadow-md rounded-md py-4 px-6 ">
        <div className="border-b-2 border-gray-300  pb-3 ">
          <div className=" flex flex-row">
            <div className=" w-full font-semibold text-lg">{`Week ${weekData.weekNo}`}</div>
          </div>
        </div>
        <div className="py-3">
          <div className="w-full grid gap-4 grid-cols-8 items-center">
            <div className=" text-base text-center"></div>
            {getDaysFormat(weekBeginIndex).map((day, _index) => (
              <div
                key={_index}
                className="text-center font-semibold text-base justify-center"
              >
                {day?.value.charAt(0)}
              </div>
            ))}

            {weekData.crewRotations.map((crewRotation, index) => (
              <>
                <div
                  key={index}
                  className="font-semibold text-base text-center"
                >
                  {crewRotation.crewRotation}
                </div>

                {getWeekFormat({
                  data: [...crewRotation.schedTurns],
                  weekBeginIndex: weekBeginIndex,
                }).map((schedTurn, _index) => (
                  <div key={_index} className="  text-center text-sm ">
                    <ScheduleTurnInput
                      schedTurn={schedTurn}
                      weekNo={weekData.weekNo}
                      crewRotation={crewRotation.crewRotation}
                    />
                  </div>
                ))}
              </>
            ))}
          </div>
        </div>

        <div className="flex w-full border-t-2 border-gray-300 pt-3">
          <div className="flex w-1/2 justify-center">
            <Button
              variant="text"
              className="text-sm text-primary-400 cursor-pointer"
              onPressChange={() => setOpenCopySchedule(true)}
            >
              <BsFiles className="text-sm" />
              <span className="text-xs ml-2"> Copy Schedule</span>
            </Button>
            <CopySchedule
              isOpenDrawer={openCopySchedule}
              setOpenCopySchedule={setOpenCopySchedule}
              totalWeeks={totalWeeks}
              selectedWeek={weekData.weekNo}
            />
          </div>
          <div className="bg-gray-300 w-0.5 mx-6"></div>
          <div className="flex w-1/2  justify-center">
            <Button
              variant="text"
              className="text-sm  text-red-600 cursor-pointer"
              onPress={() => setOpenDeleteConfirmation(true)}
            >
              <BsTrash id="delete" className="text-sm" />
              <span className="text-xs ml-2"> Delete</span>
            </Button>
          </div>
        </div>
      </div>
      <Modal
        ariaLabel={'Delete One Week'}
        isDismissable
        isOpen={openDeleteConfirmation}
        size="default"
      >
        <Modal.Header>
          <div className="flex flex-grow items-center ">
            <BsExclamationCircle className="text-red-500 mr-2 text-3xl" />{' '}
            <h2>Delete Week(s)</h2>
          </div>
          <UnstyledButton>
            <BsX
              size="2rem"
              onClick={() => setOpenDeleteConfirmation(false)}
              className="cursor-pointer absolute sm:relative right-2 sm:right-0"
            />
          </UnstyledButton>
        </Modal.Header>
        <Modal.Content>
          Are you sure you want to Delete the week(s)?
        </Modal.Content>
        <Modal.Footer>
          <div className="flex gap-2">
            <Button
              variant={'outline'}
              onPress={() => setOpenDeleteConfirmation(false)}
            >
              Cancel
            </Button>
            <Button
              variant={'filled'}
              onPress={() => handleDelete()}
              color="danger"
            >
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
