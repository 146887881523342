import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { create } from 'zustand';
import { SortDirection } from 'types/common';
import {
  PeopleOptions,
  PEOPLE_SORT,
  PERSON_STATUS,
  PeopleSort,
} from 'features/people';
import { Org } from 'features/org';

interface SecurityStoreState {
  params: PeopleOptions;
  selectedPlant: Org | null | 'all';
  selectedLocation: Org | null | 'all';
  setPage: (value: number) => void;
  setPageSize: (value: number) => void;
  setSort: (val: { type: PeopleSort; direction: SortDirection }) => void;
  setPlant: (value: Org | 'all') => void;
  setPayrollLocations: (value: Org | 'all') => void;
  setSearch: (value: string) => void;
  clearSearch: () => void;
}

export const useSecurityStore = create<SecurityStoreState>()(
  devtools(
    immer((set) => ({
      params: {
        page: '1',
        pageSize: '9',
        sortBy: PEOPLE_SORT.RECENTLYUPDATED,
        sortType: SortDirection.DESC,
        status: `${PERSON_STATUS.ACTIVE},${PERSON_STATUS.INACTIVE},${PERSON_STATUS.PENDING},${PERSON_STATUS.TERMINATEASSIGNMENT}`,
      },
      selectedLocation: null,
      selectedPlant: null,
      setPlant: (value) =>
        set(
          (state) => {
            state.params.plant = value === 'all' ? null : value.code;
            state.selectedPlant = value === 'all' ? 'all' : { ...value };
            state.params.payrollLocations = null;
            state.selectedLocation = 'all';
            state.params.page = '1';
          },
          false,
          'set-plant'
        ),
      setPayrollLocations: (value) =>
        set(
          (state) => {
            state.params.payrollLocations = value === 'all' ? null : value.code;
            state.selectedLocation = value === 'all' ? 'all' : { ...value };
            state.params.page = '1';
          },
          false,
          'set-payroll-location'
        ),

      setPage: (value) =>
        set(
          (state) => {
            state.params.page = value.toString();
          },
          false,
          'set-page'
        ),
      setPageSize: (value) =>
        set(
          (state) => {
            state.params.pageSize = value.toString();
            state.params.page = '1';
          },
          false,
          'set-pageSize'
        ),
      setSort: (value) =>
        set(
          (state) => {
            state.params.sortBy = value.type;
            state.params.sortType = value.direction;
            state.params.page = '1';
          },
          false,
          'set-sort'
        ),
      setSearch: (value) =>
        set(
          (state) => {
            if (value.length > 0) {
              state.params.search = value;
              state.params.page = '1';
            } else delete state.params.search;
          },
          false,
          'set-search'
        ),
      clearSearch: () =>
        set(
          (state) => {
            state.params.search = '';
            delete state.params.search;
          },
          false,
          'clear-search'
        ),
    }))
  )
);
