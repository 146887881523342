import React from 'react';
import Card from 'components/Card/Card';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';
import { UnapprovedCrew } from 'features/paypulloff/types';
import { Link } from '@uss/react-components';
import { NavLink } from 'react-router-dom';
interface CrewCardProps {
  crew: UnapprovedCrew;
}

const UnapprovedCrewCard = ({ crew }: CrewCardProps) => {
  return (
    <Card>
      <Link
        as={NavLink}
        to={`unapproved-crews/${crew.crewId.toString()}`}
        className="block cursor-pointer"
        key={crew.crewKey}
      >
        <Card.Header>
          <div className="flex items-center">
            <Title as="h2">{crew.crewKey ? crew.crewKey : '-'}</Title>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="flex flex-wrap items-center w-full mb-4">
            <LabeledText
              className="w-1/2"
              label="Unapproved Timecard Count"
              lineClamp={1}
            >
              {crew.count}
            </LabeledText>
            <LabeledText
              className="w-1/2"
              label="Primary Approver"
              lineClamp={1}
            >
              {crew.primaryApprover}
            </LabeledText>
          </div>
        </Card.Body>
      </Link>
    </Card>
  );
};

export default UnapprovedCrewCard;
