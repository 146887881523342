import { Button, Drawer, TextArea } from '@uss/react-components';
import useNotification from 'components/Notification/useNotification';
import LabeledText from 'components/Typography/LabeledText';
import { format, parseISO } from 'date-fns';
import { useAddPtoBlackout } from 'features/paid-time-off/api/mutations';
import { AddBlackoutPost } from 'features/paid-time-off/types';
import {
  GetCrewParamsBody,
  GetOrgDetailsParamsBody,
} from 'features/paid-time-off/types/crew-pto';
import React, { useState } from 'react';

interface Props {
  ussids: string[];
  blackoutDate: string;
  open: boolean;
  setOpen: (val: boolean) => void;
  detailsRequest?: GetOrgDetailsParamsBody;
  totalsRequest?: GetCrewParamsBody;
}
export const AddBlackouts = ({
  ussids,
  blackoutDate,
  open,
  setOpen,
  detailsRequest,
  totalsRequest,
}: Props) => {
  const [note, setNote] = useState('');

  //add pto blackout record api hook
  const { mutateAsync: addPtoBlackout, isPending: addPtoBlackoutPending } =
    useAddPtoBlackout(detailsRequest, totalsRequest);

  //notification
  const notify = useNotification();
  const handleAddButton = async () => {
    const payload: AddBlackoutPost = {
      ussIds: ussids,
      date: blackoutDate,
      notes: note,
    };

    await addPtoBlackout(payload, {
      onSuccess: (success) => {
        setNote('');
        setOpen(false);
        if (success.success) {
          notify.success({
            message: `You have added Blackout for ${format(
              parseISO(blackoutDate),
              'MMM dd, yyyy'
            )} for the selected employees.`,
          });
        } else if (success.status === 206) {
          notify.warning({
            message: `One or more of the selected employees already have PTO on the day - the Blackout day was not entered for those employees. But, the Blackout day was entered for the remaining employees.`,
          });
        } else {
          if (success.items) {
            // eslint-disable-next-line array-callback-return
            success.items.map((e) => {
              if (e.status.toString() === 'PARTIAL_CONTENT')
                notify.warning({
                  message: e.errors[0].message,
                });
              else {
                notify.danger({
                  message: e.errors[0].message,
                });
              }
            });
          }
        }
      },
    });
  };

  return (
    <Drawer
      placement={window.innerWidth > 768 ? 'right' : 'bottom'}
      title="Add Blackout"
      isOpen={open}
      onClose={(): void => {
        setOpen(false);
      }}
      closeButton={window.innerWidth > 768 ? false : true}
      className="w-full sm:w-1/2 md:w-1/3 xl:w-1/4 2xl:w-1/5 md:top-0 top-18 md:rounded-t-none rounded-t-md"
    >
      <div className="flex-grow">
        <div className="flex flex-col mt-10">
          <LabeledText
            className="w-1/2 sm:w-auto sm:flex-grow mb-4 sm:mb-0"
            label="Blackout Date"
            lineClamp={1}
          >
            {format(parseISO(blackoutDate), 'MMM dd, yyyy')}
          </LabeledText>
          <div className="mt-2">
            <TextArea
              maxLength={100}
              label="Notes (Optional)"
              id="form-comment"
              name="note"
              placeholder={'Enter'}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setNote(e.target.value);
              }}
              value={note}
            />
            <div className="col-span-2 text-xs my-2 text-gray-600 text-right">
              {100 - note.length}/100
            </div>
          </div>
        </div>
      </div>
      <Drawer.Controls>
        <Button
          aria-label="Cancel Button"
          className="md:mb-0 mb-2 w-full"
          variant="outline"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          aria-label="Add Button"
          variant="primary"
          className="md:ml-4 w-full"
          onClick={handleAddButton}
          disabled={addPtoBlackoutPending}
        >
          Add
        </Button>
      </Drawer.Controls>
    </Drawer>
  );
};
