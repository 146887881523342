import React from 'react';
import { StatusSelect } from './StatusSelect';
import { PtoDetailsView, PtoStatus } from 'features/paid-time-off/types';
import DateFilter from './DatePickers/DateFilter';
import { CalendarView } from 'components/CalendarV2/types';
import YearMonthToggle from './YearMonthToggle';
import RadioPTODetailsView from './RadioPTODetailsView';
import { YearSelect } from './DatePickers/YearSelect';
import { appNewDate } from 'utils/appNewDate';
import { addYears, format, subYears } from 'date-fns';
import { SortDirection } from 'types/common';
import { PtoDetailsSort, SortByType } from 'features/paid-time-off/types';
import Sort from 'components/Sort/Sort';

const sortOptions: {
  label: string;
  name: PtoDetailsSort;
  direction: SortDirection;
}[] = [
  {
    label: 'Date',
    name: 'shiftDate',
    direction: SortDirection.ASC,
  },
  {
    label: 'Date',
    name: 'shiftDate',
    direction: SortDirection.DESC,
  },
];

const years = [
  format(subYears(new Date(), 1), 'yyyy'),
  format(new Date(appNewDate()), 'yyyy'),
  format(addYears(new Date(), 1), 'yyyy'),
];

interface PtoFilterParams {
  calendarType: CalendarView;
  startDateValue: Date;
  status: PtoStatus | undefined;
  //object combining the below
  sortBy: PtoDetailsSort | undefined;
  sortType: 'asc' | 'desc' | undefined;
  onSelectDate: (value: Date) => void;
  toggleYearMonth: (value: 'year' | 'month') => void;
  setStatus: (value: PtoStatus | 'all') => void;
  setPtoDetailsView: (value: PtoDetailsView) => void;
  setYear: (value: string) => void;
  setSort?: (val: { type: SortByType; direction: SortDirection }) => void;
}
export const PtoFilters = ({
  calendarType,
  startDateValue,
  status,
  sortBy,
  sortType,
  onSelectDate,
  toggleYearMonth,
  setStatus,
  setPtoDetailsView,
  setYear,
  setSort,
}: PtoFilterParams) => {
  return (
    <div className="flex gap-4 flex-wrap md:flex-nowrap w-full">
      <div className="flex gap-3 flex-wrap items-center md:flex-nowrap w-full md:w-2/3 ">
        <div className="max-w-max">
          <YearMonthToggle
            calendarType={calendarType}
            toggleYearMonth={toggleYearMonth}
          />
        </div>
        <div className="bg-gray-300 h-8 w-0.5 hidden md:block "></div>
        <div className=" w-36">
          {calendarType === 'month' && (
            <DateFilter
              calendarType={calendarType}
              startDateValue={startDateValue}
              onSelectDate={onSelectDate}
            />
          )}

          {calendarType === 'year' && (
            <YearSelect
              setYear={setYear}
              selectedYear={format(startDateValue, 'yyyy')}
              data={years}
            />
          )}
        </div>
        <div className="max-w-max">
          <RadioPTODetailsView setPtoDetailsView={setPtoDetailsView} />
        </div>
      </div>
      <div className="flex md:justify-end gap-3 w-full md:w-2/6">
        <div className="w-2/4">
          <StatusSelect setStatus={setStatus} status={status} />
        </div>
        <div className=" w-2/4">
          {setSort && (
            <Sort<PtoDetailsSort>
              options={sortOptions}
              selectedOption={
                sortOptions.filter(
                  (value) =>
                    sortBy === value.name && sortType === value.direction
                )[0]
              }
              onChange={(value) =>
                setSort({
                  type: value.name,
                  direction: value.direction,
                })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
