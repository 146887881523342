import { SHIFT_ASSIGNMENT, ShiftAssignment } from 'features/people/types';

const getFormattedShiftAssignment = (shift: ShiftAssignment) => {
  switch (shift) {
    case SHIFT_ASSIGNMENT.EIGHT_DAY_HOUR:
      return '8-DAY HOUR';
    case SHIFT_ASSIGNMENT.EIGHT_HOUR:
      return '8-HR';
    case SHIFT_ASSIGNMENT.TWELVE_HOUR:
      return '12-HR AWS';
    default:
      return '-';
  }
};
export default getFormattedShiftAssignment;
