import { Datepicker } from '@uss/react-components';
import { add, compareAsc, startOfWeek } from 'date-fns';
import { format } from 'date-fns-tz';
import useWindowSize from 'hooks/useWindowSize';
import {
  CopyParamAction,
  CopyParamActions,
  SectionType,
  StartDateErrorType,
  UpdateFromDateError,
} from 'features/timecards/types';
import React from 'react';
import { WeekStart } from 'types/common';
import Loader from 'components/Skeleton/Loader';

interface Props {
  weekRangeChecked: { from: boolean; to: boolean };
  updateWeekState: React.Dispatch<CopyParamAction>;
  startDate: Date | null;
  endDate: Date | null;
  sectionType: SectionType;
  fromDateError: StartDateErrorType;
  updateErrorState: UpdateFromDateError;
  startDayOfWeek: WeekStart | undefined;
}

const WeekRangeSelect = ({
  weekRangeChecked,
  updateWeekState,
  startDate,
  endDate,
  sectionType,
  fromDateError,
  updateErrorState,
  startDayOfWeek,
}: Props) => {
  const { width } = useWindowSize();

  const onCLearStartDate = () => {
    updateWeekState({
      type:
        sectionType === SectionType.FROM
          ? CopyParamActions.DELETE_FROM_START_WEEK
          : CopyParamActions.DELETE_TO_START_WEEK,
    });
    if (weekRangeChecked.from) {
      updateWeekState({
        type: CopyParamActions.DELETE_TO_END_WEEK,
      });
    }
  };

  const onCLearEndDate = () => {
    updateWeekState({
      type:
        sectionType === SectionType.FROM
          ? CopyParamActions.DELETE_FROM_END_WEEK
          : CopyParamActions.DELETE_TO_END_WEEK,
    });
  };
  const handleStartDateChange = (value: [Date | null, Date | null]) => {
    const [start] = value;
    if (start  && startDayOfWeek !== undefined) {
      const selectedDate = format(
        startOfWeek(start, { weekStartsOn: startDayOfWeek }),
        'yyyy-MM-dd'
      );

      if (endDate && compareAsc(start, endDate) >= 0) {
        onCLearEndDate();
      }

      if (sectionType === SectionType.TO) {
        updateWeekState({
          type: CopyParamActions.TO_START_WEEK,
          payload: selectedDate,
        });
        updateErrorState({
          source: fromDateError.source,
          target: false,
        });
      } else {
        updateWeekState({
          type: CopyParamActions.FROM_START_WEEK,
          payload: selectedDate,
        });
        updateErrorState({
          source: false,
          target: fromDateError.target,
        });
      }
    }
  };

  const handleEndDateChange = (value: [Date | null, Date | null]) => {
    const [start] = value;

    if (start && startDayOfWeek!==undefined) {
      const selectedDate = format(
        startOfWeek(start, { weekStartsOn: startDayOfWeek }),
        'yyyy-MM-dd'
      );
      if (sectionType === SectionType.TO)
        updateWeekState({
          type: CopyParamActions.TO_END_WEEK,
          payload: selectedDate,
        });
      else
        updateWeekState({
          type: CopyParamActions.FROM_END_WEEK,
          payload: selectedDate,
        });
    }
  };

  return (
    <div className=" flex flex-col my-1">
      {width && (
        <div className="flex flex-col gap-y-2">
          <div>
            <label className="text-xs text-gray-600 mb-2">
              {(sectionType === SectionType.TO && !weekRangeChecked.from
                ? weekRangeChecked.to
                : weekRangeChecked.from) && 'Start '}
              Week
            </label>
            { startDayOfWeek !== undefined ? (
              <Datepicker
                startDate={startDate}
                onRangeChange={handleStartDateChange}
                calendarType="week"
                startDayOfWeek={startDayOfWeek}
                onClear={onCLearStartDate}
                isError={
                  sectionType === SectionType.FROM
                    ? fromDateError.source
                    : fromDateError.target
                }
                errormessage={'Required Field'}
                isMobile={width <= 768}
                disabled={
                  !weekRangeChecked.from && sectionType === SectionType.FROM
                }
                id={`${
                  sectionType === SectionType.FROM ? 'from' : 'to'
                }-start-week`}
              />
            ) : (
              <Loader isFilter={true} />
            )}
          </div>

          {(weekRangeChecked.from ||
            (weekRangeChecked.to && sectionType !== SectionType.FROM)) && (
            <div>
              <span className="text-xs text-gray-600 ">End Week 2</span>
              {startDayOfWeek !== undefined  ? (
                <Datepicker
                  startDate={endDate}
                  onRangeChange={handleEndDateChange}
                  startDayOfWeek={startDayOfWeek}
                  calendarType="week"
                  isMobile={width <= 768}
                  onClear={onCLearEndDate}
                  minDate={
                    startDate ? add(startDate, { weeks: 1 }) : new Date()
                  }
                  disabled={
                    weekRangeChecked.from && sectionType === SectionType.TO
                  }
                  id={`${
                    sectionType === SectionType.FROM ? 'from' : 'to'
                  }-end-week`}
                />
              ) : (
                <Loader isFilter={true} />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(WeekRangeSelect);
