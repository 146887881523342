import React from 'react';
import { Avatar } from '@uss/react-components';
import Card from 'components/Card/Card';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';
import { getInitials, avatarColor } from 'utils';
import {
  UnapprovedCrewEmployee,
  UnapprovedCrewEmpOtherTimecard,
} from '../../types';
import { format, parseISO } from 'date-fns';
import SeparatedLabels from 'components/SeparatedLabels/SeparatedLabels';
import Tooltip from 'components/Tooltip/Tooltip';
import getFormattedOrgSecurityStatus from 'features/people/utilities/getFormattedPersonStatus';
import { uniqueId } from 'lodash';

interface Props {
  unapprovedCrewEmp: UnapprovedCrewEmployee;
}

const UnapprovedCrewEmployeeCard = ({ unapprovedCrewEmp }: Props) => {
  const crewCount = unapprovedCrewEmp.otherTimeCards.length;
  const crewKey =
    crewCount >= 1 ? unapprovedCrewEmp.otherTimeCards[0].crewKey : '--';

  return (
    <Card>
      <Card.Body>
        <div className="sm:flex items-center w-full sm:h-full">
          <div className="flex items-center w-full h-full lg:w-1/4 relative lg:pr-6 lg:after:w-0.5 lg:after:absolute lg:after:right-0 lg:after:h-full lg:after:bg-gray-200">
            <Avatar color={avatarColor(unapprovedCrewEmp.ussId)} size="medium">
              {getInitials(
                unapprovedCrewEmp.firstName,
                unapprovedCrewEmp.lastName
              )}
            </Avatar>
            <div className="pl-3 w-full">
              <Title as="h2" data-testid="employee-displayname">
                {unapprovedCrewEmp.displayName}
              </Title>
              <SeparatedLabels
                size="md"
                items={[
                  {
                    label: 'USS ID',
                    value: unapprovedCrewEmp.ussId,
                    width: 'w-20',
                  },
                ]}
              />
            </div>
          </div>
          <div className="bg-gray-200 w-full h-0.5 mx-0 my-3 sm:hidden"></div>
          <div className="flex flex-wrap justify-between lg:flex-nowrap w-full lg:w-3/5 ">
            <LabeledText
              label="Status"
              lineClamp={1}
              className="w-1/2 lg:w-1/6 mb-4 lg:mb-0 ml-0 lg:ml-4 lg:last-of-type:ml-0 capitalize"
            >
              {unapprovedCrewEmp.employeeStatus
                ? getFormattedOrgSecurityStatus(
                    unapprovedCrewEmp.employeeStatus
                  )
                : '-'}
            </LabeledText>
            <LabeledText
              label="Status Effective Date"
              lineClamp={2}
              className="w-1/2 lg:w-1/4 mb-4 last-of-type:mb-0 lg:mb-0 ml-0 lg:ml-4 lg:last-of-type:ml-0"
            >
              {format(
                parseISO(unapprovedCrewEmp.statusEffectiveDate),
                'MMM dd, yyyy'
              )}
            </LabeledText>
            <LabeledText
              label="Employee Class"
              lineClamp={1}
              className="w-1/2 lg:w-1/4 ml-0 lg:ml-4 lg:last-of-type:ml-0"
            >
              {unapprovedCrewEmp.empClass}
              {unapprovedCrewEmp.empClassDesc
                ? `- ${unapprovedCrewEmp.empClassDesc}`
                : ''}
            </LabeledText>
            <LabeledText
              label="Timecard Status"
              lineClamp={1}
              className="w-1/2 lg:w-1/4 lg:mb-0 ml-0 lg:ml-4 lg:last-of-type:ml-0"
            >
              {unapprovedCrewEmp.status
                ? unapprovedCrewEmp.status.description
                : '-'}
            </LabeledText>
          </div>
          <div className="bg-gray-200 w-full h-0.5 mx-0 my-3 sm:hidden"></div>
          <div
            className="w-full lg:w-1/5 lg:pl-6 
          relative lg:before:w-0.5 lg:before:absolute lg:before:left-0 lg:before:h-full lg:before:bg-gray-200"
          >
            <span className="w-1/2 lg:w-1/6 mb-4 lg:mb-0 ml-0 lg:ml-4 lg:last-of-type:ml-0">
              <span className="text-gray-700 text-xs text-left">
                Other Timecard(s)
              </span>
              <span className="flex">
                <p
                  aria-label="Other Timecard(s)"
                  title="Other Timecard(s)"
                  className="flex md:block mr-2"
                >
                  <span className="text-gray-900 text-sm text-left font-semibold line-clamp-1 mr-2 md:mr-0">
                    {crewKey}
                  </span>
                  <span className="text-gray-900 text-xs text-left font-normal line-clamp-1">
                    {crewKey !== '--' &&
                    unapprovedCrewEmp.otherTimeCards.length > 0 &&
                    unapprovedCrewEmp.otherTimeCards[0]?.status
                      ? unapprovedCrewEmp.otherTimeCards[0].status.description
                      : '-'}
                  </span>
                </p>
                {crewCount > 1 && (
                  <span className="flex items-start">
                    <Tooltip
                      placement="bottom"
                      tooltipContent={
                        <TooltipContent
                          timecards={unapprovedCrewEmp.otherTimeCards}
                        />
                      }
                    >
                      <span className="text-gray-900 text-sm text-left font-semibold line-clamp-1">
                        {crewCount ? `+${crewCount - 1}` : ''}
                      </span>
                    </Tooltip>
                  </span>
                )}
              </span>
            </span>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

interface TooltipContentProps {
  timecards: UnapprovedCrewEmpOtherTimecard[];
}

const TooltipContent = ({ timecards }: TooltipContentProps) => {
  const timecardList = timecards.slice(1, timecards.length);
  return (
    <>
      <ul data-testid="other-timecards-tooltip">
        {timecardList.map((item, index) => (
          <li
            key={uniqueId()}
            className="flex flex-nowrap justify-between items-center text-gray-700 font-normal whitespace-nowrap mb-2 last-of-type:mb-0"
          >
            <span className="text-sm pr-4 flex-grow">{item.crewKey}</span>
            <span className="text-xs">
              {item.status ? item.status.description : '-'}
            </span>
          </li>
        ))}
      </ul>
    </>
  );
};

export default UnapprovedCrewEmployeeCard;
