import React, { ReactNode } from 'react';

type badgeColors =
  | 'primary'
  | 'yellow'
  | 'green'
  | 'gray'
  | 'purple'
  | 'red'
  | 'blue';

interface BadgeProps {
  //variant: keyof typeof variants;
  children?: React.ReactNode;
  color: badgeColors;
  onlyIcon?: boolean;
  badgeIcon?: ReactNode;
  rightCont?: boolean;
  label?: string;
}

function setBadgeStyleColor(color: string) {
  switch (color) {
    case 'blue':
      return 'text-blue-900 border border-blue-500 bg-blue-900 ';
    case 'red':
      return 'text-red-900 border border-red-500 bg-red-900 ';
    case 'yellow':
      return 'text-yellow-900 border border-yellow-500 bg-yellow-900 ';
    case 'green':
      return 'text-green-900 border border-green-500 bg-green-900 ';
    case 'purple':
      return 'text-purple-900 border border-purple-500 bg-purple-900 ';
    case 'gray':
      return 'text-gray-900 border border-gray-500 bg-gray-900 ';

    default:
      return 'text-primary-900 border border-primary-500 bg-primary-900 ';
  }
}
const IconBadge = ({
  color = 'primary',
  children,
  onlyIcon,
  badgeIcon,
}: BadgeProps) => {
  return (
    <>
      {onlyIcon ? (
        <span
          className={`inline-flex items-center bg-opacity-10 justify-center h-6 w-6 mr-2 last-of-type:mr-0  text-xs 2xl:text-sm rounded-full ${setBadgeStyleColor(
            color
          )}`}
        >
          {badgeIcon}
        </span>
      ) : (
        <span
          className={`w-auto inline-flex items-center bg-opacity-10 justify-center px-2 py-1 mr-2 last-of-type:mr-0 mb-2 text-xs 2xl:text-sm rounded-full ${setBadgeStyleColor(
            color
          )}`}
        >
          {badgeIcon && <span className="mr-2">{badgeIcon}</span>} {children}
        </span>
      )}
    </>
  );
};
export default IconBadge;
