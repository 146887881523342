import { QueryStatus } from '@tanstack/react-query';
import { Org } from 'features/org/types';

import { createContext, useContext } from 'react';

const OrgFiltersContext = createContext<{
  orgs: Org[];
  plants: Org[];
  payrollLocations: Org[];
  divisions: Org[];
  departments: Org[];
  crews: Org[];
  status: QueryStatus;
  plantStatus: QueryStatus;
} | null>(null);

export function useOrgFiltersContext() {
  const context = useContext(OrgFiltersContext);

  if (!context) {
    throw new Error(
      'OrgFilters.* component must be rendered as child of the OrgFilters'
    );
  }

  return context;
}

export default OrgFiltersContext;
