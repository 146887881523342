import React from 'react';
import Card from 'components/Card/Card';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';
import { Org } from 'features/org';
import Badge from 'components/Badge/Badge';
import { getOrgStatusColor } from 'features/org/utilities/getOrgStatusColor';
import { getOrgStatusText } from 'features/org/utilities/getOrgStatusText';

interface CrewsListProps {
  crew: Org;
}

export const CrewCard = ({ crew: c }: CrewsListProps) => {
  const location =
    c.location && c.locationDescription
      ? `${c.location} - ${c.locationDescription}`
      : '---';

  const division =
    c.division && c.divisionDescription
      ? `${c.division} - ${c.divisionDescription}`
      : '---';
  const department =
    c.department && c.departmentDescription
      ? `${c.department} - ${c.departmentDescription}`
      : '---';

  return (
    <Card>
      <Card.Header>
        <div className="flex items-center">
          <div data-testid="heading">
            <Title as="h2" aria-label="Crew Name">
              {c.crew}
            </Title>

            <p className="text-gray-700 text-sm text-left line-clamp-1">
              {c.description}
            </p>
          </div>
        </div>
        <div className="flex items-center" data-testid="badge">
          {
            <Badge variant={getOrgStatusColor(c.status.code)}>
              <span>{getOrgStatusText(c.status.code)}</span>
            </Badge>
          }
        </div>
      </Card.Header>
      <Card.Body>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <LabeledText label="Location">{location}</LabeledText>
          <LabeledText label="Division">{division}</LabeledText>
          <LabeledText label="Department">{department}</LabeledText>
          <LabeledText label="Crew Member Count">{'-'}</LabeledText>
        </div>
      </Card.Body>
    </Card>
  );
};
