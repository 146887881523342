import { Person } from 'features/people';
import { Timecard } from '../types';

export const holidayNotWorkedCheck = (
  isHoliday: boolean,
  timecard: Timecard,
  employee?: Person
) => {
  if (
    (employee?.plantType === 'integrated_mill' ||
      employee?.plantType === 'office') &&
    (employee.empClass === '3' || employee.empClass === '6')
  ) {
    return (
      isHoliday &&
      timecard.scheduleVariance.variance &&
      timecard.scheduleVariance.variance < 0 &&
      timecard.scheduleVariance.payCode !== 'HS'
    );
  }
};
