import { QueryStatus } from '@tanstack/react-query';
import { Select } from '@uss/react-components';
import Loader from 'components/Skeleton/Loader';
import getAdjustmentTitle from 'features/timecards/utilities/getAdjustmentTitle';
interface AdjustmentSelectProps {
  selected: number | null;
  hasTimecard: boolean;
  adjustments: number[] | undefined;
  setAdjustment: (value: number | null) => void;
  status: QueryStatus;
  disabled?: boolean;
}

const AdjustmentSelect = ({
  selected,
  hasTimecard,
  adjustments,
  setAdjustment,
  status,
  disabled = false,
}: AdjustmentSelectProps) => {
  return (
    <>
      {status === 'pending' ? (
        <div className="w-full">
          <Loader isFilter={true} />
        </div>
      ) : (
        <Select
          selected={selected ? selected : '0'}
          onSelect={(value: number) => {
            setAdjustment(value);
          }}
          className="w-full"
          disabled={!hasTimecard || disabled}
          data-testid="adjustment-select-filter"
        >
          {<Select.Button label={getAdjustmentTitle(selected)} />}
          <Select.Options>
            {!hasTimecard && (
              <Select.Option key={'none'} value={0} truncate>
                No Adjustments
              </Select.Option>
            )}
            {adjustments?.map((item, index) => {
              return (
                <Select.Option key={index} value={item} truncate>
                  {item === 0 ? `Original` : `Adjustment ${item}`}
                </Select.Option>
              );
            })}
          </Select.Options>
        </Select>
      )}
    </>
  );
};
export default AdjustmentSelect;
