import clsx from 'clsx';
import React from 'react';
import { Breadcrumbs } from '@uss/react-components';
import { BsChevronLeft } from 'react-icons/bs';
interface PageProps {
  heading?: string;
  breadcrumbs?: React.ReactNode[];
  align?: keyof typeof alignment;
  children: React.ReactNode;
}

const alignment = {
  left: 'justify-items-left',
  center: 'justify-items-center',
};

const Page = (props: PageProps): JSX.Element => {
  const { heading, breadcrumbs, align = 'left' } = props;
  return (
    <div className={`box-border py-4 ${alignment[align]}`}>
      <section
        className={clsx(
          breadcrumbs || heading ? 'grid grid-cols-1 gap-y-4 pb-4' : 'hidden'
        )}
      >
        {breadcrumbs && (
          <>
            <div className="px-5 flex items-center sm:hidden">
              <BsChevronLeft
                size="0.75rem"
                className="text-gray-600 mx-1 mt-px "
              />

              <Breadcrumbs nodes={[breadcrumbs[breadcrumbs.length - 1]]} />
            </div>
            <div className="px-5 hidden sm:block">
              <Breadcrumbs nodes={breadcrumbs} />
            </div>
          </>
        )}

        {heading && (
          <div className="px-5">
            <h1
              className="text-lg sm:text-2xl font-semibold text-gray-700"
              id="page-title"
              data-testid="page-title"
            >
              {heading}
            </h1>
          </div>
        )}
      </section>
      {props.children}
    </div>
  );
};

interface PageSectionProps {
  children: React.ReactNode;
  bg?: keyof typeof backgrounds;
  className?: string;
  padding?: boolean;
  isSticky?: boolean;
}

const backgrounds = {
  white: 'bg-white',
  gray: 'bg-gray-100',
  undefined: ' ',
};

Page.Section = function Section({
  children,
  bg = 'undefined',
  className = '',
  padding = false,
  isSticky = false,
}: PageSectionProps): JSX.Element {
  return (
    <section
      className={clsx([
        backgrounds[bg],
        '',
        '',

        'px-5 pb-4 last-of-type:pb-0',
        isSticky && 'md:sticky md:bg-gray-100 z-30',
        className,
      ])}
    >
      {children}
    </section>
  );
};

interface FiltersProps {
  children: React.ReactNode;
}

Page.Filters = function Filters({ children }: FiltersProps): JSX.Element {
  return (
    <section className="flex sm:flex-row flex-col items-center w-full  flex-wrap sm:flex-nowrap">
      {children}
    </section>
  );
};

interface FiltersLeftProps {
  children: React.ReactNode;
  className?: string;
}

Page.FiltersSection = function FiltersSection({
  children,
  className = '',
}: FiltersLeftProps): JSX.Element {
  return (
    <div
      className={clsx([
        `flex items-center mr-0 sm:mr-4 last-of-type:mr-0 mb-2 sm:mb-`,
        className,
      ])}
    >
      {children}
    </div>
  );
};

export default Page;
