import { compareAsc, format, parseISO } from 'date-fns';
import { ZodErrorType } from 'types/common';
import { z } from 'zod';
import { Timecard } from 'features/timecards/types';
import {
  TimecardRegularDaySchema,
  TimecardSchema,
} from 'features/timecards/schemas/timecardSchema';

const getTimecardErrors = ({ timecardArr }: { timecardArr: Timecard[] }) => {
  const validateRow = (timecard: Timecard) => {
    const isFutureDay = compareAsc(new Date(), parseISO(timecard.date)) < 0;
    const errObj: {
      date: string;
      message: ZodErrorType;
    } = { date: '', message: {} };
    try {
      if (isFutureDay) {
        TimecardSchema.parse(timecard);
      } else {
        TimecardRegularDaySchema.parse(timecard);
      }
    } catch (error: unknown) {
      if (error instanceof z.ZodError) {
        if (
          error.errors.length > 0 &&
          error.errors[0].path.length === 0 &&
          error.errors[0].message
        ) {
          const err = error.errors[0].message;
          errObj.date = timecard.date;
          errObj.message = { error: err };
        } else {
          let err = {};
          error.errors.map((acc: z.ZodIssue) => {
            const nameField = acc.path[0];
            err = { ...err, [nameField]: acc.message };
            return true;
          });

          errObj.date = timecard.date;
          errObj.message = err;
        }
      }
    }
    return errObj;
  };

  const errors = timecardArr.map((data) => validateRow(data));

  const errMessages = errors
    .map((data) => {
      const zodErrorObj = Object.keys(data.message);
      return zodErrorObj.map((key) => {
        return key === 'varianceNoPaycode' ||
          key === 'worked' ||
          key === 'approved' ||
          key === 'scheduled'
          ? '(' +
              format(parseISO(data.date), 'MMM d, E') +
              ') : ' +
              String(data.message[key])
          : '';
      });
    })
    .flat()
    .join(' | ');

  return { errMessages };
};

export default getTimecardErrors;
