import Card from 'components/Card/Card';
import SpinnerSection, {
  SpinnerSectionProps,
} from 'components/Spinner/SpinnerSection';

const InProgressCard = ({ title, description }: SpinnerSectionProps) => {
  return (
    <Card>
      <Card.Body>
        <div className="mt-20 mb-32 md:mt-18 md:mb-14">
          <SpinnerSection title={title} description={description} />
        </div>
      </Card.Body>
    </Card>
  );
};
export default InProgressCard;
