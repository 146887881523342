import Page from 'components/Layouts/Page';
import Tabs from 'components/Tabs/Tabs';
import { NavLink } from 'react-router-dom';
import { useMyPtoStore } from 'features/paid-time-off/state/mypto-store';
import { BsPlus } from 'react-icons/bs';
import PtoRemovedRecords from 'features/paid-time-off/components/MyPto/PtoRemovedRecords';
import MyPtoDetails from 'features/paid-time-off/components/MyPto/MyPtoDetails';
import { SellBackPTOView } from 'features/paid-time-off/components/SellBackPTO/SellBackPTOView';
import { AnnualTotals } from 'features/paid-time-off/components/AnnualTotals/AnnualTotals';
import EmployeeProfile from 'features/people/components/EmployeeProfile';
import { useLoggedInUser } from 'features/people/api';
import Title from 'components/Typography/Title';
import { UserView as IneligibleForPTO } from 'features/paid-time-off/components/UnauthorizedMsg/UnauthorizedMsg';
const MyPto = () => {
  const { selectedYear } = useMyPtoStore((state) => ({
    selectedYear: state.selectedYear,
  }));

  const { data: user } = useLoggedInUser();

  const ptoTabLinks = [
    {
      label: 'PTO Records',
      element: <MyPtoDetails />,
    },
    {
      label: 'Removed Records',
      element: <PtoRemovedRecords ussId={'me'} year={selectedYear} />,
    },
  ];

  return (
    <Page heading="My PTO (NU)">
      <IneligibleForPTO user={user}>
        <Page.Section>
          <div className="grid grid-cols-1 gap-4">
            {user?.paidSickTimeEligibility === 'n' && (
              <div className="w-full flex flex-wrap gap-4 md:gap-0">
               <SellBackPTOView plantType={user.plantType} />
                {(user.ptoClass === 'integrated-non-exempt' ||
                  user.ptoClass === 'mini-mill') && (
                  <div className="flex md:justify-end w-full md:w-max md:pl-8">
                    <NavLink
                      className="flex justify-end items-center pr-2"
                      to={`request-pto`}
                    >
                      <span className="text-primary-400 font-semibold flex items-center ">
                        <BsPlus size="1.5rem" />
                        <span className="ml-2">Request PTO</span>
                      </span>
                    </NavLink>
                  </div>
                )}
              </div>
            )}

            <EmployeeProfile />
            <AnnualTotals year={selectedYear} />
            {user?.paidSickTimeEligibility === 'y' ? (
              <>
                <Title as="h2" aria-label="Employee Name">
                  PTO Records
                </Title>
                <MyPtoDetails />
              </>
            ) : (
              <Tabs tabLinks={ptoTabLinks}></Tabs>
            )}
          </div>
        </Page.Section>
      </IneligibleForPTO>
    </Page>
  );
};

export default MyPto;
