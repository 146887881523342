import {
  Button,
  IconButton,
  Select,
  SelectOptions,
  Sidepane,
} from '@uss/react-core';
import { useModelList } from 'features/model-listing/api/queries';
import { MODEL_SORT, MODEL_SORT_ORDER, MODEL_STATUS } from 'features/model-listing/constants';
import { useGetModelOwners } from 'features/model-listing/hooks/useGetModelOwners';
import {
  ModelDrawerFilters,
  useModelListFiltersStore,
} from 'features/model-listing/state/model-list-store';
import {
  ModelListParams,
  ModelOwner,
  ModelStatus,
} from 'features/model-listing/types';
import { ORG_TYPE, Org } from 'features/org';
import { useEmployeeOrgs } from 'features/org/api';
import { useLoggedInUser } from 'features/people/api';
import useWindowSize from 'hooks/useWindowSize';
import { Key, useEffect, useState } from 'react';
import { BsX } from 'react-icons/bs';

interface RequestPTODrawerProps {
  openFilterDrawer: boolean;
  setOpenFilterDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModelListingDrawer = ({
  openFilterDrawer,
  setOpenFilterDrawer,
}: RequestPTODrawerProps) => {
  let isDevice = false;
  const { width } = useWindowSize();
  if (width && width > 768) {
    isDevice = true;
  }

  //store
  const store = useModelListFiltersStore();
  //filter states
  const [plant, setPlant] = useState<Org | null>(null);
  const [plantId, setPlantId] = useState<string | null>(null);

  const [payroll, setPayroll] = useState<Org | null>(null);
  const [locationId, setLocationId] = useState<string | null>(null);

  const [owner, setOwner] = useState<ModelOwner | null>(null);
  const [modelOwnerUssId, setModelOwnerUssId] = useState<number | null>(null);

  const [status, setStatus] = useState<ModelStatus | null>(null);

  const { data: user } = useLoggedInUser();
  //getting logged-in user id
  const ussId = user ? user.ussId.toString() : '';
  //get model list without pagination
  const { data: modelList } = useModelList(ussId, { 'sortBy': MODEL_SORT.MODEL_NAME, 'sortType': MODEL_SORT_ORDER.ASC });
  //get owner list
  const modelOwnersList = useGetModelOwners(modelList?.items ?? []);

  //plant selection
  const { data: plantList } = useEmployeeOrgs('me', {
    type: ORG_TYPE.PLANT,
    sortBy: 'plant',
  });

  //location selection
  const { data: locationList, status: locationStatus } = useEmployeeOrgs('me', {
    type: ORG_TYPE.LOCATION,
    sortBy: 'location',
    parentOrgId: !plantId ? undefined : plantId,
  });

  const fnUpdateFields = () => {
    //plant
    if (store.selectedPlant !== null) {
      setPlantId(store.selectedPlant.id);
      setPlant(store.selectedPlant);
    } else {
      setPlantId(null);
      setPlant(null);
    }
    //payroll location
    if (store.selectedPayrollLocation !== null) {
      setLocationId(store.selectedPayrollLocation.id);
      setPayroll(store.selectedPayrollLocation);
    } else {
      setLocationId(null);
      setPayroll(null);
    }
    //owner
    if (store.selectedModelOwner !== null) {
      setOwner(store.selectedModelOwner);
      setModelOwnerUssId(store.selectedModelOwner.ussId);
    } else {
      setOwner(null);
      setModelOwnerUssId(null);
    }
    //status
    if (store.selectedStatus !== null) setStatus(store.selectedStatus);
    else setStatus(null);
  };

  useEffect(() => {
    fnUpdateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    store.params,
    store.selectedPlant,
    store.selectedPayrollLocation,
    store.selectedModelOwner,
  ]);

  const handleClose = () => {
    setOpenFilterDrawer(false);
    fnUpdateFields();
  };

  //clear filters
  const handleClear = () => {
    setPlantId(null);
    setLocationId(null);
    setPayroll(null);
    setOwner(null);
    setStatus(null);
    store.resetFilters();
    handleClose();
  };

  const handlePlantSelect = (val: Key) => {
    setPlantId(val.toString());
    setPlant(plantList?.items.find((e) => e.id === val) ?? null);
    setPayroll(null);
    setLocationId(null);
  };
  const handlePayrollSelect = (val: Key) => {
    setLocationId(val.toString());
    setPayroll(locationList?.items.find((e) => e.id === val) ?? null);
  };
  const handleOwnerSelect = (val: Key) => {
    setModelOwnerUssId(Number(val));
    setOwner(modelOwnersList.find((e) => e.ussId === val) ?? null);
  };

  const handleStatus = (val: Key) => {
    setStatus(val.toString());
  };

  const handleApply = () => {
    //dropdown values to save in store
    const fields: ModelDrawerFilters = {
      selectedPlant: plant,
      selectedPayrollLocation: payroll,
      selectedModelOwner: owner,
      selectedStatus: status,
    } as const;

    //params values to save in store
    const payload: ModelListParams = { ...store.params };
    if (plantId !== null) payload.plant = plantId;
    if (locationId !== null) {
      payload.payrollLocation = locationId;
    } else delete payload.payrollLocation
    if (owner !== null) payload.modelOwner = owner.ussId;
    if (status !== null) payload.modelStatus = status;

    store.applyFilter(fields, payload);
    store.setIsFilterApplied(true);
    setOpenFilterDrawer(false);
  };

  return (
    <Sidepane
      ariaLabel="filter"
      placement={isDevice ? 'right' : 'bottom'}
      isOpen={openFilterDrawer}
      onOpenChange={handleClose}
    >
      <div className="w-full flex items-center">
        <h3 className="text-lg flex-grow">Filter</h3>
        <div className="flex flex-row items-center">
          <Button variant="text" onPress={handleClear} color="primary">
            Clear All
          </Button>
          <IconButton onPress={handleClose}>
            <BsX size="2rem" className="text-gray-600" />
          </IconButton>
        </div>
      </div>

      <div className="w-full flex flex-col flex-grow gap-5 mb-6 mt-6">
        <Select
          items={plantList?.items}
          selectedKey={plantId}
          onSelectionChange={(e) => handlePlantSelect(e)}
          label="Plant"
          className="w-full"
        >
          {(item) => (
            <SelectOptions id={item.id}>{`${item.plant}`}</SelectOptions>
          )}
        </Select>
        <Select
          items={locationList?.items}
          selectedKey={locationId}
          onSelectionChange={(e) => handlePayrollSelect(e)}
          label="Payroll Location"
          isDisabled={locationStatus === 'pending'}
          className="w-full"
        >
          {(item) => (
            <SelectOptions id={item.id}>
              {item.location &&
                `${item.location} - ${item.locationDescription ?? ''}`}
            </SelectOptions>
          )}
        </Select>

        <Select
          items={modelOwnersList}
          selectedKey={modelOwnerUssId}
          onSelectionChange={(e) => handleOwnerSelect(e)}
          label="Model Owner"
          className="w-full"
        >
          {(item) => (
            <SelectOptions id={item.ussId}>{item.displayName}</SelectOptions>
          )}
        </Select>

        <Select
          items={Object.entries(MODEL_STATUS)}
          selectedKey={status}
          placeholder={'Select'}
          onSelectionChange={(e) => handleStatus(e)}
          label="Status"
          className="w-full"
        >
          {(item) => <SelectOptions id={item[1]}>{item[0]}</SelectOptions>}
        </Select>
      </div>

      <div className="w-full sm:flex justify-end">
        <div className="sm:flex sm:justify-between sm:gap-2">
          <Button
            variant="outline"
            color={'primary'}
            full={true}
            type="button"
            onPressChange={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            color={'primary'}
            full={true}
            onPressChange={handleApply}
          >
            Apply
          </Button>
        </div>
      </div>
    </Sidepane>
  );
};
