import { Avatar } from '@uss/react-components';
import Card from 'components/Card/Card';
import SeparatedLabels from 'components/SeparatedLabels/SeparatedLabels';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';
import { format, parseISO } from 'date-fns';
import { PTO_STATUS } from 'features/paid-time-off/constants';
import { CrewPtoDetail } from 'features/paid-time-off/types';
import { getPtoStatusText } from 'features/paid-time-off/utility/getPtoStatusText';
import useWindowSize from 'hooks/useWindowSize';
import { avatarColor, getInitialsByDisplayName } from 'utils';
import { NavLink } from 'react-router-dom';
import { CrewPtoActions } from './CrewPtoActions';
import {
  GetOrgDetailsParamsBody,
  GetCrewParamsBody,
} from 'features/paid-time-off/types/crew-pto';

interface Props {
  pto: CrewPtoDetail;
  detailsRequest?: GetOrgDetailsParamsBody;
  totalsRequest?: GetCrewParamsBody;
}

const EmployeePtoCard = ({ pto, detailsRequest, totalsRequest }: Props) => {
  const { width } = useWindowSize();

  return (
    <Card
      bgColor={
        pto.ptoDetails.details[0]?.status === PTO_STATUS.COMPANY_BLACKOUT
          ? 'bg-gray-100'
          : 'bg-white'
      }
      border={
        pto.ptoDetails.details[0]?.status === PTO_STATUS.COMPANY_BLACKOUT
          ? 'border border-gray-700'
          : undefined
      }
    >
      <Card.Header>
        <div className="sm:flex flex-col w-full h-full ">
          <div className="flex items-center w-full relative lg:after:h-0.5 lg:after:absolute lg:after:mt-16 lg:after:w-full md:after:h-0.5 md:after:absolute md:after:mt-16 md:after:w-full">
            <Avatar color={avatarColor(Number(pto.ussId))} size="medium">
              {getInitialsByDisplayName(pto.displayName)}
            </Avatar>
            <div className="pl-3 w-full">
              <Title as="h2">
                <NavLink
                  to={`/paid-time-off/employees/${pto.ussId}`}
                  className="text-primary-400 cursor-pointer"
                >
                  {pto.displayName}
                </NavLink>
              </Title>
              <SeparatedLabels
                size="md"
                items={[
                  { label: 'USS ID', value: pto.ussId, width: 'w-20' },
                  {
                    label: 'Emp Class',
                    value: pto.empClass
                      ? pto.empClass + ' - ' + pto.empClassDesc
                      : '-',
                  },
                ]}
              />
            </div>
          </div>
        </div>

        <CrewPtoActions
          placement="header"
          pto={pto}
          detailsRequest={detailsRequest}
          totalsRequest={totalsRequest}
        />
      </Card.Header>
      <Card.Body>
        <div className="flex flex-wrap justify-between lg:flex-nowrap w-full md:flex-nowrap">
          <LabeledText
            label="Status"
            lineClamp={1}
            className="w-1/2 lg:w-1/5 mb-4  lg:mb-0"
          >
            {pto.ptoDetails.details[0]?.status === PTO_STATUS.COMPANY_BLACKOUT
              ? 'Blackout'
              : getPtoStatusText(pto.ptoDetails.details[0]?.status)}
          </LabeledText>

          <LabeledText
            label="PTO Date"
            lineClamp={1}
            className="w-1/2 lg:w-1/5 mb-4 lg:mb-0"
          >
            {pto.date ? format(parseISO(pto.date), 'MMM dd, yyyy') : '--'}
          </LabeledText>
          <LabeledText
            label="Pay Code"
            lineClamp={1}
            className="w-1/2 lg:w-1/5 mb-4 lg:mb-0"
          >
            {pto.payCode} - {pto.payCodeDescription}
          </LabeledText>
          <LabeledText
            label="Remaining"
            lineClamp={1}
            className="w-1/2 lg:w-1/5 mb-4 lg:mb-0"
          >
            {pto.remainingPto ? pto.remainingPto.toString() + ' Hours' : '--'}
          </LabeledText>
          <LabeledText
            label="Requested On"
            lineClamp={1}
            className="w-full lg:w-4/12 mb-4 last-of-type:mb-0 lg:mb-0"
          >
            {format(new Date(pto.requestedOn), 'MMM dd, yyyy, hh:mm aa')}
          </LabeledText>
        </div>
      </Card.Body>
      {width && width < 780 && (
        <Card.Footer>
          <CrewPtoActions
            placement="footer"
            pto={pto}
            detailsRequest={detailsRequest}
            totalsRequest={totalsRequest}
          />
        </Card.Footer>
      )}
    </Card>
  );
};

export default EmployeePtoCard;
