import { EMPLOYEE_CLASSES, EmpClasses } from 'features/timecards/types';

const getFormattedEmpClassFilter = (status: EmpClasses) => {
  switch (status) {
    case EMPLOYEE_CLASSES.EXEMPT:
      return '4 - Exempt';
    case EMPLOYEE_CLASSES.NON_EXEMPT:
      return '3 - Non-exempt';
    case EMPLOYEE_CLASSES.PART_TIME:
      return '0 - Part Time';
    case EMPLOYEE_CLASSES.NON_EXCEMPT_P_M:
      return '6 - Non-exempt P&M';
  }
};
export default getFormattedEmpClassFilter;
