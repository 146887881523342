import OrgProvider from 'features/org/context/OrgProvider';
import React from 'react';
import { Outlet } from 'react-router-dom';

const index = () => {
  return (
    <OrgProvider>
      <Outlet />
    </OrgProvider>
  );
};

export default index;
