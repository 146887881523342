import IconBadge from 'components/Badge/IconBadge';
import { format } from 'date-fns';
import { getPtoStatusIcon } from 'features/paid-time-off/utility/getPtoStatusIcon';
import { BsXLg } from 'react-icons/bs';
import { CalendarBlock } from 'components/CalendarV2/types';

const CrewPtoMonthContent = ({
  calendardata,
}: {
  calendardata?: CalendarBlock | null;
}) => {
  return (
    <>
      <span
        className="sm:flex items-end flex-wrap w-full h-full hidden"
        data-testid="pto-event"
      >
        {calendardata?.events &&
          calendardata.events.map((item, i) => (
            <span
              className="w-1/2 flex items-center mb-2"
              key={`calendar-Block-${format(item.date, 'MM-dd-yyyy')}-${
                item.status ? item.status : ''
              }`}
            >
              <IconBadge
                color={item.color}
                badgeIcon={item.status ? getPtoStatusIcon(item.status) : <></>}
                onlyIcon
              />
              <span
                className="text-sm text-gray-700"
                data-testid="pto-event-text"
              >
                {item.text}
              </span>
            </span>
          ))}
      </span>

      <span className="flex items-end justify-start sm:justify-end w-full  flex-grow sm:hidden">
        {calendardata?.events &&
          calendardata.disabled !== true &&
          calendardata.events.length > 0 && (
            <span
              className="bg-gray-900  h-1.5 w-1.5 mr-0.5 rounded-full"
              data-testid="mobile-pto-event"
            ></span>
          )}
      </span>
      {calendardata && calendardata.text === 'Blackout' ? (
        <span
          className="flex justify-start sm:justify-end items-end h-full w-full"
          data-testid="blackout-icon"
        >
          <BsXLg className="hidden sm:inline-block" />
          <BsXLg size={'0.5rem'} className="inline-block sm:hidden" />
        </span>
      ) : (
        ''
      )}
    </>
  );
};
export default CrewPtoMonthContent;
