import { Table } from '@uss/react-components';
import { format, parseISO } from 'date-fns';
import { PayPeriodList } from 'features/pay-periods';

interface PayCycleTableProps {
  paycycles: PayPeriodList | undefined;
}

const PayCycleTable = ({
  paycycles = {
    items: [],
    lastPayCycleLoadedDate: '',
    page: 0,
    pageSize: 10,
    totalCount: 0,
  },
}: PayCycleTableProps) => {
  return (
    <Table>
      <Table.Header>
        <Table.HeaderRow>
          <Table.HeaderCell scope="col" className="h-14">
            Pay Period
          </Table.HeaderCell>
          <Table.HeaderCell scope="col" className="h-14">
            Date Range
          </Table.HeaderCell>
          <Table.HeaderCell scope="col" className="h-14">
            Approve By
          </Table.HeaderCell>
          <Table.HeaderCell scope="col" className="h-14">
            Pay Date
          </Table.HeaderCell>
          <Table.HeaderCell scope="col" className="h-14">
            Number of Weeks
          </Table.HeaderCell>
        </Table.HeaderRow>
      </Table.Header>
      {paycycles.items.length > 0 && (
        <Table.Body>
          {paycycles.items.map((pc) => {
            return (
              <Table.BodyRow key={pc.id}>
                <Table.BodyCell>
                  <span className="line-clamp-2">{pc.payPeriod}</span>
                </Table.BodyCell>
                <Table.BodyCell>
                  <span className="line-clamp-2">
                    {`${format(parseISO(pc.startDate), 'MMM dd')} - 
                    ${format(parseISO(pc.endDate), 'MMM dd, yyyy')}`}
                  </span>
                </Table.BodyCell>
                <Table.BodyCell>
                  <span className="line-clamp-2">
                    {`${format(
                      parseISO(pc.approvalByDate),
                      'MMM dd, yyyy, hh:mm aa'
                    )} ET`}
                  </span>
                </Table.BodyCell>
                <Table.BodyCell>
                  <span className="line-clamp-2">
                    {`${format(parseISO(pc.payDate), 'MMM dd, yyyy')}`}
                  </span>
                </Table.BodyCell>
                <Table.BodyCell>
                  <span className="line-clamp-2">{pc.noOfWeeks}</span>
                </Table.BodyCell>
              </Table.BodyRow>
            );
          })}
        </Table.Body>
      )}
      {paycycles.items.length === 0 && (
        <tbody>
          <tr className="text-center h-24 py-12">
            <td colSpan={5}>No Pay Cycles Returned</td>
          </tr>
        </tbody>
      )}
    </Table>
  );
};

export default PayCycleTable;
