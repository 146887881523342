import Loader from 'components/Skeleton/Loader';
interface WeekCardLoaderProps {
  numberOfWeeks: number;
  numberOfCrewRotations: number;
  numberOfGridCols: number;
}

export const WeekCardLoader = ({
  numberOfWeeks,
  numberOfCrewRotations,
  numberOfGridCols,
}: WeekCardLoaderProps) => {
  const weeksArray = Array.from(Array(numberOfWeeks));
  const crewRotationArray = Array.from(Array(numberOfCrewRotations));

  return (
    <>
      <div
        className={`grid grid-cols-1 md:grid-cols-${numberOfGridCols} sm:grid-cols-${numberOfGridCols} 2xl:grid-cols-4 gap-4`}
      >
        {weeksArray.map((n, i) => {
          return (
            <>
              <div
                key={i}
                className=" flex flex-col bg-white max-h-max w-full shadow-md rounded-md p-4 "
              >
                <div className="border-b-2 border-gray-300  pb-2 ">
                  <Loader rows={1} />
                </div>
                <div>
                  <div className="w-full grid gap-2 grid-cols-7 items-center">
                    {Array.from(Array(7)).map((day, _index) => (
                      <div key={_index}>
                        {crewRotationArray.map((_day, _index) => (
                          <div key={_index} className="p-1">
                            <Loader rows={1} />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
