import { Button, DropdownMenu } from '@uss/react-components';
import { PtoDetailsFields } from 'features/paid-time-off/types';
import {
  PtoDetailDisabledButtons,
  PtoScreenType,
} from 'features/paid-time-off/types/pto-requests';
import { BiChevronUp } from 'react-icons/bi';

export const ActionButtons = ({
  ptoType,
  buttonStates,
  actions,
  ptoDetails,
  editAwaiting,
  removeAwaiting,
}: {
  ptoType: PtoScreenType;
  buttonStates: PtoDetailDisabledButtons;
  actions: {
    handleSave: (action: string) => void;
    handleCancel: () => void;
    handleRemove: () => void;
    handleBlackoutSave: () => void;
  };
  ptoDetails: PtoDetailsFields;
  editAwaiting: boolean;
  removeAwaiting: boolean;
}) => {
  const {
    isSaveDisabled,
    isRemoveDisabled,
    isDenyDisabled,
    isApproveDisabled,
  } = buttonStates;

  return (
    <div className="gap-3 flex justify-end w-full sm:flex-nowrap flex-wrap mt-4">
      {ptoType === 'myPto' && (
        <>
          <Button
            onClick={actions.handleCancel}
            variant="outline"
            className=" w-full sm:w-auto"
            type="button"
          >
            Cancel
          </Button>
          <Button
            onClick={actions.handleRemove}
            variant="outline"
            className=" w-full sm:w-auto border-red-600 text-red-600 hover:border-red-700 hover:text-red-700"
            type="button"
            disabled={isRemoveDisabled || removeAwaiting}
          >
            Remove
          </Button>
          <Button
            variant="primary"
            onClick={() => actions.handleSave('save')}
            type="button"
            className=" w-full sm:w-auto"
            disabled={isSaveDisabled || editAwaiting}
          >
            Save
          </Button>
        </>
      )}
      {ptoType === 'empPto' && (
        <>
          <DropdownMenu>
            <DropdownMenu.Button
              className="flex justify-center items-center text-base border 
        relative rounded-sm outline-none focus-visible:ring-opacity-100 focus-visible:ring focus-visible:ring-accent1-400 focus-visible:border-accent1-400  px-8 py-1.5 disabled:text-gray-500 disabled:bg-gray-300 disabled:border-gray-300  bg-white text-primary-400 border-primary-400  hover:text-primary-700 hover:border-primary-700  w-full sm:w-auto"
            >
              More <BiChevronUp size="1.25rem" className="ml-2" />
            </DropdownMenu.Button>
            <DropdownMenu.Items
              renderAs="ol"
              className="w-24 pt-3 pb-2 shadow-md -top-24 lg:-top-36 "
            >
              <DropdownMenu.Item
                renderAs="li"
                key="Cancel"
                className="text-gray-700 text-sm flex flex-col items-start"
              >
                <Button
                  onClick={actions.handleCancel}
                  variant="text"
                  className="text-sm sm:my-3 border-blue-800 hover:border-blue-900 hover:text-blue-900"
                  type="button"
                >
                  Cancel
                </Button>
              </DropdownMenu.Item>
              <DropdownMenu.Item renderAs="li" key="Remove">
                <Button
                  onClick={actions.handleRemove}
                  variant="text"
                  className="text-sm border-red-600 text-red-600 hover:border-red-700 hover:text-red-700"
                  type="button"
                  disabled={isRemoveDisabled || removeAwaiting}
                >
                  Remove
                </Button>
              </DropdownMenu.Item>
              <DropdownMenu.Item renderAs="li" key="Save">
                <Button
                  variant="text"
                  className={`text-sm sm:my-3 ${
                    !isSaveDisabled ? 'hover:text-blue-900 ' : ''
                  }`}
                  onClick={
                    ptoDetails.status === 'blackout'
                      ? () => actions.handleBlackoutSave()
                      : () => actions.handleSave('save')
                  }
                  type="button"
                  disabled={isSaveDisabled || editAwaiting}
                >
                  Save
                </Button>
              </DropdownMenu.Item>
            </DropdownMenu.Items>
          </DropdownMenu>
          <Button
            onClick={() => actions.handleSave('deny')}
            variant="outline"
            type="button"
            className=" w-full sm:w-auto"
            disabled={isDenyDisabled}
          >
            Save & Deny
          </Button>
          <Button
            onClick={() => actions.handleSave('approve')}
            variant="primary"
            type="button"
            disabled={isApproveDisabled}
            className=" w-full sm:w-auto"
          >
            Save & Approve
          </Button>
        </>
      )}
    </div>
  );
};
