import React, { useState } from 'react';
import { Button } from '@uss/react-components';
import { BsCheck2, BsTrash, BsX, BsXLg } from 'react-icons/bs';
import { useRemoveMileage, useUpdateMileage } from '../api/mutation';
import useUserRoles from 'hooks/useUserRoles';
import {
  MileageActionsVal,
  MileageDetails,
  MileageStatusTypeVal,
} from '../types/mileageEntry';
import useModal from 'components/Modals/use-modal';
import { format, parseISO } from 'date-fns';
import { ROLES } from 'features/roles';
import { notify } from 'components/Notification/useNotification';
import { queryClient } from 'services/api/react-query';

interface MileageActionProps {
  inHeader?: boolean;
  inFooter?: boolean;
  mileageDetails: MileageDetails;
}
export const MileageActions = ({
  inHeader,
  inFooter,
  mileageDetails,
}: MileageActionProps) => {
  const { uss_id, roles } = useUserRoles();
  const [selectedAction, setSelectedAction] = useState<MileageActionsVal | ''>(
    ''
  );
  //Remove Mileage Entry
  const { mutateAsync } = useRemoveMileage();
  //Update Mileage Entry
  const { mutateAsync: mutateAction } = useUpdateMileage(
    uss_id,
    selectedAction,
    mileageDetails.displayName
  );

  //For Schedule-Pay Viewer this page is read-only
  const isReadOnly = roles[0] === ROLES.SCHEDULE_PAY_VIEWER;

  //popup for remove
  const modal = useModal();
  const openModal = ({
    title,
    message,
    type,
  }: {
    title: string;
    message: string;
    type: 'danger';
  }) => {
    modal.openModal({
      title: title,
      type: type,
      children: <span>{message}</span>,
      labels: {
        confirm: 'Remove',
        cancel: 'Cancel',
      },
      confirmVariant: 'danger',
      onCancel: () => modal.closeModal(),
      onConfirm: () => handleMileageRemove(mileageDetails.mileageDetailsId),
    });
  };
  const handleRemove = () => {
    openModal({
      title: 'Remove Mileage Entry?',
      message: `Are you sure you want to delete Mileage Entry for
        ${format(parseISO(mileageDetails.travelDate), 'MMMM dd, yyyy')}?`,
      type: 'danger',
    });
  };

  const handleMileageRemove = async (mileageDetailsId: string) => {
    await mutateAsync(
      {
        mileageDetailsId: mileageDetailsId,
      },
      {
        onSuccess: async () => {
          notify.success({
            message: 'The mileage entry has been removed successfully.',
          });
          modal.closeModal();
          await queryClient.resetQueries({
            queryKey: ['mileage-details'],
            type: 'active',
          });
        },
      }
    );
  };

  //for approve/deny
  const handleMileageAction = async (
    action: MileageActionsVal,
    mileageDetailsId: string
  ) => {
    setSelectedAction(action);
    await mutateAction({
      actionStatus: action,
      payload: {
        items: [
          {
            status: action,
            mileageDetailsId: mileageDetailsId,
          },
        ],
      },
    });
  };

  const isDisabled =
    mileageDetails.status === MileageStatusTypeVal.PAID || isReadOnly;

  return (
    <>
      {inHeader && (
        <div className="flex justify-between items-start sm:items-center sm:mt-0 mt-2">
          <Button
            variant="text"
            className="hidden sm:flex "
            onClick={() =>
              handleMileageAction('APPROVED', mileageDetails.mileageDetailsId)
            }
            disabled={
              isDisabled ||
              mileageDetails.status === MileageStatusTypeVal.APPROVED
            }
          >
            <BsCheck2
              id="right"
              size={'1.75rem'}
              className={`${
                isDisabled ||
                mileageDetails.status === MileageStatusTypeVal.APPROVED
                  ? 'pointer-events-none cursor-not-allowed text-gray-500'
                  : 'text-primary-400 cursor-pointer'
              } `}
              aria-disabled={isReadOnly}
            />
          </Button>
          <Button
            variant="text"
            className={'hidden sm:flex mx-6'}
            onClick={() =>
              handleMileageAction('DENIED', mileageDetails.mileageDetailsId)
            }
            disabled={
              isDisabled ||
              mileageDetails.status === MileageStatusTypeVal.DENIED
            }
          >
            <BsXLg
              id="cross"
              size={'1.25rem'}
              className={`${
                isDisabled ||
                mileageDetails.status === MileageStatusTypeVal.DENIED
                  ? 'pointer-events-none cursor-not-allowed text-gray-500'
                  : 'text-primary-400 cursor-pointer'
              } `}
            />
          </Button>
          <Button
            variant="text"
            className="flex"
            onClick={handleRemove}
            disabled={isDisabled}
          >
            <BsTrash
              id="delete"
              size={'1.25rem'}
              className={`${
                isDisabled
                  ? 'pointer-events-none cursor-not-allowed text-gray-500'
                  : 'text-red-600 cursor-pointer'
              } `}
            />
          </Button>
        </div>
      )}
      {inFooter && (
        <div className="flex sm:hidden w-full ">
          <div className="flex w-1/2 justify-center">
            <Button
              variant="text"
              className={`text-sm ${
                isDisabled ||
                mileageDetails.status === MileageStatusTypeVal.APPROVED
                  ? 'pointer-events-none cursor-not-allowed text-gray-400'
                  : 'text-primary-400 cursor-pointer'
              } `}
              onClick={() =>
                handleMileageAction('APPROVED', mileageDetails.mileageDetailsId)
              }
              disabled={
                isDisabled ||
                mileageDetails.status === MileageStatusTypeVal.APPROVED
              }
            >
              <BsCheck2 id="right" className="text-2xl" />
              <span className="text-base ml-2 ">Approve</span>
            </Button>
          </div>
          <div className="bg-gray-300 w-0.5 mx-6"></div>
          <div className="flex w-1/2 justify-center">
            <Button
              variant="text"
              className={`text-sm ${
                isDisabled ||
                mileageDetails.status === MileageStatusTypeVal.DENIED
                  ? 'pointer-events-none cursor-not-allowed text-gray-400'
                  : 'text-primary-400 cursor-pointer'
              } `}
              onClick={() =>
                handleMileageAction('DENIED', mileageDetails.mileageDetailsId)
              }
              disabled={
                isDisabled ||
                mileageDetails.status === MileageStatusTypeVal.DENIED
              }
            >
              <BsX id="cross" className="text-3xl" />
              <span className="text-base ml-2"> Deny</span>
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
