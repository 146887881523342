import { PAYROLL_GROUP_TYPE } from '../constants';
import { PayrollGroupType } from '../types';

const getPayrollGroupTypeText = (value: PayrollGroupType) => {
  switch (value) {
    case PAYROLL_GROUP_TYPE.UNION:
      return 'Union';
    case PAYROLL_GROUP_TYPE.NON_UNION:
      return 'Non Union';
  }
};
export default getPayrollGroupTypeText;
