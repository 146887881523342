import {
  AreaManagerTabs,
  HrLrTabs,
  ReviewStatus,
  ReviewerTabs,
} from '../types';
import {
  ReviewActionLabel,
  UserWarnedFlag,
} from '../types/employeeReviewDetails';
import getCurrentView from './getCurrentView';

export const getViewPermissionsByRole = (
  pathname: string,
  reviewStatus: ReviewStatus | null,
  currentTab?: AreaManagerTabs | ReviewerTabs | HrLrTabs | undefined,
  userWarnedFlag?: UserWarnedFlag
): {
  isFormDisabled: boolean;
  showAddCommentsSection: boolean;
  actionText: string;
  isActionButtonsDisabled: boolean;
  actionButton1Label: ReviewActionLabel;
  actionButton2Label: ReviewActionLabel;
  showUntilNextReviewMsg: boolean;
  showDangerTerminateBtn: boolean;
} => {
  const { isAreaManagerView, isReviewerView, isHrLrView, isEmployeeView } =
    getCurrentView(pathname);

  const isDisabledForAreaManager =
    isAreaManagerView &&
    reviewStatus !== ReviewStatus.EMP_TERMINATED &&
    reviewStatus !== ReviewStatus.SUPERVISOR_REVIEW;

  const isDisabledForReviewer =
    isReviewerView &&
    reviewStatus !== ReviewStatus.REVIEW_DUE &&
    reviewStatus !== ReviewStatus.FINAL_REVIEW &&
    reviewStatus !== ReviewStatus.REVALUATE &&
    reviewStatus !== ReviewStatus.COUNSELLING;

  const isDisabledForHrLr =
    isHrLrView && reviewStatus !== ReviewStatus.HR_LR_REVIEW;

  const isDisabledForEmployee =
    isEmployeeView && reviewStatus !== ReviewStatus.EMP_ACKNOWLEDGEMENT;

  const getIsFormDisabled = () =>
    isReviewerView && !isDisabledForReviewer
      ? currentTab === ReviewerTabs.REVIEW_DUE ||
        currentTab === ReviewerTabs.RE_EVALUATE
        ? false
        : true
      : true; //needs to be disabled for area manager, hr/lr and employee for all tabs

  const getShowAddCommentsSection = () =>
    (isAreaManagerView && currentTab === AreaManagerTabs.TERMINATION) ||
    isDisabledForAreaManager ||
    isDisabledForReviewer ||
    (isDisabledForHrLr && currentTab === HrLrTabs.REVIEW_PERFORMANCE) ||
    isEmployeeView
      ? false
      : true;

  const getActionText = () =>
    isAreaManagerView && currentTab === AreaManagerTabs.TERMINATION
      ? 'I agree that above employee has been terminated.'
      : isReviewerView && currentTab !== ReviewerTabs.FINAL_REVIEW
      ? 'Reviewer Recommendation:'
      : (isReviewerView && currentTab === ReviewerTabs.FINAL_REVIEW) ||
        (isHrLrView && currentTab !== HrLrTabs.REVIEW_PERFORMANCE)
      ? ''
      : isEmployeeView
      ? 'I have read this document and discussed the contents with my manager or supervisor. My signature means that I have been advised of my performance evaluation and does not mean that I agree or disagree with the evaluation or any statement contained in this document.'
      : "I recommend this employee's employment to";

  const getIsActionButtonDisabled = () =>
    isDisabledForAreaManager ||
    isDisabledForReviewer ||
    (isDisabledForHrLr && currentTab === HrLrTabs.REVIEW_PERFORMANCE) ||
    isDisabledForEmployee;

  const getActionButton1Label = () =>
    (isAreaManagerView && currentTab === AreaManagerTabs.TERMINATION) ||
    isEmployeeView
      ? ReviewActionLabel.ACKNOWLEDGE
      : isReviewerView && currentTab === ReviewerTabs.FINAL_REVIEW
      ? ReviewActionLabel.COMPLETE
      : isHrLrView && currentTab !== HrLrTabs.REVIEW_PERFORMANCE
      ? ReviewActionLabel.ADD
      : ReviewActionLabel.RETAIN;

  const getActionButton2Label = () =>
    (isAreaManagerView && currentTab === AreaManagerTabs.TERMINATION) ||
    isEmployeeView ||
    (isHrLrView && currentTab !== HrLrTabs.REVIEW_PERFORMANCE) ||
    (isReviewerView && currentTab === ReviewerTabs.FINAL_REVIEW)
      ? ReviewActionLabel.CANCEL
      : ReviewActionLabel.TERMINATION;

  const getShowDangerTermination = () =>
    isHrLrView && currentTab === HrLrTabs.REVIEW_PERFORMANCE;

  const getShowUntilNextReviewMsg = () =>
    isEmployeeView && userWarnedFlag === UserWarnedFlag.Y;

  return {
    isFormDisabled: getIsFormDisabled(),
    showAddCommentsSection: getShowAddCommentsSection(),
    actionText: getActionText(),
    isActionButtonsDisabled: getIsActionButtonDisabled(),
    actionButton1Label: getActionButton1Label(),
    actionButton2Label: getActionButton2Label(),
    showUntilNextReviewMsg: getShowUntilNextReviewMsg(),
    showDangerTerminateBtn: getShowDangerTermination(),
  };
};
