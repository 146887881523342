import useUserRoles from 'hooks/useUserRoles';
import { ROLES } from 'features/roles/types/';
import { OrgList } from 'features/org';

const useTimecardAccess = (
  crews: OrgList | undefined,
  crewId: string | undefined | null
) => {
  // Create array of crewId's

  const { roles } = useUserRoles();

  const checkCrewAccess = (
    crewList: string[] | undefined,
    crew: string | undefined | null
  ) => {
    if (crewList && crew) {
      return !crewList.includes(crew);
    } else {
      return true;
    }
  };

  //Checking if user is schedule pay viewer and it would be used to disable timecard and actions
  const isScheduledPayViewer = roles.includes(ROLES.SCHEDULE_PAY_VIEWER);

  const isTimecardDisabled =
    checkCrewAccess(
      crews?.items.map((crew) => crew.id),
      crewId
    ) || isScheduledPayViewer;

  return { isTimecardDisabled };
};

export default useTimecardAccess;
