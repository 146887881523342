import {
  Actions,
  CommentRole,
  UserWarnedFlag,
  performanceCriteriaTypeObj,
} from 'features/probationary-tracking/types/employeeReviewDetails';
import { z } from 'zod';
import { ReviewStatus, AttendanceType } from '../types';
import { PERSON_STATUS } from 'features/people';

const commentSchema = z
  .string()
  .max(500)
  .min(1, { message: 'Comment cannot be empty' });

const EmployeeDetailSchema = z.object({
  ussId: z.number(),
  lastName: z.string(),
  firstName: z.string(),
  middleName: z.string(),
  displayName: z.string(),
});

const ReviewPerformanceDetailSchema = z.object({
  abilityToWorkSafely: performanceCriteriaTypeObj,
  abilityToWorkWithOthers: performanceCriteriaTypeObj,
  qualityOfWork: performanceCriteriaTypeObj,
  quantityOfWork: performanceCriteriaTypeObj,
  undOfWrittenAndVerbalInst: performanceCriteriaTypeObj,
  willingnessAndCooperation: performanceCriteriaTypeObj,
  overallEmpValue: performanceCriteriaTypeObj,
  overallRating: performanceCriteriaTypeObj,
});

const AttendanceDetailSchema = z.object({
  absentDate: z.string(),
  tardy: z.boolean(),
  type: z.nativeEnum(AttendanceType),
});

const CommentsSchema = z.object({
  id: z.string(),
  comment: z.string(),
  date: z.string(),
  lastName: z.string(),
  firstName: z.string(),
  displayName: z.string(),
  role: z.nativeEnum(CommentRole),
});

export const ReviewGetSchema = z.object({
  id: z.string(),
  ussId: z.number(),
  lastName: z.string(),
  firstName: z.string(),
  middleName: z.string().nullable(),
  displayName: z.string(),
  status: z.nativeEnum(PERSON_STATUS),
  reviewStatus: z.nativeEnum(ReviewStatus).nullable(),
  active: z.boolean(),
  reviewNumber: z.number(),
  workedHours: z.number().nullable(),
  reviewPeriodStart: z.string().nullable(),
  reviewPeriodEnd: z.string().nullable(),
  approvedDate: z.string().nullable(),
  reviewer: EmployeeDetailSchema,
  supervisor: EmployeeDetailSchema,
  performance: ReviewPerformanceDetailSchema,
  attendance: z.array(AttendanceDetailSchema),
  comments: z.array(CommentsSchema).nullable(),
  userWarnedFlag: z.nativeEnum(UserWarnedFlag),
});

export const ReviewPutSchema = z.object({
  abilityToWorkSafely: performanceCriteriaTypeObj,
  abilityToWorkWithOthers: performanceCriteriaTypeObj,
  qualityOfWork: performanceCriteriaTypeObj,
  quantityOfWork: performanceCriteriaTypeObj,
  undOfWrittenAndVerbalInst: performanceCriteriaTypeObj,
  willingnessAndCooperation: performanceCriteriaTypeObj,
  overallEmpValue: performanceCriteriaTypeObj,
  overallRating: performanceCriteriaTypeObj,
  comment: commentSchema,
  action: z.nativeEnum(Actions),
});

export const ReviewPatchSchema = z.object({
  action: z.nativeEnum(Actions),
  comment: commentSchema.optional(),
});
