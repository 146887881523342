import { QueryStatus } from '@tanstack/react-query';
import { Autocomplete, Button, Drawer } from '@uss/react-components';
import { useSearchReportees } from 'features/people/api';
import { PeopleResponse, Person, ReporteeOptions } from 'features/people/types';
import { useState } from 'react';
interface AssignReviewer {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateReviewer: (reviewer: Person | null) => Promise<void>;
  selectionText: string;
  title: string;
  actionTitle: string;
  isUpdating: boolean;
  supervisorId?: 'me' | number;
}

const AssignReviewerDrawer = ({
  isOpen,
  setIsOpen,
  handleUpdateReviewer,
  selectionText,
  title,
  actionTitle,
  isUpdating = false,
  supervisorId = 'me',
}: AssignReviewer) => {
  const [selectedOption, setSelectedOption] = useState<Person | null>(null);
  const [inputValue, setInputValue] = useState('');
  const searchPayload: ReporteeOptions = {
    search: inputValue,
    page: '1',
    pageSize: '50',
  };
  //Supervisor ID need to be passed for HRLR role since the HRLR need to see the same list as the Supervisor does
  const { data: filteredOptions, status } = useSearchReportees(
    !!supervisorId ? supervisorId.toString() : '',
    searchPayload
  );

  const clearAll = () => {
    setInputValue(() => '');
    setSelectedOption(null);
  };
  const onCancelSearch = (): void => {
    clearAll();
  };
  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.currentTarget.value);
    if (e.currentTarget.value === '') {
      clearAll();
    }
  };
  const handleSelect = (option: Person): void => {
    setInputValue(option.lastName);
    setSelectedOption(option);
  };
  const handleClose = () => {
    clearAll();
    setIsOpen(false);
  };
  return (
    <Drawer
      placement={window.innerWidth > 768 ? 'right' : 'bottom'}
      title={title}
      isOpen={isOpen}
      onClose={handleClose}
      closeButton={true}
      className="w-full sm:w-1/2 md:w-1/3 xl:w-1/4 2xl:w-1/5 md:top-0 top-18 md:rounded-t-none rounded-t-md"
    >
      <div>
        <span
          className="text-xs text-gray-700 font-normal"
          data-testid="selected-text"
        >
          {selectionText}
        </span>
      </div>
      <div className="flex-grow">
        <div className="py-2 ">
          <Autocomplete
            onClose={onCancelSearch}
            label="Auto"
            value={inputValue}
            onChange={handleFilter}
            placeholder="Find Reviewer"
          >
            <SuggestionsList
              numberOfSearchChars={inputValue.length}
              options={filteredOptions}
              onSelect={handleSelect}
              status={status}
            />
          </Autocomplete>
        </div>
      </div>
      <Drawer.Controls>
        <Button
          className="md:mb-0 mb-2 w-full md:px-2"
          variant="outline"
          role="button"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="md:ml-4 w-full md:px-2"
          role="button"
          disabled={!selectedOption || isUpdating}
          onClick={async () => {
            await handleUpdateReviewer(selectedOption);
            handleClose();
          }}
        >
          {actionTitle}
        </Button>
      </Drawer.Controls>
    </Drawer>
  );
};

interface SuggestionsListProps {
  numberOfSearchChars: number;
  options: PeopleResponse | undefined;
  onSelect: (option: Person) => void;
  status: QueryStatus;
}
const SuggestionsList = ({
  numberOfSearchChars,
  options,
  onSelect,
  status,
}: SuggestionsListProps) => {
  return (
    <div data-testid="options">
      {status === 'success' &&
        (!options?.items || !options.items.length ? (
          <span className="flex justify-center">No results found!!</span>
        ) : (
          options.items.map((option) => {
            return (
              <div key={option.ussId} data-testid="option">
                <div
                  className="cursor-pointer py-2 px-4  hover:bg-blue-50"
                  role="button"
                  aria-hidden="true"
                  onClick={() => onSelect(option)}
                >
                  <div className=" col-span-2 flex flex-col">
                    <span className="text-base font-semibold text-gray-700">
                      {option.displayName}
                    </span>
                  </div>
                </div>
                <div className="uss-h-0.5 uss-bg-gray-200"></div>
              </div>
            );
          })
        ))}
      {status === 'pending' &&
        (numberOfSearchChars < 3 ? (
          <span className="flex justify-center">
            Enter at least 3 characters
          </span>
        ) : (
          <span className="flex justify-center">Loading Results!!!</span>
        ))}
      {status === 'error' && (
        <span className="flex justify-center">Error occured!!!</span>
      )}
    </div>
  );
};

export default AssignReviewerDrawer;
