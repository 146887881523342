import { Link } from '@uss/react-components';
import HolidayLinkSvg from 'components/svgs/my-pto/HolidayLinkSvg';
import { usePersonDetails } from 'features/people/api';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';

export const HolidayList = () => {
  const { data: loggedInUser } = usePersonDetails('me');

  const linkTo = () => {
    if (loggedInUser?.plantType === 'mini_mill') {
      return `https://me.uss.com/group/my-hr/holidays3`;
    } else if (
      (loggedInUser?.plantType === 'integrated_mill' ||
        loggedInUser?.plantType === 'office') &&
      loggedInUser.unionInd === 'n'
    ) {
      return `https://my.uss.com/group/my-hr/employment-guide/holidays`;
    } else {
      return `https://my.uss.com/group/my-hr/holidays2`;
    }
  };
  return (
    <div className="flex flex-wrap m-6 rounded-sm items-center justify-center  bg-white py-10">
      <div className="flex justify-center items-center w-4/5 sm:w-2/5">
        <HolidayLinkSvg className="w-full" />
      </div>
      <div className="w-full flex flex-col gap-2 justify-center items-center">
        <p>Click the link to view</p>
        <p className="flex items-center gap-2 text-sm text-primary-400 underline">
          <span>
            <BsBoxArrowUpRight size={12} />
          </span>
          <Link as={NavLink} to={linkTo()} target="_blank">
            Holidays- My HR (uss.com)
          </Link>
        </p>
      </div>
    </div>
  );
};
