import { EmployeeTimecardList, PrimaryCrewPtoType } from '../types';

export const getPrimaryCrewPtos = (
  primaryCrewPtoDetails: EmployeeTimecardList
) => {
  const ptoArr: PrimaryCrewPtoType[] = [];
  if (primaryCrewPtoDetails.items[0]) {
    primaryCrewPtoDetails.items[0].timecard.forEach((item) => {
      if (item.ptoDetails) {
        const obj: { date: string; paycode: string } = {
          date: item.date,
          paycode: item.ptoDetails.paycode,
        };
        ptoArr.push(obj);
      }
    });
  }
  return ptoArr;
};
