import Card from 'components/Card/Card';
import { Accordion } from '@uss/react-components';
import { useState } from 'react';
import { BsArrowsAngleExpand } from 'react-icons/bs';
import { getDates } from 'features/timecards/utilities/getDates';
import PolicySummaryCardHeader from './PolicySummaryCardHeader';
import PolicySummaryCardBody from './PolicySummaryCardBody';
import PolicySummaryHeader from './PolicySummaryHeader';
import shouldDisplayHolidayPay from 'features/timecards/utilities/shouldDisplayHolidayPay';
import {
  EmployeePolicySummary,
  PolicySummaryAdjustmentWiseHours,
  PolicySummaryFields,
  PolicySummaryTotalHours,
} from 'features/timecards/types';
import { HolidayList } from 'features/holidays/types';
import isBrsPayroll from 'features/timecards/utilities/isBrsPayroll';
import shouldDisplayUnWorkedHrs from 'features/timecards/utilities/shouldDisplayUnWorkedHrs';
import shouldDisplayWorkedHrsTurns from 'features/timecards/utilities/shouldDisplayWorkedHrs';

interface PolicySummaryCardListProps {
  width: number | undefined;
  policySummaryData: Record<string, EmployeePolicySummary>;
  week: string | null;
  holidayList: HolidayList | undefined;
}
interface PolicySummaryCardProps {
  policySummaryData: PolicySummaryAdjustmentWiseHours;
  shouldDisplayHolidayPay: boolean;
  isTotalHoursCard: boolean;
  empNew: PolicySummaryFields;
}
interface PolicySummaryAccordionProps {
  policySummaryData: PolicySummaryAdjustmentWiseHours;
  shouldDisplayHolidayPay: boolean;
  isTotalHoursCard: boolean;
  empNew: PolicySummaryFields;
}
interface TotalHoursCardProps {
  width: number | undefined;
  dates: string[];
  holidayList: HolidayList | undefined;
  totalHours: PolicySummaryTotalHours;
  empNew: PolicySummaryFields;
}
const PolicySummaryCardList = ({
  width,
  policySummaryData,
  week,
  holidayList,
}: PolicySummaryCardListProps) => {
  const { dates } = !!week
    ? getDates({
        startDate: week,
        weekStartsOn: 6,
        customFormat: 'yyyy-MM-dd',
      })
    : { dates: [] };
  return (
    <>
      {Object.keys(policySummaryData).map(
        (payProcessingDate, index, payProcessingDates) => {
          return (
            <div key={payProcessingDate}>
              {policySummaryData[payProcessingDate].items.map((policyData) => {
                const shouldDisplayHolidaySection = shouldDisplayHolidayPay({
                  dates: dates,
                  holidayList: holidayList,
                  holidayPayHours: policyData.holidayPayHours,
                  isOriginalTimecard: policyData.adjustment === 0,
                });

                return (
                  <div
                    key={`adjustment-${policyData.adjustment}`}
                    data-testid={`adjustment-${policyData.adjustment}`}
                    className="mb-2"
                  >
                    <PolicySummaryHeader
                      adjustment={policyData.adjustment}
                      payProcessingDate={policyData.payProcessingDate}
                    />
                    {!!width && width < 768 ? (
                      <PolicySummaryAccordion
                        policySummaryData={policyData}
                        shouldDisplayHolidayPay={shouldDisplayHolidaySection}
                        isTotalHoursCard={false}
                        empNew={
                          policySummaryData[payProcessingDate]
                            .policySummaryFields
                        }
                      />
                    ) : (
                      <PolicySummaryCard
                        policySummaryData={policyData}
                        shouldDisplayHolidayPay={shouldDisplayHolidaySection}
                        isTotalHoursCard={false}
                        empNew={
                          policySummaryData[payProcessingDate]
                            .policySummaryFields
                        }
                      />
                    )}
                  </div>
                );
              })}

              {policySummaryData[payProcessingDate].totalHours[
                policySummaryData[payProcessingDate].totalHours.length - 1
              ].adjustment !== 0 && (
                <TotalHoursCard
                  totalHours={
                    policySummaryData[payProcessingDate].totalHours[
                      policySummaryData[payProcessingDate].totalHours.length - 1
                    ]
                  }
                  width={width}
                  dates={dates}
                  holidayList={holidayList}
                  empNew={
                    policySummaryData[payProcessingDate].policySummaryFields
                  }
                />
              )}

              {index !== 0 && index !== payProcessingDates.length - 1 && (
                <hr className="mt-8 mb-4 border-gray-300" />
              )}
            </div>
          );
        }
      )}
    </>
  );
};

const PolicySummaryCard = ({
  policySummaryData,
  isTotalHoursCard,
  empNew,
}: PolicySummaryCardProps) => {
  const shouldDisplayHolidayPaySection = isBrsPayroll({
    employeePolicySummarydata: empNew,
  });
  const shouldDisplayUnWorkedHrsSection = shouldDisplayUnWorkedHrs({
    employeePolicySummarydata: empNew,
  });
  const shouldDisplayWorkedHrsTurnsSection = shouldDisplayWorkedHrsTurns({
    employeePolicySummarydata: empNew,
  });
  return (
    <Card bgColor={isTotalHoursCard ? 'bg-blue-50' : 'bg-white'}>
      <Card.Body>
        <PolicySummaryCardHeader
          adjustmentWiseHours={policySummaryData}
          isBrsPayroll={shouldDisplayHolidayPaySection}
          shouldDisplayUnWorkedHrs={shouldDisplayUnWorkedHrsSection}
          isAdjustmentTimecard={
            policySummaryData.adjustment !== 0 && !isTotalHoursCard
          }
        />

        <hr className=" border-gray-300" />
        <PolicySummaryCardBody
          allHours={policySummaryData.allHours}
          isAdjustmentTimecard={
            policySummaryData.adjustment !== 0 && !isTotalHoursCard
          }
          employeePolicySummarydata={empNew}
          shouldDisplayWorkedHrsTurns={shouldDisplayWorkedHrsTurnsSection}
        />
      </Card.Body>
    </Card>
  );
};
const PolicySummaryAccordion = ({
  policySummaryData,
  shouldDisplayHolidayPay,
  isTotalHoursCard,
  empNew,
}: PolicySummaryAccordionProps) => {
  const [panelState, setPanelState] = useState(true);
  function togglePanelState(): void {
    setPanelState(!panelState);
  }
  const shouldDisplayHolidayPaySection = isBrsPayroll({
    employeePolicySummarydata: empNew,
  });
  const shouldDisplayUnWorkedHrsSection = shouldDisplayUnWorkedHrs({
    employeePolicySummarydata: empNew,
  });
  const shouldDisplayWorkedHrsTurnsSection = shouldDisplayWorkedHrsTurns({
    employeePolicySummarydata: empNew,
  });
  return (
    <Accordion variant="Link">
      <Card bgColor={isTotalHoursCard ? 'bg-blue-50' : 'bg-white'}>
        <Card.Body>
          <PolicySummaryCardHeader
            adjustmentWiseHours={policySummaryData}
            isBrsPayroll={shouldDisplayHolidayPaySection}
            shouldDisplayUnWorkedHrs={shouldDisplayUnWorkedHrsSection}
            isAdjustmentTimecard={
              policySummaryData.adjustment !== 0 && !isTotalHoursCard
            }
          />
          <hr className="mt-4 mb-3 border-gray-300" />
          <Accordion.Panel variant="Link">
            <PolicySummaryCardBody
              allHours={policySummaryData.allHours}
              isAdjustmentTimecard={
                policySummaryData.adjustment !== 0 && !isTotalHoursCard
              }
              employeePolicySummarydata={empNew}
              shouldDisplayWorkedHrsTurns={shouldDisplayWorkedHrsTurnsSection}
            />
            <hr className=" mt-4 mb-3 border-gray-300" />
          </Accordion.Panel>
          <div className="flex justify-center  items-center text-center">
            <div className="mr-2">
              <BsArrowsAngleExpand
                size="0.75rem"
                className="text-primary-400"
                aria-label={panelState ? 'View More' : 'View Less'}
              />
            </div>
            <div>
              <Accordion.Button
                openLabel="View Less"
                closeLabel="View More"
                variant="Link"
                onClick={togglePanelState}
              ></Accordion.Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Accordion>
  );
};
const TotalHoursCard = ({
  width,
  dates,
  holidayList,
  totalHours,
  empNew,
}: TotalHoursCardProps) => {
  const shouldDisplayHolidaySection = shouldDisplayHolidayPay({
    dates: dates,
    holidayList: holidayList,
    holidayPayHours: totalHours.holidayPayHours,
    isTotalHoursCard: true,
  });

  return (
    <div data-testid={`total-hours-card-${totalHours.payProcessingDate}`}>
      <PolicySummaryHeader
        adjustment={totalHours.adjustment}
        isTotalHoursCard={true}
        payProcessingDate={totalHours.payProcessingDate}
      />
      {!!width && width < 768 ? (
        <PolicySummaryAccordion
          policySummaryData={totalHours}
          shouldDisplayHolidayPay={shouldDisplayHolidaySection}
          isTotalHoursCard={true}
          empNew={empNew}
        />
      ) : (
        <PolicySummaryCard
          policySummaryData={totalHours}
          shouldDisplayHolidayPay={shouldDisplayHolidaySection}
          isTotalHoursCard={true}
          empNew={empNew}
        />
      )}
    </div>
  );
};
export default PolicySummaryCardList;
