import NoResults from 'components/NoResults/NoResults';
import { usePolicySummary } from 'features/timecards/api/policySummary';
import Error from 'components/Error/Error';

import { useHolidays } from 'features/holidays/api';
import PolicySummaryLoader from '../TimecardDetails/Loaders/PolicySummaryLoader';
import { getFormattedPolicySummaryObj } from 'features/timecards/utilities/getFormattedPolicySummaryData';
import PolicySummaryCardList from '../TimecardDetails/PolicySummary/PolicySummaryCardList';
import EmployeePolicySummarySchema from 'features/timecards/schemas/policySummarySchema';
import { ErrorBoundary } from 'react-error-boundary';
import { compareAsc } from 'date-fns';
import { HolidayType } from 'features/holidays';
interface PolicySummaryProps {
  week: string | null;
  ussID: string;
  holidayType: HolidayType;
  width: number | undefined;
}

const PolicySummary = ({
  week,
  ussID,
  holidayType,
  width,
}: PolicySummaryProps) => {
  return (
    <ErrorBoundary
      resetKeys={[week]}
      fallbackRender={({ error }) => {
        return (
          <Error
            width="300"
            height="300"
            title="Something went wrong!"
            description={error.message}
          />
        );
      }}
    >
      <Content
        week={week}
        ussID={ussID}
        holidayType={holidayType}
        width={width}
      />
    </ErrorBoundary>
  );
};
export const Content = ({
  week,
  ussID,
  holidayType,
  width,
}: PolicySummaryProps) => {
  const { data, status, isRefetching } = usePolicySummary(ussID, { week });
  const { data: holidayList } = useHolidays(holidayType);
  const isPolicySummaryAvailable = !!week
    ? compareAsc(new Date(week), new Date('2023-01-21')) >= 0
    : false;

  if (status === 'success' && isPolicySummaryAvailable) {
    //Checking if the data is in valid format
    EmployeePolicySummarySchema.parse(data);
  }
  return isPolicySummaryAvailable ? (
    <div>
      {(status === 'pending' || isRefetching) && (
        <PolicySummaryLoader width={width} />
      )}
      {status === 'error' && <Error />}
      {status === 'success' &&
        !isRefetching &&
        (data.items.length > 0 ? (
          <PolicySummaryCardList
            width={width}
            policySummaryData={getFormattedPolicySummaryObj(data)}
            week={week}
            holidayList={holidayList}
          />
        ) : (
          <NoResults
            width="300"
            height="300"
            description={
              'No policy summary records found for the selected employee'
            }
          />
        ))}
    </div>
  ) : (
    <NoResults
      width="300"
      height="300"
      description={
        'Policy Summary records are not available prior to 01/21/2023'
      }
    />
  );
};
export default PolicySummary;
