import { Org } from 'features/org';
import { GetCrewParamsBody, GetOrgDetailsParamsBody } from '../types/crew-pto';
import { PtoFilterStatusType } from '../types';

interface ReturnType {
  totalsRequest: GetCrewParamsBody;
  detailsRequest: GetOrgDetailsParamsBody;
}

export interface Options {
  selectedCrew: Org | 'all' | null;
  selectedDepartment: Org | 'all' | null;
  selectedDivision: Org | null;
  ussids: string[];
  date: string;
  departments: Org[];
  selectedDate: string;
  status: PtoFilterStatusType;
}

function useCrewPTORequests(options: Options): ReturnType {
  function whichRequestShouldIMake(): GetCrewParamsBody {
    if (options.ussids.length > 0) {
      return {
        body: {
          ids: [...options.ussids],
        },
        params: {
          type: 'ussid',
          yearMonth: options.date,
        },
      };
    }

    if (options.selectedCrew !== 'all' && options.selectedCrew !== null) {
      return {
        body: {
          ids: [options.selectedCrew.id],
        },
        params: {
          type: 'crew',
          yearMonth: options.date,
        },
      };
    }

    if (
      options.selectedDepartment !== 'all' &&
      options.selectedDepartment !== null
    ) {
      return {
        body: {
          ids: [options.selectedDepartment.id],
        },
        params: {
          type: 'dept',
          yearMonth: options.date,
        },
      };
    }
    if (options.selectedDivision !== null) {
      return {
        body: {
          ids: [...options.departments.map((i) => i.id)],
        },
        params: {
          type: 'dept',
          yearMonth: options.date,
        },
      };
    }

    return {
      body: {
        ids: [],
      },
      params: {
        type: 'dept',
        yearMonth: options.date,
      },
    };
  }

  function whichDetailsRequestShouldIMake(): GetOrgDetailsParamsBody {
    if (options.ussids.length > 0) {
      return {
        body: {
          ids: [...options.ussids],
        },
        params: {
          type: 'ussid',
          date: options.selectedDate,
          status: options.status === 'all' ? undefined : options.status,
        },
      };
    }

    if (options.selectedCrew !== 'all' && options.selectedCrew !== null) {
      return {
        body: {
          ids: [options.selectedCrew.id],
        },
        params: {
          type: 'crew',
          date: options.selectedDate,
          status: options.status === 'all' ? undefined : options.status,
        },
      };
    }

    if (
      options.selectedDepartment !== 'all' &&
      options.selectedDepartment !== null
    ) {
      return {
        body: {
          ids: [options.selectedDepartment.id],
        },
        params: {
          type: 'dept',
          date: options.selectedDate,
          status: options.status === 'all' ? undefined : options.status,
        },
      };
    }
    if (options.selectedDivision !== null) {
      return {
        body: {
          ids: [...options.departments.map((i) => i.id)],
        },
        params: {
          type: 'dept',
          date: options.selectedDate,
          status: options.status === 'all' ? undefined : options.status,
        },
      };
    }

    return {
      body: {
        ids: [],
      },
      params: {
        type: 'dept',
        date: options.selectedDate,
      },
    };
  }

  return {
    totalsRequest: whichRequestShouldIMake(),
    detailsRequest: whichDetailsRequestShouldIMake(),
  };
}

export default useCrewPTORequests;
