import { z } from 'zod';
import { MODEL_FILTER_TYPE, MODEL_SORT, MODEL_STATUS, MODEL_TYPE } from '../constants';

//params to pass in API
export const ModelListParamsOptions = z.object({
  modelType: z
    .union([z.literal('global'), z.literal('plant')])
    .optional(),
  modelName: z.string().optional(),
  sortBy: z.union([z.literal('modelName'), z.literal('lastUpdatedOn')]).default('modelName'),
  sortType: z.union([z.literal('asc'), z.literal('desc')]).default('asc'),
  page: z.string().optional(),
  pageSize: z.string().optional(),
  plant: z.string().optional(),
  payrollLocation: z.string().optional(),
  modelOwner: z.number().optional(),
  modelStatus: z.string().optional(),
});

export const ZodModelType = z.nativeEnum(MODEL_TYPE);
export const ZodModelStatus = z.nativeEnum(MODEL_STATUS);
export const ZodModelSort = z.nativeEnum(MODEL_SORT);
export const ZodModelFilterType = z.nativeEnum(MODEL_FILTER_TYPE);

export const OwnerSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  displayName: z.string(),
  ussId: z.number(),
});

//Get API modeling list response schema types
export const ModelListSchema = z.object({
  id: z.string(),
  modelName: z.string(),
  modelDescription: z.string(),
  modelType: ZodModelType,
  modelStatus: ZodModelStatus,
  weekBeginIndex: z.number(),
  plantName: z.string(),
  plantOrgId: z.string(),
  payrollLocationCode: z.string(),
  payrollLocationOrgId: z.string(),
  totalWeeks: z.number(),
  totalCrewRotations: z.number(),
  createdOn: z.string(),
  lastUpdatedOn: z.string(),
  lastUpdatedBy: z.string(),
  owner: OwnerSchema.nullable(),
});

export const ModelListResponseSchema = z.object({
  items: z.array(ModelListSchema),
  page: z.number(),
  pageSize: z.number(),
  totalCount: z.number(),
});
