import { Button } from '@uss/react-components';
import {
  EmployeeTimecard,
  TIMECARD_ACTION_BUTTON,
  TimecardActionButton,
} from 'features/timecards/types';
import useModal from 'components/Modals/use-modal';
import { getActionModalProperties } from 'features/timecards/utilities/getActionModalProperties';
import { alertType } from 'components/Modals/ModalsContext';
interface PayActionsProps {
  buttonRef: React.MutableRefObject<TimecardActionButton>;
  handleUpdate: () => Promise<void>;
  payButtonsState: {
    canSaveApprove: boolean;
    canUnapprove: boolean;
    canOpenAdjustment: boolean;
    canSaveAdjustment: boolean;
    canSaveApproveAdjustment: boolean;
    canUnapproveAdjustment: boolean;
    isApproveDisabled: boolean;
  };
  isTimecardDisabled: boolean;
  employee: EmployeeTimecard;
  approveAuditalerts: alertType[];
}
type PayActionButtons = Exclude<
  TimecardActionButton,
  | typeof TIMECARD_ACTION_BUTTON.CREATE_TIMECARD
  | typeof TIMECARD_ACTION_BUTTON.SAVE
  | typeof TIMECARD_ACTION_BUTTON.SAVE_VERIFY
  | typeof TIMECARD_ACTION_BUTTON.CANCEL
>;
type Actions = Record<
  PayActionButtons,
  {
    isAllowed: boolean;
    title: string;
    actionType: PayActionButtons;
    isDisabled: boolean;
  }
>;
const PayActions = ({
  buttonRef,
  handleUpdate,
  payButtonsState,
  isTimecardDisabled,
  employee,
  approveAuditalerts,
}: PayActionsProps) => {
  const {
    canSaveApprove,
    canUnapprove,
    canOpenAdjustment,
    canSaveAdjustment,
    canSaveApproveAdjustment,
    canUnapproveAdjustment,
    isApproveDisabled,
  } = payButtonsState;

  const actions: Actions = {
    [TIMECARD_ACTION_BUTTON.SAVE_APPROVE]: {
      isAllowed: canSaveApprove,
      title: 'Save & Approve',
      actionType: TIMECARD_ACTION_BUTTON.SAVE_APPROVE,
      isDisabled: isApproveDisabled || isTimecardDisabled,
    },
    [TIMECARD_ACTION_BUTTON.UNAPPROVE]: {
      isAllowed: canUnapprove,
      title: 'Unapprove',
      actionType: TIMECARD_ACTION_BUTTON.UNAPPROVE,
      isDisabled: isTimecardDisabled,
    },
    [TIMECARD_ACTION_BUTTON.SAVE_ADJUSTMENTS]: {
      isAllowed: canSaveAdjustment || canSaveApproveAdjustment,
      title: 'Save',
      actionType: TIMECARD_ACTION_BUTTON.SAVE_ADJUSTMENTS,
      isDisabled: isTimecardDisabled,
    },
    [TIMECARD_ACTION_BUTTON.SAVE_APPROVE_ADJUSTMENTS]: {
      isAllowed: canSaveAdjustment || canSaveApproveAdjustment,
      title: 'Save & Approve Adjustment',
      actionType: TIMECARD_ACTION_BUTTON.SAVE_APPROVE_ADJUSTMENTS,
      isDisabled: !canSaveApproveAdjustment || isTimecardDisabled,
    },
    [TIMECARD_ACTION_BUTTON.OPEN_ADJUSTMENTS]: {
      isAllowed: canOpenAdjustment,
      title: 'Open For Adjustment',
      actionType: TIMECARD_ACTION_BUTTON.OPEN_ADJUSTMENTS,
      isDisabled: isTimecardDisabled,
    },
    [TIMECARD_ACTION_BUTTON.UNAPPROVE_ADJUSTMENTS]: {
      isAllowed: canUnapproveAdjustment,
      title: 'Unapprove Adjustment',
      actionType: TIMECARD_ACTION_BUTTON.UNAPPROVE_ADJUSTMENTS,
      isDisabled: isTimecardDisabled,
    },
  } as const;

  const actionModal = useModal();
  return (
    <>
      {(Object.keys(actions) as (keyof typeof actions)[]).map(
        (type) =>
          actions[type].isAllowed && (
            <Button
              onClick={() => {
                buttonRef.current = actions[type]
                  .actionType as TimecardActionButton;
                const { modalTitle, confirmButtonTitle, modalBodyText } =
                  getActionModalProperties(buttonRef);

                const shouldAppendNoPayMessage =
                  employee.timecard.every(
                    (timeCard) =>
                      ((timeCard.approved.hours === 0 ||
                        timeCard.approved.hours === null) &&
                        (timeCard.worked.hours ?? 0) > 0) ||
                      timeCard.worked.hours === null
                  ) &&
                  actions[type].actionType ===
                    TIMECARD_ACTION_BUTTON.SAVE_APPROVE &&
                  employee.payPeriodStatus === 'open';
                actionModal.openModal({
                  title: modalTitle,
                  type: 'regular',
                  size: 'md',
                  alert: !actions.unapprove.isAllowed ? approveAuditalerts : [],
                  children: (
                    <div className="text-sm text-gray-700">
                      <p>{modalBodyText} </p>
                      {shouldAppendNoPayMessage && (
                        <p className="mt-6">
                          This will result in no pay for Worked Hours.
                        </p>
                      )}
                    </div>
                  ),

                  labels: { confirm: confirmButtonTitle, cancel: 'Cancel' },
                  onCancel: actionModal.closeModal,
                  onConfirm: async () => {
                    actionModal.closeModal();
                    await handleUpdate();
                  },
                });
              }}
              variant="primary"
              className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
              type="submit"
              disabled={actions[type].isDisabled}
              key={type}
              data-testid={actions[type].actionType}
            >
              {actions[type].title}
            </Button>
          )
      )}
    </>
  );
};
export default PayActions;
