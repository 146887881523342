import Page from 'components/Layouts/Page';
import Tabs from 'components/Tabs/Tabs';
import { format } from 'date-fns';
import { NavLink } from 'react-router-dom';
import { useSellBackPtoList } from 'features/paid-time-off/api/queries';
import { PtoSummaryOptions } from 'features/paid-time-off/types/employee-pto';
import { SellBackPtoOptions } from 'features/paid-time-off/types/sellback';
import { HISTORY_ENTITY, History } from 'features/history';
import { useMyPtoStore } from 'features/paid-time-off/state/mypto-store';
import SellBackPTOCard from './tabs/sellBackPTOCard';
import { AnnualTotals } from 'features/paid-time-off/components/AnnualTotals/AnnualTotals';
import { useEmployeePtoSummary } from 'features/paid-time-off/api/queries';

const SellBackPTO = () => {
  const store = useMyPtoStore();
  const state = store.params;

  //annual total data
  const payload: PtoSummaryOptions = {
    year: store.params.year,
  };
  const { data: PTOSummary, status: PTOSummaryStatus } = useEmployeePtoSummary(
    'me',
    payload
  );

  //get sellBack Details

  const sellBackOptions: SellBackPtoOptions = {
    ussId: 'me',
    year: store.params.year,
  };
  const { data: sellBackDetails, status } = useSellBackPtoList(
    'me',
    sellBackOptions
  );

  const ussId = sellBackDetails?.ussId ? sellBackDetails.ussId : '';

  const ptoSellBackTabLinks = [
    {
      label: 'Sell Back',
      element: (
        <SellBackPTOCard
          PTOSummary={PTOSummary}
          PTOSummaryStatus={PTOSummaryStatus}
          sellBackDetails={sellBackDetails}
        />
      ),
    },
    {
      label: 'History',
      element: (
        <History
          id={ussId}
          entity={HISTORY_ENTITY.SELLBACK_PTO}
          noRecordError="No sellback history records found"
          year={state.year ?? ''}
        />
      ),
    },
  ];
  return (
    <Page
      breadcrumbs={[
        <NavLink key="bc-1" to="/my-pto-nu">
          My PTO (NU)
        </NavLink>,
      ]}
      heading="Sell Back PTO"
    >
      <Page.Section>
        <AnnualTotals year={store.params.year ?? format(new Date(), 'yyyy')} />
      </Page.Section>
      <Page.Section>
        {status === 'success' && <Tabs tabLinks={ptoSellBackTabLinks}></Tabs>}
      </Page.Section>
    </Page>
  );
};

export default SellBackPTO;
