import { format, parseISO } from 'date-fns';
import isDateHoliday from 'features/timecards/utilities/checkDateHoliday';
import holidayWithHours from 'features/timecards/validations/holiday-with-hours';
import { ptoPaycodeAndHours } from 'features/timecards/validations/pto-paycode-and-hours';
import {
  Timecard,
  TimecardAuditMsg,
  TimecardEfforts,
} from 'features/timecards/types';
import { HolidayList } from 'features/holidays';
import { Person, ShiftAssignment } from 'features/people/types';
import { useNonUnionStore } from './useNonUnionStore';
import { holidayNotWorkedCheck } from '../validations/holidayNotWorkedCheck';
/**
 * Hook that validates the timecard foe soft audit.
 *
 * @param timecard Timecard you wish to get validations for
 * @param shiftType Employee shift
 * @returns Boolean values representing different actions that a user can take.
 */
const useTimecardSoftAudit = (
  shiftType: ShiftAssignment | null,
  holidayList: HolidayList | undefined,
  employee?: Person
) => {
  const state = useNonUnionStore();
  const feature = state.feature;
  const isPay = feature === 'pay';

  let msgs: TimecardAuditMsg[] = [];
  let holidayMgs: TimecardAuditMsg[] = [];
  let holidayNotWrokedMgs: TimecardAuditMsg[] = [];

  const validateSoftAudit = (timecardList: Timecard[]) => {
    msgs = [];
    holidayMgs = [];
    holidayNotWrokedMgs = [];
    let invalidTimecard: TimecardAuditMsg[] = [];

    let holidayWithHoursError: TimecardAuditMsg[] = [];
    let holidayNotWorkedError: TimecardAuditMsg[] = [];

    for (const item of timecardList) {
      invalidTimecard = getTimecardErrors(item);
      holidayWithHoursError = getHolidayWithHoursError(item, holidayList);
      holidayNotWorkedError = getHolidayNotWorkedAlert(
        item,
        holidayList,
        employee
      );
    }

    return { invalidTimecard, holidayWithHoursError, holidayNotWorkedError };
  };

  const getTimecardErrors = (timecard: Timecard) => {
    const timecardEfforts: TimecardEfforts = isPay
      ? timecard.approved
      : timecard.worked;
    if (
      ptoPaycodeAndHours(
        shiftType,
        timecardEfforts.hours,
        timecard.scheduleVariance.payCode
      )
    ) {
      const date = format(parseISO(timecard.date), 'MMM d, E');
      const auditMsg =
        feature === 'pay' || feature === 'scheduling'
          ? `Did this employee actually work and take PTO time on ${date}? `
          : `Did you actually work and take PTO time on ${date}? `;

      msgs.push({
        key: 'ptoPaycodeAndHours',
        msg: auditMsg,
      });
    }

    return msgs;
  };

  const getHolidayWithHoursError = (
    timecard: Timecard,
    holidayList: HolidayList | undefined
  ) => {
    const timecardEfforts: TimecardEfforts = isPay
      ? timecard.approved
      : timecard.worked;

    const isHoliday = isDateHoliday(timecard.date, holidayList);

    const holidayWithHoursAudit = holidayWithHours({
      isHoliday: isHoliday,
      efforts: timecardEfforts.hours,
    });

    if (holidayWithHoursAudit) {
      const date = format(parseISO(timecard.date), 'MMM d, E');
      const auditMsg =
        feature === 'pay' || feature === 'scheduling'
          ? `Did this employee actually work on ${date}? `
          : `Did you actually work on ${date}? `;

      holidayMgs.push({
        key: 'holidayWithHours',
        msg: auditMsg,
      });
    }

    return holidayMgs;
  };
  const getHolidayNotWorkedAlert = (
    timecard: Timecard,
    holidayList: HolidayList | undefined,
    employee?: Person
  ) => {
    const isHoliday = isDateHoliday(timecard.date, holidayList);
    const isholidayNotWorkedCheck = holidayNotWorkedCheck(
      isHoliday,
      timecard,
      employee
    );
    if (isholidayNotWorkedCheck) {
      const auditMsg =
        'Was the employee required to work on the holiday but reported absent for all of the day or part of the day?';
      holidayNotWrokedMgs.push({
        key: 'holidayNotWorked',
        msg: auditMsg,
      });
    }

    return holidayNotWrokedMgs;
  };
  return { validateSoftAudit };
};

export default useTimecardSoftAudit;
