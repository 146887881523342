import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { create } from 'zustand';
import {
  CrewSortByType,
  LocationList,
  UnapprovedCrewsGetOptions,
} from '../types';
import { SortDirection } from 'types/common';

interface UnapprovedCrewsState {
  params: UnapprovedCrewsGetOptions;
  selectedPayrollLocation: LocationList | null;
  setPage: (value: number) => void;
  setPageSize: (value: number) => void;
  setSort: (val: { type: CrewSortByType; direction: SortDirection }) => void;
  setPayrollLocation: (value: LocationList) => void;
}

export const useUnapprovedCrewsStore = create<UnapprovedCrewsState>()(
  devtools(
    immer((set) => ({
      params: {
        page: 1,
        pageSize: 9,
        sortBy: 'crewKey',
        sortType: SortDirection.ASC,
        locationId: null,
      },
      selectedPayrollLocation: null,
      setPayrollLocation: (value) =>
        set(
          (state) => {
            state.selectedPayrollLocation = value;
            state.params.locationId = value.locationName;
          },
          false,
          'set-location'
        ),
      setPage: (value) =>
        set(
          (state) => {
            state.params.page = value;
          },
          false,
          'set-page'
        ),
      setPageSize: (value) =>
        set(
          (state) => {
            state.params.pageSize = value;
            state.params.page = 1;
          },
          false,
          'set-pageSize'
        ),
      setSort: (value) =>
        set(
          (state) => {
            state.params.sortBy = value.type;
            state.params.sortType = value.direction;
            state.params.page = 1;
          },
          false,
          'set-sort'
        ),
    }))
  )
);
