import React from 'react';
import { Table } from '@uss/react-components';
import { useNavigate } from 'react-router-dom';
import { PAYCODE_TYPE, PaycodeList, PayrollGroups } from 'features/paycode';
import PaycodeListingLoader from './loaders/PaycodeListingLoader';
import NoResults from 'components/NoResults/NoResults';
import useWindowSize from 'hooks/useWindowSize';
import getPaycodeTypeText from '../utilities/getPaycodeTypeText';
import getPlantTypeText from '../utilities/getPlantTypeText';
import getPayrollGroupTypeText from '../utilities/getPayrollGroupTypeText';
import { QueryStatus } from '@tanstack/react-query';
interface PaycodeTableProps {
  paycodes: PaycodeList | undefined;
  status: QueryStatus;
}

export const PaycodeTable = ({ paycodes, status }: PaycodeTableProps) => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const handleRowClick = (id: string) => {
    navigate(`${id}`);
  };

  const getPayrollGroupText = (
    payrollGroups: PayrollGroups[] | undefined | null
  ) => {
    if (payrollGroups && payrollGroups.length > 0) {
      const activePayGroup = payrollGroups
        .filter((item) => item.active)
        .map(({ payrollGroupType }) => payrollGroupType);

      //get only unique values
      const uniqueTexts = activePayGroup.filter(
        (value, index, array) => array.indexOf(value) === index
      );

      return uniqueTexts.map((s) => getPayrollGroupTypeText(s)).join(', ');
    } else {
      return '-';
    }
  };

  return (
    <Table>
      <Table.Header>
        <Table.HeaderRow>
          <Table.HeaderCell className="w-1/6 lg:w-1/12" scope="col" rowSpan={2}>
            Code
          </Table.HeaderCell>
          <Table.HeaderCell scope="col" className="w-1/3 lg:w-1/6" rowSpan={2}>
            Description
          </Table.HeaderCell>
          <Table.HeaderCell scope="col" rowSpan={2} className="w-1/4 sm:w-1/6">
            Type
          </Table.HeaderCell>
          {width && width > 1024 && (
            <Table.HeaderCell className="w-1/5" scope="col" rowSpan={2}>
              Pay Group
            </Table.HeaderCell>
          )}
          {width && width > 1024 && (
            <Table.HeaderCell className="w-1/5" scope="col" rowSpan={2}>
              Plant Type
            </Table.HeaderCell>
          )}
          <Table.HeaderCell scope="col" rowSpan={2} className="w-1/4 sm:w-1/12">
            Status
          </Table.HeaderCell>
        </Table.HeaderRow>
      </Table.Header>
      {status === 'pending' ? (
        <PaycodeListingLoader />
      ) : (
        paycodes &&
        paycodes.items.length === 0 && (
          <Table.Body>
            <Table.BodyRow>
              <Table.BodyCell colSpan={7}>
                <NoResults width={'300'} height={'300'} />
              </Table.BodyCell>
            </Table.BodyRow>
          </Table.Body>
        )
      )}
      {paycodes && (
        <Table.Body>
          {paycodes.items.map((pc) => {
            return (
              <Table.BodyRow
                key={pc.id}
                onClick={() => handleRowClick(pc.id)}
                data-testid="paycode-row"
              >
                <Table.BodyCell>
                  <span className="text-gray-700 text-sm line-clamp-1 lg:line-clamp-2 xl:line-clamp-2 2xl:line-clamp-2">
                    {pc.code ? pc.code : '-'}
                  </span>
                </Table.BodyCell>
                <Table.BodyCell>
                  <span className="text-gray-700 text-sm line-clamp-1 lg:line-clamp-2 xl:line-clamp-2 2xl:line-clamp-2">
                    {pc.description ? pc.description : '-'}
                  </span>
                </Table.BodyCell>
                <Table.BodyCell>
                  <span className="text-gray-700 text-sm line-clamp-1 lg:line-clamp-2 xl:line-clamp-2 2xl:line-clamp-2">
                    {Object.values(PAYCODE_TYPE).includes(pc.type)
                      ? getPaycodeTypeText(pc.type)
                      : '-'}
                  </span>
                </Table.BodyCell>
                {width && width > 1024 && (
                  <Table.BodyCell>
                    <span className="text-gray-700 text-sm line-clamp-2">
                      {getPayrollGroupText(pc.payrollGroups)}
                    </span>
                  </Table.BodyCell>
                )}

                {width && width > 1024 && (
                  <Table.BodyCell>
                    <span className="text-gray-700 text-sm line-clamp-2">
                      {pc.plantTypes && pc.plantTypes.length > 0
                        ? pc.plantTypes
                            .map((s) => getPlantTypeText(s))
                            .join(', ')
                        : '-'}
                    </span>
                  </Table.BodyCell>
                )}
                <Table.BodyCell>
                  <span className="text-gray-700 text-sm line-clamp-1 lg:line-clamp-2 xl:line-clamp-2 2xl:line-clamp-2 ">
                    {pc.status.description ? pc.status.description : '-'}
                  </span>
                </Table.BodyCell>
              </Table.BodyRow>
            );
          })}
        </Table.Body>
      )}
    </Table>
  );
};
