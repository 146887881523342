import useUserRoles from 'hooks/useUserRoles';
import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { SortDirection } from 'types/common';
import {
  OrgFilterStatus,
  OrgList,
  OrgsOptions,
  OrgsSortByType,
  ORG_TYPE,
  OrgType,
} from 'features/org';
import { Person } from 'features/people';

/**
 *
 * @param ussId USS Id of the employee
 * @param options.type
 * @returns
 *
 * @description /api/v1/people/:ussId/orgs
 */

const getEmployeeOrgs = <T = OrgsOptions>(
  ussId: string,
  options?: T
): Promise<OrgList> => {
  return axios.get(`/v2/people/${ussId}/orgs`, { params: options });
};

export type UseEmployeeCrewsOptions = Omit<OrgsOptions, 'type' | 'role'>;
/**
 *
 * @param ussId USS Id of the user you wish to fetch crews for.
 *
 * To get the crews for the logged in user, you can send 'me' in the `ussId` field
 *
 * @param options
 *
 * @example
 *
 * // Fetches all crews for user with the ussId of '12345'
 * const { data, status } = useEmployeeCrews('12345');
 */

export const useEmployeeCrews = (
  ussId: string,
  options?: UseEmployeeCrewsOptions
) => {
  return useQuery<OrgList>({
    queryFn: () =>
      getEmployeeOrgs(ussId, {
        ...options,
        type: ORG_TYPE.CREW,
        role: 'crew_member,crew_temp_member',
        week: options?.week,
        sortBy: 'code',
        sortType: SortDirection.ASC,
        status: 'active',
      }),
    queryKey: [`${ussId}-employee-crews`, { ussId, ...options }],
    enabled: options?.week !== '',
    placeholderData: keepPreviousData,
  });
};

/**
 * Hook to provide the crews in which the logged in user is either a pay_approver or schedule_pay_viewer
 *
 * @returns react-query useQuery result
 */

export const useUserCrews = (options?: OrgsOptions) => {
  const { isSuperAdmin } = useUserRoles();

  const superAdminQueryOptions: UseQueryOptions<OrgList> = {
    queryKey: ['super_admin_pay_crews', options],
    queryFn: () =>
      getEmployeeOrgs('me', {
        type: ORG_TYPE.CREW,
        role: 'super_admin,super_payroll_admin',
        sortBy: 'code',
        sortType: SortDirection.ASC,
        status: 'active',
        ...options,
      }),
    staleTime: Infinity,
    gcTime: Infinity,
  };
  const queryOptions: UseQueryOptions<OrgList> = {
    queryKey: ['logged-in-user-pay-crews', options],
    queryFn: () =>
      getEmployeeOrgs('me', {
        type: ORG_TYPE.CREW,
        role: 'pay_approver,scheduling_admin,schedule_pay_viewer,t_a_plant_coordinator,payroll_admin',
        sortBy: 'code',
        sortType: SortDirection.ASC,
        status: 'active',
        ...options,
      }),
    staleTime: Infinity,
    gcTime: Infinity,
  };

  return useQuery<OrgList>(
    isSuperAdmin ? superAdminQueryOptions : queryOptions
  );
};

/**
 * Hook that can fetch any type of orgs for a given user
 *
 * @param ussId ussId of the person you are fetching orgs for
 * @param options
 *
 * @example
 *
 * // Fetches all orgs for the employee '12345'
 * const {data, status} = useEmployeeOrgs('12345')
 * // Fetches the divisions for user '12345' that they have the pay_approver or payroll_admin roll
 * const {data, status} = useEmployeeOrgs('12345', {role: 'pay_approver,payroll_admin', type: 'division'})
 *
 *
 */

export const useEmployeeOrgs = (
  ussId: string,
  options?: OrgsOptions,
  queryOptions?: Omit<UseQueryOptions<OrgList>, 'queryFn' | 'queryKey'>
) => {
  return useQuery<OrgList>({
    ...queryOptions,
    queryFn: () =>
      getEmployeeOrgs(ussId, {
        ...options,
      }),
    queryKey: [`employee-orgs`, options],
  });
};

export const useCrewListOrgs = (
  ussId: string,
  employeeCrewIds?: string[],
  employee?: Person,
  options?: OrgsOptions,
  queryOptions?: Omit<UseQueryOptions<OrgList>, 'queryFn' | 'queryKey'>
) => {
  return useQuery<OrgList>({
    ...queryOptions,
    queryFn: () =>
      getEmployeeOrgs(ussId, {
        ...options,
      }),
    queryKey: [`crew-list-orgs`, options],
    select: (element) => {
      return {
        ...element,
        items: element.items.filter(
          (c) =>
            !employeeCrewIds?.includes(c.id) &&
            c.location === employee?.payrollLocation &&
            !(employee.empClass !== '4' && c.crew === 'SPECOT')
        ),
      };
    },
  });
};

interface UseOrgOptions {
  type: OrgType;
  parentOrgId?: string | null;
  sortBy?: OrgsSortByType;
  parentOrgs?: 'y';
  status?: OrgFilterStatus;
}

/**
 * Hook that fetches a list of orgs that are children under a specific parent.
 *
 * @param ussId ussId of the person that you are requesting orgs for
 * @param options.parentOrgId the id of the parent org
 * @param options.type the type of child orgs you want to fetch, this must something under the parent
 *
 * @example
 *
 * //  Fetches all divisions under the org with the id of 'abc'
 * const { data, status} = useOrgChildren('1234', {parentOrgId: 'abc', type: 'division'})
 *
 *
 */
export const useOrgChildren = (ussId: string, options: UseOrgOptions) => {
  return useQuery<OrgList>({
    queryFn: () =>
      getEmployeeOrgs<UseOrgOptions>(ussId, {
        ...options,
      }),
    queryKey: [`orgs-${options.type}`, ussId, { ...options }],
    enabled: !!options.parentOrgId || options.type === ORG_TYPE.PLANT,
  });
};

/**
 * Hook that fetches a list of locations for a given ussId.
 *
 * @param ussId ussId of the person that you are requesting orgs for
 *
 * @example
 *
 * //  Fetches all locations for the ussId '70006798'
 * const { data, status} = useAllLocations('70006798')
 *
 *
 */
export const useAllLocations = (ussId: string) => {
  const options = {
    type: 'location',
    parentOrgId: null,
    sortBy: 'location',
  };
  return useQuery<OrgList>({
    queryFn: () =>
      getEmployeeOrgs(ussId, {
        type: ORG_TYPE.LOCATION,
        parentOrgId: null,
        sortBy: 'location',
      }),
    queryKey: [`orgs`, ussId, { ...options }],
  });
};
