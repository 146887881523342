import Alert from 'components/Alert/Alert';
import SeparatedLabels from 'components/SeparatedLabels/SeparatedLabels';
import { Person } from 'features/people/types';
import getDateRangeText from 'features/timecards/utilities/getDateRangeText';
import React from 'react';

interface SameEmployeeModalType {
  fromPerson: Person | undefined;
  toPerson?: Person | null;
  sourceCrewKey: string | undefined;
  fromStartDate: Date | undefined;
  toStartDate: Date | undefined;
  fromEndDate?: Date;
  toEndDate?: Date;
  copyForSameEmployee: boolean;
  targetCrewKey: string | undefined;
}

const CopyEmployeeModal = ({
  toPerson,
  fromPerson,
  sourceCrewKey,
  fromStartDate,
  toStartDate,
  fromEndDate,
  toEndDate,
  copyForSameEmployee,
  targetCrewKey,
}: SameEmployeeModalType) => {
  return (
    <>
      {copyForSameEmployee && (
        <div className="flex flex-col w-full pb-2">
          <p className="text-gray-700 text-sm font-semibold text-left">
            {fromPerson?.displayName}
          </p>
          <SeparatedLabels
            size="sm"
            items={[
              {
                label: 'Employee uss id',
                value: fromPerson?.ussId,
              },

              {
                label: 'Employee crew key',
                value: sourceCrewKey,
              },
            ]}
          />
        </div>
      )}
      {copyForSameEmployee && (
        <div className="bg-white border-t border-gray-300 my-2 "></div>
      )}
      <div className="flex flex-col sm:flex-row sm:justify-between">
        <div
          className="flex flex-col flex-1 gap-1 items-start"
          data-testid="modal-from-section"
        >
          <p className="text-gray-700 text-base font-semibold">From</p>
          {!copyForSameEmployee && (
            <div className="flex flex-col items-start">
              <p className="text-gray-700 text-sm font-semibold">
                {fromPerson?.displayName}
              </p>
              <SeparatedLabels
                size="sm"
                items={[
                  {
                    label: 'Employee uss id',
                    value: fromPerson?.ussId,
                  },

                  {
                    label: 'Employee crew key',
                    value: sourceCrewKey,
                  },
                ]}
              />
            </div>
          )}
          <div className="flex sm:flex-col">
            <div className="flex flex-col  items-start sm:my-2">
              <p className="text-gray-700 font-light text-xs ">
                {fromEndDate && 'Start '}Week
              </p>
              <p className="text-gray-900 text-sm font-medium text-left ">
                {fromStartDate ? getDateRangeText(fromStartDate) : ''}
              </p>
            </div>
            {fromEndDate && (
              <div className="flex flex-col items-start sm:my-2">
                <p className="text-gray-700 font-light text-xs text-left ">
                  End Week
                </p>
                <p className="text-gray-900 text-sm font-medium text-left">
                  {getDateRangeText(fromEndDate)}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="bg-white sm:border-t-0 border-t border-gray-300 my-2 "></div>
        <div
          className="flex flex-col flex-1 gap-1 items-start "
          data-testid="modal-to-section"
        >
          <p className="text-gray-700 text-base font-semibold">To</p>
          {!copyForSameEmployee && toPerson && (
            <div className="flex flex-col items-start">
              <p className="text-gray-700 text-sm font-semibold">
                {toPerson.displayName}
              </p>
              <SeparatedLabels
                size="sm"
                items={[
                  {
                    label: 'Employee uss id',
                    value: toPerson.ussId,
                  },

                  {
                    label: 'Employee crew key',
                    value: targetCrewKey,
                  },
                ]}
              />
            </div>
          )}
          <div className="flex sm:flex-col">
            <div className="flex flex-col items-start sm:my-2">
              <p className="text-gray-700 font-light text-xs">
                {toEndDate && 'Start '}Week
              </p>
              <p className="text-gray-900 text-sm font-medium text-left">
                {toStartDate ? getDateRangeText(toStartDate) : ''}
              </p>
            </div>
            {toEndDate && (
              <div className="flex flex-col items-start sm:my-2">
                <p className="text-gray-700 font-light text-xs">End Week</p>
                <p className="text-gray-900 text-sm font-medium text-left">
                  {getDateRangeText(toEndDate)}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {copyForSameEmployee && (
        <div className="bg-white border-t border-gray-300 my-2 "></div>
      )}
      <p className="text-gray-700 text-md mt-4 text-left ">
        <Alert variant="info">
          {`Any requested or approved PTO in "To" date range will be overwritten
          and will need to be requested again`}
        </Alert>
        <div className="mt-2">
          Are you sure you want to copy the schedule(s)?
        </div>
      </p>
    </>
  );
};

export default CopyEmployeeModal;
