import { z } from 'zod';
import { sellBackHoursIncrement, sellBackMaximumPto } from '../validations';

export const SellBackPtoPostSchema = z
  .object({
    sellbackHours: z
      .number({
        required_error: 'sell back hours is required',
        invalid_type_error: 'sell back hours is required',
      })
      .nonnegative('Hours must be positive number or 0'),
    increment: z.number(),
    maximumPto: z.number(),
  })
  .refine(
    (data) => sellBackHoursIncrement(data.sellbackHours, data.increment),
    {
      message: 'Hours entered must be in correct PTO Increments',
      path: ['ptoHourIncrement'],
    }
  )
  .refine((data) => sellBackMaximumPto(data.sellbackHours, data.maximumPto), {
    message: 'Hours should not be greater than maximum pto hours',
    path: ['ptoHourMaximumPto'],
  });
//create type from zod schema
type SellBackPostValidationType = z.infer<typeof SellBackPtoPostSchema>;

//need to remove
export type SellBackPtoPost = Omit<SellBackPostValidationType, 'increment'>;

export const SellBackPTOPutSchema = z
  .object({
    sellbackHours: z
      .number({
        required_error: 'sell back hours is required',
        invalid_type_error: 'sell back hours is required',
      })
      .nonnegative('Hours must be positive number or 0'),
    increment: z.number(),
    maximumPto: z.number(),
  })
  .refine(
    (data) => sellBackHoursIncrement(data.sellbackHours, data.increment),
    {
      message: 'Hours entered must be in correct PTO Increments',
      path: ['ptoHourIncrement'],
    }
  )
  .refine((data) => sellBackMaximumPto(data.sellbackHours, data.maximumPto), {
    message: 'Hours should not be greater than maximum pto hours',
    path: ['ptoHourMaximumPto'],
  });
//create type from zod schema
type SellBackPutValidationType = z.infer<typeof SellBackPTOPutSchema>;

//need to remove
export type SellBackPtoPut = Omit<SellBackPutValidationType, 'increment'>;

export interface SellBackPtoOptions {
  ussId: string;
  year?: string;
  pageSize?: string;
  page?: string;
}
