import React, { useEffect, useState } from 'react';
import Page from 'components/Layouts/Page';
import Error from 'components/Error/Error';
import { NavLink } from 'react-router-dom';
import { BsPlus } from 'react-icons/bs';
import { RadioGroup, SearchInput, Select } from '@uss/react-components';
import useUserRoles from 'hooks/useUserRoles';
import Pagination from 'components/Pagination/Pagination';
import getPaycodeTypeText from 'features/paycode/utilities/getPaycodeTypeText';
import getPaycodeStatusText from 'features/paycode/utilities/getPaycodeStatusText';
import {
  ApplicableEmpType,
  PAYCODE_STATUS,
  PAYCODE_TYPE,
  PaycodeStatus,
  PaycodeTable,
  PaycodeType,
  SearchSortFilter,
} from 'features/paycode';
import { usePaycodes } from 'features/paycode/api/getPaycodes';
import { usePaycodeListStore } from 'features/paycode/state/store';
import useWindowSize from 'hooks/useWindowSize';
import { BiFilterAlt } from 'react-icons/bi';
import useDebounceSearch from 'features/timecards/hooks/useDebounceSearch';
import { MobileFilter } from 'features/paycode/components/Filters/MobileFilters';

const Paycode = () => {
  const store = usePaycodeListStore();
  const { data, status } = usePaycodes(store.params);
  const typeFilterList = Object.values(PAYCODE_TYPE);
  const statusFilterList = Object.values(PAYCODE_STATUS);

  //get user roles
  const { isSuperAdmin } = useUserRoles();

  const handleTypeChange = (value: PaycodeType | 'all') => {
    store.setType(value);
  };

  const handleEmployeeType = (value: ApplicableEmpType | 'all') => {
    store.setApplicableEmpType(value);
  };

  const handleStatusChange = (value: PaycodeStatus | 'all') => {
    store.setStatus(value);
  };
  const { width } = useWindowSize();
  const [searchTerm, setSearchTerm] = useState(store.params.search);
  const debounceSearch = useDebounceSearch(searchTerm ? searchTerm : '', 500);

  useEffect(() => {
    store.setSearch(debounceSearch ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value);
    store.setSearch(e.target.value);
  };

  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false);

  return (
    <Page
      breadcrumbs={[
        <NavLink key="bc-1" to="/admin">
          Admin
        </NavLink>,
      ]}
      heading="Pay Code Maintenance"
    >
      {width &&
        (width < 1024 ? (
          <Page.Section>
            <div className="flex flex-row justify-between flex-wrap mb-2">
              <MobileFilter
                open={isMobileFiltersOpen}
                setOpen={setIsMobileFiltersOpen}
              />
              <div className="w-10/12 ">
                <SearchInput
                  label="Search"
                  id="search"
                  value={searchTerm}
                  autoCompleteFlag={searchTerm ? true : false}
                  selectedFlag={searchTerm ? true : false}
                  onClose={() => {
                    setSearchTerm('');
                    store.setClearSearch();
                  }}
                  onChange={(e) => handleSearchChange(e)}
                  placeholder="Search"
                />
              </div>

              <div className="w-2/12 pl-4 pt-2">
                <BiFilterAlt
                  size={'1.5rem'}
                  className="text-primary-400 "
                  onClick={() => {
                    setIsMobileFiltersOpen(true);
                  }}
                />
              </div>
            </div>
            <NavLink
              className="flex flex-row justify-end items-center"
              to="/admin/paycodes/create"
              data-testid="create-paycode-link"
            >
              <div className="text-primary-400 font-semibold flex items-center mx-2">
                <BsPlus size="2rem" />
                <span>{isSuperAdmin ? 'Add' : 'Request'}</span>
              </div>
            </NavLink>
          </Page.Section>
        ) : (
          <>
            <SearchSortFilter />
            <Page.Section>
              <Page.Filters>
                <h2 className="sr-only">Pay Code Filters</h2>
                <Page.FiltersSection className="sm:grid sm:grid-cols-3 sm:gap-4 flex w-full flex-wrap">
                  <div className="col-span-2 flex flex-row flex-wrap mb-4 sm:mb-0">
                    <div className="flex items-center sm:border-r sm:border-gray-500 pr-4 mr-4 mb-4 sm:mb-0">
                      <RadioGroup
                        hideLabel
                        label="search"
                        selected={
                          store.params.applicableEmpType
                            ? store.params.applicableEmpType
                            : 'all'
                        }
                        onSelect={(value: ApplicableEmpType) =>
                          handleEmployeeType(value)
                        }
                      >
                        <RadioGroup.Option
                          data-testid="employee-type-radio-all"
                          variant="badges"
                          value="all"
                          id="all"
                        >
                          All
                        </RadioGroup.Option>
                        <RadioGroup.Option
                          variant="badges"
                          value="nonunion"
                          id="nonunion"
                          data-testid="employee-type-radio-nonunion"
                        >
                          Non Union
                        </RadioGroup.Option>
                        <RadioGroup.Option
                          variant="badges"
                          value="union"
                          id="union"
                          data-testid="employee-type-radio-union"
                        >
                          Union
                        </RadioGroup.Option>
                      </RadioGroup>
                    </div>
                    <div className="flex flex-grow">
                      <Select
                        data-testid="paycode-type-select"
                        className="w-2/3 mr-4"
                        selected={{
                          value: store.params.type,
                        }}
                        onSelect={(item: PaycodeType | 'all') =>
                          handleTypeChange(item)
                        }
                      >
                        <Select.Button
                          label={
                            store.params.type
                              ? store.params.type[0].toLocaleUpperCase() +
                                store.params.type.slice(1)
                              : 'Select Pay Code Type'
                          }
                        />
                        <Select.Options>
                          <Select.Option value="all">All</Select.Option>
                          {typeFilterList.map((i) => {
                            return (
                              <Select.Option key={i} value={i}>
                                {getPaycodeTypeText(i)}
                              </Select.Option>
                            );
                          })}
                        </Select.Options>
                      </Select>

                      <Select
                        data-testid="paycode-status-select"
                        className="w-5/12"
                        selected={{
                          value: store.params.status,
                        }}
                        onSelect={(item: PaycodeStatus | 'all') =>
                          handleStatusChange(item)
                        }
                      >
                        <Select.Button
                          label={
                            store.params.status
                              ? store.params.status[0].toLocaleUpperCase() +
                                store.params.status.slice(1)
                              : 'Select Status'
                          }
                        />
                        <Select.Options>
                          <Select.Option value="all">All</Select.Option>
                          {statusFilterList.map((t) => {
                            return (
                              <Select.Option key={t} value={t}>
                                {getPaycodeStatusText(t)}
                              </Select.Option>
                            );
                          })}
                        </Select.Options>
                      </Select>
                    </div>
                  </div>
                  <div className="flex justify-center w-full sm:justify-end ">
                    <NavLink
                      className="flex flex-row justify-center items-center"
                      to="/admin/paycodes/create"
                      data-testid="create-paycode-link"
                    >
                      <div className="text-primary-400 font-semibold flex items-center mx-2">
                        <BsPlus size="2rem" />
                        <span>
                          {isSuperAdmin ? 'Add Pay Code' : 'Request Pay Code'}
                        </span>
                      </div>
                    </NavLink>
                  </div>
                </Page.FiltersSection>
              </Page.Filters>
            </Page.Section>
          </>
        ))}
      <Page.Section>
        <div className="p-4 bg-white rounded-md">
          {status === 'error' ? (
            <Error />
          ) : (
            <PaycodeTable paycodes={data} status={status} />
          )}
        </div>
      </Page.Section>
      <Page.Section bg="gray">
        <Pagination
          pageOptions={[5, 10, 15, 20, 25, 30]}
          totalCount={data?.totalCount}
          page={data?.page}
          pageSize={data?.pageSize}
          onPageChange={(s) => store.setPage(s.selected + 1)}
          onPageSizeChange={(ps) => store.setPageSize(ps)}
        />
      </Page.Section>
    </Page>
  );
};

export default Paycode;
