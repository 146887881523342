/**
 * @description (Save, Save & Approve) Cannot have hours without a turn selected
 * @returns
 */

import { TimecardEfforts } from 'features/timecards/types';

export const hoursAndTurn = (value: TimecardEfforts) => {
  if (value.hours !== null && value.hours !== 0) {
    return value.turn !== '' && value.turn !== null ? true : false;
  } else {
    return true;
  }
};
