export enum TabTypes {
  TIMECARD_SCHEDULING = 'Timecard Scheduling',
  TIMECARD_APPROVALS = 'Timecard Approvals',
  HISTORY = 'History',
  POLICY_SUMMARY = 'Policy Summary',
  VIEW_ALL_CREWS = 'View All Crews',
  SWIPE_TIMES = 'Swipe Times',
  ANNUAL_PTO_TOTALS = 'Annual PTO Totals',
}

export type SchedulingDetailsTabs = Exclude<
  TabTypes,
  TabTypes.TIMECARD_APPROVALS
>;

export type PayDetailsTabs = Exclude<TabTypes, TabTypes.TIMECARD_SCHEDULING>;
