import React, { useEffect } from 'react';
import { addYears, format, parse, parseISO, subDays, subYears } from 'date-fns';
import { PtoFormType } from 'features/paid-time-off/components/Forms/RequestPtoForm';
import { UpdatePtoRequestSchema } from 'features/paid-time-off/schemas/update-pto-schema';
import {
  UpdatePtoRequestPut,
  PtoDetailsFields,
} from 'features/paid-time-off/types';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { Datepicker, Select, TextArea } from '@uss/react-components';
import { getPtoPaycodes } from 'features/paid-time-off/utility/getPtoPaycodeList';
import ValidationError from 'components/Validation/ValidationError';
import { validateForm } from 'utils/validateForm';
import {
  useDeletePtoRequest,
  useUpdatePtoRequest,
} from 'features/paid-time-off/api/mutations';
import { zodResolver } from '@hookform/resolvers/zod';
import { RequestPtoSchema } from 'features/paid-time-off/schemas/request-pto-schema';
import { usePersonDetails } from 'features/people/api';
import { appNewDate } from 'utils/appNewDate';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import useModal from 'components/Modals/use-modal';
import { ptoFormFieldEnable } from 'features/paid-time-off/utility/ptoFormFieldEnable';
import { DATE_RANGE_PAYCODES, PTO_UPDATE_CONTEXT } from 'features/paid-time-off/constants';
import checkCrossOverWeek from 'features/paid-time-off/utility/checkCrossOverWeek';
import { PayPeriod, usePayPeriodWithDateRange } from 'features/pay-periods';
import { getFuturePayPeriod } from 'features/paid-time-off/utility/getFuturePayPeriod';
import { ActionButtons } from './PTODetailsActionBtns';
import { PtoScreenType } from 'features/paid-time-off/types/pto-requests';

const PtoDetailsForm = ({
  ptoDetails,
  ptoId,
  ptoType,
}: {
  ptoDetails: PtoDetailsFields;
  ptoId: string;
  ptoType: PtoScreenType;
}) => {
  const { id = '' } = useParams<'id'>();
  //Edit PTO
  const { mutateAsync: updatePtoRequest, isPending: editAwaiting } =
    useUpdatePtoRequest(ptoId, ptoDetails.ussId);
  //Remove PTO
  const { mutateAsync: removePtoRequest, isPending: removeAwaiting } =
    useDeletePtoRequest(ptoId, id);

  const { data: user } = usePersonDetails(id ? id : 'me');
  const {
    control,
    register,
    setValue,
    setError,
    clearErrors,
    getValues,
    watch,
    formState: { errors },
  } = useForm<PtoFormType>({
    resolver: zodResolver(RequestPtoSchema),
  });

  const years = [
    format(subYears(new Date(), 1), 'yyyy'),
    format(new Date(appNewDate()), 'yyyy'),
    format(addYears(new Date(), 1), 'yyyy'),
  ];

  const notes = useWatch({ control, name: 'notes' });
  const paycode = useWatch({ control, name: 'paycode' });
  const chargeYear = useWatch({ control, name: 'chargeYear' });
  const paycodeDesc = useWatch({ control, name: 'paycodeDescription' });
  const [enableValidator, setEnableValidator] = useState(false);

  /*Disabling dates in Blackout-Detail datepicker logic : START */
  /*Enable adding blackout for dates with future pay-period only*/
  const currentYear = new Date().getFullYear().toString();
  //Payperiod API call - with date range of 2 years
  const { data } = usePayPeriodWithDateRange(
    user?.payrollNameId ? user.payrollNameId : '',
    {
      startDate: `${currentYear}-1-1`,
      endDate: `${`${parseInt(currentYear) + 1}`}-12-31`,
    }
  );
  //Future Pay Period
  const futurePayPeriod: PayPeriod[] = getFuturePayPeriod(data);

  //getting first day of current month
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  /*Disabling dates in Blackout-Detail datepicker logic : END */

  React.useEffect(() => {
    if (enableValidator) {
      const subscription = watch((value) => {
        if (ptoId && ptoId.length > 0) {
          clearErrors();
          validateForm(value, UpdatePtoRequestSchema, setError);
        }
      });
      return () => subscription.unsubscribe();
    }
    if (ptoDetails.startDate) setValue('ptoDate', ptoDetails.startDate);
    if (ptoDetails.notes) setValue('notes', ptoDetails.notes);
    if (ptoDetails.chargeYear)
      setValue(
        'chargeYear',
        ptoDetails.chargeYear ? ptoDetails.chargeYear.toString() : ''
      );
    if (ptoDetails.paycode) {
      setValue('paycode', ptoDetails.paycode);
      handlePaycodeDesc(ptoDetails.paycode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, enableValidator, ptoDetails]);
  const handlePaycodeDesc = (val: string) => {
    if (val) {
      const descArr =
        user && getPtoPaycodes(user.ptoClass, user.shiftAssignment);
      const matchedDesc = descArr?.filter((item) => item.paycode === val);
      matchedDesc &&
        setValue('paycodeDescription', matchedDesc[0]?.description);
    }
  };

  const navigate = useNavigate();
  const modal = useModal();

  const confirmUpdate = async (payload: UpdatePtoRequestPut) => {
    const data = getValues();
    const { paycodeDescription, ...UpdatePtoRequestSchema } = data;

    if (validateForm(data, UpdatePtoRequestSchema, setError)) {
      await updatePtoRequest(payload);
    }
    modal.closeModal();
  };

  const confirmRemove = async () => {
    await removePtoRequest();
    modal.closeModal();
    handleCancel();
  };
  const openModal = ({
    title,
    message,
    type,
    payload,
  }: {
    title: string;
    message: string;
    type: 'success' | 'danger' | 'regular';
    payload: UpdatePtoRequestPut | null;
  }) => {
    modal.openModal({
      title: title,
      type: type,
      children: <span>{message}</span>,
      labels: {
        confirm:
          payload === null ? 'Remove' : type === 'danger' ? 'Deny' : 'Approve',
        cancel: 'Cancel',
      },
      confirmVariant: type === 'danger' ? 'danger' : 'primary',
      onCancel: () => modal.closeModal(),
      onConfirm: () =>
        payload === null ? confirmRemove() : confirmUpdate(payload),
    });
  };
  const handleRemove = () => {
    openModal({
      title: `Remove ${ptoDetails.status === 'blackout' ? 'Blackout' : 'PTO'}`,
      message: `Are you sure you want to Remove ${
        ptoDetails.status === 'blackout' ? 'Blackout' : 'PTO'
      } for
    ${format(parseISO(ptoDetails.startDate), 'MMMM dd, yyyy')}?`,
      type: 'danger',
      payload: null,
    });
  };

  /**
   * @description Function that gives the status based on ptoStatus and ptoType
   *
   * Crew Member > My PTO (NU)
    Edits Notes on an Approved PTO Request - clicks Save 
    - Remains in Approved state
  
    * Crew Member > My PTO (NU)
      Edits Date on an Approved PTO Request - clicks Save - Changes from Approved to Requested
      Edits Pay Code on an Approved PTO Request - clicks Save - Changes from Approved to Requested
    *
  */
  const getStatus = (
    payload: UpdatePtoRequestPut,
    ptoDetails: PtoDetailsFields
  ) => {
    if (ptoDetails.status !== 'approved') {
      return ptoDetails.status;
    } else {
      if (ptoType === 'myPto') {
        //For status Approved
        //For My PTO - if paycode or date or chargeyear value changes then change status=requested
        if (
          payload.paycode !== ptoDetails.paycode ||
          payload.ptoDate !== ptoDetails.startDate ||
          (!isChargeYearHidden &&
            payload.chargeYear !== ptoDetails.chargeYear.toString())
        )
          return 'requested';
        else if (payload.notes !== ptoDetails.notes) return 'approved';
        else return 'approved';
      } else return 'approved';
    }
  };

  const handleSave = async (actionType: string) => {
    setEnableValidator(true);
    clearErrors();
    const data = getValues();
    const payload: UpdatePtoRequestPut = {
      chargeYear: data.chargeYear,
      paycode: data.paycode,
      status: 'approved',
      ptoDate: data.ptoDate,
      notes: data.notes,
    };
    //add context param only for empPto/crewPto/requests(NU)
    if (ptoType !== 'myPto') payload.context = PTO_UPDATE_CONTEXT.EMP_PTO;
    if (actionType === 'save') {
      if (
        ptoDetails.status === 'approved' ||
        ptoDetails.status === 'requested' ||
        ptoDetails.status === 'denied' ||
        ptoDetails.status === 'adjustment'
      ) {
        payload.status = getStatus(payload, ptoDetails);
        //adding default value from ptoDetails for field chargeYear if its value is undefined
        if (!data.chargeYear) {
          payload.chargeYear = ptoDetails.chargeYear
            ? ptoDetails.chargeYear.toString()
            : '';
        }
        //adding default value from ptoDetails for field chargeYear if its value is undefined
        if (!data.chargeYear) {
          payload.chargeYear = ptoDetails.chargeYear.toString();
        }
        if (validateForm(data, UpdatePtoRequestSchema, setError)) {
          await updatePtoRequest(payload);
        }
      }
    } else if (actionType === 'approve') {
      openModal({
        title: 'Save & Approve PTO?',
        message: `Are you sure you want to Save & Approve PTO for
      ${format(parseISO(data.ptoDate), 'MMMM dd, yyyy')}?`,
        type: 'success',
        payload: payload,
      });
    } else if (actionType === 'deny') {
      payload.status = 'denied';
      openModal({
        title: 'Save & Deny PTO?',
        message: `Are you sure you want to Save & Deny PTO for
      ${format(parseISO(data.ptoDate), 'MMMM dd, yyyy')}?`,
        type: 'danger',
        payload: payload,
      });
    }
  };
  const handleCancel = () => {
    ptoType === 'empPto'
      ? navigate(`/paid-time-off/employees/${id}`)
      : navigate('/my-pto-nu');
  };

  //Save function for Blackout records only
  const handleBlackoutSave = async () => {
    setEnableValidator(true);
    clearErrors();
    const data = getValues();
    if (ptoDetails.status === 'blackout') {
      const payload: UpdatePtoRequestPut = {
        status: ptoDetails.status,
        ptoDate: data.ptoDate,
        notes: data.notes,
        context: PTO_UPDATE_CONTEXT.EMP_PTO
      };
      if (validateForm(payload, UpdatePtoRequestSchema, setError)) {
        await updatePtoRequest(payload);
      }
    }
  };

  const actions = {
    handleSave,
    handleCancel,
    handleRemove,
    handleBlackoutSave,
  };

  //Logic to check crossover week
  const dateVal = useWatch({ control, name: 'ptoDate' });
  const _paycode = useWatch({ control, name: 'paycode' });
  const hasDateRange = DATE_RANGE_PAYCODES.includes(ptoDetails.paycode);

  const isRangeInCrossOverWeek =
    hasDateRange &&
    checkCrossOverWeek(dateVal ? dateVal : ptoDetails.startDate, undefined);

  const payCodeVal = _paycode ? _paycode : ptoDetails.paycode;
  const hasChargeYear =
    (payCodeVal === 'VH' || payCodeVal === 'VD') && isRangeInCrossOverWeek;

  const isBlackout = ptoDetails.status === 'blackout';
  const {
    formFields: {
      isPayCodeDisabled,
      isDateDisabled,
      isChargeYearDisabled,
      isNotesDisabled,
      isChargeYearHidden,
    },
    buttons: {
      isSaveDisabled,
      isRemoveDisabled,
      isDenyDisabled,
      isApproveDisabled,
    },
  } = ptoFormFieldEnable({
    ptoDetails,
    hasChargeYear,
    ptoType,
  });

  useEffect(() => {
    if (_paycode) {
      handlePaycodeDesc(_paycode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_paycode]);
  return (
    <form className="@container">
      <div className="bg-white sm:bg-white rounded-md xl:p-4">
        <div className="max-w-2xl mx-auto xl:p-8 p-3 mt-2">
          <div className="grid grid-cols-1 @xl:grid-cols-2 gap-4 ">
            {/* PayCode */}
            {!isBlackout && (
              <div className="@xl:col-start-1 @xl:col-end-2">
                <label
                  className="text-xs text-gray-700 font-normal"
                  htmlFor="paycode"
                >
                  Pay Code
                </label>
                <Controller
                  control={control}
                  defaultValue={paycode}
                  name="paycode"
                  render={({ field, fieldState: { invalid, error } }) => (
                    <>
                      <Select
                        {...field}
                        selected={field.value}
                        onSelect={field.onChange}
                        className="w-full"
                        disabled={isPayCodeDisabled}
                      >
                        <Select.Button
                          label={
                            field.value
                              ? field.value + ` - ${paycodeDesc}`
                              : 'Select Paycode'
                          }
                          error={invalid}
                        />
                        <Select.Options error={invalid}>
                          {user &&
                            getPtoPaycodes(
                              user.ptoClass,
                              user.shiftAssignment
                            ).map((item, itemIdx) => (
                              <Select.Option key={itemIdx} value={item.paycode}>
                                <span>
                                  {item.paycode} - {item.description}
                                </span>
                              </Select.Option>
                            ))}
                        </Select.Options>
                      </Select>
                      {invalid && (
                        <ValidationError
                          msg={error?.message ?? ''}
                          name="paycodeRequired"
                        />
                      )}
                    </>
                  )}
                />
              </div>
            )}

            {/* Pto Date */}
            <div className="@xl:col-start-1 @xl:col-end-2">
              <Controller
                control={control}
                defaultValue={ptoDetails.startDate}
                name="ptoDate"
                render={({ field, fieldState: { invalid, error } }) => (
                  <>
                    {ptoDetails.status !== 'blackout' ? (
                      <Datepicker
                        isLabel={true}
                        onDateChange={(date) => {
                          field.onChange(format(date, 'yyyy-MM-dd'));
                          setValue('chargeYear', format(date, 'yyyy'));
                        }}
                        calendarType={'date'}
                        id="pto-date"
                        ariaLabelledBy={'Date'}
                        {...register('ptoDate')}
                        isError={invalid}
                        errormessage={error?.message ?? ''}
                        startDate={
                          field.value
                            ? parse(field.value, 'yyyy-MM-dd', new Date())
                            : parse(
                                ptoDetails.startDate,
                                'yyyy-MM-dd',
                                new Date()
                              )
                        }
                        disabled={isDateDisabled}
                      />
                    ) : (
                      <Datepicker
                        isLabel={true}
                        onDateChange={(date) => {
                          field.onChange(format(date, 'yyyy-MM-dd'));
                          setValue('chargeYear', format(date, 'yyyy'));
                        }}
                        calendarType={'date'}
                        id="pto-date"
                        ariaLabelledBy={'Date'}
                        {...register('ptoDate')}
                        isError={invalid}
                        errormessage={error?.message ?? ''}
                        startDate={
                          field.value
                            ? parse(field.value, 'yyyy-MM-dd', new Date())
                            : parse(
                                ptoDetails.startDate,
                                'yyyy-MM-dd',
                                new Date()
                              )
                        }
                        disabled={isDateDisabled}
                        minDate={
                          new Date(
                            futurePayPeriod[0]
                              ? parseISO(futurePayPeriod[0].startDate)
                              : ''
                          )
                        }
                        excludeDateIntervals={[
                          {
                            start: firstDay,
                            end: subDays(
                              new Date(
                                futurePayPeriod[0]
                                  ? parseISO(futurePayPeriod[0].startDate)
                                  : ''
                              ),
                              1
                            ),
                          },
                        ]}
                      />
                    )}
                  </>
                )}
              />
            </div>

            {/* Charge Year */}
            {!isChargeYearHidden && !isBlackout && (
              <div className="@xl:col-start-1 @xl:col-end-2">
                <label
                  className="text-xs text-gray-700 font-normal"
                  htmlFor="chargeYear"
                >
                  Charge Year
                </label>
                <Controller
                  control={control}
                  name="chargeYear"
                  defaultValue={chargeYear}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <Select
                      {...field}
                      selected={field.value}
                      onSelect={field.onChange}
                      className="w-full"
                      disabled={isChargeYearDisabled}
                      defaultValue={
                        ptoDetails.chargeYear
                          ? ptoDetails.chargeYear.toString()
                          : ''
                      }
                    >
                      <Select.Button
                        label={field.value ? field.value : 'Select'}
                      />
                      <Select.Options>
                        {years.map((item, itemIdx) => (
                          <Select.Option key={itemIdx} value={item}>
                            <span>{item}</span>
                          </Select.Option>
                        ))}
                      </Select.Options>
                    </Select>
                  )}
                />
              </div>
            )}

            {/* Notes */}
            <div className="@xl:col-span-2">
              <label
                className="text-xs text-gray-700 font-normal"
                htmlFor="notes"
              >
                Notes (Optional)
              </label>
              <div className="col-span-2 mb-4 lg:mb-0">
                <TextArea
                  maxLength={100}
                  label="Note"
                  id="form-comment"
                  placeholder={'Enter text'}
                  {...register('notes')}
                  hideLabel
                  error={!!errors.notes?.message}
                  errorMessage={errors.notes?.message}
                  disabled={isNotesDisabled}
                  defaultValue={notes ? notes : ''}
                />
                <div className="col-span-2 text-xs mb-2 text-gray-600 text-right">
                  {100 - (notes ? notes.length : 0)} Characters left
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ActionButtons
        buttonStates={{
          isSaveDisabled: isSaveDisabled,
          isRemoveDisabled: isRemoveDisabled,
          isDenyDisabled: isDenyDisabled,
          isApproveDisabled: isApproveDisabled,
        }}
        ptoType={ptoType}
        actions={actions}
        ptoDetails={ptoDetails}
        editAwaiting={editAwaiting}
        removeAwaiting={removeAwaiting}
      />
    </form>
  );
};
export default PtoDetailsForm;
