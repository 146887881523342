/**
 * @description request pto range end date cannot be earlier than start date.
 * @returns
 */

import { isBefore, isValid } from 'date-fns';

export const requestPtoDateRange = (
  startDate: string,
  endDate: string | null
) => {
  // check if end date is not null and start and end date is valid dates
  if (endDate && isValid(new Date(endDate)) && isValid(new Date(startDate))) {
    //check if end date is before start date
    if (isBefore(new Date(endDate), new Date(startDate))) {
      return false;
    }
  }
  return true;
};
