import { axios } from '../../../services/api/axios';
import { useMutation } from '@tanstack/react-query';
import { WMSError } from 'types/common';
import { ChangeCrewBody, ChangeCrewMutationObjType } from '../types';
import { queryClient } from 'services/api/react-query';

/**
 * Move employee future schedules to different crew
 *
 * @param ussId USS ID of the employee
 * @param crewId Crew ID of the employee's current crew
 * @returns Promise
 */
const changeCrews = (
  ussId: string,
  crewId: string,
  body: ChangeCrewBody
): Promise<{ success: boolean; message: string }> => {
  return axios.put(`/v1/people/${ussId}/crews/${crewId}/change-crew`, body);
};

/**
 * Hook that allows to  Move employee's future schedules to different crew
 *
 * @returns React Query useMutation Result
 *
 * @link http://cappsq6.psc.uss.com:8382/SBWM-DOCUMENT/swagger-ui/index.html?urls.primaryName=sbwm-people#/crew-controller/changeCrews
 */

export const useChangeCrews = () => {
  return useMutation<
    {
      success: boolean;
      message: string;
    },
    WMSError,
    ChangeCrewMutationObjType
  >({
    mutationFn: (obj: ChangeCrewMutationObjType) =>
      changeCrews(obj.ussId, obj.crewId, obj.body),
    onSuccess: async () => {
      queryClient.removeQueries({ queryKey: ['crew-members'] });
      await queryClient.refetchQueries({ queryKey: ['employee-securities'] });
    },
  });
};
