import React from 'react';
import { NotFound } from '@uss/react-components';
import { StyleProps } from '@uss/react-components/lib/components/NotFound/NotFoundSVG';

interface NoResultsProps extends StyleProps {
  title?: string;
  description?: string;
}

const NoResults = ({ title, description, width, height }: NoResultsProps) => {
  return (
    <div className="uss-flex uss-flex-col uss-items-center py-8">
      <NotFound width={width} height={height} />
      <span className="uss-text-lg uss-text-gray-700 uss-font-semibold">
        {title ? title : 'No Records Found'}
      </span>
      <span className="uss-text-sm uss-text-gray-700">
        {description
          ? description
          : 'No employee records found, Please modify the search criteria to view the records'}
      </span>
    </div>
  );
};

export default NoResults;
