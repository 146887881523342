import { PaginationResponse } from 'types/common';
import { PtoDetail } from '.';

export interface PtoDetailsList extends PaginationResponse {
  items: PtoDetail[];
}
export interface PtoSummaryOptions {
  year?: string; //Default value - current year
}

export const AddPTOPlantType = {
  miniMill: 'mini_mill',
  integratedMill: 'integrated_mill',
  office: 'office',
};

export const IntegratedEmployeeClass = {
  0: '0',
  4: '4',
  3: '3',
  6: '6',
};

export const EmpPTOVal = {
  PTO: 'PTO',
  Blackout: 'Blackout',
};
