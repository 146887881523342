import { Button, Modal, TextArea } from '@uss/react-components';
import useNotification from 'components/Notification/useNotification';
import { useOrgDetails, useUpdateOrg } from 'features/org/api';
import { OrgPutSchema } from 'features/org/schemas';
import { OrgFormType, OrgPut, ORG_TYPE } from 'features/org';
import { updateOrgData } from 'features/org/utilities/updateOrgData';
import useValidateForm from 'hooks/useValidateForm';
import _ from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { queryClient } from 'services/api/react-query';
import { ZodErrorType } from 'types/common';

interface ConfirmationModalProps {
  orgId: string;
  org: OrgFormType;
  type: typeof ORG_TYPE.DIVISION | typeof ORG_TYPE.DEPARTMENT;
  handleCancel: () => void;
  setIsFormUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmationModal = ({
  orgId,
  org,
  type,
  handleCancel,
  setIsFormUpdated,
}: ConfirmationModalProps) => {
  const [comment, setComment] = useState('');
  const [errors, setErrors] = useState<ZodErrorType>({});
  const [enableValidator, setEnableValidator] = useState<boolean>(false);
  const [updatedData, setUpdatedData] = useState(org);

  //get selected org details
  const { data } = useOrgDetails(orgId);

  //org post reducer
  const [orgData, dispatch] = useReducer(updateOrgData, org);

  //update org api hook
  const { mutateAsync: editOrgRecord } = useUpdateOrg(
    typeof data !== 'undefined' ? data.id : ''
  );

  const { trimValues, validateForm } = useValidateForm(OrgPutSchema, setErrors);
  //notification
  const notify = useNotification();

  const handleChangeComment = (text: string) => {
    setEnableValidator(true);
    setComment(text);
    dispatch({ type: 'comments', payload: text });
  };

  useEffect(() => {
    const updateDetails = {
      ...updatedData,
      comments: comment,
    };
    setUpdatedData(updateDetails);

    if (enableValidator) {
      validateForm(trimValues(updateDetails));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment, enableValidator]);

  const validateField = (name: string): boolean => {
    return (
      !_.isEmpty(errors) &&
      typeof errors[name] === 'string' &&
      errors[name].length > 0
    );
  };

  const updateDivision = async () => {
    const payload = {
      parentOrgId: orgData.parentOrgId,
      name: orgData.name.trim(),
      description: orgData.description.trim(),
      comments: orgData.comments.trim(),
      sortSequence: data?.sortSequence,
      safetyMeetingWeekOfMonth: data?.safetyMeetingWeekOfMonth,
      safetyMeetingDayOfWeek: data?.safetyMeetingDayOfWeek,
      safetyMeetingTurnOnTheDay: data?.safetyMeetingTurnOnTheDay,
      safetyMeetingLengthMinutes: data?.safetyMeetingLengthMinutes,
      safetyMeetingBeforeOrAfterShift: data?.safetyMeetingBeforeOrAfterShift,
      safetyMeetingIncentiveEligible: data?.safetyMeetingIncentiveEligible,
    };
    await editOrgRecord(payload as OrgPut, {
      onSuccess: async () => {
        await queryClient.refetchQueries({ queryKey: [`orgs-division`] });
        await queryClient.refetchQueries({
          queryKey: [`org-details-${orgId}`, orgId],
        });
        setIsFormUpdated(false);
        notify.success({
          message: 'You have successfully saved this division',
        });
      },
    });
  };

  const updateDepartment = async () => {
    const payload = {
      parentOrgId: orgData.parentOrgId,
      name: orgData.name.trim(),
      description: orgData.description.trim(),
      comments: orgData.comments.trim(),
      sortSequence: data?.sortSequence,
      safetyMeetingWeekOfMonth: data?.safetyMeetingWeekOfMonth,
      safetyMeetingDayOfWeek: data?.safetyMeetingDayOfWeek,
      safetyMeetingTurnOnTheDay: data?.safetyMeetingTurnOnTheDay,
      safetyMeetingLengthMinutes: data?.safetyMeetingLengthMinutes,
      safetyMeetingBeforeOrAfterShift: data?.safetyMeetingBeforeOrAfterShift,
      safetyMeetingIncentiveEligible: data?.safetyMeetingIncentiveEligible,
    };
    await editOrgRecord(payload as OrgPut, {
      onSuccess: async () => {
        await queryClient.refetchQueries({ queryKey: [`orgs-department`] });
        await queryClient.refetchQueries({
          queryKey: [`org-details-${orgId}`, orgId],
        });
        setIsFormUpdated(false);
        notify.success({
          message: 'You have successfully saved this department',
        });
      },
    });
  };

  const handleSubmit = async () => {
    setEnableValidator(true);
    if (validateForm(trimValues(updatedData))) {
      setEnableValidator(false);

      if (type === ORG_TYPE.DIVISION) {
        await updateDivision();
      } else {
        await updateDepartment();
      }
      handleCancel();
    }
  };

  return (
    <>
      <div>
        <p className="text-sm text-gray-700" data-testid="org-modal-body">
          Are you sure you want to save the changes?
        </p>
      </div>
      <div className="col-span-2 mb-4 lg:mb-0">
        <TextArea
          maxLength={500}
          label=""
          id="form-comment"
          name="comment"
          placeholder="Describe reason"
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            handleChangeComment(e.target.value);
          }}
          value={comment}
          error={validateField('comments')}
          errorMessage={errors.comments}
          data-testid="modal-description"
        />
        <div
          className="col-span-2 text-xs my-2 text-gray-600 text-right"
          data-testid="description-characters-left"
        >
          {500 - comment.length} Characters left
        </div>
      </div>

      <Modal.Actions>
        <Button
          onClick={handleCancel}
          variant="outline"
          className="w-full sm:w-auto"
          data-testid="modal-cancel"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            void handleSubmit();
          }}
          variant="primary"
          className="ml-0 sm:ml-4 w-full sm:w-auto mt-3 sm:mt-0"
          type="submit"
          data-testid="modal-confirm"
        >
          Save
        </Button>
      </Modal.Actions>
    </>
  );
};

export default ConfirmationModal;
