import { Accordion } from '@uss/react-components';
import LabeledText from 'components/Typography/LabeledText';
import useWindowSize from 'hooks/useWindowSize';

interface Props {
  crewDescription?: string | null;
  name?: string | null;
  email?: string | null;
  crewKey?: string | null;
}

const Detailscard = ({
  name = '-',
  crewKey = '-',
  email = '-',
  crewDescription = '-',
}: Props) => {
  const { width } = useWindowSize();

  return (
    <>
      {width &&
        (width < 1024 ? (
          <Accordion
            variant="default"
            className="shadow-md bg-white rounded-lg border border-blue-600"
            data-testid="details-card"
          >
            <Accordion.Button variant="default">
              <DetailsHeader crewKey={crewKey} />
            </Accordion.Button>
            <Accordion.Panel variant="default">
              <div className="flex flex-row flex-wrap w-full items-center border-t border-gray-200 pt-4">
                <DetailsBody
                  name={name}
                  email={email}
                  crewDescription={crewDescription}
                />
              </div>
            </Accordion.Panel>
          </Accordion>
        ) : (
          <div
            data-testid="details-card"
            className="shadow-md w-full p-4 bg-white rounded-lg border border-blue-600"
          >
            <DetailsHeader crewKey={crewKey} />
            <DetailsBody
              name={name}
              email={email}
              crewDescription={crewDescription}
            />
          </div>
        ))}
    </>
  );
};

const DetailsHeader = ({ crewKey }: { crewKey: string | null }) => {
  return (
    <div className="w-full">
      <span
        className="text-gray-700 text-lg font-semibold line-clamp-1"
        data-testid="crew-name"
      >
        {crewKey}
      </span>
    </div>
  );
};

interface DetailsBodyProps {
  crewDescription: string | null;
  name: string | null;
  email: string | null;
}

const DetailsBody = ({ name, email, crewDescription }: DetailsBodyProps) => {
  return (
    <>
      <div className="flex flex-wrap justify-between flex-grow sm:flex-nowrap w-full">
        <LabeledText
          label="Crew Description"
          lineClamp={1}
          className="w-1/2 md:w-3/12"
        >
          {crewDescription}
        </LabeledText>
        <LabeledText
          label="Primary Pay Approver Name"
          lineClamp={1}
          className="w-1/2 md:w-3/12"
        >
          {name}
        </LabeledText>
        <LabeledText
          label="Primary Pay Approver’s Email Address"
          lineClamp={1}
          no_clamp
          className="w-1/2 md:w-3/12 pt-4 md:pt-0"
        >
          {email}
        </LabeledText>
        {/* <LabeledText
            label="Primary Contact Phone Number"
            lineClamp={1}
            className="w-1/2 md:w-3/12 pt-4 md:pt-0"
          >
            {crewDetails
              ? crewDetails.crewPayApproverDetails[0].payApprovers[0]
                  .primaryContactNumber
              : '-'}
          </LabeledText> */}
      </div>
    </>
  );
};

export default Detailscard;
