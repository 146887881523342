import {
  PolicySummaryAdjustmentWiseHours,
  PolicySummaryTotalHours,
} from 'features/timecards/types';
import { getFormattedPolicySummaryHrs as formatHours } from 'features/timecards/utilities/getFormattedPolicySummaryData';
const PolicySummaryCardHeader = ({
  adjustmentWiseHours,
  isBrsPayroll,
  shouldDisplayUnWorkedHrs,
  isAdjustmentTimecard,
}: {
  adjustmentWiseHours:
    | PolicySummaryAdjustmentWiseHours
    | PolicySummaryTotalHours;
  isBrsPayroll: boolean;
  shouldDisplayUnWorkedHrs: boolean;
  isAdjustmentTimecard: boolean;
}) => {
  return (
    <div className="flex w-full mb-4 ">
      <div className="flex flex-col md:gap-y-4 md:flex-row flex-wrap  justify-center md:justify-start md:items-center w-1/2 md:w-[70%] xl:w-3/4 border-r-4 border-gray-300 pr-4 md:pr-0">
        {adjustmentWiseHours.crewWise.map((crew) => (
          <div
            className="w-full md:w-1/3 mb-6 md:mb-0 last-of-type:mb-0"
            key={crew.crewId}
            data-testid="crew"
          >
            <div className="flex flex-col  justify-around w-full md:w-4/5 xl:w-3/5 ">
              <p
                className="text-sm  text-gray-700 font-light mb-2"
                data-testid="crew-key"
              >
                {crew.crewKey}
              </p>
              <span
                className="flex flex-row justify-between pb-1 border-dotted border-b border-gray-400"
                data-testid="crew-worked-hrs"
              >
                <span className="text-xs font-light line">Worked Hrs</span>
                <span className="text-sm text-accent1-500 md:text-accent1-700 pl-3">
                  {crew.workedHours !== null
                    ? formatHours(crew.workedHours)
                    : '-'}
                </span>
              </span>
              {shouldDisplayUnWorkedHrs && (
                <span
                  className="flex flex-row justify-between mt-1"
                  data-testid="crew-unworked-hrs"
                >
                  <span className="text-xs font-light">Unworked Hrs</span>
                  <span className="text-sm text-accent1-500 md:text-accent1-700 pl-3">
                    {crew.unworkedHours !== null
                      ? formatHours(crew.unworkedHours, isAdjustmentTimecard)
                      : '-'}
                  </span>
                </span>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="flex flex-col md:flex-row  justify-center md:justify-between  w-1/2 md:w-[30%] xl:w-1/4  ">
        <div
          className="w-full h-full flex flex-col justify-center py-4  md:py-0 pl-4 "
          data-testid="total-approved"
        >
          <p className="text-xs  font-light">Total Approved</p>
          <p className="text-gray-900 ">
            <span className="text-3xl font-semibold">
              {adjustmentWiseHours.totalApprovedHours !== null
                ? formatHours(
                    adjustmentWiseHours.totalApprovedHours,
                    isAdjustmentTimecard
                  )
                : '-'}
            </span>
            <span className="text-base">Hrs</span>
          </p>
        </div>
        {isBrsPayroll && (
          <div
            className="md:w-full h-full flex flex-col justify-center py-4  md:py-0 md:pl-4 ml-4 md:ml-0 border-t md:border-t-0 md:border-l-4  border-gray-300"
            data-testid="holiday-pay"
          >
            <p className="text-xs font-light">Holiday Pay</p>
            <p className="text-gray-900">
              <span className="text-3xl font-semibold">
                {formatHours(
                  adjustmentWiseHours.holidayPayHours,
                  isAdjustmentTimecard
                )}
              </span>
              <span className="text-base">Hrs</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PolicySummaryCardHeader;
