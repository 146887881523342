import { NumberField } from '@uss/react-core';
import { useAddWeekStore } from 'features/model-listing/state/add-week-store';
import { ModelSchedTurns } from 'features/model-listing/types';
import { useEffect, useState } from 'react';
interface ScheduleTurnInputProps {
  schedTurn?: ModelSchedTurns;
  weekNo: number;
  crewRotation: string;
}
export const AddWeekCard = (data: ScheduleTurnInputProps) => {
  const store = useAddWeekStore();

  const [value, setValue] = useState<number | undefined>(
    data.schedTurn?.turnValue
  );

  useEffect(() => {
    setValue(data.schedTurn?.turnValue);
  }, [data.schedTurn?.turnValue]);

  const handleChange = (e: number) => {
    setValue(e);
    const updateSchedTurn = store.addWeek && {
      weekNo: store.addWeek.weekNo,
      crewRotations: store.addWeek.crewRotations.map((crew) =>
        crew.crewRotation !== data.crewRotation
          ? crew
          : {
              crewRotation: crew.crewRotation,
              pkCrewRotationId: crew.pkCrewRotationId,
              schedTurns: crew.schedTurns.map((turn) =>
                turn.dayIndex !== data.schedTurn?.dayIndex
                  ? turn
                  : {
                      ...turn,
                      turnValue: Number.isNaN(e) ? 0 : e,
                    }
              ),
            }
      ),
    };
    store.UpdateAddWeek(updateSchedTurn ? updateSchedTurn : store.addWeek);
  };
  return (
    <>
      <NumberField
        id={data.schedTurn?.pkScheduleTurnId}
        aria-label={value?.toString()}
        value={value === 0 ? NaN : value}
        onChange={(e) => handleChange(e)}
        className="[&_input]:p-0 [&_input]:flex [&_input]:text-center"
      />
    </>
  );
};
