import React from 'react';
import { useMyPtoStore } from 'features/paid-time-off/state/mypto-store';
import { PtoFilters } from 'features/paid-time-off/components/Filters/PtoFilters';

export function MyPtoFilters() {
  const store = useMyPtoStore();
  return (
    <PtoFilters
      calendarType={store.calendarType}
      startDateValue={store.startDate()}
      status={store.params.status}
      sortBy={store.params.sortBy}
      sortType={store.params.sortType}
      onSelectDate={store.onSelectDate}
      toggleYearMonth={store.toggleYearMonth}
      setStatus={store.setStatus}
      setPtoDetailsView={store.setPtoDetailsView}
      setYear={store.setYear}
      setSort={store.setSort}
    />
  );
}
