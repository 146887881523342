import NoResults from 'components/NoResults/NoResults';
import { CalendarBlock } from '../types';
import MonthCard from '../Card/MonthCard';

interface YearViewProps {
  blocks: CalendarBlock[];
  renderItem: (item: CalendarBlock) => React.ReactNode;
  handleClick?: (date: Date) => void;
}

export const YearView = ({
  blocks,
  renderItem,
  handleClick,
}: YearViewProps) => {
  return (
    <>
      {blocks.length ? (
        <div className="grid gap-2 grid-cols-3 2xl:grid-cols-4">
          {blocks.map((item, _index) => (
            <MonthCard
              key={_index}
              calendardata={item}
              {...(handleClick ? { handleClick: handleClick } : {})}
              as={handleClick ? 'button' : 'div'}
            >
              <>{renderItem(item)}</>
            </MonthCard>
          ))}
        </div>
      ) : (
        <NoResults
          title="Year Calendar"
          description="No records available"
          width="300"
          height="300"
        />
      )}
    </>
  );
};
