import { useEffect, useState } from 'react';
import { TextArea, Modal, Button } from '@uss/react-components';
import { ZodErrorType } from 'types/common';
import _ from 'lodash';
import useValidateForm from 'hooks/useValidateForm';
import { PaycodeStatus } from './types';
import {
  PaycodeArchiveBodySchema,
  useUpdatePaycodeStatus,
} from './api/updatePaycodeStatus';
import { PAYCODE_STATUS } from './constants';

interface ToggleArchiveModalProps {
  paycodeId: string;
  code: PaycodeStatus;
  action: string;
  handleUpdate: () => Promise<void>;
  closeModal: () => void;
}

const ArchiveRestoreModal = ({
  paycodeId,
  code,
  action,
  handleUpdate,
  closeModal,
}: ToggleArchiveModalProps) => {
  const [comments, setComments] = useState<string>('');
  const [errors, setErrors] = useState<ZodErrorType>({});
  const [enableValidator, setEnableValidator] = useState<boolean>(false);
  const [divisionData, setDivisionData] = useState({
    status: {
      code: code,
    },
    comments: comments,
  });
  const { mutate } = useUpdatePaycodeStatus(paycodeId, code);
  const { trimValues, validateForm } = useValidateForm(
    PaycodeArchiveBodySchema,
    setErrors
  );

  useEffect(() => {
    const updatedArchiveData = {
      status: {
        code: code,
      },
      comments: comments,
    };
    setDivisionData(updatedArchiveData);
    if (enableValidator) {
      validateForm(trimValues(updatedArchiveData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, comments, enableValidator]);

  const handleCommentschange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEnableValidator(true);
    setComments(e.target.value);
  };

  const handleSubmit = () => {
    divisionData.status.code =
      code !== PAYCODE_STATUS.ARCHIVED
        ? PAYCODE_STATUS.ARCHIVED
        : PAYCODE_STATUS.ACTIVE;
    setEnableValidator(true);
    if (validateForm(trimValues(divisionData))) {
      closeModal();
      mutate(divisionData);
    }
  };

  const handleApprove = async () => {
    if (code === PAYCODE_STATUS.DENIED) {
      divisionData.status.code = PAYCODE_STATUS.ACTIVE;
      setEnableValidator(true);
      if (validateForm(trimValues(divisionData))) {
        closeModal();
        mutate(divisionData);
      }
    } else {
      // on click of approve from pending paycode status, comment is not input from modal
      await handleUpdate();
    }
  };

  const getModalHeaderText = (code: PaycodeStatus, action: string): string => {
    if (action === 'archive' || action === 'restore') {
      if (code === PAYCODE_STATUS.ACTIVE) {
        return `Why do you want to archive this pay code?`;
      } else {
        return `Why do you want to restore this pay code?`;
      }
    } else {
      if (code === PAYCODE_STATUS.DENIED) {
        return `Why do you want to approve this pay code?`;
      } else {
        return `Are you sure you want to approve this pay code?`;
      }
    }
  };

  return (
    <>
      <div>
        <p className="text-sm text-gray-700" data-testid="org-modal-body">
          {getModalHeaderText(code, action)}
        </p>
      </div>
      {code !== PAYCODE_STATUS.PENDING && (
        <div className="col-span-2 mb-4 lg:mb-0">
          <TextArea
            maxLength={500}
            label=""
            id="form-comments"
            placeholder="Describe reason"
            onChange={handleCommentschange}
            error={
              !_.isEmpty(errors) &&
              typeof errors.comments === 'string' &&
              errors.comments.length > 0
            }
            errorMessage={errors.comments}
            data-testid="modal-description"
          />
          <div
            className="col-span-2 text-xs my-2 text-gray-600 text-right"
            data-testid="description-characters-left"
          >
            {500 - comments.length} Characters left
          </div>
        </div>
      )}
      <Modal.Actions>
        <Button
          onClick={closeModal}
          variant="outline"
          className="w-full sm:w-auto"
          data-testid="modal-cancel"
        >
          Cancel
        </Button>
        {action === 'archive' || action === 'restore' ? (
          <Button
            onClick={handleSubmit}
            variant="primary"
            className="ml-0 sm:ml-4 w-full sm:w-auto mt-3 sm:mt-0"
            data-testid="modal-confirm"
          >
            {code === PAYCODE_STATUS.ACTIVE ? 'Archive' : 'Restore'}
          </Button>
        ) : (
          <Button
            onClick={handleApprove}
            variant="primary"
            className="ml-0 sm:ml-4 w-full sm:w-auto mt-3 sm:mt-0"
            data-testid="modal-confirm"
          >
            Approve
          </Button>
        )}
      </Modal.Actions>
    </>
  );
};
export default ArchiveRestoreModal;
