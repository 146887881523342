import { RadioToggleIcon } from '@uss/react-components';
import { CalendarView } from 'components/CalendarV2/types';
import React from 'react';

interface YearMonthToggleParams {
  calendarType: CalendarView;
  toggleYearMonth: (value: 'year' | 'month') => void;
}

const YearMonthToggle = ({
  calendarType,
  toggleYearMonth,
}: YearMonthToggleParams) => {
  const onClickIcon = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const setValue: 'option1' | 'option2' =
      e.currentTarget.name === 'option1' ? 'option1' : 'option2';
    toggleYearMonth(setValue === 'option1' ? 'year' : 'month');
  };
  return (
    <div className="flex h-8">
      <RadioToggleIcon
        option1Title="Year"
        option2Title="Month"
        onClick={onClickIcon}
        Option1={
          <span
            data-testid="pto-year-option"
            className={`${
              calendarType === 'year' ? ' text-white' : ' text-primary-400'
            } w-10 sm:w-20   text-base  flex items-center justify-center`}
          >
            Year
          </span>
        }
        Option2={
          <span
            data-testid="pto-month-option"
            className={`${
              calendarType === 'month' ? ' text-white' : ' text-primary-400'
            } sm:w-20 w-10 text-base flex items-center justify-center`}
          >
            Month
          </span>
        }
        optionSelected={calendarType === 'year' ? 'option1' : 'option2'}
      />
    </div>
  );
};

export default YearMonthToggle;
