import { useEffect, useState } from 'react';
import { TextArea, Modal, Button } from '@uss/react-components';
import { ZodErrorType } from 'types/common';
import _ from 'lodash';

import useValidateForm from 'hooks/useValidateForm';
import { OrgPatchStatus, ORG_TYPE } from 'features/org';
import { useUpdateOrgStatus } from '../api';
import { orgPatchSchema } from '../schemas';

interface ToggleArchiveModalProps {
  id: string;
  code: OrgPatchStatus;
  orgType: typeof ORG_TYPE.DIVISION | typeof ORG_TYPE.DEPARTMENT;
  closeModal: () => void;
}

const ArchiveRestoreModal = ({
  orgType,
  id,
  code,
  closeModal,
}: ToggleArchiveModalProps) => {
  const [comments, setComments] = useState<string>('');
  const [errors, setErrors] = useState<ZodErrorType>({});
  const [enableValidator, setEnableValidator] = useState<boolean>(false);
  const [divisionData, setDivisionData] = useState({
    status: {
      code: code,
    },
    comments: comments,
  });
  const { mutate } = useUpdateOrgStatus(id, code, orgType);
  const { trimValues, validateForm } = useValidateForm(
    orgPatchSchema,
    setErrors
  );
  useEffect(() => {
    const updatedArchiveData = {
      status: {
        code: code,
      },
      comments: comments,
    };
    setDivisionData(updatedArchiveData);
    if (enableValidator) {
      validateForm(trimValues(updatedArchiveData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, comments, enableValidator]);
  const handleCommentschange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEnableValidator(true);
    setComments(e.target.value);
  };
  const handleSubmit = () => {
    divisionData.status.code = code !== 'archived' ? 'archived' : 'active';
    setEnableValidator(true);
    if (validateForm(trimValues(divisionData))) {
      closeModal();
      mutate(divisionData);
    }
  };
  return (
    <>
      <div>
        <p className="text-sm text-gray-700" data-testid="org-modal-body">
          {code === 'active'
            ? `Why do you want to archive this ${orgType.toLowerCase()} ?`
            : `Why do you want to restore this ${orgType.toLowerCase()} ?`}
        </p>
      </div>
      <div className="col-span-2 mb-4 lg:mb-0">
        <TextArea
          maxLength={500}
          label=""
          id="form-comments"
          placeholder="Describe reason"
          onChange={handleCommentschange}
          error={
            !_.isEmpty(errors) &&
            typeof errors.comments === 'string' &&
            errors.comments.length > 0
          }
          errorMessage={errors.comments}
          data-testid="modal-description"
        />
        <div
          className="col-span-2 text-xs my-2 text-gray-600 text-right"
          data-testid="description-characters-left"
        >
          {500 - comments.length} Characters left
        </div>
      </div>
      <Modal.Actions>
        <Button
          onClick={closeModal}
          variant="outline"
          className="w-full sm:w-auto"
          data-testid="modal-cancel"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="primary"
          className="ml-0 sm:ml-4 w-full sm:w-auto mt-3 sm:mt-0"
          data-testid="modal-confirm"
        >
          {code === 'active' ? 'Archive' : 'Restore'}
        </Button>
      </Modal.Actions>
    </>
  );
};
export default ArchiveRestoreModal;
