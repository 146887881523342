import { Select } from '@uss/react-components';
import CardList from 'components/Card/CardList';
import Page from 'components/Layouts/Page';
import CardsLoader from 'components/Loaders/CardsLoader';
import Pagination from 'components/Pagination/Pagination';
import Error from 'components/Error/Error';
import {
  Org,
  useDivisionPageParamsDispatch,
  useDivisionPageParams,
  ORG_TYPE,
} from 'features/org';
import { useAllLocations, useOrgChildren } from 'features/org/api';
import { OrgCard, OrgTools, SortOptionsType } from 'features/org/components';
import { usePersonDetails } from 'features/people/api';
import { ROLES } from 'features/roles';
import useUserRoles from 'hooks/useUserRoles';
import { useEffect, useState } from 'react';
import { SortDirection } from 'types/common';
import { NavLink } from 'react-router-dom';

const Divisions = () => {
  const params = useDivisionPageParams();
  const dispatch = useDivisionPageParamsDispatch();

  const { data: locations, status: locationStatus } = useAllLocations('me');
  const { data: loggedInUser } = usePersonDetails('me');
  const { roles } = useUserRoles();

  const getUsersDefaultLocation = () => {
    let defaultLocation = null;
    if (locations && params.parentOrgId) {
      const location = locations.items.filter(
        (value) => value.id === params.parentOrgId
      )[0];
      defaultLocation = location;
    } else {
      if (
        roles.includes(ROLES.SUPER_ADMIN) ||
        roles.includes(ROLES.SUPER_PAYROLL_ADMIN)
      ) {
        defaultLocation = locations ? locations.items[0] : null;
      } else if (
        (roles.includes(ROLES.T_A_PLANT_COORDINATOR) ||
          roles.includes(ROLES.PAYROLL_ADMIN)) &&
        loggedInUser &&
        locations
      ) {
        const location = locations.items.filter(
          (value) => loggedInUser.payrollLocation === value.location
        )[0];
        defaultLocation = location;
      }
    }

    return { defaultLocation };
  };

  const { defaultLocation } = getUsersDefaultLocation();
  const [selectedLocation, setSelectedLocation] = useState<Org | null>(
    defaultLocation ? defaultLocation : null
  );
  const { data: divisions, status: divisionStatus } = useOrgChildren('me', {
    type: ORG_TYPE.DIVISION,
    parentOrgId: defaultLocation ? defaultLocation.id : null,
    ...params,
  });

  const noResults = {
    title: 'No Records Found',
    description:
      'No division records found, Please modify the search criteria to view the records',
  };
  useEffect(() => {
    setSelectedLocation(defaultLocation);
  }, [defaultLocation]);

  const handleLocationChange = (value: Org) => {
    setSelectedLocation(value);
    dispatch({ type: 'parentOrgId', payload: value.id });
  };

  const sortOptions: SortOptionsType[] = [
    {
      label: 'Recently Added',
      name: 'recently-added',
      direction: SortDirection.DESC,
    },
    {
      label: 'Code',
      name: 'code',
      direction: SortDirection.ASC,
    },
    {
      label: 'Code',
      name: 'code',
      direction: SortDirection.DESC,
    },
    {
      label: 'Description',
      name: 'description',
      direction: SortDirection.ASC,
    },
    {
      label: 'Description',
      name: 'description',
      direction: SortDirection.DESC,
    },
  ];

  return (
    <Page
      heading="Org Maintenance - Divisions"
      breadcrumbs={[
        <NavLink key="bc-1" to="/admin">
          Admin
        </NavLink>,
      ]}
    >
      <Page.Section bg="gray">
        {locationStatus === 'success' && (
          <div
            className="mb-6 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4"
            data-testid="location-filter"
          >
            <Select
              selected={selectedLocation}
              onSelect={handleLocationChange}
              className="w-full"
            >
              <Select.Button
                label={
                  selectedLocation?.locationDescription
                    ? `${selectedLocation.location ?? ''} - ${
                        selectedLocation.locationDescription
                      }`
                    : 'Select'
                }
              />

              <Select.Options>
                {locations.items.length ? (
                  locations.items.map((item: Org, index: number) => (
                    <Select.Option truncate key={index} value={item}>
                      <span>{`${item.location ?? ''}-${
                        item.locationDescription ?? ''
                      }`}</span>
                    </Select.Option>
                  ))
                ) : (
                  <span>No location available</span>
                )}
              </Select.Options>
            </Select>
          </div>
        )}
        <OrgTools
          params={params}
          dispatch={dispatch}
          parentOrgId={selectedLocation?.id}
          type={ORG_TYPE.DIVISION}
          sortOptions={sortOptions}
        />
      </Page.Section>
      <Page.Section>
        {divisionStatus === 'pending' && <CardsLoader count={9} />}
        {divisionStatus === 'success' && (
          <CardList<Org>
            items={divisions.items}
            renderItem={(org) => <OrgCard type={ORG_TYPE.DIVISION} org={org} />}
            noResults={noResults}
          />
        )}
        {divisionStatus === 'error' && <Error />}
      </Page.Section>
      <Page.Section bg="gray">
        {!!divisions?.totalCount && (
          <Pagination
            pageOptions={[9, 18, 27, 36, 45, 54]}
            totalCount={divisions.totalCount}
            page={divisions.page}
            pageSize={divisions.pageSize}
            onPageChange={(s) =>
              dispatch({ type: 'page', payload: s.selected + 1 })
            }
            onPageSizeChange={(ps) =>
              dispatch({ type: 'pageSize', payload: ps })
            }
          />
        )}
      </Page.Section>
    </Page>
  );
};

export default Divisions;
