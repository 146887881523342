import React from 'react';
import { createRoot } from 'react-dom/client';
import '@uss/react-components/lib/index.css';
import '@uss/react-core/dist/style.css';
import './index.css';
import App from './App';
import { MsalProvider } from '@azure/msal-react';
// import { worker } from './mocks/browser';
import { msalInit, msalInstance } from 'msal-init';

//MSAL Setup for Login
msalInit();

//API Mocking Service Worker Initialization -- COMMENT OUT TO TURN OFF MOCKING.

// if (process.env.NODE_ENV === 'development') {
//   if (window.location.pathname === '/wms/app') {
//     window.location.pathname = '/wms/app/';
//   }
//   void worker.start({
//     serviceWorker: {
//       url: '/wms/app/mockServiceWorker.js',
//     },
//   });
// }

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
// render app to root
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);
