import Page from 'components/Layouts/Page';
import { ErrorBoundary } from 'react-error-boundary';
import Error from 'components/Error/Error';
import { NavLink, useParams } from 'react-router-dom';
import EmployeeDetailsSummary from 'features/probationary-tracking/components/common/EmployeeDetailsSummary/EmployeeDetailsSummary';
import { usePerformanceReviewDetailsSummary } from 'features/probationary-tracking/api/getPerfReviewDetailsSummary';
import EmployeeDetailsSummaryLoader from 'features/probationary-tracking/components/common/EmployeeDetailsSummary/EmployeeDetailsSummaryLoader';
import { ReviewPerformanceCards } from 'features/probationary-tracking/components/common/ReviewPerformanceCards/ReviewPerformanceCards';
import CardsLoader from 'components/Loaders/CardsLoader';
import AssignReassignReviewerDrawer from 'features/probationary-tracking/components/common/AssignReassignReviewerDrawer/AssignReassignReviewerDrawer';
import { useState } from 'react';
import getFormattedEmployeeName from 'features/probationary-tracking/utilities/getFormattedEmployeeName';
import { MdEdit } from 'react-icons/md';
import { Button } from '@uss/react-components';
import { useReassignReviewer } from 'features/probationary-tracking/api/updateReviewer';
import { Person } from 'features/people/types';

const AreaManagerEmpDetails = () => {
  return (
    <Page
      heading={'Employee Details'}
      breadcrumbs={[
        <NavLink
          to="/probationary-tracking/area-manager-view/employees"
          key="bc-1"
        >
          Probationary Employees
        </NavLink>,
      ]}
    >
      <ErrorBoundary
        fallbackRender={({ error }) => {
          return (
            <Error
              width="300"
              height="300"
              title="Something went wrong!"
              description={error.message}
            />
          );
        }}
      >
        <Content />
      </ErrorBoundary>
    </Page>
  );
};
const Content = () => {
  const { ussId = '' } = useParams<'ussId'>();
  const { data, status } = usePerformanceReviewDetailsSummary(ussId);
  const { mutateAsync: reassignReviewer, isPending: isUpdatingReviewer } =
    useReassignReviewer(ussId);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleReassignReviewer = async (selectedReviewer: Person | null) => {
    if (selectedReviewer) {
      const payload = { reviewerUssId: selectedReviewer.ussId.toString() };
      await reassignReviewer(payload);
    }
  };
  return (
    <>
      <Page.Section>
        <div className="flex justify-end mb-4">
          <Button
            variant="text"
            className="text-sm text-primary-400 "
            startIcon={<MdEdit size={'0.75em'} />}
            onClick={() => setIsOpen(true)}
          >
            Reassign Reviewer
          </Button>

          <AssignReassignReviewerDrawer
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectionText={`${getFormattedEmployeeName(data)} is selected`}
            handleUpdateReviewer={handleReassignReviewer}
            title="Re-Assign Reviewer"
            actionTitle="Re-assign"
            isUpdating={isUpdatingReviewer}
            supervisorId={data?.supervisorDetails.ussId}
          />
        </div>
        <div data-testid="employee-details-summary-card">
          {status === 'pending' && <EmployeeDetailsSummaryLoader />}
          {status === 'success' && <EmployeeDetailsSummary data={data} />}
        </div>
      </Page.Section>

      <Page.Section>
        {status === 'pending' && <CardsLoader count={3} />}
        {status === 'error' && <Error />}
        {status === 'success' && (
          <ReviewPerformanceCards reviewDetails={data.reviewDetails} />
        )}
      </Page.Section>
    </>
  );
};
export default AreaManagerEmpDetails;
