import { EmployeeDetail, ReviewerDetail } from '../types';

function getFormattedEmployeeName(data?: EmployeeDetail | ReviewerDetail) {
  if (data?.displayName) {
    return data.displayName;
  } else if (data?.firstName && data.lastName) {
    const lastName = data.lastName;
    const middleName = data.middleName ? ` ${data.middleName}` : '';
    const firstName = data.firstName;
    return `${lastName}, ${firstName}${middleName}`;
  } else {
    return '-';
  }
}
export default getFormattedEmployeeName;
