import { StateCreator } from 'zustand';
import { ProbationaryEmployeesStore } from '../store';
import { ReviewDueSlice, ReviewDueTabSlice } from './review-due';
import { ReEvaluateSlice, ReEvaluateTabSlice } from './re-evaluate';
import { CounselingSlice, CounselingTabSlice } from './counseling';
import { FinalReviewSlice, FinalReviewTabSlice } from './final-review';
import { ReviewerTabs } from 'features/probationary-tracking/types';
import { AssignedSlice, AssignedTabSlice } from './assigned';

interface TabSpecificState {
  assigned: AssignedSlice;
  reviewDue: ReviewDueSlice;
  reEvaluate: ReEvaluateSlice;
  counseling: CounselingSlice;
  finalReview: FinalReviewSlice;
}
export interface ReviewerSliceType extends TabSpecificState {
  currentTab?: ReviewerTabs;
  setCurrentTab: (value: ReviewerTabs) => void;
}

export const ReviewerSlice: StateCreator<
  ProbationaryEmployeesStore,
  [],
  [],
  ReviewerSliceType
> = (...args) => {
  const [set] = args;
  return {
    setCurrentTab: (value) =>
      set(({ reviewer }) => ({
        reviewer: { ...reviewer, currentTab: value },
      })),
    assigned: { ...AssignedTabSlice(...args) },
    reviewDue: { ...ReviewDueTabSlice(...args) },
    reEvaluate: { ...ReEvaluateTabSlice(...args) },
    counseling: { ...CounselingTabSlice(...args) },
    finalReview: { ...FinalReviewTabSlice(...args) },
  };
};
