import { Badge, Button } from '@uss/react-core';
import { useModelListFiltersStore } from 'features/model-listing/state/model-list-store';
import { getModelStatusText } from 'features/model-listing/utility/getModelStatusText';
import { useEffect, useState } from 'react';

interface ModelContainerProps {
  setCount: (value: number) => void;
}

export const FilterBadges = ({ setCount }: ModelContainerProps) => {
  //store
  const store = useModelListFiltersStore();
  const [filtersCount, setFiltersCount] = useState(0);

  useEffect(() => {
    let total = 0;
    if (store.selectedPlant !== null) total++;
    if (store.selectedPayrollLocation !== null) total++;
    if (store.selectedModelOwner !== null) total++;
    if (store.selectedStatus !== null) total++;
    setFiltersCount(total);
    setCount(total);
    //If filter is not applied then setting this flag false
    if (total === 0) {
      store.setIsFilterApplied(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  return (
    <div className="my-2">
      {filtersCount > 0 && (
        <div className="flex flex-row items-center gap-3">
          <span className="text-sm text-gray-700">
            Filtered by ({filtersCount}):{' '}
          </span>
          <Button variant="text" onPressChange={() => store.resetFilters()}>
            <span className="text-primary-400">Clear All</span>
          </Button>
        </div>
      )}
      <div className="mb-4 mt-1">
        {store.params.plant && (
          <Badge
            color={'primary'}
            label={store.selectedPlant?.plant ?? ''}
            isInteractive
            closeEvent={() => store.clearPlant()}
          />
        )}
        {store.params.payrollLocation && (
          <Badge
            color={'primary'}
            label={store.selectedPayrollLocation?.description ?? ''}
            isInteractive
            closeEvent={() => store.clearPayrollLocation()}
          />
        )}
        {store.params.modelOwner && (
          <Badge
            color={'primary'}
            label={store.selectedModelOwner?.displayName ?? ''}
            isInteractive
            closeEvent={() => store.clearModelOwner()}
          />
        )}
        {store.params.modelStatus && (
          <Badge
            color={'primary'}
            label={getModelStatusText(store.params.modelStatus)}
            isInteractive
            closeEvent={() => store.clearStatus()}
          />
        )}
      </div>
    </div>
  );
};
