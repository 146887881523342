import { StatusType } from 'features/org';

export const getOrgStatusColor = (value: StatusType) => {
  switch (value) {
    case 'active': {
      return 'green';
    }
    case 'archived': {
      return 'gray';
    }
    case 'pending': {
      return 'purple';
    }
    case 'denied': {
      return 'red';
    }
    default: {
      return 'green';
    }
  }
};
