import { PLANT_TYPE, PaycodeList } from 'features/paycode';
import { Person, SHIFT_ASSIGNMENT } from 'features/people';

export const getPaycodesByEmpClass = (
  timeCardPaycode: PaycodeList | undefined,
  employee: Person
) => {
  //For Integrated / Office (Plant Type) & Exempt (Emp Class 4) -12HRS
  // filterning out 'UF' paycode
  if (timeCardPaycode && timeCardPaycode.items.length > 0) {
    if (
      (employee.plantType === PLANT_TYPE.INTEGRATED_EXEMPT ||
        employee.plantType === PLANT_TYPE.OFFICE) &&
      employee.empClass === '4' &&
      employee.shiftAssignment === SHIFT_ASSIGNMENT.TWELVE_HOUR
    ) {
      const filteredPaycodeItemArr = timeCardPaycode.items.filter(
        (pc) => pc.code !== 'UF'
      );

      timeCardPaycode.items = filteredPaycodeItemArr;
      return timeCardPaycode;
    }

    return timeCardPaycode;
  }
};
