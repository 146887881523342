import { Input } from '@uss/react-components';
import { useEffect, useState } from 'react';
import { HourTypes } from 'features/timecards/types';

interface HoursInputProps {
  index: number;
  label: string;
  futureDay?: boolean;
  editable?: boolean;
  error: boolean;
  initialValue: string | null;
  hourType: HourTypes;
  updateInput: (target: string, columnName: HourTypes) => void;
}

const HoursInput = ({
  index,
  label,
  futureDay = false,
  editable = false,
  error,
  hourType,
  initialValue,
  updateInput,
}: HoursInputProps) => {
  const [value, setValue] = useState(String(initialValue));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const num = Number(event.target.value);
    if (Number.isNaN(num)) {
      return;
    } else {
      setValue(() => event.target.value.trim());
      updateInput(event.target.value.trim(), hourType);
    }
  };

  const disabled = (hourType: HourTypes) => {
    if (hourType === 'scheduledHours') {
      return false;
    } else if (futureDay) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setValue(String(initialValue));
  }, [initialValue]);

  if (editable) {
    return (
      <Input
        id={`${hourType}-${index}`}
        key={`${hourType}-${index}`}
        label={'Hours'}
        title={label}
        type="text"
        inputMode="decimal"
        placeholder=""
        hideLabel
        disabled={disabled(hourType)}
        error={error}
        onChange={(event) => handleChange(event)}
        value={value}
        onKeyDown={(e) =>
          ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
        }
      />
    );
  } else {
    return (
      <span data-testid="input-read-only" title={label}>
        {value || '-'}
      </span>
    );
  }
};

export default HoursInput;
