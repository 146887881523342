import Page from 'components/Layouts/Page';
import Tabs, { TabLinkType } from 'components/Tabs/Tabs';
import ProbationaryEmployeesSummary from 'features/probationary-tracking/components/area-manager/Summary';
import Assigned from 'features/probationary-tracking/components/area-manager/tabs/Assigned';
import ReviewPerformance from 'features/probationary-tracking/components/area-manager/tabs/Review_Performance';
import Termination from 'features/probationary-tracking/components/area-manager/tabs/Termination';
import Unassigned from 'features/probationary-tracking/components/area-manager/tabs/Unassigned';
import { AreaManagerTabs } from 'features/probationary-tracking/types';
import { ErrorBoundary } from 'react-error-boundary';
import Error from 'components/Error/Error';
import { useProbationaryEmployeesStore } from 'features/probationary-tracking/state/store';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const AreaManagerView = () => {
  return (
    <Page heading={'Probationary Employees'}>
      <ErrorBoundary
        fallbackRender={({ error }) => {
          return (
            <Error
              width="300"
              height="300"
              title="Something went wrong!"
              description={error.message}
            />
          );
        }}
      >
        <Content />
      </ErrorBoundary>
    </Page>
  );
};
const Content = () => {
  // Tabs
  const { areaManager } = useProbationaryEmployeesStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const setCurrentTab = areaManager.setCurrentTab;
  const currentTab = areaManager.currentTab;

  const handleTabClick = (label: AreaManagerTabs) => {
    //Setting current tab selection to ref on tab click
    setCurrentTab(label);
  };

  useEffect(() => {
    if (currentTab) {
      const updatedSearchParams = new URLSearchParams(searchParams.toString());
      updatedSearchParams.set('tab', currentTab);
      setSearchParams(updatedSearchParams.toString());
    } else {
      setCurrentTab(AreaManagerTabs.UNASSIGNED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabLinks: TabLinkType[] = [
    {
      label: AreaManagerTabs.UNASSIGNED,
      element: <Unassigned />,
      customOnChange: () => handleTabClick(AreaManagerTabs.UNASSIGNED),
    },
    {
      label: AreaManagerTabs.ASSIGNED,
      element: <Assigned />,
      customOnChange: () => handleTabClick(AreaManagerTabs.ASSIGNED),
    },
    {
      label: AreaManagerTabs.REVIEW_PERFORMANCE,
      element: <ReviewPerformance />,
      customOnChange: () => handleTabClick(AreaManagerTabs.REVIEW_PERFORMANCE),
    },
    {
      label: AreaManagerTabs.TERMINATION,
      element: <Termination />,
      customOnChange: () => handleTabClick(AreaManagerTabs.TERMINATION),
    },
  ];
  return (
    <>
      <Page.Section>
        <ProbationaryEmployeesSummary />
      </Page.Section>
      <Page.Section>
        <Tabs tabLinks={tabLinks} />
      </Page.Section>
    </>
  );
};
export default AreaManagerView;
