import { Org } from 'features/org';
import { ROLES } from 'features/roles';

export interface DisplayButton {
  crew?: Org | null;
  roles: string[];
}

const displayButton = ({ crew, roles }: DisplayButton) => {
  const buttonState = {
    approveButton: false,
    archiveButton: false,
    cancelButton: false,
    denyButton: false,
    restoreButton: false,
    saveButton: false,
  };
  if (
    roles.includes(ROLES.SUPER_ADMIN) ||
    roles.includes(ROLES.SUPER_PAYROLL_ADMIN) ||
    roles.includes(ROLES.PAYROLL_ADMIN) ||
    roles.includes(ROLES.T_A_PLANT_COORDINATOR)
  ) {
    if (crew?.status.code === 'active') {
      buttonState.cancelButton = true;
      buttonState.archiveButton = true;
      buttonState.saveButton = true;
      return buttonState;
    } else if (crew?.status.code === 'archived') {
      buttonState.restoreButton = true;
      return buttonState;
    } else if (
      crew?.status.code === 'rejected' ||
      crew?.status.code === 'denied'
    ) {
      buttonState.approveButton = true;
      return buttonState;
    } else if (crew?.status.code === 'pending') {
      buttonState.approveButton = true;
      buttonState.cancelButton = true;
      buttonState.denyButton = true;
      return buttonState;
    }
  } else if (roles.includes(ROLES.PAY_APPROVER)) {
    if (crew?.status.code === 'active' || crew?.status.code === 'pending') {
      buttonState.cancelButton = true;
      buttonState.saveButton = true;
      return buttonState;
    } else {
      return buttonState;
    }
  }
  return buttonState;
};
export default displayButton;
