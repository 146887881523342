import {
  Accordion,
  Button,
  Drawer,
  RadioGroup,
  Select,
} from '@uss/react-components';
import { SortBy } from 'features/paycode/api';
import { PAYCODE_STATUS, PAYCODE_TYPE } from 'features/paycode/constants';
import { usePaycodeListStore } from 'features/paycode/state/store';
import {
  ApplicableEmpType,
  PaycodeStatus,
  PaycodeType,
  SortByEnum,
} from 'features/paycode/types';
import getPaycodeStatusText from 'features/paycode/utilities/getPaycodeStatusText';
import getPaycodeTypeText from 'features/paycode/utilities/getPaycodeTypeText';
import { useState } from 'react';
import { BiFilterAlt } from 'react-icons/bi';
import {
  BsArrowDown,
  BsArrowUp,
  BsSortDown,
  BsSortUpAlt,
} from 'react-icons/bs';
import { SortDirection } from 'types/common';

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
}

interface MobileFilterSort {
  empType: ApplicableEmpType | 'all';
  type: PaycodeType | 'all' | null;
  status: PaycodeStatus | 'all' | null;
  sort: {
    type:
      | 'type'
      | 'status'
      | 'code'
      | 'description'
      | 'recently-added'
      | 'recently-archived'
      | 'recently-denied';
    direction: SortDirection;
  };
}
export const MobileFilter = ({ open, setOpen }: Props) => {
  const store = usePaycodeListStore();

  const defaultState: MobileFilterSort = {
    empType: store.params.applicableEmpType ?? 'all',
    type: store.params.type ?? null,
    status: store.params.status ?? null,
    sort: {
      type: store.params.sortBy ?? 'recently-added',
      direction: store.params.sortType ?? SortDirection.ASC,
    },
  };
  const [filterState, setFilterState] =
    useState<MobileFilterSort>(defaultState);

  const handleClearAll = () => {
    setFilterState({
      empType: 'all',
      type: null,
      status: null,
      sort: {
        type: 'recently-added',
        direction: SortDirection.ASC,
      },
    });
  };

  const sortFilters = [
    {
      label: 'Code',
      name: 'code',
      direction: SortDirection.ASC,
    },
    {
      label: 'Code',
      name: 'code',
      direction: SortDirection.DESC,
    },
    {
      label: 'Description',
      name: 'description',
      direction: SortDirection.ASC,
    },
    {
      label: 'Description',
      name: 'description',
      direction: SortDirection.DESC,
    },
    {
      label: 'Type',
      name: 'type',
      direction: SortDirection.ASC,
    },
    {
      label: 'Type',
      name: 'type',
      direction: SortDirection.DESC,
    },
    {
      label: 'Status',
      name: 'status',
      direction: SortDirection.ASC,
    },
    {
      label: 'Status',
      name: 'status',
      direction: SortDirection.DESC,
    },
    {
      label: 'Recently Added',
      name: 'recently-added',
      direction: SortDirection.DESC,
    },
  ];

  const handleEmployeeType = (value: ApplicableEmpType | 'all') => {
    setFilterState({ ...filterState, empType: value });
  };

  const handleTypeChange = (value: PaycodeType | 'all') => {
    setFilterState({ ...filterState, type: value });
  };

  const handleStatusChange = (value: PaycodeStatus | 'all') => {
    setFilterState({ ...filterState, status: value });
  };

  const handleSortFilters = (value: {
    type: SortBy;
    direction: SortDirection;
  }) => {
    setFilterState({ ...filterState, sort: value });
  };
  const handleApplyFilter = () => {
    store.setApplicableEmpType(filterState.empType);
    store.setType(filterState.type ?? 'all');
    store.setStatus(filterState.status ?? 'all');
    store.setSort(filterState.sort);
    setOpen(false);
  };

  const typeFilterList = Object.values(PAYCODE_TYPE);
  const statusFilterList = Object.values(PAYCODE_STATUS);

  return (
    <Drawer
      placement={'bottom'}
      title="Filter & Sorts"
      isOpen={open}
      onClose={(): void => {
        setFilterState(defaultState);
        setOpen(false);
      }}
      closeButton={true}
      className="w-full sm:w-1/2 md:w-1/3 xl:w-1/4 2xl:w-1/5 md:top-0 top-18 bg-gray-100"
    >
      <Accordion
        variant="default"
        className="shadow-md bg-white rounded-lg border mb-4"
        isOpen={true}
      >
        <Accordion.Button variant="default">
          <div className="flex flex-col ">
            <span className="flex gap-2  ">
              <BiFilterAlt size={'1.5rem'} className="text-primary-400 " />
              <p className="text-base">Filter by</p>
            </span>
          </div>
        </Accordion.Button>
        <Accordion.Panel variant="default">
          <div className="flex items-center sm:border-r sm:border-gray-500  py-4 ">
            <RadioGroup
              hideLabel
              label="search"
              selected={filterState.empType}
              onSelect={(value: ApplicableEmpType) => handleEmployeeType(value)}
            >
              <RadioGroup.Option variant="badges" value="all" id="all">
                All
              </RadioGroup.Option>
              <RadioGroup.Option
                variant="badges"
                value="nonunion"
                id="nonunion"
              >
                Non Union
              </RadioGroup.Option>
              <RadioGroup.Option variant="badges" value="union" id="union">
                Union
              </RadioGroup.Option>
            </RadioGroup>
          </div>
          <div className="flex flex-col flex-wrap w-full gap-2 items-end justify-end pt-4 border-t border-gray-200">
            <Button
              variant="text"
              className="text-primary-300 "
              onClick={handleClearAll}
            >
              Clear All
            </Button>
            <Select
              className="w-full"
              selected={{
                value: filterState.type,
              }}
              onSelect={(item: PaycodeType | 'all') => handleTypeChange(item)}
            >
              <Select.Button
                label={
                  filterState.type && filterState.type !== 'all'
                    ? filterState.type[0].toLocaleUpperCase() +
                      filterState.type.slice(1)
                    : 'Select Pay Code Type'
                }
              />
              <Select.Options>
                <Select.Option value="all">All</Select.Option>
                {typeFilterList.map((i) => {
                  return (
                    <Select.Option key={i} value={i}>
                      {getPaycodeTypeText(i)}
                    </Select.Option>
                  );
                })}
              </Select.Options>
            </Select>

            <Select
              className="w-full"
              selected={{
                value: filterState.status,
              }}
              onSelect={(item: PaycodeStatus | 'all') =>
                handleStatusChange(item)
              }
            >
              <Select.Button
                label={
                  filterState.status && filterState.status !== 'all'
                    ? filterState.status[0].toLocaleUpperCase() +
                      filterState.status.slice(1)
                    : 'Select Status'
                }
              />
              <Select.Options>
                <Select.Option value="all">All</Select.Option>
                {statusFilterList.map((t) => {
                  return (
                    <Select.Option key={t} value={t}>
                      {getPaycodeStatusText(t)}
                    </Select.Option>
                  );
                })}
              </Select.Options>
            </Select>
          </div>
        </Accordion.Panel>
      </Accordion>
      <Accordion
        variant="default"
        className="shadow-md bg-white rounded-lg border"
        isOpen={true}
      >
        <Accordion.Button variant="default">
          <div className="flex flex-col ">
            <span className="flex gap-2">
              <BsSortUpAlt color="#005288" size="1rem" />
              <p className="text-base">SortBy</p>
            </span>
            <div className="flex items-center sm:border-r sm:border-gray-500"></div>
          </div>
        </Accordion.Button>
        <Accordion.Panel variant="default">
          <Select selected={filterState.sort} onSelect={handleSortFilters}>
            <Select.Button label={SortByEnum[filterState.sort.type]} filter>
              {filterState.sort.direction === SortDirection.ASC ? (
                <BsSortUpAlt color="#005288" size="1rem" />
              ) : (
                <BsSortDown color="#005288" size="1rem" />
              )}
            </Select.Button>
            <Select.Options>
              {sortFilters.map((item, itemIdx) => {
                return (
                  <Select.Option
                    key={itemIdx}
                    value={{ type: item.name, direction: item.direction }}
                  >
                    <span className="flex items-center justify-between w-full">
                      <span className="pr-6">{item.label}</span>
                      <span className="flex justify-end ">
                        {item.direction === SortDirection.ASC ? (
                          <BsArrowUp color="#005288" />
                        ) : (
                          <BsArrowDown color="#005288" />
                        )}
                      </span>
                    </span>
                  </Select.Option>
                );
              })}
            </Select.Options>
          </Select>
        </Accordion.Panel>
      </Accordion>
      <Drawer.Controls>
        <Button
          className="md:mb-0 mb-2 w-full"
          variant="outline"
          onClick={(): void => {
            setFilterState(defaultState);
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="md:ml-4 w-full"
          onClick={handleApplyFilter}
        >
          Apply
        </Button>
      </Drawer.Controls>
    </Drawer>
  );
};
