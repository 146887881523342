import { format } from 'date-fns';
import { AddCrew } from 'features/crew-maintenance/components/Crews/AddCrew';
import { useAddCrewStore } from 'features/crew-maintenance/state/addcrewStore';
import { Org, ORG_TYPE } from 'features/org';
import { useEmployeeOrgs } from 'features/org/api';
import { useCrewTimecard } from 'features/timecards/api/crewTimecard';
import { useEffect } from 'react';
import { SortDirection } from 'types/common';
interface DetailsProps {
  crew?: Org | null;
  crewId?: string;
}

export const Details = ({ crew, crewId }: DetailsProps) => {
  const store = useAddCrewStore();
  const { data: plants, status: plantStatus } = useEmployeeOrgs('me', {
    type: ORG_TYPE.PLANT,
    sortBy: 'plant',
    sortType: SortDirection.ASC,
    context: 'summary',
  });

  const { data: orgs, status: orgsStatus } = useEmployeeOrgs('me', {
    sortType: SortDirection.ASC,
    parentOrgId: store.selectedPlant?.id ?? undefined,
    context: 'summary',
  });

  const { data: timecardData } = useCrewTimecard(crewId ? crewId : '', {
    week: format(new Date(), 'yyyy-MM-dd'),
  });

  useEffect(() => {
    store.clearSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (crew && plants) {
      store.setPlant(plants.items.filter((e) => e.plant === crew.plant)[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plants]);

  return (
    <>
      {
        <AddCrew
          crew={crew}
          plants={plants}
          plantStatus={plantStatus}
          orgs={orgs}
          orgsStatus={orgsStatus}
          crewId={crewId ? crewId : ''}
          approvedTimeCardExists={timecardData?.approvedTimeCardExists}
        />
      }
    </>
  );
};
