import { useMutation, useQuery } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import {
  ShiftAssignmentBody,
  ShiftAssignmentList,
  ShiftAssignmentParams,
} from '../types';
import { WMSError } from 'types/common';
import { notify } from 'components/Notification/useNotification';
import { PayPeriodStatus } from 'features/pay-periods';
import { queryClient } from 'services/api/react-query';
import {
  getChangeErrorMessage,
  getChangeSuccessMessage,
} from '../utilities/shiftAssignmentCommon';

const get = (
  ussId: string,
  params?: ShiftAssignmentParams
): Promise<ShiftAssignmentList> => {
  return axios.get(`/v1/people/${ussId}/shift-assignment`, { params });
};

export const useShiftAssignment = (
  ussId: string,
  params?: ShiftAssignmentParams
) => {
  return useQuery({
    queryFn: () => get(ussId, params),
    queryKey: [`get-shift-assignment`, params?.payPeriodId],
  });
};

const changeShiftAssignment = (
  ussId: string,
  body: ShiftAssignmentBody
): Promise<{
  success: boolean;
  message: string;
}> => {
  return axios.post(`/v1/people/${ussId}/shift-assignment`, body);
};

export const useChangeShiftAssignment = (
  ussId: string,
  payPeriodStatus?: PayPeriodStatus,
  week?: string
) => {
  const errMsg = getChangeErrorMessage(payPeriodStatus);
  return useMutation<
    {
      success: boolean;
      message: string;
    },
    WMSError,
    ShiftAssignmentBody
  >({
    mutationFn: (body: ShiftAssignmentBody) =>
      changeShiftAssignment(ussId, body),
    onSuccess: async () => {
      const message = getChangeSuccessMessage(payPeriodStatus);
      notify.success({ message });
      await queryClient.refetchQueries({
        queryKey: [`get-shift-assignment`],
      });
      await queryClient.refetchQueries({
        queryKey: ['person-details', { ussId, params: { week } }],
      });
    },
    meta: {
      errorMessage: errMsg,
    },
  });
};

const undoShiftAssignment = (
  ussId: string,
  shiftId: string
): Promise<{
  success: boolean;
  message: string;
}> => {
  return axios.delete(`/v1/people/${ussId}/shift-assignment/${shiftId}`);
};

export const useUndoShiftAssignment = (ussId: string, week?: string) => {
  return useMutation<
    {
      success: boolean;
      message: string;
    },
    WMSError,
    string
  >({
    mutationFn: (shiftId: string) => undoShiftAssignment(ussId, shiftId),
    onSuccess: async () => {
      notify.success({
        message:
          "You have successfully reverted this employee's shift assignment.",
      });
      await queryClient.refetchQueries({
        queryKey: [`get-shift-assignment`],
      });
      await queryClient.refetchQueries({
        queryKey: ['person-details', { ussId, params: { week } }],
      });
    },
  });
};
