import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { ModelDetailParams, ModelSchedTurns, ModelWeeks } from '../types';
export interface ScheduleTurn {
  scheduleTurn: ModelSchedTurns;
  weekNumber: number;
  crewRotation: string;
}
interface EditModelStore {
  eachWeek: ModelWeeks | null;
  weeks: ModelWeeks[] | null;
  updateWeeks: (weeks: ModelWeeks[] | null) => void;
  updateEachWeek: (week: ModelWeeks | null) => void;
  params: ModelDetailParams;

  setPage: (value: number) => void;
  setPageSize: (value: number) => void;
}
export const useEditModelStore = create<EditModelStore>()(
  devtools(
    immer((set, get) => ({
      weeks: null,
      eachWeek: null,
      params: {
        page: 1,
        pageSize: 6,
      },

      setPage: (value) =>
        set(
          (state) => {
            state.params.page = value;
          },
          false,
          'set-edit-detail-page'
        ),
      setPageSize: (value) =>
        set(
          (state) => {
            state.params.pageSize = value;
            state.params.page = 1;
          },
          false,
          'set-edit-detail-pageSize'
        ),
      updateEachWeek: (value) =>
        set(
          (state) => {
            state.eachWeek = value;
          },
          false,
          'each-update-weeks'
        ),
      updateWeeks: (value) =>
        set(
          (state) => {
            state.weeks = value;
          },
          false,
          'update-weeks'
        ),
    }))
  )
);
