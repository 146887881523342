import { z } from 'zod';
import { appNewDate } from 'utils/appNewDate';
import { format } from 'date-fns';
import { requestPtoDateRange } from '../validations/request-pto/request-pto-date-range';
import { addPtoContext } from './v2';

export const AddPtoSchema = z
  .object({
    ussId: z.string().nonempty(),
    ptoOptionType: z.string().nonempty({ message: 'PTO Option is required' }),
    paycode: z.string(),
    paycodeDesc: z.string().optional().nullable(),
    startDate: z
      .string({
        required_error: 'Start date is required',
        invalid_type_error: 'Start date is required',
      })
      .min(1, 'Start date is required'),
    endDate: z
      .string()
      .nullable()
      .default(null)
      .transform((v) => {
        if (v === '') {
          return null;
        } else {
          return v;
        }
      }),
    excludeWeekends: z.coerce.boolean().default(false),
    excludeHolidays: z.boolean().default(false),
    chargeYear: z.string().default(format(appNewDate(), 'yyyy')),
    notes: z
      .string()
      .max(100, 'Note cannot be more than 100 characters')
      .nullable()
      .default(null)
      .transform((v) => {
        if (v === '') {
          return null;
        } else {
          return v;
        }
      }),
    status: z
      .union([z.literal('approved'), z.literal('requested')])
      .default('requested'),
    context: addPtoContext
  })
  .refine(
    (val) => {
      if (
        (val.ptoOptionType === '' || val.ptoOptionType === 'PTO') &&
        val.paycode === ''
      )
        return false;
      return true;
    },
    {
      message: 'Pay Code is required.',
      path: ['paycode'],
    }
  )
  .refine((val) => requestPtoDateRange(val.startDate, val.endDate), {
    message: 'End date cannot be earlier than start date.',
    path: ['endDate'],
  });
