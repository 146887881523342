import { format } from 'date-fns';
import getPolicySummaryAdjTitle from 'features/timecards/utilities/getPolicySummaryHeaderTitle';

const PolicySummaryHeader = ({
  adjustment,
  payProcessingDate,
  isTotalHoursCard,
}: {
  adjustment: number;
  payProcessingDate: string;
  isTotalHoursCard?: boolean;
}) => {
  return (
    <div className="sm:flex sm:justify-between py-4">
      <p
        className=" font-semibold text-sm md:text-base text-gray-700"
        data-testid="adjustment-title"
      >
        {getPolicySummaryAdjTitle(adjustment, isTotalHoursCard)}
      </p>
      <p
        className="text-xs md:text-sm text-gray-700 sm:mt-0 mt-1.5"
        data-testid="pay-processing-date"
      >{`Pay Processing Date: ${format(
        new Date(payProcessingDate),
        'MMM dd, yyyy'
      )}`}</p>
    </div>
  );
};

export default PolicySummaryHeader;
