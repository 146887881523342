import { ProbEmployeeReview } from 'features/probationary-tracking/types/employeeReviewDetails';
import EmployeeCard from '../EmployeeList/EmployeeCard/EmployeeCard';

const ReviewDetailsCard = ({
  employeeData,
}: {
  employeeData: ProbEmployeeReview;
}) => {
  const {
    supervisor,
    firstName,
    lastName,
    displayName,
    ussId,
    status,
    reviewPeriodStart,
    reviewPeriodEnd,
  } = employeeData;

  const modifiedEmployeeData = {
    supervisor,
    firstName,
    lastName,
    displayName,
    ussId,
    status,
    startDate: reviewPeriodStart,
    estEndDate: reviewPeriodEnd,
  };

  return (
    <EmployeeCard employee={modifiedEmployeeData} collapsible>
      <EmployeeCard.Supervisor customLabel={'Supervisor Name'} />
      <EmployeeCard.SupervisorUssId />
      <EmployeeCard.ReviewPeriod />
    </EmployeeCard>
  );
};

export default ReviewDetailsCard;
