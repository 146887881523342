import { useQuery } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { PaycodeList } from '../types';
import {
  EMPLOYEE_TYPE,
  YES_NO,
  ZodEmployeeType,
  ZodNonUnionEmployee,
  ZodPaycodeStatus,
  ZodPaycodeType,
  ZodPlantType,
  ZodShiftAssignment,
  ZodUnionEmployee,
} from 'features/paycode/constants';
import { z } from 'zod';
import { SortDirection } from 'types/common';

export const SORT_BY = {
  CODE: 'code',
  DESCRIPTION: 'description',
  TYPE: 'type',
  STATUS: 'status',
  RECENTLY_ADDED: 'recently-added',
  RECENTLY_ARCHIVED: 'recently-archived',
  RECENTLY_DENIED: 'recently-denied',
} as const;
const ZodSortBy = z.nativeEnum(SORT_BY);

export type SortBy = z.infer<typeof ZodSortBy>;

export const PAYROLL_GROUP = {
  NON_EXEMPT_FULL_TIME: 'non-exempt-full-time',
  NON_EXEMPT_PART_TIME: 'non-exempt-part-time',
  EXEMPT: 'exempt',
  HOURLY: 'hourly',
  BIWEEKLY_SALARY: 'biweekly-Salary',
} as const;

const ZodPayrollGroupOption = z.nativeEnum(PAYROLL_GROUP);
export const ZodAppEmpType = z.nativeEnum(EMPLOYEE_TYPE);
const ZodPaycodeObj = z.object({
  applicableEmpType: ZodEmployeeType.optional(),
  unionEmpType: ZodUnionEmployee.optional(),
  nonunionEmpType: ZodNonUnionEmployee.optional(),
  shifts: ZodShiftAssignment.optional(),
  type: ZodPaycodeType.optional(),
  status: ZodPaycodeStatus.optional(),
  search: z.string().optional(),
  pto: z.nativeEnum(YES_NO).optional(),
  payable: z.nativeEnum(YES_NO).optional(),
  report: z.nativeEnum(YES_NO).optional(),
  incEligible: z.nativeEnum(YES_NO).optional(),
  productionBonus: z.nativeEnum(YES_NO).optional(),
  sortBy: ZodSortBy.optional(),
  sortType: z.nativeEnum(SortDirection).optional(),
  payrollGroup: ZodPayrollGroupOption.optional(),
  page: z.number().optional(),
  pageSize: z.number().optional(),
  restrictedUsage: z.boolean().optional(),
  mockResponse: z.boolean().optional(),
  paidSickTimeEligibility: z.nativeEnum(YES_NO).optional(),
  plantType: ZodPlantType.optional(),
});

export type PaycodeOptions = z.infer<typeof ZodPaycodeObj>;

const getAllPaycodes = (options?: PaycodeOptions): Promise<PaycodeList> => {
  return axios.get(`/v3/paycodes`, {
    params: options,
  });
};

export const usePaycodes = (options?: PaycodeOptions) => {
  return useQuery<PaycodeList, Error>({
    queryKey: ['paycodes', { ...options }],
    queryFn: () => getAllPaycodes(options),
  });
};
