import { Datepicker } from '@uss/react-components';
import { CalendarView } from 'components/CalendarV2/types';
import { addYears, subYears } from 'date-fns';
import useWindowSize from 'hooks/useWindowSize';

interface DateFilterParams {
  calendarType: CalendarView;
  startDateValue: Date;
  onSelectDate: (value: Date) => void;
}
const DateFilter = ({
  calendarType,
  startDateValue,
  onSelectDate,
}: DateFilterParams) => {
  const { width } = useWindowSize();
  const minDate = subYears(new Date(), 2);
  const maxDate = addYears(new Date(), 1);

  return (
    <Datepicker
      startDate={startDateValue}
      onDateChange={onSelectDate}
      calendarType={calendarType}
      id="pto-date-filter"
      minDate={minDate}
      maxDate={maxDate}
      onClear={() => onSelectDate(startDateValue)}
      data-testid="ptoDateFilter"
      isMobile={width && width < 1024 ? true : false}
    />
  );
};

export default DateFilter;
