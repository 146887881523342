/**
 * @description any paycode should be selected if scheduled variance is added
 * @returns boolean
 */

export const varianceNoPaycode = (
  variance: number | null,
  payCode: string | null
) => {
  if (variance !== null && variance !== 0) {
    return payCode !== null && payCode !== '' ? true : false;
  } else {
    return true;
  }
};
