/**
 * @description Hours should not be greater then maximum pto hours
 * @returns
 */

export const sellBackMaximumPto = (
  sellBackHours: number,
  maximumPto: number
) => {
  if (sellBackHours > maximumPto) {
    return false;
  }
  return true;
};
