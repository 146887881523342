import { PayrollName, PayrollOptions } from 'features/paypulloff/types';
import { useQuery } from '@tanstack/react-query';
import { axios } from 'services/api/axios';

const get_payroll_names = (params: PayrollOptions): Promise<PayrollName> => {
  return axios.get('/v1/payrolls', { params });
};

export const usePayrollName = (params: PayrollOptions) => {
  return useQuery<PayrollName>({
    queryKey: ['payrollName'],
    queryFn: () => get_payroll_names(params),
    staleTime: 5000,
    gcTime: 5000,
    refetchOnMount: true,
    // select: (element) => {
    //   return {
    //     ...element,
    //     items:
    //       /* we might get null values from api */
    //       // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    //       element?.items && element.items.length > 0
    //         ? element.items.filter(
    //             (item) => item.id === 'big_river_steel_weekly'
    //           )
    //         : [],
    //   };
    // },
  });
};
