import { useMutation } from '@tanstack/react-query';
import { WMSError } from 'types/common';

import { axios } from '../../../services/api/axios';
import { UpdateCrewMembersResponse, UpdatePayApproverBody } from '../types';

const updatePrimaryApprover = (
  orgId: string,
  body: UpdatePayApproverBody
): Promise<UpdateCrewMembersResponse> => {
  return axios.put(`/v1/orgs/${orgId}/security`, body);
};

/**
 * Hook that allows you to edit/update primary approver for an org.
 * @param orgId orgId
 *
 * @example
 * const {mutate} = useUpdatePrimaryApprover("12345")
 * mutate([{ussId: 12345, orgRoleId: '12345', primary:'y'}])
 */
export const useUpdatePrimaryApprover = (orgId: string) => {
  return useMutation<
    UpdateCrewMembersResponse,
    WMSError,
    UpdatePayApproverBody
  >({
    mutationFn: (body: UpdatePayApproverBody) =>
      updatePrimaryApprover(orgId, body),
    meta: {
      errorMessage: 'Unable to update primary approver.',
    },
  });
};
