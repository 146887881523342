import { PersonStatus } from 'features/people/types';

export enum ReviewStatus {
  NOT_OPEN = 'not-open',
  NOT_REVIEWED = 'not-reviewed',
  REVIEW_DUE = 'review-due',
  SUPERVISOR_REVIEW = 'supervisor-review',
  REVALUATE = 'revaluate',
  HR_LR_REVIEW = 'hr-lr-review',
  LOW_RATING = 'low-rating',
  COUNSELLING = 'counselling',
  EMP_TERMINATED = 'emp-terminated',
  EMP_ACKNOWLEDGEMENT = 'emp-acknowledgement',
  SUPERVISOR_ACKNOWLEDGEMENT = 'supervisor-acknowledgement',
  FINAL_REVIEW = 'final-review',
  SUPERVISOR_TERM_ACKNOWLEDGEMENT = 'supervisor-term-acknowledgement',
  TERMINATED = 'terminated',
  RETAINED = 'retained',
}

export interface EmployeeDetail {
  ussId: number;
  lastName: string;
  firstName: string;
  middleName: string | null;
  displayName: string | null;
}
export interface ReviewerDetail {
  ussId: number | null;
  lastName: string | null;
  firstName: string | null;
  middleName: string | null;
  displayName: string | null;
}
export interface ProbEmployeeDetails {
  id: string;
  ussId: number;
  lastName: string;
  firstName: string;
  middleName: string | null;
  displayName: string;
  suffix: string | null;
  status: PersonStatus;
  reviewStatus: ReviewStatus | null;
  activeReviewNumber: number;
  startDate: string | null;
  estEndDate: string | null;
  workedHours: number | null;
  bargUnit: string | null;
  reviewer: ReviewerDetail;
  supervisor: EmployeeDetail;
}
export interface ProbEmployeesList {
  items: ProbEmployeeDetails[];
  page: number;
  pageSize: number;
  totalCount: number;
}
export interface ProbationaryEmployeesListOptions {
  search?: string;
  page?: string;
  pageSize?: string;
  unassigned?: 'y' | 'n';
  context?: 'supervisor' | 'reviewer' | 'hr-lr';
}
