import { AuthenticatedTemplate, useAccount, useMsal } from '@azure/msal-react';
import { callMsGraphPicture, profile } from 'services/api/graph.service';
import React, { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { Avatar } from '@uss/react-components';
import { getInitials } from 'utils';

const Profile = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  // UI State
  const [avatar, setAvatar] = useState<string>('');

  // Graph Queries
  const { data: profileData } = useQuery({
    queryKey: ['profile'],
    queryFn: profile,
    staleTime: 2000,
    refetchOnWindowFocus: false,
  });

  const getProfilePicture = async (token: string) => {
    const avatar = await callMsGraphPicture(token);
    const reader = new FileReader();

    reader.readAsDataURL(avatar as Blob);
    reader.onloadend = function () {
      if (typeof reader.result === 'string') {
        setAvatar(reader.result);
      }
    };
  };

  useEffect(() => {
    const getProfileData = async () => {
      if (account) {
        await instance
          .acquireTokenSilent({
            account,
            scopes: ['User.Read'],
          })
          .then(async (response) => {
            await getProfilePicture(response.accessToken);
          });
      }
    };
    getProfileData().catch(() => null);
  }, [account, instance]);

  //Check Image
  const [loaded, setLoaded] = useState(true);

  function checkImage(): void {
    if (typeof avatar === 'string') {
      const img = new Image();
      img.onload = (): void => setLoaded(true);
      img.onerror = (): void => setLoaded(false);
      img.src = avatar;
    }
  }
  checkImage();
  return (
    <>
      <AuthenticatedTemplate>
        <div className="flex items-center pr-8 w-full">
          <div className="flex items-center mr-2 text-gray-600">
            {loaded ? (
              <Avatar size={'medium'} image={true} url={avatar} color={1}>
                {''}
              </Avatar>
            ) : (
              <Avatar size={'medium'} color={2}>
                {profileData?.givenName !== undefined
                  ? getInitials(profileData.givenName, profileData.surname)
                  : ''}
              </Avatar>
            )}
          </div>

          <div className="flex flex-col text-left items-center">
            <span className="text-base text-primary-400 w-full">
              {profileData?.displayName}
            </span>
            <span className="text-xs text-gray-700 w-full">
              {profileData?.jobTitle}
            </span>
          </div>
        </div>
      </AuthenticatedTemplate>
    </>
  );
};

export default Profile;
