const holidayWithHours = ({
  isHoliday,
  efforts,
}: {
  isHoliday: boolean;
  efforts: number | null;
}) => {
  return isHoliday && (efforts ? efforts > 0 : false);
};

export default holidayWithHours;
