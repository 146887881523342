import { ReactElement, useLayoutEffect, useState } from 'react';
import { Select } from '@uss/react-components';
import useWindowSize from 'hooks/useWindowSize';
import { Tab } from '@headlessui/react';
import { useSearchParams } from 'react-router-dom';
export interface TabLinkType {
  element: ReactElement | JSX.Element;
  label: string;
  customOnChange?: () => unknown;
  isDisabled?: boolean;
}
interface TabsProps {
  tabLinks: TabLinkType[];
  selectedIndexNumber?: number;
}

const Tabs = ({ tabLinks, selectedIndexNumber }: TabsProps): JSX.Element => {
  const { width } = useWindowSize();
  const [selectedIndex, setSelectedIndex] = useState<number>(
    selectedIndexNumber ? selectedIndexNumber : 0
  );
  const [searchParams, setsearchParams] = useSearchParams();
  const preferredTab = searchParams.get('tab') ?? '';
  const tabViewMobile = width && width < 768 && tabLinks.length <= 2;
  const tabView = width && (width > 768 || tabViewMobile);

  const handleTabChange = (value: TabLinkType) => {
    const foundIndex = tabLinks.findIndex((tab) => {
      return tab.label === value.label;
    });
    if (foundIndex !== -1) {
      setSelectedIndex(foundIndex);
      if (searchParams.get('tab')) {
        searchParams.delete('tab');
        setsearchParams(searchParams);
      }
      const selectedTab = tabLinks[foundIndex];
      if (selectedTab.customOnChange) {
        selectedTab.customOnChange();
      }
    }
  };
  useLayoutEffect(() => {
    if (preferredTab.length > 0) {
      const foundIndex = tabLinks.findIndex((tab) => {
        return tab.label.toLowerCase() === preferredTab.toLowerCase();
      });
      if (foundIndex !== -1) {
        setSelectedIndex(foundIndex);
      }
    }
  }, [preferredTab, tabLinks]);

  const getTabClassName = (selected: boolean) => {
    if (selected) {
      if (tabViewMobile) {
        return 'py-2 px-4 border-b-4 border-accent1-400 font-semibold w-full';
      } else {
        return 'py-2 px-4 border-b-4 border-accent1-400 font-semibold ';
      }
    } else {
      return 'py-2 px-4 text-gray-700  inline-block h-full  font-normal w-full sm:w-auto disabled:text-gray-500';
    }
  };

  return (
    <>
      {tabView ? (
        <div data-testid="tabBar-container">
          <Tab.Group
            selectedIndex={selectedIndex}
            onChange={(index) => handleTabChange(tabLinks[index])}
          >
            <Tab.List
              className="flex flex-row justify-start border-b-2 border-gray-300 mb-2  text-lg"
              data-testid="tabBar-tabList"
            >
              {tabLinks.map((link, index) => (
                <Tab
                  key={index}
                  className={({ selected }) => getTabClassName(selected)}
                  data-testid="tabBar-tab"
                  disabled={link.isDisabled}
                >
                  {link.label}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              {tabLinks.map((link, index) => (
                <Tab.Panel
                  className="sm:min-h-72"
                  key={index}
                  data-testid="tabBar-tabContent"
                >
                  {link.element}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      ) : (
        <>
          <Select
            selected={tabLinks[selectedIndex].label}
            onSelect={handleTabChange}
            className="w-full"
            data-testid="tabBar-container"
          >
            <Select.Button
              label={tabLinks[selectedIndex].label}
              tabsMenuButton
            />
            <Select.Options data-testid="tabBar-tabList">
              {tabLinks.map((link, index) => {
                return (
                  <>
                    {!link.isDisabled && (
                      <Select.Option
                        data-testid="tabBar-tab"
                        key={index}
                        value={link}
                        truncate
                      >
                        {link.label}
                      </Select.Option>
                    )}
                  </>
                );
              })}
            </Select.Options>
          </Select>
          <div data-testid="tabBar-tabContent">
            {tabLinks[selectedIndex].element}
          </div>
        </>
      )}
    </>
  );
};
export default Tabs;
