import { QueryStatus } from '@tanstack/react-query';
import { createContext, useContext } from 'react';
import { KPIOptions } from './EmployeesSummary';

const EmployeesSummaryContext = createContext<{
  data: KPIOptions | undefined | null;
  status: QueryStatus;
} | null>(null);

export function useEmployeesSummaryContext() {
  const context = useContext(EmployeesSummaryContext);

  if (!context) {
    throw new Error(
      'EmployeesSummary.* component must be rendered as child of the EmployeesSummary'
    );
  }

  return context;
}

export default EmployeesSummaryContext;
