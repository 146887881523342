import {
  CrewPayApproverDetails,
  PayApproverOptions,
} from 'features/paypulloff/types';
import { useQuery } from '@tanstack/react-query';
import { axios } from 'services/api/axios';

const get_unapproved_crews_details = (
  params: PayApproverOptions
): Promise<CrewPayApproverDetails> => {
  return axios.get(`/v1/crews/pay-approvers`, {
    params,
  });
};

export const useUnapprovedCrewDetails = (params: PayApproverOptions) => {
  return useQuery<CrewPayApproverDetails>({
    queryKey: ['unapprovedCrewDetails', { ...params }],
    queryFn: () => get_unapproved_crews_details(params),
    staleTime: 5000,
    gcTime: 5000,
    refetchOnMount: true,
  });
};
