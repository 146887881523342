import React from 'react';
import { Select } from '@uss/react-components';
import { PtoStatus, PtoFilterStatusType } from 'features/paid-time-off/types';
import { getPtoStatusText } from 'features/paid-time-off/utility/getPtoStatusText';
import { PTO_STATUS } from 'features/paid-time-off/constants';

const statusValues = Object.values(PTO_STATUS).sort();
interface StatusSelectParams {
  setStatus: (value: PtoStatus | 'all') => void;
  status: PtoStatus | undefined;
}

export const StatusSelect = ({ setStatus, status }: StatusSelectParams) => {
  const handleStatusChange = (value: PtoFilterStatusType) => {
    setStatus(value);
  };
  const statusValue = status ? getPtoStatusText(status) : 'Select Status';
  return (
    <Select
      selected={status ? status : 'all'}
      onSelect={(value: PtoFilterStatusType) => {
        handleStatusChange(value);
      }}
    >
      <Select.Button label={statusValue} />
      <Select.Options>
        <Select.Option value="all">All</Select.Option>
        {statusValues.map(
          (item, index) =>
            item !== 'removed' && (
              <Select.Option key={index} value={item}>
                {getPtoStatusText(item)}
              </Select.Option>
            )
        )}
      </Select.Options>
    </Select>
  );
};
