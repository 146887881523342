import { HolidayList } from 'features/holidays/types';
import isDateHoliday from 'features/timecards/utilities/checkDateHoliday';

interface ShouldDisplayHolidayPay {
  dates: string[];
  holidayList: HolidayList | undefined;
  holidayPayHours: number;
  isOriginalTimecard?: boolean;
  isTotalHoursCard?: boolean;
}

const shouldDisplayHolidayPay = ({
  dates,
  holidayList,
  holidayPayHours,
  isOriginalTimecard,
  isTotalHoursCard,
}: ShouldDisplayHolidayPay) => {
  const isHolidayWeek = dates.some((date) => isDateHoliday(date, holidayList));
  if (!isHolidayWeek) {
    return false;
  } else {
    return !!isOriginalTimecard || !!isTotalHoursCard || holidayPayHours !== 0;
  }
};
export default shouldDisplayHolidayPay;
