import React, { useEffect, useState } from 'react';
import { Drawer, DropdownMenu } from '@uss/react-components';
import { BsThreeDotsVertical, BsTools } from 'react-icons/bs';
import Card from 'components/Card/Card';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';
import { BsGear, BsTrash } from 'react-icons/bs';
import { SecurityRecord, useUpdateSecurityForBlackout } from 'features/security';
import { Button } from '@uss/react-components';
import SecurityRecordLoader from 'features/timecards/components/TimecardDetails/Loaders/SaveSecurityRecordLoader';
import { Link } from 'react-router-dom';
import { Switch } from '@uss/react-components';
import { ROLECODES } from 'features/roles';
import { Person } from 'features/people';
import { EffExpDate } from 'features/crew-maintenance/components/common/EffExpDate';
import { queryClient } from 'services/api/react-query';

interface Props {
  record: SecurityRecord;
  onRemove: (record: SecurityRecord) => void;
  employeeData: Person | undefined;
}

export const SecurityRecordCard = ({
  record,
  onRemove,
  employeeData,
}: Props) => {
  const { mutateAsync: updateRecord } = useUpdateSecurityForBlackout(
    record.ussId.toString()
  );
  const [ptoBlackOutToggle, setptoBlackOutToggle] = useState(false);

  const onSwitch = async () => {
    setptoBlackOutToggle(!ptoBlackOutToggle);
    await onSave(!ptoBlackOutToggle)
  };
  const [openDrawer, setOpenDrawer] = useState(false);

  const onClose = async () => {
    setOpenDrawer(false);
    //refetch API onClose
    await queryClient.refetchQueries({
      queryKey: ['employee-securities'],
      type: 'active',
    });
  };
  const onSave = async (value: boolean) => {
    await updateRecord({
      orgId: record.orgId,
      role: ROLECODES.PAY_APPROVER,
      primary: null,
      permissions: {
        ptoBlackOut: value ? 'y' : null,
      },
    });
  };
  useEffect(() => {
    if (record.permissions?.ptoBlackOut === 'y') {
      setptoBlackOutToggle(true);
    }
  }, [record.permissions]);

  return (
    <Card>
      <Card.Header>
        <div className="flex flex-col flex-wrap flex-grow">
          <Title as="h2">{record.roleName ? record.roleName : '-'}</Title>
          <div className="flex justify-between">
            <p className="text-gray-700 text-sm text-left line-clamp-1">
              {record.plant ? record.plant : '-'}
            </p>
            <EffExpDate member={record} isFutureEffectiveDate={false} />
          </div>
        </div>
        {record.crew !== null && record.role === 'pay_approver' ? (
          <DropdownMenu>
            <DropdownMenu.Button>
              <BsThreeDotsVertical
                size="1.5rem"
                aria-label="More Options"
                className="text-primary-400"
              />
            </DropdownMenu.Button>
            <DropdownMenu.Items
              renderAs="ul"
              className="w-52 pt-3 pb-2 shadow-md"
            >
              <DropdownMenu.Item
                renderAs="li"
                key="Clear Shift"
                className="text-gray-700 text-sm flex item-center"
              >
                <Button
                  variant="icon"
                  className="text-sm"
                  startIcon={<BsGear className="text-primary-400 " />}
                >
                  <Link
                    className="text-sm text-gray-700 flex items-center justify-center "
                    to={`/admin/crew-maintenance/crew/${record.orgId}?tab=Pay Approvers`}
                  >
                    <span>Manage Approvers</span>
                  </Link>
                </Button>
              </DropdownMenu.Item>
              <DropdownMenu.Item
                renderAs="li"
                className="mt-5 text-gray-700 text-sm flex item-center"
              >
                <Button
                  variant="icon"
                  className="text-sm"
                  startIcon={<BsTools className="text-primary-400 " />}
                  onClick={(e) => {
                    setOpenDrawer(true);
                  }}
                >
                  <span className="text-sm text-gray-700 flex items-center ">
                    Manage Permissions
                  </span>
                </Button>
              </DropdownMenu.Item>
            </DropdownMenu.Items>
          </DropdownMenu>
        ) : (
          <span>
            <Button
              variant="icon"
              iconLabel="Add Note"
              className="text-primary-400"
              disabled
            >
              <BsThreeDotsVertical size="1rem" aria-label="More Options" />
            </Button>
          </span>
        )}
      </Card.Header>
      <Drawer
        title="Manage Permissions"
        isOpen={openDrawer}
        onClose={onClose}
        closeButton={true}
        className="w-full sm:w-1/2 md:w-1/3 xl:w-1/4 2xl:w-1/5 md:top-0 top-18 md:rounded-t-none rounded-t-md"
        placement={window.innerWidth > 768 ? 'right' : 'bottom'}
      >
        {record.crew === null && record.role === 'pay_approver' ? (
          <SecurityRecordLoader />
        ) : (
          <div data-testid="drawer-content flex-grow">
            <div className="mb-4 mt-2 flex-grow">
              <div className="grid grid-cols-2 gap-4">
                <div
                  className="flex flex-col justify-start"
                  data-testid="selected-row-date"
                >
                  <span className="text-xs text-gray-600 ">
                    {employeeData?.displayName ? employeeData.displayName : '-'}
                  </span>

                  <span className="text-sm w-3/4">
                    <span> {record.ussId ? record.ussId : '-'} </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="mb-2 flex flex-wrap sm:flex-nowrap w-full lg:w-4/5">
              <LabeledText
                label="Role"
                className="w-1/2 sm:w-auto sm:flex-grow mb-4 sm:mb-0"
              >
                {record.roleName ? record.roleName : '-'}
              </LabeledText>
              <LabeledText
                label="Crew"
                className="w-1/2 sm:w-auto sm:flex-grow mb-4 sm:mb-0"
              >
                {record.crew ? record.crew : '-'}
              </LabeledText>
            </div>
            <div className="flex items-center w-full mb-2 mt-4">
              <p className="text-gray-700 text-xs justify-center mr-2 overflow-hidden whitespace-nowrap">
                Provide PTO Blackout Permission
              </p>
              <span className="ml-4">
                <Switch>
                  <Switch.Label isDisabled={false}>
                    {ptoBlackOutToggle ? 'Yes' : 'No'}
                  </Switch.Label>
                  <Switch.Handle
                    enabled={ptoBlackOutToggle}
                    onChange={() => onSwitch()}
                    isDisabled={false}
                  ></Switch.Handle>
                </Switch>
              </span>
            </div>
          </div>
        )}
      </Drawer>

      <Card.Body>
        <div className="grid grid-cols-2 gap-2 items-center w-full mb-4">
          <LabeledText label="Location" className="w-full" lineClamp={1}>
            {record.location
              ? `${record.location} -  ${record.locationDescription ?? ''}`
              : 'All'}
          </LabeledText>
          <LabeledText label="Division" className="w-full" lineClamp={1}>
            {record.division
              ? `${record.division}  - ${record.divisionDescription ?? ''}`
              : 'All'}
          </LabeledText>

          <LabeledText label="Department" className="w-full" lineClamp={1}>
            {record.department
              ? `${record.department}  - ${record.departmentDescription ?? ''}`
              : 'All'}
          </LabeledText>
          <LabeledText label="Crew" className="w-full" lineClamp={1}>
            {record.crew
              ? `${record.crew} - ${record.crewDescription ?? ''}`
              : 'All'}
          </LabeledText>
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="grid w-full justify-center">
          <Button
            variant="text"
            className="text-red-500 cursor-pointer flex items-center justify-center"
            onClick={() => onRemove(record)}
            disabled={record.expirationDate !== null && record.newCrew === null}
          >
            <BsTrash className="text-sm" />
            <span className="text-xs ml-2"> Remove</span>
          </Button>
        </div>
      </Card.Footer>
    </Card>
  );
};
