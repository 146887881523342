import React from 'react';
import { Table } from '@uss/react-components';
import { TimecardTotal } from 'features/timecards/types';

const TableTotalHoursRow = ({ totals }: { totals: TimecardTotal }) => {
  return (
    <Table.FooterRow id="total-hours-row" className="h-10">
      <Table.FooterCell id="total-hours">Total Hours</Table.FooterCell>
      <Table.FooterCell id="total-elps-time"></Table.FooterCell>
      <Table.FooterCell></Table.FooterCell>
      <Table.FooterCell id="total-scheduled-hours">
        {totals.scheduled}
      </Table.FooterCell>
      <Table.FooterCell></Table.FooterCell>
      <Table.FooterCell id="total-worked-hours">
        {totals.worked}
      </Table.FooterCell>
      <Table.FooterCell></Table.FooterCell>
      <Table.FooterCell id="total-approved-hours">
        {totals.approved}
      </Table.FooterCell>
      <Table.FooterCell></Table.FooterCell>
      <Table.FooterCell id="total-schedule-variance-hours">
        {totals.scheduleVariance}
      </Table.FooterCell>
      <Table.FooterCell id="total-paid-hours-not-worked">
        {totals.paidHoursNotWorked}
      </Table.FooterCell>
      <Table.FooterCell></Table.FooterCell>
      <Table.FooterCell></Table.FooterCell>
    </Table.FooterRow>
  );
};

export default TableTotalHoursRow;
