import Page from 'components/Layouts/Page';
import { NavLink } from 'react-router-dom';
import { format } from 'date-fns';
import { AnnualTotals } from 'features/paid-time-off/components/AnnualTotals/AnnualTotals';
import RequestPtoForm from 'features/paid-time-off/components/Forms/RequestPtoForm';
import EmployeeProfile from 'features/people/components/EmployeeProfile';
import { appNewDate } from 'utils/appNewDate';
import { PTO_ADD_CONTEXT } from 'features/paid-time-off/constants';

const RequestPTO = () => {
  return (
    <Page
      breadcrumbs={[
        <NavLink key="bc-1" to="/my-pto-nu">
          My PTO (NU)
        </NavLink>,
      ]}
      heading={'Request PTO'}
    >
      <Page.Section>
        <div className="my-2">
          <EmployeeProfile />
        </div>
        <AnnualTotals year={format(appNewDate(), 'yyyy')} />
      </Page.Section>
      <Page.Section>
        <RequestPtoForm
          defaultValues={{
            paycode: '',
            status: 'requested',
            chargeYear: format(appNewDate(), 'yyyy'),
            ussId: '',
            ptoDate: '',
            notes: '',
            excludeWeekends: false,
            excludeHolidays: false,
            startDate: '',
            endDate: null,
            paycodeDescription: '',
            context: PTO_ADD_CONTEXT.MY_PTO
          }}
        />
      </Page.Section>
    </Page>
  );
};

export default RequestPTO;
