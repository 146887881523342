import CardList from 'components/Card/CardList';
import Error from 'components/Error/Error';
import Card from 'components/Card/Card';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';
import { format } from 'date-fns';
import CardsLoader from 'components/Loaders/CardsLoader';
import { usePTOHistory } from 'features/paid-time-off/api/queries';
import {
  PTOHIstoryItems,
  PTOHistoryParams,
} from 'features/paid-time-off/types/pto-history';
import { SortDirection } from 'types/common';

interface PTOHistoryProps {
  noRecordError?: string;
  descLabel?: string;
  ptoId: string;
}
export const PTOHistory = ({
  noRecordError,
  descLabel,
  ptoId,
}: PTOHistoryProps): JSX.Element => {
  const noResults = {
    title: 'No Records Found',
    description: noRecordError ?? `No history records found`,
  };

  const params: PTOHistoryParams = {
    sortBy: 'action-taken-on',
    sortType: SortDirection.DESC,
  };

  const { data, status } = usePTOHistory(ptoId, params);

  return (
    <div className="mt-4">
      {status === 'pending' && <CardsLoader variant="row-grid" count={6} />}
      {status === 'success' && (
        <CardList<PTOHIstoryItems>
          variant="row-grid"
          items={data.items}
          renderItem={(item) => (
            <PTOHistoryCard descLabel={descLabel} history={item} />
          )}
          noResults={noResults}
        />
      )}
      {status === 'error' && <Error />}
    </div>
  );
};

interface PTOHistoryCardProps {
  history: PTOHIstoryItems;
  descLabel?: string;
}

export const PTOHistoryCard = ({
  history,
  descLabel = 'Description',
}: PTOHistoryCardProps): JSX.Element => {
  return (
    <Card>
      <Card.Body>
        <div data-testid="history-card">
          <div
            className="flex flex-col flex-wrap flex-grow"
            data-testid="history-card-title"
          >
            <Title as="h2">
              {history.status
                ? history.status.charAt(0).toUpperCase() +
                  history.status.slice(1)
                : '-'}
            </Title>
          </div>
          <div className="flex flex-wrap justify-between md:flex-nowrap items-center w-full">
            <LabeledText
              label={descLabel}
              className="w-full md:w-7/12 mb-2 pr-4 md:mb-0"
              no_clamp
            >
              {history.description ? history.description : '-'}
            </LabeledText>
            <LabeledText
              label="Action taken By"
              className="w-full md:w-3/12 mb-2 md:mb-0"
              no_clamp
            >
              {history.actionTakenBy ? history.actionTakenBy : '-'}
            </LabeledText>
            <LabeledText
              label="Action taken On"
              className="w-full md:w-2/12"
              no_clamp
            >
              {history.lastUpdateDt
                ? format(
                    new Date(history.lastUpdateDt),
                    'MMM dd, yyyy, hh:mm aa'
                  )
                : '-'}
            </LabeledText>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
