import useNotification from 'components/Notification/useNotification';
import { useMutation } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { queryClient } from 'services/api/react-query';
import {
  TimecardUpdatePayload,
  EmployeeTimecard,
  TIMECARD_STATUS,
} from '../types';

/**
 * Endpoint to update timecard information for an specific employee
 *
 * @param ussId USS ID of the person
 * @param id ID of the timecard
 * @returns Promise
 */
const update_timecard = (
  ussId: string,
  id: string,
  body: TimecardUpdatePayload
): Promise<EmployeeTimecard> => {
  return axios.put(`/v1/people/${ussId}/timecard-details/${id}`, body);
};

/**
 * Updates an employees existing timecard
 *
 * @param ussId USS ID of the user
 * @param id ID of the timecard
 * @returns React Query useMutation Result
 *
 * @link https://cappsq.psc.uss.com/SBWM-DOCUMENT/swagger-ui/index.html?urls.primaryName=sbwm-schedulepay#/schedule-pay-controller/updateEmployeeTimecard
 */

export const useUpdateEmployeeTimecard = (ussId: string, id: string) => {
  const notify = useNotification();
  return useMutation({
    mutationFn: (body: TimecardUpdatePayload) =>
      update_timecard(ussId, id, body),

    onSuccess: async (response, body) => {
      const status = body.status ? response.status.code : 'updated';
      notify.success({
        message:
          status === TIMECARD_STATUS.APPROVED_ADJUSTMENT
            ? `You have successfully approved the adjustment.`
            : status === TIMECARD_STATUS.UNAPPROVED_ADJUSTMENT
            ? `You have successfully unapproved the adjustment.`
            : `You have successfully ${status} the timecard.`,
      });
      await queryClient.invalidateQueries({
        queryKey: ['person-details'],
        type: 'active',
      });
      await queryClient.resetQueries({
        queryKey: ['employee-timecard'],
        type: 'active',
      });
      await queryClient.refetchQueries({
        queryKey: ['pto-summary'],
        type: 'active',
      });
    },
  });
};
