import { Button } from '@uss/react-components';
import {
  TIMECARD_ACTION_BUTTON,
  TimecardActionButton,
} from 'features/timecards/types';
import useModal from 'components/Modals/use-modal';
import { getActionModalProperties } from 'features/timecards/utilities/getActionModalProperties';
interface SchedulingActionsProps {
  buttonRef: React.MutableRefObject<TimecardActionButton>;
  handleUpdate: () => Promise<void>;
  schedulingButtonsState: {
    canSaveVerify: boolean;
    isSaveVerifyDisabled: boolean;
  };
  isTimecardDisabled: boolean;
}
type SchedulingActionButtons = Extract<
  TimecardActionButton,
  typeof TIMECARD_ACTION_BUTTON.SAVE_VERIFY
>;
type Actions = Record<
  SchedulingActionButtons,
  {
    isAllowed: boolean;
    title: string;
    actionType: SchedulingActionButtons;
    isDisabled: boolean;
  }
>;

const SchedulingActions = ({
  buttonRef,
  handleUpdate,
  schedulingButtonsState,
  isTimecardDisabled,
}: SchedulingActionsProps) => {
  const { canSaveVerify, isSaveVerifyDisabled } = schedulingButtonsState;
  const actions: Actions = {
    [TIMECARD_ACTION_BUTTON.SAVE_VERIFY]: {
      isAllowed: canSaveVerify,
      title: 'Save & Verify',
      actionType: TIMECARD_ACTION_BUTTON.SAVE_VERIFY,
      isDisabled: isSaveVerifyDisabled || isTimecardDisabled,
    },
  };
  const actionModal = useModal();
  return (
    <>
      {(Object.keys(actions) as (keyof typeof actions)[]).map(
        (type) =>
          actions[type].isAllowed && (
            <Button
              onClick={() => {
                buttonRef.current = actions[type]
                  .actionType as TimecardActionButton;
                const { modalTitle, confirmButtonTitle, modalBodyText } =
                  getActionModalProperties(buttonRef);
                actionModal.openModal({
                  title: modalTitle,
                  type: 'regular',
                  size: 'md',
                  children: (
                    <p className="text-sm text-gray-700">{modalBodyText}</p>
                  ),
                  labels: { confirm: confirmButtonTitle, cancel: 'Cancel' },
                  onCancel: actionModal.closeModal,
                  onConfirm: async () => {
                    actionModal.closeModal();
                    await handleUpdate();
                  },
                });
              }}
              variant="primary"
              className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
              type="submit"
              disabled={actions[type].isDisabled}
              key={type}
              data-testid={actions[type].actionType}
            >
              {actions[type].title}
            </Button>
          )
      )}
    </>
  );
};
export default SchedulingActions;
