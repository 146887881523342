import Loader from 'components/Skeleton/Loader';

const AddTempEmployeeDrawerLoader = () => {
  return (
    <div className="flex-grow px-2">
      <div className="flex flex-col ">
        <span className="text-xs text-gray-700 font-normal">Crew</span>
        <span>
          <Loader rows={1} />
        </span>
      </div>
      <div className="py-2 w-64">
        <Loader rows={1} />
      </div>
      <div className="py-2  grid grid-cols-2 gap-x-2">
        <div className=" col-span-2 flex flex-col">
          <span className="text-base font-semibold text-gray-700">
            <Loader rows={1} />
          </span>
          <span className="text-xs text-gray-700">
            <Loader rows={1} />
          </span>
        </div>

        <div className="flex flex-col py-2">
          <span className=" text-xs text-gray-700">Employee Type</span>
          <p className="text-sm text-gray-700 ">
            <Loader rows={1} />
          </p>
        </div>

        <div className="flex flex-col py-2">
          <span className=" text-xs text-gray-700">Job Title</span>
          <p className=" text-sm  text-gray-700">
            <Loader rows={1} />
          </p>
        </div>
        <div className="flex flex-col py-1">
          <span className=" text-xs text-gray-700">USS ID</span>
          <p className="text-sm text-gray-700 ">
            <Loader rows={1} />
          </p>
        </div>

        <div className="flex flex-col py-1">
          <span className=" text-xs text-gray-700">Supervisor</span>
          <p className=" text-sm  text-gray-700">
            <Loader rows={1} />
          </p>
        </div>
        <div className=" col-span-2 flex flex-col py-2">
          <span className=" text-xs text-gray-700">Status</span>
          <span className="text-sm text-gray-700">
            <Loader rows={1} />
          </span>
        </div>
      </div>
    </div>
  );
};
export default AddTempEmployeeDrawerLoader;
