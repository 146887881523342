import { compareAsc, parseISO } from 'date-fns';
import { PAY_PERIOD_STATUS } from 'features/pay-periods/types';
import {
  EmployeeTimecardList,
  Timecard,
  TimecardActionType,
  TIMECARD_STATUS,
  HourTypes,
  TurnTypes,
  TIMECARD_UPDATE_ACTIONS,
  TimecardEfforts,
  TimecardStatusCode,
} from 'features/timecards/types';
import {
  scheduledVariance,
  ScheduleVarianceProps,
} from '../utilities/scheduleVariance';
import { hoursAndTurn } from '../validations/hours-and-turn';
import { negativeHoursTurn } from '../validations/negative-hours-turn';
import { partialHours } from '../validations/partial-hours';
import { totalTurnHours } from '../validations/total-turn-hours';
import { useNonUnionStore } from './useNonUnionStore';

const useTimecardRowDetails = ({
  employeeTimecardObj,
  updateTimecard,
  timecardRowData: rowData,
  rowIndex,
  isTimecardDisabled,
}: {
  employeeTimecardObj: EmployeeTimecardList;

  updateTimecard: React.Dispatch<TimecardActionType>;
  timecardRowData: Timecard;
  rowIndex: number;
  isTimecardDisabled: boolean;
}) => {
  const state = useNonUnionStore();
  const feature = state.feature;
  const payPeriodStatus = employeeTimecardObj.items[0].payPeriodStatus;

  const status = employeeTimecardObj.items[0].status.code;

  const isPay = feature === 'pay';

  //check if day is a future day. Would be used to disable hours and turns for future days
  const isFutureDay = compareAsc(new Date(), parseISO(rowData.date)) < 0;

  const isTimecardEditable =
    payPeriodStatus === PAY_PERIOD_STATUS.OPEN ||
    payPeriodStatus === PAY_PERIOD_STATUS.NOT_OPEN;

  const adjustment = employeeTimecardObj.items[0].adjustment;

  //schedule variance hours to be displayed on screen.
  const scheduledVarianceHours = scheduledVariance({
    scheduledHours: Number(rowData.scheduled.hours),
    approvedHours: rowData.approved.hours,
    workedHours: Number(rowData.worked.hours),
    isPay: isPay,
  });

  const handleTurnUpdate = (turn: string, columnName: TurnTypes) => {
    updateTimecard({
      type: TIMECARD_UPDATE_ACTIONS.TURNS,
      payload: {
        rowIndex: rowIndex,
        turn: turn,
        columnName: columnName,
      },
    });
  };

  const handleInputChange = (target: string, columnName: HourTypes) => {
    const hours = Number(target);
    const scheduledHoursInput = Number(rowData.scheduled.hours);
    const approvedHoursInput = rowData.approved.hours;
    const workedHoursInput = Number(rowData.worked.hours);
    const timecardHours = {
      scheduledHours:
        columnName === 'scheduledHours' ? hours : scheduledHoursInput,
      approvedHours:
        columnName === 'approvedHours' ? hours : approvedHoursInput,
      workedHours: columnName === 'workedHours' ? hours : workedHoursInput,
      isPay: isPay,
    };
    //calculating schedule variance hours if hour value is changed
    const scheduledVar = scheduledVariance(timecardHours);

    updateTimecard({
      type: TIMECARD_UPDATE_ACTIONS.HOURS,
      payload: {
        rowIndex: rowIndex,
        hours: hours,
        columnName: columnName,
        scheduleVariance: scheduledVar,
      },
    });
  };
  //TODO:List of all PTO paycodes. This should be dynamic and added from API
  const PTO_PAYCODES_List = [
    'P1',
    'P2',
    'P3',
    'P4',
    'P5',
    'P6',
    'P7',
    'P8',
    'P0',
    'VH',
    'VD',
    'PH',
    'PD',
  ];
  // This checks if PTO paycode is selected on Timecard paycode dropdown.
  const isPtoPaycodeSelected: boolean = PTO_PAYCODES_List.includes(
    rowData.scheduleVariance.payCode !== null
      ? String(rowData.scheduleVariance.payCode)
      : ''
  );

  const onSelectPaycode = (value: string) => {
    updateTimecard({
      type: TIMECARD_UPDATE_ACTIONS.PAYCODE,
      payload: {
        rowIndex: rowIndex,
        paycode: value,
      },
    });
  };

  //For worked to be editable: feature should be scheduling + PayPeriod- Open and status should not be approved

  const isWorkedEditable =
    feature !== 'pay' &&
    payPeriodStatus === PAY_PERIOD_STATUS.OPEN &&
    status !== TIMECARD_STATUS.APPROVED &&
    !isTimecardDisabled;

  //For Approved to be editable:  feature should be pay + PayPeriod- 'open' + status should not be 'approved' and 'approved-adjustment' + user should have access

  const isApprovedEditable: boolean =
    (payPeriodStatus === PAY_PERIOD_STATUS.OPEN ||
      (adjustment !== 0 && adjustment > 0)) &&
    status !== TIMECARD_STATUS.APPROVED &&
    status !== TIMECARD_STATUS.APPROVED_ADJUSTMENT &&
    isPay &&
    !isTimecardDisabled;

  //For Scheduling to be editable: feature should be scheduling + PayPeriod- 'open' && 'not-open' + status should not be approved + user should have access

  const isScheduleEditable =
    //( if user is on 'scheduling' screen and (Pay period Status status is 'open' or 'not open') and timecard status is not 'approved')
    ((!isPay &&
      (payPeriodStatus === PAY_PERIOD_STATUS.NOT_OPEN ||
        payPeriodStatus === PAY_PERIOD_STATUS.OPEN) &&
      status !== TIMECARD_STATUS.APPROVED) ||
      //or (if user is on 'non union pay' screen and adjustment is grater then 0 and timecard status is 'unapproved adjustment')
      (isPay &&
        adjustment !== 0 &&
        adjustment > 0 &&
        (status === TIMECARD_STATUS.UNAPPROVED_ADJUSTMENT ||
          status === TIMECARD_STATUS.OPEN_ADJUSTMENT))) &&
    //and timecard is not disabled
    !isTimecardDisabled;

  const handleClearShift = () => {
    const getColumnName = () => {
      if (feature === 'pay') return 'approvedHours';
      else if (isWorkedEditable) return 'workedHours';
      else return 'scheduledHours';
    };
    const columnName = getColumnName();

    const scheduledHoursInput = Number(rowData.scheduled.hours);
    const approvedHoursInput = rowData.approved.hours;
    const workedHoursInput = Number(rowData.worked.hours);
    const timecardHours: ScheduleVarianceProps = {
      scheduledHours:
        columnName === 'scheduledHours' || columnName === 'workedHours'
          ? 0
          : scheduledHoursInput,
      approvedHours: columnName === 'approvedHours' ? 0 : approvedHoursInput,
      workedHours: columnName === 'workedHours' ? 0 : workedHoursInput,
      isPay: isPay,
    };

    const scheduleVariance = scheduledVariance(timecardHours);
    updateTimecard({
      type: TIMECARD_UPDATE_ACTIONS.CLEAR_SHIFT,
      payload: {
        rowIndex: rowIndex,
        columnName: columnName,
        scheduleVariance: scheduleVariance,
      },
    });
  };

  /**
   * Update the comment added by user in timecard state
   * @param comment The text entered by user.
   */
  const handleCommentUpdate = (comment: string) => {
    updateTimecard({
      type: TIMECARD_UPDATE_ACTIONS.COMMENTS,
      payload: { rowIndex: rowIndex, comment: comment },
    });
  };

  const timecardHoursValidations = ({ hours, turn }: TimecardEfforts) => {
    return (
      negativeHoursTurn({ hours, turn }) &&
      partialHours(hours) &&
      totalTurnHours({ hours, turn })
    );
  };

  const noHourTurnValidation = ({ hours, turn }: TimecardEfforts): boolean => {
    return hoursAndTurn({
      turn: turn,
      hours: hours,
    });
  };

  const isApprovedTurnEmpty = !noHourTurnValidation(rowData.approved);
  const isWorkedTurnEmpty = !noHourTurnValidation(rowData.worked);
  const isScheduledTurnEmpty = !noHourTurnValidation(rowData.scheduled);

  const isApprovedHoursInvalid = !timecardHoursValidations(rowData.approved);
  const isScheduleHoursInvalid = !timecardHoursValidations(rowData.scheduled);
  const isWorkedHoursInvalid = !timecardHoursValidations(rowData.worked);

  const clearShiftStatus: TimecardStatusCode[] = [
    TIMECARD_STATUS.OPEN_ADJUSTMENT,
    TIMECARD_STATUS.UNAPPROVED_ADJUSTMENT,
  ];

  //Check if three dots menu on row should be enabled
  const isMoreOptionsEnabled: boolean = isPay
    ? (payPeriodStatus === PAY_PERIOD_STATUS.OPEN ||
        (adjustment !== 0 && clearShiftStatus.includes(status))) &&
      status !== TIMECARD_STATUS.APPROVED
    : adjustment === 0 &&
      (payPeriodStatus === PAY_PERIOD_STATUS.OPEN ||
        payPeriodStatus === PAY_PERIOD_STATUS.NOT_OPEN) &&
      status !== TIMECARD_STATUS.APPROVED;

  return {
    handleInputChange,
    handleTurnUpdate,
    onSelectPaycode,
    scheduledVarianceHours,
    isTimecardEditable,
    handleClearShift,
    handleCommentUpdate,
    isApprovedHoursInvalid,
    isScheduleHoursInvalid,
    isWorkedHoursInvalid,
    isApprovedTurnEmpty,
    isWorkedTurnEmpty,
    isScheduledTurnEmpty,
    isMoreOptionsEnabled,
    isFutureDay,
    isWorkedEditable,
    isApprovedEditable,
    isScheduleEditable,
    isPtoPaycodeSelected,
  };
};

export default useTimecardRowDetails;
