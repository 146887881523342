import { SearchInput } from '@uss/react-components';
import Page from 'components/Layouts/Page';
import useDebounceSearch from '../../../timecards/hooks/useDebounceSearch';
import React, { useEffect, useState } from 'react';
import Sort from './Sort';
import { usePaycodeListStore } from 'features/paycode/state/store';

export const SearchSortFilter = () => {
  const store = usePaycodeListStore();

  const [searchTerm, setSearchTerm] = useState(store.params.search);

  const debounceSearch = useDebounceSearch(searchTerm ? searchTerm : '', 500);
  useEffect(() => {
    store.setSearch(debounceSearch ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value);
    store.setSearch(e.target.value);
  };
  return (
    <Page.Section bg="gray">
      <div className="flex flex-row justify-between flex-wrap">
        <div className="w-full sm:w-10/12">
          <div className="w-full mb-2 sm:mb-0 sm:w-4/12 ">
            <SearchInput
              label="Search"
              id="search"
              value={searchTerm}
              autoCompleteFlag={searchTerm ? true : false}
              selectedFlag={searchTerm ? true : false}
              onClose={() => {
                setSearchTerm('');
                store.setClearSearch();
              }}
              onChange={(e) => handleSearchChange(e)}
              placeholder="Search"
            />
          </div>
        </div>
        <div className="flex items-center justify-end w-full sm:w-1/6">
          <Sort />
        </div>
      </div>
    </Page.Section>
  );
};
