import { TimecardTotal } from 'features/timecards/types';
import React from 'react';

const TimecardTotalHrsCard = ({ totals }: { totals: TimecardTotal }) => {
  return (
    <div className="bg-accent2-500 px-4 py-4 my-4  rounded-lg text-white block">
      <div className="flex flex-row justify-between mb-4">
        <div>
          <span className="text-lg">Total Hours</span>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-y-4 mb-4">
        <div className="flex flex-col justify-start">
          <span className="text-xs">Scheduled Hrs</span>
          <span className="text-sm">{totals.scheduled}</span>
        </div>
        <div className="flex flex-col justify-start">
          <span className="text-xs  ">Worked Hrs</span>
          <span className="text-sm">{totals.worked}</span>
        </div>
        <div className="flex flex-col justify-start">
          <span className="text-xs ">Approved Hrs</span>
          <span className="text-sm">{totals.approved}</span>
        </div>
        <div className="flex flex-col justify-start">
          <span className="text-xs ">Schedule Variance Hrs</span>
          <span className="text-sm">{totals.scheduleVariance}</span>
        </div>
        <div className="flex flex-col justify-start">
          <span className="text-xs ">Paid Not Worked Hrs</span>
          <span className="text-sm">{totals.paidHoursNotWorked}</span>
        </div>
        <div className="flex flex-col justify-start">
          <span className="text-xs ">Total Payable Hrs</span>
          <span className="text-sm">
            {totals.approved + totals.paidHoursNotWorked}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TimecardTotalHrsCard;
