export interface CreateMileageRequest {
  ussID: string;
  miles: number;
  travelDate: string;
}

export interface MileageDetails {
  mileageDetailsId: string;
  ussId: string;
  firstName: string;
  lastName: string;
  displayName: string;
  payrollNumber: number;
  payrollLocation: string;
  payrollNameId: string;
  locationDescription: string;
  miles: number;
  travelDate: string;
  requestedDate: string;
  status: MileageStatusTypes;
}

export interface MileageDetailsResponse {
  items: MileageDetails[];
  page: number;
  pageSize: number;
  totalCount: number;
}

export type MileageTabStatus = 'PENDING' | 'COMPLETED';

export interface GetMileageQueryParams {
  payrollLocation?: string;
  status?: MileageTabStatus;
  travelDate?: string;
  page?: string;
  pageSize?: string;
  context: 'my-mileage' | 'all-mileage' | null;
}

export interface RemoveMileageQueryParams {
  mileageDetailsId: string;
}

export interface UpdateMileageField {
  mileageDetailsId: string;
  status: MileageStatusTypes;
}

export interface UpdateMileageRequest {
  items: UpdateMileageField[];
}

export interface MileageActionPayload {
  actionStatus: MileageStatusTypes;
  payload: UpdateMileageRequest;
}

export type MileageActionsVal = 'APPROVED' | 'DENIED';

export type MileageStatusTypes =
  | 'PENDING'
  | 'APPROVED'
  | 'DENIED'
  | 'PAID'
  | 'COMPLETED';

export const MileageStatusTypeVal = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  DENIED: 'DENIED',
  PAID: 'PAID',
  COMPLETED: 'COMPLETED',
};
