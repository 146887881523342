import { Org, OrgList } from 'features/org/types';

import { useCrewPtoStore } from 'features/paid-time-off/state';
import { SelectDataType } from 'features/paid-time-off/types';
import { useEffect } from 'react';
import CrewDateFilter from './CrewDatePicker';
import { EmployeeMultiSelect } from './EmployeeMultiSelect';
import { useOrgMembers } from 'features/org/api/getOrgMembers';
import { UseQueryResult } from '@tanstack/react-query';
import OrgFilters from 'components/OrgFilters/OrgFilters';

interface CrewPtoFilterProps {
  plants: UseQueryResult<OrgList>;
  payrollLocations: Org[];
  divisions: Org[];
  orgs: UseQueryResult<OrgList>;
  setEmpCount: (val: number) => void;
}

const CrewPtoFilter = ({
  plants,
  payrollLocations,
  divisions,
  orgs,
  setEmpCount,
}: CrewPtoFilterProps) => {
  const store = useCrewPtoStore();

  function membersToFetch(): string {
    if (store.selectedCrew !== 'all' && store.selectedCrew !== null) {
      return store.selectedCrew.id;
    }

    if (
      store.selectedDepartment !== 'all' &&
      store.selectedDepartment !== null
    ) {
      return store.selectedDepartment.id;
    }
    if (store.selectedDivision !== null) {
      return store.selectedDivision.id;
    }
    return '';
  }

  /** useOrgMembers TEST ***************************** */
  //REFACTOR: Update employee List to use this
  const { data: members, status } = useOrgMembers({
    orgId: membersToFetch(),
    options: {
      sortBy: 'lastName',
    },
  });

  //** ***************************************** */

  // REFACTOR: Refactor the employee logic
  const employees: SelectDataType[] = members
    ? members.items
        .filter((e) => e.empClass === '3' || e.empClass === '6')
        .map(({ displayName, ussId }) => ({
          label: displayName ?? '',
          value: ussId.toString(),
        }))
    : [];

  //Setting total employee count into state variable
  useEffect(() => {
    if (employees.length > 0) {
      setEmpCount(employees.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees]);

  const onEmployeeChange = (value: string[]) => {
    store.updateEmployees(value);
    store.setStatus('all');
  };

  useEffect(() => {
    if (store.selectedPlant === null && plants.status === 'success') {
      store.setPlant(plants.data.items[0]);
    }

    if (store.selectedPayrollLocation === null && orgs.status === 'success') {
      store.setPayrollLocation(payrollLocations[0]);
    }

    if (
      store.selectedPlant &&
      store.selectedPayrollLocation &&
      store.selectedDivision === null &&
      divisions.length > 0
    ) {
      store.setDivision(divisions[0]);
    }
  });

  return (
    <>
      <p className="text-base mb-2">Select to view PTO:</p>

      <OrgFilters
        plants={plants.data?.items ? plants.data.items : []}
        orgs={orgs.data?.items ? orgs.data.items : []}
        selectedOrgs={{
          selectedPlant: store.selectedPlant,
          selectedPayrollLocation: store.selectedPayrollLocation,
          selectedDivision: store.selectedDivision,
          selectedDepartment: store.selectedDepartment,
        }}
        status={orgs.status}
        plantStatus={plants.status}
      >
        <OrgFilters.Plant
          selectedItem={store.selectedPlant}
          handleSelect={store.setPlant}
        />
        <OrgFilters.Location
          selectedItem={store.selectedPayrollLocation}
          handleSelect={store.setPayrollLocation}
        />
        <OrgFilters.Division
          selectedItem={store.selectedDivision}
          handleSelect={store.setDivision}
        />
        <OrgFilters.Department
          selectedItem={store.selectedDepartment}
          handleSelect={store.setDepartment}
        />
        <OrgFilters.Crew
          selectedItem={store.selectedCrew}
          handleSelect={store.setCrew}
        />
        <EmployeeMultiSelect
          enable={status === 'success' && employees.length > 0}
          selectedEmployee={store.ussids}
          employees={employees}
          status={status}
          onSelect={onEmployeeChange}
        />
      </OrgFilters>

      <div className="flex items-center mt-2 sm:mt-4 lg:mt-4 justify-between lg:justify-start">
        <div className="w-full sm:w-1/4 lg:w-1/5 mb-4 lg:mb-0 relative mr-2 last-of-type:mr-0">
          <CrewDateFilter />
        </div>
      </div>
    </>
  );
};
export default CrewPtoFilter;
