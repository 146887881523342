import { Button } from '@uss/react-components';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
interface EmployeeCarousalProps {
  currentIndex: number;
  totalResults: number;
  onClickLeft: () => void;
  onClickRight: () => void;
}
const EmployeeCarousal = ({
  currentIndex,
  totalResults,
  onClickLeft,
  onClickRight,
}: EmployeeCarousalProps) => {
  return (
    <div
      className="flex items-center justify-center sm:justify-end w-full "
      data-testid="employee-carousal"
    >
      <Button
        variant="icon"
        startIcon={
          <BsChevronLeft
            size="1.25rem"
            className={`${
              currentIndex === 0 ? 'uss-text-grey-700' : 'uss-text-primary-400'
            } uss-text-base uss-cursor-pointer`}
          />
        }
        onClick={onClickLeft}
        disabled={currentIndex === 0}
        data-testid="decrement-icon"
      />

      <span className="mx-2 text-sm" data-testid="employee-results">
        Employee {currentIndex + 1} of {totalResults}
      </span>

      <Button
        variant="icon"
        startIcon={
          <BsChevronRight
            size="1.25rem"
            className={`${
              currentIndex === totalResults - 1
                ? 'uss-text-grey-700'
                : 'uss-text-primary-400'
            } uss-text-base uss-cursor-pointer`}
          />
        }
        onClick={onClickRight}
        disabled={currentIndex === totalResults - 1}
        data-testid="increment-icon"
      />
    </div>
  );
};

export default EmployeeCarousal;
