import Page from 'components/Layouts/Page';
import { NavLink, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { AnnualTotals } from 'features/paid-time-off/components/AnnualTotals/AnnualTotals';
import EmployeeProfile from 'features/people/components/EmployeeProfile';
import { appNewDate } from 'utils/appNewDate';
import AddPTOForm from 'features/paid-time-off/components/Forms/AddPTOForm';
import { PTO_ADD_CONTEXT } from 'features/paid-time-off/constants';

const AddPTO = () => {
  //get empId from params
  const { id: empId = '' } = useParams<'id'>();

  return (
    <Page
      breadcrumbs={[
        <NavLink
          key="bc-1"
          className="flex flex-row justify-center items-center"
          to={`/paid-time-off`}
        >
          PTO - Vacation
        </NavLink>,
        <NavLink key="bc-2" to="/paid-time-off/employees">
          Employee Search (NU)
        </NavLink>,
        <NavLink key="bc-3" to={`/paid-time-off/employees/${empId}`}>
          Employee PTO (NU)
        </NavLink>,
      ]}
      heading={'Add PTO/Blackout'}
    >
      <Page.Section>
        <div className="my-2">
          <EmployeeProfile id={empId} />
        </div>
        <AnnualTotals id={empId} year={format(appNewDate(), 'yyyy')} />
      </Page.Section>
      <Page.Section>
        <AddPTOForm
          defaultValues={{
            paycode: '',
            status: 'approved',
            chargeYear: format(appNewDate(), 'yyyy'),
            ussId: '',
            notes: '',
            excludeWeekends: false,
            excludeHolidays: false,
            startDate: '',
            endDate: null,
            ptoOptionType: '',
            paycodeDescription: '',
            context: PTO_ADD_CONTEXT.EMP_PTO
          }}
        />
      </Page.Section>
    </Page>
  );
};

export default AddPTO;
