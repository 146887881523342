import LabeledText from 'components/Typography/LabeledText';
import { PayPeriod } from 'features/pay-periods';
import { ShiftAssignment as ShiftAssignmentObj } from 'features/crew-maintenance/types';
import pendingShiftAssignmentText from 'features/crew-maintenance/utilities/getShiftAssignmentText';

export const PendingShiftAssignment = ({
  payPeriod,
  pendingShiftAssignment,
}: {
  payPeriod: PayPeriod;
  pendingShiftAssignment: ShiftAssignmentObj;
}) => {
  return (
    <div>
      <LabeledText
        label="Pending Shift Assignment"
        className="w-full md:w-7/12 mb-2 pr-4 md:mb-0"
      >
        {pendingShiftAssignmentText(pendingShiftAssignment, payPeriod)}
      </LabeledText>
    </div>
  );
};
