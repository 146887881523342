import { useMutation } from '@tanstack/react-query';
import { SecurityPayload, SecurityRecord } from 'features/security';
import { axios } from 'services/api/axios';
import { notify } from 'components/Notification/useNotification';
import { queryClient } from 'services/api/react-query';
import { WMSError } from 'types/common';

const addEmployeeSecurity = (
  ussId: string,
  body: SecurityPayload
): Promise<SecurityRecord> => {
  return axios.post(`/v1/people/${ussId}/security`, body);
};

/**
 * Endpoint to create a new security for an employee
 * https://cappsq.psc.uss.com/SBWM-DOCUMENT/swagger-ui/index.html?urls.primaryName=sbwm-people#/security-controller/addSecurity
 * @param ussId USS Id of the person
 * @param body create security body
 * @returns Promise
 */

export const useAddEmployeeSecurity = (ussId: string) => {
  return useMutation<SecurityRecord, WMSError, SecurityPayload>({
    mutationFn: (security: SecurityPayload) =>
      addEmployeeSecurity(ussId, security),

    onSuccess: async (data) => {
      notify.success({
        message: 'Security assigned successfully.',
      });
      await queryClient.refetchQueries({
        queryKey: ['employee-securities'],
        type: 'active',
      });
      queryClient.removeQueries({ queryKey: ['crew-members'] });
    },
  });
};
