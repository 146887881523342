import { MODEL_STATUS } from '../constants';
import { ModelStatus } from '../types';

export const getModelStatusText = (status: ModelStatus) => {
  switch (status) {
    case MODEL_STATUS.ACTIVE: {
      return 'Active';
    }
    case MODEL_STATUS.ARCHIVED: {
      return 'Archived';
    }
    case MODEL_STATUS.CHECKED_OUT: {
      return 'Checked out';
    }
    case MODEL_STATUS.PENDING: {
      return 'Pending';
    }
    default:
      return '';
  }
};
