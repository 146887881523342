import { useQuery } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { PayPeriodPulloff } from 'features/paypulloff/types';
import { PAY_PERIOD_STATUS } from 'features/pay-periods/types';

const get = (payrollName: string): Promise<PayPeriodPulloff> => {
  return axios.get(`/v1/payrolls/${payrollName}/pay-pulloff`);
};

export const usePulloff = (payrollName: string) => {
  return useQuery<PayPeriodPulloff>({
    queryKey: ['pulloff', { payrollName: payrollName }],
    queryFn: () => get(payrollName),
    staleTime: 5000,
    gcTime: 5000,
    refetchInterval: (query) => {
      if (
        query.state.data?.total.payPeriodStatus ===
        PAY_PERIOD_STATUS.IN_PROGRESS
      ) {
        return 5000;
      }
      return false;
    },
    enabled: payrollName.length > 0,
  });
};
