import { Avatar, Button, Checkbox, Drawer } from '@uss/react-components';
import { Person } from 'features/people/types';
import { useState } from 'react';
import { SecurityRecord } from 'features/security/types';
import { getInitials, avatarColor } from 'utils';
import useModal from 'components/Modals/use-modal';
import { formatCrewKey } from 'utils/formatCrewKey';
import { Org } from 'features/org';
import Alert from 'components/Alert/Alert';
import { format, nextSaturday } from 'date-fns';
import { HandleMove } from 'features/crew-maintenance/types';
import { CrewSelectFilter } from '../CrewSelectFilter';

interface MoveCrewProps {
  isOpen: boolean;
  onClose: () => void;
  onMove: HandleMove;
  fromCrew: SecurityRecord;
  employeeCrewIds: string[] | undefined;
  employee: Person;
}

export const MoveCrew = ({
  isOpen,
  onClose,
  onMove,
  fromCrew,
  employeeCrewIds,
  employee,
}: MoveCrewProps) => {
  const [selectedCrew, setSelectedCrew] = useState<Org | null>(null);
  const [futureSchedules, setFutureSchedules] = useState(false);

  const modal = useModal();

  const handleMoveCrew = () => {
    setSelectedCrew(selectedCrew);
    setFutureSchedules(futureSchedules);
    modal.openModal({
      title: 'Move Crew Assignment',
      type: 'danger',
      onConfirm: async () => {
        await onMove(fromCrew, futureSchedules, selectedCrew?.id);
        modal.closeModal();
      },
      onCancel: () => modal.closeModal(),
      labels: {
        cancel: 'Cancel',
        confirm: 'Move',
      },
      children: <MoveModalMessage />,
    });
  };

  const MoveModalMessage = () => {
    return (
      <div>
        <p className="text-sm text-gray-700">
          Are you sure you want to move {employee.firstName}&nbsp;
          {employee.lastName} from crew {fromCrew.crew} to crew&nbsp;
          {selectedCrew?.crew}?
        </p>
        {!futureSchedules && (
          <p className="text-sm text-gray-700 mt-4">
            This will also delete any future schedules for this employee for the
            old crew.
          </p>
        )}
        {futureSchedules && (
          <p className="text-sm text-gray-700 pt-4  ">
            This will also move any future schedules for this employee from the
            old crew to the new crew.
          </p>
        )}
      </div>
    );
  };

  const weekStartDate = format(
    nextSaturday(new Date(format(new Date(), 'MMM dd, yyyy'))),
    'MMM dd, yyyy'
  );

  return (
    <>
      <Drawer
        placement={window.innerWidth > 768 ? 'right' : 'bottom'}
        title="Move"
        isOpen={isOpen}
        onClose={(): void => {
          onClose();
        }}
        closeButton={window.innerWidth > 768 ? false : true}
        className="w-full sm:w-1/2 md:w-1/3 xl:w-1/4 2xl:w-1/5 md:top-0 top-18 md:rounded-t-none rounded-t-md"
      >
        <div className="flex-grow">
          {
            <div className="flex items-center w-full border-b  border-gray-300 mb-5 pb-4">
              <Avatar color={avatarColor(employee.ussId)} size="medium">
                {getInitials(employee.firstName, employee.lastName)}
              </Avatar>
              <div className="flex flex-col flex-wrap flex-grow ml-4">
                <p
                  data-testid="display-name"
                  className="text-gray-700 text-lg font-semibold text-left line-clamp-1"
                >
                  {employee.displayName ? employee.displayName : '-'}
                </p>
                <p
                  data-testid="ussid"
                  className="text-gray-700 text-sm text-left"
                >
                  {employee.ussId ? employee.ussId : '-'}
                </p>
              </div>
            </div>
          }
          <div className="flex flex-col">
            <span className="text-xs text-gray-700 font-normal">From Crew</span>
            <span
              data-testid="from-crew-key"
              className="text-gray-900 text-base font-semibold mb-5"
            >
              {formatCrewKey(
                fromCrew.location,
                fromCrew.division,
                fromCrew.department,
                fromCrew.crew
              )}
            </span>

            <span className="text-xs text-gray-700 font-normal mb-0.5">
              To Crew
            </span>
            <CrewSelectFilter
              selectedCrew={selectedCrew}
              setSelectedCrew={setSelectedCrew}
              employeeCrewIds={employeeCrewIds}
              employee={employee}
            />

            <div className="mt-5">
              <Checkbox
                id="move-future-schedules"
                name="shifts"
                label="Move Future Schedules"
                checked={futureSchedules}
                onChange={() => setFutureSchedules(!futureSchedules)}
              />
            </div>

            <Alert variant="info">
              <p data-testid="info-1">
                {' '}
                Move will be effective in upcoming pay period starting
                <b>{' ' + weekStartDate}</b><br/>
                 All future PTOs will be moved as well. 
              </p>
              <p data-testid="info-2">
                For current pay period, use Add Temp Employee.
              </p>
            </Alert>
          </div>
        </div>
        <Drawer.Controls>
          <Button
            className="md:mb-0 mb-2 w-full"
            variant="outline"
            onClick={(): void => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="md:ml-4 w-full"
            disabled={!selectedCrew}
            onClick={handleMoveCrew}
          >
            Move
          </Button>
        </Drawer.Controls>
      </Drawer>
    </>
  );
};
