import { ReviewStatus } from '../types';
import getCurrentView from './getCurrentView';

const isPerfReviewButtonDisabled = (
  pathname: string,
  reviewStatus: ReviewStatus | null
) => {
  const { isEmployeeView } = getCurrentView(pathname);

  const isDisabledForEmployee =
    isEmployeeView &&
    reviewStatus !== ReviewStatus.EMP_ACKNOWLEDGEMENT &&
    reviewStatus !== ReviewStatus.FINAL_REVIEW &&
    reviewStatus !== ReviewStatus.RETAINED &&
    reviewStatus !== ReviewStatus.TERMINATED;

  return reviewStatus === null
    ? true
    : reviewStatus === ReviewStatus.NOT_OPEN || isDisabledForEmployee;
};
export default isPerfReviewButtonDisabled;
