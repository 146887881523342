/**
 * @description Must not be Overtime pay code if Payroll Indicator Payable is checked
 * @returns
 */

import { PaycodeType, PayrollGroups } from '../types';

export const PayableOvertimeCheck = (
  data: PayrollGroups[],
  type: PaycodeType
) => {
  let ispayableOvertimeCheck = true;

  data.map((payroll) =>
    payroll.payrollIndicators.map((e) => {
      if (e.name === 'payable' && e.value && type === 'overtime') {
        ispayableOvertimeCheck = false;
      }
      return ispayableOvertimeCheck;
    })
  );
  return ispayableOvertimeCheck;
};
