/**
 * @description .(Save, Save & Approve)
 * on 8-HR or 8-DAY HOUR employee, and enters less than 8 Worked Hours and one of the PTO codes listed
 * on 12-HR AWS employee, and enters less than 10 Worked Hours and one of the PTO codes listed
 * @returns
 */

import { SHIFT_ASSIGNMENT, ShiftAssignment } from 'features/people/types';

export const ptoPaycodeAndHours = (
  shift: ShiftAssignment | null,
  hours: number | null,
  paycode: string | null
) => {
  const pto_8hr = ['P1', 'P2', 'P3', 'P4', 'P5', 'P6', 'P7', 'P8'];
  const pto_8dr = ['P4', 'P8', 'PX'];
  const pto_12hr = ['P0', 'PX'];
  if (hours && paycode && shift) {
    switch (shift) {
      case SHIFT_ASSIGNMENT.EIGHT_HOUR:
        return pto_8hr.includes(paycode) && hours < 8;
      case SHIFT_ASSIGNMENT.EIGHT_DAY_HOUR:
        return pto_8dr.includes(paycode) && hours < 8;
      case SHIFT_ASSIGNMENT.TWELVE_HOUR:
        return pto_12hr.includes(paycode) && hours < 10;
    }
  }
  return false;
};
