import { useMutation } from '@tanstack/react-query';
import { SecurityPayload } from 'features/security';
import { axios } from 'services/api/axios';
import { notify } from 'components/Notification/useNotification';
import { queryClient } from 'services/api/react-query';

type UpdateSecurityPayload = Pick<
  SecurityPayload,
  'orgRoleId' | 'orgId' | 'role' | 'primary' | 'sortOrder' | 'permissions'
>;
const patchUpdateSecurity = (
  ussId: string,
  body: UpdateSecurityPayload
): Promise<{
  success: boolean;
  message: string;
}> => {
  return axios.patch(`/v1/people/${ussId}/security`, body);
};
/**
 * Endpoint to muliple user security updates
 *https://cappsq.psc.uss.com/SBWM-DOCUMENT/swagger-ui/index.html?urls.primaryName=sbwm-people#/security-controller/batchUpdateUserSecurity
 * @param ussId USS Id of the person
 * @param body update security body
 * @returns Promise
 */

export const usePatchUpdateSecurity = (orgId: string) => {
  return useMutation({
    mutationFn: (body: UpdateSecurityPayload) =>
      patchUpdateSecurity(orgId, body),

    onSuccess: async () => {
      notify.success({
        message: 'Security updated successfully.',
      });
      await queryClient.refetchQueries({
        queryKey: ['employee-securities'],
        type: 'active',
      });
    },
  });
};

//Update blackout permission
export const useUpdateSecurityForBlackout = (orgId: string) => {
  return useMutation({
    mutationFn: (body: UpdateSecurityPayload) =>
      patchUpdateSecurity(orgId, body),

    onSuccess: () => {
      notify.success({
        message: 'You have updated the security successfully.',
      });
    },
  });
};
