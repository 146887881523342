import { axios } from 'services/api/axios';
import { useQuery } from '@tanstack/react-query';
import { ProbationaryEmployeesSummary } from '../types';

export const useProbationaryEmployeesSummary = () => {
  return useQuery({
    queryFn: () => probationary_employees_summary(),
    queryKey: ['probationary-employee-summary'],
  });
};

const probationary_employees_summary =
  (): Promise<ProbationaryEmployeesSummary> => {
    return axios.get(`/v1/prob-emp/summary`);
  };
