import Page from 'components/Layouts/Page';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { ORG_TYPE } from 'features/org';
import { useOrgDetails } from 'features/org/api';
import useUserRoles from 'hooks/useUserRoles';
import { ROLES } from 'features/roles';
import Tabs from 'components/Tabs/Tabs';
import { DepartmentDetails, OrgDetailsCard } from 'features/org/components';
import { History, HISTORY_ENTITY } from 'features/history';

const Department = () => {
  //get id from params
  const { id = '' } = useParams<'id'>();

  //get selected division details
  const { data, status } = useOrgDetails(id);

  const { roles } = useUserRoles();

  const tabLinks = [
    {
      label: `Details`,
      element: <DepartmentDetails />,
    },
  ];

  //only Super_Admin, Super_Payroll_Admin, Payroll_Admin and T&A Coordinator are able to view Department History
  if (
    roles.includes(ROLES.SUPER_ADMIN) ||
    roles.includes(ROLES.SUPER_PAYROLL_ADMIN) ||
    roles.includes(ROLES.PAYROLL_ADMIN) ||
    roles.includes(ROLES.T_A_PLANT_COORDINATOR)
  ) {
    tabLinks.push({
      label: 'History',
      element: (
        <History
          id={id}
          entity={HISTORY_ENTITY.DEPARTMENT}
          noRecordError="No history records found for the selected department"
          descLabel="Comments"
        />
      ),
    });
  }

  return (
    <Page
      breadcrumbs={[
        <NavLink key="bc-1" to="/admin">
          Admin
        </NavLink>,
        <NavLink key="bc-2" to="/admin/org-structure/divisions">
          Org Structure Divisions
        </NavLink>,
        <NavLink
          key="bc-3"
          to={`/admin/org-structure/${data?.parentOrgId ?? ''}/departments`}
        >
          Org Structure Departments
        </NavLink>,
      ]}
      heading="Department Details"
    >
      {status === 'success' && (
        <>
          <Page.Section>
            <OrgDetailsCard data={data} type={ORG_TYPE.DEPARTMENT} />
          </Page.Section>
          <Page.Section>
            <Tabs tabLinks={tabLinks} />
            <Outlet />
          </Page.Section>
        </>
      )}
    </Page>
  );
};

export default Department;
