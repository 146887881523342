import { findIndex, merge } from 'lodash';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { AreaManagerSlice, AreaManagerSliceType } from './area-manager';
import { ReviewerSlice, ReviewerSliceType } from './reviewer';
import { HrLrSlice, HrLrSliceType } from './hr-lr';
import {
  BargainingUnitTotalSliceType,
  BargainingUnitTotalSlice,
} from './bargaining-unit-total';
export interface ProbationaryEmployeesStore {
  areaManager: AreaManagerSliceType;
  reviewer: ReviewerSliceType;
  hrLr: HrLrSliceType;
  bargainingUnitTotal: BargainingUnitTotalSliceType;
}
export const toggleEmployee = (value: string, selectedEmployees: string[]) => {
  const i = findIndex(selectedEmployees, (ussId) => ussId === value);
  if (i >= 0) {
    selectedEmployees.splice(i, 1);
  } else {
    selectedEmployees.push(value);
  }
  return selectedEmployees;
};

export const useProbationaryEmployeesStore =
  create<ProbationaryEmployeesStore>()(
    devtools(
      immer(
        persist(
          (...args) => ({
            areaManager: { ...AreaManagerSlice(...args) },
            reviewer: { ...ReviewerSlice(...args) },
            hrLr: { ...HrLrSlice(...args) },
            bargainingUnitTotal: { ...BargainingUnitTotalSlice(...args) },
          }),
          {
            name: 'prob-emp-state',
            storage: createJSONStorage(() => sessionStorage),
            merge: (persistedState, currentState) =>
              merge(persistedState, currentState),
          }
        )
      )
    )
  );
