import { PAY_PERIOD_STATUS, PayPeriodStatus } from 'features/pay-periods/types';
import {
  EmployeeTimecard,
  TIMECARD_STATUS,
  TimecardStatusCode,
} from 'features/timecards/types';
import { useNonUnionStore } from './useNonUnionStore';
/**
 * Hook that accepts a timecard and determines the status of the timecard.  Returns various Boolean values that tell you the different states of the timecard.
 *
 * @param timecard Timecard that you wish to get status values for.
 * @returns Boolean values for different timecard statuses
 */

const useTimecardStatus = (timecard: EmployeeTimecard) => {
  const state = useNonUnionStore();
  const feature = state.feature;
  // isPay returns boolean value. if current page is Pay page is returns true
  const isPay = feature === 'pay';

  //   Original Timecard

  // Timecard status checks to make action buttons visible-
  // For "Save", "Save & Approve" and "Save & Verify" actions on timecard
  const saveVerifyApproveStatus: TimecardStatusCode[] = [
    TIMECARD_STATUS.NOT_VERIFIED,
    TIMECARD_STATUS.VERIFIED,
    TIMECARD_STATUS.UNAPPROVED,
  ];

  // For "Unapprove" action on timecard
  const unapproveStatus: TimecardStatusCode[] = [TIMECARD_STATUS.APPROVED];

  // For "Open for Adjustment" action on timecard
  const openAdjustmentStatus: PayPeriodStatus[] = ['processed', 'closed'];

  // For "Save and Approve Adjustment" action on timecard
  const saveApproveAdjustmentSatus: TimecardStatusCode[] = [
    TIMECARD_STATUS.OPEN_ADJUSTMENT,
    TIMECARD_STATUS.UNAPPROVED_ADJUSTMENT,
  ];

  // For "Unapprove Adjustment" action on timecard
  const unapproveAdjustmentStatus: TimecardStatusCode[] = [
    TIMECARD_STATUS.APPROVED_ADJUSTMENT,
  ];

  // For disableing "Save" and "Save & Verify" actions on Scheduling pages
  const saveDisabledStatus: PayPeriodStatus[] = [
    'closed',
    'processed',
    'in-progress',
    'locked',
  ];
  const saveVerifyDisabledStatus: PayPeriodStatus[] = [
    'closed',
    'in-progress',
    'processed',
    'locked',
    'not-open',
  ];

  const status = timecard.status.code,
    payPeriodStatus = timecard.payPeriodStatus,
    currentPayPeriodStatus = timecard.currentPayPeriodStatus,
    originalTimecard = timecard.adjustment === 0,
    hasAdjustments = timecard.adjustment > 0,
    latestAdjustment =
      timecard.adjustmentVersions[timecard.adjustmentVersions.length - 1];

  // Save
  // - payPeriodStatus - open, not-open
  // - status -> code - not-verified, verified, unapproved

  const canSave = (): boolean => {
    let isSaveEnabled: boolean =
      saveVerifyApproveStatus.includes(status) && originalTimecard;
    if (isPay) {
      isSaveEnabled =
        isSaveEnabled &&
        (payPeriodStatus === PAY_PERIOD_STATUS.OPEN ||
          payPeriodStatus === PAY_PERIOD_STATUS.NOT_OPEN);
    }
    return isSaveEnabled;
  };

  // Save and Approve
  // - payPeriodStatus - Open
  // - status -> code - not-verified, verified, unapproved

  const canSaveApprove = (): boolean => {
    const isSaveApproveEnabled: boolean =
      payPeriodStatus === PAY_PERIOD_STATUS.OPEN &&
      saveVerifyApproveStatus.includes(status);
    return isSaveApproveEnabled;
  };
  const isApproveDisabled: boolean = timecard.id === 'temp';

  // for scheduling Save is active only for payPeriodStatus open/not-open

  const isSaveDisabled = (): boolean => {
    const saveDisabled: boolean =
      !isPay &&
      (status === TIMECARD_STATUS.VERIFIED ||
        saveDisabledStatus.includes(payPeriodStatus));

    return saveDisabled;
  };

  // Save verify is Active only for open payPeriodStatus

  const isSaveVerifyDisabled = (): boolean => {
    const saveVerifyDisabled: boolean =
      !isPay &&
      (status === TIMECARD_STATUS.APPROVED ||
        timecard.id === 'temp' ||
        saveVerifyDisabledStatus.includes(payPeriodStatus));

    return saveVerifyDisabled;
  };

  // Unapprove

  // - adjustment = 0
  // - payPeriodStatus = open
  // - status - code = approved

  const canUnapprove = (): boolean => {
    const isUnapproveEnabled: boolean =
      originalTimecard &&
      payPeriodStatus === PAY_PERIOD_STATUS.OPEN &&
      unapproveStatus.includes(status)
        ? true
        : false;

    return isUnapproveEnabled;
  };

  // Original and Adjustments

  // Open Adjustment

  // - currentPayPeriodStatus - processed, closed

  const canOpenAdjustment = (): boolean => {
    const isLatestAdjustment =
      latestAdjustment > 0
        ? status === TIMECARD_STATUS.APPROVED_ADJUSTMENT
        : true;
    const isOpenForAdjustmentEnabled: boolean =
      openAdjustmentStatus.includes(currentPayPeriodStatus) &&
      timecard.adjustment === latestAdjustment &&
      (timecard.id === 'temp' || isLatestAdjustment)
        ? true
        : false;
    return isOpenForAdjustmentEnabled;
  };

  // Adjustments

  // Save
  // - adjustment > 0
  // - status -> code - open-adjustment, unapproved-adjustment

  const canSaveAdjustment = (): boolean => {
    const isSaveAdjustmentEnabled: boolean =
      hasAdjustments && saveApproveAdjustmentSatus.includes(status)
        ? true
        : false;
    return isSaveAdjustmentEnabled;
  };

  // Adjustment Editable - Save & Approve Adjustment
  // - adjustment > 0
  // - currentPayPeriodStatus = open
  // - status -> code = open-adjustment, unapproved-adjustment

  const canSaveApproveAdjustment = (): boolean => {
    const isSaveApproveAdjustmentEnabled: boolean =
      hasAdjustments &&
      (currentPayPeriodStatus === PAY_PERIOD_STATUS.OPEN ||
        currentPayPeriodStatus === PAY_PERIOD_STATUS.LOCKED) &&
      saveApproveAdjustmentSatus.includes(status)
        ? true
        : false;

    return isSaveApproveAdjustmentEnabled;
  };

  // Adjustment Not Editable - Unapprove Adjustment
  // - adjustment > 0
  // - currentPayPeriodStatus = open, locked
  // - status -> code = approved-adjustment

  const canUnapproveAdjustment = (): boolean => {
    const isUnapproveAdjustmentEnabled: boolean =
      hasAdjustments &&
      (currentPayPeriodStatus === PAY_PERIOD_STATUS.OPEN ||
        currentPayPeriodStatus === PAY_PERIOD_STATUS.LOCKED) &&
      unapproveAdjustmentStatus.includes(status)
        ? true
        : false;

    return isUnapproveAdjustmentEnabled;
  };

  // Save and Verify
  // adjustment = 0
  // - payPeriodStatus -> Open - active
  // payPeriodStatus -> Not- open, closed , processed , locked - disabled
  // - status -> code - not-verified, verified, unapproved

  const canSaveVerify = (): boolean => {
    return originalTimecard && saveVerifyApproveStatus.includes(status)
      ? true
      : false;
  };

  return {
    canSave,
    canUnapprove,
    canSaveApprove,
    isApproveDisabled,
    canOpenAdjustment,
    canSaveAdjustment,
    canSaveApproveAdjustment,
    canUnapproveAdjustment,
    canSaveVerify,
    isSaveDisabled,
    isSaveVerifyDisabled,
  };
};

export default useTimecardStatus;
