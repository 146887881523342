import { compareAsc, parseISO } from 'date-fns';
import { PAY_PERIOD_STATUS, PayPeriodStatus } from 'features/pay-periods/types';
import getTimecardStatusDescription from 'features/timecards/utilities/getTimecardStatusDescription';
import { scheduledVariance } from 'features/timecards/utilities/scheduleVariance';
import {
  EmployeeTimecardList,
  TimecardFeature,
  TIMECARD_STATUS,
} from 'features/timecards/types';
import { defaultTimecard } from '../utilities/defaultTimecard';

/**
 * @param dates
 * @param timecards
 * @param isPay
 * @returns Timecard array with 7 records one for each day of the week.
 * If the value for the day is not received from API default values would be assigned.
 */

const generateTimecard = (
  dates: string[],
  timecards: EmployeeTimecardList,
  feature: TimecardFeature
) => {
  const timecardObj = timecards.items[0];

  //Checking is the scheduled timecard is approved. To be used to find timecard type which inturn get schedule variance hours calculated.

  // Getting timecard type. To be used as params for getting schedule variance hours calculated.

  return dates.map((date) => {
    //Checking if we got data for the date from API

    const isPay = feature === 'pay';
    const index = timecardObj.timecard.findIndex((i) => i.date === date);
    const timecard = timecardObj.timecard;

    if (index >= 0) {
      const isFutureDay =
        compareAsc(new Date(), parseISO(timecard[index].date)) < 0;

      const isTimecardApproved =
        timecardObj.status.code === TIMECARD_STATUS.APPROVED ||
        timecardObj.status.code === TIMECARD_STATUS.APPROVED_ADJUSTMENT;
      const isWorkedEditable =
        !isPay &&
        timecardObj.payPeriodStatus === PAY_PERIOD_STATUS.OPEN &&
        !isTimecardApproved;

      const isApprovedEditable =
        isPay &&
        !isFutureDay &&
        timecardObj.payPeriodStatus === PAY_PERIOD_STATUS.OPEN &&
        !isTimecardApproved;

      if (isWorkedEditable && !isFutureDay) {
        //Prepopulating the worked hours if null are received from API for schedule timecard and day is not a future day.

        if (
          timecard[index].worked.hours === null &&
          !timecard[index].scheduleVariance.payCode?.length
        ) {
          timecard[index].worked.hours = timecard[index].scheduled.hours;
        }

        //Prepopulating the worked turns if null are received from API for schedule timecard and day is not a future day.

        if (
          timecard[index].worked.turn === null &&
          !timecard[index].scheduleVariance.payCode?.length
        ) {
          timecard[index].worked.turn = timecard[index].scheduled.turn;
        }
      } else if (isApprovedEditable) {
        //Prepopulating the approved hours if null are received from API for pay timecard and day is not a future day.
        if (timecard[index].approved.hours === null) {
          timecard[index].approved.hours = timecard[index].worked.hours;
        }

        //Prepopulating the approved turns if null are received from API for pay timecard and day is not a future day.
        if (timecard[index].approved.turn === null) {
          timecard[index].approved.turn = timecard[index].worked.turn;
        }
      }
      // Calculating schedule variance hours if timecard is editable
      if (
        (timecardObj.payPeriodStatus === PAY_PERIOD_STATUS.OPEN ||
          timecardObj.payPeriodStatus === PAY_PERIOD_STATUS.NOT_OPEN) &&
        timecardObj.status.code !== TIMECARD_STATUS.APPROVED &&
        timecardObj.status.code !== TIMECARD_STATUS.APPROVED_ADJUSTMENT
      ) {
        timecard[index].scheduleVariance.variance = scheduledVariance({
          scheduledHours: Number(timecard[index].scheduled.hours),
          approvedHours: timecard[index].approved.hours,
          workedHours: Number(timecard[index].worked.hours),
          isPay: isPay,
        });
      }
      timecard[index].context = feature;
      return timecard[index];
    } // setting the default values for the day that are not received from API.
    else
      return {
        id: 'temp',
        date: date,
        elapsedTime: null,
        scheduled: {
          turn: '',
          hours: null,
        },
        worked: {
          turn: '',
          hours: null,
        },
        approved: {
          turn: '',
          hours: null,
        },
        scheduleVariance: {
          payCode: null,
          variance: 0,
        },
        paidHoursNotWorked: null,
        exceptionCode: null,
        exceptionCodeDescription: null,
        comments: '',
        blank: true,
        context: feature,
        ptoDetails: null,
      };
  });
};

const useGenerateTimecard = ({
  employeeTimecard,
  week,
  feature,
  payPeriodStatus,
  dates,
}: {
  employeeTimecard: EmployeeTimecardList | undefined;
  week: string | null;
  feature: TimecardFeature;
  payPeriodStatus: PayPeriodStatus | undefined;
  dates: string[];
}) => {
  const statusDescription = getTimecardStatusDescription(
    payPeriodStatus,
    feature === 'pay'
  );

  const defaultTimecardObj = defaultTimecard(dates);

  //Presetting the properties if the employeeTimecard is an empty list

  if (payPeriodStatus && !employeeTimecard?.items.length) {
    defaultTimecardObj.items[0].payPeriodStatus = payPeriodStatus;
    defaultTimecardObj.items[0].currentPayPeriodStatus = payPeriodStatus;
    defaultTimecardObj.items[0].week = week ? week : '';
    defaultTimecardObj.items[0].status.description = statusDescription;
  }

  const getEmployeeTimecardObj = (
    timecard: EmployeeTimecardList
  ): EmployeeTimecardList => {
    timecard.items[0].timecard = generateTimecard(dates, timecard, feature);
    return timecard;
  };

  const employeeTimecardObj: EmployeeTimecardList =
    payPeriodStatus && employeeTimecard && employeeTimecard.items.length
      ? getEmployeeTimecardObj(employeeTimecard)
      : defaultTimecardObj;

  return { employeeTimecardObj, week };
};

export default useGenerateTimecard;
