import React from 'react';
import NoResults from 'components/NoResults/NoResults';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
type Variants = 'col-grid' | 'row-grid';
interface Props<T> {
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  noResults?: { title: string; description: string };
  isColGrid?: boolean;
  isRowGrid?: boolean;
  variant?: Variants;
}

const CardList = <T,>({
  items,
  renderItem,
  noResults,
  variant = 'col-grid',
}: Props<T>) => {
  if (items.length === 0 && noResults)
    return (
      <NoResults
        title={noResults.title}
        description={noResults.description}
        width={'300'}
        height={'300'}
      />
    );
  if (items.length === 0) return <NoResults width={'300'} height={'300'} />;

  return (
    <div
      className={clsx(
        variant === 'col-grid'
          ? 'grid md:grid-cols-2 xl:grid-cols-3 gap-4'
          : '',
        variant === 'row-grid' ? 'grid md:grid-rows-1 gap-4' : ''
      )}
      data-testid="card-list"
    >
      {items.map((item, index) => (
        <div key={`${uniqueId()}`} data-testid={'card'}>
          {renderItem(item)}
        </div>
      ))}
    </div>
  );
};

export default CardList;
