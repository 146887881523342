import { QueryStatus } from '@tanstack/react-query';
import { Select } from '@uss/react-components';
import { RoleItem } from 'features/roles';
import { RoleList } from 'features/roles/api';

interface Props {
  selectedValue: RoleItem | null;
  selectData: RoleList | undefined;
  dataStatus: QueryStatus;
  onSelect: (value: RoleItem) => void;
  error: boolean;
}

const RoleSelectInput = ({
  selectData,
  selectedValue,
  dataStatus,
  onSelect,
  error,
}: Props) => {
  return (
    <Select
      selected={selectedValue}
      onSelect={onSelect}
      disabled={dataStatus !== 'success'}
    >
      <Select.Button
        label={selectedValue?.roleName ? selectedValue.roleName : 'Select'}
      />
      <Select.Options>
        <Select.Option value={''}> Select </Select.Option>
        {selectData?.items.map((item, itemIdx) => (
          <Select.Option key={itemIdx} value={item}>
            <span>{item.roleName}</span>
          </Select.Option>
        ))}
      </Select.Options>
      {error && <p className="text-sm text-red-600">Please select an option</p>}
    </Select>
  );
};

export default RoleSelectInput;
