import { Button } from '@uss/react-components';
import { BsTrash } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import { useDeleteAdjustment } from 'features/timecards/api/deleteAdjustment';
import {
  EmployeeTimecardList,
  TIMECARD_STATUS,
} from 'features/timecards/types';

const DeleteAdjustment = ({
  setAdjustment,
  employeeTimecardObj,
}: {
  setAdjustment: (value: number | null) => void;
  employeeTimecardObj: EmployeeTimecardList;
}) => {
  const { ussId = '' } = useParams<'ussId'>();

  const timecardId = employeeTimecardObj.items[0].id;

  const deleteAdjustment = useDeleteAdjustment(
    ussId,
    timecardId,
    employeeTimecardObj.items[0].adjustment,
    setAdjustment
  );

  const handleDeleteAdjustment = async () => {
    await deleteAdjustment.mutateAsync();
  };

  return (
    <div className="flex items-center justify-end w-100 md:w-1/4 lg:w-1/5">
      <Button
        onClick={handleDeleteAdjustment}
        variant="text"
        className="text-red-500 font-semibold"
        startIcon={<BsTrash size="1rem" />}
        disabled={
          employeeTimecardObj.items[0].status.code ===
          TIMECARD_STATUS.APPROVED_ADJUSTMENT
        }
      >
        Delete Adjustment
      </Button>
    </div>
  );
};

export default DeleteAdjustment;
