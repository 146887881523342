import React from 'react';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';

type Props = NavLinkProps & { currentselection?: string; external?: boolean };

const SideNavLink = (props: Props) => {
  const { pathname } = useLocation();
  const isScheduleCrewListing =
    props.currentselection === 'Schedules (NU)' &&
    (pathname.includes('non-union-scheduling/crew-listing') ||
      pathname.includes('non-union-scheduling/employee') ||
      pathname.includes('non-union-scheduling/mass-edit'));

  const isPayCrewlisting =
    props.currentselection === 'Approvals (NU)' &&
    pathname.includes('non-union-pay');
  return (
    <NavLink
      {...props}
      className={({ isActive }: { isActive: boolean }) =>
        'flex bg-none text-white rounded-none hover:text-primary-400 hover:rounded-none hover:bg-blue-50 justify-start items-baseline w-full py-2 text-base font-regular text-left  focus:outline-none mx-0 ' +
        (isActive || isScheduleCrewListing || isPayCrewlisting
          ? '!text-primary-400 bg-blue-100 font-semibold'
          : '')
      }
      to={props.to}
      target={props.external ? '_blank' : ''}
    >
      <>
        {props.children}
        {props.external && <BsBoxArrowUpRight size={12} />}
      </>
    </NavLink>
  );
};

export default SideNavLink;
