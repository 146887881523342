import { Button } from '@uss/react-components';
import React, { useEffect, useRef, useState } from 'react';
import { ConfirmationModal } from './ConfirmationModal';
import { getViewPermissionsByRole } from 'features/probationary-tracking/utilities/getViewPermissionsByRole';
import {
  AreaManagerTabs,
  HrLrTabs,
  ReviewerTabs,
} from 'features/probationary-tracking/types';
import {
  Actions,
  EditReviewPerformanceDetail,
  ProbEmployeeReview,
  ReviewActionLabel,
  ReviewPerformanceDetail,
  UserWarnedFlag,
} from 'features/probationary-tracking/types/employeeReviewDetails';
import { getReviewActionModalProps } from 'features/probationary-tracking/utilities/getReviewActionModalProps';
import { getSuccessConfirmationMessage } from 'features/probationary-tracking/utilities/getSuccessConfirmationMessage';
import getCurrentView from 'features/probationary-tracking/utilities/getCurrentView';
import useModal from 'components/Modals/use-modal';
import {
  usePatchPerformanceReview,
  useUpdatePerformanceReview,
} from 'features/probationary-tracking/api/updatePerformanceReview';
import { ZodErrorType } from 'types/common';
import {
  ReviewPatchSchema,
  ReviewPutSchema,
} from 'features/probationary-tracking/schemas/PerformanceReview';
import useValidateForm from 'hooks/useValidateForm';
import { useNavigate } from 'react-router-dom';

interface RetentionModalProps {
  data: ProbEmployeeReview;
  editComment: string;
  performanceObj: ReviewPerformanceDetail;
  setErrors: React.Dispatch<React.SetStateAction<ZodErrorType>>;
  pathname: string;
  currentTab: AreaManagerTabs | ReviewerTabs | HrLrTabs | undefined;
  userWarnedFlag?: UserWarnedFlag | undefined;
}

export const PerformanceCriteriaActions = ({
  data,
  editComment,
  performanceObj,
  setErrors,
  pathname,
  currentTab,
}: RetentionModalProps) => {
  const { isAreaManagerView, isReviewerView, isHrLrView, isEmployeeView } =
    getCurrentView(pathname);
  const modal = useModal();
  const { reviewNumber, ussId, id, reviewStatus, displayName, userWarnedFlag } =
    data;
  const {
    actionText,
    isActionButtonsDisabled,
    actionButton1Label,
    actionButton2Label,
    showUntilNextReviewMsg,
    showDangerTerminateBtn,
    isFormDisabled,
    showAddCommentsSection,
  } = getViewPermissionsByRole(
    pathname,
    reviewStatus,
    currentTab,
    userWarnedFlag
  );
  const validationSchema = isFormDisabled ? ReviewPatchSchema : ReviewPutSchema;
  const { trimValues, validateForm } = useValidateForm(
    validationSchema,
    setErrors
  );

  const action = useRef<Actions>(
    isAreaManagerView
      ? Actions.SUPERVISOR_RETAINED
      : isReviewerView
      ? Actions.REVIEWER_RETAINED
      : isHrLrView
      ? Actions.HR_LR_RETAINED
      : Actions.EMP_ACKNOWLEDGEMENT
  );

  const [enableValidator, setEnableValidator] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (enableValidator) {
      if (!isFormDisabled) {
        validateForm({
          ...performanceObj,
          comment: editComment,
          action: action.current,
        });
      } else {
        if (showAddCommentsSection) {
          validateForm({
            comment: editComment,
            action: action.current,
          });
        } else {
          validateForm({
            action: action.current,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [performanceObj, editComment, action]);

  const successMessage = getSuccessConfirmationMessage(
    pathname,
    currentTab,
    reviewNumber,
    action.current
  );

  const { mutateAsync: editPerformanceReview } = useUpdatePerformanceReview(
    ussId,
    id,
    reviewNumber
  );

  const { mutateAsync: patchPerformanceReview } = usePatchPerformanceReview(
    ussId,
    id,
    successMessage
  );

  const handleUpdate = async () => {
    modal.closeModal();
    if (isFormDisabled) {
      const body = {
        comment: editComment,
        action: action.current,
      };

      await patchPerformanceReview(body);
    } else {
      const body = {
        ...(performanceObj as EditReviewPerformanceDetail),
        comment: editComment,
        action: action.current,
      };

      await editPerformanceReview(body);
    }
  };

  const validateFormFn = () => {
    if (!isFormDisabled) {
      return validateForm(
        trimValues({
          ...performanceObj,
          comment: editComment,
          action: action.current,
        })
      );
    } else {
      if (showAddCommentsSection) {
        return validateForm(
          trimValues({
            comment: editComment,
            action: action.current,
          })
        );
      } else {
        return validateForm({
          action: action.current,
        });
      }
    }
  };

  const openConfirmationModal = (btnLabel: ReviewActionLabel) => {
    const reviewActionModalProps = getReviewActionModalProps(
      pathname,
      currentTab,
      btnLabel,
      reviewNumber,
      displayName
    );

    modal.openModal({
      title: `${
        reviewActionModalProps.title ? reviewActionModalProps.title : '-'
      }`,
      type: 'regular',
      children: (
        <ConfirmationModal
          btnLabel={btnLabel}
          handleCancel={() => modal.closeModal()}
          handleUpdate={handleUpdate}
          actionRef={action}
          pathname={pathname}
          reviewNumber={reviewNumber}
          reviewActionModalProps={reviewActionModalProps}
        />
      ),
    });
  };

  const handleClick = async (btnLabel: ReviewActionLabel) => {
    if (btnLabel !== ReviewActionLabel.CANCEL) {
      setEnableValidator(true);
      if (validateFormFn()) {
        setEnableValidator(false);
        if (btnLabel === ReviewActionLabel.ACKNOWLEDGE && isEmployeeView) {
          action.current = Actions.EMP_ACKNOWLEDGEMENT;
          await handleUpdate();
        } else {
          openConfirmationModal(btnLabel);
        }
      } else {
        return;
      }
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      {showUntilNextReviewMsg && (
        <div
          className="text-sm text-gray-700 mb-2"
          data-testid="user-warning-msg"
        >{`Performance Review ${reviewNumber} is completed until next review.`}</div>
      )}
      <div className="text-xs text-gray-600">{actionText}</div>
      <div className="flex flex-col lg:flex-row my-2">
        <Button
          onClick={() => handleClick(actionButton1Label)}
          variant="primary"
          className="ml-0 mb-2 lg:mb-0 sm:ml-0 text-lg w-full sm:w-auto"
          type="button"
          data-testid="button-1"
          disabled={isActionButtonsDisabled}
        >
          {actionButton1Label}
        </Button>
        <Button
          onClick={() => handleClick(actionButton2Label)}
          variant={showDangerTerminateBtn ? 'danger' : 'outline'} //TODO: Replace danger to red-outline variant once Design System V3 is available
          className="ml-0 mb-2 lg:mb-0 lg:ml-2 text-lg w-full sm:w-auto"
          type="button"
          data-testid="button-2"
          disabled={isActionButtonsDisabled}
        >
          {actionButton2Label}
        </Button>
      </div>
    </>
  );
};
