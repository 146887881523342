import { useAccount, useMsal } from '@azure/msal-react';
import { intersection } from 'lodash';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
declare type TokenClaims = {
  /**
   * Audience
   */
  aud?: string;
  /**
   * Issuer
   */
  iss?: string;
  /**
   * Issued at
   */
  iat?: number;
  /**
   * Not valid before
   */
  nbf?: number;
  /**
   * Immutable object identifier, this ID uniquely identifies the user across applications
   */
  oid?: string;
  /**
   * Immutable subject identifier, this is a pairwise identifier - it is unique to a particular application ID
   */
  sub?: string;
  /**
   * Users' tenant or '9188040d-6c67-4c5b-b112-36a304b66dad' for personal accounts.
   */
  tid?: string;
  ver?: string;
  upn?: string;
  preferred_username?: string;
  login_hint?: string;
  emails?: string[];
  name?: string;
  nonce?: string;
  /**
   * Expiration
   */
  exp?: number;
  home_oid?: string;
  sid?: string;
  cloud_instance_host_name?: string;
  cnf?: {
    kid: string;
  };
  x5c_ca?: string[];
  ts?: number;
  at?: string;
  u?: string;
  p?: string;
  m?: string;
  roles?: string[];
  amr?: string[];
  idp?: string;
  auth_time?: number;
};
type IdTokenClaims = TokenClaims & {
  roles: string[] | undefined;
  uss_id: string;
};

/**
 *
 * @returns The users roles that they are assigned in Azure, also returns utility functions to compare roles and check if a role is present
 * @example
 * const { roles, hasRole, compareRoles } = useUserRoles();
 */

const useUserRoles = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  /**
   * Roles that the user is assigned, this data is coming from Azure
   * @example
   * ['SUPER_ADMIN','PAYROLL_ADMIN']
   */
  const azureRoles = account
    ? (account.idTokenClaims as IdTokenClaims).roles
    : [];
  const uss_id = account ? (account.idTokenClaims as IdTokenClaims).uss_id : '';

  /**
   * Function that compares the logged in users roles with the array of roles that are passed in.
   *
   * @param arr Array of roles that you would like to compare to the users roles
   * @returns Array of common roles present for the user and the passed values.
   */
  const compareRoles = (arr: string[]) => {
    return intersection(azureRoles, arr);
  };

  /**
   *
   * @param input Role that you wish to check
   * @returns boolean value to determine if the user has the specificed role
   */

  const hasRole = (input: string) => {
    if (!azureRoles) return false;
    return azureRoles.includes(input);
  };

  const isSuperAdmin = hasRole('SUPER_ADMIN') || hasRole('SUPER_PAYROLL_ADMIN');
  const isAuthorized = Boolean(azureRoles);

  const roles = azureRoles ? azureRoles : [];

  return {
    roles,
    compareRoles,
    hasRole,
    isSuperAdmin,
    isAuthorized,
    uss_id,
  };
};

export default useUserRoles;
