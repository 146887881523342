import { Select } from '@uss/react-components';
import React from 'react';
import {
  BsArrowDown,
  BsArrowUp,
  BsSortDown,
  BsSortUpAlt,
} from 'react-icons/bs';
import { SortBy, SortByEnum } from 'features/paycode';
import { SortDirection } from 'types/common';
import { usePaycodeListStore } from 'features/paycode/state/store';

const sortFilters = [
  {
    label: 'Code',
    name: 'code',
    direction: SortDirection.ASC,
  },
  {
    label: 'Code',
    name: 'code',
    direction: SortDirection.DESC,
  },
  {
    label: 'Description',
    name: 'description',
    direction: SortDirection.ASC,
  },
  {
    label: 'Description',
    name: 'description',
    direction: SortDirection.DESC,
  },
  {
    label: 'Type',
    name: 'type',
    direction: SortDirection.ASC,
  },
  {
    label: 'Type',
    name: 'type',
    direction: SortDirection.DESC,
  },
  {
    label: 'Status',
    name: 'status',
    direction: SortDirection.ASC,
  },
  {
    label: 'Status',
    name: 'status',
    direction: SortDirection.DESC,
  },
  {
    label: 'Recently Added',
    name: 'recently-added',
    direction: SortDirection.DESC,
  },
];
const Sort = () => {
  const store = usePaycodeListStore();

  const handleSortFilters = (value: {
    name: SortBy;
    direction: SortDirection;
  }) => {
    store.setSort({
      type: value.name,
      direction: value.direction,
    });
  };
  return (
    <div className="w-full md:w-44">
      <Select
        data-testid="sort"
        selected={store.params.sortBy}
        onSelect={handleSortFilters}
      >
        <Select.Button
          label={
            store.params.sortBy
              ? `${SortByEnum[store.params.sortBy]}`
              : 'Select'
          }
          filter
        >
          {store.params.sortType === SortDirection.ASC ? (
            <BsSortUpAlt color="#005288" size="1rem" />
          ) : (
            <BsSortDown color="#005288" size="1rem" />
          )}
        </Select.Button>
        <Select.Options>
          {sortFilters.map((item, itemIdx) => {
            return (
              <Select.Option
                key={itemIdx}
                value={{ name: item.name, direction: item.direction }}
              >
                <span className="flex items-center justify-between w-full">
                  <span className="pr-6">{item.label}</span>
                  <span className="flex justify-end ">
                    {item.direction === SortDirection.ASC ? (
                      <BsArrowUp color="#005288" />
                    ) : (
                      <BsArrowDown color="#005288" />
                    )}
                  </span>
                </span>
              </Select.Option>
            );
          })}
        </Select.Options>
      </Select>
    </div>
  );
};

export default Sort;
