import CardList from 'components/Card/CardList';
import { ReviewDetails } from 'features/probationary-tracking/types';
import { ReviewPerformanceCard } from './ReviewPerformanceCard';
import { useLocation } from 'react-router-dom';

interface Props {
  reviewDetails: ReviewDetails[];
}

export const ReviewPerformanceCards = ({ reviewDetails }: Props) => {
  const { pathname } = useLocation();

  return (
    <CardList<ReviewDetails>
      items={reviewDetails}
      renderItem={(review) => {
        return <ReviewPerformanceCard review={review} pathname={pathname} />;
      }}
      noResults={{
        title: 'No Records Found',
        description: 'No Performance Review records found',
      }}
    />
  );
};
