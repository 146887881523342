import Loader from 'components/Skeleton/Loader';

const AddNoteDrawerLoader = () => {
  return (
    <div>
      <div className="mb-4">
        <div className="grid grid-cols-2 gap-x-2">
          <div className="flex flex-col justify-start">
            <span className="text-xs text-gray-600 ">Date</span>
            <span>
              <Loader rows={1} />
            </span>
          </div>
          <div className="flex flex-col justify-start">
            <span className="text-xs text-gray-600 ">Elapsed Time</span>
            <span>
              <span>
                <Loader rows={1} />
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col justify-start">
            <span className="text-xs text-gray-600 ">Scheduled Turn</span>
            <span>
              <span>
                <Loader rows={1} />
              </span>
            </span>
          </div>
          <div className="flex flex-col justify-start">
            <span className="text-xs text-gray-600 ">Scheduled Hours</span>
            <span>
              <span>
                <Loader rows={1} />
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col justify-start">
            <span className="text-xs text-gray-600 ">Worked Turn</span>
            <span>
              <span>
                <Loader rows={1} />
              </span>
            </span>
          </div>
          <div className="flex flex-col justify-start">
            <span className="text-xs text-gray-600 ">Worked Hours</span>
            <span>
              <span>
                <Loader rows={1} />
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="flex flex-col justify-start">
          <span className="text-xs text-gray-600 ">Notes</span>
          <span className="text-sm w-full">
            <span>
              <Loader rows={1} />
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};
export default AddNoteDrawerLoader;
