import Card from 'components/Card/Card';
import CardList from 'components/Card/CardList';
import Error from 'components/Error/Error';
import CardsLoader from 'components/Loaders/CardsLoader';
import Pagination from 'components/Pagination/Pagination';
import LabeledText from 'components/Typography/LabeledText';
import { formatInTimeZone } from 'date-fns-tz';
import { useSwipeDetails } from 'features/swipe-times/api/getSwipeTimes';
import { SwipeTimesItem } from 'features/swipe-times/types/swipeTimes';
import { useSwipeTimeStore } from './state/store';

interface SwipeTimesProps {
  ussID: string;
  week: string | null;
  noRecordError?: string;
}
export const SwipeTimes = ({
  ussID,
  week,

  noRecordError,
}: SwipeTimesProps) => {
  const store = useSwipeTimeStore();

  const { data, status } = useSwipeDetails(ussID, {
    week,
    page: store.params.page,
    pageSize: store.params.pageSize,
  });

  const noResults = {
    title: 'No Records Found',
    description: noRecordError ?? `No Swipe In/Out records found`,
  };

  return (
    <>
      <h3 className="font-semibold my-4">Swipe In/Out</h3>
      {status === 'pending' && <CardsLoader variant="row-grid" count={6} />}
      {status === 'error' && <Error />}
      {status === 'success' && (
        <>
          <CardList<SwipeTimesItem>
            variant="row-grid"
            items={data.items}
            renderItem={(item) => <SwipeTimesCard SwipeTime={item} />}
            noResults={noResults}
          />
          <div className="py-4">
            <Pagination
              pageOptions={[9, 18, 27, 36, 45, 54]}
              totalCount={data.totalCount}
              page={Number(store.params.page)}
              pageSize={Number(store.params.pageSize)}
              onPageChange={(s) => store.setPage((s.selected + 1).toString())}
              onPageSizeChange={(ps) => store.setPageSize(ps.toString())}
            />
          </div>
        </>
      )}
    </>
  );
};

interface SwipeTimesCardProps {
  SwipeTime: SwipeTimesItem;
}

export const SwipeTimesCard = ({
  SwipeTime,
}: SwipeTimesCardProps): JSX.Element => {
  return (
    <Card>
      <Card.Body>
        <div data-testid="Swipetime-card">
          <div className="flex flex-wrap sm:flex-nowrap md:flex-nowrap items-center w-full">
            <div className="w-full sm:w-2/5 flex mb-4 sm:mb-0 ">
              <LabeledText className="w-4/5 " label={'In'}>
                {SwipeTime.inTime
                  ? formatInTimeZone(
                      SwipeTime.inTime,
                      SwipeTime.timezone,
                      'MMM dd, yyyy, hh:mm aa zzz'
                    )
                  : '-'}
              </LabeledText>
              <LabeledText label="INRDR" className="w-1/5 " no_clamp>
                {SwipeTime.inRdr ? SwipeTime.inRdr : '-'}
              </LabeledText>
            </div>
            <div className="w-full sm:w-2/5 flex mb-4 sm:mb-0 ">
              <LabeledText className="w-4/5 " label="Out" no_clamp>
                {SwipeTime.outTime
                  ? formatInTimeZone(
                      SwipeTime.outTime,
                      SwipeTime.timezone,
                      'MMM dd, yyyy, hh:mm aa zzz'
                    )
                  : '-'}
              </LabeledText>
              <LabeledText className="w-1/5 " label="OUTRDR" no_clamp>
                {SwipeTime.outRdr ? SwipeTime.outRdr : '-'}
              </LabeledText>
            </div>
            <div className="w-full sm:w-1/5 ">
              <LabeledText label="Elapse" no_clamp>
                {SwipeTime.elapsedTime ? SwipeTime.elapsedTime : '-'}
              </LabeledText>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
