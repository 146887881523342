import { Checkbox } from '@uss/react-components';
import Alert from 'components/Alert/Alert';
import Card from 'components/Card/Card';
import { add, differenceInWeeks, format, isEqual, parseISO } from 'date-fns';
import { Org, OrgList } from 'features/org/types';
import { CrewSelect } from 'features/timecards/components/Filters/CrewSelect';
import { Person } from 'features/people/types';
import {
  UpdateFromDateError,
  CopySectionProps,
  CopyParamActions,
  SectionType,
} from 'features/timecards/types';
import React, { useEffect } from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import SearchEmployee from './SearchEmployee';
import WeekRangeSelect from './WeekRangeSelect';
import { QueryStatus } from '@tanstack/react-query';

type CopyToSectionProps = Omit<CopySectionProps, 'data' | 'id'> & {
  selectedOption: Person | null;
  setSelectedOption: React.Dispatch<React.SetStateAction<Person | null>>;
  setCrewSelected: (value: Org) => void;
  crewSelected: Org | null;
  crewList: OrgList | undefined;
  crewLoadingStatus: QueryStatus;
  week: string | null;
  updateErrorState: UpdateFromDateError;
  isMySchedule: boolean;
};

const CopyToSection = ({
  week,
  sourceCrewKey,
  weekRangeChecked,
  weeksState,
  updateWeekState,
  setWeekRangeChecked,
  selectedOption,
  setSelectedOption,
  setCrewSelected,
  crewSelected,
  crewList,
  crewLoadingStatus,
  errorState,
  updateErrorState,
  isMySchedule,
  startDayOfWeek,
}: CopyToSectionProps) => {
  const targetWeekPeriod =
    !weekRangeChecked.from &&
    weeksState.target.lastWeek &&
    weeksState.target.firstWeek
      ? differenceInWeeks(
          parseISO(weeksState.target.lastWeek),
          parseISO(weeksState.target.firstWeek)
        )
      : 0;

  const sourceWeekPeriod =
    weeksState.source.firstWeek !== '' &&
    weekRangeChecked.from &&
    weeksState.source.lastWeek
      ? differenceInWeeks(
          parseISO(weeksState.source.lastWeek),
          parseISO(weeksState.source.firstWeek)
        )
      : 0;

  let endDateCalc: Date | null = null;

  if (sourceWeekPeriod && sourceWeekPeriod > 0 && weeksState.target.firstWeek) {
    endDateCalc = add(parseISO(weeksState.target.firstWeek), {
      weeks: sourceWeekPeriod,
    });
  } else if (weeksState.target.lastWeek) {
    endDateCalc = parseISO(weeksState.target.lastWeek);
  } else endDateCalc = null;

  useEffect(() => {
    if (
      sourceWeekPeriod > 0 &&
      endDateCalc &&
      !!week &&
      !isEqual(endDateCalc, parseISO(weeksState.target.lastWeek || week))
    ) {
      updateWeekState({
        type: CopyParamActions.TO_END_WEEK,
        payload: format(endDateCalc, 'yyyy-MM-dd'),
      });
    }
  }, [
    weeksState.target.firstWeek,
    sourceWeekPeriod,
    endDateCalc,
    weeksState.target.lastWeek,
    updateWeekState,
    week,
  ]);

  return (
    <Card>
      <Card.Body>
        <div
          className="flex items-center justify-center sm:mb-28 "
          data-testid="to-card"
        >
          <div className="w-11/12 md:w-3/4 xl:3/4 2xl:3/4">
            <h2 className=" font-semibold text-lg text-gray-700">To</h2>
            <div className="bg-white border-t border-gray-300 my-2"></div>
            <Alert variant="info">
              Note: Any existing schedule(s) will be overwritten
            </Alert>

            {!isMySchedule && (
              <SearchEmployee
                selectedOption={selectedOption}
                sourcePayrollLocation={sourceCrewKey?.slice(0, 3)}
                setSelectedOption={setSelectedOption}
              />
            )}

            <div className=" flex flex-col my-4">
              {isMySchedule ? (
                <>
                  <span className="text-xs text-gray-700 mt-1">Crew </span>
                  <span className=" text-sm text-gray-900 font-medium ">
                    {sourceCrewKey}
                  </span>
                </>
              ) : (
                <CrewSelect
                  setCrew={setCrewSelected}
                  crews={crewList?.items ?? []}
                  selectedCrew={crewSelected}
                  crewStatus={crewLoadingStatus}
                  showLabel
                />
              )}
            </div>
            <Checkbox
              label="Week Range"
              id="daterange-to"
              disabled={weekRangeChecked.from}
              checked={weekRangeChecked.from || weekRangeChecked.to}
              onChange={() => {
                !weekRangeChecked.from &&
                  setWeekRangeChecked({
                    from: weekRangeChecked.from,
                    to: !weekRangeChecked.to,
                  });
              }}
            />
            <WeekRangeSelect
              fromDateError={errorState}
              weekRangeChecked={weekRangeChecked}
              startDate={
                weeksState.target.firstWeek
                  ? parseISO(weeksState.target.firstWeek)
                  : null
              }
              endDate={
                weeksState.target.lastWeek
                  ? parseISO(weeksState.target.lastWeek)
                  : null
              }
              updateWeekState={updateWeekState}
              sectionType={SectionType.TO}
              updateErrorState={updateErrorState}
              startDayOfWeek={startDayOfWeek}
            />
            {!weekRangeChecked.from && targetWeekPeriod > 0 && (
              <div
                className="p-2 flex items-center "
                data-testid="target-week-selected-info"
              >
                <BsInfoCircle className="text-gray-700" size={'16'} />
                <p className=" text-sm pl-2  text-gray-700">
                  {targetWeekPeriod + 1} week{targetWeekPeriod > 1 && 's'}{' '}
                  selected
                </p>
              </div>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CopyToSection;
