import {
  TIMECARD_ACTION_BUTTON,
  ActionModalProperties,
  TimecardActionButton,
} from 'features/timecards/types';

const actionModalProperties: ActionModalProperties = {
  [TIMECARD_ACTION_BUTTON.SAVE]: {
    modalTitle: 'Save Changes',
    confirmButtonTitle: 'Save',
    modalBodyText: 'Are you sure you want to save the changes?',
  },
  [TIMECARD_ACTION_BUTTON.CREATE_TIMECARD]: {
    modalTitle: 'Save Changes',
    confirmButtonTitle: 'Save',
    modalBodyText: 'Are you sure you want to save the changes?',
  },
  [TIMECARD_ACTION_BUTTON.SAVE_APPROVE]: {
    modalTitle: 'Approve Timecard',
    confirmButtonTitle: 'Approve',
    modalBodyText:
      'Are you sure you want to approve the timecard for this employee?',
  },
  [TIMECARD_ACTION_BUTTON.UNAPPROVE]: {
    modalTitle: 'Unapprove Timecard',
    confirmButtonTitle: 'Unapprove',
    modalBodyText:
      'Are you sure you want to unapprove the timecard for this employee?',
  },
  [TIMECARD_ACTION_BUTTON.OPEN_ADJUSTMENTS]: {
    modalTitle: 'Open Adjustment',
    confirmButtonTitle: 'Open',
    modalBodyText:
      'Are you sure you want to open adjustment for this timecard?',
  },
  [TIMECARD_ACTION_BUTTON.SAVE_ADJUSTMENTS]: {
    modalTitle: 'Save Adjustment',
    confirmButtonTitle: 'Save Adjustment',
    modalBodyText:
      'Are you sure you want to save the adjustment for this timecard?',
  },
  [TIMECARD_ACTION_BUTTON.SAVE_APPROVE_ADJUSTMENTS]: {
    modalTitle: 'Approve Adjustment',
    confirmButtonTitle: 'Approve',
    modalBodyText:
      'Are you sure you want to approve the adjustment for this timecard?',
  },
  [TIMECARD_ACTION_BUTTON.UNAPPROVE_ADJUSTMENTS]: {
    modalTitle: 'Unapprove Adjustment',
    confirmButtonTitle: 'Unapprove',
    modalBodyText:
      'Are you sure you want to unapprove adjustment for this timecard?',
  },
  [TIMECARD_ACTION_BUTTON.SAVE_VERIFY]: {
    modalTitle: 'Verify Changes',
    confirmButtonTitle: 'Verify',
    modalBodyText: 'Are you sure you want to verify the changes?',
  },
};
const defaultActionModalProperties = {
  modalTitle: 'Cancel Changes',
  confirmButtonTitle: 'Save',
  modalBodyText: 'Are you sure you want to cancel the changes?',
};
export const getActionModalProperties = (
  buttonRef: React.MutableRefObject<TimecardActionButton>
) => {
  // Checks if buttonref.current matches with any of modalProperties key.
  const foundKey = (
    Object.keys(actionModalProperties) as (keyof typeof actionModalProperties)[]
  ).find((key) => {
    return key === buttonRef.current;
  });
  // If there is a match, return it, else return default properties
  return foundKey
    ? actionModalProperties[foundKey]
    : defaultActionModalProperties;
};
