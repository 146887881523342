import getUnitOfMeasure, {
  UnitOfMeasure,
} from 'features/paid-time-off/utility/getUnitOfMeasure';
import React, { useId } from 'react';
function TotalsValue({
  label,
  value,
  unitOfMeasure,
  divider = false,
}: {
  label: string;
  value: number;
  unitOfMeasure: UnitOfMeasure;
  divider?: boolean;
}) {
  const id = useId();
  return (
    <div
      data-testid="total-container"
      className={
        divider
          ? 'pt-3 lg:pt-0 border-t-4 lg:border-t-0 lg:border-l-4 border-gray-100 pl-0 lg:pl-4'
          : ''
      }
    >
      <p className="text-sm font-light" id={id}>
        {label}
      </p>
      <p className="text-gray-900 text-xl lg:text-3xl" aria-labelledby={id}>
        {value}
        <span className="text-base">
          {' '}
          {getUnitOfMeasure(unitOfMeasure, value)}
        </span>
      </p>
    </div>
  );
}

export default TotalsValue;
