import { axios } from 'services/api/axios';
import { useQuery } from '@tanstack/react-query';
import { PerformanceReviewDetails } from '../types';

export const usePerformanceReviewDetailsSummary = (ussId: string) => {
  return useQuery({
    queryFn: () => getReviewDetailsSummary(ussId),
    queryKey: ['performance-review-details', ussId],
    select: (data) => {
      data.reviewDetails = data.reviewDetails.sort((a, b) => {
        return a.reviewNumber && b.reviewNumber
          ? a.reviewNumber - b.reviewNumber
          : 0;
      });
      return data;
    },
  });
};

const getReviewDetailsSummary = (
  ussId: string
): Promise<PerformanceReviewDetails> => {
  return axios.get(`/v1/prob-emp/${ussId}/reviews`);
};
