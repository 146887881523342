import { ShiftAssignment as ShiftAssignmentObj } from 'features/crew-maintenance/types';
import { isAfter, isBefore, parseISO } from 'date-fns';

export const findFuturePendingShift = (
  shiftAssignmentData: ShiftAssignmentObj[],
  currentDate: Date
) => {
  const data = shiftAssignmentData.find((item: ShiftAssignmentObj) => {
    return (
      item.changeInitialized &&
      isAfter(parseISO(item.startDate), currentDate) &&
      item.payPeriodStatus === 'not-open'
    );
  });
  return data ? data : null;
};

export const findClosedPendingShift = (
  shiftAssignmentData: ShiftAssignmentObj[],
  currentDate: Date
) => {
  const data = shiftAssignmentData.find((item: ShiftAssignmentObj) => {
    return (
      item.changeInitialized &&
      isBefore(parseISO(item.startDate), currentDate) &&
      item.payPeriodStatus === 'closed'
    );
  });
  return data ? data : null;
};
