import { Badge } from '@uss/react-core';
import { MODEL_TYPE } from 'features/model-listing/constants';
import { ModelList } from 'features/model-listing/types';
import { getModelStatusColors } from 'features/model-listing/utility/getModelStatusColor';
import { getModelStatusText } from 'features/model-listing/utility/getModelStatusText';
import { getWeekFormatTitle } from 'features/model-listing/utility/getWeekFormatTitle';

export const ModelStatusCard = ({ record }: { record: ModelList }) => {
  const modalTypeVal = record.modelType.toLowerCase();

  return (
    <div className="lg:grid sm:w-1/2 w-full">
      <div
        className="w-full items-center border-b-2 lg:border-b-0 
      lg:border-r-2 border-gray-200 pb-3 lg:pb-0 mb-3 lg:mb-0 pr-4"
      >
        <div>
          <div className="flex flex-row items-center justify-between">
            <div
              className="text-md font-semibold text-gray-700 py-1 mb-1 text-wrap"
              aria-label="Model Name"
            >
              {record.modelName}
            </div>
            <div className="flex items-center justify-center gap-2 py-1">
              <Badge
                label={getModelStatusText(record.modelStatus)}
                color={getModelStatusColors(record.modelStatus)}
              />
            </div>
          </div>

          <div className="flex flex-row justify-start items-start">
            <div className="flex flex-row items-center">
              <span className="text-sm text-gray-900">
                {modalTypeVal.charAt(0).toUpperCase() + modalTypeVal.slice(1)}
              </span>
              <span className="px-3">{'|'}</span>
              {record.modelType.toLowerCase() === MODEL_TYPE.PLANT && (
                <>
                  <span className="text-sm text-gray-700">
                    {record.plantName}
                  </span>
                  <span className="px-3">{'|'}</span>
                </>
              )}
              <span className="text-sm">
                {getWeekFormatTitle(record.weekBeginIndex)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
