import React from 'react';
import { useEmployeePtoDetails } from 'features/paid-time-off/api/queries';
import { CalendarEvent } from 'components/CalendarV2/types';
import { usePtoCalendarDates } from 'features/paid-time-off';
import { useMyPtoStore } from 'features/paid-time-off/state/mypto-store';
import { PTOCardList } from '../PTOCardList';
import { MyPtoFilters } from './MyPtoFilters';
import { MyPtoCalendar } from './MyPtoCalendar';
import { useLoggedInUser } from 'features/people/api';
import { PtoDetail } from 'features/paid-time-off/types';

const MyPtoDetails = () => {
  const { state, detailsView, calendarType } = useMyPtoStore((state) => ({
    state: state.params,
    detailsView: state.ptoDetailsView,
    calendarType: state.calendarType,
  }));
  const { data, isLoading } = useEmployeePtoDetails('me', state);
  const { data: user } = useLoggedInUser();

  //get events
  const events: CalendarEvent[] = usePtoCalendarDates(
    data ? data.items : ([] as PtoDetail[]),
    calendarType
  );

  return (
    <div className="grid grid-cols-1 gap-4">
      <MyPtoFilters />
      {detailsView === 'calendar' ? (
        <MyPtoCalendar
          events={events}
          records={data?.items}
          loading={isLoading}
        />
      ) : (
        <PTOCardList
          records={data?.items}
          loading={isLoading}
          links={user?.paidSickTimeEligibility !== 'y'}
        />
      )}
    </div>
  );
};

export default MyPtoDetails;
