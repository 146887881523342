import React from 'react';
import Error from 'components/Error/Error';

const ApplicationError = () => {
  return (
    <div>
      <Error />
    </div>
  );
};

export default ApplicationError;
