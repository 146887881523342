import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useParams, useNavigate } from 'react-router-dom';
import Page from 'components/Layouts/Page';
import {
  useEmployeeCrews,
  useEmployeeOrgs,
  useUserCrews,
} from 'features/org/api';
import { usePersonDetails } from 'features/people/api';
import { useEmployeeTimecard } from 'features/timecards/api/getTimecardDetails';
import { CrewSelect } from 'features/timecards/components/Filters/CrewSelect';
import EmployeeDetails from 'features/timecards/components/TimecardDetails/Details/EmployeeDetails';
import WeekSelect from 'features/timecards/components/Filters/WeekSelect';
import useGenerateTimecard from 'features/timecards/hooks/useGenerateTimecard';
import useTimecardAccess from 'features/timecards/hooks/useTimecardAccess';
import DetailsSkeleton from 'components/Skeleton/DetailsSkeleton';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import Tabs, { TabLinkType } from 'components/Tabs/Tabs';
import PolicySummary from 'features/timecards/components/Tabs/PolicySummary';
import TimecardHistory from 'features/timecards/components/Tabs/TimecardHistory';
import TimecardApprovals from 'features/timecards/components/Tabs/TimecardApprovals';
import ViewAllCrews from 'features/timecards/components/Tabs/ViewAllCrews';
import {
  TabTypes,
  CrewEmployeeType,
  PayDetailsTabs,
} from 'features/timecards/types';

import { getDates } from 'features/timecards/utilities/getDates';
import EmployeeCarousal from 'features/timecards/components/Filters/EmployeeCarousal';
import { usePayPeriodStatus } from 'features/pay-periods/api';
import useWindowSize from 'hooks/useWindowSize';
import { SortDirection } from 'types/common';
import { ORG_TYPE } from 'features/org/constants';
import { HolidayType } from 'features/holidays';
import { SwipeTimes } from 'features/swipe-times/SwipeTimes';
import { useSetPrimaryCrewPtos } from 'features/timecards/hooks/useSetPrimaryCrewPtos';

const MassPayEdit = () => {
  const { pathname } = useLocation();
  const breadCrumbNodes = [
    <NavLink
      key="bc-1"
      className="flex flex-row justify-center items-center"
      to={`/pay`}
    >
      Pay
    </NavLink>,
    <NavLink
      className="flex flex-row justify-center items-center"
      key="bc-2"
      to={`/non-union-pay/overview`}
    >
      Approvals (NU)
    </NavLink>,
  ];

  const state = useNonUnionStore();

  const { width } = useWindowSize();

  const { ussId = '' } = useParams<'ussId'>();

  const navigate = useNavigate();

  const currentIndex = state.selectedEmployees.findIndex(
    (e) => e.ussId.toString() === ussId
  );
  // Person details for details card
  const { data, status } = usePersonDetails(ussId, {
    week: state.params.week ?? '',
  });
  const [holidayType, setHolidayType] = useState<HolidayType>('union');
  useEffect(() => {
    if (status === 'success') {
      setHolidayType(data.holidayType);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, status]);

  const shiftAssignment = data ? data.shiftAssignment : null;
  // Getting employee's plant
  const { data: plantList, status: plantStatus } = useEmployeeOrgs('me', {
    type: ORG_TYPE.PLANT,
    sortBy: 'plant',
    sortType: SortDirection.ASC,
    role: 'crew_member,crew_temp_member',
    status: 'active',
  });
  //Getting employee crews
  const {
    data: employeePayCrews,
    status: crewStatus,
    isRefetching: isRefetchingEmployeeCrews,
  } = useEmployeeCrews(ussId, {
    week: state.params.week ?? '',
  });

  useEffect(() => {
    if (
      employeePayCrews &&
      employeePayCrews.items.length > 0 &&
      crewStatus === 'success'
    ) {
      const isgateSysytemAvailable =
        employeePayCrews.items[0].gateSystem.available;
      state.setGateSystem(isgateSysytemAvailable);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeePayCrews, crewStatus]);

  //Crews in which the logged in user is either a pay_approver or schedule_pay_viewer
  const { data: userPayCrews } = useUserCrews();

  // Determing if the user has access to the selected crew.
  const { isTimecardDisabled } = useTimecardAccess(
    userPayCrews,
    state.crew?.id
  );

  // Employee timecards
  const { data: employeeTimecard, isLoading } = useEmployeeTimecard(
    ussId,
    {
      week: state.params.week ?? '',
      crewId: state.crew?.id,
    },
    isRefetchingEmployeeCrews ? [] : employeePayCrews?.items
  );

  //Display loader initially until crews are loaded
  let shouldDisplayLoader = true;
  if (crewStatus === 'success') {
    //Setting shouldDisplayLoader to false if user does not have access to the crew selected
    shouldDisplayLoader =
      !!state.crew?.id &&
      !!employeePayCrews.items.find((crew) => crew.id === state.crew?.id);
  }
  const isFetchingTimecards = isLoading && shouldDisplayLoader;

  /// Getting the first timecard in the list
  const timecard = employeeTimecard?.items[0];

  // Getting Payperiod status for the selected week and generating employeeTimecardObj
  const {
    weekBegin,
    weekEnd,
    dates = [],
  } = !!state.params.week &&
  state.selectedPlant?.weekBeginDayIndex !== undefined
    ? getDates({
        startDate: state.params.week,
        weekStartsOn: state.selectedPlant.weekBeginDayIndex,
        customFormat: 'yyyy-MM-dd',
      })
    : { weekBegin: null, weekEnd: null };

  const { data: payPeriodStatus } = usePayPeriodStatus(
    data?.payrollNameId ? data.payrollNameId : '',
    weekBegin ? weekBegin : '',
    weekEnd ? weekEnd : ''
  );

  const { employeeTimecardObj } = useGenerateTimecard({
    employeeTimecard: employeeTimecard,
    week: state.params.week,
    feature: 'pay',
    payPeriodStatus: payPeriodStatus,
    dates: dates,
  });
  // Determine if the user is a temp member of the selected Crew
  const isTemp =
    !!employeeTimecard &&
    employeeTimecard.items[0] &&
    employeeTimecard.items[0].employeeType === CrewEmployeeType.TEMP;

  const currentTab = useRef<PayDetailsTabs>(TabTypes.TIMECARD_APPROVALS);
  const handleTabClick = (label: PayDetailsTabs) => {
    //Setting current tab selection to ref on tab click
    currentTab.current = label;
  };

  const tabLinks: TabLinkType[] = [
    {
      label: TabTypes.TIMECARD_APPROVALS,

      element: (
        <TimecardApprovals
          isFetchingTimecards={isFetchingTimecards}
          employeeTimecardObj={employeeTimecardObj}
          isTimecardDisabled={isTimecardDisabled}
          shiftAssignment={shiftAssignment}
          width={width}
          holidayType={holidayType}
        />
      ),
      customOnChange: () => handleTabClick(TabTypes.TIMECARD_APPROVALS),
    },
    {
      label: TabTypes.HISTORY,
      element: <TimecardHistory id={timecard?.id ?? ''} />,
      customOnChange: () => handleTabClick(TabTypes.HISTORY),
    },
    {
      label: TabTypes.POLICY_SUMMARY,
      element: (
        <PolicySummary
          ussID={ussId}
          week={state.params.week}
          holidayType={holidayType}
          width={width}
        />
      ),
      customOnChange: () => handleTabClick(TabTypes.POLICY_SUMMARY),
    },
    {
      label: TabTypes.VIEW_ALL_CREWS,
      element: <ViewAllCrews />,
      customOnChange: () => handleTabClick(TabTypes.VIEW_ALL_CREWS),
    },
    {
      label: TabTypes.SWIPE_TIMES,
      element: <SwipeTimes ussID={ussId} week={state.params.week} />,
      customOnChange: () => handleTabClick(TabTypes.SWIPE_TIMES),
      isDisabled: state.crew?.gateSystem.available === 'n' ? true : false,
    },
  ];

  const handleEmployeeChange = (type: 'increment' | 'decrement') => {
    const modifyBy = type === 'increment' ? 1 : -1;
    const newIndex = currentIndex + modifyBy;
    navigate(
      `/non-union-pay/mass-edit/employee/${state.selectedEmployees[
        newIndex
      ].ussId.toString()}`
    );
  };
  if (
    plantStatus === 'success' &&
    plantList.items.length > 0 &&
    !!state.selectedPlant &&
    !Number.isInteger(state.selectedPlant.weekBeginDayIndex)
  ) {
    throw new Error("Plant's weekBeginDayIndex cannot be null/undefined");
  }
  useEffect(() => {
    state.setFeature('pay');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      plantStatus === 'success' &&
      plantList.items.length > 0 &&
      !plantList.items.find(
        (p) =>
          !!p.id && !!state.selectedPlant?.id && p.id === state.selectedPlant.id
      )
    ) {
      state.setPlant(plantList.items[0]);
    }
  }, [plantList, plantStatus, state]);
  //If the current Crew's plant doesn't match with the selected plant, set it to the selected crew's plant
  useEffect(() => {
    if (
      !!state.selectedPlant &&
      !!state.crew &&
      state.selectedPlant.plant !== state.crew.plant
    ) {
      const foundPlant = plantList?.items.find(
        (p) => !!p.plant && !!state.crew?.plant && p.plant === state.crew.plant
      );
      if (foundPlant) {
        state.setPlant(foundPlant);
      }
    }
  }, [state, state.crew, plantList?.items]);
  //Redirect back to crew listing page if there are no selected employees or if employee cannot be found
  useEffect(() => {
    if (state.selectedEmployees.length === 0 || currentIndex === -1) {
      navigate('/non-union-pay/overview');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //Scroll to top if page url changes
  useEffect(() => {
    const titleElement = document.querySelector('#page-title');
    if (titleElement) {
      titleElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [pathname]);

  /** Saving Primary Crew Ptos to Store**/
  useSetPrimaryCrewPtos(ussId);

  return (
    <Page breadcrumbs={breadCrumbNodes} heading={'Pay Details'}>
      <Page.Section>
        <EmployeeCarousal
          currentIndex={currentIndex}
          totalResults={state.selectedEmployees.length}
          onClickLeft={() => handleEmployeeChange('decrement')}
          onClickRight={() => handleEmployeeChange('increment')}
        />
      </Page.Section>
      <Page.Section>
        {status !== 'success' ? (
          <DetailsSkeleton />
        ) : (
          <EmployeeDetails employee={data} temp={isTemp} />
        )}
      </Page.Section>
      <Page.Section>
        <div className="flex flex-row flex-wrap px-3">
          <div className="w-full sm:w-4/5">
            <Page.Filters>
              <h3 className="sr-only">Timecard Filters</h3>
              <Page.FiltersSection className="w-full sm:w-1/2 2xl:w-4/12">
                <WeekSelect
                  disabled={true}
                  startDayOfWeek={state.selectedPlant?.weekBeginDayIndex}
                />
              </Page.FiltersSection>
              <Page.FiltersSection className="w-full sm:w-1/4 2xl:w-4/12">
                <CrewSelect
                  crews={employeePayCrews?.items ? employeePayCrews.items : []}
                  selectedCrew={state.crew}
                  setCrew={state.setCrew}
                  crewStatus={crewStatus}
                  disabled={true}
                />
              </Page.FiltersSection>
            </Page.Filters>
          </div>
        </div>
        <div className="mt-2">
          <Tabs tabLinks={tabLinks} />
        </div>
      </Page.Section>
      <Page.Section>
        <EmployeeCarousal
          currentIndex={currentIndex}
          totalResults={state.selectedEmployees.length}
          onClickLeft={() => handleEmployeeChange('decrement')}
          onClickRight={() => handleEmployeeChange('increment')}
        />
      </Page.Section>
    </Page>
  );
};

export default MassPayEdit;
