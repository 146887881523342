import { isAfter, isBefore, parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { SellBackActionType } from '../types';
import { appNewDate } from 'utils/appNewDate';

export const getSellBackPtoMsg = (
  sellBackStart: string | null | undefined,
  sellBackEnd: string | null | undefined,
  selectedDate: Date
): SellBackActionType => {
  const sellBackStartDate: Date | null = sellBackStart
    ? parseISO(sellBackStart)
    : null;
  const sellBackEndDate: Date | null = sellBackEnd
    ? parseISO(sellBackEnd)
    : null;

  const _showSellBackPTOMsg =
    !!sellBackStartDate &&
    !!sellBackEndDate &&
    isAfter(appNewDate(), new Date(selectedDate.getFullYear(), 10, 1)) &&
    isBefore(appNewDate(), sellBackEndDate);

  const _showSellBackPTOLink =
    !!sellBackStartDate && isAfter(appNewDate(), sellBackStartDate);

  const getSellBackMsg = () => {
    if (_showSellBackPTOMsg) {
      return `Sell Back PTO hours can be entered/updated beginning ${format(
        sellBackStartDate,
        'LLL d, yyyy'
      )} and will be locked/processed on ${format(
        sellBackEndDate,
        'LLL d, yyyy'
        )} at ${format(sellBackEndDate,  'hh:mm aa zzz')} `;
    } else {
      return '';
    }
  };

  const getSellBackLinkText = () => {
    if (_showSellBackPTOLink && sellBackEndDate) {
      if (
        isAfter(appNewDate(), sellBackStartDate) &&
        isBefore(appNewDate(), sellBackEndDate)
      ) {
        return 'Sell Back PTO';
      } else {
        return 'View Sell Back PTO';
      }
    } else {
      return '';
    }
  };
  return {
    //flag to show sell back pto message from (1st Nov) to (endDate) of sell back period
    showSellBackPTOMsg: _showSellBackPTOMsg,
    //flag to show sell Back/view sell back link text from (startDate) to (next year) of sell back period
    showSellBackPTOLink: _showSellBackPTOLink,
    sellBackMsg: getSellBackMsg(),
    sellBackLinkText: getSellBackLinkText(),
  };
};
