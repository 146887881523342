import {
  EmployeePolicySummary,
  PolicySummaryAdjustmentWiseHours,
  PolicySummaryTotalHours,
} from 'features/timecards/types/policySummary';

export const getFormattedPolicySummaryHrs = (
  hours: number,
  shouldDisplaySignedValue?: boolean,
  shouldDisplayDashes?: boolean
) => {
  const formattedHrs = Number.isInteger(hours)
    ? hours.toFixed(1)
    : hours.toFixed(2);

  if (shouldDisplaySignedValue && hours > 0) {
    return `+${formattedHrs}`;
  } else if (shouldDisplayDashes && hours === 0) {
    return '--';
  } else {
    return formattedHrs;
  }
};

export const groupByPayProcessingDate = (
  adjustmentWiseHours:
    | PolicySummaryAdjustmentWiseHours[]
    | PolicySummaryTotalHours[]
) => {
  return adjustmentWiseHours.reduce<
    Record<
      string,
      PolicySummaryAdjustmentWiseHours[] | PolicySummaryTotalHours[]
    >
  >((policySummaryData, currentAdjustment) => {
    const { payProcessingDate } = currentAdjustment;
    if (!Object.keys(policySummaryData).includes(payProcessingDate)) {
      policySummaryData[payProcessingDate] = [];
    }
    policySummaryData[payProcessingDate].push(currentAdjustment);
    return policySummaryData;
  }, {});
};

export const getFormattedPolicySummaryObj = (data: EmployeePolicySummary) => {
  const groupedItems = groupByPayProcessingDate(data.items);
  const groupedTotalhours = groupByPayProcessingDate(data.totalHours);
  const payProcessingDates = Object.keys(groupedItems);
  return payProcessingDates.reduce(
    (policySummaryObj, payProcessingDate) => ({
      ...policySummaryObj,

      [payProcessingDate]: {
        items: groupedItems[payProcessingDate],
        totalHours: groupedTotalhours[payProcessingDate],
        policySummaryFields: {
          ussId: data.ussId,
          employeeClass: data.employeeClass,
          shiftAssignment: data.shiftAssignment,
          payrollNameId: data.payrollNameId,
          union: data.union,
          userStatus: data.userStatus,
          policyGroup: data.policyGroup,
          specotCrew: data.specotCrew,
        },
      },
    }),
    {}
  );
};
