import React from 'react';
import { TimecardAuditMsg, SoftAuditTypes } from 'features/timecards/types';
interface SoftAuditModalProps {
  msgType: 'Save/Approve' | 'Save/Verify';
  setSoftAuditConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  handleSoftAudit: (
    ptoMsgs: TimecardAuditMsg[],
    holidayMsgs: TimecardAuditMsg[],
    holidayNotWorked: TimecardAuditMsg[],
    type: SoftAuditTypes
  ) => void;
  ptoMsgs: TimecardAuditMsg[];
  holidayMsgs: TimecardAuditMsg[];
  holidayNotWorked: TimecardAuditMsg[];
  closeModal: () => void;
}
type SoftAuditModalProperties = Record<
  SoftAuditTypes,
  {
    modalTitle: string;
    modalBodyText: string;
    children: JSX.Element;
    alert?: { type: 'info'; message: JSX.Element };
    onConfirm: () => void;
  }
>;

interface SoftAuditMsgBodyProps {
  msgs: TimecardAuditMsg[];
  modalBodyText: string;
}
export const SoftAuditMsgBody = ({
  msgs,
  modalBodyText,
}: SoftAuditMsgBodyProps) => {
  return (
    <div className="w-full">
      <div className="text-sm text-gray-700">
        <p data-testid="soft-audit-modal-body-text">{modalBodyText}</p>
        {msgs.map((message, i) => {
          return (
            <p key={i} className="mt-4" data-testid="soft-audit-messages">
              {message.msg}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export const getSoftAuditModalProperties = ({
  msgType,
  setSoftAuditConfirm,
  handleSoftAudit,
  ptoMsgs,
  holidayMsgs,
  holidayNotWorked,
  closeModal,
}: SoftAuditModalProps) => {
  //PTO
  const ptoModalTitle = 'Worked Time and PTO Time';
  const ptoModalBodyText = `You are attempting to ${msgType} for worked time and PTO time.`;
  //Holiday
  const holidayModalTitle = 'Holiday Worked and Holiday Pay';
  const holidayModalBodyText = `You are attempting to ${msgType} for worked time on a holiday(paid as Holiday Worked).`;
  const softAuditModalProperties: SoftAuditModalProperties = {
    pto: {
      modalTitle: ptoModalTitle,
      modalBodyText: ptoModalBodyText,
      children: (
        <SoftAuditMsgBody msgs={ptoMsgs} modalBodyText={ptoModalBodyText} />
      ),
      onConfirm: () => {
        setSoftAuditConfirm(false);
        if (holidayMsgs.length > 0) {
          closeModal();
          handleSoftAudit([], holidayMsgs, [], 'holiday');
        } else {
          setSoftAuditConfirm(true);
          closeModal();
        }
      },
    },
    holiday: {
      modalTitle: holidayModalTitle,
      alert: {
        type: 'info',
        message: (
          <>
            <p>
              ‘Holiday Pay’ earnings do not require hours to be entered
              <br />
              <br />
              ‘Holiday Worked’ earnings require hours to be entered if employee
              really worked on the actual holiday
            </p>
          </>
        ),
      },
      modalBodyText: holidayModalBodyText,
      children: (
        <SoftAuditMsgBody
          msgs={holidayMsgs}
          modalBodyText={holidayModalBodyText}
        />
      ),
      onConfirm: () => {
        setSoftAuditConfirm(true);
        closeModal();
      },
    },
    holidayNotWorked: {
      modalTitle: 'Holiday Not Worked Alert',
      alert: {
        type: 'info',
        message: (
          <p>
            Employees scheduled to work on a day designated as a holiday that
            are not required to work the holiday, use absentee code ‘HS’ to get
            credit for the holiday not worked.
          </p>
        ),
      },
      modalBodyText: '',
      children: <SoftAuditMsgBody msgs={holidayNotWorked} modalBodyText={''} />,
      onConfirm: () => {
        setSoftAuditConfirm(true);
        closeModal();
      },
    },
  };
  return softAuditModalProperties;
};
