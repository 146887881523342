import {
  Button,
  IconButton,
  Modal,
  Sidepane,
  Tab,
  UnstyledButton,
} from '@uss/react-core';
import { notify } from 'components/Notification/useNotification';
import { useEditModel } from 'features/model-listing/api/mutations';
import { useModelDetailStore } from 'features/model-listing/state/model-detail-store';
import { EditModelReqBody } from 'features/model-listing/types';
import { useState } from 'react';
import { BsExclamationCircle, BsX } from 'react-icons/bs';
import { queryClient } from 'services/api/react-query';
import { DeleteCrewRotations } from './DeleteCrewRotations';
import { DeleteWeeks } from './DeleteWeeks';

interface DeleteWeeksCrewsDrawerProps {
  openDrawer: boolean;
  setOpenDrawer: (val: boolean) => void;
}

export default function DeleteWeeksCrewsRotationsDrawer({
  openDrawer,
  setOpenDrawer,
}: DeleteWeeksCrewsDrawerProps) {
  const handleClose = () => {
    setOpenDrawer(false);
  };
  const [openDeleteCrewRotation, setOpenDeleteCrewRotation] =
    useState<boolean>(false);
  const [crewRotationPayload, setCrewRotationPayload] =
    useState<EditModelReqBody | null>(null);

  const [openDeleteWeeks, setOpenDeleteWeeks] = useState<boolean>(false);
  const [deleteWeeksPayload, setDeleteweeksPayload] =
    useState<EditModelReqBody | null>(null);

  const store = useModelDetailStore();
  const { mutateAsync: editModelMutateAsync } = useEditModel(
    store.modelDetail?.id ? store.modelDetail.id : ''
  );
  const handleDeleteCrewRotation = async () => {
    if (crewRotationPayload) {
      await editModelMutateAsync(crewRotationPayload, {
        onSuccess: async () => {
          setOpenDrawer(false);
          setOpenDeleteCrewRotation(false);

          notify.success({
            message: `You have deleted the crew.`,
          });
          await queryClient.resetQueries({
            queryKey: ['model-details'],
            type: 'active',
          });
        },
      });
    }
  };

  const handleDeleteWeeks = async () => {
    if (deleteWeeksPayload) {
      await editModelMutateAsync(deleteWeeksPayload, {
        onSuccess: async () => {
          setOpenDrawer(false);
          setOpenDeleteWeeks(false);

          notify.success({
            message: `You have deleted the week(s).`,
          });
          await queryClient.resetQueries({
            queryKey: ['model-details'],
            type: 'active',
          });
        },
      });
    }
  };

  return (
    <>
      <Sidepane
        ariaLabel="filter"
        placement={'right'}
        isOpen={openDrawer}
        onOpenChange={handleClose}
        className="w-full sm:w-2/5 lg:w-1/4"
      >
        <div className="w-full flex gap-10 items-center pb-7">
          <h3 className="text-lg flex-grow">Delete Week(s) / Crew(s)</h3>
          <IconButton onPress={handleClose}>
            <BsX className="text-gray-600" />
          </IconButton>
        </div>
        <div className="flex flex-col flex-grow">
          <Tab
            orientation="horizontal"
            selectedKey={'2'}
            equalWidthTabs
            tabLinks={[
              {
                id: 'DeleteWeeks',
                title: 'Week',
                content: (
                  <DeleteWeeks
                    setOpenDrawer={setOpenDrawer}
                    openDrawer={openDrawer}
                    setOpenDeleteWeeks={setOpenDeleteWeeks}
                    setDeleteWeeksPayload={setDeleteweeksPayload}
                  />
                ),
              },
              {
                id: 'DeleteCrewRotations',
                title: 'Crew Rotation',
                content: (
                  <DeleteCrewRotations
                    setOpenDrawer={setOpenDrawer}
                    openDrawer={openDrawer}
                    setOpenDeleteCrewRotation={setOpenDeleteCrewRotation}
                    setCrewRotationPayload={setCrewRotationPayload}
                  />
                ),
              },
            ]}
          />
        </div>
      </Sidepane>
      {/* Delete Crew Rotations confirmation Modal */}
      <Modal
        ariaLabel={'Delete Crew Rotation(s)'}
        isOpen={openDeleteCrewRotation}
        size="default"
      >
        <Modal.Header>
          <div className="flex flex-grow items-center ">
            <BsExclamationCircle className="text-red-500 mr-2 text-3xl" />{' '}
            <h2>Delete Crew(s)</h2>
          </div>
          <UnstyledButton>
            <BsX
              size="2rem"
              onClick={() => setOpenDeleteCrewRotation(false)}
              className="cursor-pointer absolute sm:relative right-2 sm:right-0"
            />
          </UnstyledButton>
        </Modal.Header>
        <Modal.Content>
          Are you sure you want to delete the crew(s)?
        </Modal.Content>
        <Modal.Footer>
          <div className="flex gap-2">
            <Button
              variant={'outline'}
              onPress={() => setOpenDeleteCrewRotation(false)}
            >
              Cancel
            </Button>
            <Button
              variant={'filled'}
              color={'danger'}
              onPress={() => handleDeleteCrewRotation()}
            >
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Delete Weeks confirmation Modal */}

      <Modal
        ariaLabel={'Delete Week(s)'}
        isOpen={openDeleteWeeks}
        size="default"
      >
        <Modal.Header>
          <div className="flex flex-grow items-center ">
            <BsExclamationCircle className="text-red-500 mr-2 text-3xl" />{' '}
            <h2>Delete Week(s)</h2>
          </div>
          <UnstyledButton>
            <BsX
              size="2rem"
              onClick={() => setOpenDeleteWeeks(false)}
              className="cursor-pointer absolute sm:relative right-2 sm:right-0"
            />
          </UnstyledButton>
        </Modal.Header>
        <Modal.Content>
          Are you sure you want to delete the week(s)?
        </Modal.Content>
        <Modal.Footer>
          <div className="flex gap-2">
            <Button
              variant={'outline'}
              onPress={() => setOpenDeleteWeeks(false)}
            >
              Cancel
            </Button>
            <Button
              variant={'filled'}
              color={'danger'}
              onPress={() => handleDeleteWeeks()}
            >
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
