import React, { useEffect, useState } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Checkbox,
  Datepicker,
  Select,
  TextArea,
} from '@uss/react-components';
import useCountDays from 'hooks/use-count-days';
import { addYears, format, isValid, parse, subYears } from 'date-fns';
import { DATE_RANGE_PAYCODES, PTO_ADD_CONTEXT } from 'features/paid-time-off/constants';
import { BsInfoCircle } from 'react-icons/bs';
import ValidationError from 'components/Validation/ValidationError';
import { RequestPtoSchema } from 'features/paid-time-off/schemas/request-pto-schema';
import { useNavigate, useParams } from 'react-router-dom';
import { useLoggedInUser } from 'features/people/api';
import { useHolidayDates } from 'features/holidays';
import checkCrossOverWeek from 'features/paid-time-off/utility/checkCrossOverWeek';
import { appNewDate } from 'utils/appNewDate';
import { getPtoPaycodes } from 'features/paid-time-off/utility/getPtoPaycodeList';
import { useAddPtoRequest } from 'features/paid-time-off/api/mutations';
import { useEmpPTODetailsByPtoId } from 'features/paid-time-off/api/queries';
import { z } from 'zod';
import { notify } from 'components/Notification/useNotification';
import { AddPTOPlantType } from 'features/paid-time-off/types/employee-pto';

export type PtoFormType = z.infer<typeof RequestPtoSchema>;

const RequestPtoForm = ({
  defaultValues,
  isReadonly,
}: {
  defaultValues: PtoFormType;
  isReadonly?: boolean;
}) => {
  const { ptoId = '' } = useParams<'ptoId'>();
  const { data: user, status } = useLoggedInUser();
  const { data: holidayList } = useHolidayDates(
    user?.holidayType ?? 'brs-observed'
  );
  const { mutateAsync: requestPto, isPending: changeAwaiting } =
    useAddPtoRequest();
  const { data: ptoDetails } = useEmpPTODetailsByPtoId(ptoId);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<PtoFormType>({
    resolver: zodResolver(RequestPtoSchema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (user && status === 'success') {
      setValue('ussId', user.ussId.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, status]);

  const [dateRange, setDateRange] = useState(false);
  //watch
  const excludeHolidays = useWatch({ control, name: 'excludeHolidays' });
  const excludeWeekends = useWatch({ control, name: 'excludeWeekends' });
  const notes = useWatch({ control, name: 'notes' });
  const start = useWatch({ control, name: 'startDate' });
  const end = useWatch({ control, name: 'endDate' });

  const paycodeDesc = useWatch({ control, name: 'paycodeDescription' });

  //navigation
  const navigate = useNavigate();

  //cancel button
  const handleCancel = () => {
    navigate('/my-pto-nu');
  };

  //request pto
  const handleRequest = async (data: PtoFormType) => {
    data.chargeYear = format(
      parse(data.startDate, 'yyyy-MM-dd', new Date()),
      'yyyy'
    );
    //Adding context param
    data.context = PTO_ADD_CONTEXT.MY_PTO;
    await requestPto(data, {
      onSuccess: () => {
        notify.success({
          message:
            data.endDate === null
              ? 'You have requested a PTO.'
              : 'You have requested PTO for a date range.',
        });
        handleCancel();
      },
    });
  };

  /* Date range count logic:START*/
  const parsedStart = parse(
    isValid(new Date(start)) ? start : format(new Date(), 'yyyy-MM-dd'),
    'yyyy-MM-dd',
    new Date()
  );
  const parsedEnd = parse(
    end && isValid(new Date(end))
      ? end
      : format(
          isValid(new Date(start)) ? new Date(start) : new Date(),
          'yyyy-MM-dd'
        ),
    'yyyy-MM-dd',
    new Date()
  );
  const rangeCount = useCountDays({
    start: parsedStart,
    end: parsedEnd,
    holidays: holidayList,
  });
  const getRangeCount = () => {
    if (excludeWeekends && excludeHolidays) {
      return rangeCount.numberOfDays.excludeWeekendsandHolidays.formatted;
    } else if (excludeHolidays) {
      return rangeCount.numberOfDays.excludeHolidays.formatted;
    } else if (excludeWeekends) {
      return rangeCount.numberOfDays.excludeWeekends.formatted;
    } else {
      return rangeCount.numberOfDays.formatted;
    }
  };
  // Date range allowed:P1 through P8, P0,VH, VD
  const _paycode = useWatch({ control, name: 'paycode' });
  const hasDateRange = DATE_RANGE_PAYCODES.includes(_paycode);
  /* Date range count logic :END*/
  // check if charge year active
  const isRangeInCrossOverWeek = checkCrossOverWeek(
    start,
    hasDateRange ? end : undefined
  );
  const hasChargeYear =
    (_paycode === 'VH' || _paycode === 'VD') && isRangeInCrossOverWeek;

  const years = [
    format(subYears(new Date(), 1), 'yyyy'),
    format(new Date(appNewDate()), 'yyyy'),
    format(addYears(new Date(), 1), 'yyyy'),
  ];

  const { status: ptoStatus } = getValues();
  useEffect(() => {
    if (_paycode) {
      const descArr =
        user && getPtoPaycodes(user.ptoClass, user.shiftAssignment);
      const matchedDesc = descArr?.filter((item) => item.paycode === _paycode);
      matchedDesc && setValue('paycodeDescription', matchedDesc[0].description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_paycode]);
  return (
    <>
      {status === 'success' ? (
        <form
          className="@container"
          onSubmit={handleSubmit((e) => handleRequest(e))}
        >
          <div className="bg-white sm:bg-white rounded-md p-4">
            <div className="max-w-2xl mx-auto py-8 px-8 ">
              <div className="grid grid-cols-1 @xl:grid-cols-2 gap-4 ">
                {ptoStatus !== 'blackout' && (
                  <div className="@xl:col-start-1 @xl:col-end-2">
                    <label
                      className="text-xs text-gray-700 font-normal"
                      htmlFor="paycode"
                    >
                      Pay Code
                    </label>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="paycode"
                      render={({ field, fieldState: { invalid, error } }) => (
                        <>
                          <Select
                            {...field}
                            selected={field.value}
                            onSelect={field.onChange}
                            className="w-full"
                            disabled={
                              ptoDetails?.status === 'blackout' ||
                              isReadonly === true ||
                              defaultValues.status === 'denied'
                            }
                          >
                            <Select.Button
                              label={
                                field.value
                                  ? field.value + ` - ${paycodeDesc}`
                                  : 'Select Paycode'
                              }
                              error={invalid}
                            />
                            <Select.Options error={invalid}>
                              {getPtoPaycodes(
                                user.ptoClass,
                                user.shiftAssignment
                              ).map((item, itemIdx) => (
                                <Select.Option
                                  key={itemIdx}
                                  value={item.paycode}
                                >
                                  <span>
                                    {item.paycode} - {item.description}
                                  </span>
                                </Select.Option>
                              ))}
                            </Select.Options>
                          </Select>
                          {invalid && (
                            <ValidationError
                              msg={error?.message ?? ''}
                              name="paycodeRequired"
                            />
                          )}
                        </>
                      )}
                    />
                  </div>
                )}
                {ptoId && ptoId.length > 0 ? (
                  <div className="@xl:col-start-1 @xl:col-end-2">
                    <Controller
                      control={control}
                      name="ptoDate"
                      render={({ field, fieldState: { invalid, error } }) => (
                        <Datepicker
                          isLabel={true}
                          onDateChange={(date) => {
                            field.onChange(format(date, 'yyyy-MM-dd'));
                            setValue('chargeYear', format(date, 'yyyy'));
                          }}
                          calendarType={'date'}
                          id="pto-date"
                          ariaLabelledBy={'Date'}
                          {...register('ptoDate')}
                          isError={invalid}
                          errormessage={error?.message ?? ''}
                          startDate={
                            field.value
                              ? parse(field.value, 'yyyy-MM-dd', new Date())
                              : parse(
                                  defaultValues.ptoDate,
                                  'yyyy-MM-dd',
                                  new Date()
                                )
                          }
                          disabled={true}
                        />
                      )}
                    />
                  </div>
                ) : (
                  <>
                    <div className="@xl:col-span-2 ">
                      {hasDateRange && (
                        <div className="flex justify-start items-center">
                          <Checkbox
                            id="date-range"
                            label="Date Range"
                            type="checkbox"
                            checked={dateRange}
                            onChange={() => {
                              setDateRange(!dateRange);
                              if (dateRange) {
                                setValue('endDate', null);
                              }
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="@xl:col-span-1">
                      <Controller
                        control={control}
                        name="startDate"
                        render={({ field, fieldState: { invalid, error } }) => (
                          <Datepicker
                            isLabel={true}
                            ariaLabelledBy={'Start Date'}
                            onDateChange={(date) => {
                              field.onChange(format(date, 'yyyy-MM-dd'));
                            }}
                            calendarType={'date'}
                            id="start-date"
                            {...register('startDate')}
                            isError={invalid}
                            errormessage={error?.message ?? ''}
                          />
                        )}
                      />
                    </div>
                    <div className="@xl:col-span-1">
                      {hasDateRange && dateRange && (
                        <>
                          <Controller
                            control={control}
                            name="endDate"
                            render={({
                              field,
                              fieldState: { invalid, error },
                            }) => (
                              <Datepicker
                                isLabel={true}
                                onDateChange={(date) => {
                                  field.onChange(format(date, 'yyyy-MM-dd'));
                                }}
                                calendarType={'date'}
                                id="end-date"
                                ariaLabelledBy={'End Date'}
                                {...register('endDate')}
                                isError={invalid}
                                errormessage={error?.message ?? ''}
                              />
                            )}
                          />
                          {start && end && errors.endDate === undefined && (
                            <div className="flex flex-row mt-2">
                              {getRangeCount().length > 0 && (
                                <>
                                  <BsInfoCircle className="text-gray-700" />
                                  <span
                                    data-testId="range-count-text"
                                    className="ml-2 text-sm text-gray-700"
                                  >
                                    {getRangeCount()} Selected
                                  </span>
                                </>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    {hasDateRange && dateRange && (
                      <div className="flex @xl:col-span-2">
                        <div className="flex justify-start items-center">
                          <Checkbox
                            id="excludeWeekends"
                            label="Exclude Weekends"
                            {...register('excludeWeekends')}
                            disabled={
                              rangeCount.numberOfDays.weekends.value === 0
                            }
                          />
                          <p>{errors.excludeWeekends?.message}</p>
                        </div>
                        <div className="flex justify-start items-center">
                          <Checkbox
                            id="excludeHolidays"
                            label="Exclude Holiday(s)"
                            {...register('excludeHolidays')}
                            disabled={
                              rangeCount.numberOfDays.holidays.value === 0
                            }
                          />
                          <p>{errors.excludeHolidays?.message}</p>
                        </div>
                      </div>
                    )}
                    {(user.plantType === AddPTOPlantType.integratedMill ||
                      user.plantType === AddPTOPlantType.office) && (
                      <div className="@xl:col-start-1 @xl:col-end-2">
                        <label
                          className="text-xs text-gray-700 font-normal"
                          htmlFor="chargeYear"
                        >
                          Charge Year
                        </label>
                        <Controller
                          control={control}
                          name="chargeYear"
                          defaultValue={years[1]}
                          render={({
                            field,
                            fieldState: { invalid, error },
                          }) => (
                            <Select
                              {...field}
                              selected={field.value}
                              onSelect={field.onChange}
                              className="w-full"
                              disabled={!hasChargeYear}
                              defaultValue={years[1]}
                            >
                              <Select.Button
                                label={field.value ? field.value : 'Select'}
                              />
                              <Select.Options>
                                {years.map((item, itemIdx) => (
                                  <Select.Option key={itemIdx} value={item}>
                                    <span>{item}</span>
                                  </Select.Option>
                                ))}
                              </Select.Options>
                            </Select>
                          )}
                        />
                      </div>
                    )}
                  </>
                )}

                <div className="@xl:col-span-2">
                  <label
                    className="text-xs text-gray-700 font-normal"
                    htmlFor="notes"
                  >
                    Notes (Optional)
                  </label>
                  <div className="col-span-2 mb-4 lg:mb-0">
                    <TextArea
                      maxLength={100}
                      label="Note"
                      id="form-comment"
                      placeholder={'Enter text'}
                      {...register('notes')}
                      hideLabel
                      error={!!errors.notes?.message}
                      errorMessage={errors.notes?.message}
                      disabled={isReadonly}
                    />
                    <div className="col-span-2 text-xs mb-2 text-gray-600 text-right">
                      {100 - (notes ? notes.length : 0)} Characters left
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {ptoId === '' && (
            <div className="flex justify-end flex-wrap mt-4">
              <Button
                onClick={handleCancel}
                variant="outline"
                className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto "
                type="button"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
                type="submit"
                disabled={changeAwaiting}
              >
                Request
              </Button>
            </div>
          )}
        </form>
      ) : (
        <>Loading...</>
      )}
    </>
  );
};

export default RequestPtoForm;
