import { Avatar, TextArea } from '@uss/react-components';
import SeparatedLabels from 'components/SeparatedLabels/SeparatedLabels';
import Title from 'components/Typography/Title';
import { format, parseISO } from 'date-fns';
import { CommentRole } from 'features/probationary-tracking/types/employeeReviewDetails';
import { Comment } from 'features/probationary-tracking/types/employeeReviewDetails';
import useWindowSize from 'hooks/useWindowSize';
import _ from 'lodash';
import { ZodErrorType } from 'types/common';
import { getInitials } from 'utils';

interface Props {
  comments: Comment[] | null;
  readOnly: boolean;
  errors: ZodErrorType;
  editComment: string;
  setEditComment: React.Dispatch<React.SetStateAction<string>>;
  showAddCommentsSection: boolean;
}

export const CommentsSection = ({
  comments,
  readOnly,
  errors,
  editComment,
  setEditComment,
  showAddCommentsSection,
}: Props) => {
  const { width } = useWindowSize();

  const handleChangeComment = (value: string): void => {
    setEditComment(value);
  };

  const getCommentRoleLabel = (role: CommentRole) => {
    switch (role) {
      case CommentRole.REVIEWER:
        return 'Reviewer';
      case CommentRole.SUPERVISOR:
        return 'Supervisor';
      case CommentRole.HR_LR:
        return 'HR/LR';
    }
  };

  return (
    <>
      {comments && comments.length > 0 && (
        <>
          <div className="mt-4 mb-2">
            <span>Comments</span>
          </div>
          {comments.map((comment, index) => (
            <div
              className="lg:grid lg:grid-cols-1 gap-x-10 gap-y-4 w-full mb-4"
              key={index}
            >
              <div className="flex items-center flex-grow">
                <>
                  <span>
                    <Avatar color={2} size="medium">
                      {getInitials(comment.firstName, comment.lastName)}
                    </Avatar>
                  </span>
                  <div className="ml-4 flex-grow">
                    <Title as="h2" aria-label="Employee Name">
                      {comment.displayName}
                    </Title>
                    <SeparatedLabels
                      items={[
                        {
                          label: 'Role',
                          value: getCommentRoleLabel(comment.role),
                        },
                        {
                          label: 'Review Date',
                          value: format(parseISO(comment.date), 'MMM dd, yyyy'),
                        },
                      ]}
                    />
                  </div>
                </>
              </div>
              <TextArea
                maxLength={500}
                label={''}
                data-testid={`comment-${index}`}
                id={`comment-${index}`}
                placeholder={''}
                value={comment.comment}
                disabled={true}
                rows={!!width && width < 768 ? 6 : 3}
              />
            </div>
          ))}
          {showAddCommentsSection && (
            <div className="bg-gray-200 h-0.5 col-span-2 my-6"></div>
          )}
        </>
      )}
      {showAddCommentsSection && (
        <>
          <div className="mb-2">
            <span>Add Comments</span>
          </div>
          <div className="relative col-span-2 mb-4 lg:mb-0">
            {!readOnly && (
              <div className="mb-2">
                <div
                  className="text-xs text-gray-600 md:w-2/3"
                  data-testid="comment-msg"
                >
                  If any of the above categories are rated as “Does Not Meet,”
                  please discuss the deficiency with the employee and note the
                  specific reasons for the low rating in this section.
                </div>
              </div>
            )}
            <TextArea
              maxLength={500}
              label="Comment"
              data-testid="add-comment-textaria"
              id="form-comments"
              placeholder={''}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                handleChangeComment(e.target.value);
              }}
              value={editComment}
              error={
                !_.isEmpty(errors) &&
                typeof errors.comment === 'string' &&
                errors.comment.length > 0
              }
              errorMessage={errors.comment}
            />
            <div
              className="col-span-2 text-xs mb-2 mt-1 text-gray-600 text-right"
              data-testid="comment-length"
            >
              {editComment.length}/500
            </div>
          </div>
        </>
      )}
    </>
  );
};
