import { z } from 'zod';
import { hoursAndTurn } from '../validations/hours-and-turn';
import { negativeHoursTurn } from '../validations/negative-hours-turn';
import { partialHours } from '../validations/partial-hours';
import { totalTurnHours } from '../validations/total-turn-hours';
import { varianceNoPaycode } from '../validations/variance-no-paycode';

import { TIMECARD_STATUS } from '../types';

const TimecardEffortsSchema = z
  .object({
    turn: z.string().nullable(),
    hours: z.number().nullable(),
  })
  .refine((data) => negativeHoursTurn({ hours: data.hours, turn: data.turn }), {
    message: 'cannot have negative hours on a turn',
    path: ['NegativeHoursTurn'],
  })
  .refine((data) => hoursAndTurn(data), {
    message: 'cannot have hours without a turn selected',
    path: ['hoursWithoutTurn'],
  })
  .refine((data) => partialHours(data.hours), {
    message: 'hours fields for partial hours can only be .25 or .50 or .75',
    path: ['partialHours'],
  })
  .refine((data) => totalTurnHours({ hours: data.hours, turn: data.turn }), {
    message: 'cannot have hours more than 24',
    path: ['hoursMoreThan24'],
  });

const ScheduleVarianceSchema = z.object({
  payCode: z.string().nullable(),
  variance: z.number().nullable(),
});

export const TimecardStatusCodeSchema = z.nativeEnum(TIMECARD_STATUS);

export const TimecardSchema = z.object({
  id: z.string(),
  date: z.string(),
  elapsedTime: z.string().nullable(),
  scheduled: TimecardEffortsSchema,
  worked: TimecardEffortsSchema,
  approved: TimecardEffortsSchema,
  scheduleVariance: ScheduleVarianceSchema,
  paidHoursNotWorked: z.number().nullable(),
  exceptionCode: z.string().nullable(),
  exceptionCodeDescription: z.string().nullable(),
  comments: z.string().nullable(),
  blank: z.boolean(),
  context: z
    .union([
      z.literal('pay'),
      z.literal('scheduling'),
      z.literal('my-schedule'),
    ])
    .optional(),
});
export const TimecardRegularDaySchema = TimecardSchema.refine(
  (data) => {
    return varianceNoPaycode(
      data.scheduleVariance.variance,
      data.scheduleVariance.payCode
    );
  },
  {
    message: 'paycode should be selected if scheduled variance is added',
    path: ['varianceNoPaycode'],
  }
);
