import { axios } from 'services/api/axios';
import { useMutation } from '@tanstack/react-query';
import { WMSError } from 'types/common';
import useNotification from 'components/Notification/useNotification';
import { queryClient } from 'services/api/react-query';
export interface AssignReviewer {
  reviewerUssId: string;
  empUssIds: string[];
}
export interface ReassignReviewer {
  reviewerUssId: string;
}
export interface UpdateReviewerResponse {
  success: boolean;
  message: string;
}
export const useAssignReviewer = () => {
  const notify = useNotification();
  return useMutation<UpdateReviewerResponse, WMSError, AssignReviewer>({
    mutationFn: (body: AssignReviewer) => assignReviewer(body),
    meta: {
      errorMessage: ' Unable to update reviewer',
    },
    onSuccess: async (response, request) => {
      await queryClient.refetchQueries({
        queryKey: ['probationary-employee-summary'],
      });
      await queryClient.refetchQueries({
        queryKey: ['probationary-employees-list'],
      });
      notify.success({
        message:
          request.empUssIds.length === 1
            ? 'You have successfully assigned reviewer to 1 employee'
            : `You have successfully assigned reviewer to ${request.empUssIds.length} employees`,
      });
    },
  });
};

export const useReassignReviewer = (ussId: string) => {
  const notify = useNotification();
  return useMutation<UpdateReviewerResponse, WMSError, ReassignReviewer>({
    mutationFn: (body: ReassignReviewer) => reassignReviewer(ussId, body),
    meta: {
      errorMessage: ' Unable to update reviewer',
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ['performance-review-details'],
      });
      notify.success({
        message: `You have successfully re-assigned reviewer.`,
      });
    },
  });
};
const assignReviewer = (
  body: AssignReviewer
): Promise<UpdateReviewerResponse> => {
  return axios.patch(`/v1/prob-emp/assign-reviewer`, body);
};
const reassignReviewer = (
  ussId: string,
  body: ReassignReviewer
): Promise<UpdateReviewerResponse> => {
  return axios.patch(`/v1/prob-emp/${ussId}/assign-reviewer`, body);
};
