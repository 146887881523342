/**
 * @description (Save, Save & Approve) Cannot have negative hours on a turn
 * @returns
 */

import { TimecardEfforts } from 'features/timecards/types';

export const negativeHoursTurn = (value: TimecardEfforts) => {
  return value.turn !== '' &&
    value.turn !== null &&
    value.hours != null &&
    value.hours < 0
    ? false
    : true;
};
