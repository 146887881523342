import {
  format,
  getHours,
  isBefore,
  isMonday,
  parseISO,
  startOfWeek,
  subWeeks,
  getDay,
  addWeeks,
  startOfYear,
  subYears,
  addYears,
  endOfYear,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { WeekStart } from 'types/common';
import { getApplicationStartDate } from 'utils';

interface Options {
  /** Options for the week start date. 0 = Sunday, 6 = Saturday */
  weekStart: WeekStart;
}

const currentDate = (options: Options) => {
  /**
   * Current date in EST/EDT
   */

  const currentESTDate = utcToZonedTime(new Date(), 'America/New_York');

  /**
   * Boolean to determine if the current week is open or not.  Evaluates true if it is past Monday 12pm
   */
  const currentWeekOpen =
    (isMonday(currentESTDate) && !(getHours(currentESTDate) < 12)) ||
    (getDay(currentESTDate) > 1 && getDay(currentESTDate) < 6);

  const getWeekStart = (date: Date) => {
    return format(
      startOfWeek(date, {
        weekStartsOn: options.weekStart,
      }),
      'yyyy-MM-dd'
    );
  };

  /**
   * Application Start Date, the data in which the application starts being used in production.  Nobody should be able to do anything with weeks before this date.
   */

  const applicationStartDate = startOfWeek(
    parseISO(getApplicationStartDate()),
    {
      weekStartsOn: options.weekStart,
    }
  );

  /**
   * Current Week formatted in ISO8601
   */

  const currentWeek = getWeekStart(currentESTDate);

  /**
   * Previous week formatted in ISO8601
   */

  const previousWeek = getWeekStart(subWeeks(currentESTDate, 1));

  /**
   * The current open pay period.  Pay periods closed at 12pm EST/EDT on Mondays.
   *
   * @example An example of this would be to determine the default week that should display when the page initially loads.
   */

  const currentOpenWeek = currentWeekOpen ? currentWeek : previousWeek;

  /**
   * Start of the Current Week formatted in ISO8601
   */
  const currentWeekStart = parseISO(currentWeek);

  const nextWeekStart = addWeeks(currentWeekStart, 1);
  const previousWeekStart = subWeeks(currentWeekStart, 1);

  /**
   * Beginning of Last Year Cutoff Date
   */

  const lastYearCutOff = startOfYear(subYears(currentWeekStart, 1));

  /**
   * End of  Next Year Cutoff Date
   */
  const nextYearCutOff = endOfYear(addYears(currentWeekStart, 1));

  /**
   * Cutoff Date for the application.  This tells you how far back the user should be able to go.
   */

  const cutOffDate = isBefore(lastYearCutOff, applicationStartDate)
    ? applicationStartDate
    : lastYearCutOff;

  return {
    applicationStartDate,
    nextWeekStart,
    currentWeekStart,
    cutOffDate,
    currentOpenWeek,
    previousWeekStart,
    nextYearCutOff,
  };
};

const useCurrentDate = (options: Options) => {
  return {
    ...currentDate({ ...options }),
  };
};

export default useCurrentDate;
