import Page from 'components/Layouts/Page';
import { ROLES } from 'features/roles';
import useUserRoles from 'hooks/useUserRoles';
import { NavLink } from 'react-router-dom';
import { Details } from '../[crewId]/details';

export const AddCrewLink = () => {
  const { roles } = useUserRoles();

  return (
    <Page
      breadcrumbs={[
        <NavLink key="bc-1" to="/admin">
          Admin
        </NavLink>,
        <NavLink key="bc-2" to="/admin/crew-maintenance/crew">
          Crew Maintenance
        </NavLink>,
      ]}
      heading={roles.includes(ROLES.PAY_APPROVER) ? 'Request Crew' : 'Add Crew'}
    >
      <Page.Section>
        <Details />
      </Page.Section>
    </Page>
  );
};
