import { Accordion } from '@uss/react-components';
import CardsLoader from 'components/Loaders/CardsLoader';
import SeparatedLabels from 'components/SeparatedLabels/SeparatedLabels';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';
import { format, parseISO } from 'date-fns';
import { PtoDetailsCardType } from 'features/paid-time-off/types';
import { getPtoStatusText } from 'features/paid-time-off/utility/getPtoStatusText';
import useWindowSize from 'hooks/useWindowSize';

interface Props {
  data: PtoDetailsCardType | undefined;
  isPtoDetailsLoading: boolean;
}

const PTODetailsCard = ({ data, isPtoDetailsLoading }: Props) => {
  const { width } = useWindowSize();

  if (isPtoDetailsLoading) {
    return <CardsLoader variant="row-grid" count={1} />;
  }

  const DetailsHeader = () => {
    return (
      <div className="flex items-center relative h-full w-full lg:w-2/5 2xl:w-1/4 lg:pr-8 lg:after:w-0.5 lg:after:absolute lg:after:right-0 lg:after:h-full lg:after:bg-gray-200">
        <div className="flex items-center flex-grow ">
          <div className="ml-4 flex-grow">
            <Title as="h2" aria-label="PTO Date">
              {data ? format(parseISO(data.startDate), 'MMM dd, yyyy') : '--'}
            </Title>
            {data?.status !== 'blackout' ? (
              <SeparatedLabels
                items={[
                  {
                    label: 'PTO Hours',
                    value: data
                      ? `${data.paycode} - ${data.paycodeDescription ?? ''}`
                      : '-',
                  },
                  {
                    label: 'PTO Status',
                    value: data ? getPtoStatusText(data.status) : '--',
                  },
                ]}
              />
            ) : (
              <span className="text-sm text-gray-700" aria-label="PTO Status">
                {getPtoStatusText(data.status)}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };
  const DetailsBody = () => {
    return (
      <>
        <div className="flex flex-wrap sm:flex-nowrap w-full lg:w-3/5 2xl:w-3/4 lg:pl-8">
          <LabeledText
            className="w-1/2 sm:w-auto sm:flex-grow pr-0.5"
            label="Requested On"
          >
            {data
              ? format(new Date(data.requestedOn), 'MMM dd, yyyy, hh:mm aa')
              : '--'}
          </LabeledText>
          <LabeledText
            className="w-1/2 sm:w-auto sm:flex-grow pr-0.5"
            label="Approved/Denied On"
          >
            {data?.status === 'blackout'
              ? '-'
              : data?.approvedOrDeniedOn
              ? format(
                  new Date(data.approvedOrDeniedOn),
                  'MMM dd, yyyy, hh:mm aa'
                )
              : '--'}
          </LabeledText>
        </div>
      </>
    );
  };
  return (
    <>
      {width &&
        (width < 1024 ? (
          <Accordion
            variant="default"
            className="shadow-md bg-white rounded-lg border border-blue-600"
          >
            <Accordion.Button variant="default">
              <DetailsHeader />
            </Accordion.Button>
            <Accordion.Panel variant="default">
              <div className="flex flex-row flex-wrap w-full  items-center border-t border-gray-200 pt-4">
                <DetailsBody />
              </div>
            </Accordion.Panel>
          </Accordion>
        ) : (
          <div className="flex items-center shadow-md w-full p-4 bg-white rounded-lg h-24 border border-blue-600">
            <DetailsHeader />
            <DetailsBody />
          </div>
        ))}
    </>
  );
};

export default PTODetailsCard;
