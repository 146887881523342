import { parseISO } from 'date-fns';
import parse from 'date-fns/parse';
import _ from 'lodash';
import { CalendarEvent, CalendarView } from 'components/CalendarV2/types';
import { PtoDetail, PtoStatus } from '../../../features/paid-time-off/types';
import { getPtoStatusText } from '../utility/getPtoStatusText';
import { getColors } from '../utility/ptoStatusColor';

interface PtoGroupType {
  key: string;
  date: string;
  hours: string;
  status: PtoStatus;
}

//create events object for calendar
export const usePtoCalendarDates = (
  PtoList: PtoDetail[],
  calendarType: CalendarView
) => {
  const eventList: CalendarEvent[] = [];

  if (calendarType === 'year') {
    const ptoData: PtoDetail[] = Object.assign(PtoList) as PtoDetail[];
    const result: PtoGroupType[] = [];
    const prevValue: Record<string, PtoGroupType> = {};

    //create array of months with total hours
    ptoData.forEach((value: PtoDetail) => {
      const key: string =
        parse(value.startDate, 'yyyy-MM-dd', new Date()).getMonth().toString() +
        '-' +
        value.status;

      if (_.isEmpty(prevValue[key])) {
        prevValue[key] = {
          key: key,
          date: value.startDate,
          hours: '0',
          status: value.status,
        };
        result.push(prevValue[key]);
      }
      prevValue[key].hours = (
        parseInt(prevValue[key].hours) + value.units
      ).toString();
      prevValue[key].status = value.status;
    });

    result.forEach((item) => {
      const statusValue = getPtoStatusText(item.status);
      eventList.push({
        date: new Date(parseISO(item.date)),
        text: `${statusValue} ${item.hours}h`,
        color: getColors(item.status),
        status: item.status,
      });
      return true;
    });
  } else {
    const ptoData: PtoDetail[] = Object.assign(PtoList) as PtoDetail[];
    const monthResult: PtoGroupType[] = [];
    const prevValue: Record<string, PtoGroupType> = {};

    //create array of days with total hours
    ptoData.forEach((value: PtoDetail) => {
      const key: string = value.startDate + '-' + value.status;
      if (_.isEmpty(prevValue[key])) {
        prevValue[key] = {
          key: key,
          date: value.startDate,
          hours: '0',
          status: value.status,
        };
        monthResult.push(prevValue[key]);
      }
      prevValue[key].hours = (
        parseInt(prevValue[key].hours) + value.units
      ).toString();
      prevValue[key].status = value.status;
    });

    monthResult.forEach((item) => {
      const statusValue = getPtoStatusText(item.status);
      eventList.push({
        date: new Date(parseISO(item.date)),
        text: `${statusValue} ${item.hours}h`,
        color: getColors(item.status),
        status: item.status,
      });
      return true;
    });
  }
  return eventList;
};
