import {
  PerfReviewButton,
  ReviewStatus,
} from 'features/probationary-tracking/types';
import getCurrentView from './getCurrentView';
const getPerfReviewButtonLabel = (
  pathname: string,
  reviewStatus: ReviewStatus | null
) => {
  const {
    isAreaManagerView,
    isReviewerView,
    isHrLrView,
    isEmployeeView,
    isAdminView,
  } = getCurrentView(pathname);
  if (isAreaManagerView) {
    return getAreaManagerViewLabels(reviewStatus);
  } else if (isReviewerView) {
    return getReviewerViewLabels(reviewStatus);
  } else if (isHrLrView || isAdminView) {
    return getHrLrViewLabels(reviewStatus);
  } else if (isEmployeeView) {
    return getEmployeeViewLabels(reviewStatus);
  }
  return '-';
};
const getAreaManagerViewLabels = (reviewStatus: ReviewStatus | null) => {
  switch (reviewStatus) {
    case ReviewStatus.EMP_TERMINATED:
      return PerfReviewButton.ACKNOWLEDGE_TERMINATION;
    case ReviewStatus.SUPERVISOR_REVIEW:
    case ReviewStatus.NOT_OPEN:
    case ReviewStatus.NOT_REVIEWED:
      return PerfReviewButton.REVIEW_PERFORMANCE;
    case ReviewStatus.RETAINED:
    case ReviewStatus.TERMINATED:
      return PerfReviewButton.DOWNLOAD_REPORT;
    default:
      return PerfReviewButton.VIEW_REPORT;
  }
};
const getReviewerViewLabels = (reviewStatus: ReviewStatus | null) => {
  switch (reviewStatus) {
    case ReviewStatus.REVIEW_DUE:
    case ReviewStatus.REVALUATE:
    case ReviewStatus.COUNSELLING:
    case ReviewStatus.NOT_OPEN:
    case ReviewStatus.NOT_REVIEWED:
      return PerfReviewButton.REVIEW_PERFORMANCE;
    case ReviewStatus.FINAL_REVIEW:
      return PerfReviewButton.COMPLETE_REVIEW;
    case ReviewStatus.RETAINED:
    case ReviewStatus.TERMINATED:
      return PerfReviewButton.DOWNLOAD_REPORT;
    default:
      return PerfReviewButton.VIEW_REPORT;
  }
};
const getHrLrViewLabels = (reviewStatus: ReviewStatus | null) => {
  switch (reviewStatus) {
    case ReviewStatus.RETAINED:
    case ReviewStatus.TERMINATED:
      return PerfReviewButton.DOWNLOAD_REPORT;
    case ReviewStatus.HR_LR_REVIEW:
    case ReviewStatus.NOT_OPEN:
    case ReviewStatus.NOT_REVIEWED:
      return PerfReviewButton.REVIEW_PERFORMANCE;
    default:
      return PerfReviewButton.VIEW_REPORT;
  }
};
const getEmployeeViewLabels = (reviewStatus: ReviewStatus | null) => {
  switch (reviewStatus) {
    case ReviewStatus.EMP_ACKNOWLEDGEMENT:
      return PerfReviewButton.ACKNOWLEDGE_REPORT;
    case ReviewStatus.FINAL_REVIEW:
    case ReviewStatus.RETAINED:
    case ReviewStatus.TERMINATED:
      return PerfReviewButton.VIEW_REPORT;
    default:
      return PerfReviewButton.REPORT_UNAVAILABLE;
  }
};
export default getPerfReviewButtonLabel;
