import { ModelWeeks } from '../types';

export const CheckIsTurnsEmpty = (weeks: ModelWeeks[]) => {
  // return true if there is empty schedule turn
  const data = weeks
    .map((week) =>
      week.crewRotations.map((crew) =>
        crew.schedTurns.some((sch) => sch.turnValue === 0)
      )
    )
    .flat()
    .includes(true);

  return data;
};
