import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AddAdjustmentPayload,
  Timecard,
  TimecardAuditMsg,
  TIMECARD_STATUS,
  TimecardUpdatePayload,
  TIMECARD_ACTION_BUTTON,
  SoftAuditTypes,
  EmployeeTimecard,
  TimecardPayload,
  TimecardStatusCode,
  TimecardActionButton,
  TimecardTotal,
} from 'features/timecards/types';

import useNotification from 'components/Notification/useNotification';
import { format, parse } from 'date-fns';
import useTimecardSoftAudit from '../../../../hooks/useTimecardSoftAudit';
import TimecardAction from './TimecardActions';
import useModal from 'components/Modals/use-modal';
import { Person, ShiftAssignment } from 'features/people/types';
import getTimecardErrors from 'features/timecards/utilities/getTimecardErrors';
import { HolidayList } from 'features/holidays';
import { getSoftAuditModalProperties } from 'features/timecards/utilities/getSoftAuditModalProperties';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import { WMSError } from 'types/common';
import { UseMutationResult } from '@tanstack/react-query';
import { timecardApprovalAuditAlerts } from '../../../../validations/timecard-approval-soft-audit';
import { alertType } from 'components/Modals/ModalsContext';

interface TimecardActionsContainerProps {
  employeeTimecardObj: EmployeeTimecard;
  timecardInstance: Timecard[];
  holidayList: HolidayList | undefined;
  isTimecardDisabled: boolean;
  shiftAssignment: ShiftAssignment;
  createTimecard: UseMutationResult<
    EmployeeTimecard,
    WMSError,
    TimecardPayload[]
  >;
  updateEmployeeTimecard: UseMutationResult<
    EmployeeTimecard,
    WMSError,
    TimecardUpdatePayload
  >;
  createAdjustment: UseMutationResult<
    EmployeeTimecard,
    WMSError,
    AddAdjustmentPayload
  >;
  totalHrs: TimecardTotal;
  employee: Person;
}
const TimecardActionsContainer = ({
  employeeTimecardObj,
  timecardInstance: timecard,
  holidayList,
  isTimecardDisabled,
  shiftAssignment,
  createTimecard,
  updateEmployeeTimecard,
  createAdjustment,
  totalHrs,
  employee,
}: TimecardActionsContainerProps): JSX.Element => {
  const buttonRef = useRef<TimecardActionButton>('save');
  const [softAuditConfirm, setSoftAuditConfirm] = useState(false);
  const navigate = useNavigate();
  const notify = useNotification();
  const state = useNonUnionStore();
  const week = state.params.week;
  const crew = state.crew?.id;
  const feature = state.feature;
  const isMySchedule = feature === 'my-schedule';
  const isPay = feature === 'pay';

  // Show alerts in "Approve Popup" code starts

  const timecardApproveAudit = timecardApprovalAuditAlerts(
    employee,
    timecard,
    holidayList,
    totalHrs,
    state.crew?.gateSystem.available,
    state.crew?.crew
  );

  useEffect(() => {
    //trigger update function if audit confirm flag is true
    if (softAuditConfirm) {
      handleUpdate().catch(() => {
        notify.danger({ message: 'Something went wrong with the update' });
      });
    }
    // eslint-disable-next-line
  }, [softAuditConfirm]);

  const { validateSoftAudit } = useTimecardSoftAudit(
    shiftAssignment,
    holidayList,
    employee
  );

  const handleCancel = () => {
    buttonRef.current = TIMECARD_ACTION_BUTTON.CANCEL;

    if (isPay) {
      navigate('/non-union-pay/overview');
    } else if (isMySchedule) {
      navigate('/');
    } else {
      navigate('/non-union-scheduling/crew-listing');
    }
  };

  const updateTimecard = (code: TimecardStatusCode): TimecardUpdatePayload => {
    const employeeTimecardPayload = timecard.filter((tc) => tc.id !== 'temp');
    if (
      buttonRef.current === TIMECARD_ACTION_BUTTON.SAVE ||
      buttonRef.current === TIMECARD_ACTION_BUTTON.SAVE_ADJUSTMENTS
    ) {
      return {
        timecard: employeeTimecardPayload,
      };
    } else {
      return {
        timecard: employeeTimecardPayload,
        status: {
          code: code,
        },
      };
    }
  };
  const getTimecardsArr = (): Timecard[] => {
    return timecard.filter((tc) => tc.id !== 'temp');
  };

  const formattedWeek = week
    ? format(parse(week, 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd').toString()
    : '';

  const createAdjustmentBody: AddAdjustmentPayload = {
    crewId: crew ? crew : '',
    week: formattedWeek,
    employeeType: employeeTimecardObj.employeeType,
    timecard: timecard.filter((ti) => ti.id !== 'temp'),
  };
  const softAuditModal = useModal();
  const validateTimecard = (actionName: TimecardActionButton) => {
    const {
      invalidTimecard: ptoSoftAuditMsgs,
      holidayWithHoursError: holidaySoftAuditMsgs,
      holidayNotWorkedError: holidayNotWorkedSoftAuditMsgs,
    } = validateSoftAudit(getTimecardsArr());

    if (
      (ptoSoftAuditMsgs.length > 0 ||
        holidaySoftAuditMsgs.length > 0 ||
        holidayNotWorkedSoftAuditMsgs.length > 0) &&
      !softAuditConfirm &&
      (actionName === TIMECARD_ACTION_BUTTON.SAVE ||
        actionName === TIMECARD_ACTION_BUTTON.SAVE_APPROVE ||
        actionName === TIMECARD_ACTION_BUTTON.CREATE_TIMECARD ||
        actionName === TIMECARD_ACTION_BUTTON.SAVE_VERIFY ||
        actionName === TIMECARD_ACTION_BUTTON.SAVE_APPROVE_ADJUSTMENTS ||
        actionName === TIMECARD_ACTION_BUTTON.SAVE_ADJUSTMENTS)
    ) {
      if (ptoSoftAuditMsgs.length > 0) {
        handleSoftAudit(ptoSoftAuditMsgs, holidaySoftAuditMsgs, [], 'pto');
        return false;
      }
      if (holidaySoftAuditMsgs.length > 0) {
        handleSoftAudit([], holidaySoftAuditMsgs, [], 'holiday');
      }
      if (holidayNotWorkedSoftAuditMsgs.length > 0) {
        handleSoftAudit(
          [],
          [],
          holidayNotWorkedSoftAuditMsgs,
          'holidayNotWorked'
        );
      }
    } else {
      const { errMessages } = getTimecardErrors({
        timecardArr: getTimecardsArr(),
      });

      if (errMessages.length) {
        notify.danger({ message: errMessages });
      } else {
        return true;
      }
    }
  };

  const handleSoftAudit = (
    ptoMsgs: TimecardAuditMsg[],
    holidayMsgs: TimecardAuditMsg[],
    holidayNotWorked: TimecardAuditMsg[],
    type: SoftAuditTypes
  ) => {
    const msgType = isPay ? 'Save/Approve' : 'Save/Verify';
    const closeModal = () => {
      softAuditModal.closeModal();
    };
    const softAuditModalProperties = getSoftAuditModalProperties({
      msgType,
      setSoftAuditConfirm,
      handleSoftAudit,
      ptoMsgs,
      holidayMsgs,
      holidayNotWorked,
      closeModal,
    });

    const alerts = softAuditModalProperties[type].alert
      ? ([softAuditModalProperties[type].alert] as alertType[])
      : [];
    softAuditModal.openModal({
      title: softAuditModalProperties[type].modalTitle,
      type: 'regular',
      size: 'md',
      children: softAuditModalProperties[type].children,
      labels: { confirm: 'Yes', cancel: 'Cancel' },
      onCancel: closeModal,
      onConfirm: softAuditModalProperties[type].onConfirm,
      alert: alerts,
    });
  };

  const handleUpdate = async () => {
    if (buttonRef.current === TIMECARD_ACTION_BUTTON.OPEN_ADJUSTMENTS) {
      setSoftAuditConfirm(false);
      await createAdjustment.mutateAsync(createAdjustmentBody);
    } else if (
      buttonRef.current === TIMECARD_ACTION_BUTTON.SAVE_APPROVE_ADJUSTMENTS &&
      validateTimecard(TIMECARD_ACTION_BUTTON.SAVE_APPROVE_ADJUSTMENTS)
    ) {
      setSoftAuditConfirm(false);
      await updateEmployeeTimecard.mutateAsync(
        updateTimecard(TIMECARD_STATUS.APPROVED_ADJUSTMENT)
      );
    } else if (
      buttonRef.current === TIMECARD_ACTION_BUTTON.UNAPPROVE_ADJUSTMENTS
    ) {
      setSoftAuditConfirm(false);
      await updateEmployeeTimecard.mutateAsync(
        updateTimecard(TIMECARD_STATUS.UNAPPROVED_ADJUSTMENT)
      );
    } else if (
      (buttonRef.current === TIMECARD_ACTION_BUTTON.SAVE ||
        buttonRef.current === TIMECARD_ACTION_BUTTON.SAVE_ADJUSTMENTS) &&
      validateTimecard(TIMECARD_ACTION_BUTTON.SAVE)
    ) {
      setSoftAuditConfirm(false);
      await updateEmployeeTimecard.mutateAsync(
        updateTimecard(employeeTimecardObj.status.code)
      );
    } else if (buttonRef.current === TIMECARD_ACTION_BUTTON.UNAPPROVE) {
      setSoftAuditConfirm(false);
      await updateEmployeeTimecard.mutateAsync(
        updateTimecard(TIMECARD_STATUS.UNAPPROVED)
      );
    } else if (
      buttonRef.current === TIMECARD_ACTION_BUTTON.SAVE_APPROVE &&
      validateTimecard(TIMECARD_ACTION_BUTTON.SAVE_APPROVE)
    ) {
      setSoftAuditConfirm(false);
      await updateEmployeeTimecard.mutateAsync(
        updateTimecard(TIMECARD_STATUS.APPROVED)
      );
    } else if (
      buttonRef.current === TIMECARD_ACTION_BUTTON.SAVE_VERIFY &&
      validateTimecard(TIMECARD_ACTION_BUTTON.SAVE_VERIFY)
    ) {
      setSoftAuditConfirm(false);
      await updateEmployeeTimecard.mutateAsync(
        updateTimecard(TIMECARD_STATUS.VERIFIED)
      );
    } else if (
      buttonRef.current === TIMECARD_ACTION_BUTTON.CREATE_TIMECARD &&
      validateTimecard(TIMECARD_ACTION_BUTTON.CREATE_TIMECARD)
    ) {
      setSoftAuditConfirm(false);
      await createTimecard.mutateAsync(getTimecardsArr());
    }
  };

  return (
    <div
      className="flex justify-end flex-wrap mt-4"
      data-testid="timecard-actions-container"
    >
      <TimecardAction
        isTimecardDisabled={isTimecardDisabled}
        handleCancel={handleCancel}
        handleUpdate={handleUpdate}
        employeeTimecardObj={employeeTimecardObj}
        buttonRef={buttonRef}
        approveAudits={timecardApproveAudit}
      />
    </div>
  );
};

export default TimecardActionsContainer;
