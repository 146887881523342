import { axios } from 'services/api/axios';
import {
  ApplyModelReqBody,
  ApplyModelSuccessResponse,
  CheckoutModelReqBody,
  CheckoutModelResponseBody,
  CopyModelReqBody,
  CopyModelSuccessResponse,
  CopyWeekModelReqBody,
  CreateModelResponse,
  EditModelReqBody,
  ModelDetailParams,
  ModelDetailsResponse,
  ModelListParams,
  ModelListResponse,
} from '../types';
import { CreateModel } from '../components/Forms/CreateModelForm';
import { ApiBasicStatusResponse } from 'types/common';

export const modelList = {
  get: (options?: ModelListParams): Promise<ModelListResponse | undefined> => {
    return axios.get(`/v1/timecard-model`, {
      params: options,
    });
  },
  post: (body: CreateModel): Promise<CreateModelResponse> => {
    return axios.post(`/v1/timecard-model`, body);
  },
};

export const modelDetails = {
  get: (
    modelId: string,
    options?: ModelDetailParams
  ): Promise<ModelDetailsResponse> => {
    return axios.get(`/v1/timecard-model/${modelId}`, { params: options });
  },
};

export const checkoutModel = {
  post: (
    body: CheckoutModelReqBody,
    id: string
  ): Promise<CheckoutModelResponseBody> => {
    return axios.post(`/v1/timecard-model/${id}/edit`, body);
  },
};

export const modelApply = {
  post: (body: ApplyModelReqBody): Promise<ApplyModelSuccessResponse> => {
    return axios.post(`/v1/timecard-model/${body.id}/apply`, body.payload);
  },
};
export const editModel = {
  put: (body: EditModelReqBody, id: string): Promise<ModelDetailsResponse> => {
    return axios.put(`/v1/timecard-model/${id}`, body);
  },
};
export const copyModel = {
  post: (body: CopyModelReqBody): Promise<CopyModelSuccessResponse> => {
    return axios.post(`/v1/timecard-model/${body.id}/copy`, body.payload);
  },
};
export const copyWeekModel = {
  post: (body: CopyWeekModelReqBody): Promise<ApiBasicStatusResponse> => {
    return axios.post(`/v1/timecard-model/${body.id}/copy-week`, body.payload);
  },
};

