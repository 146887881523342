import { ModelCrewRotation } from '../types';
import { schedTurns } from './getEmptyWeekObject';

export const getEmptyCrewRotationObject = (val: number) => {
  const newCrew: ModelCrewRotation = {
    pkCrewRotationId: null,
    crewRotation: '',
    schedTurns: schedTurns,
  };
  const arr: ModelCrewRotation[] = [];
  for (let i = 0; i < val; i++) {
    arr.push(newCrew);
  }
  return arr;
};
