export enum AreaManagerTabs {
  UNASSIGNED = 'Un-assigned',
  ASSIGNED = 'Assigned',
  REVIEW_PERFORMANCE = 'Review Performance',
  TERMINATION = 'Termination',
}

export enum ReviewerTabs {
  ASSIGNED = 'Assigned',
  REVIEW_DUE = 'Review Due',
  RE_EVALUATE = 'Re-evaluate',
  COUNSELING = 'Counseling',
  FINAL_REVIEW = 'Final Review',
}

export enum HrLrTabs {
  PROB_EMPLOYEES = 'Prob. Employees',
  LOW_RATING = 'Low Rating',
  REVIEW_PERFORMANCE = 'HR to Review',
}
