import Axios, { AxiosRequestConfig } from 'axios';
import { BASE_URL } from 'config';
import { getWFMToken, getMSToken } from 'services/token.service';

async function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = await getWFMToken();
  config.headers = { ...config.headers };
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers['Access-Control-Allow-Origin'] = '*';
  return config;
}

function URLInterceptor(config: AxiosRequestConfig) {
  if (config.url && !config.url.startsWith('/')) {
    config.url = '/' + config.url;
  }

  return config;
}

async function authRequestInterceptorGraph(config: AxiosRequestConfig) {
  config.headers = { ...config.headers };
  const token = await getMSToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}

export const msgraph = Axios.create({
  baseURL: 'https://graph.microsoft.com/v1.0',
});

export const axios = Axios.create({
  baseURL: BASE_URL,
});

axios.interceptors.request.use(URLInterceptor);
axios.interceptors.request.use(authRequestInterceptor);
msgraph.interceptors.request.use(authRequestInterceptorGraph);

axios.interceptors.response.use(
  (response) => {
    return response.data as unknown;
  },
  (error) => {
    //Custom error logic to handle all errors, for example we could display a toast message from here
    // const message = error.response?.data?.message || error.message;
    return Promise.reject(error);
  }
);
