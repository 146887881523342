import { SplitTotalValues } from './SplitTotalValues';
import TotalsValue from './TotalsValue';
import { PtoSummary } from 'features/paid-time-off/types';

const PartTimeSummary = ({ summary }: { summary: PtoSummary }) => {
  return (
    <div className="grid lg:grid-flow-col grid-cols-1 gap-4 lg:grid-cols-5 lg:gap-4 w-full">
      <div className="lg:col-span-2 lg:col-start-1">
        <div className="flex flex-grow items-center border-b-4 lg:border-b-0 lg:border-r-4 border-gray-100 pb-3 lg:pb-0 mb-3 lg:mb-0">
          <div className="pr-2">
            <TotalsValue
              label="Eligible"
              value={summary.totalEligible.total}
              unitOfMeasure={summary.unitOfMeasure}
            />
          </div>
          <div className="lg:flex-grow lg:px-8">
            <SplitTotalValues
              options={{
                firstValue: {
                  value: summary.workedHours,
                  label: 'Current Year Worked Hrs',
                  unitOfMeasure: summary.unitOfMeasure,
                },
                secondValue: {
                  value: summary.totalEligible.rollOver,
                  label: '1 Hour of Paid State Leave per 30 hours worked',
                  unitOfMeasure: summary.unitOfMeasure,
                },
              }}
            />
          </div>
        </div>
      </div>

      <TotalsValue
        label="Taken"
        value={summary.taken.total}
        unitOfMeasure={summary.unitOfMeasure}
      />
      <TotalsValue
        label="Adjustment"
        value={summary.adjustment.total}
        unitOfMeasure={summary.unitOfMeasure}
      />
      <TotalsValue
        label="Remaining"
        value={summary.remaining}
        unitOfMeasure={summary.unitOfMeasure}
        divider
      />
    </div>
  );
};

export default PartTimeSummary;
