import { useQuery } from '@tanstack/react-query';
import { modulesUsers } from './api';
import { UserCrewMemberOptions } from '../types';

export const useUserCrewMembers = (
  moduleId: string,
  ussId: string,
  params?: UserCrewMemberOptions
) => {
  return useQuery({
    queryFn: () => modulesUsers.getCrewMembers(moduleId, ussId, params),
    queryKey: ['user-crew-members', { ussId, ...params }],
    enabled: ussId !== '',
  });
};
