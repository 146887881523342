import getUnitOfMeasure, {
  UnitOfMeasure,
} from 'features/paid-time-off/utility/getUnitOfMeasure';
import React, { useId } from 'react';

export interface SplitTotalValuesProps {
  options: {
    firstValue: {
      label: string;
      value: number;
      unitOfMeasure: UnitOfMeasure;
    };
    secondValue: {
      label: string;
      value: number;
      unitOfMeasure: UnitOfMeasure;
    };
  };
}

export function SplitTotalValues({ options }: SplitTotalValuesProps) {
  const label1_id = useId();
  const label2_id = useId();
  return (
    <>
      <span className="flex flex-row justify-between border-dotted border-b border-gray-400">
        <span className="text-xs font-light line" id={label1_id}>
          {options.firstValue.label}
        </span>
        <span
          data-testid="annual-requested-year"
          className="text-sm text-accent1-500 font-medium pl-3"
          aria-labelledby={label1_id}
        >
          {options.firstValue.value}
          <span className="text-xs">
            {' '}
            {getUnitOfMeasure(
              options.firstValue.unitOfMeasure,
              options.firstValue.value
            )}
          </span>
        </span>
      </span>
      <span className="flex flex-row justify-between">
        <span className="text-xs font-light" id={label2_id}>
          {options.secondValue.label}
        </span>
        <span
          data-testid="annual-eligible-rollover"
          className="text-sm text-accent1-500 font-medium pl-3"
          aria-labelledby={label2_id}
        >
          {options.secondValue.value}
          <span className="text-xs">
            {' '}
            {getUnitOfMeasure(
              options.secondValue.unitOfMeasure,
              options.secondValue.value
            )}
          </span>
        </span>
      </span>
    </>
  );
}
