import React from 'react';
import { NavLink } from 'react-router-dom';
import Page from 'components/Layouts/Page';
import HelpFulHintsList from 'data/home/HelpfulHints';
const HelpfulHintsAll = () => {
  return (
    <Page
      heading="Helpful Hints"
      breadcrumbs={[
        <NavLink key="bc-1" to="/">
          Home
        </NavLink>,
      ]}
    >
      <Page.Section>
        <div className="rounded-md bg-white w-full sm:w-9/12 pt-4 pl-4">
          <ul className="marker:text-accent1-500 marker:text-lg list-outside list-disc ml-4  sm:mb-4 text-sm text-gray-700">
            {HelpFulHintsList.items.map((hint, index) => {
              return (
                <li
                  className="mb-1 sm:mb-5"
                  key={`helpful-hint-${index}`}
                  dangerouslySetInnerHTML={{ __html: hint.hint }}
                ></li>
              );
            })}
          </ul>
        </div>
      </Page.Section>
    </Page>
  );
};

export default HelpfulHintsAll;
