import CardList from 'components/Card/CardList';
import CardsLoader from 'components/Loaders/CardsLoader';
import { format, parseISO } from 'date-fns';
import {
  CrewPtoDetail,
  PtoFilterStatusType,
  PtoStatus,
} from 'features/paid-time-off/types';

import EmployeePtoCard from '../Cards/crew-pto/EmployeePtoCard';
import { useCrewPtoStore } from 'features/paid-time-off/state';
import { PtoStatusSelect } from '../Filters/crew-pto/PtoStatusSelect';
import { QueryStatus } from '@tanstack/react-query';
import {
  GetCrewParamsBody,
  GetOrgDetailsParamsBody,
} from 'features/paid-time-off/types/crew-pto';

interface Props {
  selectedDate: string;
  orgDetails: CrewPtoDetail[] | undefined;
  orgDetailsStatus: QueryStatus;
  statusList: PtoStatus[];
  fetchStatus: 'fetching' | 'paused' | 'idle';
  detailsRequest?: GetOrgDetailsParamsBody;
  totalsRequest?: GetCrewParamsBody;
}
const PTORecordsList = ({
  selectedDate,
  orgDetails,
  orgDetailsStatus,
  statusList,
  fetchStatus,
  detailsRequest,
  totalsRequest,
}: Props) => {
  const store = useCrewPtoStore();

  const onStatusSelect = (status: PtoFilterStatusType) => {
    store.setStatus(status);
  };

  return (
    <>
      <div className="flex justify-between gap-4 mb-4 flex-wrap md:flex-nowrap">
        <div className="text-base lg:text-lg">
          {orgDetails && format(parseISO(selectedDate), 'MMM dd, yyyy')}
        </div>

        <div className="w-full md:w-1/3">
          {orgDetailsStatus === 'success' && orgDetails && (
            <PtoStatusSelect
              status={store.status}
              onSelect={onStatusSelect}
              statusValues={statusList}
            />
          )}
        </div>
      </div>
      {fetchStatus !== 'idle' && (
        <CardsLoader variant="row-grid" count={9} hasAvatar />
      )}
      {orgDetailsStatus === 'success' && orgDetails && (
        <CardList
          items={orgDetails}
          variant="row-grid"
          renderItem={(pto) => (
            <EmployeePtoCard
              pto={pto}
              detailsRequest={detailsRequest}
              totalsRequest={totalsRequest}
            />
          )}
        />
      )}
    </>
  );
};
export default PTORecordsList;
