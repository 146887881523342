import React from 'react';
import { StatusType } from 'features/org';

export const OrgCardBadge = ({
  value,
  children,
}: {
  value: StatusType;
  children?: React.ReactElement;
}) => {
  const getColors = (value: StatusType) => {
    switch (value) {
      case 'active': {
        return 'bg-green-100';
      }
      case 'archived': {
        return 'bg-gray-100';
      }
      case 'pending': {
        return 'bg-orange-100';
      }
      case 'rejected': {
        return 'bg-red-100';
      }
      default: {
        return 'bg-green-100';
      }
    }
  };
  return (
    <span
      className={`flex items-center text-gray-900 justify-center px-2 lg:text-sm text-xs leading-7 rounded-full ${getColors(
        value
      )} `}
    >
      {children}
    </span>
  );
};
