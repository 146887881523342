import { OrgFormType } from 'features/org';

export type OrgActionType =
  | {
      type: 'name';
      payload: string;
    }
  | {
      type: 'description';
      payload: string;
    }
  | {
      type: 'comments';
      payload: string;
    };

export const updateOrgData = (
  state: OrgFormType,
  action: OrgActionType
): OrgFormType => {
  switch (action.type) {
    case 'name': {
      state.name = action.payload;
      return { ...state };
    }
    case 'description': {
      state.description = action.payload;
      return { ...state };
    }
    case 'comments': {
      state.comments = action.payload;
      return { ...state };
    }
    default:
      throw new Error();
  }
};
