import { ModelWeeks } from 'features/model-listing/types';
import { getDaysFormat } from 'features/model-listing/utility/getDaysFormat';
import { getWeekFormat } from 'features/model-listing/utility/getWeekFormat';

export interface calenderViewProps {
  weekData: ModelWeeks;
  weekBeginIndex: number;
  modelName: string;
  totalWeeks: number;
}
export const WeekCard = ({
  weekData,
  modelName,
  weekBeginIndex,
  totalWeeks,
}: calenderViewProps) => {
  return (
    <>
      <div className=" flex flex-col gap-2 bg-white max-h-max w-full shadow-md rounded-md p-4 ">
        <div className="border-b-2 border-gray-300  pb-2 ">
          <h3 className="flex flex-row justify-between items-center">
            <span className=" font-semibold text-lg">{`Week ${weekData.weekNo} of ${totalWeeks}`}</span>
            <span className=" font-normal text-sm ">{`${modelName}`}</span>
          </h3>
        </div>
        <div>
          <div className="w-full grid gap-2 grid-cols-8 items-center">
            <div className=" text-base text-center"></div>
            {getDaysFormat(weekBeginIndex).map((day, _index) => (
              <div
                key={_index}
                className="text-center font-semibold text-base justify-center px-2.5"
              >
                {day?.value.charAt(0)}
              </div>
            ))}

            {weekData.crewRotations.map((crewRotation, index) => (
              <>
                <div
                  key={index}
                  className="font-semibold text-base text-center  px-2.5"
                >
                  {crewRotation.crewRotation}
                </div>

                {getWeekFormat({
                  data: [...crewRotation.schedTurns],
                  weekBeginIndex: weekBeginIndex,
                }).map((schedTurn, _index) => (
                  <div key={_index} className="  text-center text-sm  px-2.5 ">
                    {schedTurn?.turnValue ? schedTurn.turnValue : '-'}
                  </div>
                ))}
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
