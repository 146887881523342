export const ORG_TYPE = {
  PLANT: 'plant',
  LOCATION: 'location',
  DIVISION: 'division',
  DEPARTMENT: 'department',
  CREW: 'crew',
} as const;

export const ORG_LOCATION_TYPE = {
  UNION: 'UNION',
  NONUNION: 'NON-UNION',
} as const;

export const ORG_STATUS_CODE = {
  ACTIVE: 'active',
  PENDING: 'pending',
  ARCHIVED: 'archived',
  REJECTED: 'rejected',
  DENIED: 'denied',
} as const;

export const ORG_SECURITY_PRIMARY = {
  YES: 'y',
  NO: 'n',
} as const;

export const ORG_FILTER_LOC_TYPE = {
  UNION: 'union',
  NONUNION: 'nonunion',
} as const;
