import { PolicySummaryFields } from '../types';

interface IsBrsPayroll {
  employeePolicySummarydata: PolicySummaryFields;
}

const isBrsPayroll = ({ employeePolicySummarydata }: IsBrsPayroll) => {
  if (
    employeePolicySummarydata.payrollNameId !== 'big_river_steel_weekly' ||
    employeePolicySummarydata.employeeClass === '0'
  ) {
    return false;
  } else {
    return true;
  }
};
export default isBrsPayroll;
