import React from 'react';

interface SkeletonType {
  elementType: keyof typeof element;
}

const element = {
  tableColumn: ' h-6 bg-gray-200 ',
};

const SkeletonElements = ({ elementType }: SkeletonType) => {
  const style = `${element[elementType]}`;
  return <div className={style}></div>;
};

export default SkeletonElements;
