import { notify } from 'components/Notification/useNotification';
import { useMutation } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { queryClient } from 'services/api/react-query';
import { WMSError } from 'types/common';

const deleteSecurityById = (
  ussId: string,
  id: string
): Promise<{
  success: boolean;
  message: string;
}> => {
  return axios.delete(`/v1/people/${ussId}/security/${id}`);
};
/**
 * Endpoint to delete an security
 * https://cappsq.psc.uss.com/SBWM-DOCUMENT/swagger-ui/index.html?urls.primaryName=sbwm-people#/security-controller/deleteUserSecurityById
 * @param ussId USS ID for the person
 * @param id ID of the requested security
 * @returns Promise
 */
export const useDeleteSecurityById = (ussId: string) => {
  return useMutation<
    {
      success: boolean;
      message: string;
    },
    WMSError,
    string
  >({
    mutationFn: (id: string) => deleteSecurityById(ussId, id),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ['employee-securities'],
        type: 'active',
      });
      notify.success({
        message: 'You have removed 1 security record for this employee',
      });
    },
  });
};
