import { z } from 'zod';
import {
  ZodNonUnionEmployee,
  ZodPaycodeStatus,
  ZodPaycodeType,
  ZodPayrollGroupType,
  ZodPayrollIndicators,
  ZodShiftAssignment,
  ZodUnionEmployee,
} from '../constants';
import { PLANT_TYPE } from '../constants';
import { ZodAppEmpType } from '../api/getPaycodes';

export const PayrollGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
  payrollGroupType: ZodPayrollGroupType,
  active: z.boolean(),
  restricted: z.boolean(),
  shifts: z.object({ name: ZodShiftAssignment, value: z.boolean() }).array(),
  payrollIndicators: z
    .object({ name: ZodPayrollIndicators, value: z.boolean() })
    .array(),
});

const ZodPlantType = z.nativeEnum(PLANT_TYPE);
const PaycodeSchema = z.object({
  id: z.string(),
  code: z.string(),
  description: z.string(),
  useCaseDescription: z.string(),
  type: ZodPaycodeType,
  earningsCode: z.string().nullable(),
  payrollGroups: PayrollGroupSchema.array().nullable(),
  status: z.object({
    code: ZodPaycodeStatus,
    description: z.string(),
    actionTakenOn: z.string(),
    actionTakenBy: z.string(),
  }),
  plantTypes: ZodPlantType.array().nullable(),
  createdByUssid: z.string(),
  createdByName: z.string(),
  createDt: z.string(),
  restrictedUsage: z.boolean(),
  createdBy: z.string(),
  comments: z.string(),
});

const PaycodeListSchema = z.object({
  items: z.array(PaycodeSchema),
  page: z.number(),
  pageSize: z.number(),
  totalCount: z.number(),
});

export enum SortByEnum {
  'code' = 'Code',
  'description' = 'Description',
  'type' = 'Type',
  'status' = 'Status',
  'recently-added' = 'Recently Added',
  'recently-archived' = 'Recently Archived',
  'recently-denied' = 'Recently Denied',
}

export type Paycode = z.infer<typeof PaycodeSchema>;
export type PaycodeList = z.infer<typeof PaycodeListSchema>;
export type ApplicableEmpType = z.infer<typeof ZodAppEmpType>;
export type PlantType = z.infer<typeof ZodPlantType>;
export type ShiftAssignment = z.infer<typeof ZodShiftAssignment>;
export type UnionEmployee = z.infer<typeof ZodUnionEmployee>;
export type NonUnionEmployee = z.infer<typeof ZodNonUnionEmployee>;
export type PaycodeType = z.infer<typeof ZodPaycodeType>;
export type PaycodeStatus = z.infer<typeof ZodPaycodeStatus>;
export type PayrollGroupType = z.infer<typeof ZodPayrollGroupType>;
export type PayrollGroups = z.infer<typeof PayrollGroupSchema>;
export type PayrollIndicators = z.infer<typeof ZodPayrollIndicators>;
