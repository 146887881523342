import React, { useEffect } from 'react';
import CardsLoader from 'components/Loaders/CardsLoader';
import Error from 'components/Error/Error';
import Pagination from 'components/Pagination/Pagination';
import CardList from 'components/Card/CardList';
import { useUnapprovedCrews } from 'features/paypulloff/api';
import UnapprovedCrewCard from 'features/paypulloff/components/UnapprovedCrews/UnapprovedCrewCard';
import { useUnapprovedCrewsStore } from 'features/paypulloff/state/crews-store';
import { LocationList, UnapprovedCrew } from 'features/paypulloff/types';

import { Select } from '@uss/react-components';
import NoResults from 'components/NoResults/NoResults';

interface PageProps {
  payrollName: string;
  locationList: LocationList[] | null;
}

const UnapprovedCrews = ({ payrollName, locationList }: PageProps) => {
  const store = useUnapprovedCrewsStore((state) => state);
  const { data, status } = useUnapprovedCrews(payrollName, store.params);
  useEffect(() => {
    if (locationList) {
      if (!store.selectedPayrollLocation) {
        store.setPayrollLocation(
          locationList.length > 0 ? locationList[0] : ({} as LocationList)
        );
      }
    }
  }, [locationList, store]);

  return (
    <>
      <div className="w-full mb-2 sm:w-4/12 mt-2 ">
        <Select
          selected={store.selectedPayrollLocation?.locationName ?? ''}
          onSelect={store.setPayrollLocation}
          className="w-full"
        >
          <Select.Button
            label={
              store.selectedPayrollLocation?.locationDescription
                ? `${store.selectedPayrollLocation.locationName} - ${store.selectedPayrollLocation.locationDescription}`
                : 'Select'
            }
          />
          <Select.Options>
            {locationList && locationList.length > 0 ? (
              locationList.map((item: LocationList, index: number) => (
                <Select.Option key={index} value={item}>
                  <span>
                    {item.locationName} - {item.locationDescription}
                  </span>
                </Select.Option>
              ))
            ) : (
              <span>No locationList available</span>
            )}
          </Select.Options>
        </Select>
      </div>
      <div className="pt-2">
        {payrollName.length > 0 && status === 'pending' && (
          /* if status is loading */
          <CardsLoader count={9} contentRows={1} />
        )}
        {/* if received error from server  */}
        {status === 'error' && <Error />}
        {status === 'success' && data.items.length > 0 && (
          <CardList<UnapprovedCrew>
            items={data.items}
            renderItem={(crew) => <UnapprovedCrewCard crew={crew} />}
          />
        )}

        {data?.items.length === 0 && (
          /* if items are empty */
          <NoResults
            width={'300'}
            height={'300'}
            description="No records found, Please modify the search criteria to view the records"
          />
        )}
      </div>
      {!!data?.totalCount && data.totalCount >= 10 && (
        <div className="pt-4">
          <Pagination
            pageOptions={[9, 18, 27, 36, 45, 54]}
            totalCount={data.totalCount}
            page={store.params.page}
            pageSize={store.params.pageSize}
            onPageChange={(p) => store.setPage(p.selected + 1)}
            onPageSizeChange={(ps) => store.setPageSize(ps)}
          />
        </div>
      )}
    </>
  );
};

export default UnapprovedCrews;
