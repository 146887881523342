import { Dropdown } from "../components/Forms/ApplyModelForm";
import { ModelDetailsResponse } from "../types";

export const useGetModelDetailItems = (details: ModelDetailsResponse | undefined) => {
  const crewRotationItems: Dropdown<string>[] = [];
  if (details && details.weeks.length > 0) {
    details.weeks[0].crewRotations.forEach(item => {
      crewRotationItems.push({ id: item.crewRotation, value: item.crewRotation });
    })
  }

  const totalWeeksArr: Dropdown<number>[] = [];
  if (details) {
    for (let i = 0; i < details.totalWeeks; i++) {
      totalWeeksArr.push({ id: i + 1, value: i + 1 });
    }
  }

  return { crewRotationItems, totalWeeksArr }
}