import { format, parseISO } from 'date-fns';
import { PayPeriod, PayPeriodStatus } from 'features/pay-periods';
import {
  ShiftAssignment,
  ShiftAssignmentStatus,
  ShiftAssignmentType,
} from '../types';

export const getFormattedPayPeriod = (payPeriod: PayPeriod) => {
  return {
    startDate: `${format(parseISO(payPeriod.startDate), 'MM/dd/yyyy')}`,
    month: ` ${format(
      new Date(2000, parseInt(payPeriod.payPeriod), 0),
      'MMMM'
    )}`,
    displayDate: `${format(
      new Date(2000, parseInt(payPeriod.payPeriod), 0),
      'MMMM'
    )} (${format(parseISO(payPeriod.startDate), 'MM/dd/yyyy')})`,
  };
};

export const getInfoMessageByStatus = (payPeriodStatus: PayPeriodStatus) => {
  switch (payPeriodStatus) {
    case 'closed':
      return "Shift Assignment changes for prior pay periods can only be submitted once all the timecards in the prior pay period are in the 'Open Adjustment' status, then the Pay Period effective date will appear below to submit the change. Remember to approve the adjusted timecards after changing the shift assignment to complete the adjustment process.";
    case 'not-open':
      return 'Shift Assignment changes for future pay periods can be submitted anytime but only one pending change is allowed at a time';
    default:
      return 'Shift Assignment changes are applied for all weeks in the pay period selected and can only be submitted if all timecards in the current pay period are not approved'; //TODO: CHECK THIS ONCE- what should be for other
  }
};

export const getChangeShiftAssignmentTo = (
  shiftAssignmentFrom: ShiftAssignmentType
) => {
  return shiftAssignmentFrom === ShiftAssignmentType.EIGHT_HOUR
    ? ShiftAssignmentType.TWELVE_HOUR
    : ShiftAssignmentType.EIGHT_HOUR;
};

export const getChangeSuccessMessage = (payPeriodStatus?: PayPeriodStatus) => {
  let message = 'Shift Assignment changed successfully';
  if (payPeriodStatus === 'open') {
    message = 'You have successfully changed the current shift assignment.';
  } else if (payPeriodStatus === 'not-open') {
    message = 'You have successfully changed the future shift assignment.';
  } else if (payPeriodStatus === 'closed') {
    message =
      'You have successfully initiated the change for closed pay period.';
  }
  return message;
};

export const getChangeErrorMessage = (payPeriodStatus?: PayPeriodStatus) => {
  let errMsg = '';
  if (payPeriodStatus === 'open') {
    errMsg =
      'There are approved timecards for this pay period hence the change cannot be done';
  } else if (payPeriodStatus === 'closed') {
    //multiple cases can be there based on timecards status
    errMsg =
      'Timecard(s) are not open for adjustment hence the change cannot be done.';
  } else {
    errMsg = 'Error occured. Unable to change shift assignment.';
  }
  return errMsg;
};

export const getChangeUndoValidation = (
  selectedPayPeriod: PayPeriod | null,
  shiftAssignment: ShiftAssignment | undefined | null,
  pendingShiftAssignment: ShiftAssignment
) => {
  const isApprovedAwaitingApproval =
    selectedPayPeriod?.status === 'closed' &&
    shiftAssignment?.status === ShiftAssignmentStatus.AWAITING_APPROVAL &&
    shiftAssignment.approvedFlag;

  const isChangedAwaitingProcessing =
    selectedPayPeriod?.status === 'closed' &&
    shiftAssignment?.status === ShiftAssignmentStatus.AWAITING_PROCESSING &&
    shiftAssignment.changeInitialized;

  const getIsChangeDisabled = () => {
    return (
      selectedPayPeriod === null ||
      (selectedPayPeriod.status === 'not-open' &&
        Object.keys(pendingShiftAssignment).length > 0 &&
        !shiftAssignment?.changeInitialized) ||
      shiftAssignment?.changeInitialized
    );
  };

  const getIsUndoDisabled = () => {
    if (
      selectedPayPeriod !== null &&
      pendingShiftAssignment.startDate === selectedPayPeriod.startDate
    ) {
      return false;
    }
    return (
      selectedPayPeriod === null ||
      isApprovedAwaitingApproval ||
      isChangedAwaitingProcessing ||
      !shiftAssignment?.changeInitialized ||
      (!shiftAssignment.adjustmentFlag &&
        shiftAssignment.payPeriodStatus === 'closed')
    );
  };
  return {
    isChangeDisabled: getIsChangeDisabled(),
    isUndoDisabled: getIsUndoDisabled(),
  };
};
