import React from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import { PAY_PERIOD_STATUS } from 'features/pay-periods/types';
import { PayPeriodData, PayPeriodPulloff } from 'features/paypulloff/types';
import ProcessingCard from 'features/paypulloff/components/ProcessingCard/ProcessingCard';
import ActionsContainer from 'features/paypulloff/components/Actions/Actions';
import CardsLoader from 'components/Loaders/CardsLoader';
import Error from 'components/Error/Error';
import NoResults from 'components/NoResults/NoResults';

interface Props {
  payrollName: string;
  data: PayPeriodPulloff | undefined;
  status: string;
  isFetching: boolean;
}

const PayPullOffProcessMsg = ({ message }: { message: string }) => {
  return (
    <div className="flex flex-row mb-2">
      <BsInfoCircle className="text-blue-500" />
      <span
        className="ml-2 text-sm text-gray-700"
        data-testid="pulloff-status-message"
      >
        {message}
      </span>
    </div>
  );
};

export const Processing = ({
  payrollName,
  data,
  status,
  isFetching,
}: Props) => {
  const payPeriod = data?.total;
  return (
    <>
      {status === 'success' || isFetching ? (
        <>
          <div className="py-4">
            {(payPeriod?.payPeriodStatus === PAY_PERIOD_STATUS.PROCESSED ||
              payPeriod?.payPeriodStatus === PAY_PERIOD_STATUS.CLOSED) && (
              <PayPullOffProcessMsg message=" Weekly pulloff has already been processed for this pay period." />
            )}

            {payPeriod?.payPeriodStatus === PAY_PERIOD_STATUS.IN_PROGRESS && (
              <PayPullOffProcessMsg message=" Weekly pulloff is in process for this pay period." />
            )}
            <ProcessingCard
              payPeriod={payPeriod}
              isTotalProcessingCard={true}
            />
            {payPeriod && (
              <ActionsContainer
                payrollName={payrollName}
                payPeriodStatus={payPeriod.payPeriodStatus}
                payPeriodId={payPeriod.payPeriodId}
              />
            )}
          </div>
          <div>
            <hr className="border-gray-400 " />
            <div className="text-gray-900 text-xl  font-bold  mb-4 mt-5">
              Payrolls Locations
            </div>
            {status === 'pending' && <CardsLoader count={9} contentRows={1} />}

            {data?.items.map((item: PayPeriodData, index: number) => (
              <div className="mb-10" key={index}>
                <ProcessingCard
                  payPeriod={item}
                  isTotalProcessingCard={false}
                />
              </div>
            ))}

            {data?.items.length === 0 && (
              /* if items are is empty */
              <NoResults
                width={'300'}
                height={'300'}
                description="No records found, Please modify the search criteria to view the records"
              />
            )}
          </div>
        </>
      ) : (
        <>{status === 'error' && <Error />}</>
      )}
    </>
  );
};
