import { RadioGroup } from '@uss/react-components';

export const RadioComponent = ({
  selected,
  radioOptions,
  handleChange,
  label,
  readOnly = false,
  direction = 'row',
}: {
  selected: string;
  radioOptions: string[];
  label: string;
  handleChange: (value: string) => void;
  readOnly?: boolean;
  direction?: 'row' | 'col' | undefined;
  name?: string;
}) => {
  return (
    <RadioGroup
      hideLabel
      label={label}
      selected={selected}
      onSelect={handleChange}
      disabled={readOnly}
      direction={direction}
    >
      {radioOptions.map((option: string) => (
        <RadioGroup.Option
          variant="radio"
          value={option}
          data-testid={`${label}-${option}`}
          id={`${label}__${option.toLowerCase()}`}
          key={option}
        >
          {option}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};
