import { useQuery } from '@tanstack/react-query';
import {
  CrewTimecardList,
  CrewTimecardListOptions,
} from 'features/timecards/types';
import { axios } from 'services/api/axios';
import { AxiosError } from 'axios';
import { Org } from 'features/org';
/**
 *
 * @param crewId Crew ID of the crew you wish to view timecards for
 * @param params API Options defined at the link below
 * @returns Result from react-query useQuery hook
 * @link https://cappsq.psc.uss.com/SBWM-DOCUMENT/swagger-ui/index.html?urls.primaryName=sbwm-schedulepay#/crew-time-card-controller/getTimecards
 *
 */
export const useCrewTimecards = (
  crewId: string,
  params: CrewTimecardListOptions,
  userCrews: Org[] = []
) => {
  const isValidCrew = !!userCrews.find((crew) => crew.id === crewId);

  return useQuery<CrewTimecardList, AxiosError>({
    queryKey: [`crew-timecards`, crewId, { ...params }],
    queryFn: () => get_Timecards(crewId, params),
    enabled: !!crewId && isValidCrew && !!params.week,
    refetchOnMount: true,
  });
};
/**
 *
 * @param params API Options
 * @param crewId Crew key for the crew you wish to request
 * @returns Promise
 */
const get_Timecards = (
  crewId: string,
  params: CrewTimecardListOptions
): Promise<CrewTimecardList> => {
  return axios.get(`/v1/crews/${crewId}/timecards`, { params });
};

export const useCrewTimecard = (
  crewId: string,
  params: CrewTimecardListOptions
) => {
  return useQuery<CrewTimecardList, AxiosError>({
    queryKey: [`crew-timecards`, crewId, { ...params }],
    queryFn: () => get_Timecards(crewId, params),
    enabled: !!crewId && !!params.week,
    refetchOnMount: true,
  });
};
