import Badge from 'components/Badge/Badge';
import { CalendarBlock } from 'components/CalendarV2/types';

export const PtoYearContent = ({
  calendardata,
}: {
  calendardata?: CalendarBlock | null;
}) => {
  return (
    <>
      <span
        className="w-full sm:flex items-end flex-grow flex-wrap hidden"
        data-testid="pto-event"
      >
        {' '}
        {calendardata?.events &&
          calendardata.events.map((item, i) => (
            <span key={i} data-testid="pto-event-text">
              <Badge variant={item.color}>{item.text}</Badge>
            </span>
          ))}
      </span>
      <span className="flex items-end flex-grow flex-wrap sm:hidden">
        {calendardata?.events &&
          calendardata.events.map((_item, _index) => (
            <span
              key={_index}
              className="bg-gray-900 h-1.5 w-1.5 mr-0.5  rounded-full"
            ></span>
          ))}
      </span>
    </>
  );
};
