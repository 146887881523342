import { ReviewStatus } from '../types';

export const getReviewStatusText = (status: ReviewStatus | null) => {
  switch (status) {
    case ReviewStatus.NOT_OPEN:
      return 'Not Started';
    case ReviewStatus.REVIEW_DUE:
      return 'Review due';
    case ReviewStatus.SUPERVISOR_REVIEW:
      return 'Supervisor review';
    case ReviewStatus.REVALUATE:
      return 'Re-evaluate';
    case ReviewStatus.HR_LR_REVIEW:
      return 'HR/LR review';
    case ReviewStatus.COUNSELLING:
      return 'Counseling';
    case ReviewStatus.EMP_TERMINATED:
      return 'Emp. Terminated';
    case ReviewStatus.EMP_ACKNOWLEDGEMENT:
      return 'Emp. acknowledge';
    case ReviewStatus.SUPERVISOR_ACKNOWLEDGEMENT:
      return 'Supervisor acknowledge';
    case ReviewStatus.SUPERVISOR_TERM_ACKNOWLEDGEMENT:
      return 'Supervisor termination acknowledge';
    case ReviewStatus.FINAL_REVIEW:
      return 'Final review';
    case ReviewStatus.NOT_REVIEWED:
      return 'Review due';
    case ReviewStatus.TERMINATED:
      return 'Terminated';
    case ReviewStatus.RETAINED:
      return 'Retained';
    case ReviewStatus.LOW_RATING:
      return 'Low rating';

    default:
      return '-';
  }
};
