import { PayPeriod, PayPeriodList } from 'features/pay-periods';

export const getFuturePayPeriod = (data: PayPeriodList | undefined) => {
  const futurePayPeriod: PayPeriod[] = [];
  const futurePPRecords = data?.items.filter(
    (entry) => entry.status === 'not-open'
  );
  if (futurePPRecords) {
    futurePPRecords.forEach((entry) => {
      const startDateVal = Date.parse(entry.startDate);
      const endDateVal = Date.parse(entry.endDate);
      const today = Date.now();
      if (today <= startDateVal && today < endDateVal)
        futurePayPeriod.push(entry);
    });
  }
  return futurePayPeriod;
};
