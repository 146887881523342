import { useGenerateCalendarDates } from 'features/paid-time-off';
import { CalendarBlock, CalendarEvent, CalendarHoliday } from './types';
import { MonthView } from './View/MonthView';
import { YearView } from './View/YearView';

interface CalendarProps {
  date: Date;
  view: string;
  events: CalendarEvent[];
  holidays: CalendarHoliday[];
  selected: Date;
  handleDateClick?: (date: Date) => void;
  monthContent?: React.FC<{ calendardata: CalendarBlock }>;
  yearContent?: React.FC<{ calendardata: CalendarBlock }>;
  clickable?: boolean;
}
const Calendar = (props: CalendarProps) => {
  const calendarDates = useGenerateCalendarDates(
    props.date,
    props.view,
    props.events,
    props.selected,
    props.holidays
  );

  const YearContent = props.yearContent;
  const MonthContent = props.monthContent;
  const EmptyContent: React.FC = () => {
    return <></>;
  };
  return (
    <div className="rounded-md p-2 lg:p-4 bg-white my-4">
      {props.view === 'month' ? (
        <MonthView
          blocks={calendarDates}
          renderItem={(employee) =>
            MonthContent ? (
              <MonthContent calendardata={employee} />
            ) : (
              <EmptyContent />
            )
          }
          handleClick={props.handleDateClick}
        />
      ) : (
        <YearView
          blocks={calendarDates}
          renderItem={(employee) =>
            YearContent ? (
              <YearContent calendardata={employee} />
            ) : (
              <EmptyContent />
            )
          }
          handleClick={props.handleDateClick}
        />
      )}
    </div>
  );
};

export default Calendar;
