import useCurrentDate from 'hooks/useCurrentDate';
import { usePayPeriodsShiftAssignment } from 'features/pay-periods';

export const usePayPeriodsFilter = (payrollName: string) => {
  const { currentOpenWeek } = useCurrentDate({ weekStart: 6 });
  const { data: payPeriodsByPayrollName } =
    usePayPeriodsShiftAssignment(payrollName);
  const sixMonthsAgo = new Date(currentOpenWeek);

  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 7);

  if (payPeriodsByPayrollName) {
    const pastSixMonthsPayPeriodsAndCurrent =
      payPeriodsByPayrollName.items.filter(({ startDate, status }) => {
        return new Date(startDate) >= sixMonthsAgo && status !== 'not-open';
      });
    const notOpen3PayPeriods = payPeriodsByPayrollName.items
      .filter(({ status }) => {
        return status === 'not-open';
      })
      .slice(0, 3);

    return [...pastSixMonthsPayPeriodsAndCurrent, ...notOpen3PayPeriods].sort(
      (a, b) => {
        return a.startDate > b.startDate
          ? 1
          : b.startDate > a.startDate
          ? -1
          : 0;
      }
    );
  }
};
