import { Autocomplete, Button, Drawer, Select } from '@uss/react-components';
import { usePayPeriod } from 'features/pay-periods/api';
import { usePayrollName } from 'features/pay-periods/api/getPayrollName';
import { useSendEmail } from 'features/pay-periods/api/sendEmail';
import { PayrollName, SendEmailPayload } from 'features/pay-periods/types';
import { PeopleOptions, Person } from 'features/people';
import { useSearchPeople } from 'features/people/api';
import React, { useEffect, useState } from 'react';
import { SelectedEmployeeDetails } from './SelectedEmployeeDetails';
import { SuggestionsList } from './SuggestionsList';

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  selectedPaycyclelName: PayrollName | null;
}
export const EmailSchedule = ({
  open,
  setOpen,
  selectedPaycyclelName,
}: Props) => {
  const currentYear = new Date().getFullYear().toString();
  const [inputValue, setInputValue] = useState('');
  const [payrollName, setPayrollName] = useState<PayrollName | null>(null);
  const [selectedPayYear, setSelectedPayYear] = useState<string>(currentYear);
  const [selectedOption, setSelectedOption] = useState<Person | null>(null);

  // Data Fetching
  const { data: payrollNamesData } = usePayrollName({ enabled: 'Y' });
  const { data } = usePayPeriod(payrollName ? payrollName.id : '');

  useEffect(() => {
    if (selectedPaycyclelName) setPayrollName(selectedPaycyclelName);
  }, [selectedPaycyclelName]);

  const searchPayload: PeopleOptions = {
    search: inputValue,
    page: '1',
    pageSize: '50',
  };

  const { data: filteredOptions, status } = useSearchPeople(searchPayload);

  const { mutateAsync: sendEmailRecord } = useSendEmail();

  const handleCancel = (): void => {
    setInputValue('');
    setSelectedOption(null);
    setOpen(false);
    setSelectedPayYear(currentYear);
    setPayrollName(selectedPaycyclelName);
  };

  const onCancelSearch = (): void => {
    setInputValue('');
    setSelectedOption(null);
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.currentTarget.value);
    if (e.currentTarget.value === '') {
      setInputValue('');
      setSelectedOption(null);
    }
  };

  const handleSelect = (option: Person): void => {
    setInputValue(option.lastName);
    setSelectedOption(option);
  };

  const payload = {
    payYear: parseInt(selectedPayYear),
    payrollName: payrollName?.id,
    sendTo: selectedOption?.ussId.toString(),
  };

  const handleSendEmail = async (): Promise<void> => {
    await sendEmailRecord(payload as SendEmailPayload);
    handleCancel();
  };

  return (
    <Drawer
      placement={window.innerWidth > 768 ? 'right' : 'bottom'}
      title="Email Annual Pay Cycle Schedule"
      isOpen={open}
      onClose={(): void => {
        handleCancel();
        setOpen(false);
      }}
      closeButton={window.innerWidth > 768 ? false : true}
      className="w-full sm:w-1/2 md:w-1/3 xl:w-1/4 2xl:w-1/5 md:top-0 top-18  md:rounded-t-none rounded-t-md"
    >
      <div className="flex flex-col">
        <span className="text-xs text-gray-700 font-normal mt-6">Pay Year</span>
        <Select
          selected={selectedPayYear}
          onSelect={setSelectedPayYear}
          className="w-full"
          data-testid={`select-payYear`}
        >
          <Select.Button label={selectedPayYear ? selectedPayYear : ''} />
          <Select.Options>
            {Array.from(new Set(data?.items.map((item) => item.year))).map(
              (item: string, index: number) => (
                <Select.Option truncate key={index} value={item}>
                  <span>{`${item}`}</span>
                </Select.Option>
              )
            )}
          </Select.Options>
        </Select>
        <span className="text-xs text-gray-700 font-normal mt-4">
          Pay Cycle Name
        </span>
        <Select
          selected={payrollName}
          onSelect={setPayrollName}
          className="w-full"
          data-testid={`select-payrollName`}
        >
          <Select.Button
            label={payrollName?.id ? `${payrollName.name}` : 'Select'}
          />
          <Select.Options>
            {payrollNamesData && payrollNamesData.items.length > 0
              ? payrollNamesData.items.map(
                  (item: PayrollName, index: number) => (
                    <Select.Option truncate key={index} value={item}>
                      <span>{`${item.name}`}</span>
                    </Select.Option>
                  )
                )
              : ''}
          </Select.Options>
        </Select>
        <span className="text-xs text-gray-700 font-normal mt-4">
          Send Email To
        </span>

        <Autocomplete
          onClose={onCancelSearch}
          value={inputValue}
          onChange={handleFilter}
          placeholder="Search Name / USSID"
        >
          <SuggestionsList
            numberOfSearchChars={inputValue.length}
            options={filteredOptions?.items}
            onSelect={handleSelect}
            status={status}
          />
        </Autocomplete>
      </div>
      <SelectedEmployeeDetails employee={selectedOption} />
      <div className="flex-grow"></div>

      <Drawer.Controls>
        <Button
          className="md:mb-0 mb-2 w-full"
          variant="outline"
          onClick={handleCancel}
          data-testid={`cancel-button`}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="md:ml-4 w-full"
          onClick={handleSendEmail}
          data-testid={`send-button`}
        >
          Send
        </Button>
      </Drawer.Controls>
    </Drawer>
  );
};
