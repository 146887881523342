import React from 'react';
import { Avatar, Link } from '@uss/react-components';
import Card from 'components/Card/Card';
import SeparatedLabels from 'components/SeparatedLabels/SeparatedLabels';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';
import { NavLink } from 'react-router-dom';
import { Person } from 'features/people/types';
import { getInitials, avatarColor } from 'utils';
import getFormattedPersonStatus from './utilities/getFormattedPersonStatus';

interface Props {
  person: Person;
  payrollLocation: string;
  plant: string;
}

export const PersonCardSecurity = ({
  person,
  payrollLocation,
  plant,
}: Props) => {
  const empClass =
    person.empClass && person.empClassDesc
      ? `${person.empClass}-${person.empClassDesc}`
      : '---';
  return (
    <Card>
      <Link
        as={NavLink}
        className="block cursor-pointer"
        key={person.ussId}
        to={`assign-security/${person.ussId}`}
      >
        <Card.Header>
          <div className="flex items-center">
            <Avatar color={avatarColor(person.ussId)} size="medium">
              {getInitials(person.firstName, person.lastName)}
            </Avatar>
            <div className="pl-3">
              <Title as="h2">{person.displayName}</Title>
              <div className="flex">
                <SeparatedLabels
                  size="md"
                  items={[
                    {
                      label: 'Employee Type',
                      value: person.unionInd === 'y' ? 'Union' : 'Non Union',
                    },
                    { label: 'USS ID', value: person.ussId },
                    {
                      label: 'Employee Class',
                      value: empClass,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="flex flex-wrap items-center w-full mb-4">
            <LabeledText label="Plant" className="w-1/2" lineClamp={1}>
              {plant ? plant : '-'}
            </LabeledText>
            <LabeledText
              label="Payroll Location"
              className="w-1/2"
              lineClamp={1}
            >
              {payrollLocation ? payrollLocation : person.payrollLocation}
            </LabeledText>

            {/* <LabeledText label="Payroll Location">
              {payrollLocation ? payrollLocation : person.payrollLocation}
            </LabeledText> */}
          </div>
          <div className="flex flex-wrap items-center w-full">
            <LabeledText label="Job Title" className="w-1/2 pr-3" lineClamp={1}>
              {person.jobTitle}
            </LabeledText>
            <LabeledText label="Status" className="w-1/2" lineClamp={1}>
              {getFormattedPersonStatus(person.status)}
            </LabeledText>
          </div>
        </Card.Body>
      </Link>
    </Card>
  );
};
