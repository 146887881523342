import { Accordion, Avatar, Checkbox } from '@uss/react-components';
import Card from 'components/Card/Card';
import SeparatedLabels from 'components/SeparatedLabels/SeparatedLabels';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';
import getFormattedPersonStatus from 'features/people/utilities/getFormattedPersonStatus';
import { avatarColor, getInitials } from 'utils';
import EmployeeCardContext, {
  useEmployeeCardBodyContext,
} from './EmployeeCardBodyContext';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import {
  EmployeeCardProps,
  EmployeeDisplayProps,
} from 'features/probationary-tracking/types';
import useWindowSize from 'hooks/useWindowSize';
import getFormattedEmployeeName from 'features/probationary-tracking/utilities/getFormattedEmployeeName';
import getFormattedReviewNumber from 'features/probationary-tracking/utilities/getFormattedReviewNumber';

interface DisplayItemProps {
  label: string;
  value: string | undefined;
}
function EmployeeCard({
  employee,
  isEmployeeSelected,
  handleOnChange,
  selectable,
  clickable,
  collapsible = false,
  children,
}: EmployeeCardProps) {
  const { width } = useWindowSize();
  // Used to navigate to new page when row is clicked, ignoring checkbox clicks.  Checkbox registers a row click because it is in the row.
  const navigate = useNavigate();
  const handleRowClick = (ussId: number, target: Element) => {
    const targetType = target.getAttribute('type');
    if (targetType !== 'checkbox') {
      navigate(`${ussId}`);
    }
  };
  return (
    <div className="my-4" data-testid="employee-card">
      {collapsible && !!width && width < 768 ? (
        <Accordion
          variant="default"
          className="shadow-md bg-white rounded-lg border border-primary-400"
        >
          <Accordion.Button variant="default">
            <DetailsHeader
              selectable={selectable}
              employee={employee}
              isEmployeeSelected={isEmployeeSelected}
              handleOnChange={handleOnChange}
            />
          </Accordion.Button>
          <Accordion.Panel variant="default">
            {clickable ? (
              <ButtonWrapper
                employee={employee}
                handleRowClick={handleRowClick}
              >
                <AccordionContent employee={employee} renderItem={children} />
              </ButtonWrapper>
            ) : (
              <AccordionContent employee={employee} renderItem={children} />
            )}
          </Accordion.Panel>
        </Accordion>
      ) : (
        <Card bgColor={'bg-white'}>
          <Card.Body>
            {clickable ? (
              <ButtonWrapper
                employee={employee}
                handleRowClick={handleRowClick}
              >
                <CardContent
                  selectable={selectable}
                  employee={employee}
                  isEmployeeSelected={isEmployeeSelected}
                  handleOnChange={handleOnChange}
                  renderItem={children}
                />
              </ButtonWrapper>
            ) : (
              <CardContent
                selectable={selectable}
                employee={employee}
                isEmployeeSelected={isEmployeeSelected}
                handleOnChange={handleOnChange}
                renderItem={children}
              />
            )}
          </Card.Body>
        </Card>
      )}
    </div>
  );
}
const DetailsHeader = ({
  selectable,
  employee,
  isEmployeeSelected,
  handleOnChange,
}: {
  selectable?: boolean;
  employee: EmployeeDisplayProps;
  isEmployeeSelected?: boolean;
  handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div
      className="flex items-center w-full h-full md:w-[30%] relative md:pr-2 md:after:w-0.5 md:after:absolute md:after:right-0 md:after:h-full md:after:bg-gray-200"
      data-testid="employee-card-header"
    >
      {selectable && (
        <Checkbox
          label={employee.ussId.toString()}
          name={employee.ussId.toString()}
          id={employee.ussId.toString()}
          hideLabel
          checked={isEmployeeSelected}
          onChange={handleOnChange}
        />
      )}
      <div className="mr-3">
        <Avatar color={avatarColor(employee.ussId)} size="medium">
          {getInitials(employee.firstName, employee.lastName)}
        </Avatar>
      </div>
      <div className="w-full">
        <Title as="h2" data-testid="employee-displayname">
          {employee.displayName}
        </Title>
        <SeparatedLabels
          size="md"
          items={[
            {
              label: 'USS ID',
              value: employee.ussId,
            },

            {
              label: 'Employee Status',
              value: getFormattedPersonStatus(employee.status),
            },
          ]}
        />
      </div>
    </div>
  );
};
const DetailsBody = ({
  employee,
  children,
}: {
  employee: EmployeeDisplayProps;
  children?: React.ReactNode;
}) => {
  return (
    <EmployeeCardContext.Provider
      value={{
        data: employee,
      }}
    >
      <div
        className="flex flex-wrap justify-between md:flex-nowrap w-full md:w-[70%] md:pl-2"
        data-testid="employee-card-body"
      >
        {children}
      </div>
    </EmployeeCardContext.Provider>
  );
};
const AccordionContent = ({
  employee,
  renderItem,
}: {
  employee: EmployeeDisplayProps;
  renderItem?: React.ReactNode;
}) => {
  return (
    <div className="flex flex-row flex-wrap w-full pt-4 items-center border-t border-t-gray-200">
      <DetailsBody employee={employee}>{renderItem}</DetailsBody>
    </div>
  );
};

const CardContent = ({
  selectable,
  isEmployeeSelected,
  handleOnChange,
  employee,
  renderItem,
}: {
  selectable?: boolean;
  employee: EmployeeDisplayProps;
  isEmployeeSelected?: boolean;
  handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  renderItem?: React.ReactNode;
}) => {
  return (
    <div className="md:flex items-center w-full md:h-full md:p-2">
      <DetailsHeader
        selectable={selectable}
        employee={employee}
        isEmployeeSelected={isEmployeeSelected}
        handleOnChange={handleOnChange}
      />
      <div className="bg-gray-200 w-full h-0.5 mx-0 my-3 md:hidden"></div>
      <DetailsBody employee={employee}>{renderItem}</DetailsBody>
    </div>
  );
};
const ButtonWrapper = ({
  employee,
  children,
  handleRowClick,
}: {
  children: React.ReactNode;
  employee: EmployeeDisplayProps;
  handleRowClick: (ussId: number, target: Element) => void;
}) => {
  return (
    <button
      className="w-full"
      onClick={(e) => {
        const target = e.target as Element;
        handleRowClick(employee.ussId, target);
      }}
    >
      {children}
    </button>
  );
};
function DisplayItem({ label, value }: DisplayItemProps) {
  return (
    <LabeledText
      label={label}
      lineClamp={1}
      className="w-1/2 md:w-1/5 mb-3 md:mb-0  md:pl-2 md:last-of-type:pl-0 capitalize text-left"
    >
      {value ?? '-'}
    </LabeledText>
  );
}

function StartDate() {
  const { data } = useEmployeeCardBodyContext();
  return (
    <DisplayItem
      label="Start Date"
      value={
        data.startDate ? format(parseISO(data.startDate), 'MMM d, yyyy') : '-'
      }
    />
  );
}
function EstEndDate() {
  const { data } = useEmployeeCardBodyContext();
  return (
    <DisplayItem
      label="Est. End Date"
      value={
        data.estEndDate ? format(parseISO(data.estEndDate), 'MMM d, yyyy') : '-'
      }
    />
  );
}
function Supervisor({ customLabel }: { customLabel?: string }) {
  const { data } = useEmployeeCardBodyContext();

  return (
    <DisplayItem
      label={customLabel ? customLabel : 'Supervisor'}
      value={data.supervisor ? getFormattedEmployeeName(data.supervisor) : '-'}
    />
  );
}
function Reviewer() {
  const { data } = useEmployeeCardBodyContext();
  return (
    <DisplayItem
      label="Reviewer"
      value={data.reviewer ? getFormattedEmployeeName(data.reviewer) : '-'}
    />
  );
}
function BargainingUnit() {
  const { data } = useEmployeeCardBodyContext();
  return (
    <DisplayItem
      label="Bargaining Unit"
      value={data.bargUnit ? data.bargUnit : '-'}
    />
  );
}
function ReviewPeriod() {
  const { data } = useEmployeeCardBodyContext();

  const formattedStartDate = data.startDate
    ? format(parseISO(data.startDate), 'MMM d')
    : '';

  const formattedEndDate = data.estEndDate
    ? format(parseISO(data.estEndDate), 'MMM d, yyyy')
    : '';

  return (
    <DisplayItem
      label="Review Period"
      value={`${formattedStartDate} - ${formattedEndDate}`}
    />
  );
}
function HoursWorked() {
  const { data } = useEmployeeCardBodyContext();
  return (
    <DisplayItem label="Hours Worked" value={data.workedHours?.toString()} />
  );
}
function ReviewStatus() {
  const { data } = useEmployeeCardBodyContext();
  return (
    <DisplayItem
      label="Review Status"
      value={data.reviewStatus ? data.reviewStatus : '-'}
    />
  );
}
function ReviewNumber() {
  const { data } = useEmployeeCardBodyContext();
  return (
    <DisplayItem
      label="Review Number"
      value={getFormattedReviewNumber(data.activeReviewNumber)}
    />
  );
}

function SupervisorUssId() {
  const { data } = useEmployeeCardBodyContext();
  return (
    <DisplayItem
      label="Supervisor USS ID"
      value={
        data.supervisor
          ? data.supervisor.ussId
            ? `${data.supervisor.ussId}`
            : '-'
          : '-'
      }
    />
  );
}

EmployeeCard.StartDate = StartDate;
EmployeeCard.EstEndDate = EstEndDate;
EmployeeCard.Supervisor = Supervisor;
EmployeeCard.Reviewer = Reviewer;
EmployeeCard.BargainingUnit = BargainingUnit;
EmployeeCard.ReviewPeriod = ReviewPeriod;
EmployeeCard.HoursWorked = HoursWorked;
EmployeeCard.ReviewStatus = ReviewStatus;
EmployeeCard.ReviewNumber = ReviewNumber;
EmployeeCard.SupervisorUssId = SupervisorUssId;

export default EmployeeCard;
