import { Checkbox } from '@uss/react-components';
import Card from 'components/Card/Card';
import { differenceInWeeks, parseISO } from 'date-fns';
import { CopySectionProps, SectionType } from 'features/timecards/types';
import React from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import WeekRangeSelect from './WeekRangeSelect';

const CopyFromSection = ({
  weeksState,
  updateWeekState,
  data,
  sourceCrewKey,
  id,
  weekRangeChecked,
  setWeekRangeChecked,
  errorState,
  updateErrorState,
  isMySchedule,
  startDayOfWeek,
}: CopySectionProps) => {
  const weekPeriod =
    weeksState.source.firstWeek !== '' &&
    weekRangeChecked.from &&
    weeksState.source.lastWeek
      ? differenceInWeeks(
          parseISO(weeksState.source.lastWeek),
          parseISO(weeksState.source.firstWeek)
        )
      : 0;

  const startDate =
    weeksState.source.firstWeek !== ''
      ? parseISO(weeksState.source.firstWeek)
      : null;

  return (
    <Card>
      <Card.Body>
        <div
          className="flex items-center justify-center sm:mb-28 "
          data-testid="from-card"
        >
          <div className="w-11/12 md:w-3/4 xl:3/4 2xl:3/4">
            <h2 className=" font-semibold text-lg text-gray-700">From</h2>
            <div className="bg-white border-t border-gray-300  my-2"></div>
            {!isMySchedule && (
              <div className=" flex flex-col ">
                <p className="font-semibold">{data?.displayName}</p>
                <p className="text-xs text-gray-700">{id}</p>
              </div>
            )}
            <div className=" flex flex-col my-4">
              <p className="text-xs text-gray-700">Crew</p>
              <p className=" text-sm font-medium text-gray-900 ">
                {sourceCrewKey}
              </p>
            </div>
            <Checkbox
              label="Week Range"
              id="daterange-from"
              checked={weekRangeChecked.from}
              onChange={() => {
                setWeekRangeChecked({
                  from: !weekRangeChecked.from,
                  to: false,
                });
                updateErrorState({ source: false, target: errorState.target });
              }}
            />
            <WeekRangeSelect
              fromDateError={errorState}
              weekRangeChecked={weekRangeChecked}
              startDate={startDate}
              endDate={
                weeksState.source.lastWeek
                  ? parseISO(weeksState.source.lastWeek)
                  : null
              }
              sectionType={SectionType.FROM}
              updateWeekState={updateWeekState}
              updateErrorState={updateErrorState}
              startDayOfWeek={startDayOfWeek}
            />
            {weekPeriod > 0 && (
              <div
                className="p-2 flex items-center "
                data-testid="source-week-selected-info"
              >
                <BsInfoCircle className="text-gray-700" size={'16'} />
                <p className=" text-sm pl-2  text-gray-700">
                  {weekPeriod + 1} week{weekPeriod > 1 && 's'} selected
                </p>
              </div>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CopyFromSection;
