import { useQuery } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { PayrollNameList, PayrollNameOptions } from '../types';

const get_payroll_names = (
  params?: PayrollNameOptions
): Promise<PayrollNameList> => {
  return axios.get('/v1/payrolls', { params });
};

export const usePayrollName = (params?: PayrollNameOptions) => {
  return useQuery({
    queryKey: ['payrollName'],
    queryFn: () => get_payroll_names(params),
  });
};
