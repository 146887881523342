import { Button } from '@uss/react-components';
import Card from 'components/Card/Card';
import useNotification from 'components/Notification/useNotification';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';
import { format, parseISO } from 'date-fns';
import { downloadPerformanceReport } from 'features/probationary-tracking/api/downloadPerformanceReport';
import {
  PerfReviewButton,
  ReviewDetails,
  ReviewStatus,
} from 'features/probationary-tracking/types';
import getPerfReviewButtonLabel from 'features/probationary-tracking/utilities/getPerfReviewButtonLabel';
import { getReviewStatusText } from 'features/probationary-tracking/utilities/getReviewStatusText';
import isPerfReviewButtonDisabled from 'features/probationary-tracking/utilities/isPerfReviewButtonDisabled';
import { BsDownload, BsEye } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
interface Props {
  review: ReviewDetails;
  pathname: string;
}
export const ReviewPerformanceCard = ({ review, pathname }: Props) => {
  const navigate = useNavigate();
  const notify = useNotification();
  const { ussId = '' } = useParams<'ussId'>();

  const {
    reviewNumber,
    workedHours,
    reviewPeriodStartDt,
    reviewPeriodEndDt,
    reviewStatus,
    approvedDate,
    id,
  } = review;

  const buttonLabel = getPerfReviewButtonLabel(pathname, reviewStatus);
  const isOngoing =
    reviewStatus !== ReviewStatus.NOT_OPEN &&
    reviewStatus !== ReviewStatus.RETAINED &&
    reviewStatus !== ReviewStatus.TERMINATED;

  const startIcon =
    buttonLabel === PerfReviewButton.VIEW_REPORT ? (
      <BsEye
        size="1rem"
        className="text-primary-400"
        aria-label={'View Report'}
      />
    ) : buttonLabel === PerfReviewButton.DOWNLOAD_REPORT ? (
      <BsDownload
        size="1rem"
        className="text-primary-400"
        aria-label={'Download Report'}
      />
    ) : (
      <></>
    );

  const handleClick = async (
    id: string,
    buttonLabel: '-' | PerfReviewButton
  ) => {
    if (buttonLabel === PerfReviewButton.DOWNLOAD_REPORT) {
      await downloadPerformanceReport(ussId, id, reviewNumber, {
        onSuccess: () => {
          notify.success({
            message: `Performance report has been downloaded successfully.`,
          });
        },
        onError: () => {
          notify.danger({
            message: `Unexpected error occured. Unable to download performance report.`,
          });
        },
      });
    } else {
      navigate(`performance-review/${id}`);
    }
  };
  return (
    <Card>
      <Card.Header>
        <Title as={'h2'} aria-label="Review Title">
          {`Performance Review #${reviewNumber ? reviewNumber : '-'}`}
        </Title>
        {isOngoing && (
          <span
            className={`flex items-center text-gray-900 justify-center px-2 lg:text-sm text-xs leading-7 rounded-full bg-gray-200`}
            data-testid="ongoing-label"
          >
            <span className="capitalize">Ongoing</span>
          </span>
        )}
      </Card.Header>
      <Card.Body>
        <div className="flex flex-wrap items-center w-full mb-4">
          <LabeledText label="Hours Worked" className="w-1/2" lineClamp={1}>
            {workedHours ? workedHours : '-'}
          </LabeledText>
          <LabeledText label="Review Period" className="w-1/2" lineClamp={1}>
            {reviewPeriodStartDt || reviewPeriodEndDt
              ? `${
                  reviewPeriodStartDt
                    ? format(parseISO(reviewPeriodStartDt), 'MMM dd')
                    : ''
                } - ${
                  reviewPeriodEndDt
                    ? format(parseISO(reviewPeriodEndDt), 'MMM dd, yyyy')
                    : ''
                }`
              : '-'}
          </LabeledText>
        </div>
        <div className="flex flex-wrap items-center w-full">
          <LabeledText
            label="Review Status"
            className="w-1/2 pr-3"
            lineClamp={1}
          >
            {getReviewStatusText(reviewStatus)}
          </LabeledText>
          <LabeledText label="Approved date" className="w-1/2" lineClamp={1}>
            {approvedDate
              ? format(parseISO(approvedDate), 'MMM dd, yyyy')
              : '-'}
          </LabeledText>
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="flex items-center justify-center">
          <Button
            disabled={isPerfReviewButtonDisabled(pathname, reviewStatus)}
            variant="text"
            className="text-sm text-primary-400"
            onClick={() => handleClick(id, buttonLabel)}
            startIcon={startIcon}
          >
            {buttonLabel}
          </Button>
        </div>
      </Card.Footer>
    </Card>
  );
};
