import { Button, Datepicker, Select } from '@uss/react-components';
import Page from 'components/Layouts/Page';
import { useEffect, useState } from 'react';

import Pagination from 'components/Pagination/Pagination';
import { add, format, parseISO } from 'date-fns';
import {
  PAY_PERIOD_SORT,
  PayrollName,
  usePayCycleParams,
  usePayPeriods,
} from 'features/pay-periods';
import { usePayrollName } from 'features/pay-periods/api/getPayrollName';
import { usePersonDetails } from 'features/people/api';

import PayCycleTable from 'features/pay-periods/components/PayCyclesTable';
import useWindowSize from 'hooks/useWindowSize';
import { BsEnvelope, BsInfoCircle } from 'react-icons/bs';
import { SortDirection } from 'types/common';

import { EmailSchedule } from 'features/pay-periods/components/EmailSchedule/EmailSchedule';

const PayCycles = () => {
  const { data: loggedInUser, status } = usePersonDetails('me');
  const { data: payrollNamesData, status: payrollNameStatus } =
    usePayrollName();

  const [payrollName, setPayrollName] = useState<PayrollName | null>(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(add(new Date(), { days: 6 }));
  const [addOpen, setAddOpen] = useState(false);
  useEffect(() => {
    if (payrollNameStatus === 'success' && status === 'success') {
      const userPayroll = payrollNamesData.items.find((item) => {
        return item.id === loggedInUser.payrollNameId;
      });
      setPayrollName(userPayroll ? userPayroll : payrollNamesData.items[0]);
    }
  }, [loggedInUser, payrollNameStatus, payrollNamesData, status]);

  useEffect(() => {
    if (payrollName?.openPayperiod.startDate) {
      const startDate = payrollName.openPayperiod.startDate.split('T')[0];
      setStartDate(parseISO(startDate));
      dispatch({
        type: 'startDate',
        payload: format(parseISO(startDate), 'yyyy-MM-dd'),
      });
    }
    if (payrollName?.currentYearLastPayPeriod.endDate) {
      const endate = payrollName.currentYearLastPayPeriod.endDate.split('T')[0];
      setEndDate(parseISO(endate));
      dispatch({
        type: 'endDate',
        payload: format(parseISO(endate), 'yyyy-MM-dd'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payrollName]);

  const { state, dispatch } = usePayCycleParams({
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
    page: 1,
    pageSize: 10,
    sortBy: PAY_PERIOD_SORT.STARTDATE,
    sortType: SortDirection.ASC,
  });

  // Data Fetching
  const { data } = usePayPeriods(payrollName ? payrollName.id : '', state);

  //Change Handlers
  const handleStartDateChange = (value: Date) => {
    setStartDate(value);
    dispatch({ type: 'startDate', payload: format(value, 'yyyy-MM-dd') });
  };

  const handleEndDateChange = (value: Date) => {
    setEndDate(value);
    dispatch({ type: 'endDate', payload: format(value, 'yyyy-MM-dd') });
  };
  const { width } = useWindowSize();

  return (
    <Page breadcrumbs={['Reference Links']} heading="Pay Cycle Schedule">
      <Button
        variant="text"
        className="text-primary-400 text-base font-semibold flex items-center mr-2 mb-2 pr-4 justify-center w-full sm:justify-end"
        startIcon={<BsEnvelope size={'0.75rem'} />}
        onClick={(): void => setAddOpen(true)}
      >
        <span className="text-base mr-2">Email Schedule</span>
      </Button>
      <EmailSchedule
        open={addOpen}
        setOpen={setAddOpen}
        selectedPaycyclelName={payrollName}
      />

      <Page.Section>
        <div className="p-2 bg-white rounded-md">
          <Page.Filters>
            <Page.FiltersSection className="w-full sm:w-2/5 xl:w-4/12 px-4 flex flex-wrap border-r border-gray-500 ">
              <span className="text-xs text-gray-600">Pay Cycle</span>
              <Select
                selected={payrollName}
                onSelect={setPayrollName}
                className="w-full"
              >
                <Select.Button
                  label={payrollName?.id ? `${payrollName.name}` : 'Select'}
                />
                <Select.Options>
                  {payrollNamesData && payrollNamesData.items.length > 0
                    ? payrollNamesData.items.map(
                        (item: PayrollName, index: number) => (
                          <Select.Option truncate key={index} value={item}>
                            <span>{`${item.name}`}</span>
                          </Select.Option>
                        )
                      )
                    : ''}
                </Select.Options>
              </Select>
            </Page.FiltersSection>
            <Page.FiltersSection className="w-full sm:w-3/5 xl:w-4/6">
              <span className="mr-3">
                <span className="text-xs text-gray-600">Date Range From</span>
                <Datepicker
                  startDate={startDate}
                  onDateChange={handleStartDateChange}
                  calendarType="date"
                  id="date1"
                  isMobile={width && width < 1024 ? true : false}
                />
              </span>
              <span>
                <span className=" text-xs text-gray-600">Date Range To</span>
                <Datepicker
                  startDate={endDate}
                  onDateChange={handleEndDateChange}
                  calendarType="date"
                  id="date2"
                  isMobile={width && width < 1024 ? true : false}
                />
              </span>
            </Page.FiltersSection>
          </Page.Filters>
          {data?.lastPayCycleLoadedDate ? (
            <div className="flex flex-row mb-3 bg-blue-50 py-2">
              <BsInfoCircle className="ml-2 text-blue-500" />
              <span className="ml-2 text-sm text-gray-700">
                {`Annual Pay Cycle Schedule was last updated on ${format(
                  parseISO(data.lastPayCycleLoadedDate),
                  'MMM dd, yyyy'
                )}`}
              </span>
            </div>
          ) : (
            ''
          )}

          <PayCycleTable paycycles={data} />
        </div>
      </Page.Section>

      <Page.Section>
        {!!data?.totalCount && (
          <Pagination
            pageOptions={[5, 10, 15, 20, 25, 30]}
            totalCount={data.totalCount}
            page={state.page}
            pageSize={state.pageSize}
            onPageChange={(s) =>
              dispatch({ type: 'page', payload: s.selected + 1 })
            }
            onPageSizeChange={(ps) =>
              dispatch({ type: 'pageSize', payload: ps })
            }
          />
        )}
      </Page.Section>
    </Page>
  );
};

export default PayCycles;
