import useWindowSize from 'hooks/useWindowSize';
import { ToastContainer } from 'react-toastify';

export const NotificationContainer = () => {
  const { width } = useWindowSize();
  return (
    <>
      {width &&
        (width > 768 ? (
          <ToastContainer
            style={{
              width: '70%',
            }}
            closeButton={false}
          />
        ) : (
          <ToastContainer closeButton={false} />
        ))}
    </>
  );
};
