import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { queryClient } from 'services/api/react-query';
import { notify } from 'components/Notification/useNotification';
import { Paycode, PaycodeStatus } from '../types';
import { PAYCODE_STATUS } from 'features/paycode/constants';
import { CUSTOM_ERROR_MESSAGE } from 'features/paycode/constants';
import { axios } from 'services/api/axios';

const codeSchema = z.union([
  z.literal(PAYCODE_STATUS.ACTIVE),
  z.literal(PAYCODE_STATUS.ARCHIVED),
]);
export const PaycodeArchiveBodySchema = z.object({
  status: z.object({
    code: codeSchema,
  }),
  comments: z
    .string({
      required_error: CUSTOM_ERROR_MESSAGE.emptyComments,
      invalid_type_error: CUSTOM_ERROR_MESSAGE.emptyComments,
    })
    .min(1, { message: CUSTOM_ERROR_MESSAGE.emptyComments }),
});

export interface PaycodeUpdateStatusBody {
  comments: string;
  status: {
    code: PaycodeStatus;
  };
}

const updatePaycodeStatus = (
  paycodeId: string,
  body: PaycodeUpdateStatusBody
): Promise<Paycode> => {
  return axios.patch(`/v3/paycodes/${paycodeId}`, body);
};

const getSuccessMessage = (code: PaycodeStatus): string => {
  let successMsg = '';
  if (code === PAYCODE_STATUS.DENIED) {
    successMsg = 'You have successfully approved this pay code.';
  } else if (code === PAYCODE_STATUS.ACTIVE) {
    successMsg = 'You have successfully archived this pay code.';
  } else if (code === PAYCODE_STATUS.ARCHIVED) {
    successMsg = 'You have successfully restored this pay code.';
  }
  return successMsg;
};

export const useUpdatePaycodeStatus = (
  paycodeId: string,
  code: PaycodeStatus
) => {
  return useMutation({
    mutationFn: (body: PaycodeUpdateStatusBody) =>
      updatePaycodeStatus(paycodeId, body),

    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: [`get-paycodes-${paycodeId}`, paycodeId],
      });
      notify.success({
        message: getSuccessMessage(code),
      });
    },
  });
};
