import { axios } from 'services/api/axios';
import { WMSError } from 'types/common';

export const downloadPerformanceReport = async (
  ussId: string,
  id: string,
  reviewNumber: number | null,
  options?: { onSuccess?: () => void; onError?: () => void }
) => {
  await axios
    .get(`/v1/prob-emp/${ussId}/reviews/${id}/report`, {
      responseType: 'arraybuffer',
    })
    .then((res) => {
      const pdfBlob = new Blob([res as unknown as BlobPart], {
        type: 'application/pdf',
      });
      const downloadUrl = URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = reviewNumber
        ? `performance_report_${reviewNumber}.pdf`
        : 'performance_report.pdf';
      link.click();
      URL.revokeObjectURL(downloadUrl);
    })
    .then(() => !!options?.onSuccess && options.onSuccess())
    .catch((err: WMSError) => {
      !!options?.onError && options.onError();
      throw new Error(err.message);
    });
};
