import CardList from 'components/Card/CardList';
import Page from 'components/Layouts/Page';
import CardsLoader from 'components/Loaders/CardsLoader';
import Pagination from 'components/Pagination/Pagination';
import Error from 'components/Error/Error';

import { Org, OrgInfoCard, ORG_TYPE, useOrgParams } from 'features/org';
import { useOrgChildren, useOrgDetails } from 'features/org/api';
import { CrewCard } from 'features/org/components';
import { OrgTools, SortOptionsType } from 'features/org/components/OrgTools';
import { NavLink, useParams } from 'react-router-dom';
import { SortDirection } from 'types/common';

const Crews = () => {
  const { id = '' } = useParams<'id'>();

  const { state, dispatch } = useOrgParams({
    page: '1',
    pageSize: '9',
    sortBy: 'crew',
    sortType: SortDirection.ASC,
    status: 'active',
  });
  const { data: crews, status: crewStatus } = useOrgChildren('me', {
    type: ORG_TYPE.CREW,
    parentOrgId: id ? id : null,
    ...state,
  });
  const noResults = {
    title: 'No Records Found',
    description:
      'No crew records found, Please modify the search criteria to view the records',
  };

  const { data, status } = useOrgDetails(id);

  const sortOptions: SortOptionsType[] = [
    {
      label: 'Recently Added',
      name: 'recently-added',
      direction: SortDirection.DESC,
    },
    {
      label: 'Crew',
      name: 'crew',
      direction: SortDirection.ASC,
    },
    {
      label: 'Crew',
      name: 'crew',
      direction: SortDirection.DESC,
    },
    {
      label: 'Description',
      name: 'description',
      direction: SortDirection.ASC,
    },
    {
      label: 'Description',
      name: 'description',
      direction: SortDirection.DESC,
    },
  ];

  return (
    <>
      {data && (
        <Page
          breadcrumbs={[
            <NavLink key="bc-1" to="/admin">
              Admin
            </NavLink>,
            <NavLink key="bc-2" to="/admin/org-structure/divisions">
              Org Structure Divisions
            </NavLink>,
            <NavLink
              key="bc-2"
              to={`/admin/org-structure/${data.parentOrgId ?? ''}/departments`}
            >
              Org Structure Departments
            </NavLink>,
          ]}
          heading="Org Maintenance - Crews"
        >
          <Page.Section bg="gray">
            <div className="flex mb-2 text-xs md:text-sm md:justify-end">
              Crews are maintained under Crew Maintenance Module
            </div>
            {status === 'success' && (
              <div className="mb-6">
                <OrgInfoCard type={ORG_TYPE.CREW} data={data} />
              </div>
            )}

            <OrgTools
              params={state}
              dispatch={dispatch}
              type={ORG_TYPE.CREW}
              sortOptions={sortOptions}
              parentOrgId={data.parentOrgId}
              code={data.status.code}
            />
          </Page.Section>
          <Page.Section>
            {crewStatus === 'pending' && <CardsLoader count={9} />}
            {crewStatus === 'success' && (
              <CardList<Org>
                items={crews.items}
                renderItem={(org) => <CrewCard crew={org} />}
                noResults={noResults}
              />
            )}
            {crewStatus === 'error' && <Error />}
          </Page.Section>
          <Page.Section bg="gray">
            {!!crews?.totalCount && (
              <Pagination
                pageOptions={[9, 18, 27, 36, 45, 54]}
                totalCount={crews.totalCount}
                page={crews.page}
                pageSize={crews.pageSize}
                onPageChange={(s) =>
                  dispatch({ type: 'page', payload: s.selected + 1 })
                }
                onPageSizeChange={(ps) =>
                  dispatch({ type: 'pageSize', payload: ps })
                }
              />
            )}
          </Page.Section>
        </Page>
      )}
    </>
  );
};

export default Crews;
