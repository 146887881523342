import React from 'react';
import { useEmployeePtoStore } from 'features/paid-time-off/state/mypto-store';
import { PtoFilters } from 'features/paid-time-off/components/Filters/PtoFilters';

export function EmployeePtoFilters() {
  const {
    calendarType,
    startDate,
    status,
    sortBy,
    sortType,
    onSelectDate,
    toggleYearMonth,
    setStatus,
    setPtoDetailsView,
    setYear,
    setSort,
  } = useEmployeePtoStore((state) => ({
    calendarType: state.calendarType,
    startDate: state.startDate(),
    status: state.params.status,
    sortBy: state.params.sortBy,
    sortType: state.params.sortType,
    onSelectDate: state.onSelectDate,
    toggleYearMonth: state.toggleYearMonth,
    setStatus: state.setStatus,
    setPtoDetailsView: state.setPtoDetailsView,
    setYear: state.setYear,
    setSort: state.setSort,
  }));
  return (
    <PtoFilters
      calendarType={calendarType}
      startDateValue={startDate}
      status={status}
      sortBy={sortBy}
      sortType={sortType}
      onSelectDate={onSelectDate}
      toggleYearMonth={toggleYearMonth}
      setStatus={setStatus}
      setPtoDetailsView={setPtoDetailsView}
      setYear={setYear}
      setSort={setSort}
    />
  );
}
