type ClientId =
  | '0a62dd83-9cde-4e04-b643-c87f1c353314' // DEV
  | 'd53a01b7-10c8-4574-8169-bdedf05b4910' // QA / UAT
  | '3c78ad6e-ebec-48f6-80e4-31801b1fd620'; // PROD

type ApiScope =
  | 'https://workforcemanagement.uss.com/employee.read' //DEV
  | 'https://workforcemanagementqa.uss.com/employee.read' // QA / UAT
  | 'https://workforcemanagementprod.uss.com/employee.read'; // PROD

interface Config {
  API_SCOPE: ApiScope;
  CLIENT_ID: ClientId;
}

type ConfigObj =
  | typeof LOCAL_AUTH_CONFIG
  | typeof DevConfig
  | typeof QAConfig
  | typeof UATConfig
  | typeof ProdConfig;

const DevConfig = {
  API_SCOPE: 'https://workforcemanagement.uss.com/employee.read',
  CLIENT_ID: '0a62dd83-9cde-4e04-b643-c87f1c353314',
} as const;
const QAConfig = {
  API_SCOPE: 'https://workforcemanagementqa.uss.com/employee.read',
  CLIENT_ID: 'd53a01b7-10c8-4574-8169-bdedf05b4910',
} as const;
const UATConfig = {
  API_SCOPE: 'https://workforcemanagementqa.uss.com/employee.read',
  CLIENT_ID: 'd53a01b7-10c8-4574-8169-bdedf05b4910',
} as const;
const ProdConfig: Config = {
  API_SCOPE: 'https://workforcemanagementprod.uss.com/employee.read',
  CLIENT_ID: '3c78ad6e-ebec-48f6-80e4-31801b1fd620',
} as const;

// Update this to determine which config to use for the local environment, this needs to match with the LOCAL_BASE_URL
const LOCAL_AUTH_CONFIG = QAConfig;

// ** BASE URL VALUES
const BASE_PREFIX = '/api';
const LOCAL_BASE_URL = 'https://cappsq.psc.uss.com';

export const BASE_URL =
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'test' ||
  window.location.hostname === 'localhost'
    ? LOCAL_BASE_URL + BASE_PREFIX
    : BASE_PREFIX;

// **************************

function getAuthConfig(hostname?: string): ConfigObj {
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test' ||
    window.location.hostname === 'localhost'
  ) {
    return LOCAL_AUTH_CONFIG;
  }
  switch (hostname) {
    case 'cappsd.psc.uss.com':
      return DevConfig;
    case 'cappsq.psc.uss.com':
      return QAConfig;
    case 'cappsu.psc.uss.com':
      return QAConfig;
    case 'cappsp.psc.uss.com':
      return ProdConfig;
    case 'wms.uss.com':
      return ProdConfig;
    default:
      return ProdConfig;
  }
}

export const AUTH_CONFIG: ConfigObj = getAuthConfig(window.location.hostname);
