import { SortDirection } from 'types/common';
import { ObjectValues } from 'types/utils';

export const HISTORY_ENTITY = {
  EMPLOYEE_TIMECARD: 'employee-timecard',
  PAY_PERIODS_WEEKLY: 'pay-periods-weekly',
  PAY_PERIODS_SEMI_MONTHLY: 'pay-periods-semi-monthly',
  PAY_PERIODS_MONTHLY: 'pay-periods-monthly',
  DIVISION: 'division',
  DEPARTMENT: 'department',
  CREW: 'crew',
  PAY_CODES: 'pay-codes',
  USER_SECURITY: 'user-security',
  ORG_SECURITY: 'org-security',
  CREW_BY_EMP: 'crew-by-emp',
  CREW_BY_CREW: 'crew-by-crew',
  SELLBACK_PTO: 'sellback-pto',
  PTO: 'pto',
} as const;

export type HistoryEntity = ObjectValues<typeof HISTORY_ENTITY>;

const HISTORY_EVENT = {
  NOT_VERIFIED: 'not-verified',
  VERIFIED: 'verified',
  APPROVED: 'approved',
  UNAPPROVED: 'unapproved',
  OPEN_ADJUSTMENT: 'open-adjustment',
  APPROVED_ADJUSTMENT: 'approved-adjustment',
  UNAPPROVED_ADJUSTMENT: 'unapproved-adjustment',
  NOT_OPEN: 'not-open',
  OPEN: 'open',
  LOCKED: 'locked',
  PROCESSED: 'processed',
  CLOSED: 'closed',
  ACTIVE: 'active',
  PENDING: 'pending',
  ARCHIVED: 'archived',
  REJECTED: 'rejected',
  ADDED: 'added',
  DELETED: 'deleted',
  UPDATED: 'updated',
  SELLBACK_PTO: 'sellback-pto',
} as const;

type HistoryEvent = ObjectValues<typeof HISTORY_EVENT>;

export type HistoryExpand = 'y' | 'n';

export type HistorySort = 'action-taken-on';

export interface HistoryItem {
  id: string;
  event: string;
  entityId: string;
  comments: string;
  actionTakenBy: string;
  actionTakenOn: string;
}

export interface HistoryItemList {
  items: HistoryItem[];
  page: number;
  pageSize: number;
  totalCount: number;
}

export interface HistoryOptions {
  entity: HistoryEntity;
  expand?: HistoryExpand;
  entityId?: string;
  type?: HistoryEvent;
  sortBy?: HistorySort;
  sortType?: SortDirection;
  pageSize?: string;
  page?: string;
  year?: string;
}
