import Card from 'components/Card/Card';
import Loader from 'components/Skeleton/Loader';
import LabeledText from 'components/Typography/LabeledText';

const PolicySummaryLoader = ({ width }: { width: number | undefined }) => {
  return (
    <div data-testid="policy-summary-loader">
      <div className="flex justify-between my-4 w-full">
        <p className="w-2/5">
          <Loader rows={1} />
        </p>
        <p className="w-2/5">
          <Loader rows={1} />
        </p>
      </div>
      {!!width && width < 768 ? (
        <PolicySummaryAccordionLoader />
      ) : (
        <PolicySummaryCardLoader />
      )}
    </div>
  );
};
const PolicySummaryCardLoader = () => {
  return (
    <Card>
      <Card.Body>
        <div className="flex w-full mb-4 ">
          <div className="flex flex-col md:gap-y-4 md:flex-row justify-center md:justify-start md:items-center w-1/2 md:w-[70%] xl:w-3/4 border-r-4 border-gray-300 pr-4  md:pr-0">
            {Array.from(Array(3).keys()).map((val) => (
              <div
                className="w-full md:w-1/3 mb-6 md:mb-0 last-of-type:mb-0"
                key={`adjustment-${val}`}
              >
                <div className="flex flex-col justify-around w-full md:w-4/5 xl:w-3/5">
                  <p className="mb-2">
                    <Loader rows={1} />
                  </p>
                  <p>
                    <Loader rows={1} />
                  </p>
                  <p>
                    <Loader rows={1} />
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-col  md:flex-row justify-center md:justify-between  w-1/2 md:w-[30%] xl:w-1/4 ">
            <div className="w-full h-full flex flex-col justify-center py-4  md:py-0 pl-4 md:pr-4">
              <p className="text-xs  font-light">Total Approved</p>
              <p className="text-gray-900 ">
                <Loader rows={1} />
              </p>
            </div>

            <div className="w-full h-full flex flex-col justify-center py-4  md:py-0 pl-4 border-t md:border-t-0 md:border-l-4  border-gray-300">
              <p className="text-xs font-light">Holiday Pay</p>
              <p className="text-gray-900">
                <Loader rows={1} />
              </p>
            </div>
          </div>
        </div>
        <hr className="  border-gray-300" />
        <div className="w-full md:w-11/12 mt-4 ">
          <div
            id="worked-hours"
            className="flex flex-col justify-center w-full mb-4"
          >
            <p className="text-gray-920 text-sm text-left font-semibold">
              Worked Hours
            </p>
            <div className="flex flex-wrap gap-y-4">
              <div className="w-1/2 md:w-1/5 pr-4">
                <LabeledText label="Regular" lineClamp={1}>
                  <Loader rows={1} />
                </LabeledText>
              </div>
              <div className="w-1/2 md:w-1/5 pl-4 md:pl-0 md:pr-4">
                <LabeledText label="Overtime" lineClamp={1}>
                  <Loader rows={1} />
                </LabeledText>
              </div>

              <div className="w-1/2 md:w-1/5 pr-4">
                <LabeledText label="Holiday" lineClamp={1}>
                  <Loader rows={1} />
                </LabeledText>
              </div>
              <div className="w-1/2 md:w-1/5 pl-4 md:pl-0 md:pr-4">
                <LabeledText label="Training" lineClamp={1}>
                  <Loader rows={1} />
                </LabeledText>
              </div>
              <div className="w-1/2 md:w-1/5 pr-4">
                <LabeledText label="Training OT" lineClamp={1}>
                  <Loader rows={1} />
                </LabeledText>
              </div>
            </div>
          </div>

          <div
            id="unworked-hours"
            className="flex flex-col justify-center w-full"
          >
            <p className="text-gray-920 text-sm text-left font-semibold">
              Unworked Hours
            </p>
            <div className="flex flex-wrap  gap-y-4 ">
              <div className="w-1/2 md:w-1/5 pr-4">
                <LabeledText label="PTO" lineClamp={1}>
                  <Loader rows={1} />
                </LabeledText>
              </div>
              <div className="w-1/2 md:w-1/5 pl-4 md:pl-0 md:pr-4">
                <LabeledText label="Funeral Leave" lineClamp={1}>
                  <Loader rows={1} />
                </LabeledText>
              </div>

              <div className="w-1/2 md:w-1/5 pr-4">
                <LabeledText label="Jury Duty" lineClamp={1}>
                  <Loader rows={1} />
                </LabeledText>
              </div>
              <div className="w-1/2 md:w-1/5 pl-4 md:pl-0 md:pr-4">
                <LabeledText label="Worked Time Off" lineClamp={1}>
                  <Loader rows={1} />
                </LabeledText>
              </div>
              <div className="w-1/2 md:w-1/5 pr-4">
                <LabeledText label="Paid Family Leave" lineClamp={1}>
                  <Loader rows={1} />
                </LabeledText>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
const PolicySummaryAccordionLoader = () => {
  return (
    <Card>
      <Card.Body>
        <div className="flex w-full mb-4 ">
          <div className="flex flex-col md:gap-y-4 md:flex-row justify-center md:justify-start md:items-center w-1/2 md:w-[70%] xl:w-3/4 border-r-4 border-gray-300 pr-4  md:pr-0">
            {Array.from(Array(3).keys()).map((val) => (
              <div
                className="w-full md:w-1/3 mb-6 md:mb-0 last-of-type:mb-0"
                key={`adjustment-${val}`}
              >
                <div className="flex flex-col justify-around w-full md:w-4/5 xl:w-3/5">
                  <p className="mb-2">
                    <Loader rows={1} />
                  </p>
                  <p>
                    <Loader rows={1} />
                  </p>
                  <p>
                    <Loader rows={1} />
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-col md:flex-row  justify-center md:justify-between  w-1/2 md:w-[30%] xl:w-1/4  ">
            <div className="w-full h-full  flex flex-col justify-center py-4  md:py-0 pl-4 ">
              <p className="text-xs  font-light">Total Approved</p>
              <p>
                <Loader rows={1} />
              </p>
            </div>

            <div className="md:w-full h-full flex flex-col justify-center py-4  md:py-0 md:pl-4 ml-4 md:ml-0 border-t md:border-t-0 md:border-l-4  border-gray-300">
              <p className="text-xs font-light">Holiday Pay</p>
              <p>
                <Loader rows={1} />
              </p>
            </div>
          </div>
        </div>
        <hr className=" mt-4 mb-3 border-gray-300" />
        <Loader rows={1} />
      </Card.Body>
    </Card>
  );
};
export default PolicySummaryLoader;
