import Page from 'components/Layouts/Page';
import React from 'react';

const SchedulingOverview = () => {
  return (
    <Page heading="Scheduling">
      <Page.Section padding>
        <div className="bg-white p-4 rounded-md">
          <p>
            This module provides supervisors with the ability to schedule their
            crews. They are also able to Verify, mass Verify, and Copy schedules
            into the future.
          </p>
        </div>
      </Page.Section>
    </Page>
  );
};

export default SchedulingOverview;
