import { useReducer } from 'react';
import { SortDirection } from 'types/common';
import { PayPeriodListOptions, PayPeriodSort } from 'features/pay-periods';

export const usePayCycleParams = (initialParams: PayPeriodListOptions) => {
  type ActionType =
    | { type: 'startDate'; payload: string }
    | { type: 'endDate'; payload: string }
    | { type: 'payrollName'; payload: PayPeriodListOptions }
    | { type: 'page'; payload: number }
    | { type: 'pageSize'; payload: number }
    | {
        type: 'filter';
        payload: { name: PayPeriodSort; direction: SortDirection };
      };

  const filterReducer = (
    state: PayPeriodListOptions,
    action: ActionType
  ): PayPeriodListOptions => {
    switch (action.type) {
      case 'startDate': {
        state.startDate = action.payload;
        state.page = 1;
        return { ...state };
      }
      case 'endDate': {
        if (action.payload !== '') {
          state.endDate = action.payload;
        } else delete state.endDate;
        state.page = 1;
        return { ...state };
      }
      case 'page': {
        state.page = action.payload;
        return { ...state };
      }
      case 'pageSize': {
        state.pageSize = action.payload;
        return { ...state };
      }
      case 'filter': {
        state.sortBy = action.payload.name;
        state.sortType = action.payload.direction;
        return { ...state };
      }

      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(filterReducer, initialParams);

  return { state, dispatch };
};

// export default usePayCycleParams;
