import { Table } from '@uss/react-components';
import React from 'react';
import SkeletonElements from 'components/Skeleton/SkeletonElements';

interface CrewListingTableSkeletonProps {
  numberOfRows: number;
  numberOfColumns: number;
}
const CrewListingTableSkeleton = ({
  numberOfRows,
  numberOfColumns,
}: CrewListingTableSkeletonProps) => {
  return (
    <Table.Body>
      {Array.from(Array(numberOfRows)).map((n, i) => (
        <Table.BodyRow key={i}>
          {Array.from(Array(numberOfColumns)).map((num, index) => (
            <Table.BodyCell className="px-2" key={index}>
              <SkeletonElements elementType="tableColumn" />
            </Table.BodyCell>
          ))}
        </Table.BodyRow>
      ))}
    </Table.Body>
  );
};

export default CrewListingTableSkeleton;
