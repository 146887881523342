import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

const TabLink = (props: NavLinkProps) => {
  return (
    <NavLink
      {...props}
      className={({ isActive }: { isActive: boolean }) =>
        'py-2 px-4 text-gray-700  w-full inline-block h-full' +
        (isActive
          ? ' border-b-4 border-accent1-400 font-semibold pb-px  w-full inline-block h-full'
          : '')
      }
      to={props.to}
    >
      {props.children}
    </NavLink>
  );
};

export default TabLink;
