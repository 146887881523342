import React, { useEffect, useState } from 'react';
import { Button, Drawer } from '@uss/react-components';
import { BsPlusLg } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import { RoleItem } from 'features/roles';
import { SecurityPayload } from 'features/security';
import { useGetOrgRoles, RoleListOptions } from 'features/roles/api';
import { useAddEmployeeSecurity } from 'features/security/api/addEmployeeSecurity';
import SecuritySelectInput from './SecuritySelectInput';
import RoleSelectInput from './RoleSelectInput';
import { Org, ORG_TYPE } from 'features/org';
import { useOrgChildren } from 'features/org/api';
import { wrapAsyncFunction } from 'utils';

interface AddSecurityRecordProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const AddSecurityRecord = ({
  isOpen,
  setIsOpen,
}: AddSecurityRecordProps) => {
  const [selectedPlant, setPlant] = useState<Org | null>(null);
  const [selectedLocation, setLocation] = useState<Org | null>(null);
  const [selectedDivision, setDivision] = useState<Org | null>(null);
  const [selectedDepartment, setDepartment] = useState<Org | null>(null);
  const [selectedCrew, setCrew] = useState<Org | null>(null);
  const [selectedRole, setRole] = useState<RoleItem | null>(null);
  const [orgId, setOrgId] = useState<string | undefined>('');

  const [isValid, setIsValid] = useState<boolean>(false);

  const { id = '' } = useParams<'id'>();

  const { data: plants, status: plantStatus } = useOrgChildren('me', {
    type: ORG_TYPE.PLANT,
    parentOrgId: null,
    parentOrgs: 'y',
    sortBy: 'plant',
  });
  const { data: locations, status: locationStatus } = useOrgChildren('me', {
    type: ORG_TYPE.LOCATION,
    parentOrgId: selectedPlant ? selectedPlant.id : null,
    sortBy: 'location',
  });
  const { data: divisions, status: divisionStatus } = useOrgChildren('me', {
    type: ORG_TYPE.DIVISION,
    parentOrgId: selectedLocation ? selectedLocation.id : null,
    sortBy: 'division',
  });
  const { data: departments, status: departmentStatus } = useOrgChildren('me', {
    type: ORG_TYPE.DEPARTMENT,
    parentOrgId: selectedDivision ? selectedDivision.id : null,
    sortBy: 'department',
  });
  const { data: crews, status: crewStatus } = useOrgChildren('me', {
    type: ORG_TYPE.CREW,
    parentOrgId: selectedDepartment ? selectedDepartment.id : null,
    sortBy: 'crew',
  });

  useEffect(() => {
    let _orgId = null;
    if (selectedCrew?.id) {
      _orgId = selectedCrew.id;
    } else if (selectedDepartment?.id) {
      _orgId = selectedDepartment.id;
    } else if (selectedDivision?.id) {
      _orgId = selectedDivision.id;
    } else if (selectedLocation?.id) {
      _orgId = selectedLocation.id;
    } else {
      _orgId = selectedPlant?.id;
    }
    setOrgId(_orgId);

    if (selectedRole !== null) {
      setIsValid(false);
    }
  }, [
    selectedPlant,
    selectedLocation,
    selectedDivision,
    selectedDepartment,
    selectedCrew,
    selectedRole,
  ]);

  const roleSearchParams: RoleListOptions = {
    orgId: orgId,
    sortBy: 'name',
  };
  const { data: roleData, status: roleStatus } =
    useGetOrgRoles(roleSearchParams);

  //assign securities
  const assignSecurity = useAddEmployeeSecurity(id);
  const onPlantChange = (value: Org) => {
    setPlant(value);
    setLocation(null);
    setDivision(null);
    setDepartment(null);
    setCrew(null);
    setRole(null);
  };

  const onLocationChange = (value: Org) => {
    setLocation(value);

    setDivision(null);
    setDepartment(null);
    setCrew(null);
    setRole(null);
  };

  const onDivisionChange = (value: Org) => {
    setDivision(value);

    setDepartment(null);
    setCrew(null);
    setRole(null);
  };
  const onDepartmentChange = (value: Org) => {
    setDepartment(value);

    setCrew(null);
    setRole(null);
  };

  const onCrewChange = (value: Org) => {
    setCrew(value);
    setRole(null);
  };
  const onRoleChange = (value: RoleItem) => {
    setRole(value);
  };

  const handleOnSave = async () => {
    if (selectedRole) {
      const payload: SecurityPayload = {
        orgId: selectedRole.orgId,
        role: selectedRole.roleCode,
      };
      await assignSecurity.mutateAsync(payload);
      clearData();
      setIsOpen(false);
    } else {
      setIsValid(true);
    }
  };

  const handleOnClose = () => {
    setIsOpen(false);
    clearData();
  };

  const clearData = () => {
    setPlant(null);
    setLocation(null);
    setDivision(null);
    setDepartment(null);
    setCrew(null);
    setRole(null);
    setIsValid(false);
  };

  return (
    <>
      <Button
        onClick={(): void => setIsOpen(true)}
        className="text-primary-400 text-base font-semibold flex items-center mx-2"
        variant="text"
        startIcon={<BsPlusLg size="1rem" />}
      >
        <span className="hidden sm:inline-block">Add Record</span>
        <span className="inline-block sm:hidden">Add</span>
      </Button>

      <Drawer
        placement={window.innerWidth > 768 ? 'right' : 'bottom'}
        title="Add Record"
        className="w-full sm:w-1/2 md:w-1/3 xl:w-1/4 2xl:w-1/5 md:top-0 top-18 md:rounded-t-none rounded-t-md"
        isOpen={isOpen}
        onClose={handleOnClose}
        closeButton={window.innerWidth > 768 ? false : true}
      >
        <div className="flex-grow">
          <div>
            <span className="text-xs text-gray-700 font-normal">Plant</span>
            <SecuritySelectInput
              selectedValue={selectedPlant}
              selectData={plants}
              dataStatus={plantStatus}
              onSelect={onPlantChange}
              type={ORG_TYPE.PLANT}
            />
          </div>
          <div className="mt-2">
            <span className="text-xs text-gray-700 font-normal">Location</span>
            <SecuritySelectInput
              selectedValue={selectedLocation}
              selectData={locations}
              dataStatus={locationStatus}
              onSelect={onLocationChange}
              type={ORG_TYPE.LOCATION}
            />
          </div>
          <div className="mt-2">
            <span className="text-xs text-gray-700 font-normal">Division</span>
            <SecuritySelectInput
              selectedValue={selectedDivision}
              selectData={divisions}
              dataStatus={divisionStatus}
              onSelect={onDivisionChange}
              type={ORG_TYPE.DIVISION}
            />
          </div>
          <div className="mt-2">
            <span className="text-xs text-gray-700 font-normal">
              Department
            </span>
            <SecuritySelectInput
              selectedValue={selectedDepartment}
              selectData={departments}
              dataStatus={departmentStatus}
              onSelect={onDepartmentChange}
              type={ORG_TYPE.DEPARTMENT}
            />
          </div>
          <div className="mt-2">
            <span className="text-xs text-gray-700 font-normal">Crew</span>
            <SecuritySelectInput
              selectedValue={selectedCrew}
              selectData={crews}
              dataStatus={crewStatus}
              onSelect={onCrewChange}
              type={ORG_TYPE.CREW}
            />
          </div>
          <div className="mt-2">
            <span className="text-xs text-gray-700 font-normal">Role</span>
            <RoleSelectInput
              selectedValue={selectedRole}
              selectData={roleData}
              dataStatus={roleStatus}
              onSelect={onRoleChange}
              error={isValid}
            />
          </div>
        </div>
        <Drawer.Controls>
          <Button
            variant="outline"
            onClick={handleOnClose}
            className="w-full sm:w-auto"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={wrapAsyncFunction(handleOnSave)}
            className="ml-0 sm:ml-4 w-full sm:w-auto mt-3 sm:mt-0"
          >
            Add
          </Button>
        </Drawer.Controls>
      </Drawer>
    </>
  );
};
