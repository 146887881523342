import React from 'react';
import { Person } from 'features/people/types';
import LabeledText from 'components/Typography/LabeledText';
import { QueryStatus } from '@tanstack/react-query';

type PersonProp = Pick<
  Person,
  | 'ussId'
  | 'hrLocation'
  | 'payrollLocation'
  | 'displayName'
  | 'supervisorId'
  | 'emailAddress'
  | 'empClass'
  | 'empClassDesc'
  | 'jobTitle'
>;
interface SuggestionsListProps<T> {
  numberOfSearchChars: number;
  options: T[] | undefined;
  onSelect: (option: T) => void;
  status: QueryStatus;
}

export function SuggestionsList<T extends PersonProp>({
  numberOfSearchChars,
  options,
  onSelect,
  status,
}: SuggestionsListProps<T>) {
  return (
    <div>
      {status === 'success' &&
        (!options?.length ? (
          <span data-testid="no-result" className="flex justify-center">
            No results found!!
          </span>
        ) : (
          options.map((option) => {
            return (
              <div key={option.ussId}>
                <div
                  data-testid="list-item"
                  className="cursor-pointer py-2 px-4  hover:bg-blue-50"
                  role="button"
                  aria-hidden="true"
                  onClick={() => onSelect(option)}
                >
                  <div className=" col-span-2 flex flex-col">
                    <span className="text-base font-semibold text-gray-700">
                      {option.displayName}
                    </span>
                    <span className="text-xs text-gray-700 flex">
                      {option.supervisorId}
                      <span className="bg-gray-300 w-0.5 mx-2"></span>
                      {option.emailAddress}
                    </span>
                  </div>
                  <div>
                    <LabeledText label="Employee Class">
                      {option.empClass}
                      {'-'}
                      {option.empClassDesc}
                    </LabeledText>
                  </div>

                  <div>
                    <LabeledText label="Job Title">
                      {option.jobTitle}
                    </LabeledText>
                  </div>
                  <div>
                    <LabeledText label="Payroll Location">
                      {option.payrollLocation} {'- '}
                      {option.hrLocation}
                    </LabeledText>
                  </div>
                </div>
                <div className="uss-h-0.5 uss-bg-gray-200"></div>
              </div>
            );
          })
        ))}
      {status === 'pending' &&
        (numberOfSearchChars < 3 ? (
          <span className="flex justify-center">
            Enter at least 3 characters
          </span>
        ) : (
          <span className="flex justify-center">Loading Results!!!</span>
        ))}
      {status === 'error' && (
        <span className="flex justify-center">Error occured!!!</span>
      )}
    </div>
  );
}
