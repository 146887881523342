import {
  eachDayOfInterval,
  endOfWeek,
  format as f,
  format,
  parse,
  parseISO,
  startOfWeek,
} from 'date-fns';

/**
 * Hook that creates an array of 7 dates starting with a specific day.
 *
 * @param date Date that is in the week you wish to create a range from
 * @param customFormat Format in which the dates to be returned.
 * @param weekStartsOn The day of week that should be start day of the week.
 * @example
 *  const { dates } = getDateRange('2021-10-27','yyyy-MM-dd', 6)
 */

interface DatesType {
  startDate: string;
  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
  customFormat?: string;
}

export const getDates = ({
  startDate,
  weekStartsOn,
  customFormat = 'yyyy-MM-dd',
}: DatesType) => {
  const d = f(parse(startDate, 'yyyy-MM-dd', new Date()), customFormat);
  const day = parseISO(d);

  // Getting the current week
  const weekBegin =
    startDate &&
    f(startOfWeek(day, { weekStartsOn: weekStartsOn }), customFormat);

  const weekEnd =
    startDate &&
    f(endOfWeek(day, { weekStartsOn: weekStartsOn }), customFormat);

  const getDateRangeArr = () => {
    //creating an array of dates between start and end day of the selected week in custom format

    const dateRangeArr = eachDayOfInterval({
      start: startOfWeek(parseISO(weekBegin), { weekStartsOn: weekStartsOn }),
      end: endOfWeek(parseISO(weekBegin), { weekStartsOn: weekStartsOn }),
    });

    //getting array of dates formated with formatISO
    return dateRangeArr.map((date) => format(date, customFormat));
  };

  const dates = getDateRangeArr();

  return { weekBegin, weekEnd, dates };
};
