import { AreaManagerTabs, HrLrTabs, ReviewerTabs } from '../types';
import { ReviewActionLabel } from '../types/employeeReviewDetails';
import getCurrentView from './getCurrentView';

export interface ReviewActionModalProps {
  title: string;
  cancelButtonLabel: string;
  confirmButtonLabel: string;
  modalBodyText?: string;
}

export const getReviewActionModalProps = (
  pathname: string,
  currentTab: AreaManagerTabs | ReviewerTabs | HrLrTabs | undefined,
  actionLabel: ReviewActionLabel,
  reviewNumber?: number,
  displayName?: string
): ReviewActionModalProps => {
  const { isAreaManagerView, isReviewerView, isHrLrView } =
    getCurrentView(pathname);
  return isAreaManagerView &&
    currentTab === AreaManagerTabs.TERMINATION &&
    actionLabel === ReviewActionLabel.ACKNOWLEDGE
    ? {
        title: 'Acknowledge Report !',
        cancelButtonLabel: 'No',
        confirmButtonLabel: 'Yes',
        modalBodyText:
          'Are you sure you want to acknowledge employee termination?',
      }
    : isReviewerView &&
      currentTab === ReviewerTabs.RE_EVALUATE &&
      (actionLabel === ReviewActionLabel.RETAIN ||
        actionLabel === ReviewActionLabel.TERMINATION)
    ? {
        title: 'Submit Re-evaluation!',
        cancelButtonLabel: 'Cancel',
        confirmButtonLabel: 'Submit',
        modalBodyText: 'Are you sure you want to submit the report?',
      }
    : isReviewerView &&
      currentTab === ReviewerTabs.FINAL_REVIEW &&
      actionLabel === ReviewActionLabel.COMPLETE
    ? {
        title: `Complete performance review #${
          reviewNumber ? reviewNumber : '-'
        }`,
        cancelButtonLabel: 'No',
        confirmButtonLabel: 'Yes',
        modalBodyText: `Are you sure you want to complete performance review #${
          reviewNumber ? reviewNumber : '-'
        }?`,
      }
    : isHrLrView &&
      (currentTab === HrLrTabs.LOW_RATING ||
        currentTab === HrLrTabs.PROB_EMPLOYEES) &&
      actionLabel === ReviewActionLabel.ADD
    ? {
        title: 'Add Comments!',
        cancelButtonLabel: 'No',
        confirmButtonLabel: 'Yes',
        modalBodyText: 'Are you sure you want to add comments?',
      }
    : isHrLrView &&
      currentTab === HrLrTabs.REVIEW_PERFORMANCE &&
      actionLabel === ReviewActionLabel.TERMINATION
    ? {
        title: 'Employee termination!',
        cancelButtonLabel: 'No',
        confirmButtonLabel: 'Yes',
        modalBodyText: `Are you sure you want to recommend termination for ${
          displayName ? displayName : '-'
        }?`,
      }
    : (isAreaManagerView || isHrLrView) &&
      actionLabel === ReviewActionLabel.RETAIN
    ? {
        title: 'Submit performance report!',
        cancelButtonLabel: 'Cancel',
        confirmButtonLabel: 'Submit',
      }
    : {
        title: 'Submit performance report!',
        cancelButtonLabel: 'Cancel',
        confirmButtonLabel: 'Submit',
        modalBodyText: 'Are you sure you want to submit the report?',
      };
};
