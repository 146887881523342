import { ROLES } from 'features/roles';
import { EmployeeSecurityList } from 'features/security';

export const isEligibleToAddBlackout = (
  roles: string[],
  payApproverRecord: EmployeeSecurityList | undefined,
  primaryCrewName: string
) => {
  if (
    roles.includes(ROLES.PAY_APPROVER) &&
    payApproverRecord?.items &&
    primaryCrewName !== ''
  ) {
    //creating array of {permission:'y'} records
    const enabledPermissionArr = payApproverRecord.items.filter(
      (item) =>
        item.role === 'pay_approver' && item.permissions?.ptoBlackOut === 'y'
    );
    //check if user's primary crew value is included in enabledPermissionArr
    const ptoBlackoutValue = enabledPermissionArr.some(
      (item) => item.crew === primaryCrewName
    );
    return ptoBlackoutValue;
  }
};
