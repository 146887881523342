import Page from 'components/Layouts/Page';
import { NavLink, useParams } from 'react-router-dom';
import EmployeeProfile from 'features/people/components/EmployeeProfile';
import PtoRecord from 'features/paid-time-off/components/Details/PTORecord';
import { useEmpPTODetailsByPtoId } from 'features/paid-time-off/api/queries';

const MyPtoRecord = () => {
  const { ptoId = '' } = useParams<'ptoId'>();
  const { data: ptoDetails } = useEmpPTODetailsByPtoId(ptoId);
  const heading =
    ptoDetails?.status === 'blackout' ? 'Blackout Details' : 'PTO Details';

  return (
    <Page
      breadcrumbs={[
        <NavLink to="/my-pto-nu" key="bc-1">
          My PTO (NU)
        </NavLink>,
      ]}
      heading={heading}
    >
      <Page.Section>
        <div className="grid grid-cols-1 gap-4">
          <EmployeeProfile />

          <PtoRecord ptoId={ptoId} ptoType="myPto" />
        </div>
      </Page.Section>
    </Page>
  );
};

export default MyPtoRecord;
