import { z } from 'zod';
import { ZodModelStatus, OwnerSchema, ZodModelType } from './model-list';

export const SchedTurnsSchema = z.object({
  dayIndex: z.number(),
  turnValue: z.number(),
  pkScheduleTurnId: z.string(),
});

export const CrewRotationSchema = z.object({
  pkCrewRotationId: z.string().nullable(),
  crewRotation: z.string(),
  schedTurns: z.array(SchedTurnsSchema),
});

export const WeekSchema = z.object({
  weekNo: z.number(),
  crewRotations: z.array(CrewRotationSchema),
});

export const ModelDetailsSchema = z.object({
  id: z.string(),
  modelName: z.string(),
  modelDescription: z.string(),
  modelType: ZodModelType,
  modelStatus: ZodModelStatus,
  weekBeginIndex: z.number(),
  plantName: z.string(),
  plantOrgId: z.string(),
  payrollLocationCode: z.string(),
  payrollLocationOrgId: z.string(),
  totalWeeks: z.number(),
  totalCrewRotations: z.number(),
  createdOn: z.string(),
  lastUpdatedOn: z.string(),
  lastUpdatedBy: z.string(),
  lastUsedBy: z.string(),
  lastUsedOn: z.string(),
  owner: OwnerSchema,
  weeks: z.array(WeekSchema),
  page: z.number(),
  pageSize: z.number(),
  totalCount: z.number(),
  lastCheckoutBy: z.string(),
  lastCheckoutOn: z.string(),
  lastCheckoutUssId: z.string()
});
