import {
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isAfter,
  isBefore,
  isEqual,
  parse,
  parseISO,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import {
  CalendarBlock,
  CalendarEvent,
  CalendarHoliday,
} from 'components/CalendarV2/types';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const checkHoliday = (
  dtString: string,
  holidays: CalendarHoliday[]
): CalendarHoliday | null => {
  const arr = holidays.filter((m) => {
    return isEqual(m.date, new Date(parseISO(dtString)));
  });
  return arr.length > 0 ? arr[0] : null;
};
export const useGenerateCalendarDates = (
  date: Date,
  type: string,
  events: CalendarEvent[],
  selected: Date,
  holidays: CalendarHoliday[]
) => {
  //calendarBlock list
  const calendarBlock: CalendarBlock[] = [];

  if (type === 'month') {
    let startDate = startOfMonth(date);

    //start month from sunday
    if (format(startDate, 'i') !== '7') {
      startDate = startOfWeek(new Date(startDate));
    }

    let endDate = endOfMonth(date);
    //start month on saturday
    if (format(endDate, 'i') !== '6') {
      endDate = endOfWeek(new Date(endDate));
    }

    //create date array from start and end date
    const dates = eachDayOfInterval({
      start: new Date(startDate),
      end: new Date(endDate),
    });

    for (const monthdate of dates) {
      const _date = format(monthdate, 'yyyy-MM-dd');

      //check if date if holiday
      const _holiday = checkHoliday(_date, holidays);

      //get date events
      const _events = events.filter((m) => {
        return isEqual(m.date, new Date(parseISO(_date)));
      });
      calendarBlock.push({
        date: monthdate,
        dateText: format(monthdate, 'd'),
        text: _holiday ? _holiday.text : '',
        events: _events,
        selected: isEqual(
          new Date(parseISO(_date)),
          new Date(parseISO(format(selected, 'yyyy-MM-dd')))
        ),
        disabled:
          isBefore(monthdate, startOfMonth(date)) ||
          isAfter(monthdate, endOfMonth(date)),
      });
    }
  } else {
    //create year blocks
    for (const month of months) {
      //get month events
      const _events = events.filter((m) => {
        return format(m.date, 'MMM') === month;
      });
      calendarBlock.push({
        date: parse(month + ' ' + format(date, 'y'), 'MMM y', new Date()),
        dateText: month,
        events: _events,
        selected:
          format(new Date(selected), 'MMM') === month &&
          format(new Date(selected), 'y') === format(date, 'y'),
      });
    }
  }

  return calendarBlock;
};
