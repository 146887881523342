export interface ScheduleVarianceProps {
  approvedHours: number | null;
  workedHours: number;
  scheduledHours: number;
  isPay: boolean;
}

export const scheduledVariance = ({
  scheduledHours,
  approvedHours,
  workedHours,
  isPay,
}: ScheduleVarianceProps) => {
  if (isPay) {
    return Number(approvedHours) - scheduledHours;
  } else {
    return workedHours - scheduledHours;
  }
};
