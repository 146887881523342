import { graphConfig } from 'config/authConfig';
import { msgraph } from 'services/api/axios';
import { GraphDataResponse } from 'types';

// eslint-disable-next-line import/no-anonymous-default-export

export const profile = async (): Promise<GraphDataResponse> => {
  const { data } = await msgraph.get<GraphDataResponse>('/me');

  return data;
};

export async function callMsGraphPicture(accessToken: string) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(
    graphConfig.graphMeEndpoint + '/photo/$value?v=' + String(Date.now()),
    options
  )
    .then(async (response: Response) => {
      return await response.blob();
    })
    .catch((error: unknown) => {
      if (error instanceof Error) {
        throw error;
      }
    });
}
