import { notify } from 'components/Notification/useNotification';
import { useMutation } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { queryClient } from 'services/api/react-query';
import { WMSError } from 'types/common';
import { Org, OrgPatch, OrgPatchStatus, ORG_TYPE } from 'features/org';

const updateOrgStatus = (id: string, body: OrgPatch): Promise<Org> => {
  return axios.patch(`/v1/orgs/${id}`, body);
};

export const useUpdateOrgStatus = (
  id: string,
  code: OrgPatchStatus,
  orgType:
    | typeof ORG_TYPE.DIVISION
    | typeof ORG_TYPE.DEPARTMENT
    | typeof ORG_TYPE.CREW
) => {
  return useMutation<Org, WMSError, OrgPatch>({
    mutationFn: (body: OrgPatch) => updateOrgStatus(id, body),

    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: [`org-details-${id}`, id] });
      if (orgType !== ORG_TYPE.CREW) {
        notify.success({
          message:
            code === 'active'
              ? `You have successfully archived this ${orgType.toLowerCase()}.`
              : `You have successfully restored this ${orgType.toLowerCase()}.`,
        });
      }
    },
  });
};
