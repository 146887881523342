import { PAY_PERIOD_STATUS, PayPeriodStatus } from 'features/pay-periods/types';
import { PersonStatus } from 'features/people/types';
import { PaginationResponse, SortDirection } from 'types/common';
import { ObjectValues } from 'types/utils';

import { z } from 'zod';

export const PULLOFF_TYPES = {
  WEEKLY: 'big_river_steel_weekly',
  SEMI_MONTHLY: 'semi-monthly',
  MONTHLY: 'monthly',
} as const;

export type PayPulloffType = ObjectValues<typeof PULLOFF_TYPES>;

export type CrewSortByType = 'crewKey';

// Pay Pulloff

const totalPayPeriodSchema = z.object({
  payPeriodId: z.string(),
  payPeriodStartDate: z.string(),
  payPeriodEndDate: z.string(),
  payPeriodStatus: z.nativeEnum(PAY_PERIOD_STATUS),
  approvedEmployeeCount: z.number(),
  totalApprovedHours: z.number(),
  approvedPercentage: z.number(),
  approvedAdjEmployeeCount: z.number(),
  approvedAdjustmentsCount: z.number(),
  approvedTimecardsCount: z.number(),
});

const locationPayPeriodSchema = z.object({
  payPeriodId: z.string(),
  payPeriodStartDate: z.string(),
  payPeriodEndDate: z.string(),
  payPeriodStatus: z.nativeEnum(PAY_PERIOD_STATUS),
  approvedEmployeeCount: z.number(),
  totalApprovedHours: z.number(),
  approvedPercentage: z.number(),
  approvedAdjEmployeeCount: z.number(),
  approvedAdjustmentsCount: z.number(),
  approvedTimecardsCount: z.number(),
  locationName: z.string(),
  locationDescription: z.string(),
});

export type PayPeriodPulloff = z.infer<typeof payPeriodPulloff>;

const payPeriodPulloff = z.object({
  items: locationPayPeriodSchema.array(),
  total: totalPayPeriodSchema,
});

export interface LocationList {
  locationName: string;
  locationDescription: string;
}
export interface PayPeriodData {
  payPeriodId: string;
  payPeriodStartDate: string;
  payPeriodEndDate: string;
  payPeriodStatus: PayPeriodStatus;
  approvedEmployeeCount: number;
  totalApprovedHours: number;
  approvedPercentage: number;
  approvedAdjEmployeeCount: number;
  approvedAdjustmentsCount: number;
  approvedTimecardsCount: number;
  locationName?: string;
  locationDescription?: string;
}
// Payroll Names
const payrollNameSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
});

export type PayrollNameSchema = z.infer<typeof payrollNameSchema>;

const payrollNameListSchema = z.object({
  items: z.array(payrollNameSchema),
  page: z.number(),
  pageSize: z.number(),
  totalCount: z.number(),
});

export type PayrollName = z.infer<typeof payrollNameListSchema>;

// Unapproved Crews

const UnapprovedCrewsSchema = z.object({
  crewId: z.string(),
  crewKey: z.string(),
  count: z.number(),
  primaryApprover: z.string().nullable(),
});

export type UnapprovedCrew = z.infer<typeof UnapprovedCrewsSchema>;

export interface UnapprovedCrewsGetOptions {
  sortBy?: CrewSortByType;
  sortType?: SortDirection;
  page?: number;
  pageSize?: number;
  locationId?: string | null;
}

// Unapproved Crews Employees

interface UnapprovedCrewTimecardStatus {
  code: string;
  description: string;
  primaryApprover: string;
  actionTakenOn: string;
  actionTakenBy: string;
  totalApprovedHours: number;
}

export interface UnapprovedCrewEmployee {
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  ussId: number;
  employeeType: string;
  crewId: string;
  crewKey: string;
  crewDescription: string;
  empClass: string;
  empClassDesc?: string;
  statusEffectiveDate: string;
  status: UnapprovedCrewTimecardStatus | null;
  otherTimeCards: UnapprovedCrewEmpOtherTimecard[];
  employeeStatus?: PersonStatus;
}
export interface UnapprovedCrewEmpOtherTimecard {
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  ussId: number;
  employeeType: string;
  crewId: string;
  crewKey: string;
  crewDescription: string;
  class: string;
  statusEffectiveDate: string;
  status: UnapprovedCrewTimecardStatus | null;
}

//Unapproved Crew Employees API

export interface UnapprovedCrewEmployeeListOptions {
  search?: string;
  page?: number;
  pageSize?: number;
}

export interface UnapprovedCrewEmployeeList extends PaginationResponse {
  items: UnapprovedCrewEmployee[];
  payPeriodStatus: PayPeriodStatus;
}

// Pay Approvers

const PayApproverSchema = z.object({
  ussId: z.number(),
  firstName: z.string(),
  middleName: z.string().nullable(),
  lastName: z.string(),
  emailAddress: z.string().nullable(),
  displayName: z.string(),
});

const PayApproverDetailsSchema = z.object({
  crewId: z.string(),
  crewKey: z.string(),
  crewDescription: z.string(),
  payApprovers: z.array(PayApproverSchema),
});

export const CrewPayApproverDetailsSchema = z.object({
  items: z.array(PayApproverDetailsSchema),
  page: z.number(),
  pageSize: z.number(),
  totalCount: z.number(),
});

export type CrewPayApproverDetails = z.infer<
  typeof CrewPayApproverDetailsSchema
>;

// export interface CrewPayApproverDetails {
//   items: PayApproverDetails[];
// }

export interface PayApproverOptions {
  crewIds: string;
  approverType?: 'primary' | 'secondary' | 'both';
}
export interface PayrollOptions {
  enabled: 'Y' | 'N';
}
