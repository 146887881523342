import { Accordion } from '@uss/react-components';
import { format } from 'date-fns';
import useWindowSize from 'hooks/useWindowSize';
import getPaycodeTypeText from 'features/paycode/utilities/getPaycodeTypeText';
import getPaycodeStatusText from 'features/paycode/utilities/getPaycodeStatusText';
import { Paycode } from 'features/paycode/types';

interface SelectedPaycodeProps {
  data: Paycode | undefined;
}
export const SelectedPaycodeCard = ({ data }: SelectedPaycodeProps) => {
  const { width } = useWindowSize();
  const DetailsHeader = () => {
    return (
      <div>
        <p
          className="text-gray-700  text-lg font-semibold text-left line-clamp-1"
          aria-label="Paycode And Description"
        >
          {data?.code} - {data?.description}
        </p>
        <div className="flex flex-row">
          <p
            className="text-gray-700 text-sm text-left line-clamp-1"
            aria-label="Paycode Type"
          >
            {data?.type ? getPaycodeTypeText(data.type) : '-'}
          </p>
          <p
            className="text-gray-700 text-sm text-left line-clamp-1"
            aria-label="Paycode Status"
          >
            <span className="px-3">|</span>
            {data?.status.code ? getPaycodeStatusText(data.status.code) : '-'}
          </p>
        </div>
      </div>
    );
  };
  const DetailsBody = () => {
    return (
      <>
        <div className="flex flex-wrap sm:flex-nowrap w-full  lg:w-3/5">
          <span className="w-1/2 sm:w-auto sm:flex-grow  mb-4 sm:mb-0">
            <p
              className="text-gray-600 text-xs text-left"
              id="last_action_taken_by"
            >
              Last Action Taken By
            </p>
            <p
              className="text-gray-900 text-sm text-left font-semibold line-clamp-1"
              aria-labelledby="last_action_taken_by"
            >
              {data?.status.actionTakenBy}
            </p>
          </span>
          <span className="w-1/2 sm:w-auto sm:flex-grow hidden sm:block ">
            <p
              className="text-gray-600 text-xs text-left"
              id="last_action_taken_on"
            >
              Last Action Taken On
            </p>
            <p
              className="text-gray-900 text-sm text-left font-semibold line-clamp-1"
              aria-labelledby="last_action_taken_on"
            >
              {format(
                data?.status.actionTakenOn
                  ? new Date(data.status.actionTakenOn)
                  : new Date(),
                'LLL dd, yyyy'
              )}
            </p>
          </span>
        </div>
      </>
    );
  };
  return (
    <>
      {width &&
        (width < 1024 ? (
          <Accordion
            variant="default"
            className="shadow-md bg-white rounded-lg border border-blue-600"
          >
            <Accordion.Button variant="default">
              <DetailsHeader />
            </Accordion.Button>
            <Accordion.Panel variant="default">
              <div className="flex flex-row flex-wrap w-full items-center border-t border-gray-200 pt-4">
                <DetailsBody />
              </div>
            </Accordion.Panel>
          </Accordion>
        ) : (
          <div className="flex items-center shadow-md w-full p-4 bg-white rounded-lg h-24 border border-blue-600">
            <DetailsHeader />
            <div className="bg-gray-200 w-0.5 h-full mx-6"></div>
            <DetailsBody />
          </div>
        ))}
    </>
  );
};
