const getCurrentView = (pathname: string) => {
  return {
    isAreaManagerView: pathname.includes('area-manager-view'),
    isReviewerView: pathname.includes('reviewer-view'),
    isHrLrView: pathname.includes('hr-lr-view'),
    isEmployeeView: pathname.includes('employee-acknowledgement'),
    isAdminView: pathname.includes('admin-view'),
    isBargainingUnitView: pathname.includes('bargaining-unit-total'),
  };
};
export default getCurrentView;
