import { useMutation } from '@tanstack/react-query';
import { WMSError } from 'types/common';
import { axios } from '../../../services/api/axios';
import {
  DeleteCrewMemberResponse,
  DeleteCrewMemberWithId,
  DeleteCrewMemberWithUssId,
} from '../types';

const deleteCrewMemnber = (
  orgId: string,
  body: DeleteCrewMemberWithId | DeleteCrewMemberWithUssId
): Promise<DeleteCrewMemberResponse> => {
  return axios.delete(`/v1/orgs/${orgId}/security`, { data: body });
};

/**
 * @description Hook that provides functionality to delete a crew member or temp member
 *
 * @param orgId ID of the org you wish to delete a crew member from
 *
 * @example
 *
 * const {mutate} = useDeleteCrewMember('1234')
 * // Call the mutate function to create a crew member for the given crew
 * mutate({id: 'g54h64', role: 'crew_member'})
 */

export const useDeleteCrewMember = (orgId: string) => {
  return useMutation<
    DeleteCrewMemberResponse,
    WMSError,
    DeleteCrewMemberWithId | DeleteCrewMemberWithUssId
  >({
    mutationFn: (body: DeleteCrewMemberWithId | DeleteCrewMemberWithUssId) =>
      deleteCrewMemnber(orgId, body),
    meta: {
      errorMessage: 'Unable to delete crew member.',
    },
  });
};
