import Page from 'components/Layouts/Page';
import { useGetPtoRequests } from 'features/paid-time-off/api/queries';
import { usePtoRequestsStore } from 'features/paid-time-off/state';
import { NavLink } from 'react-router-dom';

import Tabs from 'components/Tabs/Tabs';
import { PtoRequestsOptions } from 'features/paid-time-off/types/pto-requests';
import { PTO_STATUS } from 'features/paid-time-off/constants';
import RequestPtoFilter from 'features/paid-time-off/components/Filters/RequestPtoFilter';
import { useEmployeeOrgs } from 'features/org/api';
import { ORG_TYPE } from 'features/org';
import { SortDirection } from 'types/common';
import { useEffect } from 'react';
import { CompletedRequestsTab } from 'features/paid-time-off/components/PtoRequests/CompletedRequestTab';
import { useCurrentPayPeriodStartDate } from 'features/paid-time-off/hooks/useCurrentPayPeriod';
import { format } from 'date-fns';
import { appNewDate } from 'utils/appNewDate';
import PendingRequestsTab from 'features/paid-time-off/components/PtoRequests/PendingRequestsTab';

const PtoRequests = () => {
  const store = usePtoRequestsStore();
  useEffect(() => {
    if (store.selectedCrew !== 'all' && store.selectedCrew) {
      store.setOrgId(store.selectedCrew.id);
    } else if (
      store.selectedCrew === 'all' &&
      store.selectedDepartment !== 'all' &&
      store.selectedDepartment
    ) {
      store.setOrgId(store.selectedDepartment.id);
    } else if (
      store.selectedCrew === 'all' &&
      store.selectedDepartment === 'all' &&
      store.selectedDivision !== 'all' &&
      store.selectedDivision
    ) {
      store.setOrgId(store.selectedDivision.id);
    } else if (
      store.selectedCrew === 'all' &&
      store.selectedDepartment === 'all' &&
      store.selectedDivision === 'all' &&
      store.selectedPayrollLocation !== 'all' &&
      store.selectedPayrollLocation
    ) {
      store.setOrgId(store.selectedPayrollLocation.id);
    } else if (
      store.selectedCrew === 'all' &&
      store.selectedDepartment === 'all' &&
      store.selectedDivision === 'all' &&
      store.selectedPayrollLocation === 'all' &&
      store.selectedPlant
    ) {
      store.setOrgId(store.selectedPlant.id);
    }
  });

  //getting startDate for current payperiod
  const currentPayPeriodDate = useCurrentPayPeriodStartDate(
    store.selectedPlant ? store.selectedPlant.payrolls : ''
  );

  useEffect(() => {
    //value null means plant having payrolls=null
    if (currentPayPeriodDate === null)
      store.setDate(format(appNewDate(), 'yyyy-MM-dd'));
    //value has date the setDate to store
    if (currentPayPeriodDate && store.params.date !== currentPayPeriodDate) {
      store.setDate(currentPayPeriodDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPayPeriodDate, store.params.date]);

  const pendingPtoRequestsOptions: PtoRequestsOptions = {
    ...store.params,
    status: PTO_STATUS.REQUESTED,
  };
  const { data: pendingPtoRequests, status: pendingPtoRequestsStatus } =
    useGetPtoRequests(store.orgId, pendingPtoRequestsOptions);

  const pendingPtoRequestsCount = pendingPtoRequests
    ? pendingPtoRequests.totalCount
    : '';

  const completedPtoRequestsOptions: PtoRequestsOptions = {
    ...store.params,
    page: store.CompletedPage,
    pageSize: store.CompletedPageSize,
    status: `${PTO_STATUS.APPROVED},${PTO_STATUS.DENIED}`,
  };
  const { data: completedPtoRequests, status: completedPtoRequestsStatus } =
    useGetPtoRequests(store.orgId, completedPtoRequestsOptions);

  const { data: plants, status: plantStatus } = useEmployeeOrgs('me', {
    type: ORG_TYPE.PLANT,
    sortBy: 'plant',
    sortType: SortDirection.ASC,
    // role: role,
    context: 'summary',
  });

  const { data: orgs, status: orgStatus } = useEmployeeOrgs('me', {
    sortType: SortDirection.ASC,
    parentOrgId: store.selectedPlant?.id ?? undefined,
    context: 'summary',
  });

  const tabLinks = [
    {
      label: `Pending (${
        pendingPtoRequestsStatus === 'pending' ? 0 : pendingPtoRequestsCount
      })`,
      element: (
        <>
          <PendingRequestsTab
            ptoRequests={pendingPtoRequests}
            ptoRequestsStatus={pendingPtoRequestsStatus}
          />
        </>
      ),
    },
    {
      label: 'Completed',
      element: (
        <>
          <CompletedRequestsTab
            ptoRequests={completedPtoRequests}
            ptoRequestsStatus={completedPtoRequestsStatus}
          />
        </>
      ),
    },
  ];

  return (
    <Page
      heading="Requests (NU)"
      breadcrumbs={[
        <NavLink
          key="bc-1"
          className="flex flex-row justify-center items-center"
          to={`/paid-time-off`}
        >
          PTO - Vacation
        </NavLink>,
      ]}
    >
      <Page.Section>
        <div className="">
          <RequestPtoFilter
            plants={plants}
            plantStatus={plantStatus}
            orgs={orgs}
            orgStatus={orgStatus}
          />
        </div>
      </Page.Section>
      <Page.Section>
        <Tabs tabLinks={tabLinks} />
      </Page.Section>
    </Page>
  );
};

export default PtoRequests;
