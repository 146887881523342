import React, { useState, useEffect, useRef } from 'react';
import { usePopper } from 'react-popper';
interface TooltipProps {
  children: React.ReactNode;
  tooltipContent: React.ReactNode | string;
  placement:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end';
  clickTrigger?: boolean;
  className?: string;
}
/**
 * @param {string} placement placement could be, 'top', 'top-start', 'top-end', 'bottom','bottom-start', 'bottom-end', 'right', 'right-start', 'right-end', 'left', 'left-start', 'left-end' which is a position of the tooltip
 * @param {string} tooltipContent This is the content of the tooltip which can be a string
 * @param {React.ReactNode} children Required children which shows tooltip onhover|onClick
 */

const Tooltip = ({
  children,
  tooltipContent,
  placement,
  clickTrigger,
  className,
}: TooltipProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [arrowElement, setArrowElement] = useState<
    string | HTMLElement | null
  >();
  const referenceElement = useRef<HTMLDivElement>(null);
  const popperElement = useRef<HTMLDivElement>(null);
  const trigger = window.innerWidth <= 1024 || clickTrigger ? 'click' : 'hover';
  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);
  function handleDocumentClick(event: TouchEvent | MouseEvent): void {
    if (referenceElement.current?.contains(event.target as HTMLElement)) {
      return;
    }
    setIsOpen(false);
  }
  const { styles, attributes } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      placement,
      modifiers: [
        { name: 'arrow', options: { element: arrowElement } },
        {
          name: 'offset',
          options: {
            offset: [0, 5],
          },
        },
        {
          name: 'flip',
          enabled: false,
        },
      ],
    }
  );
  return (
    <>
      <button
        onClick={
          trigger === 'click' ? (): void => setIsOpen(!isOpen) : undefined
        }
        className={className}
      >
        <span
          data-testid="trigger-element"
          className="inline-block"
          ref={referenceElement}
          onMouseEnter={
            trigger === 'hover' ? (): void => setIsOpen(true) : undefined
          }
          onMouseLeave={
            trigger === 'hover' ? (): void => setIsOpen(false) : undefined
          }
        >
          {children}
        </span>
      </button>
      {isOpen && (
        <button
          onClick={(): void => {
            setIsOpen(false);
          }}
        >
          <span
            className="tooltip-container z-50 text-xs border border-gray-300 
          bg-white rounded px-4 py-2 text-gray-700  min-w-min  max-w-[288px]"
            ref={popperElement}
            data-placement={placement}
            style={styles.popper}
            {...attributes.popper}
          >
            {tooltipContent}
            <span
              className="tooltip-arrow"
              ref={setArrowElement}
              data-placement={placement}
              style={styles.arrow}
              {...attributes.arrow}
            />
          </span>
        </button>
      )}
    </>
  );
};

export default Tooltip;
