import { TIMECARD_STATUS, TimecardStatusCode } from 'features/timecards/types';

const getFormattedCrewFilterStatus = (status: TimecardStatusCode) => {
  switch (status) {
    case TIMECARD_STATUS.NOT_VERIFIED:
      return 'Not Verified';
    case TIMECARD_STATUS.VERIFIED:
      return 'Verified';
    case TIMECARD_STATUS.UNAPPROVED:
      return 'Not Approved';
    case TIMECARD_STATUS.APPROVED:
      return 'Approved';
    case TIMECARD_STATUS.OPEN_ADJUSTMENT:
      return 'Open Adjustment';
    case TIMECARD_STATUS.APPROVED_ADJUSTMENT:
      return 'Approved Adjustment';
    case TIMECARD_STATUS.UNAPPROVED_ADJUSTMENT:
      return 'Unapproved Adjustment';
  }
};
export default getFormattedCrewFilterStatus;
