import { Input, TextArea } from '@uss/react-components';
import { useOrgDetails } from 'features/org/api';
import { OrgFormType, ORG_TYPE } from 'features/org';
import { OrgActionType } from 'features/org/utilities/updateOrgData';
import _ from 'lodash';
import React, { useState } from 'react';
import { ZodErrorType } from 'types/common';

interface Props {
  orgId: string;
  org: OrgFormType;
  dispatch: React.Dispatch<OrgActionType>;
  errors: ZodErrorType;
  type: typeof ORG_TYPE.DIVISION | typeof ORG_TYPE.DEPARTMENT;
  isCommentDisabled: boolean;
}

export const OrgForm = ({
  orgId,
  org,
  dispatch,
  errors,
  type,
  isCommentDisabled,
}: Props) => {
  const [note, setNote] = useState('');
  //get org details
  const { data } = useOrgDetails(orgId);
  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    dispatch({ type: 'name', payload: value });
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = e.target.value;
    dispatch({ type: 'description', payload: value });
  };

  const handleChangeComment = (text: string) => {
    setNote(text);
    dispatch({ type: 'comments', payload: text });
  };

  const validateField = (name: string): boolean => {
    return (
      !_.isEmpty(errors) &&
      typeof errors[name] === 'string' &&
      errors[name].length > 0
    );
  };

  const commentsPlaceholder =
    orgId === '0' ? 'Describe' : `Describe reason to modify this ${type}`;

  const disableDescription = () => {
    const isFormNew = orgId === '0';
    const isOrgActive = data?.status.code === 'active';

    /**description field should be disabled for archived division/department
    description field should be enabled for add new division/department pages
     and active division/department**/

    if (isFormNew) {
      return false;
    } else if (!isOrgActive) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="bg-white sm:bg-white rounded-md p-4">
      <div className="w-full lg:w-4/5 lg:m-auto">
        <div className="lg:grid lg:grid-cols-2 gap-x-10 gap-y-6 w-full">
          <div className="mb-4 lg:mb-0" data-testid="validation-code">
            <Input
              id="form__input__code"
              label="Code"
              name="code"
              type="text"
              placeholder="Enter"
              value={org.name}
              onChange={handleCodeChange}
              error={validateField('name')}
              errorMessage={errors.name}
              disabled={orgId === '0' ? false : true}
              maxLength={2}
            />
          </div>
          <div className="mb-4 lg:mb-0" data-testid="validation-description">
            <Input
              id="form__input__description"
              label="Description"
              type="text"
              name="description"
              value={org.description ? org.description : ''}
              placeholder="Enter"
              error={validateField('description')}
              errorMessage={errors.description}
              onChange={handleDescriptionChange}
              maxLength={500}
              disabled={disableDescription()}
            />
          </div>
        </div>
        {orgId === '0' ? (
          <div className="col-span-2 mb-4 lg:mb-0">
            <TextArea
              maxLength={500}
              label="Comment"
              id="form-comment"
              name="comment"
              placeholder={commentsPlaceholder}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                handleChangeComment(e.target.value);
              }}
              value={org.comments ? org.comments : ''}
              error={!isCommentDisabled && validateField('comments')}
              errorMessage={errors.comments}
              disabled={isCommentDisabled}
            />
            <div className="col-span-2 text-xs mb-2 text-gray-600 text-right">
              {500 - note.length} Characters left
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
