import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { ModelDetailParams, ModelDetailsResponse } from '../types';

interface ModelDetailState {
  params: ModelDetailParams;
  setPage: (value: number) => void;
  setPageSize: (value: number) => void;
  modelDetail: ModelDetailsResponse | undefined;
  updateModelDetail: (value: ModelDetailsResponse) => void;
}

export const useModelDetailStore = create<ModelDetailState>()(
  devtools(
    immer((set) => ({
      params: {
        page: 1,
        pageSize: 9,
      },
      modelDetail: undefined,
      setPage: (value) =>
        set(
          (state) => {
            state.params.page = value;
          },
          false,
          'set-detail-page'
        ),
      setPageSize: (value) =>
        set(
          (state) => {
            state.params.pageSize = value;
            state.params.page = 1;
          },
          false,
          'set-detail-pageSize'
        ),
      updateModelDetail: (value) =>
        set(
          (state) => {
            state.modelDetail = value;
          },
          false,
          'update-model-detail'
        ),
    }))
  )
);
