import { z } from 'zod';

export const getValidationErrors = (data: unknown, zodSchema: unknown) => {
  if (zodSchema instanceof z.ZodObject || zodSchema instanceof z.ZodEffects) {
    try {
      zodSchema.parse(data);
    } catch (error: unknown) {
      if (error instanceof z.ZodError) {
        if (
          error.errors.length > 0 &&
          error.errors[0].path.length === 0 &&
          error.errors[0].message
        ) {
          const err = error.errors[0].message;
          return err;
        } else {
          let err = {};
          error.errors.map((acc: z.ZodIssue) => {
            const nameField = acc.path[0];
            err = { ...err, [nameField]: acc.message };
            return true;
          });
          return err;
        }
      }
    }
  }

  return {};
};
