import Spinner from 'components/Spinner/Spinner';
/**
 * @param {string} title This prop can be used to provide custom title for the spinner section
 * @param {string} description This prop can be used to provide custom description for the spinner section
 * @example <SpinnerSection 
                title='Custom Title' 
                description='Custom Description'                
            </SpinnerSection> 
 * 
 */
export interface SpinnerSectionProps {
  title?: string;
  description?: string;
}
const SpinnerSection = ({ title, description }: SpinnerSectionProps) => {
  return (
    <div className="flex flex-col items-center text-center justify-center w-full h-full">
      <Spinner />
      {title && (
        <span className="text-lg text-gray-700 font-semibold mt-11">
          {title}
        </span>
      )}
      {description && (
        <span className="text-sm text-red-600">{description}</span>
      )}
    </div>
  );
};
export default SpinnerSection;
