import { Select } from '@uss/react-components';
import { getFormattedPayPeriod } from 'features/crew-maintenance/utilities/shiftAssignmentCommon';
import { PayPeriod } from 'features/pay-periods';

interface Props {
  selectedValue: PayPeriod | null;
  onSelect: (value: PayPeriod) => void;
  error?: boolean | undefined;
  disabled: boolean;
  payPeriods: PayPeriod[] | undefined;
}

const PayPeriodStartDateSelect = ({
  selectedValue,
  onSelect,
  error,
  disabled,
  payPeriods,
}: Props) => {
  return (
    <Select selected={selectedValue} onSelect={onSelect} disabled={disabled}>
      <Select.Button
        label={
          selectedValue
            ? `${getFormattedPayPeriod(selectedValue).displayDate}`
            : 'Select'
        }
        error={error}
      />
      <Select.Options error={error}>
        {/* <Select.Option value="">Select</Select.Option> */}
        {payPeriods?.map((item, itemIdx) => (
          <Select.Option key={itemIdx} value={item}>
            <span>{`${getFormattedPayPeriod(item).displayDate}`}</span>
          </Select.Option>
        ))}
      </Select.Options>
    </Select>
  );
};

export default PayPeriodStartDateSelect;
