import {
  Button,
  Radio,
  RadioGroup,
  Select,
  SelectOptions,
} from '@uss/react-core';
import { notify } from 'components/Notification/useNotification';
import { useEditModel } from 'features/model-listing/api/mutations';
import { useAddWeekStore } from 'features/model-listing/state/add-week-store';
import { useModelDetailStore } from 'features/model-listing/state/model-detail-store';
import {
  EditModelReqBody,
  ModelDetailsResponse,
  ModelWeeks,
} from 'features/model-listing/types';
import { getDaysFormat } from 'features/model-listing/utility/getDaysFormat';
import { getEmptyWeekObject } from 'features/model-listing/utility/getEmptyWeekObject';
import { getWeekFormat } from 'features/model-listing/utility/getWeekFormat';
import { Key, useEffect, useState } from 'react';
import { queryClient } from 'services/api/react-query';
import { AddWeekCard } from './AddWeekCard';
import { useEditModelStore } from 'features/model-listing/state/edit-model-store';
interface AddWeeksProps {
  data: ModelDetailsResponse | undefined;
  setOpenDrawer: (val: boolean) => void;
  openDrawer: boolean;
}
export const AddWeeks = ({ data, setOpenDrawer }: AddWeeksProps) => {
  const store = useAddWeekStore();
  //edit-model store
  const editModelStore = useEditModelStore();

  const [position, setPosition] = useState<string>();
  const [selectedWeek, setSelecetedWeek] = useState<Key>();
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
  const { modelDetail } = useModelDetailStore();

  const weekData: ModelWeeks = getEmptyWeekObject(
    modelDetail?.totalCrewRotations ?? 4
  );
  const { mutateAsync: editModelMutateAsync } = useEditModel(data?.id ?? '');
  useEffect(() => {
    store.UpdateAddWeek(weekData);
    setPosition('Before');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!(position && selectedWeek)) {
      setIsAddButtonDisabled(true);
    } else setIsAddButtonDisabled(false);
  }, [position, selectedWeek]);

  const handleChange = async () => {
    setIsAddButtonDisabled(true);
    const allWeeks = modelDetail?.weeks;
    const num =
      position === 'Before' ? Number(selectedWeek) - 1 : Number(selectedWeek);
    const updateWeeks = allWeeks && [
      ...allWeeks.slice(0, num),
      store.addWeek,
      ...allWeeks.slice(num, modelDetail.totalWeeks),
    ];
    let counter = 0;
    const allUpdatedWeeks = updateWeeks?.map((week) => {
      counter++;
      return {
        weekNo: counter,
        crewRotations: week?.crewRotations ?? [],
      };
    });
    const payload: EditModelReqBody = {
      modelDescription: data?.modelDescription ?? '',
      action: 'save',
      weeks: allUpdatedWeeks,
    };
    await editModelMutateAsync(payload, {
      onSuccess: async () => {
        setOpenDrawer(false);
        notify.success({
          message: `You have successfully added a new week.`,
        });
        await queryClient.resetQueries({
          queryKey: ['model-details'],
          type: 'active',
        });
      },
    });
  };
  return (
    <>
      <div className="flex flex-col flex-grow">
        <div className=" overflow-y-auto ">
          <div className=" text-xs mb-3">
            Add Weeks
            <RadioGroup
              value={position}
              orientation="horizontal"
              onChange={setPosition}
            >
              <Radio value={'Before'}> Before</Radio>
              <Radio value={'After'}> After</Radio>
            </RadioGroup>
          </div>
          {data && (
            <Select
              items={editModelStore.weeks ?? []}
              placeholder="Enter week number"
              isDisabled={false}
              className="w-full"
              selectedKey={selectedWeek}
              onSelectionChange={setSelecetedWeek}
            >
              {(item) => (
                <SelectOptions id={item.weekNo}>{item.weekNo}</SelectOptions>
              )}
            </Select>
          )}
          <div className=" text-xs mt-3 mb-2">Schedule</div>

          {modelDetail && (
            <div className="py-3 border-gray-300 border p-2 rounded-lg">
              <div className="w-full grid gap-4 grid-cols-8 items-center">
                <div className=" text-base text-center"></div>
                {getDaysFormat(modelDetail.weekBeginIndex).map(
                  (day, _index) => (
                    <div
                      key={_index}
                      className="text-center font-semibold text-base justify-center"
                    >
                      {day?.value.charAt(0)}
                    </div>
                  )
                )}

                {weekData.crewRotations.map((crewRotation, index) => (
                  <>
                    <div
                      key={index}
                      className="font-semibold text-base text-center"
                    >
                      {crewRotation.crewRotation}
                    </div>

                    {getWeekFormat({
                      data: [...crewRotation.schedTurns],
                      weekBeginIndex: modelDetail.weekBeginIndex,
                    }).map((schedTurn, _index) => (
                      <div key={_index} className="  text-center text-sm ">
                        <AddWeekCard
                          schedTurn={schedTurn}
                          weekNo={weekData.weekNo}
                          crewRotation={crewRotation.crewRotation}
                        />
                      </div>
                    ))}
                  </>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex md:flex-row flex-col items-end justify-end mt-6 gap-2">
          <Button
            variant="outline"
            color={'primary'}
            full={true}
            type="button"
            onPress={() => setOpenDrawer(false)}
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            color={'primary'}
            full={true}
            type={'submit'}
            onPress={handleChange}
            isDisabled={isAddButtonDisabled}
          >
            Add
          </Button>
        </div>
      </div>
    </>
  );
};
