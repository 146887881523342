import { axios } from 'services/api/axios';
import { useQuery } from '@tanstack/react-query';
import {
  ProbEmpBargUnitsOptions,
  ProbEmployeesBargUnits,
} from '../types/bargainingUnitTotals';

export const useProbEmpBargainingUnits = ({
  params,
}: {
  params?: ProbEmpBargUnitsOptions;
}) => {
  return useQuery({
    queryFn: () => getProbEmpBargainingUnits(params),
    queryKey: ['probationary-employees-bargaining-units', { ...params }],
  });
};

const getProbEmpBargainingUnits = (
  params?: ProbEmpBargUnitsOptions
): Promise<ProbEmployeesBargUnits> => {
  return axios.get('/v1/prob-emp/barg-units', {
    params,
  });
};
