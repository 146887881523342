import { Button } from '@uss/react-components';
import Card from 'components/Card/Card';
import LabeledText from 'components/Typography/LabeledText';
import useWindowSize from 'hooks/useWindowSize';
import { BsTrash } from 'react-icons/bs';
import { useRemoveMileage } from '../api/mutation';
import { format, parseISO } from 'date-fns';
import useModal from 'components/Modals/use-modal';
import { MileageDetails, MileageStatusTypeVal } from '../types/mileageEntry';
import { notify } from 'components/Notification/useNotification';
import { queryClient } from 'services/api/react-query';

interface MileageEntryCardProps {
  mileageRecord: MileageDetails;
}

export const MileageEntryCard = ({ mileageRecord }: MileageEntryCardProps) => {
  //Getting Roles
  const { width } = useWindowSize();
  //Remove Mileage Entry
  const { mutateAsync: removeMileage } = useRemoveMileage();

  const modal = useModal();
  const openModal = ({
    title,
    message,
    type,
  }: {
    title: string;
    message: string;
    type: 'danger';
  }) => {
    modal.openModal({
      title: title,
      type: type,
      children: <span>{message}</span>,
      labels: {
        confirm: 'Remove',
        cancel: 'Cancel',
      },
      confirmVariant: 'danger',
      onCancel: () => modal.closeModal(),
      onConfirm: () => handleMileageRemove(),
    });
  };
  const handleRemove = () => {
    openModal({
      title: 'Remove Mileage Entry?',
      message: `Are you sure you want to delete Mileage Entry for
        ${format(parseISO(mileageRecord.travelDate), 'MMMM dd, yyyy')}?`,
      type: 'danger',
    });
  };

  const handleMileageRemove = async () => {
    await removeMileage(
      { mileageDetailsId: mileageRecord.mileageDetailsId },
      {
        onSuccess: async () => {
          modal.closeModal();
          notify.success({
            message: `The mileage entry has been removed successfully.`,
          });
          await queryClient.resetQueries({
            queryKey: ['mileage-details'],
            type: 'active',
          });
        },
      }
    );
  };

  return (
    <Card data-testid="mileageCard">
      <Card.Body>
        <div className="sm:flex items-center w-full h-full ">
          <div className="flex flex-wrap justify-between lg:flex-nowrap w-full lg:w-full lg:pl-1 lg:pr-5">
            <LabeledText
              label="Payroll Location"
              lineClamp={1}
              className="w-1/2 lg:w-7/12 mb-2 last-of-type:mb-0 lg:mb-0"
            >
              {mileageRecord.payrollLocation}
            </LabeledText>
            <LabeledText
              label="Miles"
              lineClamp={1}
              className="w-1/2 lg:w-3/12 mb-2 last-of-type:mb-0 lg:mb-0"
            >
              {mileageRecord.miles}
            </LabeledText>
            <LabeledText
              label="Travelled On"
              lineClamp={1}
              className="w-1/2 lg:w-5/12 mb-2 last-of-type:mb-0 lg:mb-0"
            >
              {format(new Date(mileageRecord.travelDate), 'MMM dd, yyyy')}
            </LabeledText>
            <LabeledText
              label="Requested On"
              lineClamp={1}
              className="w-1/2 lg:w-5/12 mb-2 last-of-type:mb-0 lg:mb-0"
            >
              {format(new Date(mileageRecord.requestedDate), 'MMM dd, yyyy')}
            </LabeledText>
            <LabeledText
              label="Status"
              lineClamp={1}
              className="w-full lg:w-3/12 mb-4 last-of-type:mb-0 lg:mb-0"
            >
              {mileageRecord.status[0].toLocaleUpperCase() +
                mileageRecord.status.slice(1)}
            </LabeledText>
            <div className="w-full sm:w-auto flex justify-center items-center mt-4 sm:mt-0 border-t-2 border-gray-200 sm:border-0">
              <Button
                date-testid="removeBtn"
                name="Remove"
                variant="text"
                className="text-sm text-red-600 cursor-pointer flex sm:mt-0 item-center justify-center mt-4"
                onClick={handleRemove}
                disabled={mileageRecord.status === MileageStatusTypeVal.PAID}
              >
                <BsTrash id="delete" className="text-lg" />
                {width && width < 1024 && (
                  <span className="text-base ml-2"> Remove</span>
                )}
              </Button>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
