import { ORG_TYPE } from 'features/org/constants';
import { Org } from 'features/org/types';
import { sortBy } from 'lodash';

type CondensedOrg = Pick<
  Org,
  | 'id'
  | 'parentOrgId'
  | 'type'
  | 'plant'
  | 'location'
  | 'division'
  | 'department'
  | 'code'
  | 'crew'
>;

interface Props<T> {
  orgs: T[];
  selectedPlant?: T | 'all' | null;
  selectedPayrollLocation?: T | 'all' | null;
  selectedDivision?: T | 'all' | null;
  selectedDepartment?: T | 'all' | null;
  crewSort?: 'code' | 'crew';
}

function useOrgFilters<T extends CondensedOrg>({
  orgs,
  selectedPlant,
  selectedPayrollLocation,
  selectedDivision,
  selectedDepartment,
  crewSort = 'crew',
}: Props<T>) {
  function getPayrollLocations() {
    const unfilteredPayrollLocations = orgs.filter(
      (o) => o.type === ORG_TYPE.LOCATION
    );

    return unfilteredPayrollLocations.filter((o) => {
      if (!selectedPlant || selectedPlant === 'all') return true;
      return o.plant === selectedPlant.plant;
    });
  }
  const payrollLocations = getPayrollLocations();

  function getDivisions() {
    const unfilteredDivisions = orgs.filter(
      (o) => o.type === ORG_TYPE.DIVISION
    );

    return unfilteredDivisions.filter((o) => {
      if (selectedPayrollLocation && selectedPayrollLocation !== 'all') {
        return o.location === selectedPayrollLocation.location;
      } else if (selectedPlant && selectedPlant !== 'all') {
        return o.plant === selectedPlant.plant;
      } else {
        return true;
      }
    });
  }

  const divisions = sortBy(getDivisions(), ['code']);

  function getDepartments() {
    const unfilteredDepartments = orgs.filter(
      (o) => o.type === ORG_TYPE.DEPARTMENT
    );

    return unfilteredDepartments.filter((o) => {
      if (selectedDivision && selectedDivision !== 'all') {
        return o.division === selectedDivision.division;
      } else if (selectedPayrollLocation && selectedPayrollLocation !== 'all') {
        return o.location === selectedPayrollLocation.location;
      } else if (selectedPlant && selectedPlant !== 'all') {
        return o.plant === selectedPlant.plant;
      } else {
        return true;
      }
    });
  }

  const departments = sortBy(getDepartments(), ['code']);

  function getCrews() {
    const unfilteredCrews = orgs.filter((o) => o.type === ORG_TYPE.CREW);

    return unfilteredCrews.filter((o) => {
      if (selectedDepartment !== 'all' && selectedDepartment) {
        return o.parentOrgId === selectedDepartment.id;
      } else if (selectedDivision && selectedDivision !== 'all') {
        return o.division === selectedDivision.division;
      } else if (selectedPayrollLocation && selectedPayrollLocation !== 'all') {
        return o.location === selectedPayrollLocation.location;
      } else if (selectedPlant && selectedPlant !== 'all') {
        return o.plant === selectedPlant.plant;
      } else {
        return true;
      }
    });
  }
  const crews = sortBy(getCrews(), [crewSort]);
  // const crews = getCrews();
  return {
    payrollLocations,
    divisions,
    departments,
    crews,
  };
}

export default useOrgFilters;
