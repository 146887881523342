import { Person } from 'features/people';
import EmployeeProfile from 'features/people/components/EmployeeProfile';
import Page from 'components/Layouts/Page';
import { useParams } from 'react-router-dom';

import UnauthorizedSvg from 'components/svgs/UnauthorizedSvg';

export const UnauthorizedMsg = ({ description }: { description: string }) => {
  return (
    <div className="flex flex-col text-md items-center bg-white rounded-md py-10 sm:py-10 px-8">
      <div className="px-6">
        <UnauthorizedSvg className="sm:w-96 sm:h-40" />
      </div>
      <p
        data-testid="unauthorized-description"
        className="text-sm text-gray-700 text-center pt-6"
      >
        {description}
      </p>
    </div>
  );
};

const EmployeeSection = () => {
  const { id = '' } = useParams<'id'>();
  return (
    <div className="my-2">
      {id.length === 0 ? <EmployeeProfile /> : <EmployeeProfile id={id} />}
    </div>
  );
};

export const UserView = ({
  user,
  children,
}: {
  user?: Pick<Person, 'paidSickTimeEligibility' | 'policyGroup' | 'ptoClass'>;
  children: React.ReactNode;
}) => {
  if (user === undefined) {
    return null;
  }
  if (
    user.paidSickTimeEligibility === 'n' &&
    user.policyGroup === 'part_time'
  ) {
    return (
      <Page.Section>
        <EmployeeSection />
        <UnauthorizedMsg description="Employee not eligible for Paid Time Off" />
      </Page.Section>
    );
  }
  if (user.ptoClass === 'integrated-exempt') {
    return (
      <Page.Section>
        <EmployeeSection />
        <UnauthorizedMsg description="Employee’s Paid Time Off is managed in Oracle Absence Tracker" />
      </Page.Section>
    );
  }
  return <>{children}</>;
};
