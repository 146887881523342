import { Alert, Button, IconButton, Modal, Sidepane } from '@uss/react-core';
import { BsX } from 'react-icons/bs';
import { useState } from 'react';
import ApplyModelForm from '../Forms/ApplyModelForm';
import { NavLink, useParams } from 'react-router-dom';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import { useLoggedInUser } from 'features/people/api';
import getShiftAssignmentNumber from 'features/model-listing/utility/getShiftAssignmentNumber';
import { ApplyModelReqBody } from 'features/model-listing/types';
import { useApplyModel } from 'features/model-listing/api/mutations';
import { notify } from 'components/Notification/useNotification';
import { formatCrewKeyFromCode } from 'utils/formatCrewKey';
import { addDays, format, parseISO } from 'date-fns';
import { queryClient } from 'services/api/react-query';
import { useEmployeeOrgs } from 'features/org/api';
import { ORG_TYPE } from 'features/org';

interface ApplyModelDrawerType {
  applyFeature: 'timecard' | 'crew-listing';
  openDrawer: boolean;
  setOpenDrawer: (val: boolean) => void;
  fnClearCheckbox?: () => void;
}

export default function ApplyModelDrawer({
  applyFeature,
  openDrawer,
  setOpenDrawer,
  fnClearCheckbox,
}: ApplyModelDrawerType) {
  const { data: user } = useLoggedInUser();
  const { ussId } = useParams();
  //getting logged-in user id
  // const ussId = user ? user.ussId.toString() : '';
  //drawer state
  const [isOpen, setIsOpen] = useState(false);
  //getting feature
  const state = useNonUnionStore();
  const feature = state.feature;
  const isScheduleCrewListing =
    feature === 'scheduling' && applyFeature === 'crew-listing';
  const isMySchedule = feature === 'my-schedule';
  //payload state
  const [payload, setPayload] = useState<ApplyModelReqBody | null>(null);
  //apply-model API
  const { mutateAsync } = useApplyModel();
  //location selection
  const { data: locationList } = useEmployeeOrgs('me', {
    type: ORG_TYPE.LOCATION,
    sortBy: 'location',
    parentOrgId: !state.selectedPlant?.id ? undefined : state.selectedPlant.id,
  });

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const getModelText = () => {
    if (isScheduleCrewListing) {
      return 'Choose the model you want to apply to all the employees selected';
    } else return 'Select the model you want to apply';
  };

  function handleApplyModel(data: ApplyModelReqBody) {
    setIsOpen(true);
    setPayload(data);
  }

  const fnCallApplyModel = async () => {
    payload &&
      (await mutateAsync(payload, {
        onSuccess: async () => {
          notify.info({
            message:
              'You will be notified electronically when ‘Apply Model’ has completed.This can take several minutes.Other actions can be taken, but not for the crew or week(s) involved in this process.',
          });
          setIsOpen(false);
          handleClose();
          if (isScheduleCrewListing && fnClearCheckbox) {
            //refreshing timrcard screen
            await queryClient.resetQueries({
              queryKey: ['crew-timecards'],
              type: 'active',
            });
            //unchecking all checkboxes
            fnClearCheckbox();
          }
        },
      }));
  };

  //get shiftAssignment Value
  let shiftAssignmentVal = 0;
  shiftAssignmentVal = getShiftAssignmentNumber(user?.shiftAssignment ?? '');
  //date
  let startDate = '';
  let endDate = '';
  if (state.params.week) {
    startDate = format(parseISO(state.params.week), 'PP');
    endDate = format(addDays(new Date(state.params.week), 6), 'PP');
  }

  //generating ussIds list
  let ussIds = ussId ?? '';
  if (isScheduleCrewListing && state.selectedEmployees.length > 0) {
    ussIds = '';
    state.selectedEmployees.forEach((item) => {
      ussIds = ussIds + `${item.ussId},`;
    });
    ussIds = ussIds.slice(0, -1);
  }

  //getting payrollLocation for current screen
  let payrollLocationId = '';
  if (locationList) {
    let filteredArr = [];
    //for my-schedule; get payrollLocation from loggedIn user
    if (applyFeature === 'timecard') {
      if (isMySchedule) {
        filteredArr = locationList.items.filter(
          (item) => item.code === user?.payrollLocation
        );
        if (filteredArr.length > 0) {
          payrollLocationId = filteredArr[0].id;
        }
      } else {
        payrollLocationId = locationList.items[0].id;
      }
      //for crew-listing; get payrollLocation based on crew-code
    } else if (isScheduleCrewListing) {
      filteredArr = locationList.items.filter(
        (item) => item.code === state.crew?.location
      );
      if (filteredArr.length > 0) {
        payrollLocationId = filteredArr[0].id;
      }
    }
  }

  return (
    <>
      <Sidepane
        ariaLabel="filter"
        placement={'right'}
        isOpen={openDrawer}
        onOpenChange={handleClose}
        className="w-full sm:w-2/5 lg:w-1/4"
      >
        <div className="w-full flex gap-10 items-center mb-2">
          <h3 className="text-lg flex-grow">Apply Model</h3>
          <IconButton onPress={handleClose}>
            <BsX className="text-gray-600" />
          </IconButton>
        </div>
        {isScheduleCrewListing && (
          <div className="p-2">
            <Alert
              variant="info"
              message="Note: Any existing schedule(s) will be overwritten."
            />
          </div>
        )}
        <p className="text-sm mb-1 text-wrap">{getModelText()}</p>
        <NavLink
          className={
            'text-primary-400 font-semibold cursor-pointer text-sm py-3 pl-2'
          }
          to="/non-union-scheduling/modeling"
        >
          View Model
        </NavLink>
        {isScheduleCrewListing && (
          <p className="text-sm mt-2">
            Crew: {formatCrewKeyFromCode(state.crew ? state.crew.code : '')}{' '}
          </p>
        )}
        <ApplyModelForm
          defaultValues={{
            ussIds: ussIds,
            crewId: state.crew ? state.crew.id : '',
            model: '',
            crewRotation: '',
            noOfHours: shiftAssignmentVal,
            startWeekNo: 0,
            endWeekNo: 0,
            week: state.params.week ?? '',
            hoursType: isScheduleCrewListing ? 'shiftAssignment' : 'custom',
          }}
          handleClose={handleClose}
          isSchedulingDetails={isScheduleCrewListing}
          handleApplyModel={handleApplyModel}
          payrollLocation={payrollLocationId}
        />
      </Sidepane>
      {isOpen && (
        <Modal ariaLabel="" size="default" isOpen={isOpen}>
          <Modal.Header>Apply Model</Modal.Header>
          <Modal.Content>
            {isScheduleCrewListing && (
              <p className="text-sm text-gray">
                {`${state.selectedEmployees.length} Employees are selected.`}
              </p>
            )}
            <p className="text-sm text-gray pb-3">
              {`Applying the model will override existing schedule(s) for weeks ${startDate} - ${endDate}.`}
            </p>
            {isScheduleCrewListing && (
              <div className="pl-0 p-2 mb-2">
                <Alert
                  variant="info"
                  message="Note: Any existing schedule(s) will be overwritten. Any requested
              or approved PTO in date range will be overwritten and will need to be requested again."
                />
              </div>
            )}
            <p className="text-sm text-gray pb-8">
              Are you sure you want to apply this model?
            </p>
          </Modal.Content>
          <Modal.Footer>
            <div className="sm:flex sm:justify-between sm:gap-2">
              <Button
                variant="outline"
                color={'primary'}
                full={false}
                onPress={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="filled"
                color={'primary'}
                full={false}
                onPress={fnCallApplyModel}
              >
                Apply
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
