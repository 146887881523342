import CardList from 'components/Card/CardList';
import Page from 'components/Layouts/Page';
import CardsLoader from 'components/Loaders/CardsLoader';
import Pagination from 'components/Pagination/Pagination';
import Error from 'components/Error/Error';
import {
  OrgCard,
  OrgInfoCard,
  Org,
  useDepartmentPageParams,
  useDepartmentPageParamsDispatch,
  ORG_TYPE,
} from 'features/org';
import { useOrgChildren, useOrgDetails } from 'features/org/api';
import { OrgTools, SortOptionsType } from 'features/org/components/OrgTools';
import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { SortDirection } from 'types/common';

const Departments = () => {
  //get id from params
  const { id = '' } = useParams<'id'>();
  const params = useDepartmentPageParams();
  const dispatch = useDepartmentPageParamsDispatch();
  const { data: departments, status: departmentStatus } = useOrgChildren('me', {
    type: ORG_TYPE.DEPARTMENT,
    parentOrgId: id ? id : null,
    ...params,
  });
  //get selected department details
  const { data, status } = useOrgDetails(id);
  const noResults = {
    title: 'No Records Found',
    description:
      'No department records found, Please modify the search criteria to view the records',
  };

  const sortOptions: SortOptionsType[] = [
    {
      label: 'Recently Added',
      name: 'recently-added',
      direction: SortDirection.DESC,
    },
    {
      label: 'Code',
      name: 'code',
      direction: SortDirection.ASC,
    },
    {
      label: 'Code',
      name: 'code',
      direction: SortDirection.DESC,
    },
    {
      label: 'Description',
      name: 'description',
      direction: SortDirection.ASC,
    },
    {
      label: 'Description',
      name: 'description',
      direction: SortDirection.DESC,
    },
  ];

  return (
    <Page
      breadcrumbs={[
        <NavLink key="bc-1" to="/admin">
          Admin
        </NavLink>,
        <NavLink key="bc-2" to="/admin/org-structure/divisions">
          Org Structure Divisions
        </NavLink>,
      ]}
      heading="Org Maintenance - Departments"
    >
      <Page.Section bg="gray">
        {status === 'success' && (
          <div className="mb-6">
            <OrgInfoCard data={data} type={ORG_TYPE.DEPARTMENT} />
          </div>
        )}
        {data && status === 'success' && data.parentOrgId && (
          <OrgTools
            params={params}
            dispatch={dispatch}
            type={ORG_TYPE.DEPARTMENT}
            sortOptions={sortOptions}
            parentOrgId={id}
            code={data.status.code}
          />
        )}
      </Page.Section>
      <Page.Section>
        {departmentStatus === 'pending' && <CardsLoader count={9} />}
        {departmentStatus === 'success' && (
          <CardList<Org>
            items={departments.items}
            renderItem={(org) => (
              <OrgCard type={ORG_TYPE.DEPARTMENT} org={org} />
            )}
            noResults={noResults}
            data-testid="department-list"
          />
        )}
        {departmentStatus === 'error' && <Error />}
      </Page.Section>
      <Page.Section bg="gray">
        {!!departments?.totalCount && (
          <Pagination
            pageOptions={[9, 18, 27, 36, 45, 54]}
            totalCount={departments.totalCount}
            page={departments.page}
            pageSize={departments.pageSize}
            onPageChange={(s) =>
              dispatch({ type: 'page', payload: s.selected + 1 })
            }
            onPageSizeChange={(ps) =>
              dispatch({ type: 'pageSize', payload: ps })
            }
          />
        )}
      </Page.Section>
    </Page>
  );
};

export default Departments;
