import { Accordion, Avatar } from '@uss/react-components';
import SeparatedLabels from 'components/SeparatedLabels/SeparatedLabels';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';
import { PerformanceReviewDetails } from 'features/probationary-tracking/types';
import getFormattedPersonStatus from 'features/people/utilities/getFormattedPersonStatus';
import { avatarColor, getInitials } from 'utils';
import useWindowSize from 'hooks/useWindowSize';
import Card from 'components/Card/Card';
import { format, parseISO } from 'date-fns';
import getFormattedEmployeeName from 'features/probationary-tracking/utilities/getFormattedEmployeeName';

type DetailsHeaderType = Pick<
  PerformanceReviewDetails,
  'ussId' | 'displayName' | 'firstName' | 'lastName' | 'status'
>;
type DetailsBodyType = Pick<
  PerformanceReviewDetails,
  | 'bargUnit'
  | 'department'
  | 'startDate'
  | 'estEndDate'
  | 'reviewerDetails'
  | 'supervisorDetails'
>;
type DetailsFooterType = Pick<
  PerformanceReviewDetails,
  'job' | 'workDetails' | 'lastPayDate' | 'isBreached'
>;

interface Props {
  data: PerformanceReviewDetails;
}

const EmployeeDetailsSummary = ({ data }: Props) => {
  const { width } = useWindowSize();
  return (
    <>
      {!!width && width < 768 ? (
        <Accordion
          variant="default"
          className="shadow-md bg-white rounded-lg border-2 border-primary-400"
        >
          <Accordion.Button variant="default">
            <DetailsHeader {...data} />
          </Accordion.Button>
          <Accordion.Panel variant="default">
            <div className="flex flex-row flex-wrap w-full pt-4 items-center border-t border-t-gray-200">
              <DetailsBody {...data} />
              <DetailsFooter {...data} />
            </div>
          </Accordion.Panel>
        </Accordion>
      ) : (
        <Card bgColor={'bg-white'} border={'border border-blue-600'}>
          <Card.Body>
            <div className="flex items-center w-full h-full ">
              <DetailsHeader {...data} />
              <DetailsBody {...data} />
            </div>
            <DetailsFooter {...data} />
          </Card.Body>
        </Card>
      )}
    </>
  );
};
const DetailsHeader = ({
  ussId,
  displayName,
  firstName,
  lastName,
  status,
}: DetailsHeaderType) => {
  return (
    <div
      className="flex items-center w-full h-full md:w-[30%] relative md:pr-2 md:after:w-0.5 md:after:absolute md:after:right-0 md:after:h-full md:after:bg-gray-200"
      data-testid="employee-details-card-header"
    >
      <div className="mr-3">
        <Avatar color={avatarColor(ussId)} size="medium">
          {getInitials(firstName, lastName)}
        </Avatar>
      </div>
      <div className="w-full">
        <Title as="h2" data-testid="employee-displayname">
          {displayName ?? '-'}
        </Title>
        <SeparatedLabels
          size="md"
          items={[
            {
              label: 'USS ID',
              value: ussId,
            },

            {
              label: 'Employee Status',
              value: getFormattedPersonStatus(status),
            },
          ]}
        />
      </div>
    </div>
  );
};
const DetailsBody = ({
  bargUnit,
  department,
  startDate,
  estEndDate,
  reviewerDetails,
  supervisorDetails,
}: DetailsBodyType) => {
  return (
    <div
      className="flex flex-wrap justify-between md:flex-nowrap w-full md:w-[70%] md:pl-2"
      data-testid="employee-details-card-body"
    >
      <LabeledText
        label={'Bargaining Unit'}
        lineClamp={1}
        className="w-1/2 md:w-1/6 mb-3 md:mb-0  md:pl-2  "
      >
        {bargUnit ?? '-'}
      </LabeledText>
      <LabeledText
        label={'Department'}
        lineClamp={1}
        className="w-1/2 md:w-1/6 mb-3 md:mb-0  md:pl-2  "
      >
        {department ?? '-'}
      </LabeledText>
      <LabeledText
        label={'Start Date'}
        lineClamp={1}
        className="w-1/2 md:w-1/6 mb-3 md:mb-0  md:pl-2  "
      >
        {format(parseISO(startDate), 'MMM d, yyyy')}
      </LabeledText>
      <LabeledText
        label={'Est. End Date'}
        lineClamp={1}
        className="w-1/2 md:w-1/6 mb-3 md:mb-0  md:pl-2  "
      >
        {estEndDate ? format(parseISO(estEndDate), 'MMM d, yyyy') : '-'}
      </LabeledText>
      <LabeledText
        label={'Reviewer'}
        lineClamp={1}
        className="w-1/2 md:w-1/6 mb-3 md:mb-0  md:pl-2  "
      >
        {getFormattedEmployeeName(reviewerDetails)}
      </LabeledText>
      <LabeledText
        label={'Supervisor'}
        lineClamp={1}
        className="w-1/2 md:w-1/6 mb-3 md:mb-0  md:pl-2  "
      >
        {getFormattedEmployeeName(supervisorDetails)}
      </LabeledText>
    </div>
  );
};
const DetailsFooter = ({
  job,
  workDetails,
  isBreached,
  lastPayDate,
}: DetailsFooterType) => {
  return (
    <div
      className="flex flex-wrap justify-between md:flex-nowrap w-full border-t-2  border-gray-200 md:mt-4 pt-4"
      data-testid="employee-details-card-footer"
    >
      <LabeledText
        label={'Job'}
        lineClamp={1}
        className="w-1/2 md:w-1/7 mb-3 md:mb-0 md:pl-2 "
      >
        {job ?? '-'}
      </LabeledText>
      <LabeledText
        label={'Total hrs worked'}
        lineClamp={1}
        className={'w-1/2 md:w-1/7 mb-3 md:mb-0 md:pl-2 '}
        textColor={isBreached === 'y' ? 'text-orange-600' : 'text-gray-900'}
      >
        {workDetails.totalWorkHours?.toString() ?? '-'}
      </LabeledText>
      <LabeledText
        label={'Appr. hrs worked'}
        lineClamp={1}
        className="w-1/2 md:w-1/7 mb-3 md:mb-0 md:pl-2 "
      >
        {workDetails.approvedWorkedHours?.toString() ?? '-'}
      </LabeledText>
      <LabeledText
        label={'Remaining work hrs'}
        lineClamp={1}
        className="w-1/2 md:w-1/7 mb-3 md:mb-0 md:pl-2 "
      >
        {workDetails.remainingWorkHours?.toString() ?? '-'}
      </LabeledText>
      <LabeledText
        label={'Scheduled hrs to date'}
        lineClamp={1}
        className="w-1/2 md:w-1/7 mb-3 md:mb-0 md:pl-2 "
      >
        {workDetails.schedHoursToDate?.toString() ?? '-'}
      </LabeledText>
      <LabeledText
        label={'Swipe time to date'}
        lineClamp={1}
        className="w-1/2 md:w-1/7 mb-3 md:mb-0 md:pl-2 "
      >
        {workDetails.swipeTimeToDate?.toString() ?? '-'}
      </LabeledText>
      <LabeledText
        label={'Last pay update'}
        lineClamp={1}
        className="w-1/2 md:w-1/7 mb-3 md:mb-0 md:pl-2 "
      >
        {lastPayDate ? format(parseISO(lastPayDate), 'MMM d, yyyy') : '-'}
      </LabeledText>
    </div>
  );
};
export default EmployeeDetailsSummary;
