import Page from 'components/Layouts/Page';

const AdminOverview = () => {
  return (
    <Page heading="Admin">
      <Page.Section padding>
        <div className="bg-white p-4 rounded-md">
          <p>
            This module provides supervisors with the ability to manage their
            crews.
          </p>
          <br />
          <p>
            This module also provides Payroll Administration, as well as Time &
            Attendance Plant Coordinators, with the ability to manage the
            organizational structure (Plant / Payroll Location / Division /
            Department).
          </p>
          <br />
          <p>
            This module also provides Payroll Administration with the ability to
            manage user security.
          </p>
          <br />
          <p>
            This module provides Time & Attendance Plant Coordinators and
            Payroll Administration the ability to request new pay codes.
          </p>
        </div>
      </Page.Section>
    </Page>
  );
};

export default AdminOverview;
