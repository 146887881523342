import { useMutation } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { WMSError } from 'types/common';
import {
  ProbEmployeeReview,
  ReviewPatch,
  ReviewPut,
} from '../types/employeeReviewDetails';
import { queryClient } from 'services/api/react-query';
import useNotification from 'components/Notification/useNotification';
import { useNavigate } from 'react-router-dom';

const updatePerformanceReview = (
  ussId: number,
  id: string,
  body: ReviewPut
): Promise<ProbEmployeeReview> => {
  return axios.put(`/v1/prob-emp/${ussId}/reviews/${id}`, body);
};

export const useUpdatePerformanceReview = (
  ussId: number,
  id: string,
  reviewNumber: number
) => {
  const notify = useNotification();
  const navigate = useNavigate();

  return useMutation<ProbEmployeeReview, WMSError, ReviewPut>({
    mutationFn: (body: ReviewPut) => updatePerformanceReview(ussId, id, body),
    meta: {
      errorMessage: 'Unable to update performance review.',
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ['get-review-details', { ussId, id }],
      });
      navigate(-1);
      notify.success({
        message: `You have successfully completed the performance review #${reviewNumber}`,
      });
    },
  });
};

const patchPerformanceReview = (
  ussId: number,
  id: string,
  body: ReviewPatch
): Promise<{ success: boolean; message: string }> => {
  return axios.patch(`/v1/prob-emp/${ussId}/reviews/${id}`, body);
};

export const usePatchPerformanceReview = (
  ussId: number,
  id: string,
  successMessage: string
) => {
  const notify = useNotification();
  const navigate = useNavigate();

  return useMutation<
    { success: boolean; message: string },
    WMSError,
    ReviewPatch
  >({
    mutationFn: (body: ReviewPatch) => patchPerformanceReview(ussId, id, body),
    meta: {
      errorMessage: 'Unable to update performance review.',
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ['get-review-details', { ussId, id }],
      });
      navigate(-1);
      notify.success({
        message: `${successMessage}`,
      });
    },
  });
};
