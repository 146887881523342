import { Accordion } from '@uss/react-components';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';
import useWindowSize from 'hooks/useWindowSize';
import Card from 'components/Card/Card';
import Loader from 'components/Skeleton/Loader';

const EmployeeDetailsSummaryLoader = () => {
  const { width } = useWindowSize();
  return !!width && width < 768 ? (
    <Accordion
      variant="default"
      className="shadow-md bg-white rounded-lg border border-blue-600"
    >
      <Accordion.Button variant="default">
        <DetailsHeader />
      </Accordion.Button>
      <Accordion.Panel variant="default">
        <div className="flex flex-row flex-wrap w-full pt-4 items-center border-t border-t-gray-200">
          <DetailsBody />
          <DetailsFooter />
        </div>
      </Accordion.Panel>
    </Accordion>
  ) : (
    <Card bgColor={'bg-white'} border={'border border-blue-600'}>
      <Card.Body>
        <div className="flex items-center w-full h-full ">
          <DetailsHeader />
          <DetailsBody />
        </div>
        <DetailsFooter />
      </Card.Body>
    </Card>
  );
};

const DetailsHeader = () => {
  return (
    <div
      className="flex items-center w-full h-full md:w-[30%] relative md:pr-2 md:after:w-0.5 md:after:absolute md:after:right-0 md:after:h-full md:after:bg-gray-200"
      data-testid="employee-details-card-header-loader"
    >
      <div className="mr-3">
        <Loader avatarType />
      </div>
      <div className="w-full">
        <Title as="h2">{<Loader rows={1} />}</Title>
        <Loader rows={1} />
      </div>
    </div>
  );
};
const DetailsBody = () => {
  return (
    <div className="flex flex-wrap justify-between md:flex-nowrap w-full md:w-[70%] md:pl-2">
      <LabeledText
        label={'Bargaining Unit'}
        lineClamp={1}
        className="w-1/2 md:w-1/6 mb-3 md:mb-0  md:pl-2 "
      >
        {<Loader rows={1} />}
      </LabeledText>
      <LabeledText
        label={'Department'}
        lineClamp={1}
        className="w-1/2 md:w-1/6 mb-3 md:mb-0  pl-2 "
      >
        {<Loader rows={1} />}
      </LabeledText>
      <LabeledText
        label={'Start Date'}
        lineClamp={1}
        className="w-1/2 md:w-1/6 mb-3 md:mb-0  md:pl-2 "
      >
        {<Loader rows={1} />}
      </LabeledText>
      <LabeledText
        label={'Est. End Date'}
        lineClamp={1}
        className="w-1/2 md:w-1/6 mb-3 md:mb-0  pl-2 "
      >
        {<Loader rows={1} />}
      </LabeledText>
      <LabeledText
        label={'Reviewer'}
        lineClamp={1}
        className="w-1/2 md:w-1/6 mb-3 md:mb-0  md:pl-2 "
      >
        {<Loader rows={1} />}
      </LabeledText>
      <LabeledText
        label={'Supervisor'}
        lineClamp={1}
        className="w-1/2 md:w-1/6 mb-3 md:mb-0  md:pl-2 "
      >
        {<Loader rows={1} />}
      </LabeledText>
    </div>
  );
};
const DetailsFooter = () => {
  return (
    <div className="flex flex-wrap justify-between md:flex-nowrap w-full border-t-2  border-gray-200 md:mt-4 pt-4">
      <LabeledText
        label={'Job'}
        lineClamp={1}
        className="w-1/2 md:w-1/7 mb-3 md:mb-0 md:pl-2"
      >
        {<Loader rows={1} />}
      </LabeledText>
      <LabeledText
        label={'Total hrs worked'}
        lineClamp={1}
        className="w-1/2 md:w-1/7 mb-3 md:mb-0 md:pl-2"
      >
        {<Loader rows={1} />}
      </LabeledText>
      <LabeledText
        label={'Appr. hrs worked'}
        lineClamp={1}
        className="w-1/2 md:w-1/7 mb-3 md:mb-0 md:pl-2"
      >
        {<Loader rows={1} />}
      </LabeledText>
      <LabeledText
        label={'Remaining work hrs'}
        lineClamp={1}
        className="w-1/2 md:w-1/7 mb-3 md:mb-0 md:pl-2"
      >
        {<Loader rows={1} />}
      </LabeledText>
      <LabeledText
        label={'Scheduled hrs to date'}
        lineClamp={1}
        className="w-1/2 md:w-1/7 mb-3 md:mb-0 md:pl-2"
      >
        {<Loader rows={1} />}
      </LabeledText>
      <LabeledText
        label={'Swipe time to date'}
        lineClamp={1}
        className="w-1/2 md:w-1/7 mb-3 md:mb-0 md:pl-2"
      >
        {<Loader rows={1} />}
      </LabeledText>
      <LabeledText
        label={'Last pay update'}
        lineClamp={1}
        className="w-1/2 md:w-1/7 mb-3 md:mb-0 md:pl-2"
      >
        {<Loader rows={1} />}
      </LabeledText>
    </div>
  );
};
export default EmployeeDetailsSummaryLoader;
