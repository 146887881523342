import { Button } from '@uss/react-components';
import React from 'react';

export type SellBackActionButton = 'save' | 'update';
interface SellBackActionComponentProps {
  buttonRef: React.MutableRefObject<SellBackActionButton>;
  showModal: () => void;
  handleCancel: () => void;
  sellBackId: string;
  validateForm: () => boolean;
}

export const SellBackAction = ({
  buttonRef,
  showModal,
  handleCancel,
  sellBackId,
  validateForm,
}: SellBackActionComponentProps) => {
  return (
    <>
      <Button
        onClick={handleCancel}
        variant="outline"
        className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
        type="button"
        data-testid="sellback-cancel"
      >
        Cancel
      </Button>
      <Button
        onClick={() => {
          buttonRef.current = sellBackId === '0' ? 'save' : 'update';
          if (validateForm()) {
            showModal();
          } else {
            return;
          }
        }}
        variant="primary"
        className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
        type="submit"
        data-testid="sellback-submit"
      >
        {sellBackId === '0' ? 'Submit' : 'Update'}
      </Button>
    </>
  );
};
