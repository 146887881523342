import {
  PtoClassType,
  SHIFT_ASSIGNMENT,
  ShiftAssignment,
} from 'features/people';
import { PAYCODES } from '../constants';

// show paycode list as per user pto class

export const getPtoPaycodes = (
  ptoClass: PtoClassType,
  shiftAssignment: ShiftAssignment | null
) => {
  switch (ptoClass) {
    case 'integrated-non-exempt':
      return PAYCODES.INTEGRATED_NON_EXEMPT;
    case 'mini-mill': {
      if (shiftAssignment === SHIFT_ASSIGNMENT.EIGHT_HOUR) {
        return PAYCODES.MINI_MILL_EIGHT_HOUR;
      } else if (shiftAssignment === SHIFT_ASSIGNMENT.EIGHT_DAY_HOUR) {
        return PAYCODES.MINI_MILL_EIGHT_DAY_HOUR;
      } else if (shiftAssignment === SHIFT_ASSIGNMENT.TWELVE_HOUR) {
        return PAYCODES.MINI_MILL_TWELVE_HOUR;
      }
      return [];
    }
    default:
      return [];
  }
};
