import { Select, Table } from '@uss/react-components';
import { format, parseISO } from 'date-fns';
import { Person, ShiftAssignment } from 'features/people/types';
import { PAY_PERIOD_STATUS } from 'features/pay-periods/types';
import useTimecardRowDetails from 'features/timecards/hooks/useTimecardRowDetails';
import {
  TimecardActionType,
  EmployeeTimecardList,
  Timecard,
} from 'features/timecards/types';
import HoursInput from '../FormInputs/HoursInput';
import TurnSelect from '../FormInputs/TurnSelect';
import TimecardComment from './TimecardComment';
import { PaycodeList, usePaycodes } from 'features/paycode';
import getTimecardPaycodeParams from 'features/timecards/utilities/getTimecardPaycodeParams';
import Tooltip from 'components/Tooltip/Tooltip';
import { getPaycodesByEmpClass } from 'features/timecards/utilities/getPaycodesByEmpClass';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';

interface TableRowProps {
  timecardInstance: Timecard[];
  employeeTimecardObj: EmployeeTimecardList;
  data: Timecard;
  rowIndex: number;
  updateTimecard: React.Dispatch<TimecardActionType>;
  isTimecardDisabled: boolean;
  shiftAssignment: ShiftAssignment;
  isHoliday: boolean;
  approvedStatus: boolean;
  requestedStatus: boolean;
  isBlackout: boolean | undefined;
  employee: Person;
  roles: string[];
}
const TableRow = ({
  timecardInstance,
  employeeTimecardObj,
  data,
  rowIndex,
  updateTimecard,
  isTimecardDisabled,
  shiftAssignment,
  isHoliday,
  approvedStatus,
  requestedStatus,
  isBlackout,
  employee,
  roles,
}: TableRowProps) => {
  const {
    handleInputChange,
    handleTurnUpdate,
    onSelectPaycode,
    scheduledVarianceHours,
    handleClearShift,
    handleCommentUpdate,
    isApprovedHoursInvalid,
    isScheduleHoursInvalid,
    isWorkedHoursInvalid,
    isMoreOptionsEnabled,
    isFutureDay,
    isWorkedEditable,
    isApprovedEditable,
    isScheduleEditable,
    isPtoPaycodeSelected,
  } = useTimecardRowDetails({
    employeeTimecardObj: employeeTimecardObj,
    updateTimecard,
    timecardRowData: data,
    rowIndex,
    isTimecardDisabled,
  });
  const { data: timeCardPaycode } = usePaycodes(
    getTimecardPaycodeParams(
      employee.plantType,
      employee.shiftAssignment,
      roles,
      employee.paidSickTimeEligibility,
      employee.empClass,
      scheduledVarianceHours,
      data.date,
      employee.paidSickEligibilityDate
    )
  );
  const state = useNonUnionStore();
  const dayOfWeek = format(parseISO(data.date), 'eeee');

  const filteredTimecardPayCodeArr: PaycodeList | undefined =
    getPaycodesByEmpClass(timeCardPaycode, employee);

  //*** Logic to show Primary-crew PTOs on Secondary-crew timecard ***/
  let primaryPtoVal = '';
  if (state.primaryCrewPtos && state.primaryCrewPtos.length > 0) {
    const filteredCrewData = state.primaryCrewPtos.filter(
      (item) => item.date === data.date
    );
    if (filteredCrewData.length > 0)
      primaryPtoVal = filteredCrewData[0].paycode;
  }
  //checking if employee primaryCrew is equals to selected crew from filter
  const isPrimaryCrewValid = employee.primaryCrewName === state.crew?.crew;

  return (
    <Table.BodyRow
      key={rowIndex}
      className={
        isHoliday || isBlackout || (!isPrimaryCrewValid && primaryPtoVal !== '')
          ? 'uss-border-b uss-border-gray-200 bg-gray-300 odd:bg-gray-300 uss-h-14 hover:uss-bg-blue-100'
          : 'uss-border-b uss-border-gray-200 uss-bg-white  odd:uss-bg-gray-100 uss-h-14 hover:transparent'
      }
    >
      <Table.BodyCell id={`row-${rowIndex}-week`}>
        {' '}
        <span className="line-clamp-2" aria-label="Week Date">
          {format(parseISO(data.date), 'MMM d, E')}
          {isHoliday && <p className="text-xs">Holiday</p>}
          {!isPrimaryCrewValid && primaryPtoVal !== '' && (
            <p className="text-xs">
              {primaryPtoVal}
              {' - '}
              {'Primary Crew'}
            </p>
          )}
        </span>
        <span className="line-clamp-2" aria-label="Week Date Blackout">
          {isBlackout && <p className="text-xs">Blackout</p>}
        </span>
      </Table.BodyCell>
      <Table.BodyCell id={`row-${rowIndex}-elapsed-time`}>
        {' '}
        <span className="line-clamp-2" aria-label="Elapsed Time">
          {data.elapsedTime ?? '-'}
        </span>
      </Table.BodyCell>
      <Table.BodyCell
        id={`row-${rowIndex}-scheduled-turns`}
        aria-label="Scheduled Turn"
      >
        <TurnSelect
          initialSelected={data.scheduled.turn ?? ''}
          editable={isScheduleEditable}
          futureDay={isFutureDay}
          shift={shiftAssignment}
          turnType={'scheduledTurn'}
          update={handleTurnUpdate}
        />
      </Table.BodyCell>
      <Table.BodyCell id={`row-${rowIndex}-scheduled-hours`}>
        {' '}
        <HoursInput
          index={rowIndex}
          label={`Scheduled Hours ${dayOfWeek}`}
          futureDay={isFutureDay}
          editable={isScheduleEditable}
          error={isScheduleHoursInvalid}
          updateInput={handleInputChange}
          hourType={'scheduledHours'}
          initialValue={
            data.scheduled.hours !== null ? String(data.scheduled.hours) : ''
          }
        />
      </Table.BodyCell>
      <Table.BodyCell
        id={`row-${rowIndex}-worked-turns`}
        aria-label="Worked Turn"
      >
        {' '}
        <TurnSelect
          initialSelected={data.worked.turn ?? ''}
          editable={isWorkedEditable}
          futureDay={isFutureDay}
          shift={shiftAssignment}
          turnType={'workedTurn'}
          update={handleTurnUpdate}
        />
      </Table.BodyCell>
      <Table.BodyCell id={`row-${rowIndex}-worked-hours`}>
        {' '}
        <HoursInput
          index={rowIndex}
          label={`Worked Hours ${dayOfWeek}`}
          futureDay={isFutureDay}
          editable={isWorkedEditable}
          error={isWorkedHoursInvalid}
          updateInput={handleInputChange}
          hourType={'workedHours'}
          initialValue={
            data.worked.hours !== null ? String(data.worked.hours) : ''
          }
        />
      </Table.BodyCell>
      <Table.BodyCell
        id={`row-${rowIndex}-approved-turns`}
        aria-label="Approved Turn"
      >
        <TurnSelect
          initialSelected={data.approved.turn ?? ''}
          editable={isApprovedEditable}
          futureDay={isFutureDay}
          shift={shiftAssignment}
          turnType={'approvedTurn'}
          update={handleTurnUpdate}
        />
      </Table.BodyCell>
      <Table.BodyCell id={`row-${rowIndex}-approved-hours`}>
        {' '}
        <HoursInput
          label={`Approved Hours ${dayOfWeek}`}
          futureDay={isFutureDay}
          editable={isApprovedEditable}
          error={isApprovedHoursInvalid}
          updateInput={handleInputChange}
          hourType={'approvedHours'}
          index={rowIndex}
          initialValue={
            data.approved.hours !== null ? String(data.approved.hours) : ''
          }
        />
      </Table.BodyCell>
      <Table.BodyCell
        id={`row-${rowIndex}-schedule-variance-paycode`}
        aria-label="Schedule Variance Paycode"
      >
        {isWorkedEditable ||
        isApprovedEditable ||
        isScheduleEditable ||
        employeeTimecardObj.items[0].payPeriodStatus ===
          PAY_PERIOD_STATUS.NOT_OPEN ? (
          <>
            <Select
              selected={data.scheduleVariance.payCode ?? ''}
              onSelect={(value: string) => onSelectPaycode(value)}
              disabled={
                (!isHoliday && scheduledVarianceHours === 0) ||
                (isPtoPaycodeSelected &&
                  !(
                    employee.plantType === 'integrated_mill' ||
                    employee.plantType === 'office'
                  ))
              }
            >
              <Select.Button
                label={
                  data.scheduleVariance.payCode !== null
                    ? String(data.scheduleVariance.payCode)
                    : ''
                }
              />

              <Select.Options isWidthAuto={true}>
                <Select.Option truncate value="">
                  <span className="py-2 block"></span>
                </Select.Option>
                {isHoliday &&
                scheduledVarianceHours === 0 &&
                employee.plantType === 'mini_mill' ? (
                  <Select.Option value="HB">
                    HB - Holiday Block - No Pay
                  </Select.Option>
                ) : isHoliday &&
                  scheduledVarianceHours === 0 &&
                  employee.plantType !== 'mini_mill' ? (
                  <Select.Option value="HS">
                    HS - Holiday Scheduled - Not Worked{' '}
                  </Select.Option>
                ) : (
                  filteredTimecardPayCodeArr &&
                  filteredTimecardPayCodeArr.items.length > 0 &&
                  filteredTimecardPayCodeArr.items.map((pc, id) => (
                    <Select.Option truncate key={id} value={pc.code}>
                      <span>
                        {pc.code} - {pc.description}
                      </span>
                    </Select.Option>
                  ))
                )}
              </Select.Options>
            </Select>
            {approvedStatus && (
              <p className="text-xs" aria-label="approved">
                Approved
              </p>
            )}
            {requestedStatus && (
              <p className="text-xs" aria-label="requested">
                Requested
              </p>
            )}
          </>
        ) : (
          <span>{data.scheduleVariance.payCode ?? '-'}</span>
        )}
      </Table.BodyCell>
      <Table.BodyCell id={`row-${rowIndex}-schedule-variance-hours`}>
        <span className="line-clamp-2" aria-label="Schedule Variance Hours">
          {data.scheduleVariance.variance ?? '-'}
        </span>
      </Table.BodyCell>

      <Table.BodyCell id={`row-${rowIndex}-paid-hours-not-worked`}>
        <span className="line-clamp-2" aria-label="Paid Hours Not Worked">
          {data.paidHoursNotWorked === null || data.paidHoursNotWorked === 0
            ? '-'
            : data.paidHoursNotWorked}
        </span>
      </Table.BodyCell>
      <Table.BodyCell id={`row-${rowIndex}-excp-code`}>
        {data.exceptionCode ? (
          <Tooltip
            placement="bottom"
            tooltipContent={
              <span aria-label="Exception Code Description">
                {data.exceptionCodeDescription}
              </span>
            }
            clickTrigger
          >
            <span
              className="line-clamp-2 text-accent2-500"
              aria-label="Exception Code"
            >
              {data.exceptionCode}
            </span>
          </Tooltip>
        ) : (
          <span className="line-clamp-2">{'-'}</span>
        )}
      </Table.BodyCell>
      <Table.BodyCell id={`row-${rowIndex}-comments`}>
        <span
          className="flex flex-row items-center justify-end"
          aria-label="Timecard Comment"
        >
          <TimecardComment
            isMoreOptionsEnabled={isMoreOptionsEnabled}
            handleClearShift={handleClearShift}
            timecardInstance={timecardInstance}
            employeeTimecardObj={employeeTimecardObj}
            rowData={data}
            date={data.date}
            handleCommentUpdate={handleCommentUpdate}
            employee={employee}
            isPtoPaycodeSelected={isPtoPaycodeSelected}
          />
        </span>
      </Table.BodyCell>
    </Table.BodyRow>
  );
};

export default TableRow;
