import { Avatar } from '@uss/react-components';
import Card from 'components/Card/Card';
import SeparatedLabels from 'components/SeparatedLabels/SeparatedLabels';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';
import { format, parseISO } from 'date-fns';
import { PTO_STATUS } from 'features/paid-time-off/constants';
import { getPtoStatusText } from 'features/paid-time-off/utility/getPtoStatusText';
import useWindowSize from 'hooks/useWindowSize';
import { avatarColor, getInitialsByDisplayName } from 'utils';
import { PtoRequestActions } from 'features/paid-time-off/components/PtoRequests/PtoRequestActions';
import { NavLink } from 'react-router-dom';
import { PtoRequest } from 'features/paid-time-off/types/pto-requests';

interface Props {
  pto: PtoRequest;
}

const PtoRequestCard = ({ pto }: Props) => {
  const { width } = useWindowSize();

  return (
    <Card
      bgColor={
        pto.status === PTO_STATUS.COMPANY_BLACKOUT ? 'bg-gray-100' : 'bg-white'
      }
      border={
        pto.status === PTO_STATUS.COMPANY_BLACKOUT
          ? 'border border-gray-700'
          : undefined
      }
    >
      <Card.Header>
        <div className="flex w-full justify-between">
          <div className="flex flex-wrap w-full">
            <div className="flex items-center w-full sm:w-4/12">
              <Avatar color={avatarColor(Number(pto.ussId))} size="medium">
                {getInitialsByDisplayName(pto.displayName)}
              </Avatar>
              <div className="pl-3">
                <Title as="h2">
                  <NavLink
                    to={`/paid-time-off/employees/${pto.ussId}`}
                    className="text-primary-400 cursor-pointer"
                  >
                    {pto.displayName}
                  </NavLink>
                </Title>
                <SeparatedLabels
                  size="md"
                  items={[
                    { label: 'USS ID', value: pto.ussId, width: 'w-20' },
                    {
                      label: 'Emp Class',
                      value: pto.empClass
                        ? pto.empClass + ' - ' + pto.empClassDesc
                        : '-',
                      // width: 'w-3/4',
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <PtoRequestActions placement="header" pto={pto} />
      </Card.Header>
      <Card.Body>
        <div className="flex flex-wrap sm:flex-nowrap mr-0 sm:mr-16">
          <LabeledText
            label="Status"
            lineClamp={1}
            className="w-1/2 lg:w-1/4 mb-4 lg:mb-0"
          >
            {pto.status === PTO_STATUS.COMPANY_BLACKOUT
              ? 'Blackout'
              : getPtoStatusText(pto.ptoDetails.details[0]?.status)}
          </LabeledText>
          <LabeledText
            label="PTO Date"
            lineClamp={1}
            className="w-1/2 lg:w-1/5 mb-4 lg:mb-0"
          >
            {pto.date ? format(parseISO(pto.date), 'MMM dd, yyyy') : '--'}
          </LabeledText>
          <LabeledText
            label="Pay Code"
            lineClamp={1}
            className="w-1/2 lg:w-1/5 mb-4 lg:mb-0"
          >
            {pto.payCode} - {pto.payCodeDescription}
          </LabeledText>
          <LabeledText
            label="Remaining"
            lineClamp={1}
            className="w-1/2 lg:w-1/5 mb-4 lg:mb-0"
          >
            {pto.remainingPto ? pto.remainingPto.toString() + 'h' : '--'}
          </LabeledText>
          <LabeledText
            label="Requested On"
            lineClamp={1}
            className="w-full lg:w-4/12 mb-4 last-of-type:mb-0 lg:mb-0"
          >
            {format(new Date(pto.requestedOn), 'MMM dd, yyyy, hh:mm aa')}
          </LabeledText>
        </div>
      </Card.Body>
      {width && width < 780 && (
        <Card.Footer>
          <PtoRequestActions placement="footer" pto={pto} />
        </Card.Footer>
      )}
    </Card>
  );
};

export default PtoRequestCard;
