import { AreaManagerTabs, HrLrTabs, Actions, ReviewerTabs } from '../types';
import getCurrentView from './getCurrentView';

export const getSuccessConfirmationMessage = (
  pathname: string,
  currentTab: AreaManagerTabs | ReviewerTabs | HrLrTabs | undefined,
  reviewNumber: number,
  action: Actions
) => {
  const { isAreaManagerView, isReviewerView, isHrLrView, isEmployeeView } =
    getCurrentView(pathname);
  return isAreaManagerView &&
    currentTab === AreaManagerTabs.REVIEW_PERFORMANCE &&
    action === Actions.SUPERVISOR_TERMINATED
    ? 'You have successfully completed the performance review.'
    : isAreaManagerView &&
      currentTab === AreaManagerTabs.TERMINATION &&
      action === Actions.SUPERVISOR_TERM_ACKNOWLEDGEMENT
    ? 'You have acknowledged the employee termination'
    : isReviewerView &&
      currentTab === ReviewerTabs.COUNSELING &&
      (action === Actions.REVIEWER_RETAINED ||
        action === Actions.REVIEWER_TERMINATED)
    ? `You have successfully completed the counseling with employee`
    : isHrLrView &&
      (currentTab === HrLrTabs.LOW_RATING ||
        currentTab === HrLrTabs.PROB_EMPLOYEES) &&
      action === Actions.ADD_COMMENT
    ? 'Comments have been added successfully.'
    : isHrLrView &&
      currentTab === HrLrTabs.REVIEW_PERFORMANCE &&
      action === Actions.HR_LR_RETAINED
    ? `Performance review ${reviewNumber} is completed until next review.`
    : isHrLrView &&
      currentTab === HrLrTabs.REVIEW_PERFORMANCE &&
      action === Actions.COUNSELLING
    ? 'Employee has been moved to reviewer counseling.'
    : isHrLrView &&
      currentTab === HrLrTabs.REVIEW_PERFORMANCE &&
      action === Actions.HR_LR_TERMINATED
    ? 'Employee evaluation has been submitted with termination recommendation'
    : isEmployeeView
    ? 'You have successfully acknowledge the performance report.'
    : `You have successfully completed the performance review #${reviewNumber}`;
};
