import { OrgList } from 'features/org/types';

import { usePtoRequestsStore } from 'features/paid-time-off/state';
import { useEffect } from 'react';
import { QueryStatus } from '@tanstack/react-query';
import OrgFilters from 'components/OrgFilters/OrgFilters';
import { RequestPtoSort } from './RequestPtoSort';

interface RequestPtoFilterProps {
  plants: OrgList | undefined;
  plantStatus: QueryStatus;
  orgStatus: QueryStatus;
  orgs: OrgList | undefined;
}

const RequestPtoFilter = ({
  plants,

  orgs,
  plantStatus,
  orgStatus,
}: RequestPtoFilterProps) => {
  const store = usePtoRequestsStore();

  useEffect(() => {
    if (store.selectedPlant === null && plantStatus === 'success' && plants) {
      store.setPlant(plants.items[0]);
    }
  });

  return (
    <>
      <OrgFilters
        plants={plants?.items ? plants.items : []}
        orgs={orgs?.items ? orgs.items : []}
        selectedOrgs={{
          selectedPlant: store.selectedPlant,
          selectedPayrollLocation: store.selectedPayrollLocation,
          selectedDivision: store.selectedDivision,
          selectedDepartment: store.selectedDepartment,
        }}
        status={orgStatus}
        plantStatus={plantStatus}
        itemsPerRow={1}
      >
        <div className=" grid sm:grid-cols-4 gap-4 sm:gap-x-4 sm:gap-y-4 w-full grid-cols-1">
          <OrgFilters.Plant
            selectedItem={store.selectedPlant}
            handleSelect={store.setPlant}
          />
          <OrgFilters.Location
            selectedItem={store.selectedPayrollLocation}
            handleSelect={store.setPayrollLocation}
            showSelectAll={true}
          />
          <OrgFilters.Division
            selectedItem={store.selectedDivision}
            handleSelect={store.setDivision}
            showSelectAll={true}
          />
          <OrgFilters.Department
            selectedItem={store.selectedDepartment}
            handleSelect={store.setDepartment}
          />
          <OrgFilters.Crew
            selectedItem={store.selectedCrew}
            handleSelect={store.setCrew}
          />
          <div className="w-full sm:col-start-4">
            <RequestPtoSort />
          </div>
        </div>
      </OrgFilters>
    </>
  );
};
export default RequestPtoFilter;
