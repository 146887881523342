import { z } from 'zod';
import {
  TimecardSchema,
  TimecardStatusCodeSchema,
} from 'features/timecards/schemas/timecardSchema';
import { ObjectValues } from 'types/utils';
import { PtoDetail } from 'features/paid-time-off';

export const TIMECARD_STATUS = {
  NOT_VERIFIED: 'not-verified',
  VERIFIED: 'verified',
  APPROVED: 'approved',
  UNAPPROVED: 'unapproved',
  OPEN_ADJUSTMENT: 'open-adjustment',
  APPROVED_ADJUSTMENT: 'approved-adjustment',
  UNAPPROVED_ADJUSTMENT: 'unapproved-adjustment',
} as const;

export type TimecardStatusCode = ObjectValues<typeof TIMECARD_STATUS>;

export const EMPLOYEE_CLASSES = {
  PART_TIME: '0',
  NON_EXEMPT: '3',
  EXEMPT: '4',
  NON_EXCEMPT_P_M: '6',
} as const;

export type EmpClasses = ObjectValues<typeof EMPLOYEE_CLASSES>;

// export const UNIT_OF_MEASURE = {
//   DAYS: 'days',
//   HOURS: 'hours',
// } as const;

// export type UnitOfMeasure = ObjectValues<typeof UNIT_OF_MEASURE>;

export const TIMECARD_ACTION_BUTTON = {
  CREATE_TIMECARD: 'create-timecard',
  SAVE: 'save',
  SAVE_VERIFY: 'save-verify',
  UNAPPROVE: 'unapprove',
  OPEN_ADJUSTMENTS: 'open-adjustments',
  SAVE_ADJUSTMENTS: 'save-adjustments',
  SAVE_APPROVE_ADJUSTMENTS: 'save-approve-adjustments',
  UNAPPROVE_ADJUSTMENTS: 'unapprove-adjustments',
  SAVE_APPROVE: 'save-approve',
  CANCEL: 'cancel',
} as const;

export type TimecardActionButton = ObjectValues<typeof TIMECARD_ACTION_BUTTON>;

export const TIMECARD_UPDATE_ACTIONS = {
  TURNS: 'turns',
  HOURS: 'hours',
  PAYCODE: 'paycode',
  CLEAR_SHIFT: 'clearShift',
  RESET_INITIAL_DATA: 'resetInitialData',
  COMMENTS: 'comments',
} as const;

export type HourTypes = 'scheduledHours' | 'workedHours' | 'approvedHours';
export type TurnTypes = 'scheduledTurn' | 'workedTurn' | 'approvedTurn';
export type TimecardFeature = 'pay' | 'scheduling' | 'my-schedule';

type TimecardStatusCodeType = z.infer<typeof TimecardStatusCodeSchema>;

export type TimecardPayload = z.infer<typeof TimecardSchema>;

export interface Timecard {
  ptoDetails: PtoDetail | null;
  id: string;
  date: string;
  elapsedTime: string | null;
  scheduled: TimecardEfforts;
  worked: TimecardEfforts;
  approved: TimecardEfforts;
  scheduleVariance: {
    payCode: string | null;
    variance: number | null;
  };
  paidHoursNotWorked: number | null;
  exceptionCode: string | null;
  exceptionCodeDescription: string | null;
  comments: string | null;
  blank: boolean;
  context?: TimecardFeature;
}

export interface TimecardEfforts {
  turn: string | null;
  hours: number | null;
}
export interface TimecardAuditMsg {
  key: string;
  msg: string;
}

export interface TimecardTotal {
  scheduled: number;
  worked: number;
  approved: number;
  scheduleVariance: number;
  paidHoursNotWorked: number;
}

export type TimecardActionType =
  | {
      type: 'turns';
      payload: {
        rowIndex: number;
        turn: string;
        columnName: TurnTypes;
      };
    }
  | {
      type: 'hours';
      payload: {
        rowIndex: number;
        hours: number;
        scheduleVariance: number;
        columnName: HourTypes;
      };
    }
  | {
      type: 'paycode';
      payload: { rowIndex: number; paycode: string };
    }
  | {
      type: 'clearShift';
      payload: {
        rowIndex: number;
        scheduleVariance: number;
        columnName: HourTypes;
      };
    }
  | { type: 'resetInitialData'; payload: Timecard[] }
  | {
      type: 'comments';
      payload: { rowIndex: number; comment: string };
    };
export interface TimecardUpdatePayload {
  timecard: TimecardPayload[];
  status?: {
    code: TimecardStatusCodeType;
  };
}
export interface PrimaryCrewPtoType {
  date: string;
  paycode: string;
}
