import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import {
  PeopleOptions,
  PeopleResponse,
  Person,
  ReporteeOptions,
} from '../types';

const get_person = (ussId: string, params?: PeopleOptions): Promise<Person> => {
  return axios.get(`/v1/people/${ussId}`, { params });
};

const get_people_all = (params?: PeopleOptions): Promise<PeopleResponse> => {
  return axios.get(`/v1/people`, { params });
};

const search_people = (params: PeopleOptions): Promise<PeopleResponse> => {
  return axios.get(`/v1/people`, {
    params,
  });
};
const searchReportees = (
  ussId: string,
  params: ReporteeOptions
): Promise<PeopleResponse> => {
  return axios.get(`/v1/people/${ussId}/reportees`, {
    params,
  });
};

export const usePersonDetails = (ussId: string, params?: PeopleOptions) => {
  return useQuery({
    queryFn: () =>
      get_person(ussId, {
        ...params,
        week: params?.week,
      }),
    queryKey: ['person-details', { ussId, params }],
  });
};
export const useLoggedInUser = () => {
  return useQuery({
    queryFn: () => get_person('me'),
    queryKey: ['logged-in-user'],
    gcTime: Infinity,
    staleTime: Infinity,
  });
};

export const usePeople = (
  params?: PeopleOptions,
  queryOptions?: Omit<UseQueryOptions<PeopleResponse>, 'queryFn' | 'queryKey'>
) => {
  return useQuery<PeopleResponse>({
    ...queryOptions,
    queryFn: () => get_people_all(params),
    queryKey: ['people', { ...params }],
  });
};

export const useSearchPeople = (params: PeopleOptions) => {
  return useQuery<PeopleResponse>({
    queryKey: ['temp-emp-search', { ...params }],
    queryFn: () => search_people(params),
    enabled: params.search ? params.search.length >= 3 : false,
    gcTime: 2000,
    retry: false,
  });
};
export const useSearchReportees = (ussID: string, params: ReporteeOptions) => {
  return useQuery<PeopleResponse>({
    queryKey: ['reportees-search', ussID, { ...params }],
    queryFn: () => searchReportees(ussID, params),
    enabled: !!ussID && params.search ? params.search.length >= 3 : false,
    gcTime: 2000,
    retry: false,
  });
};
