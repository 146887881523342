import React from 'react';
import { Select } from '@uss/react-components';

interface YearSelectParams {
  data: string[];
  setYear: (value: string) => void;
  selectedYear: string;
}

export const YearSelect = ({
  setYear,
  selectedYear,
  data,
}: YearSelectParams) => {
  return (
    <Select selected={selectedYear} onSelect={setYear}>
      <Select.Button label={selectedYear} />
      <Select.Options>
        {data.map((item, index) => (
          <Select.Option key={index} value={item}>
            {item}
          </Select.Option>
        ))}
      </Select.Options>
    </Select>
  );
};
