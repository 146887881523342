import { SortOptions } from 'features/timecards/types';
import { SortDirection } from 'types/common';
export const getCrewTimecardsSortOptions = (): {
  name: SortOptions;
  label: string;
  direction: SortDirection;
}[] => {
  const getSortOptionLabel = (sortOption: SortOptions) => {
    switch (sortOption) {
      case 'name':
        return 'Name';
      case 'ussId':
        return 'USS ID';
    }
  };
  return [
    {
      name: 'name',
      label: getSortOptionLabel('name'),
      direction: SortDirection.ASC,
    },
    {
      name: 'name',
      label: getSortOptionLabel('name'),
      direction: SortDirection.DESC,
    },
    {
      name: 'ussId',
      label: getSortOptionLabel('ussId'),
      direction: SortDirection.ASC,
    },
    {
      name: 'ussId',
      label: getSortOptionLabel('ussId'),
      direction: SortDirection.DESC,
    },
  ];
};
