function getFormattedReviewNumber(reviewNumber: number | undefined) {
  switch (reviewNumber) {
    case 1:
      return '1st review';
    case 2:
      return '2nd review';
    case 3:
      return '3rd review';
    default:
      return '-';
  }
}

export default getFormattedReviewNumber;
