import { useMutation } from '@tanstack/react-query';
import { WMSError } from 'types/common';
import { axios } from 'services/api/axios';
import { queryClient } from 'services/api/react-query';
import {
  BulkActionRequestBody,
  BulkActionResponse,
} from 'features/timecards/types';

const getPaySchBulkAction = ({
  crewId,
  requestBody,
}: {
  crewId: string;
  requestBody: BulkActionRequestBody;
}): Promise<BulkActionResponse> => {
  return axios.put(`/v1/crews/${crewId}/timecards-status`, {
    ...requestBody,
  });
};

export const usePaySchBulkAction = (crewId: string) => {
  return useMutation<BulkActionResponse, WMSError, BulkActionRequestBody>({
    mutationFn: (body: BulkActionRequestBody) =>
      getPaySchBulkAction({ crewId: crewId, requestBody: body }),
    meta: {
      errorMessage: 'Unable to perform actions.',
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['crew-timecards'] });
    },
  });
};
