import { usePtoRequestsStore } from 'features/paid-time-off/state';
import { PtoRequests } from 'features/paid-time-off/types/pto-requests';
import CardsLoader from 'components/Loaders/CardsLoader';
import Pagination from 'components/Pagination/Pagination';
import CardList from 'components/Card/CardList';
import PtoRequestCard from '../Cards/PtoRequestCard';
import { QueryStatus } from '@tanstack/react-query';

interface Props {
  ptoRequests: PtoRequests | undefined;
  ptoRequestsStatus: QueryStatus;
}

export const CompletedRequestsTab = ({
  ptoRequests,
  ptoRequestsStatus,
}: Props) => {
  const store = usePtoRequestsStore();

  const noResults = {
    title: 'No Records Found',
    description: 'No PTO records found',
  };

  return (
    <>
      {ptoRequestsStatus === 'pending' && (
        <CardsLoader variant="row-grid" count={9} hasAvatar />
      )}
      {ptoRequestsStatus === 'success' && ptoRequests && (
        <>
          <CardList
            items={ptoRequests.items}
            variant="row-grid"
            renderItem={(pto) => <PtoRequestCard pto={pto} />}
            noResults={noResults}
          />
          <div className="pt-5">
            <Pagination
              pageOptions={[9, 18, 27, 40, 50, 60]}
              totalCount={ptoRequests.totalCount}
              page={ptoRequests.page}
              pageSize={ptoRequests.pageSize}
              onPageChange={(s) => store.setCompletedPage(s.selected + 1)}
              onPageSizeChange={(ps) => store.setCompletedPageSize(ps)}
            />
          </div>
        </>
      )}
    </>
  );
};
