import { axios } from 'services/api/axios';
import {
  AddBlackoutPost,
  AddPtoPost,
  CrewPtoDetailsOptions,
  DeleteEmployeesBlackout,
  PTOBlackoutList,
  PTORecordsList,
  PatchPtoPayload,
  PatchPtoResponse,
  PtoBlackoutOptions,
  PtoDetail,
  PtoRecordsOptions,
  PtoSellBack,
  PtoSummary,
  RequestPtoPost,
  UpdatePtoRequestPut,
} from '../types';
import {
  SellBackPtoOptions,
  SellBackPtoPost,
  SellBackPtoPut,
} from '../types/sellback';
import {
  GetCrewPTOPayload,
  CrewPtoOrgDetailsOptions,
  CrewPtoDetailsList,
  CrewPtoTotalsList,
} from '../types/crew-pto';
import { PtoSummaryOptions } from '../types/employee-pto';
import { ApiBasicStatusResponse } from 'types/common';
import { PtoRequests, PtoRequestsOptions } from '../types/pto-requests';
import { PTOHistoryItemList, PTOHistoryParams } from '../types/pto-history';

// ** EMPLOYEE PTO

export const employeePto = {
  getAll: (
    ussId: string,
    options?: PtoRecordsOptions
  ): Promise<PTORecordsList> => {
    return axios.get(`/v2/people/${ussId}/pto-details`, {
      params: options,
    });
  },

  getSummary: (
    ussId: string,
    options?: PtoSummaryOptions
  ): Promise<PtoSummary> => {
    return axios.get(`/v2/people/${ussId}/pto-summary`, {
      params: options,
    });
  },
};

// *************

// ** PTO Records *****

export const ptoRecord = {
  get: (ptoId: string): Promise<PtoDetail> => {
    return axios.get(`/v2/pto/${ptoId}`);
  },
  post: (body: RequestPtoPost): Promise<ApiBasicStatusResponse> => {
    return axios.post(`/v2/pto`, body);
  },
  delete: (ptoId: string): Promise<ApiBasicStatusResponse> => {
    return axios.delete(`/v2/pto/${ptoId}`);
  },
  patch: (ptoId: string, body: PatchPtoPayload): Promise<PatchPtoResponse> => {
    return axios.patch(`/v2/pto/${ptoId}`, body);
  },
  put: (ptoId: string, body: UpdatePtoRequestPut): Promise<PtoDetail> => {
    return axios.put(`/v2/pto/${ptoId}`, body);
  },
};

// Add PTO Request
export const addPtoRecord = {
  post: (body: AddPtoPost): Promise<ApiBasicStatusResponse> => {
    return axios.post(`/v2/pto`, body);
  },
};

// Add Blackout Request
export const addBlackoutRecord = {
  post: (body: AddBlackoutPost): Promise<ApiBasicStatusResponse> => {
    return axios.post(`/v2/pto-blackout`, body);
  },
};

// ***********************

// Sell Back PTO *********

export const sellBack = {
  get: (ussId: string, options?: SellBackPtoOptions): Promise<PtoSellBack> => {
    return axios.get(`/v1/people/${ussId}/sell-back-pto`, {
      params: options,
    });
  },
  post: (ussId: string, body: SellBackPtoPost): Promise<PtoSellBack> => {
    return axios.post(`/v1/people/${ussId}/sell-back-pto`, body);
  },
  put: (
    ussId: string,
    id: string,
    body: SellBackPtoPut
  ): Promise<PtoSellBack> => {
    return axios.put(`/v1/people/${ussId}/sell-back-pto/${id}`, body);
  },
};

// *********

// ** CREW PTO

export const crewPto = {
  getOrgDetails: (
    body: GetCrewPTOPayload,
    options: CrewPtoOrgDetailsOptions
  ): Promise<CrewPtoDetailsList> => {
    return axios.post(`/v1/pto/org-details`, body, { params: options });
  },
  getPtoDetails: (
    body: GetCrewPTOPayload,
    options: CrewPtoDetailsOptions
  ): Promise<CrewPtoTotalsList> => {
    return axios.post(`/v1/pto/org-totals`, body, { params: options });
  },
};

// ** BLACKOUT PTO

export const blackoutPto = {
  getPtoBlackout: (
    ussId: string,
    options?: PtoBlackoutOptions
  ): Promise<PTOBlackoutList> => {
    return axios.get(`/v2/${ussId}/pto-blackout`, {
      params: options,
    });
  },
};
export const ptoBlackout = {
  post: (
    body: AddBlackoutPost
  ): Promise<{
    success: boolean;
    message: string;
  }> => {
    return axios.post(`/v2/pto-blackout`, body);
  },
  delete: (body: DeleteEmployeesBlackout): Promise<ApiBasicStatusResponse> => {
    return axios.delete(`/v2/pto-blackout`, { data: body });
  },
};

// *********

// ** PTO Requests

export const ptoRequests = {
  getPtoRequests: (
    orgId: string,
    options?: PtoRequestsOptions
  ): Promise<PtoRequests> => {
    return axios.get(`/v1/pto/org-details/${orgId}`, { params: options });
  },
};

// ****************

// *** PTO History

export const getPTOHistory = (
  ptoId: string,
  options: PTOHistoryParams
): Promise<PTOHistoryItemList> => {
  return axios.get(`/v1/pto-history/${ptoId}`, { params: options });
};

// ****************
