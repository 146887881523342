import { z } from 'zod';
import { ORG_TYPE } from '../constants';

const commentSchema = z
  .string()
  .max(500)
  .min(1, { message: 'Comments cannot be empty' });

export const OrgPostSchema = z.object({
  parentOrgId: z.string().min(1),
  name: z
    .string()
    .max(2, { message: 'String should be at most 2 digit(s)' })
    .min(1, { message: 'String should be at least 2 digit(s)' })
    .regex(/^(?:\d{2}|)$/, { message: 'String should be 2 digit(s)' }),
  type: z
    .union([
      z.literal(ORG_TYPE.PLANT),
      z.literal(ORG_TYPE.LOCATION),
      z.literal(ORG_TYPE.DIVISION),
      z.literal(ORG_TYPE.DEPARTMENT),
      z.literal(ORG_TYPE.CREW),
    ])
    .refine(
      (val) =>
        val === ORG_TYPE.DIVISION ||
        val === ORG_TYPE.DEPARTMENT ||
        val === ORG_TYPE.CREW,
      { message: 'Please use a valid Org Type' }
    ),
  description: z.string().max(500).min(1),
  comments: commentSchema,
  sortSequence: z.string().optional(),
  safetyMeetingWeekOfMonth: z.string().optional(),
  safetyMeetingDayOfWeek: z.string().optional(),
  safetyMeetingTurnOnTheDay: z.string().optional(),
  safetyMeetingLengthMinutes: z.string().optional(),
  safetyMeetingBeforeOrAfterShift: z.string().optional(),
  safetyMeetingIncentiveEligible: z.string().optional(),
});

export const descriptionSchema = z.object({
  description: z.string().max(500).min(1),
});

export const OrgStatusUnion = z.union([
  z.literal('active'),
  z.literal('archived'),
  z.literal('rejected'),
  z.literal('denied'),
  z.literal('pending'),
]);

export const OrgPutSchema = z.object({
  parentOrgId: z.string().min(1),
  name: z.string().max(2).min(1),
  description: z.string().max(500).min(1),
  status: z
    .object({
      code: OrgStatusUnion,
    })
    .optional(),
  comments: commentSchema,
  sortSequence: z.string().optional(),
  safetyMeetingWeekOfMonth: z.string().optional(),
  safetyMeetingDayOfWeek: z.string().optional(),
  safetyMeetingTurnOnTheDay: z.string().optional(),
  safetyMeetingLengthMinutes: z.string().optional(),
  safetyMeetingBeforeOrAfterShift: z.string().optional(),
  safetyMeetingIncentiveEligible: z.string().optional(),
});

export const orgPatchSchema = z.object({
  status: z.object({
    code: OrgStatusUnion.refine(
      (val) => val === 'active' || val === 'archived'
    ),
  }),
  comments: z.string().min(1, { message: 'Comments cannot be empty' }),
});
