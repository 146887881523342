import Page from 'components/Layouts/Page';
import Tabs, { TabLinkType } from 'components/Tabs/Tabs';
import ProbationaryEmployeesSummary from 'features/probationary-tracking/components/hr-lr/Summary';
import { HrLrTabs } from 'features/probationary-tracking/types';
import { ErrorBoundary } from 'react-error-boundary';
import Error from 'components/Error/Error';
import ProbEmployees from 'features/probationary-tracking/components/hr-lr/tabs/ProbEmployees';
import LowRating from 'features/probationary-tracking/components/hr-lr/tabs/LowRating';
import ReviewPerformance from 'features/probationary-tracking/components/hr-lr/tabs/ReviewPerformance';
import { useProbationaryEmployeesStore } from 'features/probationary-tracking/state/store';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const HrLrView = () => {
  return (
    <Page heading={'Probationary Employees'}>
      <ErrorBoundary
        fallbackRender={({ error }) => {
          return (
            <Error
              width="300"
              height="300"
              title="Something went wrong!"
              description={error.message}
            />
          );
        }}
      >
        <Content />
      </ErrorBoundary>
    </Page>
  );
};
const Content = () => {
  // Tabs
  const { hrLr } = useProbationaryEmployeesStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = hrLr.currentTab;
  const setCurrentTab = hrLr.setCurrentTab;
  const handleTabClick = (label: HrLrTabs) => {
    setCurrentTab(label);
  };

  useEffect(() => {
    if (currentTab) {
      const updatedSearchParams = new URLSearchParams(searchParams.toString());
      updatedSearchParams.set('tab', currentTab);
      setSearchParams(updatedSearchParams.toString());
    } else {
      setCurrentTab(HrLrTabs.PROB_EMPLOYEES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabLinks: TabLinkType[] = [
    {
      label: HrLrTabs.PROB_EMPLOYEES,
      element: <ProbEmployees />,
      customOnChange: () => handleTabClick(HrLrTabs.PROB_EMPLOYEES),
    },
    {
      label: HrLrTabs.LOW_RATING,
      element: <LowRating />,
      customOnChange: () => handleTabClick(HrLrTabs.LOW_RATING),
    },
    {
      label: HrLrTabs.REVIEW_PERFORMANCE,
      element: <ReviewPerformance />,
      customOnChange: () => handleTabClick(HrLrTabs.REVIEW_PERFORMANCE),
    },
  ];
  return (
    <>
      <Page.Section>
        <ProbationaryEmployeesSummary />
      </Page.Section>
      <Page.Section>
        <Tabs tabLinks={tabLinks} />
      </Page.Section>
    </>
  );
};
export default HrLrView;
