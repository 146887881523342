import { useQuery } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { ReviewGet } from '../types/employeeReviewDetails';

const getProbationaryEmployeeReview = (
  ussId: string,
  reviewId: string
): Promise<ReviewGet> => {
  return axios.get(`/v1/prob-emp/${ussId}/reviews/${reviewId}`);
};

export const useProbationaryEmployeesReview = (
  ussId: string,
  reviewId: string
) => {
  return useQuery({
    queryFn: () => getProbationaryEmployeeReview(ussId, reviewId),
    queryKey: ['get-review-details', { ussId, reviewId }],
  });
};
