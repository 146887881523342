import {
  ProbEmployeeReview,
  ReviewPerformanceDetail,
} from 'features/probationary-tracking/types/employeeReviewDetails';
import { NavLink, useLocation } from 'react-router-dom';
import { PerformanceSection } from './form-sections/PerformanceSection';
import { AttendanceSection } from './form-sections/AttendanceSection';
import { CommentsSection } from './form-sections/CommentsSection';
import { useState } from 'react';
import { PerformanceCriteriaActions } from './form-sections/PerformanceCriteriaActions';
import { Link } from '@uss/react-components';
import { ZodErrorType } from 'types/common';
import { getViewPermissionsByRole } from 'features/probationary-tracking/utilities/getViewPermissionsByRole';
import { useProbationaryEmployeesStore } from 'features/probationary-tracking/state/store';
import { getCurrentTab } from 'features/probationary-tracking/utilities/getCurrentTab';

interface Props {
  data: ProbEmployeeReview;
}

export const PerformanceReviewForm = ({ data }: Props) => {
  const { pathname } = useLocation();
  const { performance, attendance, comments, reviewStatus } = data;
  const [editComment, setEditComment] = useState<string>('');
  const [performanceObj, setPerformanceObj] = useState<ReviewPerformanceDetail>(
    {} as ReviewPerformanceDetail
  );
  const [errors, setErrors] = useState<ZodErrorType>({});

  const { areaManager, reviewer, hrLr } = useProbationaryEmployeesStore();

  const currentTab = getCurrentTab(
    areaManager.currentTab,
    reviewer.currentTab,
    hrLr.currentTab,
    pathname
  );

  const { isFormDisabled, showAddCommentsSection } = getViewPermissionsByRole(
    pathname,
    reviewStatus,
    currentTab
  );

  return (
    <>
      <div className="w-full lg:w-4/5 lg:m-auto">
        <div className="mb-2 flex justify-between">
          <h3>Performance Criteria</h3>
          <Link
            as={NavLink}
            className="text-primary-400 cursor-pointer flex items-center justify-center underline"
            to={`help`}
          >
            <span>Help</span>
          </Link>
        </div>

        <PerformanceSection
          performance={performance}
          readOnly={isFormDisabled}
          errors={errors}
          performanceObj={performanceObj}
          setPerformanceObj={setPerformanceObj}
        />
        <AttendanceSection attendance={attendance} />
        <CommentsSection
          comments={comments}
          readOnly={isFormDisabled}
          errors={errors}
          editComment={editComment}
          setEditComment={setEditComment}
          showAddCommentsSection={showAddCommentsSection}
        />

        <div className="flex flex-col justify-start flex-wrap mt-6">
          <PerformanceCriteriaActions
            data={data}
            setErrors={setErrors}
            editComment={editComment}
            performanceObj={performanceObj}
            pathname={pathname}
            currentTab={currentTab}
          />
        </div>
      </div>
    </>
  );
};
