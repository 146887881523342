import React, { ReactNode } from 'react';
import { BsExclamationCircle, BsInfoCircle } from 'react-icons/bs';

interface VariantsTypes {
  info: (string | React.ReactElement | Element)[];
  alert: (string | React.ReactElement | Element)[];
}
const variants: VariantsTypes = {
  info: [
    'bg-blue-50 border-blue-300 text-blue-900',
    <BsInfoCircle key="1" size="1rem" />,
  ],
  alert: [
    'bg-yellow-50 border-yellow-500 text-yellow-900',
    <BsExclamationCircle key="1" size="1rem" />,
  ],
};
interface AlertProps {
  variant: keyof VariantsTypes;
  children?: React.ReactNode;
}

const Alert = ({ variant, children }: AlertProps) => {
  const alertClass: string = variants[variant][0] as string;
  return (
    <div
      role="alert"
      className={`rounded-md border flex items-center p-2 mb-3 ${alertClass} `}
    >
      <span className="pr-2" aria-label={variant}>
        {variants[variant][1] as ReactNode}
      </span>
      <span className="text-xs text-left">{children}</span>
    </div>
  );
};

export default Alert;
