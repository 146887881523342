import React from 'react';
import Page from 'components/Layouts/Page';
import { NavLink, useParams } from 'react-router-dom';
import DetailsSkeleton from 'components/Skeleton/DetailsSkeleton';
import Tabs from 'components/Tabs/Tabs';
import { PersonDetails } from 'features/people';
import { usePersonDetails } from 'features/people/api';
import useUserRoles from 'hooks/useUserRoles';
import { ROLES } from 'features/roles';
import SecurityRecords from 'features/security/components/Tabs/SecurityRecords';
import { ORG_TYPE } from 'features/org';
import { useEmployeeOrgs } from 'features/org/api';
import { History, HISTORY_ENTITY } from 'features/history';

const AssignSecurity: React.FC = () => {
  const { id = '' } = useParams<'id'>();
  const { data, status } = usePersonDetails(id.toString());

  const { data: locationList } = useEmployeeOrgs('me', {
    type: ORG_TYPE.LOCATION,
    sortBy: 'location',
    location: data?.payrollLocation ? data.payrollLocation : undefined,
  });

  const plant = locationList?.items[0]?.plant;
  const location = locationList?.items[0]?.description
    ? ` - ${locationList.items[0].description}`
    : '';

  const { roles } = useUserRoles();
  const tabLinks = [
    {
      label: `Security Records`,
      element: <SecurityRecords employeeData={data} />,
    },
  ];

  //only Super_Admin, Super_Payroll_Admin, and Payroll_Admin are able to view Security History
  if (
    roles.includes(ROLES.SUPER_ADMIN) ||
    roles.includes(ROLES.SUPER_PAYROLL_ADMIN) ||
    roles.includes(ROLES.PAYROLL_ADMIN)
  ) {
    tabLinks.push({
      label: `History`,
      element: (
        <History
          id={id}
          entity={HISTORY_ENTITY.USER_SECURITY}
          noRecordError="No security history records found for the selected employee"
        />
      ),
    });
  }

  return (
    <Page
      breadcrumbs={[
        <NavLink key="bc-1" to="/admin">
          Admin
        </NavLink>,
        <NavLink key="bc-2" to="/admin/security">
          Security
        </NavLink>,
      ]}
      heading="Assign Security Access"
    >
      <Page.Section>
        {status === 'pending' && <DetailsSkeleton hasAvatar />}
        {status === 'success' && (
          <PersonDetails
            person={data}
            payrollLocation={location}
            plant={plant}
          />
        )}
      </Page.Section>
      <Page.Section>
        <Tabs tabLinks={tabLinks} />
      </Page.Section>
    </Page>
  );
};

export default AssignSecurity;
