import { PerformanceCriteriaType } from '../types/employeeReviewDetails';

export const getPerformanceCriteriaTypeText = (
  type: PerformanceCriteriaType
) => {
  switch (type) {
    case PerformanceCriteriaType.DOES_NOT_MEET:
      return 'Does Not Meet';
    case PerformanceCriteriaType.MEETS:
      return 'Meets';
    case PerformanceCriteriaType.EXCEEDS:
      return 'Exceeds';
    default:
      return 'Choose';
  }
};
