import Page from 'components/Layouts/Page';
import { OrgInfoCard, ORG_TYPE } from 'features/org';
import { useOrgDetails } from 'features/org/api';
import { DepartmentDetails } from 'features/org/components';
import { NavLink, useParams } from 'react-router-dom';

const AddDepartmentCode = () => {
  //get id from params
  const { parentOrgId = '' } = useParams<'parentOrgId'>();

  const { data, status } = useOrgDetails(parentOrgId);

  return (
    <Page
      breadcrumbs={[
        <NavLink key="bc-1" to="/admin">
          Admin
        </NavLink>,
        <NavLink key="bc-2" to="/admin/org-structure/divisions">
          Org Structure Divisions
        </NavLink>,
        <NavLink
          key="bc-3"
          to={`/admin/org-structure/${parentOrgId}/departments`}
        >
          Org Structure Departments
        </NavLink>,
      ]}
      heading={'Add Department Code'}
    >
      <Page.Section>
        {status === 'success' && (
          <OrgInfoCard type={ORG_TYPE.DEPARTMENT} data={data} />
        )}
      </Page.Section>
      <Page.Section>
        <DepartmentDetails />
      </Page.Section>
    </Page>
  );
};

export default AddDepartmentCode;
