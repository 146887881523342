import {
  eachDayOfInterval,
  eachWeekendOfInterval,
  formatDuration,
  isAfter,
  isEqual,
  isSaturday,
  isSunday,
  isWithinInterval,
} from 'date-fns';

interface CountDaysOptions {
  start: Date;
  end: Date | null;
  holidays?: Date[];
}

function countDays({ start, end, holidays = [] }: CountDaysOptions) {
  let obj = {
    numberOfDays: {
      value: 0,
      formatted: '',
      weekends: {
        value: 0,
        formatted: '',
      },
      holidays: {
        value: 0,
        formatted: '',
      },
      excludeWeekends: {
        value: 0,
        formatted: '',
      },
      excludeHolidays: {
        value: 0,
        formatted: '',
      },
      excludeWeekendsandHolidays: {
        value: 0,
        formatted: '',
      },
    },
  };

  if (
    end &&
    (isAfter(new Date(end), new Date(start)) ||
      isEqual(new Date(end), new Date(start)))
  ) {
    const startDate = start;
    const endDate = end;

    const intervalOptions = { start: startDate, end: endDate };

    // Full array of days
    const dates = eachDayOfInterval(intervalOptions);

    // Full array of weekend days
    const weekends = eachWeekendOfInterval(intervalOptions);

    // Array of holidays in the array
    const holidaysInInterval = holidays
      .map((h) => isWithinInterval(h, intervalOptions))
      .filter((h) => h).length;

    //Week day holidays in the array
    const weekDayHolidaysInInterval = holidays
      .filter(
        (h) =>
          !isSaturday(h) && !isSunday(h) && isWithinInterval(h, intervalOptions)
      )
      .map((h) => isWithinInterval(h, intervalOptions)).length;

    // Number of days excluding weekends
    const excludeWeekends = dates.length - weekends.length;
    // Number of days excluding holidays
    const excludeHolidays = dates.length - holidaysInInterval;
    // Number of days excluding Holidays and Weekends
    const excludeWeekendsandHolidays =
      dates.length - weekends.length - weekDayHolidaysInInterval;

    obj = {
      numberOfDays: {
        value: dates.length,
        formatted: formatDuration({ days: dates.length }),
        weekends: {
          value: weekends.length,
          formatted: formatDuration({ days: weekends.length }),
        },
        holidays: {
          value: holidaysInInterval,
          formatted: formatDuration({ days: holidaysInInterval }),
        },
        excludeWeekends: {
          value: excludeWeekends,
          formatted: formatDuration({ days: excludeWeekends }),
        },
        excludeHolidays: {
          value: excludeHolidays,
          formatted: formatDuration({
            days: excludeHolidays,
          }),
        },
        excludeWeekendsandHolidays: {
          value: excludeWeekendsandHolidays,
          formatted: formatDuration({
            days: excludeWeekendsandHolidays,
          }),
        },
      },
    };
  }

  return obj;
}

export default function useCountDays(options: CountDaysOptions) {
  return countDays(options);
}
