import { Button } from '@uss/react-components';
import PayPeriodStartDateSelect from './PayPeriodStartDateSelect';
import { useEffect, useState } from 'react';
import LabeledText from 'components/Typography/LabeledText';
import { PayPeriod } from 'features/pay-periods';
import { BsInfoCircle } from 'react-icons/bs';
import {
  useChangeShiftAssignment,
  useShiftAssignment,
  useUndoShiftAssignment,
} from 'features/crew-maintenance/api/getShiftAssignment';
import {
  getChangeShiftAssignmentTo,
  getChangeUndoValidation,
  getInfoMessageByStatus,
} from 'features/crew-maintenance/utilities/shiftAssignmentCommon';
import {
  ShiftAssignment as ShiftAssignmentObj,
  ShiftAssignmentType,
} from 'features/crew-maintenance/types';
import { usePayPeriodsFilter } from 'features/crew-maintenance/utilities/usePayPeriodsFilter';
import {
  findClosedPendingShift,
  findFuturePendingShift,
} from '../../../../utilities/pendingShift';
import { PendingShiftAssignment } from './PendingShiftAssignment';
import InProgressCard from 'features/timecards/components/CopySchedule/Loaders/InProgressCard';

export const ShiftAssignment = ({
  id,
  payrollName,
  setWeek,
}: {
  id: string;
  payrollName: string;
  setWeek?: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const payPeriods = usePayPeriodsFilter(payrollName);
  const [selectedPayPeriod, setSelectedPayPeriod] = useState<PayPeriod | null>(
    null
  );
  const { data: shiftAssignmentData } = useShiftAssignment(id, {
    payPeriodId: selectedPayPeriod?.id,
  });
  const [notOpenShiftAssignment, setNotOpenShiftAssignment] =
    useState<ShiftAssignmentObj>({} as ShiftAssignmentObj);
  const [closedPendingShift, setClosedPendingShift] =
    useState<ShiftAssignmentObj>({} as ShiftAssignmentObj);
  const [futurePayPeriod, setFuturePayPeriod] = useState({} as PayPeriod);

  useEffect(() => {
    const openPendingShift =
      shiftAssignmentData &&
      findFuturePendingShift(shiftAssignmentData.items, new Date());
    const closedPendingShift =
      shiftAssignmentData &&
      findClosedPendingShift(shiftAssignmentData.items, new Date());
    if (openPendingShift) {
      setNotOpenShiftAssignment(openPendingShift);
      const findPayPeriod = payPeriods?.find((obj) => {
        return obj.id === openPendingShift.payPeriodId;
      });
      findPayPeriod && setFuturePayPeriod(findPayPeriod);
    }
    if (closedPendingShift) {
      setClosedPendingShift(closedPendingShift);
    }
  }, [payPeriods, shiftAssignmentData]);

  const { mutateAsync: changeShiftAssignment, isPending: changePending } =
    useChangeShiftAssignment(
      id,
      selectedPayPeriod?.status,
      selectedPayPeriod?.startDate
    );
  const { mutateAsync: undoShiftAssignment, isPending: undoPending } =
    useUndoShiftAssignment(id, selectedPayPeriod?.startDate);

  const handleUndo = async (shiftId: string) => {
    await undoShiftAssignment(shiftId ? shiftId : '');
    if (selectedPayPeriod?.status === 'not-open') {
      setNotOpenShiftAssignment({} as ShiftAssignmentObj);
    }
    if (selectedPayPeriod?.status === 'closed') {
      setClosedPendingShift({} as ShiftAssignmentObj);
    }
    if (
      selectedPayPeriod?.status === 'open' &&
      Object.keys(notOpenShiftAssignment).length > 0
    ) {
      setNotOpenShiftAssignment({} as ShiftAssignmentObj);
      setFuturePayPeriod({} as PayPeriod);
    }
  };

  const handleConfirmChange = async (
    selectedPayPeriod: PayPeriod,
    shift: ShiftAssignmentType
  ) => {
    await changeShiftAssignment({
      shift: getChangeShiftAssignmentTo(shift),
      payPeriodId: selectedPayPeriod.id,
    });
    if (
      selectedPayPeriod.status === 'open' &&
      Object.keys(notOpenShiftAssignment).length > 0
    ) {
      setNotOpenShiftAssignment({} as ShiftAssignmentObj);
      setFuturePayPeriod({} as PayPeriod);
    }
  };

  const handleStartDateChange = (payPeriod: PayPeriod) => {
    setSelectedPayPeriod(payPeriod);
    if (setWeek) setWeek(payPeriod.startDate);
  };

  const shiftAssignment = selectedPayPeriod && shiftAssignmentData?.items[0];

  const { isChangeDisabled, isUndoDisabled } = getChangeUndoValidation(
    selectedPayPeriod,
    shiftAssignment,
    notOpenShiftAssignment
  );

  if (changePending || undoPending) {
    return (
      <InProgressCard
        title="Shift Change in Progress!"
        description="Shift Change in Progress. Do not refresh the page."
      />
    );
  }

  return (
    <div className="bg-white pt-4 sm:bg-white rounded-md">
      <div className="w-full lg:w-4/5 lg:m-auto px-4 ">
        <div className="mb-4">
          <h2 className="font-semibold mb-4 text-base text-gray-700">
            Change Shift Assignment
          </h2>
        </div>
        <div className="md:grid md:grid-cols-2 gap-x-10 gap-y-6 w-full">
          <div className="mb-4">
            <span className="text-xs text-gray-600">
              Effective Pay Period Start Date
            </span>
            <PayPeriodStartDateSelect
              selectedValue={selectedPayPeriod}
              onSelect={handleStartDateChange}
              disabled={false}
              payPeriods={payPeriods}
            />
          </div>
        </div>
        {selectedPayPeriod && (
          <div
            className="flex flex-row w-full bg-blue-50 rounded-md px-4 py-3 h-auto border border-blue-300 shadow-md mb-4"
            data-testid="crew-details"
          >
            <div className="text-base">
              <BsInfoCircle className="text-blue-900" />
            </div>
            <div className="flex flex-row mb-2" data-testid="info-msg">
              <span className="ml-2 text-sm text-blue-900">
                {getInfoMessageByStatus(selectedPayPeriod.status)}
              </span>
            </div>
          </div>
        )}
        <div className="md:grid md:grid-cols-2 gap-x-10 w-full">
          <div>
            <LabeledText
              label="Change Shift Assignment From"
              className="w-full md:w-7/12 mb-2 pr-4 md:mb-0"
            >
              {shiftAssignment?.shift}
            </LabeledText>
          </div>
          <div>
            <LabeledText
              label="Change Shift Assignment To"
              className="w-full md:w-7/12 mb-2 pr-4 md:mb-0"
            >
              {shiftAssignment &&
                getChangeShiftAssignmentTo(shiftAssignment.shift)}
            </LabeledText>
          </div>
          {selectedPayPeriod === null ||
          selectedPayPeriod.status === 'not-open' ? (
            <PendingShiftAssignment
              payPeriod={futurePayPeriod}
              pendingShiftAssignment={notOpenShiftAssignment}
            />
          ) : (
            <PendingShiftAssignment
              payPeriod={selectedPayPeriod}
              pendingShiftAssignment={closedPendingShift}
            />
          )}
        </div>
        <div className="flex flex-wrap mt-4 pb-4">
          <Button
            onClick={() =>
              shiftAssignmentData &&
              selectedPayPeriod &&
              handleConfirmChange(
                selectedPayPeriod,
                shiftAssignmentData.items[0].shift
              )
            }
            variant="primary"
            className="ml-0 mb-2 sm:mb-0 text-sm w-full sm:w-auto"
            type="submit"
            data-testid="change-button"
            disabled={isChangeDisabled}
          >
            Change
          </Button>
          <Button
            onClick={() =>
              shiftAssignmentData && handleUndo(shiftAssignmentData.items[0].id)
            }
            variant="outline"
            className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
            type="button"
            data-testid="undo-button"
            disabled={isUndoDisabled}
          >
            Undo
          </Button>
        </div>
      </div>
    </div>
  );
};
