import React from 'react';
import clsx from 'clsx';
import Card from 'components/Card/Card';
import Loader from './Loader';
import { times } from 'lodash';

type Variants = 'col-grid' | 'row-grid';
interface CardSkeletonProps {
  hasAvatar?: boolean;
  hasGradientBG?: boolean;
  contentRows?: number;
  variant?: Variants;
}

const CardSkeleton = ({
  hasAvatar,
  hasGradientBG,
  contentRows = 2,
  variant = 'col-grid',
}: CardSkeletonProps) => {
  return (
    <>
      {variant === 'col-grid' ? (
        <Card>
          <Card.Header
            headerbgvariant={hasGradientBG ? 'lightbluewhite' : 'white'}
          >
            <div className="flex items-center w-full">
              {hasAvatar && (
                <div>
                  <Loader avatarType />
                </div>
              )}
              <div className={clsx('w-full', hasAvatar ? 'ml-3' : '')}>
                <p>
                  <Loader rows={2} />
                </p>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            {times(contentRows, (i) => (
              <div
                key={`content-row-${i}`}
                className="flex flex-wrap items-center justify-between w-full mb-4"
              >
                <span className="w-full">
                  <Loader rows={2} />
                </span>
              </div>
            ))}
          </Card.Body>
        </Card>
      ) : (
        <Card>
          <Card.Body>
            {times(contentRows, (i) => (
              <div
                key={`content-row-${i}`}
                className="flex flex-wrap items-center justify-between w-full last-of-type:mb-0 mb-4"
              >
                <span className="w-full">
                  <Loader rows={1} />
                </span>
              </div>
            ))}
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default CardSkeleton;
