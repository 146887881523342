import { Select } from '@uss/react-components';
import { Org, ORG_TYPE } from 'features/org';
import { useCrewListOrgs } from 'features/org/api';
import { Person } from 'features/people';
import { formatCrewKey } from 'utils/formatCrewKey';

interface CrewSelectProps {
  selectedCrew: Org | null;
  setSelectedCrew: (crew: Org) => void;
  employeeCrewIds: string[] | undefined;
  employee: Person | undefined;
}
export const CrewSelectFilter = ({
  selectedCrew,
  setSelectedCrew,
  employeeCrewIds,
  employee,
}: CrewSelectProps) => {
  const { data: crews, status: crewsStatus } = useCrewListOrgs(
    'me',
    employeeCrewIds,
    employee,
    {
      type: ORG_TYPE.CREW,
      sortBy: 'code',
    }
  );

  return (
    <Select
      selected={selectedCrew}
      onSelect={(crew: Org) => setSelectedCrew(crew)}
    >
      <Select.Button
        label={
          selectedCrew
            ? formatCrewKey(
                selectedCrew.location,
                selectedCrew.division,
                selectedCrew.department,
                selectedCrew.crew
              )
            : 'Loc - Div - Dept - Crew'
        }
      />
      <Select.Options>
        {crewsStatus === 'success' && crews.items.length !== 0 ? (
          crews.items.map((item: Org, index: number) => (
            <Select.Option truncate key={index + 1} value={item}>
              <span>
                {formatCrewKey(
                  item.location,
                  item.division,
                  item.department,
                  item.crew
                )}
              </span>
            </Select.Option>
          ))
        ) : (
          <span>No Crews available</span>
        )}
      </Select.Options>
    </Select>
  );
};
