import { format, parseISO } from 'date-fns';
import Badge from 'components/Badge/Badge';
import { getColors } from 'features/paid-time-off/utility/ptoStatusColor';
import { getPtoStatusText } from 'features/paid-time-off/utility/getPtoStatusText';

import Card from 'components/Card/Card';
import { parse } from 'date-fns';
import { PtoDetail } from 'features/paid-time-off/types';
import { usePersonDetails } from 'features/people/api';
import { useParams } from 'react-router-dom';

export const PtoCard = ({ record }: { record: PtoDetail }) => {
  const takenYear = parse(
    record.startDate,
    'yyyy-MM-dd',
    new Date()
  ).getFullYear();
  const { id } = useParams<'id'>();
  const { data: employeeData } = usePersonDetails(id ?? 'me');

  return (
    <Card>
      <Card.Header>
        <div
          className="text-xl font-semibold text-gray-700 p-1"
          aria-label="PTO Date"
        >
          {format(parseISO(record.startDate), 'PP')}
        </div>
        <div className="flex items-center justify-center gap-2 py-1">
          <Badge variant={getColors(record.status)}>
            <span aria-label="PTO Status">
              {getPtoStatusText(record.status)} {record.units}
              {record.ptoClass.unitOfMeasure === 'days' ? 'd' : 'h'}
            </span>
          </Badge>
        </div>
      </Card.Header>

      <Card.Body>
        <div className="flex justify-start items-start p-1 gap-4">
          <div className="flex flex-col w-3/5">
            <span className="text-xs text-gray-700 " id="paycode-desc">
              Pay Code
            </span>
            <span
              className="text-sm font-semibold text-gray-900 line-clamp-1"
              aria-labelledby="paycode-desc"
            >
              {record.paycode} - {record.paycodeDescription}
            </span>
          </div>
          {takenYear !== record.chargeYear &&
            record.status !== 'blackout' &&
            employeeData?.plantType !== 'mini_mill' && (
              <div className="flex flex-col w-2/5">
                <span className="text-xs text-gray-700 " id="charge-year">
                  Charge Year
                </span>
                <span
                  className="text-sm text-gray-900 font-semibold"
                  aria-labelledby="charge-year"
                >
                  {record.chargeYear ? record.chargeYear : '-'}
                </span>
              </div>
            )}
        </div>
      </Card.Body>
    </Card>
  );
};
