import React, { useEffect, useReducer, useState } from 'react';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { Button } from '@uss/react-components';
import Page from 'components/Layouts/Page';
import useModal from 'components/Modals/use-modal';
import CopyEmployeeModal from 'features/timecards/components/CopySchedule/CopyEmployeeModal';
import CopyFromSection from 'features/timecards/components/CopySchedule/CopyFromSection';
import CopyToSection from 'features/timecards/components/CopySchedule/CopyToSection';
import { copyParams } from 'features/timecards/utilities/copyParams';
import { useCopyScheduleDifferentEmp } from 'features/timecards/api/copySchedule';
import {
  CopyParamActions,
  CopyScheduleDifferentEmpPayload,
  CopyType,
  StartDateErrorType,
} from 'features/timecards/types';
import useNotification from 'components/Notification/useNotification';
import { Person } from 'features/people/types';
import { usePersonDetails } from 'features/people/api';
import { parseISO } from 'date-fns';
import { useEmployeeCrews, UseEmployeeCrewsOptions } from 'features/org/api';

import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import { formatCrewKeyFromCode } from 'utils/formatCrewKey';
import CopyScheduleLoader from 'features/timecards/components/CopySchedule/Loaders/CopyScheduleLoader';
import InProgressCard from 'features/timecards/components/CopySchedule/Loaders/InProgressCard';
import Error from 'components/Error/Error';
import { Org } from 'features/org/types';
const CopyEmployeeSchedule = () => {
  // Getting id from the URL
  const { ussId: id = '' } = useParams<'ussId'>();
  const breadCrumbNodes = [
    <NavLink key="bc-1" to="/scheduling">
      Scheduling
    </NavLink>,
    <NavLink key="bc-2" to={`/non-union-scheduling/crew-listing`}>
      Schedules (NU)
    </NavLink>,
    <NavLink key="bc-3" to={`/non-union-scheduling/employee/${id}`}>
      Scheduling Details
    </NavLink>,
  ];

  const nonUnionState = useNonUnionStore();
  const week = nonUnionState.params.week;
  const crew = nonUnionState.crew?.id;

  const [selectedOption, setSelectedOption] = useState<Person | null>(null);
  const [weekRangeChecked, setWeekRangeChecked] = useState<{
    from: boolean;
    to: boolean;
  }>({ from: false, to: false });
  const { data } = usePersonDetails(id.toString());

  const [errorState, setErrorState] = useState<StartDateErrorType>({
    source: false,
    target: false,
  });

  const getWeekPayload = (
    date: string | undefined
  ): UseEmployeeCrewsOptions | undefined => {
    if (date) {
      return { week: date };
    } else return undefined;
  };

  const { data: crewSource, status: loadingStatus } = useEmployeeCrews(id, {
    week: week ?? '',
  });

  const initialCopyPayload: CopyScheduleDifferentEmpPayload = {
    source: {
      crewId: crew ?? '',
      firstWeek: !weekRangeChecked.from && !!week ? week : '',
    },
    target: {
      crewId: '',
      ussId: selectedOption?.ussId.toString() ?? '',
    },
  };

  const [state, dispatch] = useReducer(copyParams, initialCopyPayload);

  const { data: crewList, status: crewLoadingStatus } = useEmployeeCrews(
    selectedOption?.ussId.toString() ?? id,
    getWeekPayload(state.target.firstWeek)
  );

  const [crewSelected, setCrewSelected] = useState<Org | null>(
    crewList?.items[0] ? crewList.items[0] : null
  );

  const CopyForDifferentEmp = useCopyScheduleDifferentEmp({
    ussId: id,
  });

  const isCopying = CopyForDifferentEmp.isPending;

  const navigate = useNavigate();

  const notify = useNotification();

  useEffect(() => {
    if (
      crewLoadingStatus === 'success' &&
      crewList.items.length > 0 &&
      crewSelected?.id !== crewList.items[0]?.id
    ) {
      setCrewSelected(crewList.items[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crewLoadingStatus, crewList]);

  useEffect(() => {
    if (!weekRangeChecked.from && !!week) {
      dispatch({ type: CopyParamActions.DELETE_FROM_END_WEEK });
      dispatch({ type: CopyParamActions.FROM_START_WEEK, payload: week });
    } else if (!weekRangeChecked.to) {
      dispatch({ type: CopyParamActions.DELETE_TO_END_WEEK });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekRangeChecked.from, weekRangeChecked.to]);

  const modal = useModal();

  const handleCopyConfirm = async ({ copyType }: { copyType: CopyType }) => {
    const onAPICall = {
      onSuccess: () => {
        navigate(-1);
        notify.success({
          message: 'Schedule(s) successfully copied',
        });
      },
    };

    if (copyType === CopyType.SAME_EMPLOYEE) {
      state.target.ussId = id;
      state.target.crewId = crew ?? '';
    }
    await CopyForDifferentEmp.mutateAsync(
      {
        source: state.source,
        target: state.target,
      },
      onAPICall
    );
  };

  const sourceCrew = crewSource?.items.find((c) => c.id === crew);

  const sourceCrewKey = sourceCrew
    ? formatCrewKeyFromCode(sourceCrew.code)
    : 'No Crews Available';

  const targetCrew = crewList?.items.find((c) => c.id === crewSelected?.id);
  const targetCrewKey = targetCrew
    ? formatCrewKeyFromCode(targetCrew.code)
    : 'No Crews Available';

  const handleCopyCLick = () => {
    if (selectedOption?.ussId && crewSelected?.id) {
      state.target.ussId = selectedOption.ussId.toString();
      state.target.crewId = crewSelected.id;
    } else state.target.ussId = '';

    const errors: StartDateErrorType = {
      source: state.source.firstWeek === '',
      target: state.target.firstWeek === undefined,
    };

    setErrorState(errors);

    if (errors.source || errors.target) {
      return;
    } else
      modal.openModal({
        title: 'Copy Schedule',
        type: 'danger',
        children: (
          <CopyEmployeeModal
            copyForSameEmployee={false}
            targetCrewKey={targetCrewKey}
            fromPerson={data}
            toPerson={selectedOption}
            sourceCrewKey={sourceCrewKey}
            fromStartDate={
              state.source.firstWeek
                ? parseISO(state.source.firstWeek)
                : undefined
            }
            toStartDate={
              state.target.firstWeek
                ? parseISO(state.target.firstWeek)
                : undefined
            }
            toEndDate={
              state.target.lastWeek
                ? parseISO(state.target.lastWeek)
                : undefined
            }
            fromEndDate={
              state.source.lastWeek
                ? parseISO(state.source.lastWeek)
                : undefined
            }
          />
        ),
        labels: { cancel: 'Cancel', confirm: 'Confirm Copy' },

        onConfirm: async () => {
          modal.closeModal();
          await handleCopyConfirm({
            copyType: CopyType.DIFFERENT_EMPLOYEE,
          });
        },
        onCancel: () => modal.closeModal(),
      });
  };

  const breadcrumbClick = () => navigate(-1);

  return (
    <Page breadcrumbs={breadCrumbNodes} heading={'Copy Schedule'}>
      <Page.Section>
        {isCopying && (
          <InProgressCard
            title="Copying in progress!"
            description="Schedule copy is in progress. Do not refresh the page."
          />
        )}
        {loadingStatus === 'error' && <Error />}
        {loadingStatus === 'pending' && <CopyScheduleLoader />}
        {loadingStatus === 'success' && !isCopying && (
          <div
            className="grid md:grid-cols-2 gap-4  "
            data-testid="copy-section"
          >
            <CopyFromSection
              id={id}
              setWeekRangeChecked={setWeekRangeChecked}
              weekRangeChecked={weekRangeChecked}
              data={data}
              sourceCrewKey={sourceCrewKey}
              weeksState={state}
              updateWeekState={dispatch}
              errorState={errorState}
              updateErrorState={setErrorState}
              isMySchedule={false}
              startDayOfWeek={sourceCrew?.weekBeginDayIndex}
            />
            <CopyToSection
              errorState={errorState}
              setWeekRangeChecked={setWeekRangeChecked}
              weekRangeChecked={weekRangeChecked}
              sourceCrewKey={sourceCrewKey}
              weeksState={state}
              crewList={crewList}
              crewLoadingStatus={crewLoadingStatus}
              crewSelected={crewSelected}
              selectedOption={selectedOption}
              setCrewSelected={setCrewSelected}
              setSelectedOption={setSelectedOption}
              updateWeekState={dispatch}
              week={week}
              updateErrorState={setErrorState}
              isMySchedule={false}
              startDayOfWeek={targetCrew?.weekBeginDayIndex}
            />
          </div>
        )}

        <div
          className="flex flex-col sm:flex-row md:flex-row xl:flex-row 2xl:flex-row gap-2 justify-end my-4"
          data-testid="copy-actions"
        >
          <Button onClick={breadcrumbClick} variant="outline">
            Cancel
          </Button>
          <Button
            onClick={handleCopyCLick}
            disabled={isCopying || loadingStatus === 'pending'}
            variant="primary"
          >
            Copy
          </Button>
        </div>
      </Page.Section>
    </Page>
  );
};
export default CopyEmployeeSchedule;
