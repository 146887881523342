import { PtoSummary } from 'features/paid-time-off/types';
import React from 'react';
import TotalsValue from './TotalsValue';
import { SplitTotalValues } from './SplitTotalValues';

const MiniMillSummary = ({ summary }: { summary: PtoSummary }) => {
  return (
    <div className="lg:grid lg:grid-flow-col lg:gap-4 w-full ">
      <div className="lg:col-span-3 lg:col-start-1 ">
        <div className="flex flex-grow justify-start items-center border-b-4 lg:border-b-0 lg:border-r-4 border-gray-100 pb-3 lg:pb-0 mb-3 lg:mb-0 gap-4">
          <div className="w-1/4">
            <TotalsValue
              label="Eligible"
              value={summary.totalEligible.total}
              unitOfMeasure="hours"
            />
          </div>
          <div className="lg:flex-grow w-3/4 lg:w-1/5 lg:px-8">
            <SplitTotalValues
              options={{
                firstValue: {
                  value: summary.totalEligible.requestedYear,
                  label: 'Current Year',
                  unitOfMeasure: 'hours',
                },
                secondValue: {
                  value: summary.totalEligible.rollOver,
                  label: 'Rollover',
                  unitOfMeasure: 'hours',
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-6 gap-4 lg:col-span-9 lg:col-start-4 ">
        <TotalsValue
          label="Taken"
          value={summary.taken.total}
          unitOfMeasure="hours"
        />
        <TotalsValue
          label="Approved"
          value={summary.approved.total}
          unitOfMeasure="hours"
        />
        <TotalsValue
          label="Requested"
          value={summary.requested.total}
          unitOfMeasure="hours"
        />
        <TotalsValue
          label="Adjustment"
          value={summary.adjustment.total}
          unitOfMeasure="hours"
        />
        <TotalsValue
          label="Sold Back"
          value={summary.soldBack}
          unitOfMeasure="hours"
        />

        {/* <Divider /> */}
        <div className="col-span-2 lg:col-span-1">
          <TotalsValue
            label="Remaining"
            value={summary.remaining}
            unitOfMeasure="hours"
            divider
          />
        </div>
      </div>
    </div>
  );
};

export default MiniMillSummary;
