import { useProbationaryEmployeesSummary } from 'features/probationary-tracking/api/getProbEmployeesSummary';
import EmployeesSummary from '../common/EmployeesSummary/EmployeesSummary';
import useWindowSize from 'hooks/useWindowSize';

const ProbationaryEmployeesSummary = () => {
  const { data, status } = useProbationaryEmployeesSummary();
  const { width } = useWindowSize();

  return (
    <EmployeesSummary data={data?.reviewer} status={status}>
      <EmployeesSummary.Assigned
        customLabel={
          !!width && width > 768 ? 'Assigned Employees' : 'Assigned Emp.'
        }
        width="w-1/3 sm:w-1/5"
      />
      <EmployeesSummary.ReviewDue width="w-1/3 sm:w-1/5" />
      <EmployeesSummary.Revaluate width="w-1/3 sm:w-1/5" />
      <EmployeesSummary.Counseling width="w-1/4 sm:w-1/5" />
      <EmployeesSummary.FinalReview width="sm:w-1/5" />
    </EmployeesSummary>
  );
};

export default ProbationaryEmployeesSummary;
