import { PaycodeType } from '../types';
import { PAYCODE_TYPE } from '../constants';

const getPaycodeTypeText = (value: PaycodeType) => {
  switch (value) {
    case PAYCODE_TYPE.ABSENTEE:
      return 'Absentee';
    case PAYCODE_TYPE.OVERTIME:
      return 'Overtime';
    default:
      return 'Other';
  }
};
export default getPaycodeTypeText;
