import { StateCreator } from 'zustand';
import { ProbationaryEmployeesStore } from '../store';

export interface BargainingUnitTotalSliceType {
  search?: string;
  setSearch: (value: string) => void;
  page?: string;
  pageSize?: string;
  setPage: (value: number) => void;
  setPageSize: (value: number) => void;
  clearSearch: () => void;
}

export const BargainingUnitTotalSlice: StateCreator<
  ProbationaryEmployeesStore,
  [],
  [],
  BargainingUnitTotalSliceType
> = (...args) => {
  const [set] = args;
  return {
    page: '1',
    pageSize: '9',
    setSearch: (value) =>
      set((state) => {
        if (value.length > 0) {
          state.bargainingUnitTotal.search = value;
        } else {
          delete state.bargainingUnitTotal.search;
        }
        state.bargainingUnitTotal.page = '1';
        return state;
      }),
    setPage: (value) =>
      set((state) => {
        state.bargainingUnitTotal.page = value.toString();
        return state;
      }),
    setPageSize: (value) =>
      set((state) => {
        state.bargainingUnitTotal.pageSize = value.toString();
        state.bargainingUnitTotal.page = '1';
        return state;
      }),
    clearSearch: () =>
      set((state) => {
        delete state.bargainingUnitTotal.search;
        state.bargainingUnitTotal.page = '1';
        return state;
      }),
  };
};
