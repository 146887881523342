import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { ModelSchedTurns, ModelWeeks } from '../types';
export interface ScheduleTurn {
  scheduleTurn: ModelSchedTurns;
  weekNumber: number;
  crewRotation: string;
}
interface AddWeekStore {
  addWeek: ModelWeeks | null;

  UpdateAddWeek: (week: ModelWeeks | null) => void;
}
export const useAddWeekStore = create<AddWeekStore>()(
  devtools(
    immer((set, get) => ({
      addWeek: null,

      UpdateAddWeek: (value) =>
        set(
          (state) => {
            state.addWeek = value;
          },
          false,
          'update-add-weeks'
        ),
    }))
  )
);
