import React from 'react';
import CardList from 'components/Card/CardList';
import CardSkeleton from 'components/Skeleton/CardSkeleton';
import { PtoDetail } from 'features/paid-time-off';
import { PtoCard } from 'features/paid-time-off/components/Cards/PtoCard';
import { Link } from '@uss/react-components';
import { NavLink } from 'react-router-dom';
import NoResults from 'components/NoResults/NoResults';

export function PTOCardList({
  records,
  loading = false,
  links = false,
}: {
  records?: PtoDetail[];
  loading?: boolean;
  links?: boolean;
}) {
  if (loading)
    return (
      <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4 py-4">
        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((_items, index) => (
          <CardSkeleton key={index} />
        ))}
      </div>
    );
  if (!records) return <NoResults width={'300'} height={'300'} />;
  return (
    <CardList<PtoDetail>
      items={records}
      renderItem={(record) => {
        if (links) {
          return <PTOLinkCard record={record} />;
        } else {
          return <PtoCard record={record} />;
        }
      }}
    />
  );
}

const PTOLinkCard = ({ record }: { record: PtoDetail }) => {
  return (
    <Link
      as={NavLink}
      to={`${record.pkWfmEmpPtoDetailsId}`}
      className="block cursor-pointer"
      key={record.pkWfmEmpPtoDetailsId}
    >
      <PtoCard record={record} />
    </Link>
  );
};
