import React from 'react';
import Page from 'components/Layouts/Page';
import { SearchInput } from '@uss/react-components';
import Pagination from 'components/Pagination/Pagination';
import { SortDirection } from 'types/common';
import Sort from 'components/Sort/Sort';
import CardsLoader from 'components/Loaders/CardsLoader';
import {
  PEOPLE_SORT,
  PeopleSort,
  Person,
  PersonCardSecurity,
} from 'features/people';
import { usePeople } from 'features/people/api';
import CardList from 'components/Card/CardList';
import { ORG_TYPE } from 'features/org';
import { useEmployeeOrgs } from 'features/org/api';
import { useSecurityStore } from 'features/security/state/store';
import OrgFilters from 'components/OrgFilters/OrgFilters';
import { findLocation, findPlant } from 'features/org/utilities/findOrg';
import { NavLink } from 'react-router-dom';

const Security = () => {
  const store = useSecurityStore();

  const handleClose = () => {
    store.clearSearch();
  };

  const { data: plantList, status: plantStatus } = useEmployeeOrgs('me', {
    type: ORG_TYPE.PLANT,
    sortBy: 'plant',
    parentOrgs: 'y',
  });

  const { data: locationList, status: locationStatus } = useEmployeeOrgs('me', {
    type: ORG_TYPE.LOCATION,
    sortBy: 'location',
    parentOrgId:
      !store.params.plant || store.selectedPlant === 'all'
        ? undefined
        : store.selectedPlant?.id,
    parentOrgs: 'y',
  });

  const { data, status } = usePeople(store.params, {
    enabled: plantStatus === 'success' && locationStatus === 'success',
  });

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    store.setSearch(e.target.value);
  };

  // React.useEffect(() => {
  //   if (plantStatus === 'success') {
  //     if (!store.params.plant && store.selectedPlant !== 'all') {
  //       store.setPlant(plantList.items[0]);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [plantStatus, plantList]);

  const sortOptions: {
    label: string;
    name: PeopleSort;
    direction: SortDirection;
  }[] = [
    {
      label: 'Recently Updated',
      name: PEOPLE_SORT.RECENTLYUPDATED,
      direction: SortDirection.DESC,
    },
    {
      label: 'First Name',
      name: PEOPLE_SORT.FIRSTNAME,
      direction: SortDirection.ASC,
    },
    {
      label: 'First Name',
      name: PEOPLE_SORT.FIRSTNAME,
      direction: SortDirection.DESC,
    },
    {
      label: 'Last Name',
      name: PEOPLE_SORT.LASTNAME,
      direction: SortDirection.ASC,
    },
    {
      label: 'Last Name',
      name: PEOPLE_SORT.LASTNAME,
      direction: SortDirection.DESC,
    },
    {
      label: 'Hr Location',
      name: PEOPLE_SORT.HRLOCATION,
      direction: SortDirection.ASC,
    },
    {
      label: 'Hr Location',
      name: PEOPLE_SORT.HRLOCATION,
      direction: SortDirection.DESC,
    },
    {
      label: 'Payroll Location',
      name: PEOPLE_SORT.PAYROLLLOCATION,
      direction: SortDirection.ASC,
    },
    {
      label: 'Payroll Location',
      name: PEOPLE_SORT.PAYROLLLOCATION,
      direction: SortDirection.DESC,
    },
  ];

  return (
    <Page
      heading="Security"
      breadcrumbs={[
        <NavLink key="bc-1" to="/admin">
          Admin
        </NavLink>,
      ]}
    >
      <Page.Section bg="gray">
        <div className="flex sm:flex-row flex-col justify-between flex-wrap">
          <div className="w-full sm:w-10/12">
            <div className="w-full mb-2 sm:mb-0 sm:w-4/12 ">
              <SearchInput
                id="search-input"
                label="search"
                placeholder="Search Name / USS ID"
                autoCompleteFlag={store.params.search ? true : false}
                selectedFlag={store.params.search ? true : false}
                value={store.params.search ?? ''}
                onChange={handleSearchChange}
                onClose={handleClose}
              />
            </div>
          </div>
          <div className="flex items-center justify-end w-full sm:w-1/6">
            <Sort<PeopleSort>
              options={sortOptions}
              selectedOption={
                sortOptions.filter(
                  (value) =>
                    store.params.sortBy === value.name &&
                    store.params.sortType === value.direction
                )[0]
              }
              onChange={(value) =>
                store.setSort({
                  type: value.name,
                  direction: value.direction,
                })
              }
            />
          </div>
        </div>
        <div className="mt-2 sm:mt-4 ">
          <OrgFilters
            plants={plantList?.items ? plantList.items : []}
            selectedOrgs={{
              selectedPlant: store.selectedPlant,
              selectedPayrollLocation: store.selectedLocation,
            }}
            orgs={locationList?.items ? locationList.items : []}
            status={locationStatus}
            plantStatus={plantStatus}
          >
            <OrgFilters.Plant
              selectedItem={store.selectedPlant}
              handleSelect={store.setPlant}
              showSelectAll={true}
            />
            <OrgFilters.Location
              selectedItem={store.selectedLocation}
              handleSelect={store.setPayrollLocations}
              showSelectAll={true}
            />
          </OrgFilters>
        </div>
      </Page.Section>
      <Page.Section>
        {status === 'pending' && <CardsLoader count={9} hasAvatar />}
        {status === 'success' && (
          <CardList<Person>
            items={data.items}
            renderItem={(employee) => (
              <PersonCardSecurity
                person={employee}
                payrollLocation={findLocation(
                  employee.payrollLocation,
                  locationList?.items
                )}
                plant={findPlant(employee.payrollLocation, locationList?.items)}
              />
            )}
          />
        )}
      </Page.Section>
      <Page.Section bg="gray">
        {!!data?.totalCount && (
          <Pagination
            pageOptions={[9, 18, 27, 36, 45, 54]}
            totalCount={data.totalCount}
            page={data.page}
            pageSize={data.pageSize}
            onPageChange={(s) => store.setPage(s.selected + 1)}
            onPageSizeChange={(ps) => store.setPageSize(ps)}
          />
        )}
      </Page.Section>
    </Page>
  );
};

export default Security;
