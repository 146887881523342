import { useProbationaryEmployeesSummary } from 'features/probationary-tracking/api/getProbEmployeesSummary';
import EmployeesSummary from '../common/EmployeesSummary/EmployeesSummary';

const ProbationaryEmployeesSummary = () => {
  const { data, status } = useProbationaryEmployeesSummary();
  return (
    <EmployeesSummary data={data?.supervisor} status={status}>
      <EmployeesSummary.TotalEmployees width="w-[36%] sm:w-1/5" />
      <EmployeesSummary.Unassigned width="w-[32%] sm:w-1/5" />
      <EmployeesSummary.Assigned width="w-[32%] sm:w-1/5" />
      <EmployeesSummary.ReviewPerformance width="sm:w-1/5" />
      <EmployeesSummary.Termination width="sm:w-1/5" />
    </EmployeesSummary>
  );
};

export default ProbationaryEmployeesSummary;
