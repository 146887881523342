import { Accordion, Avatar } from '@uss/react-components';
import useWindowSize from 'hooks/useWindowSize';
import { getInitials, avatarColor } from 'utils';
import SeparatedLabels from 'components/SeparatedLabels/SeparatedLabels';
import { Person } from 'features/people/types';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';

interface EmployeeCardProps {
  person?: Person;
  payrollLocation: string;
  plant?: string;
}
export const PersonDetails = ({
  person,
  payrollLocation,
  plant,
}: EmployeeCardProps) => {
  const { width } = useWindowSize();
  const DetailsHeader = () => {
    const name =
      person?.lastName && person.firstName
        ? `${person.lastName}, ${person.firstName}`
        : 'Invalid Name';

    return (
      <div className="w-full lg:w-2/6 2xl:w-1/6 ">
        <div className="flex items-center">
          <span>
            <Avatar
              color={avatarColor(person !== undefined ? person.ussId : 1)}
              size="medium"
            >
              {getInitials(person?.firstName, person?.lastName)}
            </Avatar>
          </span>
          <div className="ml-4">
            <Title as="h2">{name}</Title>
            <SeparatedLabels
              items={[
                {
                  label: 'Employee Type',
                  value: person?.unionInd === 'y' ? 'Union' : 'Non Union',
                },
                {
                  label: 'USS ID',
                  value: person?.ussId,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  };
  const DetailsBody = () => {
    return (
      <>
        <div className="flex flex-wrap sm:flex-nowrap w-full lg:w-4/5">
          <LabeledText
            label="Plant"
            className="w-1/2 sm:w-auto sm:flex-grow mb-4 sm:mb-0"
          >
            {plant ? plant : '-'}
          </LabeledText>

          <LabeledText
            label="Payroll Location"
            className="w-1/2 sm:w-auto sm:flex-grow mb-4 sm:mb-0"
          >
            {person?.payrollLocation
              ? `${person.payrollLocation}  ${payrollLocation}`
              : ''}
          </LabeledText>
          <LabeledText
            label="Job Title"
            className="w-1/2 sm:w-auto sm:flex-grow mb-4 sm:mb-0"
          >
            {person?.jobTitle}
          </LabeledText>
        </div>
      </>
    );
  };
  return (
    <>
      {width &&
        (width < 1024 ? (
          <Accordion
            variant="default"
            className="shadow-md bg-white rounded-lg border border-blue-600"
          >
            <Accordion.Button variant="default">
              <DetailsHeader />
            </Accordion.Button>
            <Accordion.Panel variant="default">
              <div className="flex flex-row flex-wrap w-full items-center border-t border-gray-200 pt-4">
                <DetailsBody />
              </div>
            </Accordion.Panel>
          </Accordion>
        ) : (
          <div className="flex items-center shadow-md w-full p-4 bg-white rounded-lg h-24 border border-blue-600">
            <DetailsHeader />
            <div className="bg-gray-200 w-0.5 h-full mx-6"></div>
            <DetailsBody />
          </div>
        ))}
    </>
  );
};
