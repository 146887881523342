import { StateCreator } from 'zustand';
import { ProbationaryEmployeesStore } from '../store';
import { UnAssignedTabSlice, UnassignedSlice } from './unassigned';
import { AssignedTabSlice, AssignedSlice } from './assigned';
import {
  ReviewPerformanceTabSlice,
  ReviewPerformanceSlice,
} from './review-performance';
import { TerminationTabSlice, TerminationSlice } from './termination';
import { AreaManagerTabs } from 'features/probationary-tracking/types';

interface TabSpecificState {
  unassigned: UnassignedSlice;
  assigned: AssignedSlice;
  reviewPerformance: ReviewPerformanceSlice;
  termination: TerminationSlice;
}
export interface AreaManagerSliceType extends TabSpecificState {
  currentTab?: AreaManagerTabs;
  setCurrentTab: (value: AreaManagerTabs) => void;
}

export const AreaManagerSlice: StateCreator<
  ProbationaryEmployeesStore,
  [],
  [],
  AreaManagerSliceType
> = (...args) => {
  const [set] = args;
  return {
    setCurrentTab: (value) =>
      set(({ areaManager }) => ({
        areaManager: { ...areaManager, currentTab: value },
      })),
    unassigned: { ...UnAssignedTabSlice(...args) },
    assigned: { ...AssignedTabSlice(...args) },
    reviewPerformance: { ...ReviewPerformanceTabSlice(...args) },
    termination: { ...TerminationTabSlice(...args) },
  };
};
