import Page from 'components/Layouts/Page';
import React from 'react';

const PTOOverview = () => {
  return (
    <Page heading="PTO - Vacation">
      <Page.Section padding>
        <div className="bg-white p-4 rounded-md">
          <p>
            This module provides supervisors with the ability to manage their
            crews’ PTO. They are able to view crew PTO using a calendar view for
            a given month. They are also able to respond to PTO requests.
          </p>
          <br></br>
          <p> Crew members are able to view their taken and planned PTO.</p>
        </div>
      </Page.Section>
    </Page>
  );
};

export default PTOOverview;
