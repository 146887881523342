import useModal from 'components/Modals/use-modal';
import useNotification from 'components/Notification/useNotification';
import { useAddOrg, useOrgDetails, useOrgChildren } from 'features/org/api';
import {
  OrgPost,
  OrgPostSchema,
  OrgFormType,
  ORG_TYPE,
  descriptionSchema,
} from 'features/org';
import { OrgAction, OrgActionButton, OrgForm } from 'features/org/components';
import { updateOrgData } from 'features/org/utilities/updateOrgData';
import { ROLES } from 'features/roles';
import useUserRoles from 'hooks/useUserRoles';
import useValidateForm from 'hooks/useValidateForm';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { queryClient } from 'services/api/react-query';
import { ZodErrorType } from 'types/common';
import ArchiveRestoreModal from '../../../components/ArchiveRestoreModal';
import ConfirmationModal from '../../AddOrg/ConfirmationModal';

export const DepartmentDetails = () => {
  //get id from params
  const { id = '0' } = useParams<'id'>();
  const { parentOrgId = '0' } = useParams<'parentOrgId'>();

  //get selected org details
  const { data } = useOrgDetails(id);
  const { roles } = useUserRoles();
  const modal = useModal();
  const code = data?.status.code;

  const { data: childCrewsData } = useOrgChildren('me', {
    type: ORG_TYPE.CREW,
    parentOrgId: id ? id : null,
    status: 'active',
  });
  const isHavingActiveCrews = !!childCrewsData?.items.length;
  //initial data for org
  const initialData: OrgFormType = {
    parentOrgId: id !== '' && data?.parentOrgId ? data.parentOrgId : '0',
    name: id !== '' && data?.name ? data.name : '',
    description: id !== '' && data?.description ? data.description : '',
    type: ORG_TYPE.DEPARTMENT,
    comments: '',
  };

  //org post reducer
  const [orgData, dispatch] = useReducer(updateOrgData, initialData);

  //org action state
  const buttonRef = useRef<OrgActionButton>('save');

  //cancel button
  const navigate = useNavigate();
  const handleCancel = () => {
    const parentId =
      data && data.parentOrgId !== '' ? data.parentOrgId : parentOrgId;
    navigate(`/admin/org-structure/${parentId ?? ''}/departments`);
  };

  //validator
  const [errors, setErrors] = useState<ZodErrorType>({});
  const validationSchema = id === '0' ? OrgPostSchema : descriptionSchema;
  const { validateForm, trimValues } = useValidateForm(
    validationSchema,
    setErrors
  );
  const [enableValidator, setEnableValidator] = useState(false);

  //validate form on change of org form data
  //check if form fields updated
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  useEffect(() => {
    if (
      initialData.name.trim() !== orgData.name.trim() ||
      initialData.description.trim() !== orgData.description.trim()
    ) {
      setIsFormUpdated(true);
    } else {
      setIsFormUpdated(false);
    }

    if (enableValidator) {
      validateForm(orgData);
    }
    // eslint-disable-next-line
  }, [orgData]);
  //create org api hook
  const { mutateAsync: addOrgRecord } = useAddOrg();

  //notification
  const notify = useNotification();
  // add new department
  const addNewDepartment = async () => {
    const payload = {
      parentOrgId: parentOrgId,
      name: orgData.name.trim(),
      description: orgData.description.trim(),
      type: ORG_TYPE.DEPARTMENT,
      comments: orgData.comments.trim(),
      sortSequence: '',
      safetyMeetingWeekOfMonth: '',
      safetyMeetingDayOfWeek: '',
      safetyMeetingTurnOnTheDay: '',
      safetyMeetingLengthMinutes: '',
      safetyMeetingBeforeOrAfterShift: '',
      safetyMeetingIncentiveEligible: '',
    };
    if (parentOrgId !== '0') {
      await addOrgRecord(payload as OrgPost, {
        onSuccess: async () => {
          await queryClient.refetchQueries({ queryKey: [`orgs-department`] });

          notify.success({
            message: 'You have successfully added new department',
          });
          handleCancel();
        },
      });
    }
  };

  const handleAdd = () => {
    const confirm = async () => {
      await addNewDepartment();
      modal.closeModal();
    };

    modal.openModal({
      title: 'Add New Department?',
      type: 'regular',
      children: <div>Are you sure you want to add this new department?</div>,
      labels: { confirm: 'Add', cancel: 'Cancel' },
      onCancel: () => modal.closeModal(),
      onConfirm: () => confirm(),
    });
  };

  const handleSave = () => {
    modal.openModal({
      title: 'Save Changes!',
      type: 'regular',

      children: (
        <ConfirmationModal
          orgId={id}
          org={orgData}
          type={ORG_TYPE.DEPARTMENT}
          setIsFormUpdated={setIsFormUpdated}
          handleCancel={modal.closeModal}
        />
      ),
    });
  };

  const showModal = () => {
    if (buttonRef.current === 'create') {
      handleAdd();
    } else {
      handleSave();
    }
  };

  //org actions
  const canSave = false;
  const isSaveDisabled =
    (id !== '0' && isFormUpdated) || id === '0' ? false : true;

  const canArchive =
    (roles.includes(ROLES.SUPER_ADMIN) ||
      roles.includes(ROLES.SUPER_PAYROLL_ADMIN)) &&
    code === 'active';
  const canRestore =
    (roles.includes(ROLES.SUPER_ADMIN) ||
      roles.includes(ROLES.SUPER_PAYROLL_ADMIN)) &&
    code === 'archived';

  const handleArchiveRestoreButtonClick = (): void => {
    (code === 'active' || code === 'archived') &&
      !isHavingActiveCrews &&
      modal.openModal({
        title:
          code !== 'archived' ? 'Archive Department!' : 'Restore Department!',
        type: 'regular',
        size: 'md',
        children: (
          <ArchiveRestoreModal
            orgType={ORG_TYPE.DEPARTMENT}
            id={id}
            code={code}
            closeModal={modal.closeModal}
          />
        ),
      });
    isHavingActiveCrews &&
      notify.danger({
        message:
          'Error: All Crews must be archived before archiving the Department',
      });
  };

  return (
    <>
      <OrgForm
        orgId={id}
        org={orgData}
        dispatch={dispatch}
        errors={errors}
        type={ORG_TYPE.DEPARTMENT}
        isCommentDisabled={isSaveDisabled}
      />

      <div className="flex justify-end flex-wrap mt-4">
        <OrgAction
          showModal={showModal}
          handleArchiveRestore={handleArchiveRestoreButtonClick}
          buttonState={{
            canSave: canSave,
            isSaveDisabled: isSaveDisabled,
            canArchive: canArchive,
            canRestore: canRestore,
          }}
          orgId={id === '' ? '0' : id}
          handleCancel={handleCancel}
          validateForm={() => {
            return validateForm(trimValues(orgData));
          }}
          setEnableValidator={setEnableValidator}
          buttonRef={buttonRef}
        />
      </div>
    </>
  );
};
