import clsx from 'clsx';
import React from 'react';

type CardLabelValueProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
> & {
  children: React.ReactNode;
  label?: string;
  lineClamp?: 1 | 2 | 3 | 4 | 5;
  no_clamp?: boolean;
  textColor?: 'text-gray-900' | 'text-orange-600';
};

/**
 *
 * @param className - Applies to the root `<span>` element, normally used to control the width
 * @param label - Determines the label that should be shown
 * @param lineClamp - Sets the line clamp property on the text value, options are 1- 4
 * @param children - If children comes as `null` or `undefined` a simple "-" will display
 * @returns
 *
 * @example
 *
 * <CardLabelValue label="HR Location" className="w-1/2">
    {emp.hrLocation}
   </CardLabelValue>
 *
 */

const LabeledText = ({
  children,
  label,
  className,
  lineClamp = 1,
  no_clamp = false,
  textColor = 'text-gray-900',
}: CardLabelValueProps) => {
  function getLineClamp() {
    if (no_clamp) {
      return ``;
    }
    return `line-clamp-${lineClamp}`;
  }
  return (
    <span className={className}>
      <span className="text-gray-700 text-xs text-left">{label}</span>
      <p
        aria-label={label}
        title={label}
        className={clsx(
          `text-sm text-left font-semibold ${getLineClamp()}`,
          textColor
        )}
      >
        {children ? children : '-'}
      </p>
    </span>
  );
};

export default LabeledText;
