import { StateCreator } from 'zustand';
import { ProbationaryEmployeesStore } from '../store';
export interface ReEvaluateSlice {
  page?: string;
  pageSize?: string;
  setPage: (value: number) => void;
  setPageSize: (value: number) => void;
  search?: string;
  setSearch: (value: string) => void;
  clearSearch: () => void;
}
export const ReEvaluateTabSlice: StateCreator<
  ProbationaryEmployeesStore,
  [],
  [],
  ReEvaluateSlice
> = (set) => {
  return {
    page: '1',
    pageSize: '10',
    setPage: (value) =>
      set((state) => {
        state.reviewer.reEvaluate.page = value.toString();
        return state;
      }),
    setPageSize: (value) =>
      set((state) => {
        state.reviewer.reEvaluate.pageSize = value.toString();
        state.reviewer.reEvaluate.page = '1';
        return state;
      }),
    setSearch: (value) =>
      set((state) => {
        if (value.length > 0) {
          state.reviewer.reEvaluate.search = value;
        } else {
          delete state.reviewer.reEvaluate.search;
        }
        state.reviewer.reEvaluate.page = '1';
        return state;
      }),
    clearSearch: () =>
      set((state) => {
        delete state.reviewer.reEvaluate.search;
        state.reviewer.reEvaluate.page = '1';
        return state;
      }),
  };
};
