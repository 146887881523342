import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'services/api/react-query';

import { WMSError } from 'types/common';
import { axios } from '../../../services/api/axios';
import { CancelMemberMoveResponse, CancelMemebrMoveRequest } from '../types';

const cancelMove = (
  body: CancelMemebrMoveRequest
): Promise<CancelMemberMoveResponse> => {
  return axios.put(
    `/v1/people/${body.ussId}/crews/${body.crewId}/cancel-change-crew`
  );
};

export const useCancelMemberMove = () => {
  return useMutation<
    CancelMemberMoveResponse,
    WMSError,
    CancelMemebrMoveRequest
  >({
    mutationFn: (body: CancelMemebrMoveRequest) => cancelMove(body),
    meta: {
      errorMessage: 'Unable to cancel crew member.',
    },
    onSuccess: async () => {
      queryClient.removeQueries({ queryKey: ['crew-members'] });
      await queryClient.refetchQueries({ queryKey: ['employee-securities'] });
    },
  });
};
