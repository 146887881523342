import { useEffect, useState } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Checkbox,
  Datepicker,
  RadioGroup,
  Select,
  TextArea,
} from '@uss/react-components';
import useCountDays from 'hooks/use-count-days';
import {
  addYears,
  format,
  isValid,
  parse,
  subYears,
  subDays,
  parseISO,
} from 'date-fns';
import { AddPTOContext, DATE_RANGE_PAYCODES, PTO_ADD_CONTEXT } from 'features/paid-time-off/constants';
import { BsInfoCircle } from 'react-icons/bs';
import ValidationError from 'components/Validation/ValidationError';
import { useNavigate, useParams } from 'react-router-dom';
import { usePersonDetails } from 'features/people/api';
import { useHolidayDates } from 'features/holidays';
import checkCrossOverWeek from 'features/paid-time-off/utility/checkCrossOverWeek';
import { appNewDate } from 'utils/appNewDate';
import {
  AddBlackoutPost,
  AddPtoPost,
  PtoStatus,
} from 'features/paid-time-off/types';
import { getPtoPaycodes } from 'features/paid-time-off/utility/getPtoPaycodeList';
import {
  useAddBlackout,
  useAddPto,
} from 'features/paid-time-off/api/mutations';
import { AddPtoSchema } from 'features/paid-time-off/schemas/add-pto-schema';
import { validateForm } from 'utils/validateForm';
import {
  AddPTOPlantType,
  EmpPTOVal,
} from 'features/paid-time-off/types/employee-pto';
import useUserRoles from 'hooks/useUserRoles';
import { useEmployeeSecurityForPayAaprover } from 'features/security';
import { ROLES } from 'features/roles';
import { isEligibleToAddBlackout } from 'features/paid-time-off/utility/isEligibleToAddBlackout';
import { PayPeriod, usePayPeriodWithDateRange } from 'features/pay-periods';
import { getFuturePayPeriod } from 'features/paid-time-off/utility/getFuturePayPeriod';
import { notify } from 'components/Notification/useNotification';

interface AddPtoFormType {
  status: PtoStatus;
  ussId: string;
  paycode: string;
  startDate: string;
  endDate: string | null;
  excludeWeekends: boolean;
  excludeHolidays: boolean;
  chargeYear?: string;
  notes: string | null;
  ptoOptionType?: string;
  paycodeDescription: string;
  context: AddPTOContext;
}

interface AddBlackoutFormType {
  ussIds: string[];
  date: string;
  notes: string | null;
}

const AddPTOForm = ({ defaultValues }: { defaultValues: AddPtoFormType }) => {
  //getting employeeId from url
  const { id: employeeId = '' } = useParams<'id'>();
  //navigation
  const navigate = useNavigate();
  //getting employee details
  const { data: employeeData, status } = usePersonDetails(employeeId);
  //API call for holiday list
  const { data: holidayList } = useHolidayDates(
    employeeData?.holidayType ?? 'brs-observed'
  );
  //Getting Roles
  const { roles, uss_id } = useUserRoles();
  //Security API call to check blackout permission for Pay Approver only
  const { data: payApproverRecord } = useEmployeeSecurityForPayAaprover(
    uss_id,
    {
      role: roles[0],
    }
  );
  const currentYear = new Date().getFullYear().toString();
  //Payperiod API call - with date range of 2 years
  const { data } = usePayPeriodWithDateRange(
    employeeData?.payrollNameId ? employeeData.payrollNameId : '',
    {
      startDate: `${currentYear}-1-1`,
      endDate: `${`${parseInt(currentYear) + 1}`}-12-31`,
    }
  );
  //Future Pay Period
  const futurePayPeriod: PayPeriod[] = getFuturePayPeriod(data);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm<AddPtoFormType>({
    resolver: zodResolver(AddPtoSchema),
    defaultValues: defaultValues,
  });

  //date range checkbox
  const [dateRange, setDateRange] = useState(false);
  //watch - fields values
  const excludeHolidays = useWatch({ control, name: 'excludeHolidays' });
  const excludeWeekends = useWatch({ control, name: 'excludeWeekends' });
  const notes = useWatch({ control, name: 'notes' });
  const start = useWatch({ control, name: 'startDate' });
  const end = useWatch({ control, name: 'endDate' });
  const paycodeDesc = useWatch({ control, name: 'paycodeDescription' });
  const selectedRadioOption = useWatch({ control, name: 'ptoOptionType' });

  //Add PTO API Call
  const { mutateAsync: addPto, isPending: changeAwaiting } = useAddPto();

  //Creating Employee Full Name
  const userFullName = employeeData
    ? employeeData.lastName + ',' + employeeData.firstName
    : '';
  //Date in Day Month, Year format
  const dateWithMonthLabel = new Date(parseISO(start)).toLocaleDateString(
    'en-US',
    {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }
  );
  //Add Blackout API Call
  const { mutateAsync: addBlackout } = useAddBlackout(
    [employeeId],
    userFullName,
    dateWithMonthLabel
  );

  //setting ussId of employeeId as it is a mandatory field
  useEffect(() => {
    if (employeeId) {
      setValue('ussId', employeeId.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId]);

  /* Date range count logic:START*/
  const parsedStart = parse(
    isValid(new Date(start)) ? start : format(new Date(), 'yyyy-MM-dd'),
    'yyyy-MM-dd',
    new Date()
  );
  const parsedEnd = parse(
    end && isValid(new Date(end))
      ? end
      : format(
          isValid(new Date(start)) ? new Date(start) : new Date(),
          'yyyy-MM-dd'
        ),
    'yyyy-MM-dd',
    new Date()
  );
  const rangeCount = useCountDays({
    start: parsedStart,
    end: parsedEnd,
    holidays: holidayList,
  });
  const getRangeCount = () => {
    if (excludeWeekends && excludeHolidays) {
      return rangeCount.numberOfDays.excludeWeekendsandHolidays.formatted;
    } else if (excludeHolidays) {
      return rangeCount.numberOfDays.excludeHolidays.formatted;
    } else if (excludeWeekends) {
      return rangeCount.numberOfDays.excludeWeekends.formatted;
    } else {
      return rangeCount.numberOfDays.formatted;
    }
  };
  //Date range allowed : P1 through P8,P0,VH,VD
  const _paycode = useWatch({ control, name: 'paycode' });
  const hasDateRange = DATE_RANGE_PAYCODES.includes(_paycode);
  /* Date range count logic :END*/

  // check if charge year active (applies only for integrated_mill plantType)
  const isRangeInCrossOverWeek = checkCrossOverWeek(
    start,
    hasDateRange ? end : undefined
  );
  const hasChargeYear =
    (_paycode === 'VH' || _paycode === 'VD') && isRangeInCrossOverWeek;

  const years = [
    format(subYears(new Date(), 1), 'yyyy'),
    format(new Date(appNewDate()), 'yyyy'),
    format(addYears(new Date(), 1), 'yyyy'),
  ];

  //variable to show/hide checkbox and charge year based on paycode
  const dateRangeEnabled = _paycode !== 'PH' && _paycode !== 'PD';

  //cancel button - navigation to back
  const handleCancel = () => {
    navigate(`/paid-time-off/employees/${employeeId}`);
  };

  //add Blackout - API call
  const fnAddBlackout = async (data: AddPtoFormType) => {
    const tempObj: AddBlackoutFormType = {
      ussIds: [data.ussId],
      date: data.startDate,
      notes: data.notes,
    };
    await addBlackout(tempObj as AddBlackoutPost);
  };

  //add pto - API call
  const handleAddPTORequest = async (data: AddPtoFormType) => {
    if (selectedRadioOption === EmpPTOVal.Blackout) {
      await fnAddBlackout(data);
    } else {
      //adding default selected value for charge-year if no val selected
      if (data.chargeYear === '') {
        data.chargeYear = format(
          parse(data.startDate, 'yyyy-MM-dd', new Date()),
          'yyyy'
        );
      }
      // Destructuring the object to get required fields only (removing ptoOptionType)
      const { ptoOptionType, paycodeDescription, ...addPtoRequiredFields } =
        data;
      //Adding context param
      addPtoRequiredFields.context = PTO_ADD_CONTEXT.EMP_PTO;
      if (employeeData?.plantType === 'mini_mill' || !isRangeInCrossOverWeek) {
        //chargeDate is not required for plantType mini_mill
        delete addPtoRequiredFields.chargeYear;
      }
      if (validateForm(data, AddPtoSchema, setError)) {
        await addPto(addPtoRequiredFields as AddPtoPost, {
          onSuccess: () => {
            notify.success({
              message:
                data.endDate === null
                  ? 'You have successfully added PTO for this employee'
                  : 'You have successfully added PTO for this employee for a date range.',
            });
            handleCancel();
          },
        });
      }
    }
  };

  //Validation to check if for Role = Pay Approver having permission to Add-Blackout
  const isPAEligibleForBlackout = isEligibleToAddBlackout(
    roles,
    payApproverRecord,
    employeeData?.primaryCrewName ? employeeData.primaryCrewName : ''
  );

  //to check if blackout option is NOT enabled
  const blackoutNotEnabled = selectedRadioOption !== EmpPTOVal.Blackout;

  //getting first day of current month
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  useEffect(() => {
    if (_paycode) {
      const descArr =
        employeeData &&
        getPtoPaycodes(employeeData.ptoClass, employeeData.shiftAssignment);
      const matchedDesc = descArr?.filter((item) => item.paycode === _paycode);
      matchedDesc && setValue('paycodeDescription', matchedDesc[0].description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_paycode]);

  return (
    <>
      {status === 'success' ? (
        <form
          className="@container"
          onSubmit={handleSubmit((e) => handleAddPTORequest(e))}
        >
          <div className="bg-white sm:bg-white rounded-md p-4">
            <div className="max-w-2xl mx-auto py-8">
              <div className="grid grid-cols-1 @xl:grid-cols-2 gap-4 ">
                <div className="@xl:col-span-2 sm:w-1/2">
                  <Controller
                    control={control}
                    name="ptoOptionType"
                    render={({ field, fieldState: { invalid, error } }) => (
                      <>
                        <RadioGroup
                          direction="row"
                          {...field}
                          label={''}
                          selected={field.value ?? ''}
                          onSelect={field.onChange}
                          {...register('ptoOptionType')}
                        >
                          <RadioGroup.Option
                            id={'PTO'}
                            value={'PTO'}
                            variant="radio"
                            name="PTO"
                            disabled={false}
                          >
                            <span>PTO</span>
                          </RadioGroup.Option>
                          <div className="sm:w-1/4 w-5"></div>
                          <RadioGroup.Option
                            id={'Blackout'}
                            value={'Blackout'}
                            variant="radio"
                            name="Blackout"
                            disabled={
                              isPAEligibleForBlackout === false &&
                              roles.includes(ROLES.PAY_APPROVER)
                                ? true
                                : false
                            }
                          >
                            <span>Blackout</span>
                          </RadioGroup.Option>
                        </RadioGroup>
                        {invalid && (
                          <div className="mt-1">
                            <ValidationError
                              msg={error?.message ?? ''}
                              name="ptoOptionTypeRequired"
                            />
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>

                {blackoutNotEnabled && (
                  <div className="@xl:col-start-1 @xl:col-end-2">
                    <label
                      className="text-xs text-gray-700 font-normal"
                      htmlFor="paycode"
                    >
                      Pay Code
                    </label>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="paycode"
                      render={({ field, fieldState: { invalid, error } }) => (
                        <>
                          <Select
                            {...field}
                            selected={field.value}
                            onSelect={field.onChange}
                            className="w-full"
                            disabled={false}
                          >
                            <Select.Button
                              label={
                                field.value
                                  ? field.value + ` - ${paycodeDesc}`
                                  : 'Select Paycode'
                              }
                              error={invalid}
                            />
                            <Select.Options error={invalid}>
                              {getPtoPaycodes(
                                employeeData.ptoClass,
                                employeeData.shiftAssignment
                              ).map((item, itemIdx) => (
                                <Select.Option
                                  key={itemIdx}
                                  value={item.paycode}
                                >
                                  <span>
                                    {item.paycode} - {item.description}
                                  </span>
                                </Select.Option>
                              ))}
                            </Select.Options>
                          </Select>
                          {invalid && (
                            <ValidationError
                              msg={error?.message ?? ''}
                              name="paycodeRequired"
                            />
                          )}
                        </>
                      )}
                    />
                  </div>
                )}

                <div className="@xl:col-span-2">
                  {blackoutNotEnabled && dateRangeEnabled && (
                    <div className="flex justify-start items-center">
                      <Checkbox
                        id="date-range"
                        label="Date Range"
                        type="checkbox"
                        checked={dateRange}
                        onChange={() => {
                          setDateRange(!dateRange);
                          if (dateRange) {
                            setValue('endDate', null);
                          }
                        }}
                      />
                    </div>
                  )}
                </div>

                <div className="@xl:col-span-2">
                  {blackoutNotEnabled ? (
                    <div className="sm:grid sm:grid-cols-2 gap-2">
                      {/*Start Date for PTO*/}
                      <div className="@xl:col-span-1 mb-1">
                        <Controller
                          control={control}
                          name="startDate"
                          render={({
                            field,
                            fieldState: { invalid, error },
                          }) => (
                            <Datepicker
                              isLabel={true}
                              ariaLabelledBy={dateRange ? 'Start Date' : 'Date'}
                              onDateChange={(date) => {
                                field.onChange(format(date, 'yyyy-MM-dd'));
                              }}
                              calendarType={'date'}
                              id="start-date"
                              {...register('startDate')}
                              isError={invalid}
                              errormessage={error?.message ?? ''}
                            />
                          )}
                        />
                      </div>
                      <div>
                        {hasDateRange && dateRange && (
                          <>
                            {/*End Date for PTO*/}
                            <Controller
                              control={control}
                              name="endDate"
                              render={({
                                field,
                                fieldState: { invalid, error },
                              }) => (
                                <Datepicker
                                  isLabel={true}
                                  ariaLabelledBy={'End Date'}
                                  onDateChange={(date) => {
                                    field.onChange(format(date, 'yyyy-MM-dd'));
                                  }}
                                  calendarType={'date'}
                                  id="end-date"
                                  {...register('endDate')}
                                  isError={invalid}
                                  errormessage={error?.message ?? ''}
                                />
                              )}
                            />
                            {start && end && errors.endDate === undefined && (
                              <div className="flex flex-row items-center mt-2">
                                {getRangeCount().length > 0 && (
                                  <>
                                    <BsInfoCircle className="text-gray-700" />
                                    <span
                                      data-testId="range-count-text"
                                      className="ml-2 text-sm text-gray-700"
                                    >
                                      {getRangeCount()} Selected
                                    </span>
                                  </>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="sm:w-1/2">
                      {/*Date for Blackout*/}
                      <Controller
                        control={control}
                        name="startDate"
                        render={({ field, fieldState: { invalid, error } }) => (
                          <Datepicker
                            isLabel={true}
                            ariaLabelledBy={'Date'}
                            onDateChange={(date) => {
                              field.onChange(format(date, 'yyyy-MM-dd'));
                            }}
                            minDate={
                              new Date(
                                futurePayPeriod[0]
                                  ? parseISO(futurePayPeriod[0].startDate)
                                  : ''
                              )
                            }
                            excludeDateIntervals={[
                              {
                                start: firstDay,
                                end: subDays(
                                  new Date(
                                    futurePayPeriod[0]
                                      ? parseISO(futurePayPeriod[0].startDate)
                                      : ''
                                  ),
                                  1
                                ),
                              },
                            ]}
                            calendarType={'date'}
                            id="start-date"
                            {...register('startDate')}
                            isError={invalid}
                            errormessage={error?.message ?? ''}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>

                {hasDateRange && dateRange && blackoutNotEnabled && (
                  <div className="flex @xl:col-span-2">
                    <div className="flex justify-start items-center">
                      <Checkbox
                        id="excludeWeekends"
                        label="Exclude Weekends"
                        {...register('excludeWeekends')}
                        disabled={rangeCount.numberOfDays.weekends.value === 0}
                      />
                      <p>{errors.excludeWeekends?.message}</p>
                    </div>
                    <div className="flex justify-start items-center">
                      <Checkbox
                        id="excludeHolidays"
                        label="Exclude Holiday(s)"
                        {...register('excludeHolidays')}
                        disabled={rangeCount.numberOfDays.holidays.value === 0}
                      />
                      <p>{errors.excludeHolidays?.message}</p>
                    </div>
                  </div>
                )}

                {(employeeData.plantType === AddPTOPlantType.integratedMill ||
                  employeeData.plantType === AddPTOPlantType.office) &&
                  blackoutNotEnabled &&
                  dateRangeEnabled && (
                    <div className="@xl:col-start-1 @xl:col-end-2">
                      <label
                        className="text-xs text-gray-700 font-normal"
                        htmlFor="chargeYear"
                      >
                        Charge Year
                      </label>
                      <Controller
                        control={control}
                        name="chargeYear"
                        defaultValue={years[1]}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <>
                            <Select
                              {...field}
                              selected={field.value}
                              onSelect={field.onChange}
                              className="w-full"
                              disabled={!hasChargeYear}
                              defaultValue={years[1]}
                            >
                              <Select.Button
                                label={
                                  field.value
                                    ? field.value
                                    : years[1]
                                    ? years[1]
                                    : 'Select'
                                }
                              />
                              <Select.Options>
                                {years.map((item, itemIdx) => (
                                  <Select.Option key={itemIdx} value={item}>
                                    <span>{item}</span>
                                  </Select.Option>
                                ))}
                              </Select.Options>
                            </Select>
                            {invalid && (
                              <ValidationError
                                msg={error?.message ?? ''}
                                name="chargeYearRequired"
                              />
                            )}
                          </>
                        )}
                      />
                    </div>
                  )}

                <div className="@xl:col-span-2">
                  <label
                    className="text-xs text-gray-700 font-normal"
                    htmlFor="notes"
                  >
                    Notes (Optional)
                  </label>
                  <div className="col-span-2 mb-4 lg:mb-0">
                    <TextArea
                      maxLength={100}
                      label="Note"
                      id="form-comment"
                      placeholder={'Enter text'}
                      {...register('notes')}
                      hideLabel
                      error={!!errors.notes?.message}
                      errorMessage={errors.notes?.message}
                      disabled={false}
                    />
                    <div className="col-span-2 text-xs mb-2 text-gray-600 text-right">
                      {100 - (notes ? notes.length : 0)} Characters left
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end flex-wrap mt-4">
            <Button
              onClick={handleCancel}
              variant="outline"
              className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto "
              type="button"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
              type="submit"
              disabled={changeAwaiting}
            >
              Add
            </Button>
          </div>
        </form>
      ) : (
        <>Loading...</>
      )}
    </>
  );
};

export default AddPTOForm;
