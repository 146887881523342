import { Table } from '@uss/react-components';

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.HeaderRow>
        <Table.HeaderCell
          scope="col"
          className="w-[12%] xl:w-[14%]"
          rowSpan={2}
          id="week"
        >
          Week
        </Table.HeaderCell>
        <Table.HeaderCell
          scope="col"
          className="w-[6%]"
          rowSpan={2}
          id="elps-time"
        >
          Elps Time
        </Table.HeaderCell>
        <Table.HeaderCell
          scope="col"
          className="w-[15%]"
          colSpan={2}
          id="scheduled"
        >
          Scheduled
        </Table.HeaderCell>
        <Table.HeaderCell
          scope="col"
          className="w-[15%]"
          colSpan={2}
          id="worked"
        >
          Worked
        </Table.HeaderCell>
        <Table.HeaderCell
          scope="col"
          className="w-[15%]"
          colSpan={2}
          id="approved"
        >
          Approved
        </Table.HeaderCell>
        <Table.HeaderCell
          scope="col"
          className="w-[15%]"
          colSpan={2}
          id="schedule-variance"
        >
          Schedule Variance
        </Table.HeaderCell>
        <Table.HeaderCell
          scope="col"
          className="w-[10%] xl:w-[8%]"
          rowSpan={2}
          id="paid-hours-not-worked"
        >
          Paid Not <br /> Wrkd Hrs
        </Table.HeaderCell>
        <Table.HeaderCell
          scope="col"
          className="w-[6%]"
          rowSpan={2}
          id="excp-code"
        >
          Excp Code
        </Table.HeaderCell>
        <Table.HeaderCell
          scope="col"
          rowSpan={2}
          className="w-[6%]"
          id="comments"
        ></Table.HeaderCell>
      </Table.HeaderRow>
      <Table.HeaderRow>
        <Table.HeaderCell scope="col" id="scheduled-turns">
          Turns
        </Table.HeaderCell>
        <Table.HeaderCell scope="col" id="scheduled-hours">
          Hours
        </Table.HeaderCell>
        <Table.HeaderCell scope="col" id="worked-turns">
          Turns
        </Table.HeaderCell>
        <Table.HeaderCell scope="col" id="worked-hours">
          Hours
        </Table.HeaderCell>
        <Table.HeaderCell scope="col" id="approved-turns">
          Turns
        </Table.HeaderCell>
        <Table.HeaderCell scope="col" id="approved-hours">
          Hours
        </Table.HeaderCell>
        <Table.HeaderCell
          scope="col"
          id="schedule-variance-paycode"
          className=" text-nowrap"
        >
          Pay Code
        </Table.HeaderCell>
        <Table.HeaderCell scope="col" id="schedule-variance-hours">
          Hours
        </Table.HeaderCell>
      </Table.HeaderRow>
    </Table.Header>
  );
};

export default TableHeader;
