import React from 'react';
import Skeleton from 'react-loading-skeleton';
interface SkeletonProps {
  rows?: number;
  avatarType?: boolean;
  isFilter?: boolean;
}
const Loader = ({ rows = 0, avatarType, isFilter = false }: SkeletonProps) => {
  return (
    <>
      {avatarType && (
        <Skeleton borderRadius={'100%'} height={'40px'} width={'40px'} />
      )}
      {!!rows && <Skeleton height={'20px'} count={rows} />}
      {isFilter && <Skeleton height={'40px'} />}
    </>
  );
};

export default Loader;
