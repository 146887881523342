import React from 'react';
import { Person } from 'features/people/types';
import LabeledText from 'components/Typography/LabeledText';

interface SelectEmployeeDetailsProps {
  employee?: Person | null;
}

export const SelectedEmployeeDetails = ({
  employee,
}: SelectEmployeeDetailsProps) => {
  return (
    <div>
      {employee && (
        <div className="py-2 grid grid-cols-2 ">
          <div className=" col-span-2 flex flex-col">
            <span className="text-base font-semibold text-gray-700">
              {employee.displayName}
            </span>
            <span className="text-xs text-gray-700 flex">
              {employee.payrollLocation}
              {' - '}
              {employee.hrLocation}
              {employee.primaryCrew && (
                <span className="bg-gray-300 w-0.5 mx-2"></span>
              )}
              {employee.primaryCrew}
            </span>
          </div>

          <LabeledText label="Employee Class">
            {`${employee.empClass ?? ''} - ${employee.empClassDesc ?? ''}`}
          </LabeledText>
          <LabeledText label="Status">
            {employee.status.charAt(0).toUpperCase() + employee.status.slice(1)}
          </LabeledText>
          <LabeledText label="USS ID">{employee.ussId}</LabeledText>
          <LabeledText label="Supervisor">
            {employee.supervisorDisplayName}
          </LabeledText>
          <LabeledText label="Job Title">{employee.jobTitle}</LabeledText>
        </div>
      )}
    </div>
  );
};
