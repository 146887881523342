import React, { useContext, createContext, useReducer } from 'react';
import { SortDirection } from 'types/common';
import { OrgFilterStatus, OrgsOptions, OrgsSortByType } from 'features/org';

//Creating Contexts
const DivisionContext = createContext<OrgsOptions>({});
const DivisionDispatchContext = createContext<React.Dispatch<OrgFilterAction>>(
  () => ({})
);

const DepartmentContext = createContext<OrgsOptions>({});
const DepartmentDispatchContext = createContext<
  React.Dispatch<OrgFilterAction>
>(() => ({}));

export type OrgFilterAction =
  | { type: 'location'; payload: string }
  | { type: 'search'; payload: string }
  | { type: 'page'; payload: number }
  | { type: 'pageSize'; payload: number }
  | { type: 'clearSearch' }
  | { type: 'status'; payload: OrgFilterStatus }
  | { type: 'parentOrgId'; payload: string }
  | {
      type: 'sort';
      payload: {
        name: OrgsSortByType | undefined;
        direction: SortDirection | undefined;
      };
    };

const OrgProvider = ({ children }: { children: React.ReactNode }) => {
  const initialState: OrgsOptions = {
    page: '1',
    pageSize: '9',
    sortBy: 'code',
    sortType: SortDirection.ASC,
    status: 'active',
  };

  const { state: divisionState, dispatch: divisionDispatch } = useOrgParams({
    ...initialState,
  });

  const { state: departmentState, dispatch: departmentDispatch } = useOrgParams(
    {
      ...initialState,
    }
  );

  return (
    <DivisionContext.Provider value={divisionState}>
      <DepartmentContext.Provider value={departmentState}>
        <DepartmentDispatchContext.Provider value={departmentDispatch}>
          <DivisionDispatchContext.Provider value={divisionDispatch}>
            {children}
          </DivisionDispatchContext.Provider>
        </DepartmentDispatchContext.Provider>
      </DepartmentContext.Provider>
    </DivisionContext.Provider>
  );
};

// Custom hook to return the Division Context
export function useDivisionPageParams() {
  return useContext(DivisionContext);
}

// Custom hook to return the Division Page Params Dispatch function
export function useDivisionPageParamsDispatch() {
  return useContext(DivisionDispatchContext);
}

// Custom hook to return the Department Context
export function useDepartmentPageParams() {
  return useContext(DepartmentContext);
}

// Custom hook to return the Department Page Params Dispatch function
export function useDepartmentPageParamsDispatch() {
  return useContext(DepartmentDispatchContext);
}

export const useOrgParams = (initialParams: OrgsOptions) => {
  const filterReducer = (
    state: OrgsOptions,
    action: OrgFilterAction
  ): OrgsOptions => {
    switch (action.type) {
      case 'page': {
        state.page = action.payload.toString();
        return { ...state };
      }
      case 'pageSize': {
        state.pageSize = action.payload.toString();
        return { ...state };
      }
      case 'search': {
        if (action.payload.length > 0) {
          state.search = action.payload;
          state.page = '1';
        } else delete state.search;
        return { ...state };
      }
      case 'sort': {
        state.sortBy = action.payload.name;
        state.sortType = action.payload.direction;
        return { ...state };
      }
      case 'status': {
        if (action.payload !== 'all') {
          state.status = action.payload;
        } else delete state.status;
        return { ...state };
      }
      case 'parentOrgId': {
        state.parentOrgId = action.payload;
        return { ...state };
      }
      case 'clearSearch': {
        delete state.search;
        return { ...state };
      }
      default:
        return { ...state };
    }
  };
  const [state, dispatch] = useReducer(filterReducer, initialParams);

  return { state, dispatch };
};

export default OrgProvider;
