import _ from 'lodash';
import { TimecardFeature } from 'features/timecards/types';
import { PtoScreenType } from '../types/pto-requests';
import { PtoClass, PtoDetail } from '../types';

export type PtoDetailsList = Pick<
  PtoDetail,
  'payPeriodStatus' | 'status' | 'timecardStatus' | 'adjustment'
> & { ptoClass: Pick<PtoClass, 'name'> };

export const ptoFormFieldEnable = ({
  ptoDetails,
  hasChargeYear,
  ptoType,
}: {
  ptoDetails: PtoDetailsList | undefined;
  hasChargeYear: boolean | undefined;
  ptoType: PtoScreenType | TimecardFeature | null;
}) => {
  const formFields = {
    isPayCodeDisabled: true,
    isDateDisabled: true,
    isChargeYearDisabled: true,
    isNotesDisabled: true,
    isChargeYearHidden: true,
  };
  const buttons = {
    isSaveDisabled: true,
    isRemoveDisabled: true,
    isDenyDisabled: true,
    isApproveDisabled: true,
  };
  if (ptoDetails !== undefined) {
    if (ptoDetails.status !== 'blackout') {
      //PTO Status = Requested
      if (
        ptoDetails.status === 'requested' &&
        (ptoDetails.payPeriodStatus === 'open' ||
          ptoDetails.payPeriodStatus === 'not-open')
      ) {
        //All Fields Enabled
        formFields.isPayCodeDisabled = false;
        formFields.isDateDisabled = false;
        formFields.isNotesDisabled = false;
        formFields.isChargeYearDisabled = !hasChargeYear;
        //For Crew-Members Only
        if (ptoType === 'myPto' || ptoType === 'my-schedule') {
          //Save&Remove - Enabled, Deny&Approve - Disabled
          buttons.isSaveDisabled = false;
          buttons.isRemoveDisabled = false;
        } else {
          //Pay Approver and Above - All Buttons Enabled
          buttons.isSaveDisabled = false;
          buttons.isRemoveDisabled = false;
          buttons.isDenyDisabled = false;
          buttons.isApproveDisabled = false;
        }
      } else if (ptoDetails.status === 'approved') {
        buttons.isApproveDisabled = true;
        //For payPeriodStatus = open
        if (ptoDetails.payPeriodStatus === 'open') {
          if (ptoDetails.timecardStatus === 'approved') {
            //All Fields Disabled
            _.mapValues(formFields, () => true);
            //All Buttons Disabled
            _.mapValues(buttons, () => true);
          } else {
            //All Fields Enabled
            formFields.isPayCodeDisabled = false;
            formFields.isDateDisabled = false;
            formFields.isNotesDisabled = false;
            formFields.isChargeYearDisabled = !hasChargeYear;
            //Pay Approver and Above - All Buttons Enabled
            buttons.isSaveDisabled = false;
            buttons.isRemoveDisabled = false;
            //For Crew-member Deny Disabled
            if (ptoType === 'myPto' || ptoType === 'my-schedule')
              buttons.isDenyDisabled = true;
            else buttons.isDenyDisabled = false;
          }
        } else if (ptoDetails.payPeriodStatus === 'not-open') {
          //For payPeriodStatus = not-open
          //All Fields Enabled
          formFields.isPayCodeDisabled = false;
          formFields.isDateDisabled = false;
          formFields.isNotesDisabled = false;
          formFields.isChargeYearDisabled = !hasChargeYear;
          //For Crew-Members Only
          if (ptoType === 'myPto' || ptoType === 'my-schedule') {
            //Save&Remove - Enabled, Deny&Approve - Disabled
            buttons.isSaveDisabled = false;
            buttons.isRemoveDisabled = false;
          } else {
            //Pay Approver and Above - All Buttons Enabled
            buttons.isSaveDisabled = false;
            buttons.isRemoveDisabled = false;
            buttons.isDenyDisabled = false;
          }
        }
      } else if (ptoDetails.status === 'removed') {
        //All Fields Disabled
        _.mapValues(formFields, () => true);
        //All Buttons Disabled
        _.mapValues(buttons, () => true);
      } else if (ptoDetails.status === 'denied') {
        if (
          ptoDetails.payPeriodStatus === 'locked' ||
          ptoDetails.payPeriodStatus === 'processed' ||
          ptoDetails.payPeriodStatus === 'closed'
        ) {
          //All Fields Disabled
          _.mapValues(formFields, () => true);
          //All Buttons Disabled
          _.mapValues(buttons, () => true);
        } else if (
          ptoDetails.payPeriodStatus === 'open' ||
          ptoDetails.payPeriodStatus === 'not-open'
        ) {
          //All Fields Disabled
          _.mapValues(formFields, () => true);
          //For Crew-Members Only
          if (ptoType === 'myPto' || ptoType === 'my-schedule') {
            //All Buttons Disabled
            _.mapValues(buttons, () => true);
          } else {
            //Remove/Save/Approve - Enable, Deny - Disable
            buttons.isSaveDisabled = false;
            buttons.isRemoveDisabled = false;
            buttons.isApproveDisabled = false;
            buttons.isDenyDisabled = true;
          }
        }
      } else if (ptoDetails.status === 'taken') {
        //All Fields Disabled
        _.mapValues(formFields, () => true);
        //All Buttons Disabled
        _.mapValues(buttons, () => true);
      } else if (ptoDetails.status === 'adjustment') {
        //For Closed/Processed payperiod
        if (
          ptoDetails.payPeriodStatus === 'closed' ||
          ptoDetails.payPeriodStatus === 'processed'
        ) {
          if (ptoType === 'myPto' || ptoType === 'my-schedule') {
            //All Fields Disabled
            _.mapValues(formFields, () => true);
            //All Buttons Disabled
            _.mapValues(buttons, () => true);
          } else {
            //when an adjustment timecard is Approved, all PTO details fields and buttons in Emp PTO (NU) are disabled except for Cancel button
            if (ptoDetails.timecardStatus === 'approved') {
              //All Fields Disabled
              _.mapValues(formFields, () => true);
              //All Buttons Disabled
              _.mapValues(buttons, () => true);
            } else {
              //Paycode Enabled, Date Enabled, Notes Enabled
              formFields.isPayCodeDisabled = false;
              formFields.isDateDisabled = false;
              formFields.isNotesDisabled = false;
              //Charge-Year only Enabled PTO Date is within a cross-over week
              formFields.isChargeYearDisabled = !hasChargeYear;
              //Save&Remove - Enable, Deny&Approve - Disable
              buttons.isSaveDisabled = false;
              buttons.isRemoveDisabled = false;
              buttons.isDenyDisabled = true;
              buttons.isApproveDisabled = true;
            }
          }
        }
      }
    } else {
      //For status='blackout'
      if (
        ptoDetails.payPeriodStatus === 'locked' ||
        ptoDetails.payPeriodStatus === 'closed' ||
        ptoDetails.payPeriodStatus === 'processed'
      ) {
        //All Fields Disabled
        _.mapValues(formFields, () => true);
        //All Buttons Disabled
        _.mapValues(buttons, () => true);
      } else if (
        ptoDetails.payPeriodStatus === 'open' ||
        ptoDetails.payPeriodStatus === 'not-open'
      ) {
        if (ptoType === 'myPto' || ptoType === 'my-schedule') {
          //All Fields Disabled
          _.mapValues(formFields, () => true);
          //All Buttons Disabled
          _.mapValues(buttons, () => true);
        } else {
          //Date Enabled, Notes Enabled,
          formFields.isDateDisabled = false;
          formFields.isNotesDisabled = false;
          //Save&Remove - Enable, Deny&Approve - Disabled
          buttons.isSaveDisabled = false;
          buttons.isRemoveDisabled = false;
        }
      }
    }
    if (ptoDetails.ptoClass.name !== 'mini-mill') {
      //Charge-Year Visible based on PlantType
      formFields.isChargeYearHidden = false;
    }
    return { formFields, buttons };
  }
  return { formFields, buttons };
};
