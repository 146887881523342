import { Key, useEffect, useState } from 'react';
import {
  Button,
  Radio,
  RadioGroup,
  Select,
  SelectOptions,
} from '@uss/react-core';
import { useModelDetailStore } from 'features/model-listing/state/model-detail-store';
import { crewRotationItems } from 'features/model-listing/constants';
import { EditModelReqBody } from 'features/model-listing/types';
import { useEditModel } from 'features/model-listing/api/mutations';
import { notify } from 'components/Notification/useNotification';
import { getEmptyCrewRotationObject } from 'features/model-listing/utility/getEmptyCrewRotationObject';
import { queryClient } from 'services/api/react-query';

interface AddCrewsRotationsProps {
  openDrawer: boolean;
  setOpenDrawer: (val: boolean) => void;
}
export const AddCrewRotations = ({
  openDrawer,
  setOpenDrawer,
}: AddCrewsRotationsProps) => {
  const [position, setPosition] = useState<string>();
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
  const store = useModelDetailStore();
  const [noOfCrewAdded, setNoOfCrewAdded] = useState<Key>();
  const [selectedCrewRotation, setSelectedCrewRotation] = useState<Key>();
  const { mutateAsync: editModelMutateAsync } = useEditModel(
    store.modelDetail?.id ? store.modelDetail.id : ''
  );
  const isDisabled = store.modelDetail?.totalCrewRotations === 4;
  const totalNoCrewRotations = [
    { id: 1, value: '1' },
    { id: 2, value: '2' },
    { id: 3, value: '3' },
    { id: 4, value: '4' },
  ];
  const numberOfCrewRotationsAdded =
    store.modelDetail &&
    totalNoCrewRotations.splice(0, 4 - store.modelDetail.totalCrewRotations);

  //setting default selected option as Before
  useEffect(() => {
    setPosition('Before');
  }, []);

  useEffect(() => {
    if (isDisabled || !(position && selectedCrewRotation && noOfCrewAdded)) {
      setIsAddButtonDisabled(true);
    } else setIsAddButtonDisabled(false);
  }, [isDisabled, noOfCrewAdded, position, selectedCrewRotation]);

  const handleAddCrew = async () => {
    setIsAddButtonDisabled(true);
    const num =
      position === 'Before'
        ? Number(selectedCrewRotation) - 1
        : Number(selectedCrewRotation);

    const updatedCrewRotations = store.modelDetail?.weeks.map((week) => {
      return {
        weekNo: week.weekNo,
        crewRotations: [
          ...week.crewRotations.slice(0, num),
          ...getEmptyCrewRotationObject(Number(noOfCrewAdded)),
          ...week.crewRotations.slice(
            num,
            store.modelDetail?.totalCrewRotations
          ),
        ],
      };
    });
    const weeks = updatedCrewRotations?.map((element) => {
      let counter = -1;
      return {
        weekNo: element.weekNo,
        crewRotations: element.crewRotations.map((crew) => {
          counter++;
          return {
            pkCrewRotationId: crew.pkCrewRotationId,
            crewRotation: crewRotationItems[counter].value,
            schedTurns: crew.schedTurns,
          };
        }),
      };
    });

    const payload: EditModelReqBody = {
      modelDescription: store.modelDetail?.modelDescription ?? '',
      action: 'save',
      weeks: weeks,
    };
    await editModelMutateAsync(payload, {
      onSuccess: async () => {
        setOpenDrawer(false);
        notify.success({
          message: `You have successfully added a new crew.`,
        });
        await queryClient.resetQueries({
          queryKey: ['model-details'],
          type: 'active',
        });
      },
    });
  };

  return (
    <>
      <div className="flex flex-col flex-grow">
        <div className=" overflow-y-auto h-[calc(100vh-14rem)]">
          <div className="text-xs mb-3">
            Add Crew Rotation
            <RadioGroup
              isDisabled={isDisabled}
              value={position}
              orientation="horizontal"
              onChange={setPosition}
            >
              <Radio value={'Before'}> Before</Radio>
              <Radio value={'After'}> After</Radio>
            </RadioGroup>
          </div>

          <Select
            items={crewRotationItems.slice(
              0,
              store.modelDetail?.totalCrewRotations
            )}
            isDisabled={isDisabled}
            className="w-full"
            selectedKey={selectedCrewRotation}
            onSelectionChange={setSelectedCrewRotation}
          >
            {(item) => <SelectOptions id={item.id}>{item.value}</SelectOptions>}
          </Select>

          <Select
            items={numberOfCrewRotationsAdded}
            selectedKey={noOfCrewAdded}
            onSelectionChange={setNoOfCrewAdded}
            label="Select the number of crew rotations to be added"
            isDisabled={isDisabled}
            className="w-full mt-3"
          >
            {(item) => <SelectOptions id={item.id}>{item.id}</SelectOptions>}
          </Select>
        </div>
        <div className="flex md:flex-row flex-col items-end justify-end mt-6 gap-2">
          <Button
            variant="outline"
            color={'primary'}
            full={true}
            type="button"
            onPress={() => setOpenDrawer(false)}
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            color={'primary'}
            full={true}
            type={'submit'}
            onPress={handleAddCrew}
            isDisabled={isAddButtonDisabled}
          >
            Add
          </Button>
        </div>
      </div>
    </>
  );
};
