import { axios } from 'services/api/axios';
import { useQuery } from '@tanstack/react-query';
import {
  ProbEmployeesList,
  ProbationaryEmployeesListOptions,
  ReviewStatus,
} from '../types';

export const useProbationaryEmployeesList = ({
  reviewStatus,
  params,
}: {
  reviewStatus?: ReviewStatus;
  params?: ProbationaryEmployeesListOptions;
}) => {
  return useQuery({
    queryFn: () => getProbationaryEmployees(reviewStatus, params),
    queryKey: ['probationary-employees-list', reviewStatus, { ...params }],
  });
};

const getProbationaryEmployees = (
  reviewStatus?: ReviewStatus,
  params?: ProbationaryEmployeesListOptions
): Promise<ProbEmployeesList> => {
  if (reviewStatus) {
    return axios.get(`/v1/prob-emp/list/${reviewStatus}`, {
      params,
    });
  } else {
    return axios.get('/v1/prob-emp/list/', {
      params,
    });
  }
};
