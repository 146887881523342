import { Button } from '@uss/react-components';
import PerformanceReviewSelect from './form-sections/PerformanceReviewSelect';
import { RadioComponent } from './form-sections/RadioComponent';
import Loader from 'components/Skeleton/Loader';
import CardSkeleton from 'components/Skeleton/CardSkeleton';
import { QueryStatus } from '@tanstack/react-query';

export const PerformanceReviewFormLoader = ({
  status,
}: {
  status: QueryStatus;
}) => {
  return (
    <div className="w-full lg:w-4/5 lg:m-auto">
      <div className="mb-2 flex justify-between">
        <span>Performance Criteria</span>
        <div className="text-gray-400 cursor-pointer flex items-center justify-center underline">
          <span>Help</span>
        </div>
      </div>

      <div className="lg:grid lg:grid-cols-2 gap-x-10 gap-y-6 w-full">
        <PerformanceReviewSelect
          label={'Ability to work safely'}
          selectedValue={null}
          onSelect={() => void 0}
          disabled={true}
          status={status}
        />
        <PerformanceReviewSelect
          label={'Ability to work with Others'}
          selectedValue={null}
          onSelect={() => void 0}
          disabled={true}
          status={status}
        />
        <PerformanceReviewSelect
          label={'Quantity of Work'}
          selectedValue={null}
          onSelect={() => void 0}
          disabled={true}
          status={status}
        />
        <PerformanceReviewSelect
          label={'Quality of Work'}
          selectedValue={null}
          onSelect={() => void 0}
          disabled={true}
          status={status}
        />
        <PerformanceReviewSelect
          label={'Understanding of Written and Verbal Instructions'}
          selectedValue={null}
          onSelect={() => void 0}
          disabled={true}
          status={status}
        />
        <PerformanceReviewSelect
          label={'Attitude: Willingness and Cooperation'}
          selectedValue={null}
          onSelect={() => void 0}
          disabled={true}
          status={status}
        />
        <PerformanceReviewSelect
          label={'Overall Value of Employee'}
          selectedValue={null}
          onSelect={() => void 0}
          disabled={true}
          status={status}
        />
        <PerformanceReviewSelect
          label={'Overall rating'}
          selectedValue={null}
          onSelect={() => void 0}
          disabled={true}
          status={status}
        />

        <div className="bg-gray-200 h-0.5 col-span-2 my-6 lg:mb-0"></div>
      </div>
      <div className="mb-2 mt-4">
        <span>Attendance</span>
      </div>
      <div className="lg:grid lg:grid-cols-2 gap-x-10 gap-y-4 w-full">
        <div className="mb-4 lg:mb-0">
          <Loader rows={2} />

          <div className="my-4">
            <RadioComponent
              selected={''}
              name={'0'}
              radioOptions={['Loading']}
              readOnly={true}
              handleChange={() => void 0}
              label={'Loading'}
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-200 h-0.5 col-span-2 my-6 lg:my-2"></div>
      <div className="mt-4 mb-2">
        <span>Comments</span>
      </div>

      <div className="lg:grid lg:grid-cols-1 gap-x-10 gap-y-4 w-full mb-4">
        <CardSkeleton hasAvatar={false} contentRows={2} variant={'col-grid'} />
      </div>

      <div className="bg-gray-200 h-0.5 col-span-2 mb-4 lg:my-4"></div>
      <div className="mb-2">
        <span>Add Comments</span>
      </div>
      <div className="relative col-span-2 mb-4 lg:mb-0">
        <Loader rows={3} />
      </div>

      <div className="flex flex-col justify-start flex-wrap mt-6">
        <div className="text-xs text-gray-600">
          <Loader rows={1} />
        </div>
        <div className="flex flex-col lg:flex-row my-2">
          <Button
            variant="primary"
            className="ml-0 mb-2 lg:mb-0 sm:ml-0 text-lg w-full sm:w-auto"
            type="button"
            data-testid="button-1"
            isLoading={true}
          >
            Loading
          </Button>
          <Button
            variant="outline"
            className="ml-0 mb-2 lg:mb-0 lg:ml-2 text-lg w-full sm:w-auto"
            type="button"
            data-testid="button-2"
            isLoading={true}
          >
            Loading
          </Button>
        </div>
      </div>
    </div>
  );
};
