import { axios } from 'services/api/axios';
import { ApiBasicStatusResponse } from 'types/common';
import {
  CreateMileageRequest,
  MileageDetailsResponse,
  GetMileageQueryParams,
  MileageActionPayload,
} from '../types/mileageEntry';

export const mileageEntry = {
  post: (body: CreateMileageRequest): Promise<ApiBasicStatusResponse> => {
    return axios.post(`/v1/users/${body.ussID}/mileageDetails`, body);
  },
  get: (
    ussId: string,
    options?: GetMileageQueryParams | undefined
  ): Promise<MileageDetailsResponse | undefined> => {
    return axios.get(`/v1/users/${ussId}/mileageDetails`, {
      params: options,
    });
  },
  delete: (mileageDetailsId: string): Promise<MileageDetailsResponse> => {
    return axios.delete(`/v1/users/mileageDetails/${mileageDetailsId}`);
  },
  patch: (body: MileageActionPayload): Promise<MileageDetailsResponse> => {
    return axios.patch(`/v1/users/mileageDetails`, body.payload);
  },
};
