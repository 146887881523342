import { Autocomplete, Button, Drawer } from '@uss/react-components';
import React, { useState } from 'react';

import { useAddTemporaryEmployee } from 'features/timecards/api/addTemporaryEmployee';
import { BsPlusLg } from 'react-icons/bs';
import { Person } from 'features/people/types';
import { useSearchPeople } from 'features/people/api';
import { useNavigate } from 'react-router-dom';
import useUserRoles from 'hooks/useUserRoles';
import { ROLES } from 'features/roles';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import { CrewEmployeeType } from '../../types';
import AddTempEmployeeDrawerLoader from './Loaders/AddTempEmployeeDrawerLoader';
import { formatCrewKeyFromCode } from 'utils/formatCrewKey';

const AddTempEmployee = () => {
  const [isOpen, setIsOpen] = useState(false);
  // Getting the crew and Week out of the state
  const state = useNonUnionStore();
  const crew = state.crew;
  const week = state.params.week;
  const feature = state.feature;

  // Search
  const [inputValue, setInputValue] = useState('');

  const clearAll = () => {
    setInputValue(() => '');
    setSelectedOption(null);
  };

  const onClose = () => {
    clearAll();
    setIsOpen(false);
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.currentTarget.value);
    if (e.currentTarget.value === '') {
      clearAll();
    }
  };

  const { data: filteredOptions, status } = useSearchPeople({
    search: inputValue,
    payrollLocations: crew?.location,
    crewEligible: 'y',
    page: '1',
    pageSize: '50',
    primaryCrew: 'y',
    week: week ?? undefined,
    context: 'addTempEmp',
  });

  //Employee Selection
  const [selectedOption, setSelectedOption] = useState<Person | null>(null);
  const handleSelect = (option: Person): void => {
    setInputValue(option.lastName);
    setSelectedOption(option);
  };

  // Add Employee

  const navigate = useNavigate();
  const addEmployee = useAddTemporaryEmployee(crew ? crew.id : '');
  const handleAddButton = async () => {
    await addEmployee.mutateAsync([
      {
        week: week ?? '',
        ussId: selectedOption?.ussId.toString() ?? '',
        type: CrewEmployeeType.TEMP,
        context: state.feature === 'scheduling' ? 'schedule' : 'pay',
      },
    ]);
    clearAll();
    setIsOpen(false);
    if (selectedOption?.ussId) {
      navigate(
        `/non-union-${
          isPay ? 'pay' : 'scheduling'
        }/employee/${selectedOption.ussId.toString()}`
      );
    }
  };

  const isPay = feature === 'pay';

  //Checking if user is schedule pay viewer and it would be used to disable Add Temp Button
  const { roles } = useUserRoles();
  const isScheduledPayViewer = roles.includes(ROLES.SCHEDULE_PAY_VIEWER);
  const isUpdatingTimecardList = addEmployee.isPending;
  return (
    <>
      <Button
        variant="text"
        onClick={(): void => setIsOpen(true)}
        className="text-primary-400 w-full justify-start sm:w-auto font-semibold"
        startIcon={<BsPlusLg size="1rem" />}
        disabled={isScheduledPayViewer || !crew?.id}
      >
        Add Temp Employee
      </Button>

      <Drawer
        placement={window.innerWidth > 768 ? 'right' : 'bottom'}
        title="Add Temporary Employee"
        isOpen={isOpen}
        onClose={onClose}
        closeButton={window.innerWidth > 768 ? false : true}
        className="w-full sm:w-1/2 md:w-1/3 xl:w-1/4 2xl:w-1/5 md:top-0 top-18 md:rounded-t-none rounded-t-md"
      >
        {isUpdatingTimecardList ? (
          <AddTempEmployeeDrawerLoader />
        ) : (
          <div className="flex-grow">
            <div className="flex flex-col">
              <span className="text-xs text-gray-700 font-normal">Crew</span>
              <span className="text-gray-700 text-base font-normal">
                {formatCrewKeyFromCode(crew?.code)}
              </span>
            </div>
            <div className="py-2 w-64">
              <Autocomplete
                onClose={clearAll}
                label="Auto"
                value={inputValue}
                onChange={handleFilter}
                placeholder="Search Name / USSID"
              >
                <div data-testid="description-msg">
                  {status === 'success' &&
                    (!filteredOptions.items.length ? (
                      <span className="flex justify-center">
                        No results found!!
                      </span>
                    ) : (
                      filteredOptions.items.map((option, index) => {
                        return (
                          <AutocompleteOption
                            option={option}
                            handleSelect={handleSelect}
                            key={index}
                          />
                        );
                      })
                    ))}
                  {status === 'pending' &&
                    (inputValue.length < 3 ? (
                      <span className="flex justify-center">
                        Enter at least 3 characters
                      </span>
                    ) : (
                      <span className="flex justify-center">
                        Loading Results!!!
                      </span>
                    ))}
                  {status === 'error' && (
                    <span className="flex justify-center">
                      Error occured!!!
                    </span>
                  )}
                </div>
              </Autocomplete>
            </div>

            <div>
              {selectedOption && (
                <div
                  className="py-2 px-2 grid grid-cols-2 "
                  data-testid="selected-employee-details"
                >
                  <div className=" col-span-2 flex flex-col">
                    <span className="text-base font-semibold text-gray-700">
                      {selectedOption.lastName}
                      {selectedOption.suffix !== null &&
                        ' ' + selectedOption.suffix}
                      , {selectedOption.firstName}
                      <span className="pl-2">
                        {selectedOption.middleName !== null &&
                          selectedOption.middleName}
                      </span>
                    </span>
                    <span className="text-xs text-gray-700">
                      {selectedOption.primaryCrew}
                    </span>
                  </div>

                  <div className="flex flex-col py-2">
                    <span className=" text-xs text-gray-700">
                      Employee Type
                    </span>
                    <p className="text-sm text-gray-700 ">
                      {`${selectedOption.empClass ?? '-'} - ${
                        selectedOption.empClassDesc ?? '-'
                      }`}
                    </p>
                  </div>

                  <div className="flex flex-col py-2">
                    <span className=" text-xs text-gray-700">Job Title</span>
                    <p className=" text-sm  text-gray-700">
                      {selectedOption.jobTitle}
                    </p>
                  </div>
                  <div className="flex flex-col py-1">
                    <span className=" text-xs text-gray-700">USS ID</span>
                    <p className="text-sm text-gray-700 ">
                      {selectedOption.ussId}
                    </p>
                  </div>

                  <div className="flex flex-col py-1">
                    <span className=" text-xs text-gray-700">Supervisor</span>
                    <p className=" text-sm  text-gray-700">
                      {selectedOption.supervisorDisplayName}
                    </p>
                  </div>
                  <div className=" col-span-2 flex flex-col py-2">
                    <span className=" text-xs text-gray-700">Status</span>
                    <span className="text-sm text-gray-700">
                      {selectedOption.status.charAt(0).toUpperCase() +
                        selectedOption.status.slice(1)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <Drawer.Controls>
          <Button
            className="md:mb-0 mb-2  w-full"
            variant="outline"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="md:ml-4 w-full"
            disabled={!selectedOption || isUpdatingTimecardList}
            onClick={handleAddButton}
          >
            Add
          </Button>
        </Drawer.Controls>
      </Drawer>
    </>
  );
};

interface AutocompleteOptionProps {
  option: Person;
  handleSelect: (option: Person) => void;
}

const AutocompleteOption = ({
  option,
  handleSelect,
}: AutocompleteOptionProps) => {
  return (
    <div key={option.ussId}>
      <div
        className="cursor-pointer p-2 grid grid-cols-2 hover:bg-blue-50"
        role="button"
        aria-hidden="true"
        onClick={() => handleSelect(option)}
      >
        <div className=" col-span-2 flex flex-col">
          <span className="text-base font-semibold text-gray-700">
            {option.displayName}
          </span>
          <span className="text-xs text-gray-700">{option.primaryCrew}</span>
        </div>

        <div className="flex flex-col pt-1">
          <span className=" text-xs text-gray-700">Employee Type</span>
          <p className="text-sm text-gray-700 ">{option.empClassDesc}</p>
        </div>

        <div className="flex flex-col pt-1">
          <span className=" text-xs text-gray-700">Job Title</span>
          <p className=" text-sm text-gray-700 line-clamp-2">
            {option.jobTitle}
          </p>
        </div>
      </div>
      <div className="uss-h-0.5 uss-bg-gray-200"></div>
    </div>
  );
};
export default AddTempEmployee;
