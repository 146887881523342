export const getModalText = (
  buttonRef: string,
  crewStatus: string | undefined
) => {
  if (buttonRef === 'Add') {
    return (
      <div>
        Are you sure you want Add this crew?
        <div> Once Added, this crew will become available to users.</div>
      </div>
    );
  } else if (buttonRef === 'Request') {
    return <div>Are you sure you want Request this crew?</div>;
  } else if (buttonRef === 'Save') {
    return <div> Are you sure you want to save the changes?</div>;
  } else if (buttonRef === 'Archive') {
    return (
      <div>
        Are you sure you want to Archive this crew?
        <div>All Pay Approvers will be removed once Archived. </div>
        Once Archived, this crew will no longer be available to users.
      </div>
    );
  } else if (buttonRef === 'Approve') {
    if (crewStatus === 'denied') {
      return (
        <div>
          Are you sure you want to Approve this crew that was previously
          Rejected?
          <div>Once Approved, this crew will become available to users.</div>
        </div>
      );
    } else {
      return (
        <div>
          Are you sure you want Approve this crew?
          <div>Once Approved, this crew will become available to users.</div>
        </div>
      );
    }
  } else if (buttonRef === 'Restore') {
    return (
      <div>
        Are you sure you want to Restore this crew that was previously Archived?
        <div>Once Restored, this crew will become available to users.</div>
      </div>
    );
  } else if (buttonRef === 'Deny') {
    return (
      <div>
        Are you sure you want Deny this crew?
        <div> Once Denied, this crew will not become available to users.</div>
      </div>
    );
  }
};

export const getSuccessMessage = (buttonRef: string) => {
  let message = 'You have successfully saved the changes.';
  if (buttonRef === 'Request') {
    message = 'Your request for a new crew has been submitted.';
  } else if (buttonRef === 'Archive') {
    message = 'You have archived this crew.';
  } else if (buttonRef === 'Approve') {
    message = 'You have successfully approved the crew.';
  } else if (buttonRef === 'Deny') {
    message = 'You have denied the crew.';
  } else if (buttonRef === 'Restore') {
    message = 'You have successfully restored the crew.';
  }
  return message;
};
