import Page from 'components/Layouts/Page';
import { useProbationaryEmployeesReview } from 'features/probationary-tracking/api/getProbationaryEmployeeReview';
import ReviewDetailsCard from 'features/probationary-tracking/components/common/PerformanceReviewForm/ReviewDetailsCard';
import { PerformanceReviewForm } from 'features/probationary-tracking/components/common/PerformanceReviewForm/PerformanceReviewForm';
import { NavLink, useParams } from 'react-router-dom';
import Error from 'components/Error/Error';
import { ErrorBoundary } from 'react-error-boundary';
import DetailsSkeleton from 'components/Skeleton/DetailsSkeleton';
import { PerformanceReviewFormLoader } from 'features/probationary-tracking/components/common/PerformanceReviewForm/PerformanceReviewFormLoader';
import { ReviewGet } from 'features/probationary-tracking/types';

const ReviewerReview = () => {
  const { ussId = '', id = '' } = useParams<'ussId' | 'id'>();

  const { data, status } = useProbationaryEmployeesReview(ussId, id);

  const breadCrumbNodes = [
    <NavLink to="/probationary-tracking/reviewer-view/employees" key="bc-1">
      Probationary Employees
    </NavLink>,
    <NavLink
      to={`/probationary-tracking/reviewer-view/employees/${ussId}`}
      key="bc-2"
    >
      Employee Details
    </NavLink>,
  ];

  return (
    <Page
      breadcrumbs={breadCrumbNodes}
      heading={`Performance Review #${
        data?.reviewNumber ? data.reviewNumber : '-'
      }`}
    >
      <ErrorBoundary
        fallbackRender={({ error }) => {
          return (
            <Error
              width="300"
              height="300"
              title="Something went wrong!"
              description={error.message}
            />
          );
        }}
      >
        {status === 'error' && <Error />}
        {status === 'pending' && (
          <>
            <Page.Section>
              <div className="my-4">
                <DetailsSkeleton />
              </div>
            </Page.Section>
            <Page.Section>
              <div className="bg-white px-4 py-4 sm:bg-white rounded-md">
                <PerformanceReviewFormLoader status={status} />
              </div>
            </Page.Section>
          </>
        )}
        {status === 'success' && <Content data={data} />}
      </ErrorBoundary>
    </Page>
  );
};
const Content = ({ data }: { data: ReviewGet }) => {
  return (
    <>
      <Page.Section>
        <ReviewDetailsCard employeeData={data} />
      </Page.Section>
      <Page.Section>
        <div className="bg-white px-4 py-4 sm:bg-white rounded-md">
          <PerformanceReviewForm data={data} />
        </div>
      </Page.Section>
    </>
  );
};
export default ReviewerReview;
