export const getDaysFormat = (weekBeginIndex: number) => {
  const days = [
    {
      dayIndex: 0,
      value: 'Sunday',
    },
    {
      dayIndex: 1,
      value: 'Monday',
    },
    {
      dayIndex: 2,
      value: 'Tuesday',
    },
    {
      dayIndex: 3,
      value: 'Wednesday',
    },
    {
      dayIndex: 4,
      value: 'Thursday',
    },
    {
      dayIndex: 5,
      value: 'Friday',
    },
    {
      dayIndex: 6,
      value: 'Saturday',
    },
  ];
  if (weekBeginIndex === 0) {
    const newArray = days.sort((a, b) => a.dayIndex - b.dayIndex);
    return newArray;
  } else {
    const newData = days;

    newData.sort((a, b) => a.dayIndex - b.dayIndex);
    const lastElement = newData.pop();
    return [lastElement, ...newData];
  }
};
