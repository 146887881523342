import { Alert } from '@uss/react-components';
import React from 'react';

type Variant = 'success' | 'danger' | 'warning' | 'info';

interface Props {
  message: string | JSX.Element;
  variant: Variant;
  onClose: () => void;
}

const Notification = ({ message, variant, onClose }: Props) => {
  return (
    <Alert variant={variant} closable onClose={onClose}>
      {message}
    </Alert>
  );
};

export default Notification;
