import { Autocomplete } from '@uss/react-components';
import { PeopleOptions, Person } from 'features/people/types';
import { useSearchPeople } from 'features/people/api';
import React, { useState } from 'react';
import SearchSuggestion from './SearchSuggestion';

const SearchEmployee = ({
  selectedOption,
  setSelectedOption,
  sourcePayrollLocation,
}: {
  selectedOption: Person | null;
  setSelectedOption: React.Dispatch<React.SetStateAction<Person | null>>;
  sourcePayrollLocation?: string;
}) => {
  const [inputValue, setInputValue] = useState('');

  const searchPayload: PeopleOptions = {
    search: inputValue,
    payrollLocations: sourcePayrollLocation,
    crewEligible: 'y',
    page: '1',
    pageSize: '50',
  };

  const handleSelect = (option: Person): void => {
    setInputValue(option.lastName);
    setSelectedOption(option);
  };

  const { data: filteredOptions, status } = useSearchPeople(searchPayload);

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.currentTarget.value);
    if (e.currentTarget.value === '') {
      clearAll();
    }
  };

  const clearAll = () => {
    setInputValue(() => '');
    setSelectedOption(null);
  };
  return (
    <div className=" flex flex-col my-4">
      <Autocomplete
        onClose={() => clearAll()}
        label="Auto"
        value={inputValue}
        onChange={handleFilter}
        placeholder="Search Name / USSID"
      >
        <SearchSuggestion
          numberOfSearchChars={inputValue.length}
          options={filteredOptions}
          onSelect={handleSelect}
          status={status}
        />
      </Autocomplete>
      {selectedOption && (
        <div className=" col-span-2 flex flex-col my-2">
          <span className="text-base font-semibold text-gray-700">
            {selectedOption.displayName}
          </span>
          <span className="text-xs text-gray-700">{selectedOption.ussId}</span>
        </div>
      )}
    </div>
  );
};

export default SearchEmployee;
