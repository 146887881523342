type colorCode = 1 | 2 | 3 | 4;
function getSum(n: number) {
  let sum = 0;

  /* sum up all digits in ussId to single digit beetween 0 to 9
  for example-
  if ussId is 1234 then sum (1+2+3+4)=10 : 10=10+1=1*/

  while (n > 0 || sum > 9) {
    if (n === 0) {
      n = sum;
      sum = 0;
    }
    sum = sum + (n % 10);
    n = Math.floor(n / 10);
  }
  return sum;
}
/*Accepts ussId as a parameter(n)*/
export const avatarColor = (n: number): colorCode => {
  const sum = getSum(n);
  /*Applying colorcodes from 1 to 4 based on sum (0 to 9)*/
  switch (sum) {
    case 0: {
      return 1;
    }
    case 1: {
      return 1;
    }
    case 2: {
      return 2;
    }
    case 3: {
      return 3;
    }
    case 4: {
      return 4;
    }
    case 5: {
      return 4;
    }
    case 6: {
      return 3;
    }
    case 7: {
      return 2;
    }
    case 8: {
      return 1;
    }
    case 9: {
      return 4;
    }
    default:
      return 3;
  }
};
