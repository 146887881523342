import {
  QueryClient,
  DefaultOptions,
  QueryCache,
  MutationCache,
} from '@tanstack/react-query';
import { notify } from 'components/Notification/useNotification';
import { WMSError } from 'types/common';

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: WMSError;
  }
}

const queryConfig: DefaultOptions = {
  queries: { refetchOnWindowFocus: false },
};

export const queryClient = new QueryClient({
  defaultOptions: queryConfig,
  mutationCache: new MutationCache({
    onError: (error, variables, ctx, mutation) => {
      const customMsg = mutation.meta?.errorMessage;
      if (
        error.response?.data.errors &&
        error.config.url &&
        error.config.method
      ) {
        notify.danger({
          message: `Status: ${error.response.status} - ${
            error.response.data.errors[0].message
          } - ${error.config.method.toUpperCase()} ${error.config.url}`,
        });
        return;
      }
      if (typeof customMsg === 'string') {
        notify.danger({
          message: `Error Occurred: ${
            error.response ? error.response.status : ''
          }  ${customMsg}`,
        });
        return;
      }

      if (error.response) {
        notify.danger({
          message: `Status: ${error.response.status} - ${error.message}`,
        });
        return;
      }

      notify.danger({
        message: 'Error occurred and no response was received from the server',
      });
    },
  }),
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (error.response?.data.errors) {
        notify.danger({
          message: `${error.response.data.errors[0].message} - Status: ${error.response.status}`,
        });
        return;
      }
      if (error.response?.status) {
        notify.danger({
          message: `Status: ${error.response.status} - ${error.message}`,
        });
        return;
      }
    },
  }),
});
