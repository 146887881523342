import { PtoSummary } from 'features/paid-time-off/types';
import React from 'react';
import TotalsValue from './TotalsValue';
import { SplitTotalValues } from './SplitTotalValues';

const IntegratedNonExemptSummary = ({ summary }: { summary: PtoSummary }) => {
  return (
    <>
      <div className="w-10/12">
        <div className="w-full pb-2">
          <h3
            className="text-lg font-semibold w-full"
            data-testid="vacation-title"
          >
            Vacation
          </h3>
        </div>
        <div className="lg:grid lg:grid-flow-col lg:gap-4 w-full ">
          <div className="lg:col-span-3  lg:col-start-1 ">
            <div className="flex flex-grow items-center border-b-4 lg:border-b-0 lg:border-r-4 border-gray-100 pb-3 lg:pb-0 mb-3 lg:mb-0">
              <div className="w-1/2 lg:w-auto px-4 mb-4 lg:mb-0">
                <TotalsValue
                  label="Eligible"
                  value={summary.totalEligible.total}
                  unitOfMeasure={summary.unitOfMeasure}
                />
              </div>
              <span className="lg:flex-grow lg:w-1/5 px-2">
                <SplitTotalValues
                  options={{
                    firstValue: {
                      label: 'Current Year',
                      value: summary.totalEligible.requestedYear,
                      unitOfMeasure: 'days',
                    },
                    secondValue: {
                      label: 'Purchased Vacation',
                      value: summary.totalEligible.purchased,
                      unitOfMeasure: 'days',
                    },
                  }}
                />
              </span>
            </div>
          </div>
          <div className="flex justify-between flex-wrap lg:flex-nowrap lg:col-span-9  lg:col-start-4 ">
            <div className="w-1/2 lg:w-auto pr-4 mb-4 lg:mb-0 flex-grow">
              <TotalsValue
                label="Taken"
                value={summary.taken.total}
                unitOfMeasure={summary.unitOfMeasure}
              />
            </div>
            <div className="w-1/2 lg:w-auto px-4 mb-4 lg:mb-0  flex-grow">
              <TotalsValue
                label="Approved"
                value={summary.approved.total}
                unitOfMeasure={summary.unitOfMeasure}
              />
            </div>
            <div className="w-1/2 lg:w-auto pr-4 lg:pr-0 lg:px-4 mb-4 lg:mb-0 flex-grow">
              <TotalsValue
                label="Requested"
                value={summary.requested.total}
                unitOfMeasure={summary.unitOfMeasure}
              />
            </div>
            <div className="w-1/2 lg:w-auto px-4 mb-4 lg:mb-0  flex-grow">
              <TotalsValue
                label="Adjustment"
                value={summary.adjustment.total}
                unitOfMeasure={summary.unitOfMeasure}
              />
            </div>

            <div className="w-full lg:w-auto  pt-3 lg:pt-0 lg:pl-4 border-t-4 lg:border-t-0 lg:border-l-4 border-gray-100 flex-grow">
              <TotalsValue
                label="Remaining"
                value={summary.remaining}
                unitOfMeasure={summary.unitOfMeasure}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="border-l-4 border-gray-100 pl-6">
        <div className="w-full pb-2">
          <h3
            className="text-lg font-semibold w-full"
            data-testid="personal-day-title"
          >
            Personal Day
          </h3>
        </div>
        <div className="lg:grid lg:grid-flow-col lg:gap-4 w-full ">
          <div className="w-1/2 lg:w-auto px-4 mb-4 lg:mb-0  flex-grow">
            <TotalsValue
              label="Eligible"
              value={summary.personal.total}
              unitOfMeasure={summary.unitOfMeasure}
            />
          </div>
          <div className="w-1/2 lg:w-auto px-4 mb-4 lg:mb-0  flex-grow">
            <TotalsValue
              label="Remaining"
              value={summary.personal.remaining}
              unitOfMeasure={summary.unitOfMeasure}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IntegratedNonExemptSummary;
