import {
  Paycode,
  PaycodeForm,
  paycodeFormVisible,
  PaycodeViewForm,
} from 'features/paycode';
import { usePaycodeById } from 'features/paycode/api/getPaycodeById';
import {
  PAYCODE_STATUS,
  PAYROLL_GROUP_TYPE,
  PAYROLL_INDICATORS,
  SHIFT_ASSIGNMENT,
} from 'features/paycode/constants';
import { ROLES } from 'features/roles';
import useUserRoles from 'hooks/useUserRoles';
import { useParams } from 'react-router-dom';

const Details = () => {
  const initialPaycode: Paycode = {
    id: '0',
    code: '',
    description: '',
    useCaseDescription: '',
    type: '',
    earningsCode: '',
    comments: '',
    payrollGroups: [
      {
        id: 'union_integrated_mill_hourly',
        name: 'Union Integrated Mill: Hourly',
        payrollGroupType: PAYROLL_GROUP_TYPE.UNION,
        active: false,
        restricted: false,
        shifts: [
          {
            name: SHIFT_ASSIGNMENT.EIGHT_HOUR,
            value: false,
          },
          {
            name: SHIFT_ASSIGNMENT.TWELVE_HOUR,
            value: false,
          },
        ],
        payrollIndicators: [
          {
            name: PAYROLL_INDICATORS.VACATION,
            value: false,
          },
          {
            name: PAYROLL_INDICATORS.PAYABLE,
            value: false,
          },
          {
            name: PAYROLL_INDICATORS.REPORT,
            value: false,
          },
          {
            name: PAYROLL_INDICATORS.INCENTIVE,
            value: false,
          },
        ],
      },
      {
        id: 'union_integrated_mill_biweekly_salary',
        name: 'Union Integrated Mill: Biweekly Salary',
        payrollGroupType: PAYROLL_GROUP_TYPE.UNION,
        active: false,
        restricted: false,
        shifts: [
          {
            name: SHIFT_ASSIGNMENT.EIGHT_HOUR,
            value: false,
          },
          {
            name: SHIFT_ASSIGNMENT.TWELVE_HOUR,
            value: false,
          },
        ],
        payrollIndicators: [
          {
            name: PAYROLL_INDICATORS.VACATION,
            value: false,
          },
          {
            name: PAYROLL_INDICATORS.PAYABLE,
            value: false,
          },
          {
            name: PAYROLL_INDICATORS.REPORT,
            value: false,
          },
        ],
      },
      {
        id: 'non_union_mini_mill_hourly',
        name: 'Non Union Mini Mill: Hourly',
        payrollGroupType: PAYROLL_GROUP_TYPE.NON_UNION,
        active: false,
        restricted: false,
        shifts: [
          {
            name: SHIFT_ASSIGNMENT.EIGHT_HOUR,
            value: false,
          },
          {
            name: SHIFT_ASSIGNMENT.EIGHT_DAY_HOUR,
            value: false,
          },
          {
            name: SHIFT_ASSIGNMENT.TWELVE_HOUR,
            value: false,
          },
        ],
        payrollIndicators: [
          {
            name: PAYROLL_INDICATORS.PTO,
            value: false,
          },
          {
            name: PAYROLL_INDICATORS.PAYABLE,
            value: false,
          },
          {
            name: PAYROLL_INDICATORS.REPORT,
            value: false,
          },
          {
            name: PAYROLL_INDICATORS.PRODUCTION_BONUS,
            value: false,
          },
        ],
      },

      {
        id: 'non_union_integrated_mill_exempt',
        name: 'Non Union Integrated Mill: Exempt',
        payrollGroupType: PAYROLL_GROUP_TYPE.NON_UNION,
        active: false,
        restricted: false,
        shifts: [
          {
            name: SHIFT_ASSIGNMENT.EIGHT_HOUR,
            value: false,
          },
          {
            name: SHIFT_ASSIGNMENT.TWELVE_HOUR,
            value: false,
          },
        ],
        payrollIndicators: [
          {
            name: PAYROLL_INDICATORS.VACATION,
            value: false,
          },
          {
            name: PAYROLL_INDICATORS.PAYABLE,
            value: false,
          },
          {
            name: PAYROLL_INDICATORS.REPORT,
            value: false,
          },
        ],
      },
      {
        id: 'non_union_integrated_mill_non_exempt_full_time',
        name: 'Non Union Integrated Mill: Non Exempt Full Time',
        payrollGroupType: PAYROLL_GROUP_TYPE.NON_UNION,
        active: false,
        restricted: false,
        shifts: [
          {
            name: SHIFT_ASSIGNMENT.EIGHT_HOUR,
            value: false,
          },
          {
            name: SHIFT_ASSIGNMENT.TWELVE_HOUR,
            value: false,
          },
        ],
        payrollIndicators: [
          {
            name: PAYROLL_INDICATORS.VACATION,
            value: false,
          },
          {
            name: PAYROLL_INDICATORS.PAYABLE,
            value: false,
          },
          {
            name: PAYROLL_INDICATORS.REPORT,
            value: false,
          },
        ],
      },
      {
        id: 'non_union_integrated_mill_non_exempt_part_time',
        name: 'Non Union Integrated Mill: Non Exempt Part Time',
        payrollGroupType: PAYROLL_GROUP_TYPE.NON_UNION,
        active: false,
        restricted: false,
        shifts: [
          {
            name: SHIFT_ASSIGNMENT.EIGHT_HOUR,
            value: false,
          },
        ],
        payrollIndicators: [
          {
            name: PAYROLL_INDICATORS.PAYABLE,
            value: false,
          },
          {
            name: PAYROLL_INDICATORS.REPORT,
            value: false,
          },
        ],
      },
    ],
    status: {
      code: PAYCODE_STATUS.ACTIVE,
      description: 'Active',
      actionTakenOn: '',
      actionTakenBy: '',
    },
    createdByUssid: '',
    createdByName: '',
    createDt: '',
    restrictedUsage: false,
    createdBy: '',
    plantTypes: null,
  };
  // get user roles
  const { roles, uss_id, isSuperAdmin } = useUserRoles();

  //get id from params
  const { id = '' } = useParams<'id'>();

  //get selected paycode data
  const { data, status } = usePaycodeById(id);
  const code = data?.status.code;

  //Check who can edit paycodes which are pending state
  const paycodeCreatorID = data?.createdByUssid;
  const isPaycodeCreator = uss_id === paycodeCreatorID;

  const statusCode = data?.status.code ?? PAYCODE_STATUS.ACTIVE;
  const { canEditForm, canSave, canApprove, canReject, enableCodeType } =
    paycodeFormVisible(id, roles, isPaycodeCreator, statusCode);

  const canArchive =
    (roles.includes(ROLES.SUPER_ADMIN) ||
      roles.includes(ROLES.SUPER_PAYROLL_ADMIN)) &&
    code === PAYCODE_STATUS.ACTIVE;
  const canRestore =
    (roles.includes(ROLES.SUPER_ADMIN) ||
      roles.includes(ROLES.SUPER_PAYROLL_ADMIN)) &&
    code === PAYCODE_STATUS.ARCHIVED;

  return (
    <>
      {canEditForm ? (
        <>
          {(status === 'success' || id === '') && (
            <PaycodeForm
              id={id === '' ? '0' : id}
              paycode={id === '' ? initialPaycode : data}
              roles={roles}
              enableCodeType={enableCodeType}
              isSuperAdmin={isSuperAdmin}
              buttonState={{
                canSave: canSave,
                canApprove: canApprove,
                canArchive: canArchive,
                canRestore: canRestore,
                canReject: canReject,
              }}
            />
          )}
        </>
      ) : (
        <PaycodeViewForm
          buttonState={{
            canApprove: canApprove,
            canRestore: canRestore,
          }}
        />
      )}
    </>
  );
};

export default Details;
