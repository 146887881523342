/**
 * @description Hours entered must be in correct PTO Increments
 * @returns
 */

export const sellBackHoursIncrement = (
  sellBackHours: number,
  increment: number
) => {
  if (increment) {
    if (sellBackHours % increment !== 0) {
      return false;
    }
  }
  return true;
};
