import { addYears, format, subYears } from 'date-fns';
import { Org } from 'features/org';
import { appNewDate } from 'utils/appNewDate';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { PtoFilterStatusType } from '../types';

interface StoreState {
  /** Month/Year value used on the main Crew PTO screen */
  date: string;
  selectedPlant: Org | null;
  selectedPayrollLocation: Org | null;
  selectedDivision: Org | null;
  selectedDepartment: Org | 'all' | null;
  selectedCrew: Org | 'all' | null;
  /** USS Ids selected in the Crew PTO Mutli Select */
  ussids: string[];
  /** Selected date from calendar */
  selectedDate: string;
  /** Selected status from pto details status filter*/
  status: PtoFilterStatusType;

  /** Updates the date  */
  updateDate: (value: Date) => void;

  /** Set selected date from calendar */
  setSelectedDate: (value: string) => void;

  /** Set status date from calendar */
  setStatus: (value: PtoFilterStatusType) => void;

  // /** create payload for pto data (org-totals api) */
  // getPtoPayload: () => GetCrewParamsBody;

  // /** create payload for pto data (org-details api) */
  // getPtoDetailsPayload: () => GetOrgDetailsParamsBody;

  setPlant: (value: Org) => void;
  setPayrollLocation: (value: Org) => void;
  setDivision: (value: Org | null) => void;
  setDepartment: (value: Org | 'all' | null) => void;
  setCrew: (value: Org | 'all' | null) => void;
  /** Replaces the array of employee ussids with a new array, use for the multi select component */
  updateEmployees: (value: string[]) => void;
}
export const useCrewPtoStore = create<StoreState>()(
  devtools(
    immer(
      (set, get) => ({
        selectedPlant: null,
        selectedPayrollLocation: null,
        selectedDivision: null,
        selectedDepartment: 'all',
        selectedCrew: 'all',
        ussids: [],
        date: format(appNewDate(), 'yyyy-MM'),
        selectedDate: format(appNewDate(), 'yyyy-MM-dd'),
        status: 'all',
        updateDate: (value) =>
          set(
            (state) => {
              if (
                value.getFullYear() >= subYears(new Date(), 1).getFullYear() &&
                value.getFullYear() <= addYears(new Date(), 1).getFullYear()
              ) {
                state.date = format(value, 'yyyy-MM');
              }
              state.status = 'all';
              state.selectedDate = '';
            },
            false,
            'update-date'
          ),
        setPlant: (value) =>
          set(
            (state) => {
              state.selectedPlant = value;
              state.selectedPayrollLocation = null;
              state.selectedDivision = null;
              state.selectedDepartment = 'all';
              state.selectedCrew = 'all';
              state.ussids = [];
            },
            false,
            'update-plant'
          ),
        setPayrollLocation: (value) =>
          set(
            (state) => {
              state.selectedPayrollLocation = value;
              state.selectedDivision = null;
              state.selectedDepartment = 'all';
              state.selectedCrew = 'all';
              state.ussids = [];
            },
            false,
            'update-location'
          ),
        setDivision(value) {
          set(
            (state) => {
              state.selectedDivision = value;
              state.selectedDepartment = 'all';
              state.selectedCrew = 'all';
              state.ussids = [];
            },
            false,
            'update-division'
          );
        },
        setDepartment(value) {
          set(
            (state) => {
              state.selectedDepartment = value;
              state.selectedCrew = 'all';
              state.ussids = [];
            },
            false,
            'update-department'
          );
        },
        setCrew(value) {
          set(
            (state) => {
              state.selectedCrew = value;
              state.ussids = [];
            },
            false,
            'set-crew'
          );
        },
        updateEmployees: (value) =>
          set(
            (state) => {
              state.ussids = [...value];
            },
            false,
            'update-employee'
          ),
        setSelectedDate: (value) =>
          set(
            (state) => {
              state.selectedDate = value;
              state.status = 'all';
            },
            false,
            'set-selected-date'
          ),
        setStatus: (value) =>
          set(
            (state) => {
              state.status = value;
            },
            false,
            'set-status'
          ),
        // getPtoPayload: () => {
        //   let _type: 'crew' | 'dept' | 'ussid' | '' = '';
        //   let _ids: string[] = [];

        //   if (get().ussids.length > 0) {
        //     _ids = get().ussids;
        //     _type = 'ussid';
        //   } else if (get().crews.length > 0) {
        //     _ids = get().crews;
        //     _type = 'crew';
        //   } else if (get().departments.length > 0) {
        //     _ids = get().departments;
        //     _type = 'dept';
        //   }

        //   return {
        //     params: {
        //       type: _type,
        //       yearMonth: get().date,
        //     },
        //     body: {
        //       ids: _ids,
        //     },
        //   } as GetCrewParamsBody;
        // },
        // getPtoDetailsPayload: () => {
        //   let _ids: string[] = [];
        //   const params: CrewPtoOrgDetailsOptions = {
        //     type: 'dept',
        //     date: get().selectedDate,
        //   };

        //   if (get().status !== 'all') {
        //     params.status = get().status;
        //   } else delete params.status;

        //   if (get().ussids.length > 0) {
        //     _ids = get().ussids;
        //     params.type = 'ussid';
        //   } else if (get().crews.length > 0) {
        //     _ids = get().crews;
        //     params.type = 'crew';
        //   } else if (get().departments.length > 0) {
        //     _ids = get().departments;
        //     params.type = 'dept';
        //   }
        //   const payload: GetOrgDetailsParamsBody = {
        //     params: params,
        //     body: {
        //       ids: _ids,
        //     },
        //   } as GetOrgDetailsParamsBody;
        //   return payload;
        // },
      })
      // persist(
      //   {
      //     name: 'crew-pto-store',
      //     getStorage: () => sessionStorage,
      //   }
      // )
    )
  )
);
