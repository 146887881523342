import {
  UnapprovedCrew,
  UnapprovedCrewsGetOptions,
} from 'features/paypulloff/types';
import { useQuery } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { WMSAPIResponse } from 'types/common';

type UnapprovedCrewList = WMSAPIResponse<UnapprovedCrew>;

const get_unapproved_crews = (
  payrollName: string,
  params: UnapprovedCrewsGetOptions
): Promise<UnapprovedCrewList> => {
  return axios.get(`/v1/payrolls/${payrollName}/unapproved-crews`, {
    params,
  });
};

export const useUnapprovedCrews = (
  payrollName: string,
  params: UnapprovedCrewsGetOptions
) => {
  return useQuery<UnapprovedCrewList>({
    queryKey: ['payPullOffUnapprovedCrews', { payrollName, ...params }],
    queryFn: () => get_unapproved_crews(payrollName, params),
    staleTime: 5000,
    gcTime: 5000,
    refetchOnMount: true,
    enabled: payrollName.length > 0,
  });
};
