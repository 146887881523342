import { z } from 'zod';
import { requestPtoDateRange } from '../validations/request-pto/request-pto-date-range';
import { appNewDate } from 'utils/appNewDate';
import { format } from 'date-fns';
import { addPtoContext } from './v2';

export const RequestPtoSchema = z
  .object({
    ussId: z.string().nonempty(),
    paycode: z
      .string()
      .nonempty({ message: 'Pay Code is required' })
      .min(1, 'Pay Code is required')
      .max(2, 'Pay Code cannot be more than 2 characters'),
    paycodeDescription: z.string(),
    startDate: z
      .string({
        required_error: 'Start date is required',
        invalid_type_error: 'Start date is required',
      })
      .min(1, 'Start date is required'),
    endDate: z
      .string()
      .nullable()
      .default(null)
      .transform((v) => {
        if (v === '') {
          return null;
        } else {
          return v;
        }
      }),
    excludeWeekends: z.coerce.boolean().default(false),
    excludeHolidays: z.boolean().default(false),
    chargeYear: z.string().default(format(appNewDate(), 'yyyy')),
    notes: z
      .string()
      .max(100, 'Note cannot be more than 100 characters')
      .nullable()
      .default(null)
      .transform((v) => {
        if (v === '') {
          return null;
        } else {
          return v;
        }
      }),
    ptoDate: z.string(),
    status: z
      .union([
        z.literal('approved'),
        z.literal('requested'),
        z.literal('denied'),
        z.literal('blackout'),
        z.literal('adjustment'),
        z.literal('taken'),
        z.literal('removed'),
      ])
      .default('requested'),
      context: addPtoContext
  })
  .refine((val) => requestPtoDateRange(val.startDate, val.endDate), {
    message: 'End date cannot be earlier than start date.',
    path: ['endDate'],
  });
