import { EmployeeDisplayProps } from 'features/probationary-tracking/types';
import { createContext, useContext } from 'react';

const EmployeeCardBodyContext = createContext<{
  data: EmployeeDisplayProps;
} | null>(null);

export function useEmployeeCardBodyContext() {
  const context = useContext(EmployeeCardBodyContext);

  if (!context) {
    throw new Error(
      'EmployeeCard.* component must be rendered as child of the EmployeeCard'
    );
  }

  return context;
}

export default EmployeeCardBodyContext;
