import { format } from 'date-fns';
import React, { memo } from 'react';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import getFormattedCrewFilterStatus from 'features/timecards/utilities/getFormattedCrewFilterStatus';
import { EmployeeTimecard } from 'features/timecards/types';
import { formatCrewKeyFromCode } from 'utils/formatCrewKey';
import getFormattedPayPeriodStatus from 'features/timecards/utilities/getFormattedPayPeriodStatus';

const TimecardApprovalDetails = ({
  approvalDetails,
}: {
  approvalDetails: EmployeeTimecard;
}) => {
  const { crew } = useNonUnionStore();

  return (
    <div
      className="grid sm:grid-cols-6 gap-y-2 sm:gap-x-2 bg-blue-50 rounded px-4 py-3"
      data-testid="timecard-approval-details-card"
    >
      <div className="flex flex-row justify-start w-full">
        <span className="flex flex-col justify-start">
          <span className="text-xs text-gray-600" id="pay-period">
            Pay Period Status
          </span>
          <span
            className="text-sm text-gray-900 break-all"
            aria-labelledby="pay-period"
          >
            {getFormattedPayPeriodStatus(approvalDetails.payPeriodStatus)}
          </span>
        </span>
      </div>

      <div className="flex flex-row justify-start w-full">
        <span className="flex flex-col justify-start">
          <span className="text-xs text-gray-600" id="crew">
            Crew
          </span>
          <span
            className="text-sm text-gray-900 break-all"
            aria-labelledby="crew"
          >
            {approvalDetails.id === 'temp'
              ? '-'
              : formatCrewKeyFromCode(crew?.code)}
          </span>
        </span>
      </div>

      <div className="flex flex-row justify-start w-full">
        <span className="flex flex-col justify-start">
          <span className="text-xs text-gray-600" id="primary-approver">
            Primary Approver
          </span>
          <span
            className="text-sm text-gray-900 break-all"
            aria-labelledby="primary-approver"
          >
            {approvalDetails.status.primaryApprover
              ? approvalDetails.status.primaryApprover
              : '-'}
          </span>
        </span>
      </div>

      <div className="flex flex-row justify-start w-full">
        <span className="flex flex-col justify-start">
          <span className="text-xs text-gray-600" id="status">
            Timecard Status
          </span>
          <span
            className="text-sm text-gray-900 break-all"
            aria-labelledby="status"
          >
            {approvalDetails.id === 'temp'
              ? '-'
              : getFormattedCrewFilterStatus(approvalDetails.status.code)}
          </span>
        </span>
      </div>

      <div className="flex flex-row justify-start w-full">
        <span className="flex flex-col justify-start">
          <span className="text-xs text-gray-600" id="action-taken-on">
            Action Taken By/On
          </span>
          <span
            className="text-sm text-gray-900 break-all"
            aria-labelledby="action-taken-on"
          >
            {approvalDetails.status.actionTakenBy
              ? approvalDetails.status.actionTakenBy
              : '-'}
            <br/>
            {approvalDetails.status.actionTakenOn
              ? format(
                  new Date(approvalDetails.status.actionTakenOn),
                  'MMM dd, yyyy, hh:mm aa'
                )
              : ''}
          </span>
        </span>
      </div>

      <div className="flex flex-row justify-start w-full">
        <span className="flex flex-col justify-start">
          <span className="text-xs text-gray-600" id="total-payable-hours">
            Total Payable Hours
          </span>
          <span
            className="text-sm text-gray-900 break-all"
            aria-labelledby="total-payable-hours"
          >
            {approvalDetails.status.totalPayableHours !== null
              ? approvalDetails.status.totalPayableHours.toString()
              : '-'}
          </span>
        </span>
      </div>
    </div>
  );
};

export default memo(TimecardApprovalDetails);
