import { MODEL_STATUS } from "../constants";
import { ModelList, ModelNameList } from "../types";

export const getModelNameList = (data:ModelList[] | []) => {
  const listArr : ModelNameList[] = [];
  if (data.length > 0) {
    data.forEach(item => {
      if (item.modelStatus === MODEL_STATUS.ACTIVE || item.modelStatus === MODEL_STATUS.CHECKED_OUT) {
         const obj : ModelNameList = {id: item.id , modelName: item.modelName}
        listArr.push(obj)
      }
    })
  }
  return listArr;
}