import { Person, PersonStatus } from 'features/people/types';
import { PaginationResponse, SortDirection } from 'types/common';
import { ObjectValues } from 'types/utils';
import { z } from 'zod';
import {
  orgPatchSchema,
  OrgPostSchema,
  OrgPutSchema,
  OrgStatusUnion,
} from '../schemas';
import {
  ORG_FILTER_LOC_TYPE,
  ORG_LOCATION_TYPE,
  ORG_SECURITY_PRIMARY,
  ORG_STATUS_CODE,
  ORG_TYPE,
} from '../constants';
import { HOLIDAY_TYPE } from 'features/holidays/constants';

export type OrgType = ObjectValues<typeof ORG_TYPE>;

export type OrgTypePost =
  | typeof ORG_TYPE.DIVISION
  | typeof ORG_TYPE.DEPARTMENT
  | typeof ORG_TYPE.CREW;

type OrgFilterLocationType = ObjectValues<typeof ORG_FILTER_LOC_TYPE>;

type OrgLocationType = ObjectValues<typeof ORG_LOCATION_TYPE>;

export type OrgStatusCode = ObjectValues<typeof ORG_STATUS_CODE>;

export type OrgSecurityPrimary = ObjectValues<typeof ORG_SECURITY_PRIMARY>;

export type OrgPatchStatus =
  | typeof ORG_STATUS_CODE.ACTIVE
  | typeof ORG_STATUS_CODE.ARCHIVED;

export type OrgFilterStatus = OrgStatusCode | 'all';

export type StatusType = OrgStatusCode;

export interface OrgSecurityRecord extends Omit<Person, 'unionInd' | 'status'> {
  id: string;
  orgRoleId: string;
  orgId: string;
  role: string;
  roleId: string;
  roleName: string;
  plant: string | null;
  plantCode: string | null;
  location: string | null;
  division: string | null;
  department: string | null;
  crew: string | null;
  primary: OrgSecurityPrimary;
  sortOrder: number | null;
  unionInd: 'Y' | 'N' | null;
  status: PersonStatus;
  effectiveDate: string | null;
  expirationDate: string | null;
  newCrew?: string | null;
  locationType: OrgLocationType | null;
}

const OrgStatusSchema = z.object({
  code: OrgStatusUnion,
  description: z.string(),
  actionTakenOn: z.string(),
  actionTakenBy: z.string().nullable().optional(),
});
const OrgGateSystemSchema = z.object({
  id: z.string().nullable(),
  name: z.string().nullable(),
  available: z.union([z.literal('y'), z.literal('n')]).nullable(),
});

const OrgSchema = z.object({
  id: z.string(),
  orgDetailsId: z.string(),
  plant: z.string(),
  plantCode: z.string(),
  plantDescription: z.string().nullable(),
  plantType: z.string().nullable(),
  location: z.string().nullable(),
  locationDescription: z.string().nullable(),
  division: z.string().nullable(),
  divisionDescription: z.string().nullable(),
  department: z.string().nullable(),
  departmentDescription: z.string().nullable(),
  crew: z.string().nullable(),
  crewDescription: z.string().nullable(),
  parentOrgId: z.string().nullable(),
  name: z.string().nullable(),
  code: z.string(),
  type: z.union([
    z.literal(ORG_TYPE.CREW),
    z.literal(ORG_TYPE.DEPARTMENT),
    z.literal(ORG_TYPE.DIVISION),
    z.literal(ORG_TYPE.LOCATION),
    z.literal(ORG_TYPE.PLANT),
  ]),
  description: z.string().nullable(),
  b1Code: z.string().nullable(),
  payGroup: z.string().nullable(),
  locationType: z
    .union([
      z.literal(ORG_LOCATION_TYPE.UNION),
      z.literal(ORG_LOCATION_TYPE.NONUNION),
    ])
    .nullable(),

  locationSubType: z.string().nullable(),
  policyGroup: z.string().nullable(),
  status: OrgStatusSchema,
  sortSequence: z.string().nullable(),
  safetyMeetingWeekOfMonth: z.string().nullable(),
  safetyMeetingDayOfWeek: z.string().nullable(),
  safetyMeetingTurnOnTheDay: z.string().nullable(),
  safetyMeetingLengthMinutes: z.string().nullable(),
  safetyMeetingBeforeOrAfterShift: z.string().nullable(),
  safetyMeetingIncentiveEligible: z.string().nullable(),
  orgEffectiveDate: z.string().nullable(),
  childOrgsCount: z.number().nullable(),
  payrolls: z.string().nullable(),
  weekBeginDayIndex: z.union([z.literal(0), z.literal(6)]),
  gateSystem: OrgGateSystemSchema,
  holidayType: z.nativeEnum(HOLIDAY_TYPE).array(),
  createdBy: z.string().optional(),
});
export type Org = z.infer<typeof OrgSchema>;

export interface OrgFormType {
  name: string;
  description: string;
  comments: string;
  parentOrgId: string;
  type: OrgTypePost;
}

// ********** getEmployeeOrgs *********

export type OrgsSortByType =
  | 'plant'
  | 'location'
  | 'division'
  | 'department'
  | 'crew'
  | 'code'
  | 'crew-sort-seq'
  | 'recently-added'
  | 'description';

export interface OrgsOptions {
  week?: string;
  type?: OrgType;
  locationType?: OrgFilterLocationType;
  status?: OrgStatusCode;
  search?: string;
  role?: string;
  orgId?: string;
  parentOrgId?: string | null;
  plant?: string;
  location?: string;
  division?: string;
  department?: string;
  crew?: string;
  sortBy?: OrgsSortByType;
  sortType?: SortDirection;
  pageSize?: string;
  page?: string;
  parentOrgs?: 'y';
  hierarchyType?: 'all' | 'parent' | 'child';
  context?: 'summary' | 'detail';
}

// ****** ORG GET

const OrgListSchema = z.object({
  items: z.array(OrgSchema),
  page: z.number(),
  pageSize: z.number(),
  totalCount: z.number(),
});

export type OrgList = z.infer<typeof OrgListSchema>;

// export interface OrgList extends PaginationResponse {
//   items: Org[];
// }

// ******* ORG POST *************

export type OrgPost = z.infer<typeof OrgPostSchema>;

// ********* Org PUT ***********

export type OrgPut = z.infer<typeof OrgPutSchema>;

//***********  Org PATCH ***************

export type OrgPatch = z.infer<typeof orgPatchSchema>;

//***********  Get Org Member ***************

export interface OrgMemberList extends PaginationResponse {
  items: Person[];
}
