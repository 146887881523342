import clsx from 'clsx';
import CardSkeleton from 'components/Skeleton/CardSkeleton';
import _ from 'lodash';
import React from 'react';

type Variants = 'col-grid' | 'row-grid';
interface Props {
  count?: number;
  contentRows?: number;
  hasAvatar?: boolean;
  variant?: Variants;
}

const CardsLoader = ({
  count = 1,
  contentRows = 2,
  hasAvatar = false,
  variant = 'col-grid',
}: Props) => {
  const card: React.ReactElement[] = [];
  _.times(count, (n) => {
    card.push(
      <CardSkeleton
        hasAvatar={hasAvatar}
        key={`loader-${n}`}
        contentRows={contentRows}
        variant={variant}
        data-testid="card-skeleton"
      />
    );
  });
  return (
    <div
      data-testid="card-loader"
      className={clsx(
        variant === 'col-grid'
          ? 'grid md:grid-cols-2 xl:grid-cols-3 gap-4'
          : '',
        variant === 'row-grid' ? 'grid gap-4' : '',
        ' py-4'
      )}
    >
      {card.map((item: React.ReactElement) => {
        return item;
      })}
    </div>
  );
};

export default CardsLoader;
