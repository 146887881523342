import React from 'react';

import HelpfulHints from './cards/HelpfulHints';
import HelpFulHintsList from 'data/home/HelpfulHints';
import Page from 'components/Layouts/Page';
import { BsExclamationTriangle } from 'react-icons/bs';
export const HomeContent = () => {
  return (
    <Page>
      <h1 className="sr-only">Homepage</h1>
      <div className="bg-yellow-100 mx-6 px-4 py-2 mb-2 mt-0 rounded-md flex justify-between items-center text-yellow-700">
        <div className="flex justify-start items-center">
          <BsExclamationTriangle className="mr-2" />
          WMS may be unavailable on Wednesdays for up to 90 minutes from 1:00pm
          to 2:30pm ET / 12:00pm to 1:30pm CT for maintenance.
        </div>
      </div>
      <Page.Section>
        <HelpfulHints helpfulHintsData={HelpFulHintsList} />
      </Page.Section>
    </Page>
  );
};
