import { useEffect } from 'react';

import Error from 'components/Error/Error';

import { Link, SearchInput, Select } from '@uss/react-components';
import CardList from 'components/Card/CardList';
import Page from 'components/Layouts/Page';
import CardsLoader from 'components/Loaders/CardsLoader';
import Pagination from 'components/Pagination/Pagination';
import NavLinkTabs from 'components/Tabs/NavLinkTabs';
import { CrewCard } from 'features/crew-maintenance';
import { useCrewMaintenanceEmployeeStore } from 'features/crew-maintenance/state/store';
import { Org, ORG_TYPE } from 'features/org';
import { useEmployeeOrgs } from 'features/org/api';
import { ROLES } from 'features/roles';
import useUserRoles from 'hooks/useUserRoles';
import { BsPlus } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';

const CrewCardLink = ({ crew }: { crew: Org }) => {
  return (
    <Link
      as={NavLink}
      className="block cursor-pointer"
      to={crew.id}
      key={crew.id}
    >
      <CrewCard crew={crew} />
    </Link>
  );
};

const CrewList = () => {
  const store = useCrewMaintenanceEmployeeStore();
  const { data, status } = useEmployeeOrgs('me', store.crewParams);
  const { roles } = useUserRoles();

  const { data: plantList, status: plantStatus } = useEmployeeOrgs('me', {
    type: ORG_TYPE.PLANT,
    sortBy: 'plant',
    parentOrgs: 'y',
  });
  const { data: payrollLocations, status: locationStatus } = useEmployeeOrgs(
    'me',
    {
      type: ORG_TYPE.LOCATION,
      sortBy: 'location',
      parentOrgs: 'y',
      parentOrgId:
        store.selectedPlant && store.selectedPlant !== 'all'
          ? store.selectedPlant.id
          : undefined,
    }
  );

  //  Setting the initial plant
  useEffect(() => {
    if (plantStatus === 'success') {
      if (!store.selectedPlant) {
        store.setPlant(plantList.items[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantList, plantStatus]);

  const handleSearchChange = (value: string) => {
    store.setCrewSearch(value);
  };
  const handlePlantChange = (value: Org) => {
    store.setPlant(value);
  };
  const handleClose = () => {
    store.clearCrewSearch();
  };

  const tabs = [
    {
      label: 'Employee',
      to: '/admin/crew-maintenance',
      isRootUrl: false,
    },
    {
      label: 'Crew',
      to: '/admin/crew-maintenance/crew',
      isRootUrl: true,
    },
  ];

  return (
    <Page
      heading={'Crew Maintenance'}
      breadcrumbs={[
        <NavLink key="bc-1" to="/admin">
          Admin
        </NavLink>,
      ]}
    >
      <Page.Section>
        <NavLinkTabs tabLinks={tabs} />
      </Page.Section>
      <Page.Section>
        <h1 className="sr-only">Crew Maintenance by Crew</h1>
        <div className="flex sm:flex-row flex-col justify-between flex-wrap">
          <div className="w-full sm:w-10/12">
            <div className="w-full mb-2 sm:mb-0 sm:w-4/12 ">
              <SearchInput
                id="search"
                label="search"
                placeholder="Search Loc / Div / Dept / Crew"
                value={store.crewParams.search ?? ''}
                autoCompleteFlag={store.crewParams.search ? true : false}
                selectedFlag={store.crewParams.search ? true : false}
                onChange={(e) => {
                  handleSearchChange(e.currentTarget.value);
                }}
                onClose={handleClose}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-2 mt-2 sm:grid-cols-3 sm:gap-4 sm:mt-4">
          <Select
            selected={
              store.crewParams.parentOrgId
                ? store.crewParams.parentOrgId
                : 'all'
            }
            onSelect={handlePlantChange}
            className="w-full"
          >
            <Select.Button
              label={
                store.selectedPlant !== 'all' &&
                store.selectedPlant &&
                store.selectedPlant.description
                  ? store.selectedPlant.description
                  : 'All Plants'
              }
            />
            <Select.Options>
              <Select.Option value="all">All Plants</Select.Option>
              {plantStatus === 'success' && plantList.items.length ? (
                plantList.items.map((item: Org, index: number) => (
                  <Select.Option truncate key={index} value={item}>
                    <span>{item.plant}</span>
                  </Select.Option>
                ))
              ) : (
                <span>No plants available</span>
              )}
            </Select.Options>
          </Select>
          <Select
            selected={
              store.selectedPayrollLocation &&
              store.selectedPayrollLocation !== 'all'
                ? store.selectedPayrollLocation.id
                : 'all'
            }
            onSelect={store.setPayrollLocation}
            className="w-full"
          >
            <Select.Button
              label={
                store.selectedPayrollLocation !== 'all' &&
                store.selectedPayrollLocation &&
                store.selectedPayrollLocation.description
                  ? `${store.selectedPayrollLocation.code} - ${store.selectedPayrollLocation.description}`
                  : 'All Payroll Locations'
              }
            />
            <Select.Options>
              <Select.Option value="all">All Payroll Locations</Select.Option>
              {locationStatus === 'success' && payrollLocations.items.length ? (
                payrollLocations.items.map((item: Org, index: number) => (
                  <Select.Option truncate key={index} value={item}>
                    <span>
                      {item.location} - {item.locationDescription}
                    </span>
                  </Select.Option>
                ))
              ) : (
                <span>No locations available</span>
              )}
            </Select.Options>
          </Select>
          <div className="flex w-full justify-end ">
            {(roles.includes(ROLES.SUPER_ADMIN) ||
              roles.includes(ROLES.SUPER_PAYROLL_ADMIN) ||
              roles.includes(ROLES.PAYROLL_ADMIN) ||
              roles.includes(ROLES.T_A_PLANT_COORDINATOR) ||
              roles.includes(ROLES.PAY_APPROVER)) && (
              <NavLink to="/admin/crew-maintenance/crew/add-crew">
                <div className="text-primary-400 font-semibold flex items-center mx-0 lg:mx-2">
                  <BsPlus size="2rem" />
                  <span>
                    {window.innerWidth > 768
                      ? roles.includes(ROLES.PAY_APPROVER)
                        ? 'Request Crew'
                        : 'Add Crew'
                      : 'Add'}
                  </span>
                </div>
              </NavLink>
            )}
          </div>
        </div>
      </Page.Section>
      <Page.Section>
        {status === 'pending' && <CardsLoader count={9} contentRows={2} />}
        {status === 'error' && <Error />}
        {status === 'success' && (
          <>
            <CardList
              items={data.items}
              renderItem={(crew) => <CrewCardLink crew={crew} />}
              noResults={{
                title: 'No Crews Found',
                description:
                  'No crews available, please modify the search criteria to view the records',
              }}
            />

            <div className="py-4">
              {!!data.totalCount && data.totalCount >= 10 && (
                <div className="w-full flex flex-row justify-center">
                  <Pagination
                    pageOptions={[9, 18, 27, 36, 45, 54]}
                    totalCount={data.totalCount}
                    page={data.page}
                    pageSize={data.pageSize}
                    onPageChange={(s) =>
                      store.setCrewPage((s.selected + 1).toString())
                    }
                    onPageSizeChange={(ps) =>
                      store.setCrewPageSize(ps.toString())
                    }
                  />
                </div>
              )}
            </div>
          </>
        )}
      </Page.Section>
    </Page>
  );
};

export default CrewList;
