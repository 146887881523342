import { Button } from '@uss/react-components';
import React from 'react';

interface Actions {
  label: string;
  startIcon?: React.ReactElement;
  handleClick: () => void;
}
interface MassActionsProps {
  selectedEmployeesCount?: number;
  actions?: Actions[];
}
const ActionButton = ({ label, startIcon, handleClick }: Actions) => {
  return (
    <Button
      className="mr-5 w-full mt-3 sm:mt-0 sm:w-auto"
      variant="text"
      startIcon={startIcon ?? <></>}
      onClick={handleClick}
    >
      {label}
    </Button>
  );
};
const MassActions = ({
  selectedEmployeesCount = 0,
  actions,
}: MassActionsProps) => {
  return (
    <>
      {selectedEmployeesCount > 0 && (
        <div
          className="flex flex-col  md:flex-row md:justify-between p-2 bg-accent2-500 text-white h-auto md:h-14 items-center rounded-sm text-base mb-4"
          data-testid="mass-actions-container"
        >
          <div data-testid="selected-employees-count">
            {selectedEmployeesCount === 1
              ? '1 Employee Selected'
              : `${selectedEmployeesCount} Employees Selected`}
          </div>
          <div className="flex flex-col  md:flex-row">
            {actions?.map((action) => {
              return (
                <ActionButton
                  label={action.label}
                  startIcon={action.startIcon}
                  handleClick={action.handleClick}
                  key={action.label}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default MassActions;
