import { useMutation } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { WMSError } from 'types/common';
import { Org, OrgPut } from 'features/org';

const updateOrg = (id: string, body: OrgPut): Promise<Org> => {
  return axios.put(`/v1/orgs/${id}`, body);
};

export const useUpdateOrg = (id: string) => {
  return useMutation<Org, WMSError, OrgPut>({
    mutationFn: (body: OrgPut) => updateOrg(id, body),
    meta: {
      errorMessage: 'Unable to update org',
    },
  });
};
