import { PayPeriod } from 'features/pay-periods';
import { ShiftAssignment as ShiftAssignmentObj } from 'features/crew-maintenance/types';
import { getFormattedPayPeriod } from './shiftAssignmentCommon';

export default function pendingShiftAssignmentText(
  pendingShiftAssignment: ShiftAssignmentObj,
  selectedPayPeriod: PayPeriod
) {
  if (
    selectedPayPeriod.status === 'closed' &&
    Object.keys(pendingShiftAssignment).length > 0
  ) {
    return (
      <span data-testid="pending">
        {pendingShiftAssignment.shift} | {pendingShiftAssignment.status}
      </span>
    );
  } else if (
    selectedPayPeriod.status === 'not-open' &&
    Object.keys(pendingShiftAssignment).length > 0
  ) {
    return (
      <span className="flex flex-wrap flex-col" data-testid="pending">
        <span>
          {pendingShiftAssignment.shift} | Effective from{' '}
          {getFormattedPayPeriod(selectedPayPeriod).month} Pay Period
        </span>
        <span>
          (Starting: {getFormattedPayPeriod(selectedPayPeriod).startDate})
        </span>
      </span>
    );
  } else return <>-</>;
}
