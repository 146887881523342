import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import useUserRoles from 'hooks/useUserRoles';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import NoAccess from '../NoAccess';

const Home: React.FC = (props) => {
  const { isAuthorized } = useUserRoles();
  return (
    <>
      <UnauthenticatedTemplate>
        <Navigate to="login" replace />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        {!isAuthorized && <NoAccess />}
        {isAuthorized && <Outlet />}
      </AuthenticatedTemplate>
    </>
  );
};

export default Home;
