/**
 * @description .(Save, Save & Approve) Hours fields for partial hours can only be  .25 or .50 or .75
 * @returns
 */

export const partialHours = (hours: number | null) => {
  return hours &&
    hours % 1 !== 0 &&
    (hours.toString() + '').split('.')[1] !== '25' &&
    (hours.toString() + '').split('.')[1] !== '50' &&
    (hours.toString() + '').split('.')[1] !== '5' &&
    (hours.toString() + '').split('.')[1] !== '75'
    ? false
    : true;
};
