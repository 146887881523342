import { z } from 'zod';
import { SchedTurnsSchema, WeekSchema } from './model-details';
import { CheckIsTurnsEmpty } from './CheckIsTurnsEmpty';

export const EditModelSchema = z.object({
  modelDescription: z.string(),
  action: z.string(),
  weeks: z.array(WeekSchema).nullable().optional(),
});

export const CrewRotationValidationSchema = z.object({
  crewRotation: z.string(),
  schedTurns: z.array(SchedTurnsSchema),
});

export const WeekValidationSchema = z.object({
  weekNo: z.number(),
  crewRotations: z.array(CrewRotationValidationSchema),
});

export const EditModelValidationSchema = z
  .object({
    modelDescription: z.string(),
    action: z.string(),
    weeks: z.array(WeekSchema),
  })
  .refine((data) => CheckIsTurnsEmpty(data.weeks), {
    message: 'schedule turns are missing',
    path: ['CheckIsTurnsEmpty '], // path of error
  });

export const AddWeekCrewValidationSchema = z.object({
  numberOfWeeks: z.number().min(1).max(52),
  crewRotation: z.number(),
});
