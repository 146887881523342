import Error from 'components/Error/Error';
import { useParams } from 'react-router-dom';
import {
  SecurityRecord,
  useAddEmployeeSecurity,
  useDeleteSecurityById,
  useEmployeeSecurity,
  usePatchUpdateSecurity,
} from 'features/security';
import CardList from 'components/Card/CardList';
import {
  AddRecordDrawer,
  AssignmentCrewCard,
  CancelMemebrMoveRequest,
  MoveCrew,
} from 'features/crew-maintenance';
import useModal from 'components/Modals/use-modal';
import { intersection } from 'lodash';
import CardsLoader from 'components/Loaders/CardsLoader';
import { Person } from 'features/people/types';
import { usePersonDetails } from 'features/people/api';
import { Button } from '@uss/react-components';
import { BsPlus } from 'react-icons/bs';
import { ORG_TYPE } from 'features/org';
import { useState } from 'react';
import { useEmployeeOrgs } from 'features/org/api';
import { useCancelMemberMove } from 'features/crew-maintenance/api/cancelCrewMove';
import useNotification from 'components/Notification/useNotification';
import { ROLECODES } from 'features/roles';
import { usePayPeriodsOpenStatus } from 'features/pay-periods/api';
import { HandleMove } from 'features/crew-maintenance/types';
import { isFuture } from 'date-fns';
import { useChangeCrews } from 'features/crew-maintenance/api';
import { queryClient } from 'services/api/react-query';

const CrewAssignments = () => {
  // Drawer Open States
  const [fromCrew, setFromCrew] = useState<SecurityRecord>();
  // Getting ussId out of the URL
  const { employeeId = '' } = useParams<'employeeId'>();

  // Fetching Employee Details

  const { data: employee, status: employeeStatus } =
    usePersonDetails(employeeId);

  // Fetching the employee crews
  const { data: crews, status } = useEmployeeSecurity(employeeId, {
    role: ROLECODES.CREW_MEMBER,
    retrieveFutureDatedRecords: 'Y',
  });

  // Fetching logged in user crews
  const { data: crewList, status: crewListStatus } = useEmployeeOrgs('me', {
    type: ORG_TYPE.CREW,
    sortBy: 'code',
  });

  // ********* Logged in user crew access *********

  const crewAllowed = (orgId: string) => {
    return !allowedCrews.includes(orgId);
  };
  const crewIds = crews?.items.map((c) => c.orgId);
  const loggedInUserCrews = crewList?.items.map((c) => c.id);
  const allowedCrews = intersection(crewIds, loggedInUserCrews);

  //************************************

  // Employee ADD/MOVE Valid Crews

  // Employee Crew Ids
  const employeeCrewIds = crews?.items.map((sr) => sr.orgId);

  //notification
  const notify = useNotification();

  // ********* Delete Record ************
  const modal = useModal();

  const { mutateAsync: deleteAssignedCrew } = useDeleteSecurityById(employeeId);
  const { mutateAsync: cancelMemberMove } = useCancelMemberMove();

  const handleCancelModal = (member: SecurityRecord) => {
    modal.openModal({
      title: 'Cancel Move!',
      type: 'regular',
      onConfirm: () => handleCancel(member),
      onCancel: () => modal.closeModal(),
      labels: {
        cancel: 'No',
        confirm: 'Yes',
      },
      children: <CancelMoveMessage member={member} employee={employee} />,
    });
    return null;
  };

  const handleRemove = (crewId: string, crew: string) => {
    const remove = async (id: string) => {
      await deleteAssignedCrew(id, {
        onSuccess: (success) => {
          notify.success({
            message: success.message,
          });
        },
      });
      modal.closeModal();
    };
    modal.openModal({
      title: 'Remove Crew',
      type: 'danger',
      children: <RemoveModalMessage crew={crew} />,
      labels: { confirm: 'Remove', cancel: 'Cancel' },
      onConfirm: () => remove(crewId),
      onCancel: () => modal.closeModal(),
    });
  };

  const handleCancel = async (member: SecurityRecord) => {
    const payload: CancelMemebrMoveRequest = {
      crewId: member.orgId,
      ussId: member.ussId,
    };
    modal.closeModal();
    await cancelMemberMove(payload, {
      onSuccess: async () => {
        queryClient.removeQueries({ queryKey: ['crew-members'] });
        await queryClient.refetchQueries({ queryKey: ['employee-securities'] });
        notify.success({
          message: `You have cancelled the move for ${
            employee ? employee.firstName + ' ' + employee.lastName : ''
          }.`,
        });
      },
    });
  };
  // *************************************

  //**** HANDLE ADD ********************/

  const [addOpen, setAddOpen] = useState(false);

  const { mutateAsync: addRecord } = useAddEmployeeSecurity(employeeId);

  const handleAddButton = async (id: string | undefined) => {
    await addRecord({
      orgId: id,
      role: ROLECODES.CREW_MEMBER,
    });
    setAddOpen(false);
  };

  const { data: effectiveDate } = usePayPeriodsOpenStatus(
    employee?.payrollNameId ? employee.payrollNameId : ''
  );

  // Updating Primary Crew
  const { mutateAsync: updateRecord } = usePatchUpdateSecurity(employeeId);

  const onSwitch = async (value: boolean, orgId: string): Promise<void> => {
    await updateRecord({
      orgId: orgId,
      role: ROLECODES.CREW_MEMBER,
      primary: value ? 'y' : null,
    });
  };

  //mmove crew modal
  //**** Move Employee ************
  const [open, setOpen] = useState(false);
  const { mutateAsync: changeCrew } = useChangeCrews();

  const openMoveModal = (crew: SecurityRecord) => {
    setOpen(true);
    setFromCrew(crew);
  };

  const handleMove: HandleMove = async (
    fromCrew,
    moveSchedules,
    targetCrewId = ''
  ) => {
    await changeCrew(
      {
        ussId: fromCrew.ussId.toString(),
        crewId: fromCrew.orgId,
        body: {
          targetCrewId,
          moveSchedules,
        },
      },
      {
        onSuccess: async () => {
          queryClient.removeQueries({ queryKey: ['crew-members'] });
          await queryClient.refetchQueries({
            queryKey: ['employee-securities'],
          });

          employeeStatus === 'success' &&
            notify.success({
              message: `You have successfully moved ${
                employee.firstName + ' ' + employee.lastName
              }${moveSchedules ? ', along with any future schedules.' : '.'}`,
            });
        },
      }
    );
    setOpen(false);
  };

  //Show effective and expiring date
  const isFutureEffectiveDate = (crew: SecurityRecord) =>
    isFuture(crew.effectiveDate ? new Date(crew.effectiveDate) : new Date());

  return (
    <>
      {(status === 'pending' ||
        crewListStatus === 'pending' ||
        employeeStatus === 'pending') && (
        <CardsLoader count={6} contentRows={2} />
      )}
      {status === 'error' && <Error />}
      {status === 'success' &&
        crewListStatus === 'success' &&
        employeeStatus === 'success' && (
          <div>
            <div className="flex items-center justify-end py-4">
              <Button
                className="text-primary-400 font-semibold"
                onClick={() => setAddOpen(true)}
                variant="text"
                startIcon={<BsPlus />}
              >
                {window.innerWidth > 768 ? 'Add Record' : 'Add'}
              </Button>
            </div>
            <CardList
              items={crews.items}
              renderItem={(crew) => (
                <AssignmentCrewCard
                  handleRemove={handleRemove}
                  handleCancelMove={handleCancelModal}
                  disabled={crewAllowed(crew.orgId)}
                  crew={crew}
                  openMoveModal={() => {
                    openMoveModal(crew);
                  }}
                  primary={crew.primary === 'y'}
                  showCancelMove={
                    crew.expirationDate !== null && crew.newCrew !== null
                  }
                  onSwitch={onSwitch}
                  isFutureEffectiveDate={isFutureEffectiveDate(crew)}
                />
              )}
              noResults={{
                title: 'No Records Found',
                description: 'No crews assigned for the selected employee',
              }}
            />
          </div>
        )}

      <AddRecordDrawer
        employeeCrewIds={employeeCrewIds}
        employee={employee}
        onAdd={handleAddButton}
        open={addOpen}
        setOpen={setAddOpen}
        effectiveDate={effectiveDate}
      />

      {employeeStatus === 'success' && fromCrew && (
        <MoveCrew
          onMove={handleMove}
          isOpen={open}
          employee={employee}
          employeeCrewIds={employeeCrewIds}
          fromCrew={fromCrew}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

const RemoveModalMessage = ({ crew }: { crew: string }) => {
  return (
    <div>
      <p className="text-sm text-gray-700">
        Are you sure you want to remove {crew}?
      </p>
      <br></br>
      <p className="text-sm text-gray-700">
        This will remove all the future schedules for this employee from this
        crew.
      </p>
      <p className="text-sm text-gray-700">
        Use &apos;Move&apos; option to switch this crew to another crew and move
        future schedules.
      </p>
    </div>
  );
};

const CancelMoveMessage = ({
  member,
  employee,
}: {
  member: SecurityRecord;
  employee: Person | undefined;
}) => {
  return (
    <div>
      <p className="text-sm text-gray-700">
        Are you sure you want to cancel the move for {employee?.firstName}&nbsp;
        {employee?.lastName} from {member.crew} to {member.newCrew}?
      </p>
    </div>
  );
};

export default CrewAssignments;
