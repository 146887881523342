import { useProbationaryEmployeesList } from 'features/probationary-tracking/api/getProbEmployees';
import { ReviewStatus } from 'features/probationary-tracking/types';
import Error from 'components/Error/Error';
import NoResults from 'components/NoResults/NoResults';
import Employees from '../Employees';
import { ErrorBoundary } from 'react-error-boundary';
import { SearchInput } from '@uss/react-components';
import CardsLoader from 'components/Loaders/CardsLoader';
import { useProbationaryEmployeesStore } from 'features/probationary-tracking/state/store';
import Pagination from 'components/Pagination/Pagination';
import useDebounceSearch from 'features/timecards/hooks/useDebounceSearch';

const ReviewDue = () => {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => {
        return (
          <Error
            width="300"
            height="300"
            title="Something went wrong!"
            description={error.message}
          />
        );
      }}
    >
      <Content />
    </ErrorBoundary>
  );
};
const Content = () => {
  const { reviewer } = useProbationaryEmployeesStore();
  const page = reviewer.reviewDue.page;
  const pageSize = reviewer.reviewDue.pageSize;
  const setPage = reviewer.reviewDue.setPage;
  const setPageSize = reviewer.reviewDue.setPageSize;
  const search = reviewer.reviewDue.search;
  const setSearch = reviewer.reviewDue.setSearch;
  const clearSearch = reviewer.reviewDue.clearSearch;
  const debounceSearch = useDebounceSearch(search, 500);
  const { data, status } = useProbationaryEmployeesList({
    reviewStatus: ReviewStatus.REVIEW_DUE,
    params: {
      search: debounceSearch,
      page: page,
      pageSize,
      context: 'reviewer',
    },
  });
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      clearSearch();
    } else {
      setSearch(e.target.value);
    }
  };
  const handleClearSearch = () => {
    clearSearch();
  };
  return (
    <>
      <div className="w-full md:w-[30%] my-4">
        <SearchInput
          id="search-input"
          label="search"
          placeholder="Search Name / USS ID"
          role="searchbox"
          autoCompleteFlag={search ? true : false}
          selectedFlag={search ? true : false}
          value={search ?? ''}
          onChange={handleSearchChange}
          onClose={handleClearSearch}
        />
      </div>
      {status === 'pending' && (
        <CardsLoader variant="row-grid" count={9} hasAvatar />
      )}
      {status === 'error' && <Error />}
      {status === 'success' &&
        (data.items.length > 0 ? (
          <>
            <Employees data={data} clickable />
            <Pagination
              pageOptions={[10, 20, 30, 40, 50, 60]}
              totalCount={data.totalCount}
              page={data.page}
              pageSize={data.pageSize}
              onPageChange={(s) => setPage(s.selected + 1)}
              onPageSizeChange={(ps) => setPageSize(ps)}
            />
          </>
        ) : (
          <NoResults
            width={'300'}
            height={'300'}
            description="No Probationary employee records available"
          />
        ))}
    </>
  );
};
export default ReviewDue;
