import Loader from 'components/Skeleton/Loader';

const FilterLoader = ({ count }: { count: number }) => {
  return (
    <div className="w-full grid grid-col-1 md:grid-cols-3 gap-y-2 gap-x-4 my-4">
      {Array.from(Array(count)).map((n, i) => (
        <Loader isFilter key={i} />
      ))}
    </div>
  );
};
export default FilterLoader;
