import { Input } from '@uss/react-components';
import Alert from 'components/Alert/Alert';
import Card from 'components/Card/Card';
import useModal from 'components/Modals/use-modal';
import useNotification from 'components/Notification/useNotification';
import { isAfter, isBefore, parseISO } from 'date-fns';
import {
  useAddSellBackPto,
  useUpdateSellBackPto,
} from 'features/paid-time-off/api/mutations';
import { PtoSellBack, PtoSummary } from 'features/paid-time-off/types';
import {
  SellBackPtoPostSchema,
  SellBackPtoPost,
  SellBackPtoPut,
  SellBackPTOPutSchema,
} from 'features/paid-time-off/types/sellback';
import useValidateForm from 'hooks/useValidateForm';
import _ from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { queryClient } from 'services/api/react-query';
import { ZodErrorType } from 'types/common';
import { appNewDate } from 'utils/appNewDate';
import SellBackPeriodEnd from '../../../../features/paid-time-off/components/SellBackPTO/SellBackPeriodEnd';
import {
  SellBackAction,
  SellBackActionButton,
} from '../../../../features/paid-time-off/components/SellBackPTO/SellBackPTOActions';
import { QueryStatus } from '@tanstack/react-query';
export interface SellBackPTOCardProps {
  PTOSummary: PtoSummary | undefined;
  PTOSummaryStatus: QueryStatus;
  sellBackDetails: PtoSellBack | undefined;
}
export const SellBackPTOCard = ({
  PTOSummary,
  PTOSummaryStatus,
  sellBackDetails,
}: SellBackPTOCardProps) => {
  //sell back hours state
  const defaultHours =
    sellBackDetails && sellBackDetails.items.length > 0
      ? Number(sellBackDetails.items[0].sellbackHours)
      : 0;
  const [sellBackHours, setSellBackHours] = useState<number>(defaultHours);

  //get maximum pto hours
  const maximumHours: number = useMemo(() => {
    if (PTOSummaryStatus === 'success' && PTOSummary) {
      return PTOSummary.soldBack + PTOSummary.remaining;
    } else {
      return 0;
    }
  }, [PTOSummary, PTOSummaryStatus]);

  //get start date
  const startDate: Date = useMemo(() => {
    if (
      PTOSummaryStatus === 'success' &&
      PTOSummary &&
      PTOSummary.sellBackStart
    ) {
      return new Date(parseISO(PTOSummary.sellBackStart));
    } else {
      return appNewDate();
    }
  }, [PTOSummary, PTOSummaryStatus]);

  //get end date
  const endDate: Date = useMemo(() => {
    if (
      PTOSummaryStatus === 'success' &&
      PTOSummary &&
      PTOSummary.sellBackEnd
    ) {
      return new Date(parseISO(PTOSummary.sellBackEnd));
    } else {
      return appNewDate();
    }
  }, [PTOSummary, PTOSummaryStatus]);

  const showSellBackPTO =
    isAfter(appNewDate(), startDate) && isBefore(appNewDate(), endDate);

  const ptoIncrement = sellBackDetails ? sellBackDetails.ptoIncrement : 0;

  //sell back action state
  const buttonRef = useRef<SellBackActionButton>('save');
  const id = sellBackDetails?.items[0]?.id ? sellBackDetails.items[0].id : '0';
  const ussId = sellBackDetails?.ussId ? sellBackDetails.ussId : 'me';

  //modal hook
  const modal = useModal();

  //add sell back pto record api hook
  const { mutateAsync: addSellBackPtoRecord } = useAddSellBackPto(ussId);

  //update sell back pto record api hook
  const { mutateAsync: editSellBackPtoRecord } = useUpdateSellBackPto(
    ussId,
    id
  );

  //validation
  const [errors, setErrors] = useState<ZodErrorType>({});
  const schema = id === '0' ? SellBackPtoPostSchema : SellBackPTOPutSchema;
  const { validateForm } = useValidateForm(schema, setErrors);

  //notification
  const notify = useNotification();

  const navigate = useNavigate();

  //navigate back to PTO home page
  const handleCancel = () => {
    navigate(`/my-pto-nu`);
  };

  // add new sell back record
  const addPtoSellBack = async () => {
    const payload = {
      sellbackHours: sellBackHours,
    };
    if (id === '0') {
      await addSellBackPtoRecord(payload as SellBackPtoPost, {
        onSettled: async () => {
          await queryClient.refetchQueries({ queryKey: [`pto-summary`] });
          await queryClient.refetchQueries({ queryKey: [`sell-back-pto`] });
          modal.closeModal();
        },
        onSuccess: () => {
          notify.success({
            message: 'You have successfully submitted PTO hours to sell.',
          });
          navigate(`/my-pto-nu`);
        },
      });
    }
  };

  //update sell back record
  const updatePtoSellBack = async () => {
    const payload = {
      sellbackHours: sellBackHours,
    };
    if (id !== '0') {
      await editSellBackPtoRecord(payload as SellBackPtoPut, {
        onSettled: async () => {
          await queryClient.refetchQueries({ queryKey: [`pto-summary`] });
          await queryClient.refetchQueries({ queryKey: [`sell-back-pto`] });
          modal.closeModal();
        },
        onSuccess: () => {
          notify.success({
            message: 'You have successfully updated PTO hours to sell.',
          });
          navigate(`/my-pto-nu`);
        },
      });
    }
  };

  //add action click event
  const handleAdd = () => {
    const confirm = async () => {
      await addPtoSellBack();
      modal.closeModal();
    };

    modal.openModal({
      title: 'Confirmation',
      type: 'regular',
      children: <div>Are you sure you want to sell your PTO hours?</div>,
      labels: { confirm: 'Yes', cancel: 'No' },
      onCancel: () => modal.closeModal(),
      onConfirm: () => confirm(),
    });
  };

  //update action click event
  const handleUpdate = () => {
    const confirm = async () => {
      await updatePtoSellBack();
      modal.closeModal();
    };

    modal.openModal({
      title: 'Confirmation',
      type: 'regular',
      children: (
        <div>Are you sure you want to update your sell back PTO hours?</div>
      ),
      labels: { confirm: 'Save', cancel: 'Cancel' },
      onCancel: () => modal.closeModal(),
      onConfirm: () => confirm(),
    });
  };

  const showModal = () => {
    if (buttonRef.current === 'save') {
      handleAdd();
    } else {
      handleUpdate();
    }
  };

  const ValidationMsg = ({ msg, name }: { msg: string; name: string }) => {
    return (
      <p className="text-sm text-red-600" data-testid={`validation-` + name}>
        {msg}
      </p>
    );
  };

  const ValidationSummary = () => {
    return (
      <>
        {Object.keys(errors).map(function (key) {
          return (
            <div key={key}>
              {(key === 'ptoHourIncrement' || key === 'ptoHourMaximumPto') && (
                <ValidationMsg msg={errors[key]} name={key.toString()} />
              )}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      {showSellBackPTO ? (
        <div className="grid gap-4 mt-2">
          <div className="">
            <Alert variant="info">
              The payment will be made in the next pay processing.
            </Alert>
          </div>

          <Card>
            <Card.Body>
              <div className="grid grid-flow-row gap-4 text-xs">
                <div className="col-span-2 mb-4 lg:mb-0">
                  <div>
                    <p>
                      Maximum available PTO hours to sell (Remaining + Sell
                      Back)
                    </p>
                    <p
                      data-testid="annual-requested-year"
                      className="text-sm text-accent1-500 font-medium"
                    >
                      {maximumHours}
                      <span className="text-xs">Hrs</span>
                    </p>
                  </div>
                  <div className="w-full lg:w-2/5">
                    <Input
                      id="form__input__description"
                      label="Enter/Update The Remaining Hours You Want To Sell"
                      type="number"
                      name="hours"
                      value={sellBackHours}
                      placeholder="Enter"
                      error={
                        !_.isEmpty(errors) &&
                        typeof errors.sellbackHours === 'string' &&
                        errors.sellbackHours.length > 0
                      }
                      errorMessage={errors.sellbackHours}
                      onChange={(e) => {
                        setSellBackHours(parseInt(e.currentTarget.value));
                      }}
                    />

                    <ValidationSummary />

                    <div className="flex items-center my-2">
                      <BsInfoCircle size={'1rem'} className="text-gray-700" />{' '}
                      <span className="ml-2 text-sm text-gray-700">
                        {`PTO Increment: ${String(ptoIncrement)}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>

          <div className="flex justify-end flex-wrap">
            <SellBackAction
              showModal={showModal}
              sellBackId={id}
              handleCancel={handleCancel}
              validateForm={() => {
                const sellBackObj = {
                  sellbackHours: sellBackHours,
                  increment: ptoIncrement,
                  maximumPto: maximumHours,
                };
                return validateForm(sellBackObj);
              }}
              buttonRef={buttonRef}
            />
          </div>
        </div>
      ) : (
        <div className="mt-2">
          <Card>
            <Card.Body>
              <SellBackPeriodEnd
                startDate={startDate}
                endDate={endDate}
                width={'250'}
                height={'250'}
              />
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
};
export default SellBackPTOCard;
