import { PolicySummaryFields } from '../types';

interface ShouldDisplayWorkedHrsTurns {
  employeePolicySummarydata: PolicySummaryFields;
}

const shouldDisplayWorkedHrsTurns = ({
  employeePolicySummarydata,
}: ShouldDisplayWorkedHrsTurns) => {
  if (
    employeePolicySummarydata.employeeClass === '4' &&
    employeePolicySummarydata.shiftAssignment === '12hr'
  ) {
    return true;
  } else {
    return false;
  }
};
export default shouldDisplayWorkedHrsTurns;
