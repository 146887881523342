type FormatCrewKey = (
  location: string | null,
  division: string | null,
  department: string | null,
  crew: string | null
) => string;

export const formatCrewKey: FormatCrewKey = (
  location,
  division,
  department,
  crew
) => {
  if (!location || !division || !department || !crew) {
    return '---';
  }

  return location + '-' + division + '-' + department + '-' + crew;
};

export const formatCrewKeyFromCode = (crewCode?: string) => {
  if (!crewCode || crewCode.length !== 13) {
    return '---';
  }
  const location = crewCode.slice(0, 3);
  const division = crewCode.slice(3, 5);
  const department = crewCode.slice(5, 7);
  const crew = crewCode.slice(7);
  const crewKey = `${location}-${division}-${department}-${crew}`;
  return crewKey;
};
