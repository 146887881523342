import React, { Fragment } from 'react';
import { Checkbox } from '@uss/react-components';
import { Listbox, Transition } from '@headlessui/react';
import { BsChevronDown } from 'react-icons/bs';

interface MultiSelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  children: React.ReactNode;
  selected: unknown;
  // using any because we do not know what the type will be.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect: (value: any) => void;
  className?: string;
  isMulti?: boolean;
}
const MultiSelect = ({
  children,
  onSelect,
  selected,
  className,
  isMulti,
  ...props
}: MultiSelectProps): JSX.Element => {
  return (
    <Listbox
      data-testid="listbox-container"
      value={selected}
      onChange={onSelect}
      multiple={isMulti}
      {...props}
    >
      <div className={`relative`}>{children}</div>
    </Listbox>
  );
};

interface MultiSelectLabelProps {
  children: React.ReactNode;
}

MultiSelect.Label = function Label({
  children,
}: MultiSelectLabelProps): JSX.Element {
  return (
    <Listbox.Label className="text-gray-500 text-xs mb-2">
      {children}
    </Listbox.Label>
  );
};
interface SelectButtonProps {
  error?: boolean;
  children?: React.ReactNode;
}

MultiSelect.Button = function Button({
  error = false,
  children,
}: SelectButtonProps): JSX.Element {
  return (
    <Listbox.Button
      data-testid="listbox-button"
      className={({ open }): string =>
        ` relative w-full pl-4 pr-2 md:pr-3 text-left h-10 text-sm disabled:border-gray-400 disabled:bg-gray-200 disabled:text-gray-500 bg-white odd:   rounded-sm cursor-default sm:text-sm focus-visible:outline-none focus-visible:ring-opacity-100 focus-visible:ring focus-visible:ring-accent1-400 focus-visible:border-accent1-400${
          open
            ? 'border-2  ' +
              (error
                ? 'border  border-red-600  rounded-br-none rounded-bl-none '
                : 'border border-accent1-400  rounded-br-none rounded-bl-none ')
            : ''
        }
        ${
          error
            ? 'border border-red-600 text-gray-700 '
            : 'border text-gray-700 border-gray-600 '
        }
        `
      }
    >
      {({ disabled }): JSX.Element => (
        <span className="flex flex-nowrap">
          <span className="flex flex-nowrap items-center flex-grow mr-1">
            {children}
          </span>
          <span className="pointer-events-none">
            <BsChevronDown
              size="1em"
              data-testid="button-icon-chevron"
              className={disabled ? 'text-gray-400' : 'text-primary-400'}
            />
          </span>
        </span>
      )}
    </Listbox.Button>
  );
};

interface SelectOptionsTypes {
  children: React.ReactNode;
  isWidthAuto?: boolean;
  error?: boolean;
}

MultiSelect.Options = React.forwardRef<HTMLUListElement, SelectOptionsTypes>(
  function Options(
    { children, error = false, isWidthAuto = false },
    ref
  ): JSX.Element {
    return (
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options
          ref={ref}
          className={`${error ? 'border-red-600' : ''} ${
            isWidthAuto ? ' min-w-full' : 'w-full '
          } absolute overflow-auto text-base 
          bg-white 
          shadow-lg max-h-60 
          sm:text-sm z-10 mt-1 focus-visible:outline-none`}
        >
          {children}
        </Listbox.Options>
      </Transition>
    );
  }
);

interface SelectOptionType {
  value: unknown;
  truncate?: boolean;
  id: string;
  label: string;
}

MultiSelect.Option = function Option({
  value,
  truncate = false,
  id,
  label,
}: SelectOptionType): JSX.Element {
  return (
    <Listbox.Option
      data-testid="listbox-option"
      value={value}
      className={({ active, selected }): string =>
        `${active ? 'text-gray-700 bg-blue-50' : 'text-gray-700'}
        ${selected ? 'text-gray-700 bg-blue-50' : ''}
           cursor-default select-none relative
           ${truncate ? 'truncate' : ''}
           `
      }
    >
      {({ selected }): JSX.Element => (
        <span
          data-testid="select-value"
          className={`flex items-center mx-4 py-2 border-b border-gray-5000  ${
            selected ? 'text-gray-700 font-medium' : 'font-normal'
          } `}
        >
          <Checkbox
            id={id}
            label={label}
            hideLabel
            checked={selected}
            readOnly
          />
          {label}
        </span>
      )}
    </Listbox.Option>
  );
};

export default MultiSelect;
