import { PAY_PERIOD_STATUS, PayPeriodStatus } from 'features/pay-periods/types';

/***
 * This utility function is used to determine the appropriate Timecard Status message to be
 * displayed in Details page when timecard does not exist for the employee.
 * @param payPeriodStatus PayPeriodStatus of the timecard that is being viewed currently
 * @param isPay boolean used to determine whether the current page is under NUPay or NUScheduling
 */
const getTimecardStatusDescription = (
  payPeriodStatus: PayPeriodStatus | undefined,
  isPay: boolean
) => {
  let description = '';
  if (isPay && payPeriodStatus) {
    if (payPeriodStatus === PAY_PERIOD_STATUS.OPEN) {
      description =
        'Timecard does not exist for this employee, Save hours to create one';
    } else if (payPeriodStatus === PAY_PERIOD_STATUS.NOT_OPEN) {
      description =
        'Timecard does not exist for this employee, use Scheduling to create one';
    } else {
      description =
        'Timecard does not exist for this employee, Open for Adjustment to create one';
    }
  } else {
    if (
      payPeriodStatus === PAY_PERIOD_STATUS.OPEN ||
      payPeriodStatus === PAY_PERIOD_STATUS.NOT_OPEN
    ) {
      description =
        'Timecard does not exist for this employee, Save hours to create one';
    } else {
      description = 'Timecard does not exist for this employee';
    }
  }
  return description;
};

export default getTimecardStatusDescription;
