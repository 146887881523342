import { CalendarEventColor } from 'components/CalendarV2/types';
import { ModelStatus } from '../types';
import { MODEL_STATUS } from '../constants';

//create badge color according to status
export const getModelStatusColors = (
  value: ModelStatus
): CalendarEventColor => {
  switch (value) {
    case MODEL_STATUS.ACTIVE: {
      return 'green';
    }
    case MODEL_STATUS.ARCHIVED: {
      return 'gray';
    }
    case MODEL_STATUS.CHECKED_OUT: {
      return 'yellow';
    }
    case MODEL_STATUS.PENDING: {
      return 'purple';
    }
    default:
      return 'gray';
  }
};
