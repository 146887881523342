import { CrewTimeCard } from '../types';

const getHighApprovedHrsEmps = (employees: CrewTimeCard[]) => {
  return employees.filter(
    (emp) =>
      emp.totalHours.approved &&
      emp.totalHours.worked &&
      emp.totalHours.worked < emp.totalHours.approved
  );
};

export default getHighApprovedHrsEmps;
