import { useQuery } from '@tanstack/react-query';
import { SortDirection } from 'types/common';

import { axios } from '../../../services/api/axios';
import { RoleItem } from '../types';
type RoleSortBy = 'code' | 'name';

export interface RoleList {
  items: RoleItem[];
}

export interface RoleListOptions {
  orgId?: string;
  sortBy?: RoleSortBy;
  sortType?: SortDirection;
}

const getOrgRoles = (params: RoleListOptions): Promise<RoleList> => {
  return axios.get(`/v1/org-roles`, { params });
};
/**
 * @returns Axios Promise
 *
 * @link https://cappsd2.psc.uss.com:8345/SBWM-DOCUMENT/swagger-ui/index.html?urls.primaryName=sbwm-people#/org-role-controller/getOrgRoles
 */
export const useGetOrgRoles = (params: RoleListOptions) => {
  return useQuery({
    queryKey: ['roles', { ...params }],
    queryFn: () => getOrgRoles(params),
    meta: {
      errorMessage: 'Problem fetching the org roles',
    },
    enabled: !!params.orgId,
    refetchOnMount: true,
  });
};
