import { Accordion } from '@uss/react-components';

interface HelpContent {
  title: string;
  description: string;
}
const PerformanceCriteriaHelp = () => {
  const helpContent: HelpContent[] = [
    {
      title: 'Ability to work safely ',
      description:
        'Employee follows safe job procedures (SJP), wears required personal protective equipment (PPE) for all job tasks, acknowledges signals and alarms in accordance with standard operating procedures (SOP), energy control procedures (ECP), and recognizes potential job hazards (correspondence with supervisor or safety representatives as needed)',
    },
    {
      title: 'Ability to work with others',
      description:
        'Employee works cooperatively with co-workers, contractors, supervisors, and all other stakeholders. Employee actively participates in all job tasks that require a team effort- acts as an integral part of the team and not a spectator. Demonstrates inclusive team player behavior.',
    },
    {
      title: 'Quantity of work',
      description:
        'Employee completes all steps of job tasks including but not limited to: required paperwork, tracking, notifications, and defined measurements of work product. All work is completed in a timely manner as defined by production or process',
    },
    {
      title: 'Quality of work',
      description:
        'Employee follows procedures as defined in standard operating procedures (SOP), as reviewed in safety meetings, and adjusts according to changes in process or tasks as instructed verbally or in writing via safety reports or various communications. Employee follows instructions when observed and advised by supervisor',
    },
    {
      title: 'Understanding written and verbal instruction',
      description:
        'Employee follows procedures as defined in standard operating procedures (SOP), as reviewed in safety meetings, and adjusts according to changes in process or tasks as instructed verbally or in writing via safety reports or various communications. Employee follows instructions when observed and advised by supervisor',
    },
    {
      title: 'Attitude',
      description:
        'Employee behaves in a cordial manner. Willingly accepts feedback and exhibits corrective actions.  Does not complain about work assignments and willingly learns new tasks or accepts new or different work assignments.',
    },
    {
      title: 'Overall value',
      description:
        'Employee adds value to organization by working safely, productively, and cooperatively. Employee exemplifies company values and contributes to overall objectives of organization',
    },
    {
      title: 'Attendance',
      description:
        'Employee reports as scheduled or instructed and has little to no issues with absenteeism',
    },
  ];
  return (
    <div data-testid="perf-criteria-help">
      {helpContent.map((category) => (
        <HelpCard
          title={category.title}
          description={category.description}
          key={category.title}
        />
      ))}
    </div>
  );
};
const HelpCard = ({ title, description }: HelpContent) => {
  return (
    <Accordion
      variant="default"
      className="shadow-md bg-white rounded-lg mb-4 last-of-type:mb:0"
      data-testid="help-card"
    >
      <Accordion.Button variant="default">
        <p
          className="text-base text-gray-700 font-semibold"
          data-testid="help-card-title"
        >
          {title}
        </p>
      </Accordion.Button>
      <Accordion.Panel variant="default">
        <div
          className="w-full pt-4  border-t-2 border-t-gray-200"
          data-testid="help-card-description"
        >
          <p className="text-sm text-gray-700 ">{description}</p>
        </div>
      </Accordion.Panel>
    </Accordion>
  );
};
export default PerformanceCriteriaHelp;
