import { parseISO } from 'date-fns';
import { Holiday, HolidayList } from 'features/holidays';

const isDateHoliday = (
  date: string,
  data: HolidayList | undefined
): boolean => {
  let arr: Holiday[] = [];
  if (data) {
    const list = data.items;
    arr = list.filter((m) => {
      return (
        parseISO(m.date).toLocaleDateString() ===
        parseISO(date).toLocaleDateString()
      );
    });
  }
  return arr.length > 0;
};

export default isDateHoliday;
