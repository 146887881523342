import { Button } from '@uss/react-components';
import React from 'react';
import PayActions from './PayActions';
import SchedulingActions from './SchedulingActions';
import {
  EmployeeTimecard,
  TIMECARD_ACTION_BUTTON,
  TimecardActionButton,
} from 'features/timecards/types';
import useTimecardStatus from 'features/timecards/hooks/useTimecardStatus';
import useModal from 'components/Modals/use-modal';
import { getActionModalProperties } from 'features/timecards/utilities/getActionModalProperties';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import { alertType } from 'components/Modals/ModalsContext';
interface TimecardActionComponentProps {
  buttonRef: React.MutableRefObject<TimecardActionButton>;
  handleCancel: () => void;
  handleUpdate: () => Promise<void>;
  employeeTimecardObj: EmployeeTimecard;
  isTimecardDisabled: boolean;
  approveAudits: alertType[];
}

const TimecardAction = ({
  buttonRef,
  handleCancel,
  handleUpdate,
  employeeTimecardObj,
  isTimecardDisabled,
  approveAudits,
}: TimecardActionComponentProps) => {
  const state = useNonUnionStore();
  const feature = state.feature;
  const {
    canSave,
    canSaveApprove,
    canUnapprove,
    canOpenAdjustment,
    canSaveAdjustment,
    canSaveApproveAdjustment,
    canUnapproveAdjustment,
    isApproveDisabled,
    canSaveVerify,
    isSaveDisabled,
    isSaveVerifyDisabled,
  } = useTimecardStatus(employeeTimecardObj);

  const isPay = feature === 'pay';
  const actionModal = useModal();
  return (
    <>
      <Button
        onClick={handleCancel}
        variant="outline"
        className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
        type="button"
        data-testid={TIMECARD_ACTION_BUTTON.CANCEL}
      >
        Cancel
      </Button>
      {canSave() && (
        <Button
          onClick={() => {
            if (employeeTimecardObj.id === 'temp') {
              buttonRef.current = TIMECARD_ACTION_BUTTON.CREATE_TIMECARD;
            } else buttonRef.current = TIMECARD_ACTION_BUTTON.SAVE;
            const { modalTitle, confirmButtonTitle, modalBodyText } =
              getActionModalProperties(buttonRef);
            actionModal.openModal({
              title: modalTitle,
              type: 'regular',
              size: 'md',
              children: (
                <p className="text-sm text-gray-700">{modalBodyText}</p>
              ),
              labels: { confirm: confirmButtonTitle, cancel: 'Cancel' },
              onCancel: actionModal.closeModal,
              onConfirm: async () => {
                actionModal.closeModal();
                await handleUpdate();
              },
            });
          }}
          variant="primary"
          className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
          type="submit"
          disabled={isSaveDisabled() || isTimecardDisabled}
          data-testid={TIMECARD_ACTION_BUTTON.SAVE}
        >
          Save
        </Button>
      )}
      {isPay ? (
        <PayActions
          buttonRef={buttonRef}
          handleUpdate={handleUpdate}
          payButtonsState={{
            //Pay Screen Buttons States
            canSaveApprove: canSaveApprove(),
            canUnapprove: canUnapprove(),
            canOpenAdjustment: canOpenAdjustment(),
            canSaveAdjustment: canSaveAdjustment(),
            canSaveApproveAdjustment: canSaveApproveAdjustment(),
            canUnapproveAdjustment: canUnapproveAdjustment(),
            isApproveDisabled: isApproveDisabled,
          }}
          isTimecardDisabled={isTimecardDisabled}
          employee={employeeTimecardObj}
          approveAuditalerts={approveAudits}
        />
      ) : (
        <SchedulingActions
          buttonRef={buttonRef}
          handleUpdate={handleUpdate}
          schedulingButtonsState={{
            //Scheduling Screen Buttons States
            canSaveVerify: canSaveVerify(),
            isSaveVerifyDisabled: isSaveVerifyDisabled(),
          }}
          isTimecardDisabled={isTimecardDisabled}
        />
      )}
    </>
  );
};

export default TimecardAction;
