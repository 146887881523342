import React from 'react';

import { Paginate, Select } from '@uss/react-components';
import useWindowSize from 'hooks/useWindowSize';

interface PaginationProps {
  totalCount: number | undefined;
  page: number | undefined;
  pageSize: number | undefined;
  onPageChange: (selectedItem: { selected: number }) => void;
  onPageSizeChange: (ps: number) => void;
  pageOptions: number[];
}

const Pagination = ({
  totalCount = 16,
  page = 1,
  pageSize = 10,
  onPageChange,
  onPageSizeChange,
  pageOptions,
}: PaginationProps) => {
  const pageCount =
    !!totalCount && !!pageSize ? Math.ceil(totalCount / pageSize) : 0;

  const handlePageSizeChange = (value: number) => {
    onPageSizeChange(value);
    onPageChange({ selected: 0 });
  };
  const entriesOnCurrentPage = page * pageSize;
  const { width } = useWindowSize();

  let pageRangeVal = 3;
  if (width && width < 768) {
    pageRangeVal = 1;
  } else if (width && width > 1280) {
    pageRangeVal = 4;
  }

  if (totalCount < pageOptions[0] + 1) return <></>;

  return (
    <div
      data-testid="pagination-container"
      className="w-full flex lg:justify-between justify-center flex-row flex-wrap lg:flex-nowrap"
    >
      <div
        data-testid="pagination-page-size"
        className="flex flex-row text-sm w-full lg:w-1/5 items-center justify-center lg:justify-start pb-4 lg:pb-0"
      >
        <span>Show </span>
        <Select
          selected={pageSize}
          onSelect={(value: number) => handlePageSizeChange(value)}
          className="mx-2 w-16"
        >
          <Select.Button label={pageSize.toString()} />
          <Select.Options>
            {pageOptions.map((i) => {
              return (
                <Select.Option key={i.toString()} value={i}>
                  {i.toString()}
                </Select.Option>
              );
            })}
          </Select.Options>
        </Select>{' '}
        <span>entries</span>
      </div>
      <div
        data-testid="pagination-pages"
        className="flex flex-row justify-center w-full lg:w-2/4 px-4 lg:px-0 pb-4 lg:pb-0"
      >
        <Paginate
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={pageRangeVal}
          onPageChange={onPageChange}
          forcePage={page - 1}
        />
      </div>
      <div
        data-testid="pagination-total-results"
        className="flex flex-row w-full lg:w-1/4 items-center text-sm justify-center lg:justify-end"
      >
        Showing {page === 1 ? page : (page - 1) * pageSize + 1} to{' '}
        {entriesOnCurrentPage > totalCount ? totalCount : page * pageSize} of{' '}
        {totalCount} entries
      </div>
    </div>
  );
};

export default Pagination;
