import { z } from 'zod';

export const CrewSchema = z.object({
  plant: z.string(),
  payrollLocation: z.string(),
  division: z.string(),
  department: z.string(),
  crew: z.string().min(6),
  crewDescription: z.string().min(1),
});
