import {
  UnapprovedCrewEmployeeList,
  UnapprovedCrewEmployeeListOptions,
} from 'features/paypulloff/types';
import { useQuery } from '@tanstack/react-query';
import { axios } from 'services/api/axios';

const get_unapproved_crews_employees = (
  payrollName: string,
  crewId: string,
  params: UnapprovedCrewEmployeeListOptions
): Promise<UnapprovedCrewEmployeeList> => {
  return axios.get(`/v1/payrolls/${payrollName}/unapproved-crews/${crewId}`, {
    params,
  });
};

export const useUnapprovedEmployees = (
  payrollName: string,
  crewId: string,
  params: UnapprovedCrewEmployeeListOptions
) => {
  return useQuery<UnapprovedCrewEmployeeList>({
    queryKey: ['unapprovedCrewEmployees', { payrollName, crewId, ...params }],
    queryFn: () => get_unapproved_crews_employees(payrollName, crewId, params),
    staleTime: 5000,
    gcTime: 5000,
    refetchOnMount: true,
  });
};
