import clsx from 'clsx';
import EmployeesSummaryContext, {
  useEmployeesSummaryContext,
} from './EmployeesSummaryContext';
import { QueryStatus } from '@tanstack/react-query';
import Card from 'components/Card/Card';
import Loader from 'components/Skeleton/Loader';
import {
  HrLrResponse,
  ReviewerResponse,
  SupervisorResponse,
} from 'features/probationary-tracking/types';

export type KPIOptions = Partial<SupervisorResponse> &
  Partial<ReviewerResponse> &
  Partial<HrLrResponse>;
interface EmployeesSummaryProps {
  data: KPIOptions | undefined | null;
  status: QueryStatus;
  children?: React.ReactNode;
}
interface SummaryKPIProps {
  label: string;
  value: number | undefined;
  status: QueryStatus;
  width?: string;
  borderRight?: boolean;
  paddingLeft?: boolean;
}
interface KPIProps {
  customLabel?: string;
  width?: string;
  borderRight?: boolean;
  paddingLeft?: boolean;
}
function EmployeesSummary({ data, status, children }: EmployeesSummaryProps) {
  return (
    <EmployeesSummaryContext.Provider value={{ data, status }}>
      <Card bgColor={'bg-white'} border="border border-blue-600">
        <Card.Body>
          <div
            className="w-full h-full py-2 flex  max-sm:flex-wrap  align-center  gap-y-6 max-sm:[&>*:nth-child(3n)]:border-0 max-sm:[&>*:nth-child(3n)]:pr-0  max-sm:[&>*:nth-child(3n+1)]:pl-0"
            data-testid="summary-card"
          >
            {children}
          </div>
        </Card.Body>
      </Card>
    </EmployeesSummaryContext.Provider>
  );
}
function SummaryKPI({
  label,
  value,
  status,
  width,
  borderRight = true,
  paddingLeft = true,
}: SummaryKPIProps) {
  const getFormattedValue = (value: number) => {
    return value.toString().padStart(2, '0');
  };
  return (
    <div
      className={clsx(
        width ? width : '',
        borderRight ? 'border-r pr-4' : 'border-r-0 sm:border-r',
        paddingLeft ? 'pl-4' : 'pl-0 sm:pl-4',
        'flex flex-col justify-between border-gray-300 first-of-type:pl-0 last-of-type:border-0 last-of-type:pr-0'
      )}
      data-testid="summary-kpi"
    >
      <p className="text-xs sm:text-sm  font-light  text-gray-700 ">{label}</p>
      <p className="text-gray-900 ">
        {status === 'success' ? (
          <span
            className="text-xl md:text-3xl font-semibold  text-gray-900"
            data-testid="kpi-content"
          >
            {value ? getFormattedValue(value) : '-'}
          </span>
        ) : (
          <span
            className="text-xl md:text-3xl font-semibold"
            data-testid="kpi-loader"
          >
            <Loader rows={1} />
          </span>
        )}
      </p>
    </div>
  );
}
function TotalEmployees({ customLabel, width }: KPIProps) {
  const { data, status } = useEmployeesSummaryContext();
  return (
    <SummaryKPI
      label={customLabel ?? 'Total Employees'}
      value={data?.totalEmployees}
      status={status}
      width={width}
    />
  );
}
function Unassigned({ customLabel, width }: KPIProps) {
  const { data, status } = useEmployeesSummaryContext();
  return (
    <SummaryKPI
      label={customLabel ?? 'Unassigned'}
      value={data?.unassigned}
      status={status}
      width={width}
    />
  );
}
function Assigned({ customLabel, width }: KPIProps) {
  const { data, status } = useEmployeesSummaryContext();
  return (
    <SummaryKPI
      label={customLabel ?? 'Assigned'}
      value={data?.assigned}
      status={status}
      width={width}
    />
  );
}
function ReviewPerformance({ customLabel, width, paddingLeft }: KPIProps) {
  const { data, status } = useEmployeesSummaryContext();
  return (
    <SummaryKPI
      label={customLabel ?? 'Review Performance'}
      value={data?.reviewPerformance}
      status={status}
      width={width}
      paddingLeft={paddingLeft}
    />
  );
}
function Termination({ customLabel, width }: KPIProps) {
  const { data, status } = useEmployeesSummaryContext();
  return (
    <SummaryKPI
      label={customLabel ?? 'Termination'}
      value={data?.termination}
      status={status}
      width={width}
    />
  );
}

function ReviewDue({ customLabel, width }: KPIProps) {
  const { data, status } = useEmployeesSummaryContext();
  return (
    <SummaryKPI
      label={customLabel ?? 'Review Due'}
      value={data?.reviewDue}
      status={status}
      width={width}
    />
  );
}
function Revaluate({ customLabel, width }: KPIProps) {
  const { data, status } = useEmployeesSummaryContext();
  return (
    <SummaryKPI
      label={customLabel ?? 'Re-evaluate'}
      value={data?.revaluate}
      status={status}
      width={width}
    />
  );
}
function Counseling({ customLabel, width }: KPIProps) {
  const { data, status } = useEmployeesSummaryContext();
  return (
    <SummaryKPI
      label={customLabel ?? 'Counseling'}
      value={data?.counselling}
      status={status}
      width={width}
    />
  );
}
function FinalReview({ customLabel, width }: KPIProps) {
  const { data, status } = useEmployeesSummaryContext();
  return (
    <SummaryKPI
      label={customLabel ?? 'Final Review'}
      value={data?.finalReview}
      status={status}
      width={width}
    />
  );
}

function LowRating({ customLabel, width, borderRight }: KPIProps) {
  const { data, status } = useEmployeesSummaryContext();
  return (
    <SummaryKPI
      label={customLabel ?? 'Employees with low rating'}
      value={data?.lowRating}
      status={status}
      width={width}
      borderRight={borderRight}
    />
  );
}

EmployeesSummary.TotalEmployees = TotalEmployees;
EmployeesSummary.Assigned = Assigned;
EmployeesSummary.Unassigned = Unassigned;
EmployeesSummary.ReviewPerformance = ReviewPerformance;
EmployeesSummary.Termination = Termination;
EmployeesSummary.Assigned = Assigned;
EmployeesSummary.ReviewDue = ReviewDue;
EmployeesSummary.Revaluate = Revaluate;
EmployeesSummary.Counseling = Counseling;
EmployeesSummary.FinalReview = FinalReview;
EmployeesSummary.LowRating = LowRating;

export default EmployeesSummary;
