import { useReducer } from 'react';
import { UnapprovedCrewEmployeeListOptions } from '../types';

type EmployeeFilterAction =
  | { type: 'search'; payload: string }
  | { type: 'page'; payload: number }
  | { type: 'pageSize'; payload: number }
  | { type: 'clearSearch' };

export const useUnapproveEmployeeParams = (
  initialParams: UnapprovedCrewEmployeeListOptions
) => {
  const filterReducer = (
    state: UnapprovedCrewEmployeeListOptions,
    action: EmployeeFilterAction
  ): UnapprovedCrewEmployeeListOptions => {
    switch (action.type) {
      case 'page': {
        state.page = action.payload;
        return { ...state };
      }
      case 'pageSize': {
        state.pageSize = action.payload;
        return { ...state };
      }

      case 'search': {
        if (action.payload.length > 0) {
          state.search = action.payload;
          state.page = 1;
        } else delete state.search;
        return { ...state };
      }
      case 'clearSearch': {
        delete state.search;
        state.page = 1;
        return { ...state };
      }
      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(filterReducer, initialParams);

  return { state, dispatch };
};
