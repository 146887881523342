import { Select } from '@uss/react-components';
import Page from 'components/Layouts/Page';
import Tabs from 'components/Tabs/Tabs';
import { usePulloff } from 'features/paypulloff/api';
import { usePayrollName } from 'features/paypulloff/api/getPayrollName';
import {
  LocationList,
  PayPeriodPulloff,
  PayrollNameSchema,
} from 'features/paypulloff/types';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ComingSoon from 'views/ComingSoon';
import { Processing } from './Tabs/Processing';
import UnapprovedCrews from './Tabs/UnapprovedCrews';
import NoResults from 'components/NoResults/NoResults';

export const PayPulloff = () => {
  const { data: payrollNamesData, status: payrollNameStatus } = usePayrollName({
    enabled: 'Y',
  });
  const [locationList, setLocationList] = useState<LocationList[] | null>(null);
  const [payrollName, setPayrollName] = useState<PayrollNameSchema | null>(
    null
  );

  const { status, data, isFetching } = usePulloff(
    payrollName ? payrollName.id : ''
  );

  useEffect(() => {
    if (payrollNameStatus === 'success') {
      setPayrollName(payrollNamesData.items[0]);
    }
  }, [payrollNameStatus, payrollNamesData]);

  useEffect(() => {
    if (status === 'success') {
      data.items.sort((a, b) => {
        return a.locationName > b.locationName
          ? 1
          : b.locationName > a.locationName
          ? -1
          : 0;
      });
      const list: LocationList[] = data.items.map((item) => {
        return {
          locationName: item.locationName,
          locationDescription: item.locationDescription,
        };
      });
      setLocationList(list);
    }
  }, [data, status]);
  return (
    <Page
      heading="Pay Pulloff"
      breadcrumbs={[
        <NavLink
          key="bc-1"
          className="flex flex-row justify-center items-center"
          to={`/pay`}
        >
          Pay
        </NavLink>,
      ]}
    >
      <Page.Section bg="gray">
        <div className="flex sm:flex-row flex-col justify-between flex-wrap">
          <div className="w-full sm:w-10/12">
            <div className="w-full mb-2 sm:mb-0 sm:w-4/12 ">
              <Select
                selected={payrollName}
                onSelect={setPayrollName}
                className="w-full"
              >
                <Select.Button
                  label={payrollName?.id ? `${payrollName.name}` : 'Select'}
                />

                <Select.Options>
                  {payrollNamesData && payrollNamesData.items.length > 0 ? (
                    payrollNamesData.items.map(
                      (item: PayrollNameSchema, index: number) => (
                        <Select.Option truncate key={index} value={item}>
                          <span>{`${item.name}`}</span>
                        </Select.Option>
                      )
                    )
                  ) : (
                    <span>No location available</span>
                  )}
                </Select.Options>
              </Select>
            </div>
          </div>
        </div>
      </Page.Section>
      <Page.Section>
        <PayPulloffTabs
          payrollName={payrollName?.id ?? ''}
          locationList={locationList ?? []}
          status={payrollNameStatus}
          payPulloffStatus={status}
          payPulloffData={data}
          isFetching={isFetching}
        />
      </Page.Section>
    </Page>
  );
};

// export default PayPulloff;

const PayPulloffTabs = ({
  payrollName,
  locationList,
  status,
  payPulloffStatus,
  payPulloffData,
  isFetching,
}: {
  payrollName: string;
  locationList: LocationList[];
  status: string;
  payPulloffStatus: string;
  payPulloffData: PayPeriodPulloff | undefined;
  isFetching: boolean;
}) => {
  const payDetailsTabLinks = [
    {
      label: 'Processing',
      element: (
        <>
          {payrollName && payrollName.length > 0 ? (
            <Processing
              payrollName={payrollName}
              data={payPulloffData}
              status={payPulloffStatus}
              isFetching={isFetching}
            />
          ) : (
            <>
              {status !== 'pending' && (
                /* if payroll name is empty */
                <NoResults
                  width={'300'}
                  height={'300'}
                  description="No records found, Please modify the search criteria to view the records"
                />
              )}
            </>
          )}
        </>
      ),
    },
    {
      label: 'History',
      element: <ComingSoon />,
    },
    {
      label: 'Unapproved Crews',
      element: (
        <>
          {payrollName && payrollName.length > 0 ? (
            <UnapprovedCrews
              payrollName={payrollName}
              locationList={locationList}
            />
          ) : (
            /* if payroll name is empty */
            <NoResults
              width={'300'}
              height={'300'}
              description="No records found, Please modify the search criteria to view the records"
            />
          )}
        </>
      ),
    },
  ];

  return <Tabs tabLinks={payDetailsTabLinks}></Tabs>;
};
