import { Accordion } from '@uss/react-components';
import DetailsSkeleton from 'components/Skeleton/DetailsSkeleton';
import MiniMillSummary from './MiniMillSummary';
import IntegratedNonExemptSummary from './IntegratedNonExemptSummary';
import PartTimeSummary from './PartTimeSummary';
import { useEmployeePtoSummary } from 'features/paid-time-off/api/queries';
import { UnauthorizedMsg } from '../UnauthorizedMsg/UnauthorizedMsg';

interface AnnualTotalProps {
  year: string;
  id?: string | undefined;
}
export const AnnualTotals = ({ year, id }: AnnualTotalProps) => {
  const ussid = id ?? 'me';
  //annual total data
  const { data, status } = useEmployeePtoSummary(ussid, {
    year: year,
  });

  return (
    <div className="mt-4">
      {status === 'pending' && <DetailsSkeleton />}
      {status === 'success' && (
        <Accordion
          isOpen={true}
          variant="default"
          className="shadow-md bg-white rounded-lg "
        >
          <Accordion.Button variant="default">
            <div className="w-full mb-0 md:mb-2">
              <h2 className="text-lg font-semibold">
                {year}{' '}
                {data.ptoClass.name === 'integrated-non-exempt' &&
                  'Annual PTO Totals'}
                {data.ptoClass.name === 'mini-mill' && 'Annual Totals'}
                {data.ptoClass.name === 'part-time-paid-sick' &&
                  'Annual Paid State Leave Totals'}
              </h2>
            </div>
          </Accordion.Button>
          <Accordion.Panel variant="default">
            <div className="flex flex-row w-full items-center">
              {data.ptoClass.name === 'mini-mill' && (
                <MiniMillSummary summary={data} />
              )}
              {data.ptoClass.name === 'integrated-non-exempt' && (
                <IntegratedNonExemptSummary summary={data} />
              )}
              {data.ptoClass.name === 'part-time-paid-sick' && (
                <PartTimeSummary summary={data} />
              )}
            </div>
          </Accordion.Panel>
        </Accordion>
      )}
      {data?.ptoClass.name === 'integrated-exempt' && (
        <UnauthorizedMsg description="Employee’s Paid Time Off is managed in Oracle Absence Tracker" />
      )}
    </div>
  );
};
