import {
  Button,
  Checkbox,
  CheckboxGroup,
  IconButton,
  Sidepane,
} from '@uss/react-core';
import { useEffect, useState } from 'react';
import { BsX } from 'react-icons/bs';
import ConfirmationPopup from '../ModelDetails/ConfirmationPopup';
import { useEditModelStore } from 'features/model-listing/state/edit-model-store';
import { useCopyWeekModel } from 'features/model-listing/api/mutations';
import { CopyWeekModelReqBody } from 'features/model-listing/types';
import { useModelDetailStore } from 'features/model-listing/state/model-detail-store';
import { notify } from 'components/Notification/useNotification';
import { queryClient } from 'services/api/react-query';

interface CopyScheduleProps {
  isOpenDrawer: boolean;
  setOpenCopySchedule: React.Dispatch<React.SetStateAction<boolean>>;
  totalWeeks: number;
  selectedWeek: number;
}

export const CopySchedule = ({
  isOpenDrawer,
  setOpenCopySchedule,
  totalWeeks,
  selectedWeek,
}: CopyScheduleProps) => {
  const store = useEditModelStore();
  //Getting Model Details Id
  const modelDetailStore = useModelDetailStore();
  const modelDetailId = modelDetailStore.modelDetail?.id;
  //Copy Week API Call
  const { mutateAsync } = useCopyWeekModel();
  const weeksArray: number[] = Array.from(
    Array(totalWeeks).keys(),
    (x) => x + 1
  ).filter((n) => n !== selectedWeek);
  const [checked, setChecked] = useState<string[]>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const handleClose = () => {
    setOpenCopySchedule(false);
    setChecked([])
  };

  const handleSubmit = async () => {
    const checkedArrWithoutAll = checked.filter(item => item !== 'All');
    let isAlreadyData = false;
    if (store.weeks && store.weeks.length > 0) {
      //checkedArrWithoutAll : ['1','2','3']
      for (const val of checkedArrWithoutAll) {
        const arrWitWeekData = [];
        store.weeks.forEach((week) => {
          //finding target week from checkboxes array i.e checkedArrWithoutAll[]
          if (week.weekNo.toString() === val) {
            const filteredData = [];
            //checking if target week already contains some data
            week.crewRotations.forEach(entry => {
              if (entry.schedTurns.filter(week => week.turnValue !== 0).length > 0) {
                filteredData.push(entry)
              }
            })
            if (filteredData.length > 0) {
              arrWitWeekData.push(week);
            }
          }
        })
        if (arrWitWeekData.length > 0) {
          isAlreadyData = true;
          break;
        }
      }
    }
    if (isAlreadyData) setOpenPopup(true)
    else await handleCopyWeek()
  }

  const handleCopyWeek = async () => {
    const checkedArrWithoutAll = checked.filter(item => item !== 'All');
    const targetWeeksVal: number[] = checkedArrWithoutAll.map(num => Number(num))
    const body: CopyWeekModelReqBody = {
      payload: {
        sourceWeek: selectedWeek,
        targetWeeks: targetWeeksVal
      },
      id: modelDetailId ?? ''
    }
    await mutateAsync(body, {
      onSuccess: async () => {
        handleClose();
        notify.success({
          message: 'You have successfully copied the schedule to other week(s).',
        });
        await queryClient.resetQueries({
          queryKey: ['model-details'],
          type: 'active',
        });
      }
    })
  }

  const handleSelectAll = () => {
    //perform uncheck for Select All
    if (checked.includes('All')) {
      setChecked([]);
    } else {
      //perform check for Select All
      const strArr = ['All', ...weeksArray.map(item => item.toString())];
      setChecked(strArr)
    }
  }

  //Logic to handle automatic check/uncheck of Select ALL based on child check/uncheck
  useEffect(() => {
    const arrWithoutAll = checked.filter(item => item !== 'All');
    if (checked.includes('All')) {
      if (arrWithoutAll.length < weeksArray.length) {
        setChecked(arrWithoutAll);
      }
    } else if (arrWithoutAll.length === weeksArray.length) {
      setChecked(['All', ...checked]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <>
      <Sidepane
        ariaLabel={''}
        isOpen={isOpenDrawer}
        placement={'right'}
        className="w-full sm:w-3/12 md:w-3/12"
      >
        <Sidepane.Header className="flex">
          <div className="flex text-xl font-semibold flex-grow">
            Copy Schedule
          </div>
          <IconButton onPress={handleClose}>
            <BsX className="text-gray-600" />
          </IconButton>
        </Sidepane.Header>
        <Sidepane.Content className=" overflow-y-auto h-full">
          Select the week/weeks you want to copy this schedule to
          <div className="w-full flex flex-col flex-grow gap-5 mb-6 mt-6">
            <CheckboxGroup label={''} value={checked} onChange={setChecked}>
              <Checkbox value='All' onChange={handleSelectAll}> Select All</Checkbox>
              {weeksArray.map((week, index) => (
                <div key={index} className="my-3">
                  <Checkbox value={week.toString()}>{`Week ${week}`}</Checkbox>
                </div>
              ))}
            </CheckboxGroup>
          </div>
        </Sidepane.Content>
        <Sidepane.Controls className="flex md:flex-row flex-col items-end justify-end mt-6">
          <div className="sm:flex sm:justify-between sm:gap-2">
            <Button
              variant="outline"
              color={'primary'}
              full={true}
              type="button"
              onPress={handleClose}
            >
              Cancel
            </Button>
            <Button variant="filled" color={'primary'} full={true}
              onPressChange={handleSubmit}
              isDisabled={checked.length === 0}
            >
              Copy
            </Button>
          </div>
        </Sidepane.Controls>
      </Sidepane>
      <ConfirmationPopup
        isOpen={openPopup}
        title={'Copy Schedule'}
        message={'Any existing schedule(s) will be overwritten.'}
        secondaryMsg={'Are you sure want to copy?'}
        submitButtonText={'Copy'}
        handleAction={handleCopyWeek}
        setIsModelOpen={setOpenPopup}
      />
    </>
  );
};
