import { Org } from 'features/org';

export function findPlant(
  code: string | null,
  payrollLocations: Org[] | null | undefined
): string {
  if (!payrollLocations || !code) return '-';
  const location = payrollLocations.find((l) => l.code === code);
  if (!location?.description) return '-';

  return `${location.plant}`;
}

export function findLocation(
  code: string | null,
  payrollLocations: Org[] | null | undefined
): string {
  if (!payrollLocations || !code) return '-';
  const location = payrollLocations.find((l) => l.code === code);
  if (!location?.description) return code;

  return `${code} - ${location.description}`;
}
