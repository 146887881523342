import {
  AddEmployee,
  CancelMemebrMoveRequest,
  CardsLoader,
  DeleteCrewMemberWithUssId,
  HandleMoveEmployee,
  MemberCard,
} from 'features/crew-maintenance';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import CardList from 'components/Card/CardList';
import Error from 'components/Error/Error';
import useModal from 'components/Modals/use-modal';
import useNotification from 'components/Notification/useNotification';
import Pagination from 'components/Pagination/Pagination';
import {
  useCancelMemberMove,
  useChangeCrews,
  useDeleteCrewMember,
} from 'features/crew-maintenance/api';
import MoveEmployee from 'features/crew-maintenance/components/CrewDetails/Assignments/Members/MoveEmployee';
import {
  useAssignmentsPageParams,
  useAssignmentsParamsDispatch,
} from 'features/crew-maintenance/context/CrewDetailsContextProvider';
import { OrgSecurityRecord } from 'features/org';
import { useOrgDetails } from 'features/org/api';
import { usePayPeriodsOpenStatus } from 'features/pay-periods/api';
import { ROLECODES } from 'features/roles';
import { useCrewMembers } from 'services/api/common';
import { queryClient } from 'services/api/react-query';
import { Button } from '@uss/react-components';
import { BsPlus } from 'react-icons/bs';

const Assignments = () => {
  const { crewId = '' } = useParams<'crewId'>();

  const params = useAssignmentsPageParams();
  const { data, status } = useCrewMembers(crewId, {
    ...params,
    retrieveFutureDatedRecords: 'Y',
  });
  const dispatch = useAssignmentsParamsDispatch();

  const { data: effectiveDate } = usePayPeriodsOpenStatus(
    'big_river_steel_weekly'
  );

  const { data: currentCrew } = useOrgDetails(crewId);
  const [selectedRecord, setSelectedRecord] =
    useState<OrgSecurityRecord | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [addOpen, setAddOpen] = useState(false);
  const { mutateAsync: deleteCrewAssignment } = useDeleteCrewMember(crewId);
  const { mutateAsync: cancelMemberMove } = useCancelMemberMove();

  const notify = useNotification();
  const modal = useModal();

  //Move emplpoyee
  const { mutateAsync: changeCrew } = useChangeCrews();

  const handleMove: HandleMoveEmployee = async (
    currentCrew,
    moveFutureSchedules,
    targetCrewId = ''
  ) => {
    setIsDrawerOpen(false);

    if (selectedRecord) {
      await changeCrew(
        {
          ussId: selectedRecord.ussId.toString(),
          crewId: currentCrew.id,
          body: {
            targetCrewId,
            moveSchedules: moveFutureSchedules,
          },
        },
        {
          onSuccess: async () => {
            queryClient.removeQueries({ queryKey: ['crew-members'] });
            await queryClient.refetchQueries({
              queryKey: ['employee-securities'],
            });
            notify.success({
              message: `You have successfully moved ${
                selectedRecord.firstName + ' ' + selectedRecord.lastName
              }${
                moveFutureSchedules ? ', along with any future schedules.' : '.'
              }`,
            });
          },
        }
      );
    }
  };

  const launchMoveDrawer = (member: OrgSecurityRecord) => {
    setIsDrawerOpen(true);
    setSelectedRecord(member);
  };

  const handleCancelMove = (member: OrgSecurityRecord) => {
    modal.openModal({
      title: 'Cancel Move!',
      type: 'regular',
      onConfirm: () => handleCancel(member),
      onCancel: () => modal.closeModal(),
      labels: {
        cancel: 'No',
        confirm: 'Yes',
      },
      children: <CancelMoveMessage member={member} />,
    });
    return null;
  };

  const handleRemoveMember = (member: OrgSecurityRecord) => {
    modal.openModal({
      title: 'Remove Employee',
      type: 'danger',
      onConfirm: () => handleDelete(member.ussId),
      onCancel: () => handleDeleteModalClose(),
      labels: {
        cancel: 'Cancel',
        confirm: 'Remove',
      },
      children: <RemoveModalMessage member={member} />,
    });
  };
  const handleDelete = async (ussId: number): Promise<void> => {
    const payload = {
      ussId: ussId,
      role: ROLECODES.CREW_MEMBER,
    };
    await deleteCrewAssignment(payload as DeleteCrewMemberWithUssId, {
      onSuccess: async (success) => {
        if (data && data.page > 1 && data.items.length === 1) {
          dispatch({
            type: 'page',
            payload: (data.page - 1).toString(),
          });
        }
        queryClient.removeQueries({ queryKey: ['crew-members'] });
        await queryClient.refetchQueries({
          queryKey: ['employee-securities'],
        });
        notify.success({
          message: success.message,
        });
      },
    });

    modal.closeModal();
    setSelectedRecord(null);
  };

  const handleCancel = async (member: OrgSecurityRecord) => {
    const payload: CancelMemebrMoveRequest = {
      crewId: member.orgId,
      ussId: member.ussId,
    };
    modal.closeModal();
    await cancelMemberMove(payload, {
      onSuccess: async () => {
        queryClient.removeQueries({ queryKey: ['crew-members'] });
        await queryClient.refetchQueries({ queryKey: ['employee-securities'] });
        notify.success({
          message: `You have cancelled the move for ${
            member.firstName + ' ' + member.lastName
          }.`,
        });
      },
    });
  };
  const handleDeleteModalClose = () => {
    modal.closeModal();
    setSelectedRecord(null);
  };

  const RemoveModalMessage = ({ member }: { member: OrgSecurityRecord }) => {
    return (
      <div>
        <p className="text-sm text-gray-700">
          Are you sure you want to remove {member.firstName}&nbsp;
          {member.lastName} from {member.crew}?
        </p>
        <br></br>
        <p className="text-sm text-gray-700">
          This will remove all the future schedules for this employee.
        </p>
        <p className="text-sm text-gray-700">
          Use &apos;Move&apos;option to move this employee to another crew with
          future schedules.
        </p>
      </div>
    );
  };

  const CancelMoveMessage = ({ member }: { member: OrgSecurityRecord }) => {
    return (
      <div>
        <p className="text-sm text-gray-700">
          Are you sure you want to cancel the move for {member.firstName}&nbsp;
          {member.lastName} from {member.crew} to {member.newCrew}?
        </p>
      </div>
    );
  };
  const getTitle = () => {
    if (currentCrew?.status.code === 'archived') return 'Archived Crew!';
    else if (currentCrew?.status.code === 'pending') return 'Pending Crew!';
    else if (currentCrew?.status.code === 'denied') return 'Denied Crew!';
    else return 'No employees assigned';
  };
  const getDescription = () => {
    if (currentCrew?.status.code === 'archived')
      return 'This crew is archived. Please Restore in details section to add employees.';
    else if (currentCrew?.status.code === 'pending')
      return 'This crew is pending for approval. Please approve in details section to add employees.';
    else if (currentCrew?.status.code === 'denied')
      return 'This crew is denied. Please approve in details section to add employees.';
    else
      return 'This crew is active and employees may be assigned to the crew.';
  };
  return (
    <>
      {status === 'pending' && <CardsLoader />}
      {status === 'error' && <Error />}
      {status === 'success' && (
        <div>
          {data.items.length > 0 && (
            <div className="flex items-center justify-end py-4">
              <Button
                className="text-primary-400 font-semibold"
                onClick={(): void => setAddOpen(true)}
                variant="text"
                startIcon={<BsPlus />}
              >
                {window.innerWidth > 768 ? 'Add Employee' : 'Add'}
              </Button>
            </div>
          )}

          <CardList
            items={data.items}
            renderItem={(member) => (
              <MemberCard
                member={member}
                handleRemoveMember={handleRemoveMember}
                handleMoveMember={launchMoveDrawer}
                handleCancelMove={handleCancelMove}
                totalCount={data.totalCount}
              />
            )}
            noResults={{
              title: getTitle(),
              description: getDescription(),
            }}
          />
          {data.items.length === 0 && currentCrew?.status.code === 'active' && (
            <div className=" items-center flex flex-col">
              <Button variant="primary" onClick={(): void => setAddOpen(true)}>
                Add Employee
              </Button>
            </div>
          )}
        </div>
      )}

      {selectedRecord && currentCrew && (
        <MoveEmployee
          isOpen={isDrawerOpen}
          employee={selectedRecord}
          onClose={() => {
            setIsDrawerOpen(false);
          }}
          onMove={handleMove}
          currentCrew={currentCrew}
        />
      )}

      {status === 'success' && (
        <AddEmployee
          crewMemberCount={data.totalCount}
          effectiveDate={effectiveDate}
          open={addOpen}
          setOpen={setAddOpen}
        />
      )}
      {!!data?.totalCount && data.totalCount >= 10 && (
        <div className="py-4">
          <Pagination
            totalCount={data.totalCount}
            page={data.page}
            pageSize={data.pageSize}
            pageOptions={[5, 10, 15, 20, 25, 30]}
            onPageChange={(s) => {
              dispatch({
                type: 'page',
                payload: (s.selected + 1).toString(),
              });
            }}
            onPageSizeChange={(ps) => {
              dispatch({ type: 'pageSize', payload: ps.toString() });
            }}
          />
        </div>
      )}
    </>
  );
};

export default Assignments;
