import { useQuery } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { Paycode } from '../types';

const getPaycodeById = (paycodeId: string): Promise<Paycode> => {
  return axios.get(`/v3/paycodes/${paycodeId}`);
};

export const usePaycodeById = (paycodeId: string) => {
  return useQuery<Paycode, Error>({
    queryKey: [`get-paycodes-${paycodeId}`, paycodeId],
    queryFn: () => getPaycodeById(paycodeId),
    enabled: paycodeId !== '' && paycodeId !== '0',
  });
};
