import { QueryStatus } from '@tanstack/react-query';
import { Org } from 'features/org/types';
import OrgSelect from 'features/paid-time-off/components/Filters/crew-pto/OrgSelect';

import React from 'react';
import OrgFiltersContext, { useOrgFiltersContext } from './OrgFiltersContext';
import useOrgFilters from './use-org-filters';
import clsx from 'clsx';

interface SelectedOrgs {
  selectedPlant?: Org | 'all' | null;
  selectedPayrollLocation?: Org | 'all' | null;
  selectedDivision?: Org | 'all' | null;
  selectedDepartment?: Org | 'all' | null;
}

interface Props {
  orgs: Org[];
  selectedOrgs: SelectedOrgs;
  plants: Org[];
  status: QueryStatus;
  plantStatus: QueryStatus;
  children?: React.ReactNode;
  itemsPerRow?: number;
  crewSort?: 'crew' | 'code';
}

function OrgFilters({
  orgs,
  plants,
  plantStatus,
  status,
  children,
  selectedOrgs,
  itemsPerRow = 3,
  crewSort = 'crew',
}: Props) {
  const { payrollLocations, divisions, departments, crews } = useOrgFilters({
    orgs,
    selectedPlant: selectedOrgs.selectedPlant,
    selectedPayrollLocation: selectedOrgs.selectedPayrollLocation,
    selectedDivision: selectedOrgs.selectedDivision,
    selectedDepartment: selectedOrgs.selectedDepartment,
    crewSort: crewSort,
  });

  return (
    <OrgFiltersContext.Provider
      value={{
        orgs,
        plants,
        plantStatus,
        status,
        payrollLocations,
        divisions,
        departments,
        crews,
      }}
    >
      <div
        className={clsx(
          'grid grid-cols-1 gap-2  sm:gap-x-4 sm:gap-y-3 w-full',
          `sm:grid-cols-${itemsPerRow}`
        )}
      >
        {children}
      </div>
    </OrgFiltersContext.Provider>
  );
}

interface OrgSelectProps {
  selectedItem: Org | 'all' | null;
  handleSelect: ((value: Org | 'all' | null) => void) | ((value: Org) => void);
  showSelectAll?: boolean;
  disabled?: boolean;
  showLabel?: boolean;
}
interface CrewSelectProps extends OrgSelectProps {
  displayCrewKey?: boolean;
}

function PlantSelect({
  selectedItem,
  handleSelect,
  showSelectAll,
  disabled,
  showLabel,
}: OrgSelectProps) {
  const { plantStatus, plants } = useOrgFiltersContext();

  return (
    <OrgSelect
      selectedItem={selectedItem}
      type="plant"
      items={plants}
      handleSelect={handleSelect}
      status={plantStatus}
      showSelectAll={showSelectAll}
      disabled={disabled}
      showLabel={showLabel}
    />
  );
}
function PayrollLocationSelect({
  selectedItem,
  handleSelect,
  showSelectAll,
  disabled,
  showLabel,
}: OrgSelectProps) {
  const { status, payrollLocations } = useOrgFiltersContext();

  return (
    <OrgSelect
      selectedItem={selectedItem}
      type="payroll_location"
      items={payrollLocations}
      handleSelect={handleSelect}
      status={status}
      showSelectAll={showSelectAll}
      disabled={disabled}
      showLabel={showLabel}
    />
  );
}
function DivisionSelect({
  selectedItem,
  handleSelect,
  showSelectAll,
  disabled,
  showLabel,
}: OrgSelectProps) {
  const { divisions, status } = useOrgFiltersContext();

  return (
    <OrgSelect
      selectedItem={selectedItem}
      type="division"
      items={divisions}
      handleSelect={handleSelect}
      status={status}
      showSelectAll={showSelectAll}
      disabled={disabled}
      showLabel={showLabel}
    />
  );
}
function DepartmentSelect({
  selectedItem,
  handleSelect,
  showSelectAll = true,
  disabled,
  showLabel,
}: OrgSelectProps) {
  const { departments, status } = useOrgFiltersContext();

  return (
    <OrgSelect
      selectedItem={selectedItem}
      type="department"
      items={departments}
      handleSelect={handleSelect}
      status={status}
      showSelectAll={showSelectAll}
      disabled={disabled}
      showLabel={showLabel}
    />
  );
}
function CrewSelect({
  selectedItem,
  handleSelect,
  showSelectAll = true,
  disabled,
  showLabel,
  displayCrewKey,
}: CrewSelectProps) {
  const { crews, status } = useOrgFiltersContext();

  return (
    <OrgSelect
      selectedItem={selectedItem}
      type="crew"
      displayCrewKey={displayCrewKey}
      items={crews}
      handleSelect={handleSelect}
      status={status}
      showSelectAll={showSelectAll}
      disabled={disabled}
      showLabel={showLabel}
    />
  );
}

OrgFilters.Plant = PlantSelect;
OrgFilters.Location = PayrollLocationSelect;
OrgFilters.Division = DivisionSelect;
OrgFilters.Department = DepartmentSelect;
OrgFilters.Crew = CrewSelect;

export default OrgFilters;
