import { axios } from 'services/api/axios';
import { useQuery } from '@tanstack/react-query';
import {
  PolicySummaryOptions,
  EmployeePolicySummary,
} from 'features/timecards/types';
// Policy Summary
/**
 * @param ussId ussId of employee.
 * @param week week for which details are required.
 * @link https://cappsq6.psc.uss.com:8345/SBWM-DOCUMENT/swagger-ui/index.html?urls.primaryName=sbwm-schedulepay#/policy-summary-controller/getEmpPolicySummaryForWeek
 */

export const usePolicySummary = (
  ussId: string,
  params: PolicySummaryOptions
) => {
  return useQuery({
    queryFn: () => employee_policy_summary(ussId, params),
    queryKey: ['policy-summary', { ussId, ...params }],
    enabled: !!ussId && !!params.week,
  });
};

const employee_policy_summary = (
  ussId: string,
  params: PolicySummaryOptions
): Promise<EmployeePolicySummary> => {
  return axios.get(`/v3/people/${ussId}/policy-summary`, {
    params,
  });
};
