import { SearchInput } from '@uss/react-components';
import Sort from 'components/Sort/Sort';
import { useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import { SortDirection } from 'types/common';
import {
  OrgFilterStatus,
  OrgsOptions,
  OrgsSortByType,
  ORG_TYPE,
  OrgTypePost,
  StatusType,
} from 'features/org';
import { OrgFilterAction } from '../context/OrgProvider';
import { StatusSelect } from './StatusSelect';

interface OrgToolsProps {
  params: OrgsOptions;
  dispatch: React.Dispatch<OrgFilterAction>;
  parentOrgId?: string | null | undefined;
  type: OrgTypePost;
  sortOptions: SortOptionsType[];
  selectedSortOption?: SortOptionsType;
  code?: StatusType;
}

export interface SortOptionsType {
  label: string;
  name: OrgsSortByType;
  direction: SortDirection;
}
export const OrgTools = ({
  params,
  dispatch,
  parentOrgId,
  type,
  sortOptions,
  code,
}: OrgToolsProps) => {
  const [searchValue, setSearchValue] = useState<string>(
    params.search ? params.search : ''
  );

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    dispatch({ type: 'search', payload: value });
  };
  const handleClose = () => {
    setSearchValue('');
    dispatch({ type: 'clearSearch' });
  };

  const handleStatusSelect = (value: OrgFilterStatus) => {
    dispatch({ type: 'status', payload: value });
  };

  const addButtonLabel =
    type === ORG_TYPE.DIVISION ? 'Add Division' : 'Add Department';
  return (
    <div className="flex flex-row justify-between flex-wrap lg:flex-nowrap">
      <div className="flex flex-row w-full lg:w-auto flex-wrap lg:flex-nowrap mb-4 lg:mb-0">
        <div className="w-full lg:w-56 mb-4 lg:mb-0">
          <SearchInput
            id="search-input"
            label="search"
            placeholder="Search"
            value={searchValue}
            autoCompleteFlag={searchValue ? true : false}
            selectedFlag={searchValue ? true : false}
            onChange={(e) => {
              handleSearchChange(e.currentTarget.value);
            }}
            onClose={handleClose}
          />
        </div>
        <div className="ml-0 lg:ml-2 w-full lg:w-32">
          <StatusSelect
            type={type}
            status={params.status}
            onSelect={handleStatusSelect}
          />
        </div>
      </div>
      <div className="flex flex-row justify-end lg:justify-end  w-full lg:w-auto flex-wrap lg:flex-nowrap">
        <div className="flex flex-row w-full lg:w-48 mb-4 lg:mb-0">
          <Sort<OrgsSortByType>
            options={sortOptions}
            selectedOption={
              sortOptions.filter(
                (value) =>
                  params.sortBy === value.name &&
                  params.sortType === value.direction
              )[0]
            }
            onChange={(value) =>
              dispatch({
                type: 'sort',
                payload: {
                  name: value.name,
                  direction: value.direction,
                },
              })
            }
          />
        </div>
        {(type === ORG_TYPE.DIVISION ||
          (type === ORG_TYPE.DEPARTMENT && code && code !== 'archived')) && (
          <div className="flex justify-end" data-testid="add-button">
            <NavLink
              className="flex flex-row justify-center items-center"
              to={`/admin/org-structure/create/${
                parentOrgId ?? ''
              }/${type.toLocaleLowerCase()}`}
            >
              <div className="text-primary-400 font-semibold flex items-center mx-0 lg:mx-2">
                <BsPlus size="2rem" />
                <span>{window.innerWidth > 768 ? addButtonLabel : 'Add'}</span>
              </div>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
};
