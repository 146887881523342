/**
 * Deletes an existing adjustment for a timecard
 *
 * @param ussId USS ID of the employee
 * @param id ID of the adjustment
 * @returns React Query useMutation Result
 */

import useNotification from 'components/Notification/useNotification';
import { useMutation } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { queryClient } from 'services/api/react-query';

/**
 * Endpoint to delete an adjustment
 *
 * @param ussId USS ID for the person
 * @param timecardId ID of the requested timecard
 * @returns Promise
 */
const delete_adjustment = (
  ussId: string,
  timecardId: string
): Promise<{
  success: boolean;
  message: 'string';
}> => {
  return axios.delete(`/v1/people/${ussId}/timecard-details/${timecardId}`);
};

export const useDeleteAdjustment = (
  ussId: string,
  timecardId: string,
  currentAdjustment: number,
  setAdjustment: (value: number | null) => void
) => {
  const notify = useNotification();

  return useMutation({
    mutationFn: () => delete_adjustment(ussId, timecardId),
    onSuccess: async (success) => {
      notify.success({
        message: success.message,
      });
      queryClient.removeQueries({ queryKey: ['employee-timecard'] });
      setAdjustment(currentAdjustment - 1);
      await queryClient.refetchQueries({
        queryKey: ['pto-summary'],
        type: 'active',
      });
    },
  });
};
