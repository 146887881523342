import Alert from 'components/Alert/Alert';
import { useEmployeePtoSummary } from 'features/paid-time-off/api/queries';
import { useMyPtoStore } from 'features/paid-time-off/state';
import { getSellBackPtoMsg } from 'features/paid-time-off/utility/getSellBackPtoMsg';
import { PLANT_TYPE } from 'features/paycode';
import { BsCoin } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';

interface SellBackPTOViewProps {
  plantType:string|undefined;
}
export const SellBackPTOView = ({plantType}:SellBackPTOViewProps) => {
  const { parsedDate, selectedYear } = useMyPtoStore((state) => ({
    parsedDate: state.parsedDate(),
    selectedYear: state.selectedYear,
  }));

  //annual totel data
  const { data: PTOSummary, status: PTOSummaryStatus } = useEmployeePtoSummary(
    'me',
    {
      year: selectedYear,
    }
  );
  //check current selected year/month and parse it to date
  const sellBackActions = getSellBackPtoMsg(
    PTOSummary?.sellBackStart,
    PTOSummary?.sellBackEnd,
    parsedDate
  );
 
  return (
    <div className="flex flex-wrap flex-grow md:flex-nowrap items-center w-full md:w-3/4 gap-4 md:gap-0">
      {PTOSummaryStatus === 'success' &&
        PTOSummary.sellBackEligible &&
        plantType === PLANT_TYPE.MINI_MILL && (
          <>
            <div className="w-full md:flex-grow">
              {sellBackActions.showSellBackPTOMsg && (
                <div data-testid="sellback-pto-message">
                  <Alert variant="info">{sellBackActions.sellBackMsg}</Alert>
                </div>
              )}
            </div>

            {sellBackActions.showSellBackPTOLink && (
              <div
                className="w-full md:w-1/4 flex md:justify-end"
                data-testid="sellback-pto-link"
              >
                <NavLink
                  className="flex flex-row justify-center items-center text-primary-400 font-semibold"
                  to={`sell-back-pto`}
                >
                  <BsCoin size="1.5rem" />
                  <span className="ml-2">
                    {sellBackActions.sellBackLinkText}
                  </span>
                </NavLink>
              </div>
            )}
          </>
        )}
    </div>
  );
};
