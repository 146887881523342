import { CalendarView } from 'components/CalendarV2/types';
import { SortDirection } from 'types/common';
import { PtoDetailsView, PtoStatus } from '../types';
import { appNewDate } from 'utils/appNewDate';
import { addYears, format, parse, parseISO, subYears } from 'date-fns';
import { StateCreator, create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { PtoRecordsOptions, SortByType } from '../types';

interface MyPtoState {
  params: PtoRecordsOptions;
  ptoDetailsView: PtoDetailsView;
  calendarType: CalendarView;
  selectedYear: string;
  selectedDate: string;
  sellBackStart: string | null;
  sellBackEnd: string | null;
  setCalenderSelection: (value: Date) => void;
  parsedDate: () => Date;
  startDate: () => Date;
  setPage: (value: string) => void;
  setPageSize: (value: string) => void;
  setYear: (value: string) => void;
  setYearMonth: (value: string) => void;
  setStatus: (value: PtoStatus | 'all') => void;
  setSortBy: (value: SortByType) => void;
  setSortType: (value: SortDirection) => void;
  setSort: (val: { type: SortByType; direction: SortDirection }) => void;
  setCalendarType: (value: CalendarView) => void;
  setPtoDetailsView: (value: PtoDetailsView) => void;
  onSelectDate: (value: Date) => void;
  toggleYearMonth: (value: 'year' | 'month') => void;
  setSellBackStart: (value: string) => void;
  setSellBackEnd: (value: string) => void;
}
const createMyPtoState: StateCreator<
  MyPtoState,
  [['zustand/immer', never], ['zustand/devtools', never]],
  [],
  MyPtoState
> = (set, get) => ({
  params: {
    page: '1',
    pageSize: '40',
    sortBy: 'shiftDate',
    sortType: SortDirection.ASC,
    year: format(appNewDate(), 'yyyy'),
    chargeYear: format(appNewDate(), 'yyyy'),
  },
  selectedYear: format(appNewDate(), 'yyyy'),
  selectedDate: format(appNewDate(), 'yyyy-MM-dd'),
  ptoDetailsView: 'grid',
  calendarType: 'year',
  sellBackStart: null,
  sellBackEnd: null,
  setCalenderSelection: (value: Date) =>
    set(
      (state) => {
        if (state.calendarType === 'year') {
          state.calendarType = 'month';
          state.params.month = format(value, 'MM');
          state.params.year = format(value, 'yyyy');
        } else if (window.innerWidth <= 768) {
          state.selectedDate = format(value, 'yyyy-MM-dd');
          state.params.day = format(value, 'dd');
          state.params.month = format(value, 'MM');
        }
      },
      false,
      'setSelectedDate'
    ),
  startDate: () => {
    const _year = get().params.year;
    const _month = get().params.month;
    if (get().calendarType === 'year' && _year) {
      return parseISO(_year);
    } else if (get().calendarType === 'month' && _month && _year) {
      return parseISO(`${_year}-${_month}`);
    } else return new Date();
  },
  parsedDate: () => {
    const _year = get().params.year ?? '';
    const _month = get().params.month;

    if (get().calendarType === 'month' && _month) {
      return parse(`${_year}-${_month}`, 'yyyy-MM', appNewDate());
    } else {
      return parse(_year, 'yyyy', appNewDate());
    }
  },
  setPage: (value) =>
    set(
      (state) => {
        state.params.page = value;
      },
      false,
      'setPage'
    ),
  setPageSize: (value: string) =>
    set(
      (state) => {
        state.params.pageSize = value;
        state.params.page = '1';
      },
      false,
      'setPageSize'
    ),
  setSortBy: (value: SortByType) =>
    set(
      (state) => {
        state.params.sortBy = value;
      },
      false,
      'setSortBy'
    ),
  setSortType: (value: SortDirection) =>
    set(
      (state) => {
        state.params.sortType = value;
      },
      false,
      'setSortType'
    ),
  setSort: (value) =>
    set(
      (state) => {
        state.params.sortBy = value.type;
        state.params.sortType = value.direction;
      },
      false,
      'set-sort'
    ),
  setYear: (value: string) =>
    set(
      (state) => {
        delete state.params.day;
        delete state.params.month;
        state.params.year = value;
        state.params.chargeYear = value;
        state.selectedYear = value;
      },
      false,
      'setYear'
    ),
  setYearMonth: (value: string) =>
    set(
      (state) => {
        delete state.params.day;
        delete state.params.chargeYear;
        state.params.month = format(parseISO(value), 'MM');
        state.params.year = format(parseISO(value), 'yyyy');
        state.selectedYear = format(parseISO(value), 'yyyy');
      },
      false,
      'setYearMonth'
    ),
  setStatus: (value: PtoStatus | 'all') =>
    set(
      (state) => {
        if (value === 'all') {
          delete state.params.status;
        } else {
          state.params.status = value;
        }
      },
      false,
      'setStatus'
    ),
  setCalendarType: (value: CalendarView) =>
    set(
      (state) => {
        state.calendarType = value;
        if (value === 'month') {
          state.selectedDate = format(appNewDate(), 'yyyy-MM-dd');
        }
      },
      false,
      'setCalendarType'
    ),
  setPtoDetailsView: (value: PtoDetailsView) =>
    set(
      (state) => {
        state.ptoDetailsView = value;
        if (value === 'calendar') {
          state.selectedDate = format(appNewDate(), 'yyyy-MM-dd');
        }
      },
      false,
      'setPtoDetailsView'
    ),
  setSellBackStart: (value: string) =>
    set(
      (state) => {
        state.sellBackStart = value;
      },
      false,
      'setSellBackStart'
    ),
  setSellBackEnd: (value: string) =>
    set(
      (state) => {
        state.sellBackEnd = value;
      },
      false,
      'setSellBackEnd'
    ),
  onSelectDate: (value: Date) =>
    set(
      (state: MyPtoState) => {
        if (
          value.getFullYear() >= subYears(new Date(), 1).getFullYear() &&
          value.getFullYear() <= addYears(new Date(), 1).getFullYear()
        ) {
          delete state.params.day;
          state.params.year = format(value, 'yyyy');

          if (get().calendarType === 'month') {
            state.params.month = format(value, 'MM');
            delete state.params.chargeYear;
          }
        }
        return state;
      },
      false,
      'onSelectDate'
    ),
  toggleYearMonth: (value: 'year' | 'month') =>
    set(
      (state) => {
        delete state.params.day;
        delete state.params.month;

        state.calendarType = value;
        const _year = get().params.year;
        const _month = get().params.month;

        state.params.year = format(
          parseISO(_year ?? appNewDate().getFullYear().toString()),
          'yyyy'
        );
        state.params.chargeYear = format(
          parseISO(_year ?? appNewDate().getFullYear().toString()),
          'yyyy'
        );
        if (value === 'month') {
          delete state.params.chargeYear;
          state.params.month = _month
            ? format(parseISO(_month), 'MM')
            : format(appNewDate(), 'MM');
          state.selectedDate = format(appNewDate(), 'yyyy-MM-dd');
        }
      },
      false,
      'toggleYearMonth'
    ),
});

export const useMyPtoStore = create<MyPtoState>()(
  devtools(
    immer((...a) => ({
      ...createMyPtoState(...a),
    }))
  )
);

export const useEmployeePtoStore = create<MyPtoState>()(
  devtools(
    immer((...a) => ({
      ...createMyPtoState(...a),
    }))
  )
);
