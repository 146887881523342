import { PERSON_STATUS, PersonStatus } from 'features/people/types';

const getFormattedPersonStatus = (personStatus: PersonStatus) => {
  switch (personStatus) {
    case PERSON_STATUS.ACTIVE:
      return 'Active';
    case PERSON_STATUS.INACTIVE:
      return 'Inactive';
    case PERSON_STATUS.TERMINATEASSIGNMENT:
      return 'Terminated-Assignment';
    default:
      return '-';
  }
};
export default getFormattedPersonStatus;
