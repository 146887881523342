import { ModelSchedTurns } from '../types';

export interface WeekFormatProps {
  data: ModelSchedTurns[];
  weekBeginIndex: number;
}
export const getWeekFormat = ({ data, weekBeginIndex }: WeekFormatProps) => {
  if (weekBeginIndex === 0) {
    const newData = data;

    const newArray = newData.sort((a, b) => a.dayIndex - b.dayIndex);
    return newArray;
  } else {
    const newData = data;

    newData.sort((a, b) => a.dayIndex - b.dayIndex);
    const lastElement = newData.pop();
    return [lastElement, ...newData];
  }
};
