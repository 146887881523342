import { useQuery } from '@tanstack/react-query';
import {
  blackoutPto,
  crewPto,
  employeePto,
  getPTOHistory,
  ptoRecord,
  ptoRequests,
  sellBack,
} from './api';
import { GetCrewParamsBody, GetOrgDetailsParamsBody } from '../types/crew-pto';
import { PtoBlackoutOptions, PtoRecordsOptions } from '../types';
import { PtoSummaryOptions } from '../types/employee-pto';
import { SellBackPtoOptions } from '../types/sellback';
import { PtoRequestsOptions } from '../types/pto-requests';
import { PTOHistoryParams } from '../types/pto-history';

// *** Employee PTO *****

export const useEmployeePtoDetails = (
  ussId: string,
  options?: PtoRecordsOptions
) => {
  return useQuery({
    queryFn: () => employeePto.getAll(ussId, options),
    queryKey: ['pto-details', { ussId, ...options }],
  });
};

export const useEmployeeRemovedPTO = (
  ussId: string,
  options?: Omit<PtoRecordsOptions, 'status'>
) => {
  return useQuery({
    queryFn: () => employeePto.getAll(ussId, { ...options, status: 'removed' }),
    queryKey: ['pto-details', { ussId, ...options, status: 'removed' }],
  });
};

export const useEmployeePtoSummary = (
  ussId: string,
  options?: PtoSummaryOptions
) => {
  return useQuery({
    queryFn: () => employeePto.getSummary(ussId, options),
    queryKey: ['pto-summary', { ussId, ...options }],
  });
};

/**
 * Hook that Fetches PTO details for a particular PTO Id, https://cappsq6.psc.uss.com:8345/SBWM-DOCUMENT/swagger-ui/index.html?urls.primaryName=sbwm-schedulepay#/pto-controller/getPtoById
 * @param ptoId
 * @returns Data for particular PTO
 */
export const useEmpPTODetailsByPtoId = (ptoId: string) => {
  return useQuery({
    queryFn: () => ptoRecord.get(ptoId),
    queryKey: [`pto-id`, { ptoId }],
    enabled: ptoId !== '',
  });
};

// **************************

// *** CREW PTO ******

export const useGetCrewPtoByFilter = (data: GetOrgDetailsParamsBody) => {
  return useQuery({
    queryFn: () => crewPto.getOrgDetails(data.body, data.params),
    queryKey: ['crew-pto-details', { ...data.body, ...data.params }],
    refetchOnWindowFocus: false,
    enabled:
      data.body.ids.length > 0 && data.params.date !== '' && !!data.params.type,
  });
};

export const useGetCrewPtoTotalsByFilter = (data: GetCrewParamsBody) => {
  return useQuery({
    queryFn: () => crewPto.getPtoDetails(data.body, data.params),
    queryKey: ['crew-pto-totals', { ...data.body, ...data.params }],
    enabled: data.body.ids.length > 0 && !!data.params.type,
  });
};

// *********************

// **  Sell Back ************

export const useSellBackPtoList = (
  ussId: string,
  options?: SellBackPtoOptions
) => {
  return useQuery({
    queryFn: () => sellBack.get(ussId, options),
    queryKey: ['sell-back-pto', { ...options }],
  });
};

// *** Blackout PTO *****

export const useBlackoutPto = (ussId: string, options?: PtoBlackoutOptions) => {
  return useQuery({
    queryFn: () => blackoutPto.getPtoBlackout(ussId, options),
    queryKey: ['pto-blackout', { ussId, ...options }],
  });
};

// ******************

// **************************

// *** PTO Requests******

export const useGetPtoRequests = (
  orgId: string,
  options?: PtoRequestsOptions
) => {
  return useQuery({
    queryFn: () => ptoRequests.getPtoRequests(orgId, options),
    queryKey: ['pto-requests', { orgId, ...options }],
    refetchOnWindowFocus: false,
    enabled: orgId !== '' && options?.date !== '',
  });
};

// *********************

//*** PTO History ***/
/**
 * Hook that Fetches PTO history for a particular PTO Id,
 * @param ptoId
 * @returns Data for particular PTO history
 */

export const usePTOHistory = (ptoId: string, options: PTOHistoryParams) => {
  return useQuery({
    queryFn: () => getPTOHistory(ptoId, options),
    queryKey: [`pto-history`],
  });
};

// *********************
