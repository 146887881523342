import { Button, Modal, TextArea } from '@uss/react-components';
import useNotification from 'components/Notification/useNotification';
import { CrewButtonRef } from 'features/crew-maintenance/constants';
import { useAddCrewStore } from 'features/crew-maintenance/state/addcrewStore';
import {
  getSuccessMessage,
  getModalText,
} from 'features/crew-maintenance/utilities/getCrewText';
import { Org, OrgPost, OrgPut, ORG_STATUS_CODE, ORG_TYPE } from 'features/org';
import { useAddOrg, useUpdateOrg, useUpdateOrgStatus } from 'features/org/api';
import { ROLES } from 'features/roles';
import useUserRoles from 'hooks/useUserRoles';
import { useState } from 'react';

interface ConfirmationModalProps {
  crew?: Org | null;
  buttonRef: CrewButtonRef;
  handleCancel: () => void;
  closeModal: () => void;
}

export const ConfirmationModal = ({
  crew,
  buttonRef,
  handleCancel,
  closeModal,
}: ConfirmationModalProps) => {
  const store = useAddCrewStore();
  const notify = useNotification();
  const { roles } = useUserRoles();

  const { mutateAsync: addOrgRecord } = useAddOrg();
  //update org api hook
  const { mutateAsync: editOrgRecord } = useUpdateOrg(
    typeof crew !== 'undefined' && crew ? crew.id : ''
  );
  const { mutateAsync: editOrgStatus } = useUpdateOrgStatus(
    typeof crew !== 'undefined' && crew ? crew.id : '',
    ORG_STATUS_CODE.ARCHIVED,
    ORG_TYPE.CREW
  );

  const [comment, setComment] = useState('');
  const [enableValidator, setEnableValidator] = useState<boolean>(false);

  const handleChangeComment = (text: string) => {
    setEnableValidator(true);
    setComment(text);
    store.setComments(text);
  };
  const getStatusCode = () => {
    if (buttonRef === 'Archive') return ORG_STATUS_CODE.ARCHIVED;
    else if (buttonRef === 'Deny') return ORG_STATUS_CODE.DENIED;
    else return ORG_STATUS_CODE.ACTIVE;
  };
  const addNewCrew = async () => {
    const payload = {
      parentOrgId: store.selectedDepartment
        ? store.selectedDepartment.id
        : crew?.parentOrgId,
      name: store.crew ? store.crew : crew?.crew,
      description: store.crewDescription
        ? store.crewDescription
        : crew?.crewDescription,
      type: ORG_TYPE.CREW,
      comments: comment,
      sortSequence: '',
      safetyMeetingWeekOfMonth: '',
      safetyMeetingDayOfWeek: '',
      safetyMeetingTurnOnTheDay: '',
      safetyMeetingLengthMinutes: '',
      safetyMeetingBeforeOrAfterShift: '',
      safetyMeetingIncentiveEligible: '',
    };
    if (!crew) {
      await addOrgRecord(payload as OrgPost, {
        onSuccess: () => {
          notify.success({
            message: `${
              buttonRef === 'Request'
                ? 'Your request for a new crew has been submitted.'
                : 'You have successfully added new crew.'
            }`,
          });
          handleCancel();
        },
      });
    } else if (buttonRef === 'Restore' || buttonRef === 'Deny') {
      const payload = {
        status: {
          code: getStatusCode(),
        },
        comments: comment,
      };
      await editOrgStatus(payload, {
        onSuccess: () => {
          notify.success({
            message: getSuccessMessage(buttonRef),
          });
        },
      });
    } else if (crew.id) {
      const payloadData = roles.includes(ROLES.PAY_APPROVER)
        ? payload
        : {
            ...payload,
            status: {
              code: getStatusCode(),
            },
          };
      await editOrgRecord(payloadData as OrgPut, {
        onSuccess: () => {
          notify.success({
            message: getSuccessMessage(buttonRef),
          });
          handleCancel();
        },
      });
    }
  };

  const handleSubmit = async () => {
    setEnableValidator(true);
    if (comment.length > 0 || buttonRef === 'Approve') {
      setEnableValidator(false);
      await addNewCrew();
      closeModal();
    }
  };

  return (
    <>
      <div className="text-sm text-gray-700">
        {getModalText(buttonRef, crew?.status.code)}
      </div>
      <div className="col-span-2 mb-4 lg:mb-0">
        <TextArea
          maxLength={100}
          label=""
          id="form-comment"
          name="comment"
          placeholder="Describe reason"
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            handleChangeComment(e.target.value);
          }}
          value={comment}
          error={enableValidator && comment.length <= 0}
          errorMessage={'Please enter comment'}
        />
        <div className="col-span-2 text-xs my-2 text-gray-600 text-right">
          {100 - comment.length} Characters left
        </div>
      </div>

      <Modal.Actions>
        <Button
          onClick={closeModal}
          variant="outline"
          className="w-full sm:w-auto"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            void handleSubmit();
          }}
          variant="primary"
          className="ml-0 sm:ml-4 w-full sm:w-auto mt-3 sm:mt-0"
          type="submit"
        >
          {buttonRef}
        </Button>
      </Modal.Actions>
    </>
  );
};
