import { useState } from 'react';
import { RadioComponent } from './RadioComponent';
import {
  Actions,
  AreaManagerRetainModalOptions,
  ReviewActionLabel,
} from 'features/probationary-tracking/types/employeeReviewDetails';
import { Button, Modal } from '@uss/react-components';
import { ReviewActionModalProps } from 'features/probationary-tracking/utilities/getReviewActionModalProps';
import getCurrentView from 'features/probationary-tracking/utilities/getCurrentView';
import { getActionParamForReqBody } from 'features/probationary-tracking/utilities/getActionParamForReqBody';

interface Props {
  btnLabel: ReviewActionLabel;
  handleCancel: () => void;
  handleUpdate: () => void;
  actionRef: React.MutableRefObject<Actions>;
  pathname: string;
  reviewNumber: number;
  reviewActionModalProps: ReviewActionModalProps;
}

export const ConfirmationModal = ({
  btnLabel,
  handleCancel,
  handleUpdate,
  actionRef,
  pathname,
  reviewNumber,
  reviewActionModalProps,
}: Props) => {
  const { isAreaManagerView, isHrLrView, isReviewerView, isEmployeeView } =
    getCurrentView(pathname);

  const { cancelButtonLabel, confirmButtonLabel, modalBodyText } =
    reviewActionModalProps;

  const HrLrRetainModalOptions = {
    UNTIL_NEXT_REVIEW: `Review #${reviewNumber} complete until next performance period`,
    COUNSELLING: 'Employee counseling needed',
  };

  const modalOptions = isAreaManagerView
    ? Object.values(AreaManagerRetainModalOptions)
    : isHrLrView
    ? Object.values(HrLrRetainModalOptions)
    : [];

  const [selectedRetentionOption, setSelectedRetentionOption] = useState<
    AreaManagerRetainModalOptions | string
  >(
    isAreaManagerView
      ? AreaManagerRetainModalOptions.RE_EVALUATE
      : HrLrRetainModalOptions.UNTIL_NEXT_REVIEW
  );

  const handleChange = (value: string) => {
    if (isAreaManagerView) {
      setSelectedRetentionOption(
        value === 'Performance needs to be Re-evaluated'
          ? AreaManagerRetainModalOptions.RE_EVALUATE
          : AreaManagerRetainModalOptions.COUNSELLING
      );
    } else {
      setSelectedRetentionOption(value);
    }
  };

  const handleSubmit = () => {
    const finalAction = getActionParamForReqBody(
      btnLabel,
      isAreaManagerView,
      isReviewerView,
      isHrLrView,
      isEmployeeView,
      HrLrRetainModalOptions,
      selectedRetentionOption
    );
    actionRef.current = finalAction;
    handleUpdate();
  };

  return (
    <>
      {btnLabel === ReviewActionLabel.RETAIN &&
      (isAreaManagerView || isHrLrView) ? (
        <>
          <p className="text-sm text-gray-700">
            Select any one of the options to continue employment
          </p>
          <div className="my-4">
            <RadioComponent
              selected={selectedRetentionOption}
              radioOptions={modalOptions}
              direction={'col'}
              handleChange={handleChange}
              label={'retention-modal-option'}
            />
          </div>
        </>
      ) : (
        <p className="text-sm text-gray-700">{modalBodyText}</p>
      )}

      <Modal.Actions>
        <Button
          onClick={handleCancel}
          variant="outline"
          className="w-full sm:w-auto text-base"
          data-testid="modal-cancel"
        >
          {cancelButtonLabel}
        </Button>
        <Button
          onClick={handleSubmit}
          variant="primary"
          className="ml-0 sm:ml-4 w-full sm:w-auto mt-3 sm:mt-0 text-base"
          type="submit"
          data-testid="modal-confirm"
        >
          {confirmButtonLabel}
        </Button>
      </Modal.Actions>
    </>
  );
};
