import { useProbationaryEmployeesSummary } from 'features/probationary-tracking/api/getProbEmployeesSummary';
import EmployeesSummary from '../common/EmployeesSummary/EmployeesSummary';
import useWindowSize from 'hooks/useWindowSize';

const ProbationaryEmployeesSummary = () => {
  const { data, status } = useProbationaryEmployeesSummary();
  const { width } = useWindowSize();

  return (
    <EmployeesSummary data={data?.hr_lr} status={status}>
      <EmployeesSummary.TotalEmployees width="w-2/5 sm:w-1/4" />
      <EmployeesSummary.LowRating
        width="w-3/5 sm:w-1/4"
        borderRight={!!width && width > 640 ? true : false}
        customLabel={
          !!width && width > 640
            ? 'Employees with low rating'
            : 'Emp. with low rating'
        }
      />
      <EmployeesSummary.ReviewPerformance
        width="sm:w-1/4 pl-0 sm:pl-4"
        paddingLeft={false}
      />
    </EmployeesSummary>
  );
};

export default ProbationaryEmployeesSummary;
