import {
  endOfWeek,
  isValid,
  isWithinInterval,
  parseISO,
  startOfWeek,
} from 'date-fns';

export default function checkCrossOverWeek(
  startDate: string,
  endDate?: string | null | undefined
): boolean {
  if (isValid(new Date(startDate))) {
    const startofWeek = startOfWeek(new Date(startDate), {
      weekStartsOn: 0,
    });
    const lastDayOfYear = new Date(startofWeek.getFullYear(), 11, 31, 20, 0, 0);
    const _crossOverWeek = {
      start: startOfWeek(new Date(lastDayOfYear), {
        weekStartsOn: 0,
      }),
      end: endOfWeek(lastDayOfYear, {
        weekStartsOn: 0,
      }),
    };

    // check is _crossOverWeekStart and _crossOverWeekEnd not in same year
    if (
      _crossOverWeek.start.getFullYear() === _crossOverWeek.end.getFullYear()
    ) {
      return false;
    } else {
      // check is selected range start and end date are in crossover week
      const isStartedWithinCrossOverWeek = isWithinInterval(
        parseISO(startDate),
        {
          start: _crossOverWeek.start,
          end: _crossOverWeek.end,
        }
      );
      if (!endDate) {
        return isStartedWithinCrossOverWeek;
      } else {
        if (isValid(new Date(endDate))) {
          const isEndedWithinCrossOverWeek = isWithinInterval(
            parseISO(endDate),
            {
              start: _crossOverWeek.start,
              end: _crossOverWeek.end,
            }
          );

          if (isStartedWithinCrossOverWeek && isEndedWithinCrossOverWeek) {
            return true;
          }
        }
      }
    }
  }
  return false;
}
