import { Accordion } from '@uss/react-components';
import useWindowSize from 'hooks/useWindowSize';

import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';
import { Org } from 'features/org';
import Badge from 'components/Badge/Badge';
import { getOrgStatusColor } from 'features/org/utilities/getOrgStatusColor';
import { getOrgStatusText } from 'features/org/utilities/getOrgStatusText';

export const Detailscard = ({ crewData }: { crewData: Org }) => {
  const { width } = useWindowSize();
  const DetailsHeader = () => {
    return (
      <div className="flex items-center relative h-full w-full lg:w-1/4 2xl:w-1/4 lg:pr-8  lg:after:w-0.5 lg:after:absolute lg:after:right-0 lg:after:h-full lg:after:bg-gray-200">
        <div className="flex justify-between mr-4 flex-grow sm:items-center">
          <div className="ml-0 sm:ml-4">
            <>
              <Title as="h2">{crewData.crew ? crewData.crew : '-'}</Title>

              <p
                aria-label="Location Type"
                className="text-gray-700 text-xs text-left line-clamp-1"
              >
                {crewData.locationType ? crewData.locationType : '-'}
              </p>
            </>
          </div>
          <div data-testid="badge">
            <Badge variant={getOrgStatusColor(crewData.status.code)}>
              <span>{getOrgStatusText(crewData.status.code)}</span>
            </Badge>
          </div>
        </div>
      </div>
    );
  };
  const DetailsBody = () => {
    return (
      <>
        <div className="flex flex-wrap sm:flex-nowrap w-full lg:w-4/5 2xl:w-3/4 lg:pl-8">
          <LabeledText
            className="w-1/2 sm:w-auto sm:flex-grow mb-4 sm:mb-0"
            label="Location"
            lineClamp={1}
          >
            {crewData.location}
          </LabeledText>
          <LabeledText
            className="w-1/2 sm:w-auto sm:flex-grow mb-4 sm:mb-0"
            label="Division"
          >
            {crewData.division}
          </LabeledText>
          <LabeledText
            className="w-1/2 sm:w-auto sm:flex-grow"
            label="Department"
            lineClamp={1}
          >
            {crewData.department}
          </LabeledText>
          <LabeledText
            className="w-1/2 sm:w-auto sm:flex-grow"
            label="Description"
            lineClamp={1}
          >
            {crewData.description}
          </LabeledText>
        </div>
      </>
    );
  };
  return (
    <>
      {width &&
        (width < 1024 ? (
          <Accordion
            variant="default"
            className="shadow-md bg-white rounded-lg border border-blue-600"
          >
            <Accordion.Button variant="default">
              <DetailsHeader />
            </Accordion.Button>
            <Accordion.Panel variant="default">
              <div className="flex flex-row flex-wrap w-full pt-4 items-center border-t border-t-gray-200">
                <DetailsBody />
              </div>
            </Accordion.Panel>
          </Accordion>
        ) : (
          <div className="flex items-center shadow-md w-full p-4 bg-white rounded-lg h-24 border border-blue-600">
            <DetailsHeader />
            <DetailsBody />
          </div>
        ))}
    </>
  );
};
