import Page from 'components/Layouts/Page';
import { OrgInfoCard, ORG_TYPE } from 'features/org';
import { useOrgDetails } from 'features/org/api';
import { NavLink, useParams } from 'react-router-dom';
import { DivisionDetails } from 'features/org/components';

const AddDivisionCode = () => {
  const { parentOrgId = '' } = useParams<'parentOrgId'>();

  const { data, status } = useOrgDetails(parentOrgId);
  return (
    <Page
      breadcrumbs={[
        <NavLink key="bc-1" to="/admin">
          Admin
        </NavLink>,
        <NavLink key="bc-2" to="/admin/org-structure/divisions">
          Org Structure Divisions
        </NavLink>,
      ]}
      heading={'Add Division Code'}
    >
      <Page.Section>
        {status === 'success' && (
          <div>
            <OrgInfoCard type={ORG_TYPE.DIVISION} data={data} />
          </div>
        )}
      </Page.Section>
      <Page.Section>
        <DivisionDetails />
      </Page.Section>
    </Page>
  );
};

export default AddDivisionCode;
