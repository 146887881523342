import {
  PerformanceCriteriaType,
  ReviewPerformanceDetail,
} from 'features/probationary-tracking/types/employeeReviewDetails';
import PerformanceReviewSelect from './PerformanceReviewSelect';
import { useEffect } from 'react';
import { ZodErrorType } from 'types/common';
import _ from 'lodash';
import { getPerformanceFormLabel } from 'features/probationary-tracking/utilities/getPerformanceFormLabel';

interface Props {
  performance: ReviewPerformanceDetail;
  readOnly: boolean;
  errors: ZodErrorType;
  performanceObj: ReviewPerformanceDetail;
  setPerformanceObj: React.Dispatch<
    React.SetStateAction<ReviewPerformanceDetail>
  >;
}

export const PerformanceSection = ({
  performance,
  readOnly,
  errors,
  performanceObj,
  setPerformanceObj,
}: Props) => {
  const handleTypeChange = (
    value: PerformanceCriteriaType,
    type: string
  ): void => {
    setPerformanceObj({ ...performanceObj, [type]: value });
  };

  useEffect(() => {
    if (Object.keys(performance).length > 0) {
      setPerformanceObj(performance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performance]);

  const ValidationMsg = ({ msg, name }: { msg: string; name: string }) => {
    return (
      <p className="text-sm text-red-600" data-testid={`validation-` + name}>
        {msg}
      </p>
    );
  };

  return (
    <div className="lg:grid lg:grid-cols-2 gap-x-10 gap-y-6 w-full">
      {(Object.keys(performanceObj) as (keyof typeof performanceObj)[]).map(
        (key, index) => {
          const label = getPerformanceFormLabel(key);
          return (
            <div className="mb-4 lg:mb-0" key={index} data-testid={label}>
              <PerformanceReviewSelect
                label={label}
                selectedValue={performanceObj[key]}
                onSelect={(value) => handleTypeChange(value, key)}
                disabled={readOnly}
                error={
                  !_.isEmpty(errors) &&
                  typeof errors[key] === 'string' &&
                  errors[key].length > 0
                }
              />
              {!_.isEmpty(errors) &&
                typeof errors[key] === 'string' &&
                errors[key].length > 0 && (
                  <ValidationMsg
                    msg={`${label} is required`}
                    name={`${key}_Required`}
                  />
                )}
            </div>
          );
        }
      )}
      <div className="bg-gray-200 h-0.5 col-span-2 my-6 md:my-2"></div>
    </div>
  );
};
