import { useMutation } from '@tanstack/react-query';
import { checkoutModel, editModel, modelList, modelApply, copyModel, copyWeekModel } from './api';
import { notify } from 'components/Notification/useNotification';
import { CreateModel } from '../components/Forms/CreateModelForm';
import { useNavigate } from 'react-router-dom';
import {
  CreateModelResponse,
  CheckoutModelReqBody,
  EditModelReqBody,
  ApplyModelReqBody,
  CopyModelReqBody,
  CopyModelSuccessResponse,
  CopyWeekModelReqBody,
} from '../types';
import { queryClient } from 'services/api/react-query';

export const useCreateModel = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (body: CreateModel) => modelList.post(body),
    onSuccess: (response: CreateModelResponse) => {
      navigate(`/non-union-scheduling/modeling/${response.id}/edit-model`);
      notify.success({
        message: `You have successfully created a new model.`,
      });
    },
  });
};

export const useCheckoutModel = (id: string) => {
  return useMutation({
    mutationFn: (body: CheckoutModelReqBody) => checkoutModel.post(body, id),
  });
};

export const useApplyModel = () => {
  return useMutation({
    mutationFn: (body: ApplyModelReqBody) => modelApply.post(body),
  });
};
export const useEditModel = (id: string) => {
  return useMutation({
    mutationFn: (body: EditModelReqBody) => editModel.put(body, id),
    onSuccess: async () => {
      await queryClient.resetQueries({
        queryKey: ['model-details', id],
        type: 'active',
      });
    },
  });
};
export const useCopyModel = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (body: CopyModelReqBody) => copyModel.post(body),
    onSuccess: (response: CopyModelSuccessResponse) => {
      navigate(`/non-union-scheduling/modeling/${response.id}/edit-model`);
    },
  });
};

export const useCopyWeekModel = () => {
  return useMutation({
    mutationFn: (body: CopyWeekModelReqBody) => copyWeekModel.post(body),
  });
};