import { Button, Drawer } from '@uss/react-components';
import { format, parseISO } from 'date-fns';
import { Org } from 'features/org';
import { Person } from 'features/people';
import { useState } from 'react';
import { CrewSelectFilter } from '../CrewSelectFilter';

interface Props {
  onAdd: (id: string | undefined) => void;
  open: boolean;
  setOpen: (val: boolean) => void;
  effectiveDate: string | undefined;
  employeeCrewIds: string[] | undefined;
  employee: Person | undefined;
}

export const AddRecordDrawer = ({
  onAdd,
  open,
  setOpen,
  effectiveDate,
  employeeCrewIds,
  employee,
}: Props) => {
  const [selectedOption, setSelectedOption] = useState<Org | null>(null);

  return (
    <Drawer
      placement={window.innerWidth > 768 ? 'right' : 'bottom'}
      title="Add Record"
      isOpen={open}
      onClose={(): void => {
        setSelectedOption(null);
        setOpen(false);
      }}
      closeButton={window.innerWidth > 768 ? false : true}
      className="w-full sm:w-1/2 md:w-1/3 xl:w-1/4 2xl:w-1/5 md:top-0 top-18 md:rounded-t-none rounded-t-md"
    >
      <div className="flex-grow">
        <span className="text-xs text-gray-700 font-normal">Crew</span>
        <CrewSelectFilter
          selectedCrew={selectedOption}
          setSelectedCrew={setSelectedOption}
          employeeCrewIds={employeeCrewIds}
          employee={employee}
        />

        <div
          className="flex flex-col  w-full border-t border-gray-300 mt-8 "
          data-testid="effective-date-container"
        >
          <span className="text-xs text-gray-700 font-normal mt-4">
            Effective Date
          </span>
          <span className="text-sm text-gray-900 font-semibold">
            {effectiveDate
              ? format(parseISO(effectiveDate), 'MMM dd, yyyy')
              : '--'}
          </span>
        </div>
      </div>
      <Drawer.Controls>
        <Button
          className="md:mb-0 mb-2 w-full"
          variant="outline"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="md:ml-4 w-full"
          disabled={!selectedOption}
          onClick={() => {
            onAdd(selectedOption?.id);
            setSelectedOption(null);
          }}
        >
          Add
        </Button>
      </Drawer.Controls>
    </Drawer>
  );
};
