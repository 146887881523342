import { PayPulloffType } from 'features/paypulloff/types';
import { PaginationResponse, SortDirection } from 'types/common';
import { ObjectValues } from 'types/utils';

export const PAY_PERIOD_SORT = {
  STARTDATE: 'startDate',
  ENDDATE: 'endDate',
  WEEKNUMBER: 'weekNumber',
  PAYDATE: 'payDate',
} as const;

export type PayPeriodSort = ObjectValues<typeof PAY_PERIOD_SORT>;

export const PAY_PERIOD_STATUS = {
  NOT_OPEN: 'not-open',
  LOCKED: 'locked',
  PROCESSED: 'processed',
  OPEN: 'open',
  CLOSED: 'closed',
  IN_PROGRESS: 'in-progress',
} as const;

export type PayPeriodStatus = ObjectValues<typeof PAY_PERIOD_STATUS>;

export interface PayPeriod {
  id: string;
  payrollName: string;
  pullOffType: PayPulloffType;
  payPeriod: string;
  year: string;
  startDate: string;
  endDate: string;
  approvalByDate: string;
  payDate: string;
  status: PayPeriodStatus;
  sellBack: boolean;
  noOfWeeks: number;
}

export interface PayPeriodListOptions {
  payrollName?: string;
  startDate?: string;
  endDate?: string;
  sortBy?: PayPeriodSort;
  sortType?: SortDirection;
  pageSize?: number;
  page?: number;
  status?: PayPeriodStatus;
  payYear?: number;
}

export interface PayPeriodList extends PaginationResponse {
  items: PayPeriod[];
  lastPayCycleLoadedDate: string;
}

export interface PayrollName {
  id: string;
  name: string;
  description: string;
  openPayperiod: {
    id: string;
    startDate: string;
    endDate: string;
  };
  currentYearLastPayPeriod: {
    id: string;
    startDate: string;
    endDate: string;
  };
}

export interface PayrollNameList extends PaginationResponse {
  items: PayrollName[];
}
export interface SendEmailPayload {
  payYear: number;
  payrollName: string | null;
  sendTo: string | null;
}

export interface SendEmailResponse {
  success: boolean;
  message: string;
}

export interface PayrollNameOptions {
  enabled: string;
}
