import Page from 'components/Layouts/Page';
import useUserRoles from 'hooks/useUserRoles';
import { NavLink } from 'react-router-dom';
import Details from 'features/paycode/components/Tabs/Details';

const CreatePaycode = () => {
  //get user roles
  const { isSuperAdmin } = useUserRoles();

  const actionText = isSuperAdmin ? 'Add Pay Code' : 'Request Pay Code';
  return (
    <Page
      breadcrumbs={[
        <NavLink key="bc-1" to="/admin">
          Admin
        </NavLink>,
        <NavLink key="bc-2" to="/admin/paycodes">
          Pay Code Maintenance
        </NavLink>,
      ]}
      heading={actionText}
    >
      <Page.Section>
        <Details />
      </Page.Section>
    </Page>
  );
};

export default CreatePaycode;
