import React, { useEffect, useState } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Datepicker, Select, TextArea } from '@uss/react-components';
import { addYears, format, parse, parseISO, subYears } from 'date-fns';
import ValidationError from 'components/Validation/ValidationError';
import { RequestPtoSchema } from 'features/paid-time-off/schemas/request-pto-schema';
import { getPtoPaycodes } from 'features/paid-time-off/utility/getPtoPaycodeList';
import {
  useAddPtoRequest,
  useUpdatePtoRequest,
} from 'features/paid-time-off/api/mutations';
import { RemainingPTO } from './RemainingPTO';
import { PtoFormType } from './RequestPtoForm';
import { Person } from 'features/people';
import { DATE_RANGE_PAYCODES } from 'features/paid-time-off/constants';
import checkCrossOverWeek from 'features/paid-time-off/utility/checkCrossOverWeek';
import { appNewDate } from 'utils/appNewDate';
import useUserRoles from 'hooks/useUserRoles';
import { ROLES } from 'features/roles';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import { AddPTOPlantType } from 'features/paid-time-off/types/employee-pto';
import { PtoDetail, UpdatePtoRequestPut } from 'features/paid-time-off/types';
import { ptoFormFieldEnable } from 'features/paid-time-off/utility/ptoFormFieldEnable';
import { useEmpPTODetailsByPtoId } from 'features/paid-time-off/api/queries';

const EllipsisForm = ({
  defaultValues,
  setOpenPTODrawer,
  employee,
  ptoId,
  isAdjustmentOpen,
}: {
  defaultValues: PtoFormType;
  setOpenPTODrawer: React.Dispatch<React.SetStateAction<boolean>>;
  employee: Person;
  ptoId: string | '';
  isAdjustmentOpen: boolean;
}) => {
  //Request/Add PTO
  const { mutateAsync: requestPto, isPending: changeAwaiting } =
    useAddPtoRequest();

  //Getting PTO Details by ptoId
  const { data: ptoDetails } = useEmpPTODetailsByPtoId(ptoId);
  //Edit PTO
  const { mutateAsync: updatePtoRequest, isPending: editAwaiting } =
    useUpdatePtoRequest(
      ptoDetails ? ptoDetails.pkWfmEmpPtoDetailsId : '',
      ptoDetails ? ptoDetails.ussId : ''
    );

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<PtoFormType>({
    resolver: zodResolver(RequestPtoSchema),
    defaultValues: defaultValues,
  });

  //Getting Roles
  const { roles } = useUserRoles();
  const { feature: timecardFeature } = useNonUnionStore();

  //setting ussId of employeeId as it is a mandatory field
  useEffect(() => {
    if (employee.ussId) {
      setValue('ussId', employee.ussId.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

  const [paycodeDesc, setPaycodeDesc] = useState('');

  //setting ptoDetails value to fields
  useEffect(() => {
    if (ptoDetails) {
      setValue('paycode', ptoDetails.paycode);

      const descArr = getPtoPaycodes(
        employee.ptoClass,
        employee.shiftAssignment
      );
      const matchedDesc = descArr.filter(
        (item) => item.paycode === ptoDetails.paycode
      );
      setValue('paycodeDescription', matchedDesc[0].description);
      setPaycodeDesc(matchedDesc[0].description);
      setValue('ptoDate', ptoDetails.startDate);
      setValue(
        'chargeYear',
        ptoDetails.chargeYear ? ptoDetails.chargeYear.toString() : ''
      );
      setValue('notes', ptoDetails.notes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ptoDetails]);

  const notes = useWatch({ control, name: 'notes' });

  const { status: ptoStatus } = getValues();

  /* Charge Year Logic Start */
  const _paycode = useWatch({ control, name: 'paycode' });

  const hasDateRange = DATE_RANGE_PAYCODES.includes(_paycode);
  /**
   * Passing start-date and end-date for checking crossOverWeek
   * @param startDate The date selected from the timecard to request pto
   * @returns true/false
   * @description Here passing startDate and endDate same because request is for 1 day only
   */
  const isRangeInCrossOverWeek = checkCrossOverWeek(
    defaultValues.startDate,
    hasDateRange ? defaultValues.startDate : undefined
  );
  const hasChargeYear =
    (_paycode === 'VH' || _paycode === 'VD') && isRangeInCrossOverWeek;
  /* Charge Year Logic End */

  const years = [
    format(subYears(new Date(), 1), 'yyyy'),
    format(new Date(appNewDate()), 'yyyy'),
    format(addYears(new Date(), 1), 'yyyy'),
  ];

  //request/add pto
  const handleRequest = async (data: PtoFormType) => {
    //Whenever a Pay Approver (or higher role) Adds PTO, whether done in Schedules (NU) or Approvals (NU), it is to be added in Approved state.
    if (timecardFeature !== 'my-schedule') {
      data.status = 'approved';
    } else data.status = 'requested';
    data.chargeYear = format(
      parse(data.startDate, 'yyyy-MM-dd', new Date()),
      'yyyy'
    );
    await requestPto(data, {
      onSuccess: () => {
        setOpenPTODrawer(false);
      },
    });
  };

  /**
 * @description Function that gives the status based on ptoStatus and timecard feature
 *
 * Crew Member > My Schedule (NU)
    Ellipses > Edit PTO > Modifies Notes on an Approved PTO Request - 
    clicks Save - Remains in Approved state
 
  * Crew Member > My Schedule (NU)
    Ellipses > Edit PTO  > Modifies Pay Code on an Approved PTO Request - 
    clicks Save - Changes from Approved to Requested
  *
 */
  const getStatus = (payload: UpdatePtoRequestPut, ptoDetails: PtoDetail) => {
    if (ptoDetails.status !== 'approved') {
      return ptoDetails.status;
    } else {
      //For status Approved
      //For My Schedule - if paycode or chargeyear value changes then change status=requested
      if (timecardFeature === 'my-schedule') {
        if (
          payload.paycode !== ptoDetails.paycode ||
          ((employee.plantType === AddPTOPlantType.integratedMill ||
            employee.plantType === AddPTOPlantType.office) &&
            payload.chargeYear !== ptoDetails.chargeYear.toString())
        )
          return 'requested';
        else if (payload.notes !== ptoDetails.notes) return 'approved';
        else return 'approved';
      } else return 'approved';
    }
  };

  const handleSave = async (data: PtoFormType) => {
    if (ptoDetails) {
      if (
        ptoDetails.status === 'approved' ||
        ptoDetails.status === 'requested' ||
        ptoDetails.status === 'denied' ||
        ptoDetails.status === 'adjustment' ||
        ptoDetails.status === 'taken'
      ) {
        const payload: UpdatePtoRequestPut = {
          chargeYear: data.chargeYear ? data.chargeYear : '',
          paycode: data.paycode,
          status: 'approved',
          ptoDate: data.ptoDate,
          notes: data.notes,
        };
        payload.status = getStatus(payload, ptoDetails);

        if (roles[0] === ROLES.PAY_APPROVER) {
          payload.status = 'approved';
        }
        await updatePtoRequest(payload);
      }
    }
  };

  //cancel button
  const handleCancel = () => {
    setOpenPTODrawer(false);
  };

  //Getting current screen feature out of the state
  const feature = useNonUnionStore((state) => state.feature);

  //get validation conditions for enable/disable fields on form
  const {
    formFields: { isPayCodeDisabled, isChargeYearDisabled, isNotesDisabled },
    buttons: { isSaveDisabled },
  } = ptoFormFieldEnable({
    ptoDetails,
    hasChargeYear,
    ptoType: feature,
  });
  useEffect(() => {
    if (_paycode) {
      const descArr = getPtoPaycodes(
        employee.ptoClass,
        employee.shiftAssignment
      );
      const matchedDesc = descArr.filter((item) => item.paycode === _paycode);
      setValue('paycodeDescription', matchedDesc[0].description);
      setPaycodeDesc(matchedDesc[0].description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_paycode]);

  return (
    <form
      className="@conatiner"
      onSubmit={handleSubmit((e) =>
        ptoDetails ? handleSave(e) : handleRequest(e)
      )}
    >
      {<RemainingPTO personDetails={employee} date={defaultValues.startDate} />}
      <div className="grid grid-rows-1 @xl:grid-rows-2 gap-5 mt-3">
        {ptoStatus !== 'blackout' && (
          <div>
            <label
              className="text-xs text-gray-700 font-normal"
              htmlFor="paycode"
            >
              Pay Code
            </label>
            <Controller
              control={control}
              defaultValue=""
              name="paycode"
              render={({ field, fieldState: { invalid, error } }) => (
                <>
                  <Select
                    {...field}
                    selected={field.value}
                    onSelect={field.onChange}
                    className="w-full"
                    disabled={
                      isAdjustmentOpen
                        ? false
                        : ptoDetails
                        ? isPayCodeDisabled
                        : false
                    }
                  >
                    <Select.Button
                      label={
                        field.value
                          ? field.value + ` - ${paycodeDesc}`
                          : 'Select Paycode'
                      }
                      error={invalid}
                    />
                    <Select.Options error={invalid}>
                      {getPtoPaycodes(
                        employee.ptoClass,
                        employee.shiftAssignment
                      ).map((item, itemIdx) => (
                        <Select.Option key={itemIdx} value={item.paycode}>
                          <span>
                            {item.paycode} - {item.description}
                          </span>
                        </Select.Option>
                      ))}
                    </Select.Options>
                  </Select>
                  {invalid && (
                    <ValidationError
                      msg={error?.message ?? ''}
                      name="paycodeRequired"
                    />
                  )}
                </>
              )}
            />
          </div>
        )}

        <div>
          <Controller
            control={control}
            name="startDate"
            render={({ field, fieldState: { invalid, error } }) => (
              <Datepicker
                isLabel={true}
                ariaLabelledBy={'Date'}
                onDateChange={(date) => {
                  field.onChange(format(date, 'yyyy-MM-dd'));
                }}
                calendarType={'date'}
                id="start-date"
                {...register('startDate')}
                isError={invalid}
                errormessage={error?.message ?? ''}
                startDate={new Date(parseISO(defaultValues.startDate))}
                disabled={true}
              />
            )}
          />
        </div>

        {(employee.plantType === AddPTOPlantType.integratedMill ||
          employee.plantType === AddPTOPlantType.office) && (
          <div>
            <label
              className="text-xs text-gray-700 font-normal"
              htmlFor="chargeYear"
            >
              PTO Charge Year
            </label>
            <Controller
              control={control}
              name="chargeYear"
              defaultValue={defaultValues.chargeYear}
              render={({ field, fieldState: { invalid, error } }) => (
                <Select
                  {...field}
                  data-testid="charge_year"
                  selected={field.value}
                  onSelect={field.onChange}
                  className="w-full"
                  disabled={
                    isAdjustmentOpen
                      ? false
                      : ptoDetails
                      ? isChargeYearDisabled
                      : !hasChargeYear
                  }
                  defaultValue={defaultValues.chargeYear}
                >
                  <Select.Button label={field.value ? field.value : 'Select'} />
                  <Select.Options>
                    {years.map((item, itemIdx) => (
                      <Select.Option key={itemIdx} value={item}>
                        <span>{item}</span>
                      </Select.Option>
                    ))}
                  </Select.Options>
                </Select>
              )}
            />
          </div>
        )}

        <div>
          <label className="text-xs text-gray-700 font-normal" htmlFor="notes">
            Notes (Optional)
          </label>
          <div className="col-span-2 mb-4 lg:mb-0">
            <TextArea
              maxLength={100}
              label="Note"
              id="form-comment"
              placeholder={'Enter text'}
              {...register('notes')}
              hideLabel
              error={!!errors.notes?.message}
              errorMessage={errors.notes?.message}
              disabled={
                isAdjustmentOpen ? false : ptoDetails ? isNotesDisabled : false
              }
            />
            <div className="col-span-2 text-xs mb-2 text-gray-600 text-right">
              {100 - (notes ? notes.length : 0)} Characters left
            </div>
          </div>
        </div>
      </div>
      <div className="sm:flex sm:flex-row sm:justify-between sm:gap-2 mt-6">
        <Button
          onClick={handleCancel}
          variant="outline"
          className="mb-2 sm:mb-0 text-sm w-full"
          type="button"
        >
          Cancel
        </Button>
        {ptoDetails ? (
          <Button
            variant="primary"
            className="mb-2 sm:mb-0 text-sm w-full"
            type="submit"
            disabled={isAdjustmentOpen ? false : isSaveDisabled || editAwaiting}
          >
            Save
          </Button>
        ) : (
          <Button
            variant="primary"
            className="mb-2 sm:mb-0 text-sm w-full"
            type="submit"
            disabled={changeAwaiting}
          >
            {feature === 'my-schedule' ? 'Request' : 'Add'}
          </Button>
        )}
      </div>
    </form>
  );
};

export default EllipsisForm;
