import * as React from 'react';

const HolidayLinkSvg = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      enableBackground="new 0 0 550 300"
      xmlSpace="preserve"
      width="100%"
      height="100%"
      viewBox="0 0 550 300"
      {...props}
    >
      <style type="text/css">
        {`
	.st0 {fill:#FFFFFF;}
	.st1{fill:#C8E5F7;}
	.st2{opacity:0.7; fill:#E4F2FB;}
	.st3{fill:#C8E091;}
	.st4{fill:#8BA258;}
	.st5{fill:#BAD875;}
	.st6{fill:#00466E;}
	.st7{fill:#3D95D1;}
	.st8{fill:#005D92;}
	.st9{fill:#337DA8;}
	.st10{fill:#265E7E;}
	.st11{fill:#E9F5FC;}
	.st12{fill:#333333;}
	.st13{fill:#FEEACE;}
	.st14{fill:#91CBEE;}
	.st15{fill:#76BEEA;}
	.st16{fill:#ADD8F2;}
	.st17{fill:#CEE8F7;}
	.st18{fill:#FAA634;}
	.st19{fill:none;}
	.st20{opacity:0.2;fill:#005D92;}
	.st21{opacity:0.1;fill:#005D92;}
	.st22{fill:#FCCA85;}
	.st23{opacity:0.1;}
	.st24{fill:#64AADA;}
	.st25{opacity:0.15;fill:#005D92;}
	.st26{opacity:0.3;fill:#005D92;}
	.st27{opacity:0.4;fill:#3D95D1;}
	.st28{fill:#FBB85D;}
	.st29{opacity:0.5;fill:url(#SVGID_1_);}
	.st30{opacity:0.8;fill:url(#SVGID_00000112591641551357513980000007709154227283099280_);}
	.st31{fill:#003553;}
	.st32{fill:#3097B9;}
	.st33{opacity:0.3;fill:#BB7D27;}
  `}
      </style>
      <rect id="XMLID_82_" className="st0" width="550" height="300" />
      <g>
        <path
          className="st1"
          d="M267.86,264.87c-35.28,15.84-127.52,34.04-157.05-28.14c-45.87-96.6,22.46-192.68,71.59-178.67
		c35.28,10.06,56.67,23.09,88.62-2.4c36.15-28.84,144.97-40.63,187.19,11.93c37.19,46.31-2.61,84.38,13.39,120.45
		c58.77,132.48-105.04,79.38-136.95,65.99C302.73,240.65,285,257.18,267.86,264.87z"
        />
        <path
          className="st2"
          d="M284.01,268.17c-56.67-1.39-217.59,38.29-204.69-101.06c12.89-139.35,135.94-41.47,191.71-88.1
		c55.77-46.63,113.29-81.56,170.33-12.72c57.03,68.84,58.34,77.59,33.72,126.38c-24.62,48.79-25.29,98.8-103.65,88.18
		C293.06,270.23,306.07,268.71,284.01,268.17z"
        />
      </g>
      <g>
        <g>
          <path
            className="st3"
            d="M160.67,260.92c0,0,19.21-21.37,12.59-35.72c-11.58-25.11-26.31-22.3-29.03-40.13
			c-2.71-17.83-28.24-27.64-31.48-14.28c-3.24,13.36,7.32,16.06,5.08,23.45c-2.23,7.39,1.31,21.97,10.62,29.54
			c9.31,7.57-16.4,29.32,9.72,37.79C156.62,267.55,160.67,260.92,160.67,260.92z"
          />
          <path
            className="st4"
            d="M157.65,263c-0.04-0.23-3.61-23.63-5.94-33.32c-2.33-9.65-5.96-13.93-9.48-18.07
			c-2.96-3.49-5.76-6.78-7.76-12.99c-4.35-13.54-13.02-27.2-13.11-27.33l0.24-0.15c0.09,0.14,8.77,13.82,13.14,27.4
			c1.98,6.16,4.76,9.43,7.7,12.9c3.54,4.17,7.19,8.47,9.54,18.19c2.34,9.7,5.91,33.11,5.95,33.34L157.65,263z"
          />
        </g>
        <g>
          <path
            className="st5"
            d="M153.29,259.54c0,0-1.75-24.62-14.56-29.09c-22.43-7.82-29.48,2.96-42.11-6
			c-12.63-8.96-33.92,0.96-27.6,10.94c6.32,9.97,14.29,5.06,17.52,10.85c3.23,5.79,14.35,12.29,24.59,11.06
			c10.23-1.24,1.53,14.46,28.76,14.5C156.53,271.82,153.29,259.54,153.29,259.54z"
          />
          <path
            className="st4"
            d="M152.77,262.64c-0.17-0.12-16.75-11.86-24.14-16.2c-7.35-4.32-12.17-4.63-16.82-4.92
			c-3.92-0.25-7.62-0.49-12.66-2.96c-10.97-5.39-24.57-8.18-24.71-8.21l0.05-0.24c0.14,0.03,13.77,2.82,24.77,8.22
			c4.99,2.45,8.67,2.69,12.56,2.93c4.68,0.3,9.53,0.61,16.93,4.96c7.39,4.34,23.99,16.09,24.15,16.21L152.77,262.64z"
          />
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st6"
              d="M282.88,181.51h-0.13l-0.03,0.66h-3.18l0.09-0.88l0.1,0l0-0.01l0.01-3.23h3.3l0.07,0.43l1.52,1.12l4.82,1.38
				c0,0,0.38,0.17,0.37,0.37l-0.01,0.38l-0.01,0.23c0,0-0.06,0.1-0.45,0.22h-5.53l-0.33-0.44l-0.33-0.2L282.88,181.51z"
            />
          </g>
          <g>
            <path
              className="st6"
              d="M252.15,181.51h-0.13l-0.03,0.66h-3.18l0.09-0.88l0.1,0l0-0.01l0.73-3.23h3.3l0.07,0.43l0.81,1.12l4.82,1.38
				c0,0,0.38,0.17,0.37,0.37l-0.01,0.38l-0.01,0.23c0,0-0.06,0.1-0.45,0.22h-5.53l-0.33-0.44l-0.33-0.2L252.15,181.51z"
            />
          </g>
        </g>
        <polygon
          className="st7"
          points="249.15,95.7 270.39,98.55 270.33,102.95 270.45,108.53 277.82,144.8 283.93,178.05 278.64,178.93 
		269.88,146.56 260.04,114.98 259.61,114.28 256.66,146.08 253.95,178.16 248.81,178.45 248.53,145.38 247.45,110.95 246.06,106.31 
			"
        />
      </g>
      <g>
        <g>
          <g>
            <path
              className="st8"
              d="M143.02,235.76c0,0,4.56-3.31,5-4.68c0.61-1.9-0.89-3.37-2.25-1.96
				C144.41,230.53,144.17,231.41,143.02,235.76z"
            />
            <path
              className="st6"
              d="M146.85,229.63c0,0-1.6,1.93-3.81,6.07C143.04,235.7,146.63,230.35,146.85,229.63z"
            />
          </g>
          <g>
            <g>
              <path
                className="st8"
                d="M124.52,194.77c0,0,3.04-4.75,2.95-6.19c-0.13-1.99-2.05-2.81-2.81-1
					C123.89,189.39,123.99,190.3,124.52,194.77z"
              />
              <path
                className="st6"
                d="M125.83,187.65c0,0-0.78,2.38-1.33,7.05C124.51,194.7,125.89,188.41,125.83,187.65z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M129.64,203.36c0,0,4.56-3.31,5-4.68c0.61-1.9-0.89-3.37-2.25-1.96
					C131.02,198.13,130.78,199.01,129.64,203.36z"
              />
              <path
                className="st6"
                d="M133.47,197.22c0,0-1.6,1.93-3.81,6.07C129.66,203.29,133.25,197.94,133.47,197.22z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M129,201.88c0,0-5.63-0.03-6.79-0.9c-1.6-1.19-1.24-3.25,0.69-2.9C124.83,198.43,125.54,199.01,129,201.88z
					"
              />
              <path
                className="st6"
                d="M122.31,199.12c0,0,2.43,0.64,6.63,2.72C128.95,201.84,122.91,199.58,122.31,199.12z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M133.62,210.73c0,0-5.63-0.03-6.79-0.9c-1.6-1.19-1.24-3.25,0.69-2.9
					C129.46,207.28,130.16,207.86,133.62,210.73z"
              />
              <path
                className="st6"
                d="M126.94,207.97c0,0,2.43,0.64,6.63,2.72C133.57,210.69,127.54,208.43,126.94,207.97z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M138.41,221.67c0,0-5.6,0.58-6.85-0.15c-1.72-1.01-1.59-3.1,0.37-2.96
					C133.89,218.7,134.65,219.2,138.41,221.67z"
              />
              <path
                className="st6"
                d="M131.46,219.66c0,0,2.48,0.37,6.89,1.98C138.35,221.63,132.11,220.05,131.46,219.66z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M142.36,232.83c0,0-5.6,0.58-6.85-0.15c-1.72-1.01-1.59-3.1,0.37-2.96
					C137.84,229.86,138.61,230.36,142.36,232.83z"
              />
              <path
                className="st6"
                d="M135.41,230.82c0,0,2.48,0.37,6.89,1.98C142.3,232.8,136.06,231.21,135.41,230.82z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M123.68,193.3c0,0-5.63-0.03-6.79-0.9c-1.6-1.19-1.24-3.25,0.69-2.9
					C119.51,189.85,120.22,190.43,123.68,193.3z"
              />
              <path
                className="st6"
                d="M116.99,190.54c0,0,2.43,0.64,6.63,2.72C123.63,193.26,117.59,190.99,116.99,190.54z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M117.79,185.34c0,0-5.63-0.03-6.79-0.9c-1.6-1.19-1.24-3.25,0.69-2.9
					C113.62,181.89,114.33,182.47,117.79,185.34z"
              />
              <path
                className="st6"
                d="M111.1,182.57c0,0,2.43,0.64,6.63,2.72C117.74,185.3,111.7,183.03,111.1,182.57z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M110.87,176.76c0,0-5.63-0.03-6.79-0.9c-1.6-1.19-1.24-3.25,0.69-2.9
					C106.71,173.31,107.41,173.89,110.87,176.76z"
              />
              <path
                className="st6"
                d="M104.19,174c0,0,2.43,0.64,6.63,2.72C110.82,176.72,104.79,174.46,104.19,174z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M103.97,170.04c0,0-4.73,1.23-5.89,0.77c-1.61-0.64-1.76-2.45-0.07-2.59
					C99.71,168.08,100.44,168.41,103.97,170.04z"
              />
              <path
                className="st6"
                d="M97.76,169.22c0,0,2.17,0,6.16,0.8C103.92,170.02,98.36,169.47,97.76,169.22z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M98.99,165.93c0,0-4.85-0.56-5.77-1.41c-1.27-1.18-0.76-2.92,0.87-2.44
					C95.72,162.57,96.28,163.13,98.99,165.93z"
              />
              <path
                className="st6"
                d="M93.49,162.92c0,0,2.03,0.78,5.46,2.97C98.95,165.89,93.96,163.37,93.49,162.92z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M134.78,213.58c0,0,4.56-3.31,5-4.68c0.61-1.9-0.89-3.37-2.25-1.96
					C136.16,208.35,135.92,209.23,134.78,213.58z"
              />
              <path
                className="st6"
                d="M138.6,207.45c0,0-1.6,1.93-3.81,6.07C134.79,213.52,138.38,208.17,138.6,207.45z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M139.02,223.29c0,0,4.56-3.31,5-4.68c0.61-1.9-0.89-3.37-2.25-1.96
					C140.4,218.06,140.16,218.94,139.02,223.29z"
              />
              <path
                className="st6"
                d="M142.85,217.15c0,0-1.6,1.93-3.81,6.07C139.04,223.22,142.63,217.87,142.85,217.15z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M118.61,186.29c0,0,3.04-4.75,2.94-6.19c-0.13-1.99-2.05-2.81-2.81-1
					C117.98,180.91,118.08,181.82,118.61,186.29z"
              />
              <path
                className="st6"
                d="M119.93,179.17c0,0-0.78,2.38-1.33,7.05C118.6,186.22,119.99,179.93,119.93,179.17z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M111.41,178.18c0,0,3.04-4.75,2.95-6.19c-0.13-1.99-2.05-2.81-2.81-1
					C110.78,172.81,110.88,173.72,111.41,178.18z"
              />
              <path
                className="st6"
                d="M112.73,171.07c0,0-0.78,2.38-1.33,7.05C111.4,178.12,112.79,171.82,112.73,171.07z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M105.01,171.11c0,0,2.55-3.98,2.47-5.19c-0.11-1.67-1.72-2.36-2.36-0.84
					C104.48,166.6,104.57,167.37,105.01,171.11z"
              />
              <path
                className="st6"
                d="M106.12,165.15c0,0-0.66,2-1.11,5.91C105,171.06,106.16,165.78,106.12,165.15z"
              />
            </g>
            <path
              className="st6"
              d="M148.46,261.8c-0.01-0.15-1.47-15.47-8.31-34.86c-6.31-17.89-18.63-43.02-41.47-61.11l0.1-0.12
				c10.1,8,19,18.02,26.46,29.8c5.97,9.42,11.03,19.98,15.06,31.38c6.85,19.41,8.3,34.74,8.32,34.89L148.46,261.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              className="st9"
              d="M133.57,234.71c0,0,4.27-5.53,4.31-7.32c0.06-2.48-2.23-3.7-3.37-1.54
				C133.39,228,133.41,229.13,133.57,234.71z"
            />
            <path
              className="st10"
              d="M135.98,226.06c0,0-1.23,2.86-2.41,8.56C133.57,234.62,135.97,227,135.98,226.06z"
            />
          </g>
          <g>
            <g>
              <path
                className="st9"
                d="M97.2,192.4c0,0,1.96-6.71,1.34-8.39c-0.85-2.33-3.43-2.62-3.7-0.2C94.58,186.22,95.02,187.27,97.2,192.4z"
              />
              <path
                className="st10"
                d="M96.28,183.47c0,0-0.1,3.11,0.89,8.85C97.17,192.32,96.62,184.35,96.28,183.47z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M106.31,200.83c0,0,4.27-5.53,4.31-7.32c0.06-2.48-2.23-3.7-3.37-1.54
					C106.12,194.12,106.14,195.25,106.31,200.83z"
              />
              <path
                className="st10"
                d="M108.71,192.19c0,0-1.23,2.86-2.41,8.56C106.3,200.75,108.7,193.12,108.71,192.19z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M105.03,199.3c0,0-6.72,1.93-8.4,1.31c-2.32-0.86-2.61-3.44-0.19-3.7
					C98.86,196.65,99.9,197.09,105.03,199.3z"
              />
              <path
                className="st10"
                d="M96.11,198.35c0,0,3.11-0.09,8.85,0.92C104.95,199.26,96.98,198.68,96.11,198.35z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M113.63,208.21c0,0-6.72,1.93-8.4,1.31c-2.32-0.86-2.61-3.44-0.19-3.7
					C107.46,205.56,108.51,206,113.63,208.21z"
              />
              <path
                className="st10"
                d="M104.71,207.26c0,0,3.11-0.09,8.85,0.92C113.55,208.17,105.58,207.6,104.71,207.26z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M123.15,219.55c0,0-6.46,2.66-8.21,2.22c-2.4-0.6-2.97-3.13-0.59-3.65
					C116.73,217.6,117.82,217.92,123.15,219.55z"
              />
              <path
                className="st10"
                d="M114.18,219.58c0,0,3.08-0.43,8.89-0.06C123.07,219.53,115.08,219.82,114.18,219.58z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M131.76,231.45c0,0-6.46,2.66-8.21,2.22c-2.4-0.6-2.97-3.13-0.59-3.65
					C125.34,229.5,126.43,229.82,131.76,231.45z"
              />
              <path
                className="st10"
                d="M122.79,231.48c0,0,3.08-0.43,8.89-0.06C131.68,231.43,123.69,231.72,122.79,231.48z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M95.69,190.95c0,0-6.72,1.93-8.4,1.31c-2.32-0.86-2.61-3.44-0.19-3.7
					C89.52,188.3,90.57,188.74,95.69,190.95z"
              />
              <path
                className="st10"
                d="M86.77,190c0,0,3.11-0.09,8.85,0.92C95.62,190.91,87.64,190.33,86.77,190z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M85.9,183.53c0,0-6.72,1.93-8.4,1.31c-2.32-0.86-2.61-3.44-0.19-3.7C79.73,180.89,80.77,181.33,85.9,183.53
					z"
              />
              <path
                className="st10"
                d="M76.98,182.58c0,0,3.11-0.09,8.85,0.92C85.82,183.5,77.85,182.92,76.98,182.58z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M74.67,175.75c0,0-6.72,1.93-8.4,1.31c-2.32-0.86-2.61-3.44-0.19-3.7C68.5,173.1,69.55,173.54,74.67,175.75
					z"
              />
              <path
                className="st10"
                d="M65.75,174.8c0,0,3.11-0.09,8.85,0.92C74.6,175.72,66.62,175.14,65.75,174.8z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M64.11,170.16c0,0-5.19,3.12-6.74,2.97c-2.14-0.2-2.95-2.3-0.98-3.05
					C58.35,169.32,59.33,169.46,64.11,170.16z"
              />
              <path
                className="st10"
                d="M56.42,171.36c0,0,2.59-0.77,7.61-1.21C64.04,170.15,57.23,171.45,56.42,171.36z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M56.74,167.02c0,0-5.97,1.04-7.36,0.34c-1.92-0.96-1.93-3.21,0.18-3.2
					C51.67,164.16,52.53,164.64,56.74,167.02z"
              />
              <path
                className="st10"
                d="M49.14,165.36c0,0,2.69,0.22,7.53,1.62C56.68,166.98,49.86,165.74,49.14,165.36z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M116,211.2c0,0,4.27-5.53,4.31-7.32c0.06-2.48-2.23-3.7-3.37-1.54C115.81,204.49,115.84,205.62,116,211.2z"
              />
              <path
                className="st10"
                d="M118.4,202.56c0,0-1.23,2.86-2.41,8.56C116,211.12,118.4,203.49,118.4,202.56z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M124.44,221.26c0,0,4.27-5.53,4.31-7.32c0.06-2.48-2.23-3.7-3.37-1.54
					C124.26,214.55,124.28,215.69,124.44,221.26z"
              />
              <path
                className="st10"
                d="M126.85,212.62c0,0-1.23,2.86-2.41,8.56C124.44,221.18,126.84,213.55,126.85,212.62z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M87.21,184.37c0,0,1.96-6.71,1.34-8.39c-0.85-2.33-3.43-2.62-3.7-0.2
					C84.58,178.2,85.02,179.24,87.21,184.37z"
              />
              <path
                className="st10"
                d="M86.29,175.45c0,0-0.1,3.11,0.89,8.85C87.17,184.3,86.62,176.32,86.29,175.45z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M75.8,177.25c0,0,1.96-6.71,1.34-8.39c-0.85-2.33-3.43-2.62-3.7-0.2C73.18,171.08,73.61,172.12,75.8,177.25
					z"
              />
              <path
                className="st10"
                d="M74.88,168.33c0,0-0.1,3.11,0.89,8.85C75.77,177.18,75.22,169.2,74.88,168.33z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M65.71,171.08c0,0,1.64-5.63,1.12-7.04c-0.71-1.95-2.88-2.2-3.1-0.17
					C63.51,165.9,63.88,166.78,65.71,171.08z"
              />
              <path
                className="st10"
                d="M64.94,163.59c0,0-0.08,2.61,0.74,7.42C65.69,171.02,65.22,164.33,64.94,163.59z"
              />
            </g>
            <path
              className="st10"
              d="M149.16,263.78c-0.07-0.18-7.16-17.89-22.09-38.57c-13.77-19.08-37.22-44.67-70.73-58.2l0.08-0.18
				c14.81,5.98,28.92,14.8,41.92,26.2c10.4,9.12,20.12,19.91,28.9,32.07c14.94,20.7,22.04,38.43,22.11,38.61L149.16,263.78z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              className="st8"
              d="M135.91,248.98c0,0,1.36-3.63,1.09-4.59c-0.37-1.32-1.79-1.6-2.04-0.27
				C134.71,245.45,134.91,246.05,135.91,248.98z"
            />
            <path
              className="st6"
              d="M135.77,244c0,0-0.18,1.72,0.12,4.93C135.89,248.94,135.92,244.5,135.77,244z"
            />
          </g>
          <g>
            <g>
              <path
                className="st8"
                d="M109.69,232.49c0,0-0.06-3.88-0.66-4.67c-0.83-1.09-2.25-0.83-1.99,0.5
					C107.29,229.64,107.69,230.12,109.69,232.49z"
              />
              <path
                className="st6"
                d="M107.75,227.9c0,0,0.46,1.67,1.92,4.55C109.66,232.45,108.07,228.31,107.75,227.9z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M115.9,235.47c0,0,1.36-3.63,1.09-4.59c-0.37-1.32-1.79-1.6-2.04-0.27
					C114.71,231.94,114.9,232.54,115.9,235.47z"
              />
              <path
                className="st6"
                d="M115.76,230.49c0,0-0.18,1.72,0.12,4.93C115.89,235.43,115.91,230.99,115.76,230.49z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M114.97,234.87c0,0-3.25,2.12-4.24,2.07c-1.37-0.08-1.95-1.4-0.71-1.93
					C111.27,234.47,111.89,234.53,114.97,234.87z"
              />
              <path
                className="st6"
                d="M110.08,235.82c0,0,1.64-0.55,4.84-0.96C114.93,234.86,110.6,235.86,110.08,235.82z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M120.99,238.19c0,0-3.25,2.12-4.24,2.07c-1.37-0.08-1.95-1.4-0.71-1.93
					C117.29,237.79,117.91,237.85,120.99,238.19z"
              />
              <path
                className="st6"
                d="M116.11,239.14c0,0,1.64-0.55,4.84-0.96C120.95,238.18,116.62,239.18,116.11,239.14z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M127.9,242.65c0,0-2.99,2.47-3.99,2.52c-1.37,0.07-2.09-1.17-0.91-1.84
					C124.17,242.66,124.8,242.65,127.9,242.65z"
              />
              <path
                className="st6"
                d="M123.14,244.13c0,0,1.57-0.73,4.71-1.48C127.85,242.65,123.66,244.11,123.14,244.13z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M134.41,247.55c0,0-2.99,2.47-3.99,2.52c-1.37,0.07-2.09-1.17-0.91-1.84
					C130.68,247.56,131.31,247.56,134.41,247.55z"
              />
              <path
                className="st6"
                d="M129.66,249.03c0,0,1.57-0.73,4.71-1.48C134.36,247.55,130.18,249.01,129.66,249.03z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M108.65,231.96c0,0-3.25,2.12-4.24,2.07c-1.37-0.08-1.95-1.4-0.71-1.93
					C104.95,231.57,105.58,231.63,108.65,231.96z"
              />
              <path
                className="st6"
                d="M103.77,232.92c0,0,1.64-0.55,4.84-0.96C108.61,231.96,104.29,232.95,103.77,232.92z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M102.25,229.63c0,0-3.25,2.12-4.24,2.07c-1.37-0.08-1.95-1.4-0.71-1.93
					C98.54,229.24,99.17,229.3,102.25,229.63z"
              />
              <path
                className="st6"
                d="M97.36,230.59c0,0,1.64-0.55,4.84-0.96C102.2,229.63,97.88,230.62,97.36,230.59z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M95.02,227.34c0,0-3.25,2.12-4.24,2.07c-1.37-0.08-1.95-1.4-0.71-1.93
					C91.31,226.95,91.94,227.01,95.02,227.34z"
              />
              <path
                className="st6"
                d="M90.13,228.3c0,0,1.64-0.55,4.84-0.96C94.97,227.34,90.65,228.33,90.13,228.3z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M88.5,226.1c0,0-2.24,2.5-3.09,2.68c-1.17,0.24-1.94-0.73-1.02-1.46C85.31,226.6,85.85,226.51,88.5,226.1z"
              />
              <path
                className="st6"
                d="M84.62,227.99c0,0,1.25-0.83,3.84-1.88C88.46,226.11,85.06,227.91,84.62,227.99z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M84.08,225.64c0,0-3,1.53-3.85,1.39c-1.18-0.19-1.55-1.39-0.43-1.73
					C80.93,224.95,81.46,225.07,84.08,225.64z"
              />
              <path
                className="st6"
                d="M79.78,226.01c0,0,1.46-0.32,4.26-0.37C84.04,225.63,80.22,226.09,79.78,226.01z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M122.74,239.39c0,0,1.36-3.63,1.09-4.59c-0.37-1.32-1.79-1.6-2.04-0.27
					C121.54,235.86,121.74,236.45,122.74,239.39z"
              />
              <path
                className="st6"
                d="M122.6,234.41c0,0-0.18,1.72,0.12,4.93C122.72,239.34,122.75,234.9,122.6,234.41z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M128.86,243.34c0,0,1.36-3.63,1.09-4.59c-0.37-1.32-1.79-1.6-2.04-0.27
					C127.67,239.81,127.87,240.41,128.86,243.34z"
              />
              <path
                className="st6"
                d="M128.73,238.36c0,0-0.18,1.72,0.12,4.93C128.85,243.3,128.87,238.86,128.73,238.36z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M103.08,229.86c0,0-0.06-3.88-0.66-4.67c-0.83-1.09-2.25-0.83-1.99,0.5
					C100.68,227.02,101.08,227.5,103.08,229.86z"
              />
              <path
                className="st6"
                d="M101.13,225.28c0,0,0.46,1.67,1.92,4.55C103.05,229.83,101.45,225.69,101.13,225.28z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M95.87,227.95c0,0-0.06-3.88-0.66-4.67c-0.83-1.09-2.25-0.83-1.99,0.5
					C93.46,225.11,93.87,225.59,95.87,227.95z"
              />
              <path
                className="st6"
                d="M93.92,223.37c0,0,0.46,1.67,1.92,4.55C95.84,227.92,94.24,223.78,93.92,223.37z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M89.5,226.33c0,0-0.05-3.25-0.56-3.92c-0.7-0.92-1.89-0.7-1.67,0.42C87.49,223.94,87.83,224.35,89.5,226.33
					z"
              />
              <path
                className="st6"
                d="M87.87,222.48c0,0,0.38,1.4,1.61,3.82C89.48,226.3,88.14,222.83,87.87,222.48z"
              />
            </g>
            <path
              className="st6"
              d="M148.93,261.85c-0.07-0.08-6.72-8.32-18.02-16.85c-10.43-7.87-27.05-17.61-47.03-19.31l0.01-0.11
				c8.84,0.75,17.76,3.12,26.52,7.04c7.01,3.14,13.93,7.27,20.57,12.29c11.31,8.54,17.97,16.78,18.04,16.86L148.93,261.85z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st8"
              d="M392.04,237.53c0,0-5.09-3.51-5.6-5.01c-0.71-2.07,0.88-3.71,2.42-2.2
				C390.39,231.83,390.68,232.79,392.04,237.53z"
            />
            <path
              className="st6"
              d="M387.69,230.9c0,0,1.8,2.08,4.33,6.56C392.02,237.46,387.95,231.68,387.69,230.9z"
            />
          </g>
          <g>
            <g>
              <path
                className="st8"
                d="M411.28,192.12c0,0-3.45-5.12-3.39-6.71c0.09-2.19,2.18-3.13,3.06-1.17
					C411.83,186.21,411.74,187.21,411.28,192.12z"
              />
              <path
                className="st6"
                d="M409.65,184.35c0,0,0.92,2.59,1.63,7.69C411.28,192.04,409.6,185.18,409.65,184.35z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M405.88,201.67c0,0-5.09-3.51-5.6-5.01c-0.71-2.07,0.88-3.71,2.42-2.2
					C404.23,195.97,404.52,196.93,405.88,201.67z"
              />
              <path
                className="st6"
                d="M401.53,195.04c0,0,1.8,2.08,4.33,6.56C405.86,201.6,401.79,195.82,401.53,195.04z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M406.55,200.03c0,0,6.18-0.18,7.42-1.16c1.72-1.35,1.27-3.6-0.83-3.16
					C411.03,196.14,410.27,196.79,406.55,200.03z"
              />
              <path
                className="st6"
                d="M413.8,196.83c0,0-2.64,0.77-7.2,3.15C406.6,199.98,413.16,197.35,413.8,196.83z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M401.7,209.85c0,0,6.18-0.18,7.42-1.16c1.72-1.35,1.27-3.6-0.83-3.16
					C406.18,205.96,405.42,206.61,401.7,209.85z"
              />
              <path
                className="st6"
                d="M408.96,206.65c0,0-2.64,0.77-7.2,3.15C401.76,209.8,408.31,207.17,408.96,206.65z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M396.74,221.96c0,0,6.16,0.5,7.51-0.34c1.86-1.15,1.66-3.44-0.48-3.24
					C401.62,218.59,400.79,219.15,396.74,221.96z"
              />
              <path
                className="st6"
                d="M404.3,219.58c0,0-2.71,0.47-7.5,2.35C396.8,221.92,403.61,220.02,404.3,219.58z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M392.69,234.3c0,0,6.16,0.5,7.51-0.34c1.86-1.15,1.66-3.44-0.48-3.24
					C397.57,230.93,396.74,231.49,392.69,234.3z"
              />
              <path
                className="st6"
                d="M400.26,231.91c0,0-2.71,0.47-7.5,2.35C392.75,234.26,399.56,232.36,400.26,231.91z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M412.16,190.49c0,0,6.18-0.18,7.42-1.16c1.72-1.35,1.27-3.6-0.83-3.16
					C416.64,186.6,415.88,187.25,412.16,190.49z"
              />
              <path
                className="st6"
                d="M419.42,187.29c0,0-2.64,0.77-7.2,3.15C412.22,190.44,418.77,187.8,419.42,187.29z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M418.41,181.61c0,0,6.18-0.18,7.43-1.16c1.72-1.35,1.27-3.6-0.83-3.16
					C422.89,177.72,422.13,178.37,418.41,181.61z"
              />
              <path
                className="st6"
                d="M425.67,178.41c0,0-2.64,0.77-7.2,3.15C418.47,181.56,425.02,178.93,425.67,178.41z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M425.77,172.03c0,0,6.18-0.18,7.42-1.16c1.72-1.35,1.27-3.6-0.83-3.16
					C430.25,168.15,429.49,168.8,425.77,172.03z"
              />
              <path
                className="st6"
                d="M433.03,168.83c0,0-2.64,0.77-7.2,3.15C425.82,171.99,432.38,169.35,433.03,168.83z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M433.16,164.49c0,0,5.21,1.23,6.48,0.69c1.75-0.74,1.87-2.73,0.01-2.84
					C437.78,162.23,437,162.61,433.16,164.49z"
              />
              <path
                className="st6"
                d="M439.95,163.42c0,0-2.38,0.05-6.73,1.03C433.22,164.46,439.3,163.71,439.95,163.42z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M438.52,159.85c0,0,5.3-0.73,6.29-1.69c1.36-1.32,0.76-3.22-1.02-2.65
					C442.01,156.08,441.42,156.72,438.52,159.85z"
              />
              <path
                className="st6"
                d="M444.47,156.42c0,0-2.2,0.91-5.91,3.39C438.56,159.81,443.96,156.92,444.47,156.42z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M400.51,213.01c0,0-5.09-3.51-5.6-5.01c-0.71-2.07,0.88-3.71,2.42-2.2
					C398.86,207.31,399.15,208.27,400.51,213.01z"
              />
              <path
                className="st6"
                d="M396.16,206.38c0,0,1.8,2.08,4.33,6.56C400.49,212.94,396.42,207.16,396.16,206.38z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M396.11,223.75c0,0-5.09-3.51-5.6-5.01c-0.71-2.07,0.88-3.71,2.42-2.2S394.75,219.01,396.11,223.75z"
              />
              <path
                className="st6"
                d="M391.76,217.12c0,0,1.8,2.08,4.33,6.56C396.09,223.68,392.02,217.91,391.76,217.12z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M417.54,182.67c0,0-3.45-5.12-3.39-6.71c0.09-2.19,2.18-3.13,3.06-1.17S418,177.76,417.54,182.67z"
              />
              <path
                className="st6"
                d="M415.91,174.91c0,0,0.92,2.59,1.64,7.69C417.54,182.6,415.86,175.73,415.91,174.91z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M425.22,173.6c0,0-3.45-5.12-3.39-6.71c0.09-2.19,2.18-3.13,3.06-1.17
					C425.77,167.7,425.68,168.69,425.22,173.6z"
              />
              <path
                className="st6"
                d="M423.59,165.84c0,0,0.92,2.59,1.63,7.69C425.23,173.53,423.55,166.67,423.59,165.84z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M432.05,165.69c0,0-2.9-4.3-2.84-5.63c0.08-1.84,1.83-2.63,2.56-0.98
					C432.51,160.73,432.44,161.57,432.05,165.69z"
              />
              <path
                className="st6"
                d="M430.69,159.18c0,0,0.77,2.18,1.37,6.45C432.06,165.63,430.65,159.87,430.69,159.18z"
              />
            </g>
            <path
              className="st6"
              d="M386.75,266.2c0.01-0.17,1.21-16.99,8.21-38.42c6.46-19.78,19.32-47.64,43.89-68.04l-0.11-0.13
				c-10.86,9.02-20.37,20.24-28.25,33.34c-6.3,10.48-11.58,22.18-15.7,34.78c-7.01,21.45-8.21,38.29-8.22,38.46L386.75,266.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              className="st9"
              d="M402.37,236.13c0,0-4.82-5.95-4.92-7.92c-0.13-2.71,2.35-4.11,3.65-1.77
				C402.4,228.77,402.41,230.01,402.37,236.13z"
            />
            <path
              className="st10"
              d="M399.51,226.71c0,0,1.42,3.1,2.86,9.32C402.37,236.04,399.55,227.74,399.51,226.71z"
            />
          </g>
          <g>
            <g>
              <path
                className="st9"
                d="M441.15,188.82c0,0-2.32-7.3-1.68-9.17c0.87-2.57,3.69-2.96,4.05-0.32
					C443.87,181.98,443.42,183.14,441.15,188.82z"
              />
              <path
                className="st10"
                d="M441.93,179.01c0,0,0.18,3.41-0.75,9.72C441.19,188.74,441.59,179.98,441.93,179.01z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M431.39,198.3c0,0-4.83-5.95-4.92-7.92c-0.13-2.71,2.35-4.11,3.65-1.77
					C431.42,190.94,431.43,192.18,431.39,198.3z"
              />
              <path
                className="st10"
                d="M428.53,188.88c0,0,1.42,3.1,2.86,9.32C431.39,198.21,428.57,189.91,428.53,188.88z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M432.76,196.58c0,0,7.41,1.95,9.24,1.22c2.52-1,2.78-3.83,0.11-4.06
					C439.45,193.52,438.32,194.03,432.76,196.58z"
              />
              <path
                className="st10"
                d="M442.51,195.31c0,0-3.41-0.01-9.67,1.23C432.84,196.54,441.56,195.7,442.51,195.31z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M423.55,206.57c0,0,7.41,1.95,9.24,1.22c2.52-1,2.78-3.83,0.11-4.06
					C430.25,203.51,429.11,204.02,423.55,206.57z"
              />
              <path
                className="st10"
                d="M433.31,205.3c0,0-3.41-0.01-9.67,1.23C423.64,206.53,432.36,205.69,433.31,205.3z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M413.41,219.25c0,0,7.15,2.75,9.05,2.23c2.62-0.72,3.18-3.51,0.56-4.02
					C420.4,216.94,419.22,217.32,413.41,219.25z"
              />
              <path
                className="st10"
                d="M423.25,219.05c0,0-3.39-0.39-9.75,0.17C413.5,219.22,422.26,219.34,423.25,219.05z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M404.28,232.51c0,0,7.15,2.75,9.05,2.23c2.62-0.72,3.18-3.51,0.56-4.02
					C411.27,230.21,410.08,230.59,404.28,232.51z"
              />
              <path
                className="st10"
                d="M414.11,232.32c0,0-3.39-0.39-9.75,0.17C404.36,232.48,413.13,232.6,414.11,232.32z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M442.78,187.19c0,0,7.41,1.95,9.24,1.22c2.52-1,2.78-3.83,0.11-4.06
					C449.47,184.13,448.34,184.64,442.78,187.19z"
              />
              <path
                className="st10"
                d="M452.53,185.92c0,0-3.41-0.01-9.67,1.23C442.86,187.15,451.58,186.31,452.53,185.92z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M453.32,178.81c0,0,7.41,1.95,9.24,1.22c2.52-1,2.78-3.83,0.11-4.06
					C460.02,175.76,458.88,176.26,453.32,178.81z"
              />
              <path
                className="st10"
                d="M463.08,177.54c0,0-3.41-0.01-9.67,1.23C453.4,178.78,462.13,177.94,463.08,177.54z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M465.43,169.99c0,0,7.41,1.95,9.24,1.22c2.52-1,2.78-3.83,0.11-4.06
					C472.12,166.94,470.99,167.45,465.43,169.99z"
              />
              <path
                className="st10"
                d="M475.18,168.73c0,0-3.41-0.01-9.67,1.23C465.51,169.96,474.23,169.12,475.18,168.73z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M476.86,163.6c0,0,5.77,3.28,7.47,3.08c2.34-0.28,3.18-2.59,1-3.37
					C483.15,162.53,482.09,162.71,476.86,163.6z"
              />
              <path
                className="st10"
                d="M485.32,164.71c0,0-2.85-0.77-8.37-1.13C476.94,163.59,484.43,164.83,485.32,164.71z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M484.86,159.96c0,0,6.57,0.98,8.08,0.19c2.08-1.1,2.03-3.56-0.28-3.51
					C490.34,156.7,489.41,157.25,484.86,159.96z"
              />
              <path
                className="st10"
                d="M493.14,157.96c0,0-2.94,0.31-8.22,1.97C484.93,159.92,492.36,158.38,493.14,157.96z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M421.03,209.91c0,0-4.83-5.95-4.92-7.92c-0.13-2.71,2.35-4.11,3.65-1.77
					C421.07,202.55,421.07,203.79,421.03,209.91z"
              />
              <path
                className="st10"
                d="M418.18,200.5c0,0,1.42,3.1,2.86,9.32C421.04,209.82,418.21,201.52,418.18,200.5z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M412.03,221.16c0,0-4.83-5.95-4.92-7.92c-0.13-2.71,2.35-4.11,3.65-1.77
					C412.07,213.8,412.07,215.04,412.03,221.16z"
              />
              <path
                className="st10"
                d="M409.18,211.74c0,0,1.42,3.1,2.86,9.32C412.04,221.07,409.21,212.77,409.18,211.74z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M451.91,179.77c0,0-2.32-7.3-1.68-9.17c0.87-2.57,3.69-2.96,4.05-0.32
					C454.63,172.93,454.18,174.09,451.91,179.77z"
              />
              <path
                className="st10"
                d="M452.69,169.96c0,0,0.18,3.41-0.75,9.72C451.94,179.68,452.34,170.93,452.69,169.96z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M464.23,171.67c0,0-2.32-7.3-1.68-9.17c0.87-2.57,3.69-2.96,4.05-0.32
					C466.94,164.83,466.49,165.99,464.23,171.67z"
              />
              <path
                className="st10"
                d="M465,161.87c0,0,0.18,3.41-0.75,9.72C464.26,171.59,464.66,162.83,465,161.87z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M475.13,164.65c0,0-1.94-6.13-1.41-7.69c0.73-2.16,3.1-2.49,3.39-0.27
					C477.41,158.91,477.03,159.88,475.13,164.65z"
              />
              <path
                className="st10"
                d="M475.78,156.42c0,0,0.15,2.86-0.63,8.16C475.15,164.57,475.49,157.23,475.78,156.42z"
              />
            </g>
            <path
              className="st10"
              d="M386.04,268.39c0.07-0.2,7.39-19.79,23.22-42.85c14.61-21.27,39.66-49.92,76.04-65.61l-0.09-0.2
				c-16.09,6.94-31.32,16.96-45.28,29.79c-11.17,10.27-21.55,22.34-30.85,35.9c-15.84,23.08-23.17,42.69-23.24,42.89L386.04,268.39z
				"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              className="st8"
              d="M400.18,251.83c0,0-1.59-3.95-1.31-5c0.38-1.46,1.92-1.79,2.23-0.34
				C401.4,247.93,401.2,248.59,400.18,251.83z"
            />
            <path
              className="st6"
              d="M400.2,246.37c0,0,0.24,1.88-0.01,5.41C400.2,251.79,400.05,246.92,400.2,246.37z"
            />
          </g>
          <g>
            <g>
              <path
                className="st8"
                d="M428.49,233.08c0,0-0.03-4.25,0.61-5.14c0.88-1.22,2.44-0.97,2.2,0.49
					C431.05,229.9,430.62,230.44,428.49,233.08z"
              />
              <path
                className="st6"
                d="M430.51,228.01c0,0-0.46,1.84-1.99,5.03C428.52,233.04,430.17,228.47,430.51,228.01z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M421.76,236.51c0,0-1.59-3.95-1.31-5c0.38-1.46,1.92-1.79,2.23-0.34
					C422.98,232.61,422.78,233.27,421.76,236.51z"
              />
              <path
                className="st6"
                d="M421.79,231.05c0,0,0.24,1.88-0.01,5.41C421.78,236.46,421.64,231.6,421.79,231.05z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M422.77,235.83c0,0,3.61,2.24,4.7,2.16c1.5-0.12,2.1-1.58,0.72-2.13
					C426.82,235.3,426.13,235.38,422.77,235.83z"
              />
              <path
                className="st6"
                d="M428.15,236.75c0,0-1.81-0.57-5.33-0.93C422.82,235.82,427.58,236.8,428.15,236.75z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M416.25,239.62c0,0,3.61,2.25,4.7,2.16c1.5-0.12,2.1-1.58,0.72-2.13
					C420.3,239.09,419.62,239.18,416.25,239.62z"
              />
              <path
                className="st6"
                d="M421.63,240.54c0,0-1.81-0.57-5.33-0.93C416.3,239.61,421.07,240.59,421.63,240.54z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M408.8,244.69c0,0,3.35,2.63,4.44,2.66c1.51,0.05,2.26-1.34,0.95-2.04
					C412.88,244.6,412.19,244.61,408.8,244.69z"
              />
              <path
                className="st6"
                d="M414.05,246.19c0,0-1.74-0.76-5.2-1.51C408.85,244.68,413.48,246.18,414.05,246.19z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M401.78,250.23c0,0,3.35,2.63,4.44,2.66c1.51,0.05,2.26-1.34,0.95-2.04
					C405.87,250.15,405.18,250.16,401.78,250.23z"
              />
              <path
                className="st6"
                d="M407.03,251.73c0,0-1.74-0.76-5.2-1.51C401.83,250.23,406.46,251.72,407.03,251.73z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M429.62,232.48c0,0,3.61,2.24,4.7,2.16c1.5-0.12,2.1-1.58,0.72-2.13
					C433.67,231.96,432.98,232.04,429.62,232.48z"
              />
              <path
                className="st6"
                d="M435,233.4c0,0-1.81-0.57-5.33-0.93C429.67,232.48,434.43,233.46,435,233.4z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M436.58,229.76c0,0,3.61,2.24,4.7,2.16c1.5-0.12,2.1-1.58,0.72-2.13
					C440.63,229.24,439.95,229.32,436.58,229.76z"
              />
              <path
                className="st6"
                d="M441.96,230.69c0,0-1.81-0.57-5.33-0.93C436.63,229.76,441.4,230.74,441.96,230.69z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M444.44,227.07c0,0,3.61,2.24,4.7,2.16c1.5-0.12,2.1-1.58,0.72-2.13
					C448.5,226.54,447.81,226.62,444.44,227.07z"
              />
              <path
                className="st6"
                d="M449.83,227.99c0,0-1.81-0.57-5.33-0.93C444.49,227.06,449.26,228.04,449.83,227.99z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M451.56,225.54c0,0,2.52,2.69,3.46,2.86c1.28,0.24,2.11-0.85,1.08-1.62
					C455.07,226.01,454.47,225.92,451.56,225.54z"
              />
              <path
                className="st6"
                d="M455.86,227.52c0,0-1.39-0.88-4.26-1.97C451.6,225.55,455.37,227.43,455.86,227.52z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M456.39,224.92c0,0,3.32,1.6,4.25,1.42c1.28-0.24,1.66-1.56,0.42-1.91
					C459.83,224.09,459.25,224.23,456.39,224.92z"
              />
              <path
                className="st6"
                d="M461.12,225.21c0,0-1.61-0.32-4.68-0.3C456.43,224.91,460.63,225.31,461.12,225.21z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M414.37,240.98c0,0-1.59-3.95-1.31-5c0.38-1.46,1.92-1.79,2.23-0.34
					C415.59,237.08,415.39,237.74,414.37,240.98z"
              />
              <path
                className="st6"
                d="M414.39,235.52c0,0,0.24,1.88-0.01,5.41C414.38,240.93,414.24,236.07,414.39,235.52z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M407.76,245.47c0,0-1.59-3.95-1.31-5c0.38-1.46,1.92-1.79,2.23-0.34
					C408.98,241.57,408.77,242.23,407.76,245.47z"
              />
              <path
                className="st6"
                d="M407.78,240.01c0,0,0.24,1.88-0.01,5.41C407.77,245.42,407.63,240.56,407.78,240.01z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M435.68,230.04c0,0-0.03-4.25,0.61-5.14c0.88-1.22,2.44-0.97,2.2,0.49
					C438.24,226.86,437.81,227.4,435.68,230.04z"
              />
              <path
                className="st6"
                d="M437.69,224.97c0,0-0.46,1.84-1.99,5.03C435.71,230,437.35,225.42,437.69,224.97z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M443.53,227.76c0,0-0.03-4.25,0.61-5.14c0.88-1.22,2.44-0.97,2.2,0.49
					C446.09,224.58,445.66,225.12,443.53,227.76z"
              />
              <path
                className="st6"
                d="M445.55,222.69c0,0-0.46,1.84-1.99,5.03C443.56,227.72,445.21,223.14,445.55,222.69z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M450.46,225.81c0,0-0.03-3.57,0.51-4.31c0.74-1.02,2.05-0.81,1.84,0.41
					C452.61,223.14,452.25,223.6,450.46,225.81z"
              />
              <path
                className="st6"
                d="M452.16,221.56c0,0-0.39,1.54-1.67,4.22C450.49,225.78,451.87,221.94,452.16,221.56z"
              />
            </g>
            <path
              className="st6"
              d="M386.24,266.28c0.07-0.09,7.16-9.29,19.32-18.93c11.23-8.89,29.2-20,51.06-22.37l-0.01-0.12
				c-9.67,1.05-19.39,3.87-28.89,8.4c-7.6,3.62-15.08,8.33-22.24,14c-12.18,9.65-19.27,18.86-19.34,18.95L386.24,266.28z"
            />
          </g>
        </g>
      </g>
      <g>
        <path
          className="st7"
          d="M115,133.84c-0.37,0-0.74-0.04-1.09-0.12l0.25-1.08c0.28,0.06,0.56,0.1,0.85,0.1l0.12,0l0.03,1.11L115,133.84z
		 M116.38,133.64l-0.32-1.06c0.31-0.09,0.61-0.22,0.88-0.39l0.57,0.95C117.17,133.35,116.79,133.52,116.38,133.64z M112.74,133.28
		c-0.37-0.19-0.71-0.44-1.02-0.72l0.75-0.82c0.24,0.22,0.5,0.4,0.79,0.55L112.74,133.28z M118.5,132.36l-0.8-0.77
		c0.22-0.23,0.42-0.49,0.58-0.77l0.97,0.54C119.04,131.72,118.79,132.06,118.5,132.36z M110.91,131.61
		c-0.23-0.35-0.41-0.73-0.54-1.13l1.05-0.34c0.1,0.31,0.24,0.6,0.42,0.87L110.91,131.61z M119.72,130.2l-1.07-0.28
		c0.08-0.31,0.12-0.63,0.12-0.96c0-0.15-0.01-0.3-0.03-0.45l1.1-0.13c0.02,0.19,0.03,0.38,0.03,0.58
		C119.88,129.38,119.82,129.8,119.72,130.2z M110.14,129.26c-0.01-0.1-0.01-0.19-0.01-0.29c0-0.32,0.03-0.64,0.09-0.95l1.09,0.21
		c-0.05,0.24-0.07,0.49-0.07,0.74c0,0.08,0,0.15,0.01,0.23L110.14,129.26z M118.5,127.58c-0.12-0.3-0.27-0.58-0.47-0.84l0.89-0.66
		c0.25,0.34,0.45,0.71,0.6,1.09L118.5,127.58z M111.62,127.32l-1-0.49c0.18-0.37,0.41-0.72,0.69-1.04l0.84,0.72
		C111.94,126.76,111.76,127.03,111.62,127.32z M117.37,126.04c-0.25-0.2-0.53-0.37-0.82-0.51l0.46-1.01
		c0.38,0.17,0.74,0.39,1.06,0.66L117.37,126.04z M112.87,125.87l-0.63-0.91c0.35-0.24,0.72-0.43,1.11-0.57l0.38,1.04
		C113.42,125.54,113.14,125.68,112.87,125.87z M115.63,125.26c-0.31-0.05-0.64-0.07-0.97-0.04l-0.09-1.1
		c0.41-0.03,0.84-0.02,1.24,0.05L115.63,125.26z"
        />
        <path
          className="st7"
          d="M117.1,128.97c0,1.16-0.94,2.1-2.1,2.1c-1.16,0-2.1-0.94-2.1-2.1c0-1.16,0.94-2.1,2.1-2.1
		C116.16,126.87,117.1,127.81,117.1,128.97z"
        />
      </g>
      <path
        className="st7"
        d="M237.27,56.66l-0.16-1.3c0.37-0.05,0.73-0.15,1.07-0.3l0.53,1.2C238.24,56.46,237.76,56.6,237.27,56.66z
	 M235.78,56.63c-0.49-0.08-0.97-0.23-1.42-0.45l0.57-1.18c0.34,0.16,0.69,0.28,1.06,0.34L235.78,56.63z M239.96,55.47l-0.85-0.99
	c0.28-0.24,0.53-0.52,0.74-0.84l1.09,0.72C240.67,54.77,240.34,55.15,239.96,55.47z M233.13,55.34c-0.37-0.34-0.69-0.72-0.94-1.15
	l1.12-0.67c0.19,0.32,0.43,0.61,0.7,0.86L233.13,55.34z M241.58,53l-1.25-0.37c0.11-0.35,0.16-0.72,0.16-1.1h1.31
	C241.79,52.03,241.72,52.53,241.58,53z M231.61,52.81c-0.11-0.42-0.16-0.85-0.16-1.28l0-0.19l1.3,0.04l0,0.15
	c0,0.33,0.04,0.65,0.12,0.96L231.61,52.81z M240.4,50.71c-0.08-0.36-0.21-0.72-0.39-1.04l1.14-0.63c0.24,0.44,0.42,0.91,0.52,1.4
	L240.4,50.71z M232.96,50.29l-1.24-0.42c0.16-0.47,0.39-0.92,0.68-1.33l1.07,0.75C233.25,49.6,233.08,49.94,232.96,50.29z
	 M239.33,48.78c-0.27-0.26-0.57-0.49-0.9-0.66l0.62-1.15c0.44,0.23,0.84,0.53,1.2,0.88L239.33,48.78z M234.23,48.49l-0.81-1.03
	c0.39-0.31,0.83-0.56,1.29-0.74l0.48,1.21C234.85,48.07,234.53,48.26,234.23,48.49z M237.39,47.74c-0.36-0.08-0.74-0.1-1.11-0.06
	l-0.11-1.3c0.49-0.04,1.01-0.02,1.49,0.08L237.39,47.74z"
      />
      <path
        className="st7"
        d="M368.06,76.01l-0.1-0.81c0.23-0.03,0.46-0.09,0.67-0.19l0.33,0.75C368.67,75.89,368.37,75.97,368.06,76.01z
	 M367.13,76c-0.31-0.05-0.61-0.15-0.89-0.28l0.36-0.74c0.21,0.1,0.43,0.17,0.66,0.21L367.13,76z M369.75,75.27l-0.53-0.62
	c0.18-0.15,0.33-0.33,0.46-0.52l0.68,0.45C370.2,74.83,369.99,75.07,369.75,75.27z M365.47,75.19c-0.23-0.21-0.43-0.45-0.59-0.72
	l0.7-0.42c0.12,0.2,0.27,0.38,0.44,0.54L365.47,75.19z M370.76,73.72l-0.78-0.23c0.07-0.22,0.1-0.45,0.1-0.69h0.82
	C370.89,73.12,370.85,73.43,370.76,73.72z M364.52,73.61c-0.07-0.26-0.1-0.53-0.1-0.8l0-0.12l0.82,0.03l0,0.09
	c0,0.2,0.03,0.41,0.08,0.6L364.52,73.61z M370.02,72.29c-0.05-0.23-0.13-0.45-0.24-0.65l0.72-0.39c0.15,0.27,0.26,0.57,0.33,0.87
	L370.02,72.29z M365.36,72.02l-0.78-0.26c0.1-0.29,0.24-0.57,0.42-0.83l0.67,0.47C365.54,71.59,365.44,71.8,365.36,72.02z
	 M369.36,71.08c-0.17-0.16-0.36-0.3-0.56-0.41l0.39-0.72c0.28,0.15,0.53,0.33,0.75,0.55L369.36,71.08z M366.16,70.9l-0.51-0.64
	c0.25-0.19,0.52-0.35,0.81-0.47l0.3,0.76C366.55,70.63,366.35,70.75,366.16,70.9z M368.14,70.43c-0.22-0.05-0.46-0.06-0.7-0.04
	l-0.07-0.81c0.31-0.03,0.63-0.01,0.93,0.05L368.14,70.43z"
      />
      <g>
        <path
          className="st7"
          d="M435.09,124.4c-0.34-0.03-0.67-0.09-0.99-0.19l0.27-0.88c0.26,0.08,0.53,0.13,0.8,0.16L435.09,124.4z
		 M436.1,124.38l-0.11-0.91c0.27-0.03,0.54-0.09,0.8-0.18l0.29,0.87C436.76,124.27,436.43,124.34,436.1,124.38z M433.17,123.81
		c-0.29-0.16-0.57-0.36-0.83-0.58l0.6-0.69c0.21,0.18,0.43,0.34,0.67,0.47L433.17,123.81z M438,123.74l-0.47-0.79
		c0.24-0.14,0.46-0.3,0.66-0.49l0.62,0.68C438.56,123.36,438.29,123.57,438,123.74z M431.64,122.5c-0.21-0.27-0.38-0.56-0.53-0.86
		l0.83-0.39c0.12,0.25,0.26,0.48,0.43,0.7L431.64,122.5z M439.48,122.38l-0.74-0.53c0.16-0.22,0.3-0.46,0.41-0.71l0.84,0.37
		C439.84,121.81,439.67,122.11,439.48,122.38z M430.78,120.69c-0.08-0.33-0.12-0.66-0.13-1l0.92-0.03c0.01,0.27,0.04,0.55,0.11,0.81
		L430.78,120.69z M440.29,120.54l-0.9-0.19c0.06-0.27,0.08-0.54,0.08-0.82c0-0.08,0-0.16-0.01-0.25l0.91-0.05
		c0.01,0.1,0.01,0.2,0.01,0.3C440.39,119.88,440.36,120.21,440.29,120.54z M431.62,118.84l-0.9-0.16c0.06-0.33,0.15-0.66,0.28-0.97
		l0.85,0.34C431.74,118.31,431.67,118.57,431.62,118.84z M439.33,118.49c-0.07-0.26-0.17-0.52-0.3-0.76l0.81-0.42
		c0.15,0.3,0.28,0.61,0.37,0.94L439.33,118.49z M432.23,117.33l-0.76-0.51c0.19-0.28,0.41-0.54,0.65-0.78l0.64,0.66
		C432.56,116.89,432.38,117.11,432.23,117.33z M438.59,117.04c-0.17-0.21-0.37-0.41-0.58-0.58l0.58-0.71
		c0.26,0.21,0.5,0.45,0.71,0.71L438.59,117.04z M433.4,116.19l-0.49-0.77c0.28-0.18,0.59-0.33,0.9-0.45l0.32,0.86
		C433.88,115.92,433.63,116.05,433.4,116.19z M437.32,116.01c-0.24-0.12-0.5-0.22-0.76-0.29l0.24-0.89
		c0.33,0.09,0.64,0.21,0.94,0.36L437.32,116.01z M434.92,115.62l-0.14-0.91c0.33-0.05,0.67-0.06,1.01-0.05l-0.05,0.91
		C435.47,115.57,435.19,115.58,434.92,115.62z"
        />
        <path
          className="st7"
          d="M437.66,119.54c0,1.18-0.96,2.15-2.15,2.15c-1.18,0-2.15-0.96-2.15-2.15c0-1.18,0.96-2.15,2.15-2.15
		C436.7,117.39,437.66,118.35,437.66,119.54z"
        />
      </g>
      <path
        className="st1"
        d="M196.7,279.47c-14.54-0.04-41.33,0.7-38.02-3.03c3.31-3.72,20.76-0.08,35.07-1.32
	c14.31-1.25,27.26-2.68,41.89-0.84c14.63,1.84,10.65,3.96,4.23,4.56c-11.8,1.1-13.89,1.57-20.74,0.97
	C199.1,278.04,202.36,279.48,196.7,279.47z"
      />
      <g>
        <path
          className="st8"
          d="M386.61,270.46h-237.6c-5.6,0-10.15-4.54-10.15-10.15v-150.9c0-5.6,4.54-10.15,10.15-10.15h237.6
		c5.6,0,10.15,4.54,10.15,10.15v150.9C396.76,265.92,392.22,270.46,386.61,270.46z"
        />
        <rect
          x="155.76"
          y="107.68"
          className="st11"
          width="223.75"
          height="154.28"
        />
      </g>
      <path className="st12" d="M244.82,99.53" />
      <g>
        <rect
          x="315.82"
          y="123.45"
          className="st13"
          width="31.77"
          height="138.52"
        />
        <rect
          x="347.82"
          y="123.45"
          className="st13"
          width="31.77"
          height="138.52"
        />
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M185.03,155.2v-0.7h-1.19v-0.23l1.14-1.64h0.37v1.59h0.36v0.27h-0.36v0.7H185.03z M185.03,154.23v-0.86
								c0-0.13,0-0.27,0.01-0.4h-0.01c-0.08,0.15-0.14,0.26-0.21,0.38l-0.63,0.87v0.01H185.03z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M217.43,152.93h-0.98l-0.1,0.66c0.06-0.01,0.11-0.01,0.21-0.01c0.2,0,0.39,0.04,0.55,0.14
								c0.2,0.12,0.37,0.34,0.37,0.66c0,0.5-0.4,0.88-0.95,0.88c-0.28,0-0.52-0.08-0.64-0.16l0.09-0.26
								c0.11,0.06,0.32,0.14,0.55,0.14c0.33,0,0.61-0.21,0.61-0.56c0-0.33-0.23-0.57-0.74-0.57c-0.15,0-0.26,0.02-0.35,0.03
								l0.17-1.23h1.23V152.93z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M249.39,152.88c-0.07,0-0.16,0-0.26,0.02c-0.54,0.09-0.83,0.49-0.89,0.91h0.01
								c0.12-0.16,0.34-0.3,0.62-0.3c0.45,0,0.77,0.33,0.77,0.83c0,0.47-0.32,0.9-0.85,0.9c-0.55,0-0.91-0.43-0.91-1.09
								c0-0.5,0.18-0.9,0.43-1.15c0.21-0.21,0.5-0.34,0.82-0.38c0.1-0.02,0.19-0.02,0.25-0.02V152.88z M249.29,154.36
								c0-0.37-0.21-0.59-0.53-0.59c-0.21,0-0.4,0.13-0.5,0.32c-0.02,0.04-0.04,0.09-0.04,0.15c0.01,0.42,0.2,0.73,0.56,0.73
								C249.09,154.97,249.29,154.73,249.29,154.36z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M281.43,152.64v0.23l-1.12,2.33h-0.36l1.11-2.27v-0.01h-1.25v-0.29H281.43z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M311.71,154.55c0-0.32,0.19-0.55,0.51-0.69l0-0.01c-0.28-0.13-0.41-0.35-0.41-0.58
								c0-0.41,0.34-0.68,0.79-0.68c0.5,0,0.74,0.31,0.74,0.63c0,0.22-0.11,0.45-0.42,0.6v0.01c0.32,0.13,0.52,0.35,0.52,0.66
								c0,0.45-0.38,0.74-0.87,0.74C312.04,155.24,311.71,154.92,311.71,154.55z M313.09,154.53c0-0.31-0.22-0.46-0.56-0.56
								c-0.3,0.09-0.46,0.28-0.46,0.53c-0.01,0.26,0.19,0.49,0.51,0.49C312.89,154.99,313.09,154.8,313.09,154.53z M312.14,153.25
								c0,0.26,0.19,0.39,0.49,0.47c0.22-0.08,0.39-0.23,0.39-0.46c0-0.21-0.12-0.42-0.43-0.42
								C312.29,152.84,312.14,153.03,312.14,153.25z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M344.01,154.96c0.08,0.01,0.16,0,0.28-0.01c0.2-0.03,0.39-0.11,0.54-0.25
								c0.17-0.15,0.29-0.38,0.34-0.68h-0.01c-0.14,0.17-0.35,0.28-0.6,0.28c-0.46,0-0.76-0.35-0.76-0.78
								c0-0.49,0.35-0.91,0.88-0.91c0.52,0,0.85,0.43,0.85,1.08c0,0.56-0.19,0.96-0.44,1.2c-0.2,0.19-0.47,0.31-0.74,0.34
								c-0.13,0.02-0.24,0.02-0.32,0.02V154.96z M344.14,153.48c0,0.32,0.19,0.54,0.49,0.54c0.23,0,0.41-0.11,0.5-0.27
								c0.02-0.03,0.03-0.07,0.03-0.13c0-0.44-0.16-0.77-0.52-0.77C344.35,152.86,344.14,153.12,344.14,153.48z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M375.74,152.96L375.74,152.96l-0.45,0.24l-0.07-0.26l0.56-0.3h0.3v2.56h-0.34V152.96z"
                    />
                    <path
                      className="st14"
                      d="M378.71,153.89c0,0.87-0.32,1.35-0.89,1.35c-0.5,0-0.84-0.47-0.85-1.32c0-0.86,0.37-1.33,0.89-1.33
								C378.4,152.59,378.71,153.07,378.71,153.89z M377.31,153.93c0,0.67,0.21,1.04,0.52,1.04c0.35,0,0.52-0.41,0.52-1.07
								c0-0.63-0.16-1.04-0.52-1.04C377.54,152.86,377.31,153.23,377.31,153.93z"
                    />
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M183.73,180.42L183.73,180.42l-0.45,0.24l-0.07-0.26l0.56-0.3h0.3v2.56h-0.34V180.42z"
                    />
                    <path
                      className="st14"
                      d="M185.75,180.42L185.75,180.42l-0.45,0.24l-0.07-0.26l0.56-0.3h0.3v2.56h-0.34V180.42z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M247.61,180.42L247.61,180.42l-0.45,0.24l-0.07-0.26l0.56-0.3h0.3v2.56h-0.34V180.42z"
                    />
                    <path
                      className="st14"
                      d="M248.96,182.26c0.1,0.06,0.33,0.16,0.57,0.16c0.45,0,0.58-0.28,0.58-0.5c0-0.36-0.33-0.51-0.66-0.51
								h-0.19v-0.26h0.19c0.25,0,0.57-0.13,0.57-0.43c0-0.21-0.13-0.39-0.45-0.39c-0.2,0-0.4,0.09-0.51,0.17l-0.09-0.25
								c0.13-0.1,0.39-0.2,0.67-0.2c0.5,0,0.73,0.3,0.73,0.61c0,0.26-0.16,0.49-0.47,0.6v0.01c0.32,0.06,0.57,0.3,0.57,0.66
								c0,0.41-0.32,0.77-0.93,0.77c-0.29,0-0.54-0.09-0.67-0.17L248.96,182.26z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M279.55,180.42L279.55,180.42l-0.45,0.24l-0.07-0.26l0.56-0.3h0.3v2.56h-0.34V180.42z"
                    />
                    <path
                      className="st14"
                      d="M281.89,182.66v-0.7h-1.19v-0.23l1.14-1.64h0.37v1.59h0.36v0.27h-0.36v0.7H281.89z M281.89,181.69
								v-0.86c0-0.13,0-0.27,0.01-0.4h-0.01c-0.08,0.15-0.14,0.26-0.21,0.38l-0.63,0.87v0.01H281.89z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M311.58,180.42L311.58,180.42l-0.45,0.24l-0.07-0.26l0.56-0.3h0.3v2.56h-0.34V180.42z"
                    />
                    <path
                      className="st14"
                      d="M314.38,180.39h-0.98l-0.1,0.66c0.06-0.01,0.11-0.02,0.21-0.02c0.2,0,0.39,0.04,0.55,0.14
								c0.2,0.11,0.37,0.34,0.37,0.66c0,0.5-0.4,0.88-0.95,0.88c-0.28,0-0.52-0.08-0.64-0.16l0.09-0.26
								c0.11,0.06,0.32,0.14,0.55,0.14c0.33,0,0.61-0.21,0.61-0.56c0-0.33-0.23-0.57-0.74-0.57c-0.15,0-0.26,0.02-0.35,0.03
								l0.17-1.23h1.23V180.39z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M343.55,180.42L343.55,180.42l-0.45,0.24l-0.07-0.26l0.56-0.3h0.3v2.56h-0.34V180.42z"
                    />
                    <path
                      className="st14"
                      d="M345.57,180.42L345.57,180.42l-0.45,0.24l-0.07-0.26l0.56-0.3h0.3v2.56h-0.34V180.42z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M375.71,180.42L375.71,180.42l-0.45,0.24l-0.07-0.26l0.56-0.3h0.3v2.56h-0.34V180.42z"
                    />
                    <path
                      className="st14"
                      d="M378.44,180.34c-0.07,0-0.16,0-0.26,0.02c-0.54,0.09-0.83,0.49-0.89,0.91h0.01
								c0.12-0.16,0.34-0.3,0.62-0.3c0.45,0,0.77,0.33,0.77,0.83c0,0.47-0.32,0.9-0.85,0.9c-0.55,0-0.91-0.43-0.91-1.09
								c0-0.5,0.18-0.9,0.43-1.16c0.21-0.21,0.5-0.34,0.82-0.38c0.1-0.02,0.19-0.02,0.25-0.02V180.34z M378.35,181.82
								c0-0.37-0.21-0.59-0.53-0.59c-0.21,0-0.4,0.13-0.5,0.32c-0.02,0.04-0.04,0.09-0.04,0.15c0.01,0.42,0.2,0.73,0.56,0.73
								C378.14,182.44,378.35,182.19,378.35,181.82z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M215.97,180.42L215.97,180.42l-0.45,0.24l-0.07-0.26l0.56-0.3h0.3v2.56h-0.34V180.42z"
                    />
                    <path
                      className="st14"
                      d="M217.24,182.66v-0.21l0.27-0.26c0.65-0.62,0.95-0.95,0.95-1.34c0-0.26-0.13-0.5-0.51-0.5
								c-0.23,0-0.43,0.12-0.54,0.22l-0.11-0.25c0.18-0.15,0.43-0.26,0.73-0.26c0.55,0,0.78,0.38,0.78,0.74
								c0,0.47-0.34,0.85-0.88,1.38l-0.2,0.19v0.01h1.15v0.29H217.24z"
                    />
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M183.73,208.26L183.73,208.26l-0.45,0.24l-0.07-0.26l0.56-0.3h0.3v2.56h-0.34V208.26z"
                    />
                    <path
                      className="st14"
                      d="M186.66,207.94v0.23l-1.12,2.33h-0.36l1.11-2.27v-0.01h-1.25v-0.29H186.66z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M247.61,208.26L247.61,208.26l-0.45,0.24l-0.07-0.26l0.56-0.3h0.3v2.56h-0.34V208.26z"
                    />
                    <path
                      className="st14"
                      d="M249.08,210.25c0.08,0.01,0.16,0,0.28-0.01c0.2-0.03,0.39-0.11,0.54-0.25
								c0.17-0.15,0.29-0.38,0.34-0.68h-0.01c-0.14,0.17-0.35,0.28-0.6,0.28c-0.46,0-0.76-0.35-0.76-0.78
								c0-0.49,0.35-0.91,0.88-0.91c0.52,0,0.85,0.43,0.85,1.08c0,0.56-0.19,0.96-0.44,1.2c-0.2,0.19-0.47,0.31-0.74,0.34
								c-0.13,0.02-0.24,0.02-0.32,0.02V210.25z M249.2,208.78c0,0.32,0.19,0.54,0.49,0.54c0.23,0,0.41-0.11,0.5-0.27
								c0.02-0.03,0.03-0.07,0.03-0.13c0-0.44-0.16-0.77-0.52-0.77C249.41,208.16,249.2,208.42,249.2,208.78z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M278.79,210.5v-0.21l0.27-0.26c0.65-0.62,0.95-0.95,0.95-1.34c0-0.26-0.13-0.5-0.51-0.5
								c-0.23,0-0.43,0.12-0.54,0.22l-0.11-0.24c0.18-0.15,0.43-0.26,0.73-0.26c0.55,0,0.78,0.38,0.78,0.74
								c0,0.47-0.34,0.86-0.88,1.38l-0.21,0.19v0.01h1.15v0.29H278.79z"
                    />
                    <path
                      className="st14"
                      d="M282.52,209.19c0,0.87-0.32,1.35-0.89,1.35c-0.5,0-0.84-0.47-0.85-1.32c0-0.86,0.37-1.33,0.89-1.33
								C282.21,207.89,282.52,208.37,282.52,209.19z M281.13,209.23c0,0.67,0.21,1.04,0.52,1.04c0.35,0,0.52-0.41,0.52-1.07
								c0-0.63-0.16-1.04-0.52-1.04C281.35,208.16,281.13,208.53,281.13,209.23z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M310.83,210.5v-0.21l0.27-0.26c0.65-0.62,0.95-0.95,0.95-1.34c0-0.26-0.13-0.5-0.51-0.5
								c-0.23,0-0.43,0.12-0.54,0.22l-0.11-0.24c0.18-0.15,0.43-0.26,0.73-0.26c0.55,0,0.78,0.38,0.78,0.74
								c0,0.47-0.34,0.86-0.88,1.38l-0.21,0.19v0.01h1.15v0.29H310.83z"
                    />
                    <path
                      className="st14"
                      d="M313.6,208.26L313.6,208.26l-0.45,0.24l-0.07-0.26l0.56-0.3h0.3v2.56h-0.34V208.26z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M342.8,210.5v-0.21l0.27-0.26c0.65-0.62,0.95-0.95,0.95-1.34c0-0.26-0.13-0.5-0.51-0.5
								c-0.23,0-0.43,0.12-0.54,0.22l-0.11-0.24c0.18-0.15,0.43-0.26,0.73-0.26c0.55,0,0.78,0.38,0.78,0.74
								c0,0.47-0.34,0.86-0.88,1.38l-0.21,0.19v0.01h1.15v0.29H342.8z"
                    />
                    <path
                      className="st14"
                      d="M344.82,210.5v-0.21l0.27-0.26c0.65-0.62,0.95-0.95,0.95-1.34c0-0.26-0.13-0.5-0.51-0.5
								c-0.23,0-0.43,0.12-0.54,0.22l-0.11-0.24c0.18-0.15,0.43-0.26,0.73-0.26c0.55,0,0.78,0.38,0.78,0.74
								c0,0.47-0.34,0.86-0.88,1.38l-0.2,0.19v0.01h1.15v0.29H344.82z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M374.96,210.5v-0.21l0.27-0.26c0.65-0.62,0.95-0.95,0.95-1.34c0-0.26-0.13-0.5-0.51-0.5
								c-0.23,0-0.43,0.12-0.54,0.22l-0.11-0.24c0.18-0.15,0.43-0.26,0.73-0.26c0.55,0,0.78,0.38,0.78,0.74
								c0,0.47-0.34,0.86-0.88,1.38l-0.21,0.19v0.01h1.15v0.29H374.96z"
                    />
                    <path
                      className="st14"
                      d="M377.06,210.1c0.1,0.06,0.33,0.16,0.57,0.16c0.45,0,0.58-0.28,0.58-0.5c0-0.36-0.33-0.51-0.66-0.51
								h-0.19v-0.26h0.19c0.25,0,0.57-0.13,0.57-0.43c0-0.21-0.13-0.39-0.45-0.39c-0.2,0-0.4,0.09-0.51,0.17l-0.09-0.25
								c0.13-0.1,0.39-0.2,0.67-0.2c0.5,0,0.73,0.3,0.73,0.61c0,0.26-0.16,0.49-0.47,0.6v0.01c0.32,0.06,0.57,0.3,0.57,0.66
								c0,0.41-0.32,0.77-0.93,0.77c-0.29,0-0.54-0.09-0.67-0.17L377.06,210.1z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M215.97,208.26L215.97,208.26l-0.45,0.24l-0.07-0.26l0.56-0.3h0.3v2.56h-0.34V208.26z"
                    />
                    <path
                      className="st14"
                      d="M217.21,209.85c0-0.32,0.19-0.55,0.51-0.69l0-0.01c-0.28-0.13-0.41-0.35-0.41-0.58
								c0-0.41,0.34-0.68,0.79-0.68c0.5,0,0.74,0.31,0.74,0.63c0,0.22-0.11,0.45-0.42,0.6v0.01c0.32,0.13,0.52,0.35,0.52,0.66
								c0,0.45-0.38,0.74-0.87,0.74C217.53,210.54,217.21,210.22,217.21,209.85z M218.59,209.83c0-0.31-0.22-0.46-0.56-0.56
								c-0.3,0.09-0.46,0.28-0.46,0.53c-0.01,0.26,0.19,0.49,0.51,0.49C218.38,210.29,218.59,210.1,218.59,209.83z M217.63,208.55
								c0,0.26,0.19,0.39,0.49,0.47c0.22-0.08,0.39-0.23,0.39-0.46c0-0.21-0.12-0.42-0.43-0.42
								C217.79,208.14,217.63,208.33,217.63,208.55z"
                    />
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M182.98,238.21V238l0.27-0.26c0.65-0.62,0.95-0.95,0.95-1.34c0-0.26-0.13-0.5-0.51-0.5
								c-0.23,0-0.43,0.12-0.54,0.22l-0.11-0.25c0.18-0.15,0.43-0.26,0.73-0.26c0.55,0,0.78,0.38,0.78,0.74
								c0,0.47-0.34,0.86-0.88,1.38l-0.21,0.19v0.01h1.15v0.29H182.98z"
                    />
                    <path
                      className="st14"
                      d="M186.07,238.21v-0.7h-1.19v-0.23l1.14-1.64h0.37v1.59h0.36v0.27h-0.36v0.7H186.07z M186.07,237.24
								v-0.86c0-0.13,0-0.27,0.01-0.4h-0.01c-0.08,0.15-0.14,0.26-0.21,0.38l-0.63,0.87v0.01H186.07z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M246.86,238.21V238l0.27-0.26c0.65-0.62,0.95-0.95,0.95-1.34c0-0.26-0.13-0.5-0.51-0.5
								c-0.23,0-0.43,0.12-0.54,0.22l-0.11-0.25c0.18-0.15,0.43-0.26,0.73-0.26c0.55,0,0.78,0.38,0.78,0.74
								c0,0.47-0.34,0.86-0.88,1.38l-0.21,0.19v0.01h1.15v0.29H246.86z"
                    />
                    <path
                      className="st14"
                      d="M250.34,235.9c-0.07,0-0.16,0-0.26,0.02c-0.54,0.09-0.83,0.49-0.89,0.91h0.01
								c0.12-0.16,0.34-0.3,0.62-0.3c0.45,0,0.77,0.33,0.77,0.83c0,0.47-0.32,0.9-0.85,0.9c-0.55,0-0.91-0.43-0.91-1.09
								c0-0.5,0.18-0.9,0.43-1.16c0.21-0.21,0.5-0.34,0.82-0.38c0.1-0.02,0.19-0.02,0.25-0.02V235.9z M250.24,237.37
								c0-0.37-0.21-0.59-0.53-0.59c-0.21,0-0.4,0.13-0.5,0.32c-0.02,0.04-0.04,0.09-0.04,0.15c0.01,0.42,0.2,0.73,0.56,0.73
								C250.04,237.99,250.24,237.74,250.24,237.37z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M278.79,238.21V238l0.27-0.26c0.65-0.62,0.95-0.95,0.95-1.34c0-0.26-0.13-0.5-0.51-0.5
								c-0.23,0-0.43,0.12-0.54,0.22l-0.11-0.25c0.18-0.15,0.43-0.26,0.73-0.26c0.55,0,0.78,0.38,0.78,0.74
								c0,0.47-0.34,0.86-0.88,1.38l-0.21,0.19v0.01h1.15v0.29H278.79z"
                    />
                    <path
                      className="st14"
                      d="M282.48,235.65v0.23l-1.12,2.33H281l1.11-2.27v-0.01h-1.25v-0.29H282.48z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M215.22,238.21V238l0.27-0.26c0.65-0.62,0.95-0.95,0.95-1.34c0-0.26-0.13-0.5-0.51-0.5
								c-0.23,0-0.43,0.12-0.54,0.22l-0.11-0.25c0.18-0.15,0.43-0.26,0.73-0.26c0.55,0,0.78,0.38,0.78,0.74
								c0,0.47-0.34,0.86-0.88,1.38l-0.21,0.19v0.01h1.15v0.29H215.22z"
                    />
                    <path
                      className="st14"
                      d="M218.77,235.94h-0.98l-0.1,0.66c0.06-0.01,0.11-0.02,0.21-0.02c0.2,0,0.39,0.04,0.55,0.14
								c0.2,0.11,0.37,0.34,0.37,0.66c0,0.5-0.4,0.88-0.95,0.88c-0.28,0-0.52-0.08-0.64-0.16l0.09-0.26
								c0.11,0.06,0.32,0.14,0.55,0.14c0.33,0,0.61-0.21,0.61-0.56c0-0.33-0.23-0.57-0.74-0.57c-0.15,0-0.26,0.02-0.35,0.03
								l0.17-1.23h1.23V235.94z"
                    />
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M312.55,125.12L312.55,125.12l-0.45,0.24l-0.07-0.26l0.56-0.3h0.3v2.56h-0.34V125.12z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M343.8,127.36v-0.21l0.27-0.26c0.65-0.62,0.95-0.95,0.95-1.34c0-0.26-0.13-0.5-0.51-0.5
								c-0.23,0-0.43,0.12-0.54,0.22l-0.11-0.24c0.18-0.15,0.43-0.26,0.73-0.26c0.55,0,0.78,0.38,0.78,0.75
								c0,0.47-0.34,0.86-0.88,1.38l-0.21,0.19v0.01h1.15v0.29H343.8z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st14"
                      d="M376.05,126.96c0.1,0.06,0.33,0.16,0.57,0.16c0.45,0,0.58-0.28,0.58-0.5c0-0.36-0.33-0.51-0.66-0.51
								h-0.19v-0.26h0.19c0.25,0,0.57-0.13,0.57-0.43c0-0.21-0.13-0.39-0.45-0.39c-0.21,0-0.4,0.09-0.51,0.17l-0.09-0.25
								c0.13-0.1,0.39-0.2,0.67-0.2c0.5,0,0.73,0.3,0.73,0.61c0,0.26-0.16,0.49-0.47,0.6v0.01c0.32,0.06,0.57,0.3,0.57,0.66
								c0,0.41-0.32,0.77-0.93,0.77c-0.29,0-0.54-0.09-0.67-0.17L376.05,126.96z"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <path
                className="st14"
                d="M310.75,238.21V238l0.27-0.26c0.65-0.62,0.95-0.95,0.95-1.33c0-0.26-0.13-0.5-0.51-0.5
					c-0.23,0-0.42,0.12-0.54,0.22l-0.11-0.24c0.18-0.15,0.43-0.26,0.72-0.26c0.55,0,0.78,0.38,0.78,0.74c0,0.47-0.34,0.85-0.88,1.37
					l-0.2,0.19v0.01h1.15v0.29H310.75z"
              />
              <path
                className="st14"
                d="M312.76,237.56c0-0.32,0.19-0.55,0.51-0.68l0-0.01c-0.28-0.13-0.4-0.35-0.4-0.57
					c0-0.4,0.34-0.68,0.79-0.68c0.5,0,0.74,0.31,0.74,0.63c0,0.22-0.11,0.45-0.42,0.6v0.01c0.32,0.13,0.51,0.35,0.51,0.66
					c0,0.44-0.38,0.74-0.87,0.74C313.08,238.25,312.76,237.93,312.76,237.56z M314.14,237.54c0-0.31-0.22-0.46-0.56-0.56
					c-0.3,0.09-0.46,0.28-0.46,0.53c-0.01,0.26,0.18,0.49,0.51,0.49C313.94,238,314.14,237.81,314.14,237.54z M313.19,236.27
					c0,0.26,0.19,0.39,0.49,0.47c0.22-0.07,0.39-0.23,0.39-0.46c0-0.2-0.12-0.42-0.43-0.42
					C313.34,235.86,313.19,236.05,313.19,236.27z"
              />
            </g>
            <g>
              <path
                className="st14"
                d="M342.78,238.21V238l0.27-0.26c0.65-0.62,0.95-0.95,0.95-1.33c0-0.26-0.13-0.5-0.51-0.5
					c-0.23,0-0.42,0.12-0.54,0.22l-0.11-0.24c0.18-0.15,0.43-0.26,0.72-0.26c0.55,0,0.78,0.38,0.78,0.74c0,0.47-0.34,0.85-0.88,1.37
					l-0.2,0.19v0.01h1.15v0.29H342.78z"
              />
              <path
                className="st14"
                d="M344.99,237.97c0.08,0.01,0.16,0,0.28-0.01c0.2-0.03,0.39-0.11,0.53-0.25c0.17-0.15,0.29-0.38,0.34-0.68
					h-0.01c-0.14,0.17-0.35,0.27-0.6,0.27c-0.46,0-0.75-0.35-0.75-0.78c0-0.48,0.35-0.91,0.87-0.91c0.52,0,0.85,0.42,0.85,1.08
					c0,0.56-0.19,0.95-0.44,1.2c-0.2,0.19-0.47,0.31-0.74,0.34c-0.13,0.02-0.24,0.02-0.32,0.02V237.97z M345.12,236.5
					c0,0.32,0.19,0.54,0.49,0.54c0.23,0,0.41-0.11,0.5-0.27c0.02-0.03,0.03-0.07,0.03-0.13c0-0.44-0.16-0.77-0.52-0.77
					C345.33,235.88,345.12,236.13,345.12,236.5z"
              />
            </g>
            <g>
              <path
                className="st14"
                d="M374.95,237.81c0.1,0.06,0.33,0.16,0.57,0.16c0.44,0,0.58-0.28,0.58-0.49c0-0.36-0.33-0.51-0.66-0.51
					h-0.19v-0.26h0.19c0.25,0,0.57-0.13,0.57-0.43c0-0.2-0.13-0.38-0.45-0.38c-0.2,0-0.4,0.09-0.51,0.17l-0.09-0.25
					c0.13-0.1,0.39-0.2,0.67-0.2c0.5,0,0.73,0.3,0.73,0.61c0,0.26-0.16,0.49-0.47,0.6v0.01c0.31,0.06,0.57,0.3,0.57,0.66
					c0,0.41-0.32,0.77-0.93,0.77c-0.29,0-0.54-0.09-0.66-0.17L374.95,237.81z"
              />
              <path
                className="st14"
                d="M378.58,236.9c0,0.87-0.32,1.35-0.89,1.35c-0.5,0-0.84-0.47-0.84-1.31c0-0.86,0.37-1.33,0.89-1.33
					C378.27,235.61,378.58,236.09,378.58,236.9z M377.19,236.94c0,0.66,0.2,1.04,0.52,1.04c0.35,0,0.52-0.41,0.52-1.06
					c0-0.63-0.16-1.04-0.52-1.04C377.41,235.88,377.19,236.25,377.19,236.94z"
              />
            </g>
          </g>
          <g>
            <g>
              <g>
                <rect
                  x="155.74"
                  y="178.73"
                  className="st15"
                  width="223.75"
                  height="0.25"
                />
                <rect
                  x="155.74"
                  y="151.03"
                  className="st15"
                  width="223.75"
                  height="0.25"
                />
                <rect
                  x="155.74"
                  y="206.44"
                  className="st15"
                  width="223.75"
                  height="0.25"
                />
                <rect
                  x="155.74"
                  y="234.14"
                  className="st15"
                  width="223.75"
                  height="0.25"
                />
              </g>
              <rect
                x="187.58"
                y="123.45"
                className="st15"
                width="0.25"
                height="138.52"
              />
              <rect
                x="219.55"
                y="123.45"
                className="st15"
                width="0.25"
                height="138.52"
              />
              <rect
                x="251.51"
                y="123.45"
                className="st15"
                width="0.25"
                height="138.52"
              />
              <rect
                x="283.47"
                y="123.45"
                className="st15"
                width="0.25"
                height="138.52"
              />
              <rect
                x="315.44"
                y="123.45"
                className="st15"
                width="0.25"
                height="138.52"
              />
              <rect
                x="347.4"
                y="123.45"
                className="st15"
                width="0.25"
                height="138.52"
              />
            </g>
            <g>
              <rect
                x="155.84"
                y="107.69"
                className="st15"
                width="223.75"
                height="15.76"
              />
              <g>
                <g>
                  <rect
                    x="170.13"
                    y="113.23"
                    className="st16"
                    width="48.17"
                    height="1.42"
                  />
                  <rect
                    x="170.13"
                    y="116.29"
                    className="st16"
                    width="38.79"
                    height="1.42"
                  />
                </g>
                <g>
                  <path
                    className="st16"
                    d="M165.09,119h-4.96c-0.58,0-1.05-0.47-1.05-1.05v-4.96c0-0.58,0.47-1.05,1.05-1.05h4.96
							c0.58,0,1.05,0.47,1.05,1.05v4.96C166.15,118.53,165.67,119,165.09,119z M160.14,112.2c-0.44,0-0.8,0.36-0.8,0.8v4.96
							c0,0.44,0.36,0.8,0.8,0.8h4.96c0.44,0,0.8-0.36,0.8-0.8v-4.96c0-0.44-0.36-0.8-0.8-0.8H160.14z"
                  />
                  <g>
                    <rect
                      x="160.36"
                      y="113.48"
                      className="st8"
                      width="4.52"
                      height="0.46"
                    />
                    <rect
                      x="160.36"
                      y="114.65"
                      className="st8"
                      width="4.52"
                      height="0.46"
                    />
                    <rect
                      x="160.36"
                      y="115.83"
                      className="st8"
                      width="4.52"
                      height="0.46"
                    />
                    <rect
                      x="160.36"
                      y="117.01"
                      className="st8"
                      width="4.52"
                      height="0.46"
                    />
                  </g>
                </g>
                <g>
                  <path
                    className="st17"
                    d="M371.8,117.99h-40.52c-0.52,0-0.94-0.42-0.94-0.94v-4.07c0-0.52,0.42-0.94,0.94-0.94h40.52
							c0.52,0,0.94,0.42,0.94,0.94v4.07C372.74,117.57,372.32,117.99,371.8,117.99z"
                  />
                  <g>
                    <path
                      className="st7"
                      d="M367.78,116.13c-0.35,0-0.69-0.13-0.96-0.4c-0.53-0.53-0.53-1.39,0-1.92c0.53-0.53,1.39-0.53,1.92,0
								c0.53,0.53,0.53,1.39,0,1.92C368.48,116,368.13,116.13,367.78,116.13z M367.78,113.77c-0.26,0-0.52,0.1-0.71,0.29
								c-0.39,0.39-0.39,1.03,0,1.43c0.38,0.38,1.05,0.38,1.42,0c0.19-0.19,0.3-0.44,0.3-0.71c0-0.27-0.1-0.52-0.3-0.71
								C368.3,113.87,368.04,113.77,367.78,113.77z"
                    />
                    <polygon
                      className="st7"
                      points="369.31,116.55 368.5,115.74 368.74,115.49 369.56,116.31 							"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <path
            className="st18"
            d="M316.02,164.86c-0.58,0-1.22-0.06-1.92-0.18c-1.62-0.27-3.2-0.76-4.69-1.46c-1.53-0.71-2.66-1.52-3.48-2.47
			c-1.03-1.21-1.53-2.69-1.37-4.05c0.19-1.57,1.3-2.91,2.71-3.25l0.12,0.5c-1.18,0.29-2.16,1.47-2.32,2.81
			c-0.15,1.22,0.31,2.56,1.25,3.66c0.76,0.9,1.84,1.66,3.3,2.34c1.45,0.67,2.98,1.15,4.56,1.41c1.49,0.25,2.65,0.23,3.64-0.08
			c1.23-0.38,2.29-1.23,3.19-1.99c1.14-0.98,2.03-1.85,2.66-2.92c0.72-1.23,0.92-2.57,0.55-3.68c-0.34-1.04-1.16-1.95-2.58-2.86
			c-3.14-2.03-7.08-2.95-10.8-2.53l-0.06-0.51c3.9-0.44,7.85,0.48,11.13,2.61c1.52,0.98,2.41,1.98,2.79,3.13
			c0.41,1.25,0.19,2.74-0.6,4.1c-0.66,1.13-1.58,2.04-2.77,3.05c-0.94,0.8-2.05,1.69-3.37,2.09
			C317.39,164.77,316.74,164.86,316.02,164.86z"
          />
        </g>
      </g>
      <path
        className="st19"
        d="M303.27,148.46l0.85,0.06c-0.02-0.02-0.03-0.04-0.03-0.04l-0.89-0.26l0.03,0.1L303.27,148.46z"
      />
      <path
        className="st19"
        d="M300.28,99.26h-9.62l4.31,4.51C296.62,102.47,298.52,100.83,300.28,99.26z"
      />
      <path
        className="st20"
        d="M294.97,103.77l-4.31-4.51h-49.08l-0.42,1l21.85,2.09l0.28-0.08l4.16,0.35l2.5,3.6l6.94,11.33
	c0.04,0.25,0.11,0.5,0.21,0.74c2.43,5.51,6.02,16.28,6.02,16.28c0.14,0.27,0.31,0.5,0.49,0.72c0.18,0.25,0.41,0.5,0.68,0.76
	c3.11,2.91,12.31,10.35,12.31,10.35l0.2-0.36l1.68,1.58l0.42,0.56l-1.81-0.12c0,0-0.15,0.08-0.17,0.11
	c-0.14,0.22-0.12,0.71,0.02,0.93c0.01,0.02,0.13,0.1,0.13,0.1l2.77,0.18l1.04,1.24l2.34,0.46l1.28-0.58c0,0,1.26-0.22,1.33-0.33
	c0.07-0.12-0.22-0.4-0.22-0.4l-1.67-0.07l-0.03-0.05l2.72,0.18h0h0l0,0l1.54-0.23c0-0.01,0-0.02,0-0.03
	c0.1,0.01,0.42,0.02,0.43-0.07c0.01-0.1-0.32-0.16-0.42-0.18c0-0.01,0-0.02,0-0.02l-1.52-0.61l-1.5-0.1
	c-0.22-0.48-0.65-1.03-0.65-1.03l-1.3-0.96l-0.67-0.17l-0.95-0.24l-1.11-0.23l-2.29-2.41l0.27-0.48c0,0-6.37-7.29-9.9-10.42
	c-0.05-0.24-0.12-0.48-0.2-0.74c-1.29-4.12-2.01-8.81-3.57-13.32l5.46-3.45c0,0,0-0.06-0.01-0.14l2.74,1.76
	c-0.7,2.36,0.83,5.14,3.64,6.42c0.3,0.14,3.11,1.89,4.86,2.76c0.64,0.32,1.23,0.45,1.74,0.5c0.09,0.27,0.22,0.56,0.46,0.91
	c1.13,1.68,2.47,1.76,3.23,1.49c0.38-0.14,1.28-0.77,1.78-3.16c0.5-2.39,1.55-1.79,1.74-3.31c0.31-2.4-1.35-5.72-3.61-6.98
	c-1.7-0.95-4.23-1.23-5.37-1.32l-2.16-0.5c-0.4-0.09-0.81-0.05-1.17,0.1c-0.55-0.03-1.08,0.01-1.58,0.11l-2.31-1.49
	c0.22,0.04,0.39,0.09,0.39,0.09l4.6-2.91c0,0,0,0,0,0c0.25-0.14,0.51-0.32,0.78-0.53c4.09-3.27,5.84-6.39,9.03-9.67h-8.79
	C298.52,100.83,296.62,102.47,294.97,103.77z M304.09,148.48c0,0,0.02,0.02,0.03,0.04l-0.85-0.06l-0.04-0.15l-0.03-0.1
	L304.09,148.48z"
      />
      <polygon
        className="st21"
        points="331.34,99.9 333.68,100.36 333.47,99.69 332.73,99.38 332.59,99.26 329.69,99.26 "
      />
      <g>
        <g>
          <g>
            <g>
              <g>
                <rect
                  x="296.22"
                  y="109.26"
                  transform="matrix(0.6118 -0.791 0.791 0.6118 26.5632 279.9203)"
                  className="st22"
                  width="4.54"
                  height="7.26"
                />
              </g>
            </g>
            <g>
              <g id="XMLID_7_">
                <path
                  id="XMLID_35_"
                  className="st22"
                  d="M301.36,122.44c-2.83-1.58-4.03-4.81-2.69-7.22c1.34-2.41,4.72-3.09,7.55-1.51
						c0.4,0.22,5.67,2.87,6,3.16l0.48,2.7l-1.02,4.14l-2.42,2.42c-0.12-0.04-1.65,0.47-3.4-0.59
						C304.23,124.54,301.64,122.6,301.36,122.44z"
                />
              </g>
            </g>
          </g>
          <path
            className="st6"
            d="M307.87,127.09c0.94,1.77,2.25,1.93,3.03,1.7c0.39-0.11,1.34-0.7,2.08-3.09c0.74-2.39,1.71-1.71,2.05-3.23
			c0.55-2.4-0.75-5.87-2.84-7.28c-1.58-1.07-4.04-1.51-5.15-1.66l-2.07-0.64c-0.75-0.23-1.56,0.03-2.03,0.65l-1.32,1.72
			c-0.42,0.55-0.84,1.4-0.8,2.1l0.02,0.4c0.04,0.85,0.55,1.62,1.33,1.97l1.92,1.02l1.12,0.86l-0.17,0.59l-1.25-0.56l-0.12,0.64
			c0.39,0.18,0.97,0.93,1.78,1.35c0.13,0.07,0.12,0.51,0.25,0.58c0.69,0.35,1.38,0.69,1.84,0.9
			C307.39,125.65,307.41,126.21,307.87,127.09z"
          />
        </g>
        <path
          className="st18"
          d="M250.41,93.3l2.75-1.76c3.99-2.19,7.27-4.13,11.21-4.91l6.03-1.19c5.42-1.07,11.04-0.17,15.86,2.53l2.9,1.63
		c2.68,1.5,5.03,3.52,6.93,5.94l9.47,12.09c0.28,0.36,0.17,0.89-0.23,1.11l-4.83,2.66c0,0-0.78-0.26-1.05-0.22
		c-0.94,0.12-2.8,0.96-3.36,1.72c-0.15,0.21-0.18,1.03-0.18,1.03l-8.03,4.42c-1.74,0.96-3.92,0.25-4.78-1.54l-6.29-13.17l-2.09-3.79
		l-4.06-0.61l-0.28,0.07l-21.3-3.47L250.41,93.3z"
        />
        <g>
          <polygon
            className="st22"
            points="330.81,98.79 335.38,99.65 336.29,100.28 337.91,101.05 340.16,101.66 340.02,100.97 339.32,100.61 
			338.93,100.2 339.03,99.93 340.76,99.34 343.13,99.29 343.67,98.78 339.36,97.81 337.94,97.77 335.18,97.49 331.32,96.58 		"
          />
          <g>
            <g>
              <g>
                <g>
                  <path
                    className="st18"
                    d="M317.82,94.07l0.14,0.23c0.79,1.44,0.3,2.81-1.36,4.24c-3.83,3.29-5.86,6.5-10.45,9.71
							c-2.39,1.67-3.82,0.63-4.98-0.44l-0.21-0.21c-1.34-1.6-0.81-3.82,0.66-4.83c4.96-3.38,12.12-9.44,12.12-9.44
							C314.95,92.41,317.03,92.63,317.82,94.07z"
                  />
                  <path
                    className="st18"
                    d="M333.34,96.74l-1.75,3.18c0,0-11.17-0.64-15.6-1.56c-2.25-0.47-3.48-1.07-3.38-2.71l0,0
							c0.1-1.64,1.18-3.25,3.89-2.8C320.62,93.55,333.34,96.74,333.34,96.74z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              className="st22"
              d="M304.79,146.31l-0.91-0.3l-1.07-0.3l-2.37-3.05l-2.2,1.84l2.42,2.75l0.93,1.52l1.13,1.66l2.26,0.61
				l1.32-0.5c0,0,1.27-0.15,1.34-0.25c0.08-0.12-0.18-0.42-0.18-0.42l-1.64-0.17l-0.48-0.97l-0.05-0.31l-0.03-0.17L304.79,146.31z"
            />
            <g>
              <g>
                <path
                  className="st6"
                  d="M308.61,148.84l-9.36-1.23c0,0-0.16,0.08-0.18,0.1c-0.16,0.21-0.19,0.71-0.08,0.95
						c0.01,0.02,0.12,0.11,0.12,0.11l9.34,1.23L308.61,148.84z"
                />
              </g>
              <g>
                <path
                  className="st18"
                  d="M310,149.56c-0.03,0.05-0.05,0.14-0.04,0.26c0,0,0.45,0.07,0.47-0.04
						C310.45,149.65,310,149.56,310,149.56z"
                />
                <g>
                  <path
                    className="st7"
                    d="M310.05,149.55l-1.43-0.71l-0.15,1.15l1.54-0.14C309.99,149.71,310.02,149.6,310.05,149.55z"
                  />
                  <path
                    className="st7"
                    d="M308.46,149.99c-0.1-0.01-0.15-0.28-0.11-0.6c0.04-0.32,0.16-0.56,0.26-0.55c0.1,0.01,0.15,0.28,0.11,0.6
							C308.67,149.76,308.56,150,308.46,149.99z"
                  />
                </g>
              </g>
            </g>
            <path
              className="st22"
              d="M306.09,148.46c0,0,0.26,0.43,0.37,0.55c0.06,0.06,0.19,0.16,0.27,0.2c0.11,0.05,0.42,0.13,0.47,0.03
				c0.22-0.4-0.59-1.68-0.59-1.68l-1.18-1.05l-0.64-0.21l0.19,1.74L306.09,148.46z"
            />
          </g>
          <g>
            <g>
              <g>
                <path
                  className="st18"
                  d="M290.55,135.03l0.25-0.09c1.51-0.65,1.97-2.03,1.54-4.18c-0.99-4.95-1.12-10.66-2.76-16.01
						c-0.85-2.79-2.62-2.84-4.2-2.72l-0.3,0.03c-2.04,0.43-2.99,2.51-2.45,4.22c1.82,5.72,4.25,16.82,4.25,16.82
						C287.54,134.76,289.04,135.67,290.55,135.03z"
                />
                <path
                  className="st18"
                  d="M298.93,145.9l2.19-3.24c0,0-5.73-8.07-8.85-11.33c-1.59-1.66-2.75-2.38-4.09-1.43l0,0
						c-1.34,0.95-2.13,2.72-0.31,4.78C290.65,137.8,298.93,145.9,298.93,145.9z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g>
        <path
          className="st23"
          d="M248.27,163.94c0-2.95-0.02-12.78-0.02-12.78l-26.39,0.02c0,0-0.55,10.68-0.55,13.92
		c0,3.24-0.89,13.19-0.89,13.19s12.34,0.45,16.43,0c5.76-0.63,9.2-0.7,10.81-1.03C247.66,177.26,248.27,167.48,248.27,163.94z"
        />
        <g>
          <path
            className="st5"
            d="M248.47,175.28c-1.62,0.33-5.05,0.39-10.81,1.01c-4.08,0.44-16.43,0-16.43,0s-0.72-9.6-0.72-12.8
			c0-3.2,0.72-12.8,0.72-12.8h27.09c0,0-0.85,8.75-0.85,11.66C247.47,165.84,248.47,175.28,248.47,175.28z"
          />
          <g>
            <g>
              <g>
                <path
                  d="M225.48,155.91l-0.06-0.51c0.82-0.1,1.65-0.13,2.45-0.16l7.21-0.27l0.02,0.51l-7.17,0.27
						C227.09,155.78,226.27,155.81,225.48,155.91z"
                />
                <path
                  d="M225.55,156.97l-0.09-0.5c0.64-0.12,1.29-0.2,1.93-0.25c1-0.08,2.01-0.08,2.99-0.07l0.07,0l0,0.51h-0.07
						c-0.97,0-1.97-0.01-2.94,0.07C226.81,156.77,226.17,156.85,225.55,156.97z"
                />
              </g>
              <path
                d="M223.59,156.96C223.59,156.96,223.58,156.96,223.59,156.96c-0.1,0-0.16-0.04-0.21-0.08l-0.25-0.16
					c-0.15-0.09-0.45-0.28-0.45-0.58c0-0.18,0.1-0.33,0.2-0.47c0.14-0.19,0.33-0.39,0.58-0.37c0.13,0.01,0.24,0.07,0.33,0.14
					c0.33,0.22,0.5,0.47,0.51,0.74c0.01,0.19-0.07,0.37-0.22,0.48c-0.07,0.06-0.16,0.09-0.25,0.1
					C223.81,156.88,223.71,156.96,223.59,156.96z M223.57,156.74C223.57,156.74,223.57,156.74,223.57,156.74l0.03,0.01
					C223.59,156.74,223.58,156.74,223.57,156.74z M223.42,155.56c-0.13,0-0.25,0.14-0.35,0.27c-0.08,0.11-0.15,0.21-0.15,0.31
					c0,0.15,0.17,0.27,0.33,0.37l0.25,0.16c0.01,0.01,0.02,0.01,0.03,0.02l0.11-0.2c0.08,0.04,0.19,0.03,0.26-0.03
					c0.08-0.06,0.12-0.16,0.12-0.27c-0.01-0.18-0.14-0.36-0.4-0.54c-0.06-0.04-0.14-0.09-0.2-0.09
					C223.43,155.56,223.42,155.56,223.42,155.56z"
              />
            </g>
            <g>
              <g>
                <path
                  d="M224.67,160.84l-0.07-0.51c0.81-0.12,1.64-0.17,2.44-0.21l7.2-0.42l0.03,0.51l-7.16,0.42
						C226.28,160.67,225.46,160.72,224.67,160.84z"
                />
                <path
                  d="M224.77,161.9l-0.1-0.5c0.63-0.13,1.28-0.23,1.93-0.29c1.01-0.1,2.03-0.12,3.02-0.14l0.04,0.51
						c-1.01,0.02-2.03,0.04-3.01,0.13C226.01,161.67,225.38,161.77,224.77,161.9z"
                />
              </g>
              <path
                d="M223.11,162.16C223.11,162.16,223.11,162.16,223.11,162.16c-0.09,0-0.16-0.04-0.21-0.07l-0.26-0.16
					c-0.22-0.13-0.45-0.31-0.45-0.58c-0.01-0.2,0.11-0.35,0.2-0.47c0.09-0.12,0.28-0.37,0.55-0.37c0.01,0,0.02,0,0.03,0
					c0.13,0.01,0.24,0.07,0.33,0.14c0.33,0.22,0.5,0.47,0.51,0.74c0.01,0.19-0.07,0.37-0.22,0.48c-0.07,0.06-0.16,0.09-0.25,0.1
					C223.33,162.08,223.23,162.16,223.11,162.16z M223.09,161.93C223.09,161.94,223.09,161.94,223.09,161.93l0.03,0.01
					C223.11,161.94,223.1,161.94,223.09,161.93z M222.95,160.76c-0.13,0-0.25,0.14-0.35,0.27c-0.08,0.11-0.15,0.21-0.15,0.31
					c0,0.15,0.17,0.27,0.33,0.37l0.25,0.16c0.01,0,0.02,0.01,0.03,0.02l0.11-0.2c0.08,0.04,0.19,0.03,0.26-0.03
					c0.08-0.06,0.12-0.16,0.12-0.27c-0.01-0.18-0.14-0.36-0.4-0.54c-0.06-0.04-0.14-0.09-0.2-0.09
					C222.95,160.76,222.95,160.76,222.95,160.76z"
              />
            </g>
            <g>
              <g>
                <path
                  d="M224.63,165.84l-0.07-0.51c0.84-0.12,1.67-0.17,2.48-0.22l7.16-0.45l0.03,0.51l-7.16,0.45
						C226.27,165.67,225.45,165.72,224.63,165.84z"
                />
                <path
                  d="M224.74,166.9l-0.11-0.5c0.63-0.13,1.28-0.23,1.93-0.3c1.02-0.1,2.05-0.13,3.05-0.15l0.01,0.51
						c-0.99,0.02-2.01,0.04-3.01,0.14C225.98,166.68,225.35,166.78,224.74,166.9z"
                />
              </g>
              <path
                d="M223.25,167.13C223.25,167.13,223.25,167.13,223.25,167.13c-0.1,0-0.17-0.05-0.21-0.08l-0.25-0.16
					c-0.22-0.14-0.45-0.31-0.45-0.58c0-0.2,0.11-0.35,0.2-0.47c0.09-0.12,0.28-0.37,0.55-0.37c0.01,0,0.02,0,0.03,0
					c0.13,0.01,0.24,0.07,0.33,0.14c0.32,0.22,0.5,0.47,0.51,0.74c0.01,0.19-0.07,0.37-0.22,0.48c-0.07,0.06-0.16,0.09-0.25,0.1
					C223.47,167.04,223.37,167.13,223.25,167.13z M223.23,166.9C223.23,166.9,223.23,166.9,223.23,166.9l0.02,0.01
					C223.25,166.9,223.24,166.9,223.23,166.9z M223.09,165.73c-0.13,0-0.24,0.13-0.35,0.27c-0.08,0.11-0.15,0.21-0.15,0.31
					c0,0.15,0.17,0.27,0.33,0.37l0.25,0.16c0.01,0.01,0.02,0.01,0.03,0.02l0.11-0.2c0.08,0.04,0.19,0.03,0.26-0.03
					c0.08-0.06,0.12-0.17,0.12-0.27c-0.01-0.18-0.14-0.36-0.4-0.54c-0.06-0.04-0.14-0.09-0.2-0.09
					C223.09,165.73,223.09,165.73,223.09,165.73z"
              />
            </g>
            <g>
              <g>
                <path
                  d="M226.51,171.46c-0.42,0-0.84-0.03-1.24-0.12l0.11-0.5c0.78,0.17,1.67,0.1,2.53,0.03c0.42-0.04,0.84-0.07,1.24-0.08
						c0.87-0.01,1.76-0.1,2.61-0.18c0.93-0.09,1.88-0.18,2.84-0.18l0,0.51h0l0,0c-0.93,0-1.88,0.09-2.79,0.18
						c-0.86,0.08-1.75,0.17-2.65,0.18c-0.39,0.01-0.79,0.04-1.2,0.07C227.47,171.42,226.99,171.46,226.51,171.46z"
                />
                <path
                  d="M225.32,172.55l-0.02-0.51c0.8-0.04,1.54-0.05,2.24-0.06c1.32-0.02,2.56-0.05,3.88-0.22l0.07,0.51
						c-1.35,0.18-2.61,0.2-3.94,0.23C226.85,172.5,226.12,172.51,225.32,172.55z"
                />
              </g>
              <path
                d="M223.8,172.84C223.79,172.84,223.79,172.84,223.8,172.84c-0.09,0-0.16-0.04-0.21-0.08l-0.25-0.16
					c-0.15-0.09-0.45-0.28-0.45-0.58c-0.01-0.2,0.11-0.35,0.2-0.47c0.1-0.13,0.29-0.4,0.58-0.37c0.13,0.01,0.24,0.07,0.33,0.14
					c0.32,0.22,0.5,0.47,0.51,0.74c0.01,0.19-0.07,0.37-0.22,0.48c-0.07,0.06-0.16,0.09-0.25,0.1
					C224.02,172.75,223.91,172.84,223.8,172.84z M223.77,172.61C223.77,172.61,223.77,172.61,223.77,172.61l0.02,0.01
					C223.79,172.62,223.78,172.61,223.77,172.61z M223.63,171.44c-0.13,0-0.24,0.13-0.35,0.27c-0.08,0.11-0.15,0.21-0.15,0.31
					c0,0.15,0.16,0.26,0.33,0.37l0.25,0.16c0.01,0.01,0.02,0.01,0.03,0.02l0.11-0.2c0.08,0.04,0.19,0.03,0.26-0.03
					c0.08-0.06,0.12-0.17,0.12-0.27c-0.01-0.18-0.14-0.36-0.4-0.54c-0.07-0.05-0.14-0.09-0.2-0.09
					C223.64,171.44,223.63,171.44,223.63,171.44z"
              />
            </g>
          </g>
        </g>
      </g>
      <g>
        <polygon
          className="st23"
          points="253.01,179.96 252.62,206.23 280.07,207.09 279.82,179.96 	"
        />
        <g>
          <path
            className="st7"
            d="M280.82,204.66c-1.62,0.33-5.62,0.29-11.39,0.91c-4.08,0.44-14.88-0.65-14.88-0.65s-1.57-9.06-1.57-12.26
			s0.02-12.7,0.02-12.7h27.09c0,0-0.85,8.75-0.85,11.66C279.26,195.12,280.82,204.66,280.82,204.66z"
          />
          <g>
            <g>
              <path
                d="M257.26,187.03c-0.44,0-0.87-0.03-1.28-0.07l-0.1-0.01l-0.02-0.1c-0.07-0.44-0.21-2.46-0.22-2.54l-0.01-0.12l0.12-0.01
					c0.09-0.01,0.88-0.11,1.17-0.05c0.37,0.06,0.6,0.07,1.01-0.02c0.05-0.01,0.1-0.02,0.15-0.04c0.29-0.08,0.64-0.17,0.91-0.06
					l0.07,0.03l0.01,0.07c0.04,0.25,0.04,0.4,0.04,0.56c0,0.08,0,0.17,0.01,0.27c0.01,0.14-0.01,0.27-0.03,0.39l-0.01,0.09
					c-0.06,0.4-0.03,0.83,0,1.23l0.01,0.1l-0.09,0.03C258.43,186.97,257.84,187.03,257.26,187.03z M256.1,186.71
					c0.87,0.07,1.84,0.13,2.71-0.16c-0.03-0.39-0.05-0.79,0.01-1.19l0.01-0.09c0.02-0.12,0.04-0.23,0.03-0.33
					c-0.01-0.1-0.01-0.19-0.01-0.28c0-0.13,0-0.26-0.03-0.44c-0.19-0.04-0.46,0.03-0.68,0.09c-0.06,0.01-0.11,0.03-0.16,0.04
					c-0.45,0.1-0.72,0.09-1.11,0.02c-0.18-0.03-0.69,0.01-0.98,0.04C255.94,184.84,256.04,186.22,256.1,186.71z"
              />
              <g>
                <path
                  d="M262.11,184.96c-0.46,0-0.91-0.03-1.34-0.14l0.06-0.25c0.8,0.19,1.7,0.13,2.57,0.07c0.43-0.03,0.83-0.06,1.23-0.06
						c0.88,0,1.77-0.07,2.62-0.14c0.92-0.07,1.88-0.15,2.82-0.14l0,0.26l-0.11,0c-0.89,0.01-1.81,0.07-2.69,0.14
						c-0.86,0.07-1.75,0.14-2.64,0.14c-0.39,0-0.79,0.03-1.21,0.06C262.98,184.93,262.54,184.96,262.11,184.96z"
                />
                <path
                  d="M260.77,186.03l-0.01-0.26c0.8-0.03,1.53-0.03,2.24-0.03c1.32,0,2.57-0.01,3.9-0.17l0.03,0.25
						c-1.35,0.16-2.6,0.16-3.93,0.17C262.3,186,261.57,186,260.77,186.03z"
                />
              </g>
            </g>
            <g>
              <path
                d="M256.89,192.04c-0.26,0-0.52-0.01-0.77-0.03l-0.1,0l-0.02-0.1c-0.09-0.43-0.14-0.9-0.16-1.49c-0.01-0.32,0-0.88,0-0.9
					l0-0.13l0.13,0c0.01,0,1.16,0.03,1.59,0c0.05,0,0.27-0.04,0.49-0.08c0.48-0.08,0.85-0.14,0.97-0.14h0.11l0.02,0.11
					c0.01,0.09,0.02,0.18,0.02,0.27c0.01,0.11,0.01,0.21,0.04,0.32c0.04,0.16,0.04,0.32,0.04,0.47v0.11
					c0.01,0.41,0.08,0.83,0.15,1.24l0.03,0.18l-0.18-0.03c-0.25-0.04-0.51-0.08-0.76-0.05c-0.11,0.01-0.22,0.04-0.34,0.08
					c-0.05,0.01-0.1,0.03-0.16,0.04C257.64,192.01,257.26,192.04,256.89,192.04z M256.24,191.76c0.57,0.03,1.15,0.05,1.71-0.09
					c0.05-0.01,0.1-0.03,0.15-0.04c0.12-0.03,0.24-0.07,0.37-0.08c0.22-0.03,0.45-0.01,0.66,0.02c-0.06-0.36-0.12-0.73-0.13-1.1
					l0-0.11c0-0.15,0-0.29-0.03-0.42c-0.03-0.13-0.04-0.25-0.04-0.36c0-0.04,0-0.08-0.01-0.13c-0.19,0.02-0.54,0.08-0.81,0.12
					c-0.28,0.05-0.46,0.07-0.52,0.08c-0.36,0.02-1.17,0.01-1.48,0c0,0.19-0.01,0.54,0,0.76
					C256.12,190.95,256.16,191.38,256.24,191.76z"
              />
              <g>
                <path
                  d="M262.28,190.07c-0.46,0-0.91-0.03-1.34-0.14l0.06-0.25c0.8,0.19,1.7,0.13,2.57,0.07c0.43-0.03,0.83-0.06,1.23-0.06
						c0.88,0,1.77-0.07,2.62-0.14c0.92-0.07,1.87-0.15,2.82-0.14l0,0.26l-0.11,0c-0.9,0-1.81,0.07-2.69,0.14
						c-0.86,0.07-1.75,0.14-2.64,0.14c-0.39,0-0.79,0.03-1.21,0.06C263.15,190.04,262.71,190.07,262.28,190.07z"
                />
                <path
                  d="M260.94,191.14l-0.01-0.26c0.8-0.03,1.53-0.03,2.24-0.03c1.32,0,2.57-0.01,3.9-0.16l0.03,0.25
						c-1.35,0.16-2.6,0.16-3.93,0.17C262.47,191.12,261.74,191.12,260.94,191.14z"
                />
              </g>
            </g>
            <g>
              <path
                d="M257.6,198.03c-0.08,0-0.19-0.03-0.22-0.04l-0.05-0.02l-0.02-0.05c-0.02-0.04-0.07-0.16-0.08-0.25l0,0
					c-0.01-0.35-0.02-2.13-0.02-2.21l0-0.13l0.13,0c0.01,0,0.9,0,1.09-0.01c0.32-0.02,0.64-0.06,0.96-0.1l0.95-0.12l0.02,0.18
					c0.03,0.21,0.06,0.47,0.03,0.67c-0.05,0.41-0.03,0.74-0.02,1.09c0.01,0.23,0.02,0.47,0.01,0.74l0,0.12l-0.12,0.01
					c-0.44,0.03-0.89,0.05-1.35,0.07c-0.44,0.02-0.88,0.04-1.31,0.06C257.61,198.03,257.6,198.03,257.6,198.03z M257.51,197.76
					c0.04,0.01,0.08,0.01,0.09,0.01c0.42-0.03,0.87-0.04,1.31-0.06c0.41-0.02,0.83-0.04,1.23-0.06c0-0.21-0.01-0.41-0.02-0.6
					c-0.02-0.35-0.03-0.7,0.02-1.13c0.02-0.15,0-0.36-0.02-0.53l-0.69,0.09c-0.32,0.04-0.65,0.08-0.97,0.11
					c-0.16,0.01-0.72,0.01-0.98,0.01c0,0.43,0.01,1.78,0.02,2.07v0C257.48,197.68,257.5,197.72,257.51,197.76z"
              />
              <g>
                <path
                  d="M263.43,196.09c-0.46,0-0.91-0.03-1.34-0.14l0.06-0.25c0.8,0.19,1.7,0.13,2.57,0.07c0.43-0.03,0.83-0.06,1.23-0.06
						c0.88,0,1.77-0.07,2.62-0.14c0.92-0.07,1.87-0.15,2.82-0.14l0,0.26l-0.11,0c-0.89,0-1.81,0.07-2.69,0.14
						c-0.86,0.07-1.75,0.14-2.64,0.14c-0.39,0-0.79,0.03-1.21,0.06C264.31,196.06,263.87,196.09,263.43,196.09z"
                />
                <path
                  d="M262.1,197.16l-0.01-0.26c0.8-0.03,1.54-0.03,2.25-0.03c1.32,0,2.56-0.01,3.89-0.16l0.03,0.25
						c-1.34,0.16-2.6,0.16-3.92,0.17C263.63,197.13,262.89,197.14,262.1,197.16z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g>
        <polygon
          className="st23"
          points="286.25,179.85 286.25,205.08 303.42,205.56 312.87,205.56 312.29,189.12 312.29,180.29 	"
        />
        <g>
          <path
            className="st18"
            d="M314.06,204.55c-1.62,0.33-5.62,0.29-11.39,0.91c-4.08,0.44-14.88-0.64-14.88-0.64s-1.57-9.06-1.57-12.26
			c0-3.2,0.02-12.7,0.02-12.7h27.09c0,0-0.85,8.75-0.85,11.66C312.49,195.01,314.06,204.55,314.06,204.55z"
          />
          <g>
            <g>
              <path
                d="M291.41,200.58c-0.26,0-0.52-0.01-0.77-0.03l-0.1,0l-0.02-0.1c-0.09-0.43-0.14-0.9-0.16-1.49c-0.01-0.32,0-0.88,0-0.9
					l0-0.13l0.13,0c0.01,0,1.16,0.04,1.59,0c0.05,0,0.28-0.04,0.51-0.08c0.48-0.08,0.83-0.13,0.95-0.13h0.11l0.02,0.11
					c0.01,0.09,0.02,0.18,0.02,0.27c0.01,0.11,0.01,0.21,0.04,0.32c0.04,0.16,0.04,0.32,0.04,0.48v0.11
					c0.01,0.41,0.08,0.83,0.15,1.23l0.03,0.18l-0.18-0.02c-0.25-0.04-0.51-0.08-0.76-0.05c-0.11,0.01-0.22,0.04-0.34,0.08
					c-0.05,0.01-0.1,0.03-0.15,0.04C292.16,200.55,291.78,200.58,291.41,200.58z M290.76,200.3c0.57,0.03,1.15,0.05,1.71-0.09
					c0.05-0.01,0.1-0.03,0.14-0.04c0.12-0.03,0.25-0.07,0.38-0.08c0.22-0.03,0.44-0.01,0.66,0.02c-0.06-0.36-0.12-0.73-0.13-1.1
					v-0.11c0-0.15,0-0.29-0.03-0.42c-0.03-0.13-0.04-0.25-0.04-0.36c0-0.04,0-0.08-0.01-0.12c-0.19,0.02-0.53,0.08-0.8,0.12
					c-0.29,0.05-0.47,0.08-0.53,0.08c-0.36,0.03-1.17,0.01-1.48,0c0,0.19-0.01,0.54,0,0.76
					C290.64,199.48,290.69,199.91,290.76,200.3z"
              />
              <g>
                <path
                  d="M296.96,198.46c-0.46,0-0.91-0.03-1.34-0.14l0.06-0.25c0.8,0.19,1.7,0.13,2.57,0.07c0.43-0.03,0.83-0.06,1.23-0.06
						c0.88,0,1.77-0.07,2.62-0.14c0.92-0.07,1.86-0.15,2.82-0.14l0,0.26l-0.11,0c-0.9,0-1.81,0.07-2.69,0.14
						c-0.86,0.07-1.75,0.14-2.64,0.14c-0.39,0-0.79,0.03-1.21,0.06C297.83,198.43,297.39,198.46,296.96,198.46z"
                />
                <path
                  d="M295.62,199.53l-0.01-0.26c0.8-0.03,1.54-0.03,2.25-0.03c1.32,0,2.56-0.01,3.89-0.16l0.03,0.25
						c-1.34,0.16-2.6,0.16-3.92,0.17C297.15,199.5,296.42,199.51,295.62,199.53z"
                />
              </g>
            </g>
            <g>
              <path
                d="M291.29,195.47c-0.44,0-0.87-0.03-1.28-0.07l-0.1-0.01l-0.02-0.1c-0.07-0.44-0.21-2.46-0.22-2.54l-0.01-0.12l0.12-0.01
					c0.09-0.01,0.88-0.11,1.17-0.05c0.37,0.06,0.6,0.07,1.01-0.02c0.05-0.01,0.1-0.02,0.15-0.04c0.29-0.07,0.64-0.17,0.91-0.06
					l0.07,0.03l0.01,0.07c0.04,0.24,0.04,0.4,0.04,0.56c0,0.08,0,0.17,0.01,0.27c0.01,0.13-0.01,0.26-0.03,0.39l-0.01,0.09
					c-0.06,0.4-0.03,0.83,0,1.23l0.01,0.1l-0.09,0.03C292.46,195.42,291.87,195.47,291.29,195.47z M290.12,195.16
					c0.87,0.07,1.84,0.13,2.71-0.16c-0.03-0.39-0.05-0.79,0.01-1.19l0.02-0.09c0.02-0.12,0.04-0.22,0.03-0.33
					c-0.01-0.11-0.01-0.2-0.01-0.29c0-0.13,0-0.26-0.03-0.44c-0.19-0.04-0.46,0.03-0.68,0.09c-0.06,0.01-0.11,0.03-0.16,0.04
					c-0.45,0.1-0.71,0.09-1.11,0.02c-0.18-0.03-0.69,0.01-0.98,0.04C289.96,193.28,290.07,194.67,290.12,195.16z"
              />
              <g>
                <path
                  d="M294.78,193.15l-0.04-0.25c0.82-0.12,1.66-0.17,2.47-0.22l7.16-0.45l0.02,0.26l-7.16,0.45
						C296.42,192.98,295.59,193.03,294.78,193.15z"
                />
                <path
                  d="M294.87,194.21l-0.05-0.25c0.62-0.13,1.27-0.23,1.91-0.3c1.02-0.1,2.05-0.13,3.04-0.15l0.01,0.26
						c-0.99,0.02-2.01,0.04-3.02,0.15C296.12,193.98,295.49,194.08,294.87,194.21z"
                />
              </g>
            </g>
            <g>
              <path
                d="M289.85,185.85c-0.08,0-0.19-0.03-0.22-0.04l-0.05-0.02l-0.02-0.05c-0.02-0.04-0.08-0.16-0.08-0.25
					c-0.01-0.36-0.02-2.13-0.02-2.21v-0.13l0.13,0c0.01,0,0.9,0,1.09-0.01c0.32-0.02,0.65-0.06,0.96-0.11l0.95-0.12l0.02,0.17
					c0.03,0.21,0.06,0.47,0.03,0.67c-0.05,0.41-0.03,0.74-0.02,1.09c0.01,0.23,0.02,0.47,0.01,0.74l0,0.12l-0.12,0.01
					c-0.43,0.03-0.88,0.05-1.33,0.07c-0.45,0.02-0.9,0.04-1.33,0.07C289.86,185.85,289.86,185.85,289.85,185.85z M289.76,185.58
					c0.04,0.01,0.07,0.02,0.09,0.01c0.43-0.03,0.88-0.05,1.33-0.07c0.41-0.02,0.82-0.04,1.21-0.06c0-0.21-0.01-0.41-0.02-0.6
					c-0.02-0.35-0.03-0.7,0.02-1.14c0.02-0.15,0-0.35-0.02-0.53l-0.69,0.09c-0.32,0.04-0.65,0.08-0.98,0.11
					c-0.16,0.01-0.72,0.01-0.98,0.01c0,0.43,0,1.78,0.01,2.07v0C289.74,185.5,289.75,185.54,289.76,185.58z"
              />
              <g>
                <path
                  d="M294.43,183.93l-0.04-0.25c0.83-0.12,1.66-0.17,2.47-0.22l7.16-0.45l0.02,0.26l-7.16,0.45
						C296.07,183.76,295.25,183.81,294.43,183.93z"
                />
                <path
                  d="M294.52,184.99l-0.05-0.25c0.62-0.13,1.27-0.23,1.91-0.3c1.02-0.1,2.05-0.13,3.04-0.15l0.01,0.26
						c-0.99,0.02-2.01,0.04-3.02,0.15C295.77,184.76,295.14,184.86,294.52,184.99z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M294.56,188.54l-0.04-0.25c0.83-0.12,1.66-0.17,2.47-0.22l7.16-0.45l0.02,0.26l-7.16,0.45
						C296.2,188.37,295.38,188.42,294.56,188.54z"
                />
                <path
                  d="M294.65,189.6l-0.05-0.25c0.62-0.13,1.27-0.23,1.91-0.3c1.02-0.1,2.05-0.13,3.04-0.15l0.01,0.26
						c-0.99,0.02-2.01,0.04-3.02,0.15C295.9,189.37,295.27,189.47,294.65,189.6z"
                />
              </g>
              <g>
                <path
                  d="M289.49,190.65l-0.01-0.12c-0.02-0.16-0.01-0.94-0.01-1.6l0-0.14c0-0.36,0.05-0.78,0.05-0.79l0.01-0.11l0.11-0.01
						c0.02,0,1.96-0.16,2.79-0.26l0.13-0.02l0.01,0.13c0.07,0.69,0.11,1.87,0.08,2.59l0,0.12l-0.11,0.01
						c-0.97,0.07-1.95,0.13-2.92,0.2L289.49,190.65z M289.76,188.12c-0.01,0.15-0.03,0.42-0.04,0.66l0,0.14c0,0.3-0.01,1.12,0,1.45
						c0.89-0.06,1.78-0.12,2.67-0.19c0.02-0.66-0.01-1.66-0.07-2.3C291.56,187.98,290.15,188.09,289.76,188.12z"
                />
                <path
                  d="M290.95,189.88l-0.12-0.16c-0.27-0.34-0.57-0.66-0.9-0.94c-0.05-0.04-0.06-0.13-0.01-0.18
						c0.05-0.05,0.13-0.06,0.18-0.01c0.3,0.25,0.57,0.53,0.82,0.82c0.14-0.25,0.32-0.48,0.49-0.7c0.17-0.22,0.34-0.45,0.47-0.68
						c0.08-0.14,0.15-0.29,0.22-0.43c0.08-0.16,0.16-0.32,0.25-0.48c0.04-0.06,0.12-0.08,0.18-0.04c0.06,0.04,0.08,0.11,0.04,0.18
						c-0.09,0.15-0.16,0.3-0.24,0.46c-0.07,0.15-0.15,0.3-0.23,0.45c-0.15,0.25-0.32,0.48-0.49,0.71c-0.21,0.28-0.41,0.54-0.56,0.84
						L290.95,189.88z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <g>
              <path
                className="st6"
                d="M218.07,276.07h-0.13l-0.03,0.66h-3.18l0.09-0.88l0.1,0l0-0.01l0.01-3.23h3.3l0.07,0.43l1.52,1.12
					l4.82,1.38c0,0,0.38,0.17,0.37,0.37l-0.01,0.38l-0.01,0.23c0,0-0.06,0.1-0.45,0.22h-5.53l-0.33-0.45l-0.33-0.2L218.07,276.07z"
              />
            </g>
            <g>
              <path
                className="st6"
                d="M187.33,276.07h-0.13l-0.03,0.66h-3.18l0.09-0.88l0.1,0l0-0.01l0.73-3.23h3.3l0.07,0.43l0.81,1.12
					l4.82,1.38c0,0,0.38,0.17,0.37,0.37l-0.01,0.38l-0.01,0.23c0,0-0.06,0.1-0.45,0.22h-5.53l-0.33-0.45l-0.33-0.2L187.33,276.07z"
              />
            </g>
          </g>
          <polygon
            className="st15"
            points="183.05,192.66 205.56,194.04 205.51,197.51 205.64,203.09 213,239.36 219.12,272.61 213.82,273.49 
			205.07,241.12 195.22,209.54 194.8,208.84 191.84,240.64 189.14,272.72 183.99,273 183.71,239.94 182.63,205.51 181.24,200.87 		
			"
          />
        </g>
        <g>
          <polygon
            className="st22"
            points="176.76,184.12 175.59,186.58 175.79,187.67 175.87,189.19 174.58,192.14 173.82,192.06 
			174.3,190.19 174,190.17 172.99,193.61 171.17,191.82 171.31,188.57 171.87,187.27 173.26,185.33 174.14,182.96 		"
          />
          <g>
            <g>
              <g>
                <path
                  className="st7"
                  d="M180.09,171.64l0.24,0.12c1.5,0.67,2.83,0.07,4.12-1.69c2.98-4.08,7.09-8.03,9.93-12.86
						c1.47-2.51,0.32-3.85-0.84-4.93l-0.23-0.2c-1.7-1.21-3.88-0.51-4.76,1.04c-2.98,5.21-9.52,14.51-9.52,14.51
						C178.25,169.24,178.59,170.96,180.09,171.64z"
                />
                <path
                  className="st7"
                  d="M173.23,184.7l3.72,0.39c0,0,5.05-9.7,6.55-13.96c0.77-2.17,0.88-3.53-0.57-4.3l0,0
						c-1.45-0.76-3.39-0.67-4.4,1.88C176.98,172.6,173.23,184.7,173.23,184.7z"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <polygon
            className="st22"
            points="241.16,156.16 243.76,155.37 244.43,154.48 245.5,153.4 247.5,151.47 247.69,152.35 247.12,153.34 
			247,154.1 247.18,154.33 247.99,153.59 248.22,152.31 248.6,152.62 248.55,156.64 247.89,157.25 246.78,157.64 244.41,157.93 
			242.07,158.88 		"
          />
          <g>
            <g>
              <g>
                <path
                  className="st7"
                  d="M228.92,162.17l0.03-0.26c0.1-1.64-0.93-2.67-3.04-3.25c-4.87-1.34-9.08-3.36-14.6-4.3
						c-2.87-0.49-3.72,1.07-4.31,2.53l-0.1,0.28c-0.53,2.02,0.35,4.1,2.11,4.38c5.93,0.94,16.55,3.03,16.55,3.03
						C227.33,164.74,228.82,163.81,228.92,162.17z"
                />
                <path
                  className="st7"
                  d="M242.77,158.97l-0.07-3.53c0,0-12.3,2-16.54,3.54c-2.16,0.79-3.28,1.57-2.95,3.17l0,0
						c0.33,1.6,1.64,3.04,4.25,2.2C231.44,163.08,242.77,158.97,242.77,158.97z"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <rect
                    x="198.39"
                    y="149"
                    transform="matrix(0.2816 -0.9595 0.9595 0.2816 -0.0194 302.5173)"
                    className="st22"
                    width="7.26"
                    height="4.54"
                  />
                </g>
              </g>
              <g>
                <g id="XMLID_21_">
                  <path
                    id="XMLID_23_"
                    className="st22"
                    d="M209.86,145.13c-0.44,3.21-3.01,5.5-5.74,5.13c-2.74-0.37-4.6-3.27-4.16-6.48
							c0.06-0.45,0.61-6.32,0.75-6.74l2.34-1.43l4.22-0.56l3.14,1.38c0.01,0.12,1.04,1.37,0.69,3.39
							C210.77,141.69,209.9,144.8,209.86,145.13z"
                  />
                </g>
              </g>
            </g>
            <path
              className="st6"
              d="M211.82,137.36c1.31-1.52,0.98-2.8,0.48-3.44c-0.25-0.32-1.14-0.99-3.63-0.81
				c-2.49,0.18-2.22-0.97-3.76-0.73c-2.44,0.37-5.19,2.83-5.74,5.29c-0.42,1.86,0.07,4.31,0.33,5.4l0.16,2.16
				c0.06,0.78,0.59,1.44,1.34,1.66l2.08,0.61c0.67,0.19,1.61,0.27,2.25-0.02l0.37-0.16c0.78-0.35,1.3-1.1,1.35-1.96l0.25-2.16
				l0.39-1.36l0.61-0.05l-0.06,1.37l0.64-0.12c0.02-0.43,0.51-1.24,0.6-2.15c0.02-0.15,0.43-0.29,0.45-0.44
				c0.08-0.77,0.14-1.54,0.17-2.04C210.65,138.34,211.17,138.12,211.82,137.36z"
            />
          </g>
          <g>
            <path
              className="st7"
              d="M204.88,152.67l0.56,0.11l6.19,1.71c3.77,0.8,3,3.95,2.49,6.63l-5.38,15.84l-0.89,4.58l-0.92,11.74
				l-1.38,1.9l-22.91-1.68l-0.05-2.58l3.13-14.56l1.84-10.78l0.53-9.18c0.37-1.92,1.45-5.07,4.36-5.38
				c1.55-0.17,6.21,0.45,6.21,0.45s2.1,0.83,2.83,0.97C202.32,152.59,204.88,152.67,204.88,152.67z"
            />
          </g>
        </g>
      </g>
      <path
        className="st1"
        d="M381.09,280.42c-14.54-0.04-41.33,0.7-38.02-3.03c3.31-3.72,20.76-0.08,35.07-1.32
	c14.31-1.25,27.26-2.68,41.89-0.84c14.63,1.84,10.65,3.96,4.23,4.56c-11.8,1.1-13.89,1.57-20.74,0.97
	C383.48,278.99,386.74,280.44,381.09,280.42z"
      />
      <g>
        <path
          className="st20"
          d="M396.66,260.28v-77.34c-5.55-1.86-11.54-2.88-17.77-2.88c-29.13,0-52.74,22.09-52.74,49.35
		c0,17.1,9.29,32.16,23.41,41.01h36.96C392.12,270.42,396.66,265.88,396.66,260.28z"
        />
        <g>
          <circle className="st7" cx="383.74" cy="226.98" r="51.78" />
          <path
            className="st24"
            d="M383.74,175.2c-28.6,0-51.78,23.18-51.78,51.78s23.18,51.78,51.78,51.78s51.78-23.18,51.78-51.78
			S412.33,175.2,383.74,175.2z M383.74,277.08c-27.67,0-50.1-22.43-50.1-50.1c0-27.67,22.43-50.1,50.1-50.1
			c27.67,0,50.1,22.43,50.1,50.1C433.84,254.65,411.41,277.08,383.74,277.08z"
          />
        </g>
        <g>
          <ellipse
            transform="matrix(0.1624 -0.9867 0.9867 0.1624 97.4561 568.7643)"
            className="st11"
            cx="383.74"
            cy="226.98"
            rx="43.65"
            ry="43.65"
          />
          <path
            className="st25"
            d="M427.39,226.98c0-24.11-19.54-43.65-43.65-43.65c-12.35,0-23.49,5.13-31.43,13.37
			c7.85-7.56,18.52-12.23,30.28-12.23c24.11,0,43.65,19.54,43.65,43.65c0,11.76-4.66,22.43-12.23,30.28
			C422.26,250.46,427.39,239.32,427.39,226.98z"
          />
        </g>
        <g>
          <path
            className="st26"
            d="M386.56,227.5h-5.64v-28.33c0-1.56,1.26-2.82,2.82-2.82l0,0c1.56,0,2.82,1.26,2.82,2.82V227.5z"
          />
          <g>
            <path
              className="st8"
              d="M387.03,226.47h-5.64v-28.33c0-1.56,1.26-2.82,2.82-2.82l0,0c1.56,0,2.82,1.26,2.82,2.82V226.47z"
            />
            <path
              className="st27"
              d="M387.03,198.14c0-1.56-1.26-2.82-2.82-2.82c-0.9,0-1.7,0.43-2.21,1.09c0.5-0.44,1.14-0.71,1.85-0.71l0,0
				c1.56,0,2.82,1.26,2.82,2.82v27.95h0.36V198.14z"
            />
          </g>
        </g>
        <g>
          <path
            className="st26"
            d="M405.77,243.92l-20.14-20.14l-0.58,0.58l-5.2,5.2l20.14,20.14c0.99,0.99,2.35,1.36,3.63,1.13h0c0,0,0,0,0,0
			c0.08-0.01,0.15-0.04,0.23-0.06c0.15-0.04,0.31-0.08,0.46-0.14c0.09-0.03,0.17-0.07,0.26-0.11c0.15-0.07,0.29-0.14,0.43-0.22
			c0.07-0.04,0.14-0.08,0.21-0.13c0.2-0.14,0.4-0.29,0.58-0.47v0C407.36,248.1,407.36,245.51,405.77,243.92z"
          />
          <g>
            <path
              className="st8"
              d="M380.22,229.13l5.78-5.78l20.14,20.14c1.6,1.6,1.6,4.18,0,5.78l0,0c-1.6,1.6-4.18,1.6-5.78,0L380.22,229.13z
				"
            />
            <path
              className="st27"
              d="M406.14,243.49L386,223.35l-0.58,0.58l19.83,19.83c1.6,1.6,1.6,4.18,0,5.78c-0.38,0.38-0.81,0.65-1.27,0.85
				c0.79-0.14,1.54-0.52,2.15-1.13v0C407.73,247.68,407.73,245.09,406.14,243.49z"
            />
          </g>
        </g>
        <g>
          <path
            className="st26"
            d="M351.72,245.83c-0.18,0-0.35-0.09-0.45-0.26c-0.14-0.25-0.05-0.56,0.19-0.7l31.87-18.12
			c0.25-0.14,0.56-0.05,0.7,0.19c0.14,0.25,0.05,0.56-0.19,0.7l-31.87,18.12C351.89,245.81,351.81,245.83,351.72,245.83z"
          />
          <path
            className="st12"
            d="M351.59,245.68c-0.18,0-0.35-0.09-0.45-0.26c-0.14-0.25-0.05-0.56,0.19-0.7l31.99-18.19
			c0.25-0.14,0.56-0.05,0.7,0.19c0.14,0.25,0.05,0.56-0.19,0.7l-31.99,18.19C351.76,245.66,351.67,245.68,351.59,245.68z"
          />
        </g>
        <g>
          <g>
            <path
              className="st26"
              d="M383.58,218.86c-4.62,0-8.37,3.76-8.37,8.37c0,4.62,3.76,8.37,8.37,8.37c4.62,0,8.37-3.76,8.37-8.37
				C391.95,222.62,388.19,218.86,383.58,218.86z M389.64,222.24c0.32,0.39,0.6,0.81,0.85,1.25
				C390.24,223.04,389.96,222.62,389.64,222.24c-1.12-1.36-2.69-2.33-4.48-2.7C386.95,219.9,388.52,220.88,389.64,222.24z
				 M389.64,232.23c-1.12,1.36-2.69,2.33-4.48,2.7C386.95,234.57,388.52,233.59,389.64,232.23c0.32-0.39,0.6-0.81,0.85-1.25
				C390.24,231.43,389.96,231.85,389.64,232.23z"
            />
            <g>
              <circle className="st18" cx="383.58" cy="226.72" r="7.86" />
              <path
                className="st28"
                d="M383.58,218.86c4.34,0,7.86,3.52,7.86,7.86c0,4.34-3.52,7.86-7.86,7.86c-4.34,0-7.86-3.52-7.86-7.86
					C375.72,222.38,379.24,218.86,383.58,218.86 M383.58,218.35c-4.62,0-8.37,3.76-8.37,8.37c0,4.62,3.76,8.37,8.37,8.37
					c4.62,0,8.37-3.76,8.37-8.37C391.95,222.11,388.19,218.35,383.58,218.35L383.58,218.35z"
              />
              <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="383.5865"
                y1="218.8641"
                x2="383.5865"
                y2="227.5517"
              >
                <stop offset="0" stopColor="#FFFFFF" />
                <stop offset="1" stopColor="#000000" />
              </linearGradient>
              <path
                className="st29"
                d="M383.58,218.86c-4.21,0-7.63,3.31-7.84,7.46c2.95-1.77,9.2-4.22,15.65,1.23c0.03-0.27,0.04-0.55,0.04-0.83
					C391.44,222.38,387.92,218.86,383.58,218.86z"
              />
            </g>
          </g>
          <path
            className="st0"
            d="M388.12,226.98c0,2.51-2.04,4.55-4.55,4.55c-2.51,0-4.55-2.04-4.55-4.55s2.04-4.55,4.55-4.55
			C386.09,222.43,388.12,224.47,388.12,226.98z"
          />
        </g>
        <g>
          <g>
            <polygon
              className="st8"
              points="382.71,192.31 382.71,186.79 380.76,186.79 380.76,187.59 381.91,187.59 381.91,192.31 
				380.76,192.31 380.76,193.11 383.86,193.11 383.86,192.31 			"
            />
            <path
              className="st8"
              d="M385.02,192.31v-1.96h2.43c0.22,0,0.41-0.08,0.57-0.24c0.16-0.16,0.24-0.35,0.24-0.57v-1.95
				c0-0.22-0.08-0.41-0.24-0.56c-0.16-0.16-0.35-0.24-0.57-0.24h-2.61v0.8h2.61v1.95h-2.43c-0.22,0-0.41,0.08-0.56,0.24
				c-0.16,0.16-0.24,0.35-0.24,0.57v2.76h4.04v-0.8H385.02z"
            />
          </g>
          <path
            className="st8"
            d="M423.6,226.97c0.22-0.22,0.32-0.5,0.32-0.85v-1.5c0-0.22-0.08-0.41-0.23-0.56c-0.16-0.16-0.34-0.24-0.57-0.24
			h-2.64v0.8h2.64v1.55c0,0.11-0.04,0.21-0.12,0.28c-0.08,0.08-0.17,0.12-0.28,0.12h-1.55v0.8h1.54c0.11,0,0.21,0.04,0.28,0.12
			c0.08,0.08,0.12,0.18,0.12,0.29v1.56h-2.63v0.8h2.64c0.22,0,0.41-0.08,0.57-0.24c0.16-0.16,0.24-0.35,0.24-0.56v-1.56
			C423.93,227.48,423.82,227.22,423.6,226.97z"
          />
          <g>
            <path
              className="st8"
              d="M384.6,264.42c-0.08-0.08-0.17-0.12-0.28-0.12h-2.82v-2.22h-0.01v0.17h-0.8v4.53c0,0.11,0.04,0.2,0.12,0.28
				c0.08,0.08,0.18,0.12,0.29,0.12h3.21c0.11,0,0.21-0.04,0.29-0.12c0.08-0.08,0.12-0.17,0.12-0.28v-2.07
				C384.72,264.59,384.68,264.5,384.6,264.42z M383.91,266.37h-2.42v-1.27h2.42V266.37z"
            />
            <path
              className="st8"
              d="M381.5,261.65h2.64v-0.8h-2.64c-0.22,0-0.41,0.08-0.57,0.24c-0.16,0.16-0.23,0.35-0.23,0.56v0.42h0.8V261.65
				z"
            />
            <rect
              x="380.7"
              y="262.07"
              className="st8"
              width="0.8"
              height="0.17"
            />
          </g>
          <path
            className="st8"
            d="M347.45,223.94c-0.08-0.08-0.18-0.12-0.29-0.12h-3.21c-0.11,0-0.21,0.04-0.29,0.12
			c-0.08,0.08-0.12,0.17-0.12,0.28v2.07c0,0.11,0.04,0.21,0.12,0.29c0.08,0.08,0.17,0.12,0.28,0.12h2.82v2.22h0.01v0.42h-2.64v0.8
			h2.64c0.22,0,0.41-0.08,0.57-0.24c0.16-0.16,0.23-0.35,0.23-0.56v-0.42v-0.17v-4.53C347.57,224.11,347.53,224.02,347.45,223.94z
			 M344.35,225.89v-1.27h2.42v1.27H344.35z"
          />
        </g>

        <linearGradient
          id="SVGID_00000180348552839886170380000010517418093029725314_"
          gradientUnits="userSpaceOnUse"
          x1="403.9824"
          y1="188.2554"
          x2="376.6769"
          y2="235.5499"
        >
          <stop offset="0" stopColor="#FFFFFF" />
          <stop offset="0.6335" stopColor="#000000" />
        </linearGradient>
        <path
          opacity="0.8"
          fill="url(#SVGID_00000180348552839886170380000010517418093029725314_)"
          d="M383.74,183.33
		c-19.97,0-36.8,13.42-41.99,31.73c7.45,7.03,20.92-0.03,39.17-6.9c21.78-8.19,38.88,10.1,46.2,23.66c0.18-1.59,0.27-3.21,0.27-4.84
		C427.39,202.87,407.85,183.33,383.74,183.33z"
        />
      </g>
      <g>
        <g>
          <g>
            <path
              className="st31"
              d="M444.06,186.99c0,0-1.69-0.29-3.7,0.3c-2.5,0.74-4.42,2.23-5.08,4.6c-1.06,3.86,0.94,4.1,2.32,4.93
				c1.38,0.83-2.46,5.5,0.9,6.86c3.36,1.36-0.96,1.63,0.44,3.97c1.4,2.34,3.45,3.15,6.91,2.24c3.47-0.92,5.41-2.71,7.71-2.76
				c2.3-0.05,5.82,1.68,7.8-1.22c1.01-1.48,1.09-4.74-0.65-6.27c-3.22-2.83-6.01,0.74-7.86-6.37c-0.96-3.69-4.26-3.74-5.19-4.41
				C446.74,188.2,447.86,187.51,444.06,186.99z"
            />
            <g>
              <g>
                <path
                  className="st22"
                  d="M423.6,227.69l1.02-0.07l1.07,0.22l5.26,3.15l2.05-2.57l-5.25-3.22l-1.99-1.23l-1.79-1.18l-2.47,0.32
						l-1.1,1.03c0,0-1.19,0.66-1.22,0.8c-0.03,0.15,0.35,0.34,0.35,0.34l1.69-0.5l0.87,0.76l0.18,0.28l0.1,0.15L423.6,227.69z"
                />
                <g>
                  <g>
                    <path
                      className="st6"
                      d="M418.81,226.75l9.73-2.6c0,0,0.13-0.14,0.13-0.17c0.07-0.27-0.1-0.77-0.31-0.97
								c-0.02-0.02-0.16-0.06-0.16-0.06l-9.71,2.59L418.81,226.75z"
                    />
                  </g>
                  <g>
                    <path
                      className="st6"
                      d="M417.14,226.61c0.01-0.06-0.01-0.15-0.07-0.27c0,0-0.47,0.11-0.44,0.23
								C416.66,226.7,417.14,226.61,417.14,226.61z"
                    />
                    <g>
                      <path
                        className="st22"
                        d="M417.1,226.64l1.7,0.11l-0.32-1.2l-1.46,0.77C417.1,226.45,417.11,226.57,417.1,226.64z"
                      />
                      <path
                        className="st22"
                        d="M418.49,225.55c0.1-0.03,0.26,0.22,0.35,0.55c0.09,0.33,0.08,0.62-0.03,0.65
									c-0.1,0.03-0.26-0.22-0.35-0.55C418.37,225.87,418.39,225.58,418.49,225.55z"
                      />
                    </g>
                  </g>
                </g>
                <path
                  className="st22"
                  d="M421.45,226.09c0,0-0.43-0.32-0.59-0.4c-0.08-0.04-0.25-0.08-0.34-0.09c-0.13-0.01-0.46,0.04-0.48,0.16
						c-0.06,0.48,1.26,1.41,1.26,1.41l1.59,0.55l0.72-0.05l-0.9-1.64L421.45,226.09z"
                />
              </g>
              <path
                className="st1"
                d="M441.86,280.67c-14.54-0.04-41.33,0.7-38.02-3.03c3.31-3.72,20.76-0.08,35.07-1.32
					c14.31-1.25,27.26-2.68,41.89-0.84c14.63,1.84,10.65,3.96,4.23,4.56c-11.8,1.1-13.89,1.57-20.74,0.97
					C444.26,279.24,447.52,280.68,441.86,280.67z"
              />
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        className="st18"
                        d="M438.53,237.11l-0.26-0.07c-1.59-0.51-2.19-1.87-1.96-4.08c0.52-5.11,1.5-14.84,2.64-20.41
									c0.59-2.9,2.26-3.19,3.86-3.21l0.3,0c2.11,0.24,3.24,3.12,2.55,6.62c-1.18,5.98-3.61,18.85-3.61,18.85
									C441.55,236.55,440.12,237.62,438.53,237.11z"
                      />
                      <path
                        className="st18"
                        d="M427.3,229.13l1.82-3.7c0,0,6.26,4,10.42,6.36c2.03,1.15,2.7,2.53,1.88,3.98l0,0
									c-0.82,1.45-2.28,2.2-4.61,0.46C433.08,233.43,427.3,229.13,427.3,229.13z"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g>
                <polygon
                  className="st22"
                  points="421.82,267.24 425.15,276.14 423.93,276.65 420.11,278.36 420.11,279.26 428.27,279.3 
						428.9,278.47 429,277.12 426.15,266.7 					"
                />
                <g>
                  <polygon
                    className="st32"
                    points="423.14,276.64 423.91,276.29 423.84,277.89 427.97,276.99 429.34,276.38 429.24,277.23 
							429.56,278.83 429.12,279.58 426.77,279.49 423.43,279.49 417.79,279.49 417.53,278.86 						"
                  />
                </g>
              </g>
              <polygon
                className="st22"
                points="461.61,279.13 471.8,278.58 475.57,278.43 479.62,278.34 483.07,278.72 483.17,278.16 
					475.31,274.27 472.51,272.6 471.65,272.99 471.24,275.28 462.37,274.67 				"
              />
              <g>
                <polygon
                  className="st32"
                  points="476.55,278.57 475.71,278.59 476.4,277.15 472.25,275.15 471.07,275.17 471.3,274.38 
						471.51,272.84 472.29,272.26 474.3,273.38 477.67,275.27 483.47,278.16 483.46,278.84 479.58,278.46 					"
                />
              </g>
              <g>
                <g>
                  <g>
                    <polygon
                      className="st22"
                      points="445.61,200.38 441.36,202.18 446.37,214.1 450.62,212.3 							"
                    />
                  </g>
                </g>
                <g>
                  <g id="XMLID_36_">
                    <path
                      id="XMLID_37_"
                      className="st22"
                      d="M437.06,199.6c0.56,3.24,3.25,5.48,6.02,5.01c2.76-0.48,4.55-3.49,3.99-6.73
								c-0.05-0.28-0.64-5.4-0.91-7.26c-0.19-1.3-1.37-2.21-2.67-2.07l-4.64,0.51c-1.53,0.91-2.66,2.33-2.54,3.98
								C436.48,195.16,437.01,199.29,437.06,199.6z"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st8"
                d="M472.17,258.08L472.17,258.08c0.39-0.63,0.66-1.32,0.82-2.04l0.08-0.38c0.14-0.66,0.18-1.35,0.11-2.02
					l-0.04-0.38c-0.04-0.43-0.13-0.85-0.25-1.26l-1.14-3.73l-0.8-3.09l-11.25,1.63l-8.67,0.98l0.16,7.43l-21.68,15.84
					c-2.02,1.18-3.49,3.64-2.47,5.74l0.14,0.28c1.07,2.21,3.25,2.47,5.47,1.42l34.3-16.24c0.44-0.21,0.86-0.45,1.26-0.73l2.17-1.53
					C471.1,259.48,471.71,258.83,472.17,258.08z"
              />
              <path
                className="st8"
                d="M464.23,274.22l-33.15-2.7c-2.17-0.18-4.09,1.4-4.34,3.56l0,0c-0.28,2.4,1.6,4.51,4.02,4.51l33.26-0.08
					L464.23,274.22z"
              />
              <path
                className="st8"
                d="M470.07,259.95l0.74-0.62c0.38-0.32,0.73-0.69,1.02-1.1l0.02-0.02c0.68-0.93,1.08-2.03,1.17-3.18l0,0
					c0.05-0.59,0.01-1.19-0.12-1.77l-0.87-4.09l-1.16-3.78l-3.61,0.18l-17.21,0.65v1.77l-30.61-6.38c-2.3-0.42-5.02,0.47-5.62,2.74
					l-0.07,0.3c-0.63,2.38,0.85,3.99,3.22,4.65l38.85,11.65l9.95,0.47C467.34,261.48,468.87,260.96,470.07,259.95z"
              />
              <path
                className="st8"
                d="M427.96,272.5l-6.37-27.79c-0.47-2.13-2.55-3.49-4.69-3.09l0,0c-2.38,0.45-3.83,2.87-3.11,5.18l9.19,25.7
					H427.96z"
              />
              <g>
                <path
                  className="st18"
                  d="M444.73,232.75c0,0-4.21-6.44-4.79-9.62c-0.59-3.24-1.89-10.95,0-12.79c0.95-0.92,2.16-1.2,2.16-1.2
						l1.75-0.53l0.68,0.85c0.56,0.71,1.27,1.07,2.14,0.8l0.23-0.07c0.93-0.29,1.56-1.16,1.55-2.13l-0.01-1.01
						c0,0,3.84-0.63,5.42-0.62c3.53,0.01,4.93,2.67,5.43,5.01l2.19,8.03c0,0,2.13,8.11,3.06,10.81c0.9,2.63,2.44,6.12,2.44,6.12
						l5.34,8.22l-0.54,3.35h-21.74c0,0-0.76-0.65-0.91-2.41c-0.2-2.41-0.3-4.39-0.73-5.79
						C447.83,237.88,444.73,232.75,444.73,232.75z"
                />
              </g>
              <polygon
                className="st5"
                points="434.95,244.12 418.29,244.12 407.13,227.33 423.79,227.33 				"
              />
              <g>
                <polygon
                  className="st22"
                  points="428.59,234.52 429.14,234.47 428.9,234.72 427.41,235.15 424.82,235.57 425.23,236.06 
						425.63,236.15 427.32,238.23 429.83,238.17 432.48,237.45 443.76,235.88 444.46,231.98 432.92,234.28 431.47,233.97 
						429.82,233.41 428.04,233.7 					"
                />
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          className="st18"
                          d="M450.7,234.79l-0.26-0.07c-1.58-0.52-2.17-1.88-1.93-4.09c0.56-5.1,1.62-14.82,2.8-20.39
										c0.62-2.9,2.28-3.17,3.89-3.18l0.3,0.01c2.11,0.26,3.21,3.15,2.5,6.64c-1.23,5.97-3.75,18.82-3.75,18.82
										C453.73,234.26,452.29,235.32,450.7,234.79z"
                        />
                        <path
                          className="st18"
                          d="M439.16,236.88l-1.34-3.9c0,0,7.25-1.61,11.87-2.89c2.25-0.62,3.7-0.12,4.15,1.48l0,0
										c0.45,1.6-0.06,3.17-2.93,3.59C446.3,235.82,439.16,236.88,439.16,236.88z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <path
              className="st6"
              d="M442.16,193.77c0,0-5.04-1.33-4.23-4.23c0.94-3.37,8.65-4.54,10.61-1.31c1.31,2.16,4.8-0.33,6.22,6.42
				c1.04,4.91,3,1.41,6.74,4.53c3.75,3.13,2.73,12-1.79,11.93c-2.96-0.05-2.64-2.04-5.76-4.05c-3.12-2.01-7.58,2.06-7.53-4.74
				c0.03-3.43-1.43-3.5-1.17-4.74c0.86-4.11-0.71-4.2-1.22-2.52C443.62,196.46,442.16,193.77,442.16,193.77z"
            />
          </g>
          <path
            className="st33"
            d="M440.65,225.32c0,0,0.67,1.54,1.23,2.57c0.56,1.04,1.11,2,1.11,2l-0.13,0.71
			C442.85,230.59,440.74,226.48,440.65,225.32z"
          />
        </g>
        <g>
          <path
            className="st33"
            d="M453.65,233.74c-0.15,0.25-0.38,0.47-0.66,0.67c-0.08,0.06-0.16,0.12-0.25,0.17
			c-0.07,0.04-0.14,0.08-0.22,0.12c-0.09,0.04-0.18,0.08-0.28,0.11c-0.38,0.15-0.81,0.28-1.34,0.35c-1.33,0.19-2.87,0.42-4.39,0.64
			l0.8,1.54c0,0,2.76-0.48,4.84-1.07c2.08-0.6,3.5-2.63,3.66-3.75c0.16-1.12,2.18-18.81,2.18-18.81
			c-1.23,5.97-3.75,18.82-3.75,18.82C454.11,232.98,453.9,233.39,453.65,233.74z"
          />
        </g>
      </g>
    </svg>
  );
};

export default HolidayLinkSvg;
