import { HelpFulHintListType } from 'types/homepage';

const HelpFulHintsList: HelpFulHintListType = {
  items: [
    {
      hint: 'Only <strong>hours actually worked</strong> should be entered on a <strong>holiday</strong> because Holiday Pay is automatically paid out to all employees unless HB (Holiday Blocked - No Pay) is used',
      active: true,
      new: false,
    },
    {
      hint: ' <strong>Pay Approvers:</strong> To ensure your employees are paid on a timely basis, please make sure each time card indicates it is "<strong>Approved</strong> and ready to be processed" by <strong>11AM CT on Monday</strong>',
      active: true,
      new: false,
    },
    {
      hint: 'Unless the employee actually worked, there should not be Approved Hours on a day where there is <strong>PTO</strong>',
      active: false,
      new: false,
    },
    {
      hint: 'Hourly employees should enter <strong>PTO in WMS</strong> and not Absence Tracker',
      active: true,
      new: false,
    },
    {
      hint: 'For <strong>COVID-</strong>related absences, use Pay Code ‘AN’ and then contact BRS HR for further direction',
      active: false,
      new: false,
    },
    {
      hint: 'For <strong>Training</strong>, use Pay Code ‘TN’ which will only pay the Schedule Variance Hours as training hours with no production bonus',
      active: true,
      new: false,
    },
    {
      hint: '<strong>Payable time off</strong> is paid through Pay Codes and not by entering hours in Approved Hours field',
      active: false,
      new: false,
    },
  ],
};

export default HelpFulHintsList;
