import { axios } from 'services/api/axios';
import { useMutation } from '@tanstack/react-query';
import useNotification from 'components/Notification/useNotification';
import { AddTempEmployeePayload } from 'features/timecards/types';
import { queryClient } from 'services/api/react-query';

/**
 *
 * @param crewId Crew that you wish to add the temporary employee too
 * @returns React Query useMutation result
 * @link https://cappsq.psc.uss.com/SBWM-DOCUMENT/swagger-ui/index.html?urls.primaryName=sbwm-schedulepay#/crew-time-card-controller/saveTempCrewMember
 */

export const useAddTemporaryEmployee = (crewId: string) => {
  const notify = useNotification();
  return useMutation({
    mutationFn: (body: AddTempEmployeePayload[]) =>
      add_temporary_member(crewId, body),
    meta: {
      errorMessage: 'Unable to add temp employee.',
    },
    onSuccess: async (response, request) => {
      queryClient.removeQueries({
        queryKey: [`${request[0].ussId}-employee-crews`],
      });
      await queryClient.invalidateQueries({
        queryKey: [`${crewId}-crew-timecards`],
      });
      notify.success({ message: 'The employee is added successfully' });
    },
  });
};
/**
 *
 * @param crewId Crew Key of the crew you are requesting
 * @param body payload for timecard update
 * @returns Promise
 */
const add_temporary_member = (
  crewId: string,
  body: AddTempEmployeePayload[]
) => {
  return axios.put(`/v1/crews/${crewId}/timecards`, body);
};
