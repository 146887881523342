import { useLayoutEffect, useState } from 'react';
import TabLink from './TabLink';
import { Select } from '@uss/react-components';
import useWindowSize from 'hooks/useWindowSize';
import { useLocation, useNavigate } from 'react-router-dom';

interface TabLinkType {
  label: string;
  to: string;
  isRootUrl: boolean;
}

interface TabsProps {
  tabLinks: TabLinkType[];
}

const NavLinkTabs = ({ tabLinks }: TabsProps): JSX.Element => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const rootUrlTab = tabLinks.find((link) => link.isRootUrl);
  const tabViewMobile = width && width < 768 && tabLinks.length <= 2;
  const tabView = width && (width > 768 || tabViewMobile);

  const [selectedTab, setSelectedTab] = useState<TabLinkType>(
    rootUrlTab ? rootUrlTab : tabLinks[0]
  );

  const location = useLocation();
  useLayoutEffect(() => {
    const path = location.pathname;
    const currentChildRoute = path.substring(
      path.lastIndexOf('/') + 1,
      path.length
    );
    if (selectedTab.to !== currentChildRoute) {
      const tab = tabLinks.find((link) => link.to === currentChildRoute);
      const defaultTab = rootUrlTab ? rootUrlTab : tabLinks[0];
      setSelectedTab(tab ? tab : defaultTab);
    }
  }, [location, tabLinks, selectedTab, rootUrlTab]);
  const handleTabChange = (value: TabLinkType) => {
    setSelectedTab(value);
    navigate(`${value.to}`);
  };
  return (
    <>
      {tabView ? (
        <nav>
          <ul className="flex flex-row justify-start border-b-2 border-gray-300 mb-2">
            {tabLinks.map((link, index) => (
              <li
                className="text-lg font-normal w-full sm:w-auto"
                key={link.label}
              >
                <TabLink to={link.to} end={index === 0 ? true : false}>
                  {link.label}
                </TabLink>
              </li>
            ))}
          </ul>
        </nav>
      ) : (
        <Select
          selected={selectedTab.label}
          onSelect={handleTabChange}
          className="w-full"
        >
          <Select.Button label={selectedTab.label} tabsMenuButton />
          <Select.Options>
            {tabLinks.map((link, index) => {
              return (
                <Select.Option key={index} value={link} truncate>
                  {link.label}
                </Select.Option>
              );
            })}
          </Select.Options>
        </Select>
      )}
    </>
  );
};
export default NavLinkTabs;
