import { Slide, toast, ToastOptions } from 'react-toastify';
import './notification.css';

import Notification from './Notification';

interface NotificationProps {
  message: string | JSX.Element;
  toastSettings?: ToastOptions;
}

const toastObject: ToastOptions = {
  position: 'bottom-center',
  autoClose: 5000,
  hideProgressBar: true,
  pauseOnHover: true,
  draggable: true,
  closeOnClick: false,
  transition: Slide,
  // toastId: 'toast-id',
};

const success = ({ message, toastSettings }: NotificationProps) => {
  toast(
    <Notification
      message={message}
      variant="success"
      onClose={() => toast.dismiss()}
    />,
    {
      ...toastObject,
      ...toastSettings,
    }
  );
};
const info = ({ message, toastSettings }: NotificationProps) => {
  toast(
    <Notification
      message={message}
      variant="info"
      onClose={() => toast.dismiss()}
    />,
    {
      ...toastObject,
      ...toastSettings,
    }
  );
};
const danger = ({ message, toastSettings }: NotificationProps) => {
  toastObject.autoClose = 10000;
  toast(
    <Notification
      message={message}
      variant="danger"
      onClose={() => toast.dismiss()}
    />,
    {
      ...toastObject,
      ...toastSettings,
    }
  );
};
const warning = ({ message, toastSettings }: NotificationProps) => {
  toast(
    <Notification
      message={message}
      variant="warning"
      onClose={() => toast.dismiss()}
    />,
    {
      ...toastObject,
      ...toastSettings,
    }
  );
};

export const notify = { success, danger, info, warning };

const useNotification = () => {
  return { success, info, danger, warning };
};

export default useNotification;
