import React from 'react';
import Page from 'components/Layouts/Page';
import Detailscard from 'features/paypulloff/components/UnapprovedCrews/DetailsCard';
import CardList from 'components/Card/CardList';
import CardsLoader from 'components/Loaders/CardsLoader';
import Pagination from 'components/Pagination/Pagination';
import { SearchInput } from '@uss/react-components';
import UnapprovedCrewEmployeeCard from 'features/paypulloff/components/UnapprovedCrews/UnapprovedCrewEmployeeCard';
import { useUnapprovedEmployees } from 'features/paypulloff/api/getUnapprovedEmployees';
import { useUnapprovedCrewDetails } from 'features/paypulloff/api/getUnapprovedCrewDetail';
import { NavLink, useParams } from 'react-router-dom';
import { useUnapproveEmployeeParams } from 'features/paypulloff/hook/useUnapprovedEmployeeParams';
import { useOrgDetails } from 'features/org/api';
import { formatCrewKeyFromCode } from 'utils/formatCrewKey';
import { ErrorBoundary } from 'react-error-boundary';
import Error from 'components/Error/Error';
import {
  CrewPayApproverDetailsSchema,
  PULLOFF_TYPES,
  UnapprovedCrewEmployee,
} from 'features/paypulloff/types';

export const UnapprovedCrewDetails = () => {
  return (
    <Page
      heading="Unapproved Crew Details"
      breadcrumbs={[
        <NavLink
          key="bc-1"
          className="flex flex-row justify-center items-center"
          to={`/pay`}
        >
          Pay
        </NavLink>,
        <NavLink
          key={'unapproved-crew-link'}
          to="/pay/pay-pulloff?tab=Unapproved Crews"
        >
          Pay Pulloff
        </NavLink>,
      ]}
    >
      <ErrorBoundary
        fallbackRender={(error) => {
          return (
            <Error
              width="300"
              height="300"
              title="Something went wrong!"
              description={error.error.message}
            />
          );
        }}
      >
        <Content />
      </ErrorBoundary>
    </Page>
  );
};

const Content = () => {
  const { crewId = '' } = useParams<'crewId'>();

  const { data: crewData, status: crewDetailStatus } = useUnapprovedCrewDetails(
    {
      crewIds: crewId,
      approverType: 'primary',
    }
  );

  if (crewDetailStatus === 'success') {
    CrewPayApproverDetailsSchema.parse(crewData);
  }

  //search employee

  const { state, dispatch } = useUnapproveEmployeeParams({
    page: 1,
    pageSize: 10,
  });

  const { data, status } = useUnapprovedEmployees(
    PULLOFF_TYPES.WEEKLY,
    crewId,
    state
  );

  const displayName = crewData?.items[0]?.payApprovers[0]?.displayName;
  const email = crewData?.items[0]?.payApprovers[0]?.emailAddress;

  const handleClose = () => {
    dispatch({ type: 'clearSearch' });
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'search', payload: e.target.value });
  };
  const handlePageChange = (s: { selected: number }) => {
    dispatch({
      type: 'page',
      payload: s.selected + 1,
    });
  };
  const handlePageSizeChange = (ps: number) => {
    dispatch({ type: 'pageSize', payload: ps });
  };

  const { data: orgDetails } = useOrgDetails(crewId);

  return (
    <>
      <Page.Section>
        {crewDetailStatus === 'pending' && (
          <CardsLoader variant="row-grid" count={1} />
        )}
        {crewDetailStatus === 'success' && (
          <Detailscard
            name={displayName}
            email={email}
            crewKey={formatCrewKeyFromCode(orgDetails?.code)}
            crewDescription={orgDetails?.crewDescription}
          />
        )}
      </Page.Section>

      <Page.Section>
        <div className="w-full">
          <div className="w-full mb-2 sm:mb-0 sm:w-4/12 ">
            <SearchInput
              id="search-input"
              label="search"
              placeholder="Search Name / USS ID"
              value={state.search ?? ''}
              onChange={handleSearchChange}
              onClose={handleClose}
            />
          </div>
        </div>
      </Page.Section>
      <Page.Section>
        {status === 'pending' && (
          <CardsLoader variant="row-grid" count={9} hasAvatar />
        )}
        {status === 'success' && (
          <CardList<UnapprovedCrewEmployee>
            items={data.items}
            variant="row-grid"
            renderItem={(employee) => (
              <UnapprovedCrewEmployeeCard unapprovedCrewEmp={employee} />
            )}
          />
        )}
      </Page.Section>
      <Page.Section bg="gray">
        {!!data?.totalCount && (
          <Pagination
            pageOptions={[5, 10, 15, 20, 25, 30]}
            totalCount={data.totalCount}
            page={data.page}
            pageSize={data.pageSize}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        )}
      </Page.Section>
    </>
  );
};
