import { StateCreator } from 'zustand';
import { ProbationaryEmployeesStore } from '../store';
import { ProbEmployeesSlice, ProbEmployeesTabSlice } from './prob-employees';
import { LowRatingSlice, LowRatingTabSlice } from './low-rating';
import {
  ReviewPerformanceSlice,
  ReviewPerformanceTabSlice,
} from './review-performance';
import { HrLrTabs } from 'features/probationary-tracking/types';

interface TabSpecificState {
  probEmployees: ProbEmployeesSlice;
  lowRating: LowRatingSlice;
  reviewPerformance: ReviewPerformanceSlice;
}
export interface HrLrSliceType extends TabSpecificState {
  currentTab?: HrLrTabs;
  setCurrentTab: (value: HrLrTabs) => void;
}

export const HrLrSlice: StateCreator<
  ProbationaryEmployeesStore,
  [],
  [],
  HrLrSliceType
> = (...args) => {
  const [set] = args;
  return {
    setCurrentTab: (value) =>
      set(({ hrLr }) => ({
        hrLr: { ...hrLr, currentTab: value },
      })),
    probEmployees: { ...ProbEmployeesTabSlice(...args) },
    lowRating: { ...LowRatingTabSlice(...args) },
    reviewPerformance: { ...ReviewPerformanceTabSlice(...args) },
  };
};
