import { CrewTimeCard } from '../types';

const getNoPayEmployees = (employees: CrewTimeCard[]) => {
  return employees.filter(
    (emp) =>
      emp.totalHours.approved === 0 &&
      emp.totalHours.worked &&
      emp.totalHours.worked > 0
  );
};

export default getNoPayEmployees;
