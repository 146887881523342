import React, { useEffect, useState } from 'react';
import useWindowSize from 'hooks/useWindowSize';
import { BsExclamationCircle, BsInfoCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { usePaySchBulkAction } from 'features/timecards/api/getMassApprovals';
import {
  BulkActionRequestBody,
  BulkActionResponse,
  CrewTimeCard,
  ActionType,
} from 'features/timecards/types';
import useNotification from 'components/Notification/useNotification';
import useModal from 'components/Modals/use-modal';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import EmployeeCards from './EmployeeCards';
import CrewTimecardActions from './CrewTimecardActions';
import EmployeeTimecardTable from './EmployeeTimecardTable';
import { PayPeriodStatus, PAY_PERIOD_STATUS } from 'features/pay-periods';
import { formatCrewKeyFromCode } from 'utils/formatCrewKey';
import getNoPayEmployees from 'features/timecards/utilities/getNoPayEmployees';

interface CrewEmployeeTableProps {
  employees: CrewTimeCard[] | undefined;
  isLoadingTimecards: boolean;

  payPeriodStatus: PayPeriodStatus | undefined;
  plantType?: string | null;
}
const CrewTimecards = ({
  employees = [],

  isLoadingTimecards,
  payPeriodStatus,
  plantType,
}: CrewEmployeeTableProps) => {
  // Get Window Size
  const { width } = useWindowSize();

  // Get State Params

  const {
    week,
    crewId,
    selectedCrewKey,
    page,
    pageSize,
    search,
    status,
    selectedEmployees,
    toggleEmployee,
    toggleAllEmployees,
    clearEmployees,
    feature,
  } = useNonUnionStore((state) => ({
    week: state.params.week,
    crewId: state.crew?.id,
    selectedCrewKey: formatCrewKeyFromCode(state.crew?.code),
    page: state.params.page,
    pageSize: state.params.pageSize,
    search: state.params.search,
    status: state.params.status,
    selectedEmployees: state.selectedEmployees,
    toggleEmployee: state.toggleEmployee,
    toggleAllEmployees: state.toggleAllEmployees,
    clearEmployees: state.clearEmployees,
    feature: state.feature,
  }));

  // Unchecks all rows and resets errors when filter state changes
  useEffect(() => {
    setAllActive(false);
    clearEmployees();
    setEmployeeErrors([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [week, crewId, page, pageSize, search, status]);

  // Row Selection
  const [allActive, setAllActive] = useState(
    selectedEmployees.length > 0 &&
      selectedEmployees.length === employees.length
      ? true
      : false
  );

  // Used to navigate to new page when row is clicked, ignoring checkbox clicks.  Checkbox registers a row click because it is in the row.
  const navigate = useNavigate();
  const handleRowClick = (id: number, target: Element) => {
    const targetType = target.getAttribute('type');
    if (targetType !== 'checkbox') {
      if (feature === 'pay') {
        navigate(`/non-union-pay/employee/${id}`);
      } else {
        navigate(`/non-union-scheduling/employee/${id}`);
      }
    }
  };

  // Bulk Approve/Unapprove Modal

  const modal = useModal();

  // Errors from bulk action
  const [employeeErrors, setEmployeeErrors] = useState<string[]>([]);

  // Bulk Actions

  // ** Notifications for bulk actions
  const notify = useNotification();

  // ** Bulk action mutation to update the status
  const updateEmployeeStatus = usePaySchBulkAction(crewId ? crewId : '');

  // Handling Update for Bulk Actions
  const handleUpdate = (status: ActionType) => {
    const payload: BulkActionRequestBody = {
      timecardIds: selectedEmployees.map((e) => e.timecardId),
      status: status,
    };
    updateEmployeeStatus.mutate(payload, {
      onSuccess: (data: BulkActionResponse) => {
        setEmployeeErrors([]);
        const errorList = data.items.filter((item) => !item.success);
        errorList.forEach((err) => {
          const id = err.timecardId;
          setEmployeeErrors((employeeErrors) => [...employeeErrors, id]);
        });

        if (errorList.length === data.items.length) {
          notify.danger({
            message: `All ${errorList.length} selected timecards for ${selectedCrewKey} failed - Please review/retry.`,
          });
        } else if (errorList.length) {
          clearEmployees();
          setAllActive(false);
          notify.warning({
            message: (
              <>
                <p>
                  {errorList.length} timecard for crew {selectedCrewKey} failed
                  - Please review/retry.
                </p>
                <p>
                  {data.items.length - errorList.length} timecards were {status}
                  .
                </p>
              </>
            ),
          });
        } else
          notify.success({
            message: `You have successfully ${status} ${data.items.length} timecards that were selected for crew ${selectedCrewKey} `,
          });
        clearEmployees();
        setAllActive(false);
      },
    });

    modal.closeModal();
  };

  // Handle Checkbox
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let updatedEmployees: CrewTimeCard[] = Object.assign([], employees);

    if (plantType === 'integrated_mill') {
      updatedEmployees = updatedEmployees.filter(
        (employee: CrewTimeCard) => employee.payPeriodStatus !== 'not-open'
      );
    }

    const allEmployees = updatedEmployees.map((e) => {
      return { ussId: e.ussId, timecardId: e.id };
    });
    setEmployeeErrors([]);
    const selectedCheckbox = e.target.id;
    if (selectedCheckbox === 'select-all' && !allActive) {
      const selectedEmployee = employees.filter(
        (ctc) =>
          plantType !== 'integrated_mill' ||
          ctc.payPeriodStatus === PAY_PERIOD_STATUS.OPEN
      );
      const allSelectedEmployee = selectedEmployee.map((e) => {
        return { ussId: e.ussId, timecardId: e.id };
      });

      setAllActive(true);
      toggleAllEmployees(allSelectedEmployee);
    } else if (selectedCheckbox === 'select-all') {
      setAllActive(false);
      clearEmployees();
    } else {
      const selectedEmployee = allEmployees.find(
        (employee) => employee.timecardId === selectedCheckbox
      );
      setAllActive(false);
      if (selectedEmployee) {
        toggleEmployee(selectedEmployee);
      }
    }
  };

  const noPayEmployees = getNoPayEmployees(employees);

  return (
    <>
      <div
        className="flex flex-col sm:flex-row mb-3 sm:mb-4 justify-between"
        data-testid="info-msg"
      >
        <div className="flex items-center pb-4 sm:pb-0">
          <BsInfoCircle className="text-blue-500" />
          <span className="ml-2 text-sm text-gray-700">
            Select timecard to take an action
          </span>
        </div>
        {payPeriodStatus === 'open' &&
          (noPayEmployees.length > 0 || employeeErrors.length > 0) && (
            <div className="flex items-center" data-testid="info-msg">
              <BsExclamationCircle
                className="text-yellow-700"
                size="1rem"
                aria-label="Error"
              ></BsExclamationCircle>

              <span className="ml-2 text-sm text-gray-700 flex items-center">
                Will result in no pay for Worked Hours
              </span>
            </div>
          )}
      </div>

      {width &&
        (width <= 768 ? (
          <EmployeeCards
            employees={employees}
            isLoadingTimecards={isLoadingTimecards}
            handleRowClick={handleRowClick}
            allActive={allActive}
            handleOnChange={handleOnChange}
            employeeErrors={employeeErrors}
            payPeriodStatus={payPeriodStatus}
            rowSelect
            plantType={plantType}
          />
        ) : (
          <EmployeeTimecardTable
            allActive={allActive}
            handleOnChange={handleOnChange}
            handleRowClick={handleRowClick}
            employees={employees}
            isLoadingTimecards={isLoadingTimecards}
            employeeErrors={employeeErrors}
            rowSelect
            payPeriodStatus={payPeriodStatus}
            plantType={plantType}
          />
        ))}
      {selectedEmployees.length !== 0 && selectedCrewKey && (
        <CrewTimecardActions
          crewKey={selectedCrewKey}
          handleUpdate={handleUpdate}
          selectedEmployees={selectedEmployees}
          setAllActive={setAllActive}
          totalEmpsNum={employees.length}
          selectedEmpsNum={selectedEmployees.length}
          showPayActions={feature === 'pay'}
          handleCancel={clearEmployees}
          payPeriodStatus={payPeriodStatus}
        />
      )}
    </>
  );
};

export default CrewTimecards;
