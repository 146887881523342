import React from 'react';
import { Accordion, Avatar } from '@uss/react-components';

import { getInitials } from 'utils';
import useWindowSize from 'hooks/useWindowSize';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';

import { Person } from 'features/people/types';
import getFormattedShiftAssignment from 'utils/getFormattedShiftAssignment';
interface Props {
  employee: Person;
  temp: boolean;
}

const EmployeeDetails = ({ employee, temp = false }: Props) => {
  const { width } = useWindowSize();

  const employeeDetailsComponent = (screenWidth: number) => {
    return screenWidth < 1084 ? (
      <Accordion
        variant="default"
        className="shadow-md bg-white rounded-lg border border-blue-600"
      >
        <Accordion.Button variant="default">
          <DetailsHeader employee={employee} temp={temp} />
        </Accordion.Button>
        <Accordion.Panel variant="default">
          <div className="flex flex-row flex-wrap w-full  items-center border-t border-gray-200 pt-4">
            <DetailsBody employee={employee} />
          </div>
        </Accordion.Panel>
      </Accordion>
    ) : (
      <div
        className="flex items-center shadow-md w-full p-4 bg-white rounded-lg h-24 border border-blue-600"
        data-testid="employee-details-card"
      >
        <DetailsHeader employee={employee} temp={temp} />
        <div className="bg-gray-200 w-0.5 h-full mx-6"></div>
        <DetailsBody employee={employee} />
      </div>
    );
  };

  return <>{width && employeeDetailsComponent(width)}</>;
};

const DetailsHeader = ({
  employee,
  temp,
}: {
  employee: Person;
  temp: boolean;
}) => {
  return (
    <div
      className="w-full lg:w-2/6 2xl:w-1/6 "
      data-testid="employee-details-card-header"
    >
      <div className="flex items-center">
        <span>
          <Avatar>{getInitials(employee.firstName, employee.lastName)}</Avatar>
          {temp && <p className="text-xs flex-grow">Temp</p>}
        </span>
        <div className="ml-4">
          <Title as="h2">{`${employee.displayName ?? '-'}`}</Title>
          <p className="text-gray-700 text-xs text-left">{employee.ussId}</p>

          <p className="text-gray-700 text-xs text-left">
            {`${employee.jobTitle ?? '-'}`}
          </p>
        </div>
      </div>
    </div>
  );
};

const DetailsBody = ({ employee }: { employee: Person }) => {
  return (
    <>
      <div
        className="flex flex-wrap sm:flex-nowrap w-full lg:w-4/5"
        data-testid="employee-details-card-body"
      >
        <LabeledText
          className="w-1/2 sm:flex-grow mr-0 sm:mr-4 mb-4 sm:mb-0"
          label="Payroll Location"
          lineClamp={2}
        >
          {employee.payrollLocation} - {employee.payrollLocationDescription}
        </LabeledText>
        <LabeledText
          className="w-1/2 sm:flex-grow mr-0 sm:mr-4 mb-4 sm:mb-0"
          label="Payroll Cycle"
          lineClamp={2}
        >
          {employee.payrollName}
        </LabeledText>
        <LabeledText
          className="w-1/2 sm:flex-grow mr-0 sm:mr-4 mb-4 sm:mb-0"
          label="Employee Class"
          lineClamp={2}
        >
          {`${employee.empClass ?? '-'} - ${employee.empClassDesc ?? '-'}`}
        </LabeledText>
        <LabeledText
          className="w-1/2 sm:flex-grow mr-0 sm:mr-4 mb-4 sm:mb-0"
          label="Shift Assignment"
          lineClamp={2}
        >
          {employee.shiftAssignment
            ? getFormattedShiftAssignment(employee.shiftAssignment)
            : '-'}
        </LabeledText>
      </div>
    </>
  );
};

export default EmployeeDetails;
