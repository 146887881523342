import Page from 'components/Layouts/Page';
import { ErrorBoundary } from 'react-error-boundary';
import Error from 'components/Error/Error';
import { useParams } from 'react-router-dom';
import EmployeeDetailsSummary from 'features/probationary-tracking/components/common/EmployeeDetailsSummary/EmployeeDetailsSummary';
import EmployeeDetailsSummaryLoader from 'features/probationary-tracking/components/common/EmployeeDetailsSummary/EmployeeDetailsSummaryLoader';
import { usePerformanceReviewDetailsSummary } from 'features/probationary-tracking/api/getPerfReviewDetailsSummary';
import { ReviewPerformanceCards } from 'features/probationary-tracking/components/common/ReviewPerformanceCards/ReviewPerformanceCards';
import CardsLoader from 'components/Loaders/CardsLoader';

const EmployeeDetails = () => {
  return (
    <Page heading={'Employee Details'}>
      <ErrorBoundary
        fallbackRender={({ error }) => {
          return (
            <Error
              width="300"
              height="300"
              title="Something went wrong!"
              description={error.message}
            />
          );
        }}
      >
        <Content />
      </ErrorBoundary>
    </Page>
  );
};
const Content = () => {
  const { ussId = '' } = useParams<'ussId'>();
  const { data, status } = usePerformanceReviewDetailsSummary(ussId);

  return (
    <>
      <Page.Section>
        <div data-testid="employee-details-summary-card">
          {status === 'pending' && <EmployeeDetailsSummaryLoader />}
          {status === 'success' && <EmployeeDetailsSummary data={data} />}
        </div>
      </Page.Section>
      <Page.Section>
        {status === 'pending' && <CardsLoader count={3} />}
        {status === 'error' && <Error />}
        {status === 'success' && (
          <ReviewPerformanceCards reviewDetails={data.reviewDetails} />
        )}
      </Page.Section>
    </>
  );
};
export default EmployeeDetails;
