import React from 'react';
const variants = {
  yellow: ['bg-yellow-100'],
  green: ['bg-green-100'],
  gray: ['bg-gray-100'],
  purple: ['bg-purple-100'],
  red: ['bg-red-100'],
  primary: ['bg-primary-100'],
  blue: ['bg-blue-100'],
};
interface BadgeProps {
  variant: keyof typeof variants;
  children?: React.ReactNode;
}
const Badge = ({ variant, children }: BadgeProps) => {
  return (
    <span
      className={`w-auto inline-flex items-center text-gray-900 justify-center px-2 py-1 mr-2 last-of-type:mr-0 mb-2 text-xs 2xl:text-sm rounded-full ${variants[variant][0]} `}
    >
      {children}
    </span>
  );
};
export default Badge;
