import { SelectedEmployee } from 'features/timecards/types';

const isEmployeeTimecardSelected = (
  selectedEmployeeList: SelectedEmployee[],
  timecardId: string
) => {
  const employeeResult = selectedEmployeeList.find(
    (employee) => employee.timecardId === timecardId
  );
  return !!employeeResult;
};
export default isEmployeeTimecardSelected;
