import { PayrollLocation } from "../types";
import { OrgList } from "features/org";

export const getLocationList = (locationList: OrgList) => {
  const locArr: PayrollLocation[] = [];
  if (locationList.items.length > 0) {
      const allOption: PayrollLocation = {
        id: 'all',
        location: 'All',
        locationDescription: ''
      };
      locArr.push(allOption)
      locationList.items.forEach(data => {
        const obj: PayrollLocation = { id: data.id, location: data.location, locationDescription: data.locationDescription };
        locArr.push(obj);
      })
  }
  return locArr
}
