import React from 'react';
import { PtoDetail, usePtoCalendarDates } from 'features/paid-time-off';
import { CalendarEvent } from 'components/CalendarV2/types';
import { useParams } from 'react-router-dom';
import { useEmployeePtoStore } from 'features/paid-time-off/state/mypto-store';
import { PTOCardList } from 'features/paid-time-off/components/PTOCardList';
import { EmployeePtoCalendar } from './EmployeePtoCalendar';
import { EmployeePtoFilters } from './EmployeePtoFilters';
import { useEmployeePtoDetails } from 'features/paid-time-off/api/queries';

export const EmployeePtoDetails = () => {
  const { id = '' } = useParams<'id'>();
  const { params, calendarType, ptoDetailsView } = useEmployeePtoStore();
  const { data, isLoading } = useEmployeePtoDetails(id, params);
  //get events
  const events: CalendarEvent[] = usePtoCalendarDates(
    data ? data.items : ([] as PtoDetail[]),
    calendarType
  );

  return (
    <div className="grid grid-cols-1 gap-4">
      <EmployeePtoFilters />

      {ptoDetailsView === 'calendar' ? (
        <EmployeePtoCalendar events={events} />
      ) : (
        <PTOCardList records={data?.items} loading={isLoading} links />
      )}
    </div>
  );
};
