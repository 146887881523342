import { PTO_STATUS } from '../constants';
import { PtoStatus } from '../types';
import {
  BsArrowCounterclockwise,
  BsCheckLg,
  BsClockHistory,
  BsGear,
  BsSlashCircle,
  BsXLg,
} from 'react-icons/bs';

export const getPtoStatusIcon = (status: PtoStatus) => {
  switch (status) {
    case PTO_STATUS.ADJUSTMENT: {
      return <BsGear />;
    }
    case PTO_STATUS.APPROVED: {
      return <BsCheckLg />;
    }
    case PTO_STATUS.COMPANY_BLACKOUT: {
      return <BsSlashCircle />;
    }
    case PTO_STATUS.DENIED: {
      return <BsXLg />;
    }
    case PTO_STATUS.REQUESTED: {
      return <BsClockHistory />;
    }
    case PTO_STATUS.TAKEN: {
      return <BsArrowCounterclockwise />;
    }
  }
};
