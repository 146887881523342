import { QueryStatus } from '@tanstack/react-query';
import { Select } from '@uss/react-components';
import Loader from 'components/Skeleton/Loader';
import { Org } from 'features/org/types';
import { useEffect, useId } from 'react';
import { formatCrewKey } from 'utils/formatCrewKey';

type CondensedOrg = Pick<
  Org,
  'id' | 'location' | 'division' | 'department' | 'crew'
>;

interface CrewSelectProps<T> {
  crews: T[];
  selectedCrew: T | null;
  setCrew: (value: T) => void;
  crewStatus: QueryStatus;
  disabled?: boolean;
  showLabel?: boolean;
}

export function CrewSelect<T extends CondensedOrg>({
  crews,
  selectedCrew,
  setCrew,
  crewStatus,
  disabled = false,
  showLabel = false,
}: CrewSelectProps<T>) {
  const selected = crews.find((c) => !!c.id && c.id === selectedCrew?.id);
  const selectId = useId();
  const generateLabel = () => {
    if (crews.length === 0) return 'No Crews Available';
    if (selectedCrew === null || selected === undefined)
      return 'No Crew Selected';

    return formatCrewKey(
      selected.location,
      selected.division,
      selected.department,
      selected.crew
    );
  };

  // If the user does not have access to the selected crew, then default to the first crew in the users crew list
  useEffect(() => {
    if (
      crewStatus === 'success' &&
      crews.length > 0 &&
      !crews.find((c) => !!c.id && c.id === selectedCrew?.id)
    ) {
      setCrew(crews[0]);
    }
  });

  return (
    <>
      {crewStatus === 'pending' ? (
        <div className="w-full">
          <Loader isFilter={true} />
        </div>
      ) : (
        <>
          <label
            htmlFor={selectId}
            className={`text-xs text-gray-700 mt-1 ${
              showLabel ? '' : 'sr-only'
            }`}
          >
            Crew
          </label>
          <Select
            selected={selectedCrew}
            onSelect={(org: T) => setCrew(org)}
            className="w-full"
            disabled={crews.length === 0 || disabled}
            data-testid="crew-select-filter"
            id={selectId}
          >
            <Select.Button label={generateLabel()} />
            <Select.Options>
              {crews.map((crewItem, index) => {
                return (
                  <Select.Option value={crewItem} key={index}>
                    {formatCrewKey(
                      crewItem.location,
                      crewItem.division,
                      crewItem.department,
                      crewItem.crew
                    )}
                  </Select.Option>
                );
              })}
            </Select.Options>
          </Select>
        </>
      )}
    </>
  );
}
