import { format, parseISO } from 'date-fns';
import React from 'react';
import Calendar from 'components/CalendarV2/Calendar';
import { Holiday, useHolidays } from 'features/holidays';
import { usePersonDetails } from 'features/people/api';
import { CalendarEvent } from 'components/CalendarV2/types';
import { PtoDetail, usePtoCalendarHolidays } from 'features/paid-time-off';
import { useMyPtoStore } from 'features/paid-time-off/state/mypto-store';
import { PtoMonthContent } from 'features/paid-time-off/components/Calendar/PtoMonthContent';
import { PtoYearContent } from 'features/paid-time-off/components/Calendar/PtoYearContent';
import { PTOCardList } from '../PTOCardList';

export function MyPtoCalendar({
  events,
  records,
  loading,
}: {
  events: CalendarEvent[];
  records?: PtoDetail[];
  loading?: boolean;
}) {
  const {
    selectedDate,
    params,
    calendarType,
    setCalenderSelection,
    parsedDate,
  } = useMyPtoStore();

  // ****** Holidays, all code in this block is only used for Holidays *********
  const { data: loggedInPerson } = usePersonDetails('me');
  //get holiday data to show on calendar
  const { data: holidayData } = useHolidays(
    loggedInPerson?.holidayType ?? 'brs-observed'
  );
  const holidays =
    holidayData && holidayData.items.length > 0
      ? holidayData.items
      : ([] as Holiday[]);

  //create calendar holiday object array from holiday list
  const holidayList = usePtoCalendarHolidays(holidays);

  // *******************END Holidays**********************************
  //calendar month/year click event
  const handleDateClick = (date: Date) => {
    setCalenderSelection(date);
  };
  return (
    <>
      <Calendar
        date={parsedDate()}
        view={calendarType}
        events={events}
        holidays={holidayList}
        selected={new Date(parseISO(selectedDate))}
        handleDateClick={handleDateClick}
        monthContent={PtoMonthContent}
        yearContent={PtoYearContent}
      />
      {calendarType === 'month' &&
        window.innerWidth <= 768 &&
        params.month === format(new Date(selectedDate), 'MM') &&
        params.year === format(new Date(selectedDate), 'yyyy') && (
          <PTOCardList records={records} loading={loading} />
        )}
    </>
  );
}
