import { intersection } from 'lodash';
import { ROLES } from 'features/roles';
import {
  AddPTOPlantType,
  IntegratedEmployeeClass,
} from '../types/employee-pto';

const isUserRoleValid = (roles: string[]) => {
  if (
    intersection(roles, [
      ROLES.SUPER_ADMIN,
      ROLES.SUPER_PAYROLL_ADMIN,
      ROLES.PAYROLL_ADMIN,
      ROLES.T_A_PLANT_COORDINATOR,
      ROLES.PAY_APPROVER,
    ]).length > 0
  )
    return true;
  else return false;
};

export const isEligibleToAddPTO = (
  roles: string[],
  plantType: string | undefined,
  empClass: string | null,
  ptoClass: string | null
) => {
  const value = isUserRoleValid(roles);
  if (value) {
    if (ptoClass === 'ineligible') return false;
    else
      switch (plantType) {
        case AddPTOPlantType.miniMill: {
          return true;
        }
        case AddPTOPlantType.integratedMill: {
          if (
            empClass === IntegratedEmployeeClass[3] ||
            empClass === IntegratedEmployeeClass[6]
          )
            return true;
          return false;
        }
        case AddPTOPlantType.office: {
          if (
            empClass === IntegratedEmployeeClass[3] ||
            empClass === IntegratedEmployeeClass[6]
          )
            return true;
          return false;
        }
        default:
          return false;
      }
  }
};
