import React from 'react';
import { Avatar, Link } from '@uss/react-components';
import Card from 'components/Card/Card';
import SeparatedLabels from 'components/SeparatedLabels/SeparatedLabels';

import { NavLink } from 'react-router-dom';
import { Person } from 'features/people/types';
import { getInitials, avatarColor } from 'utils';
import Title from 'components/Typography/Title';
import LabeledText from 'components/Typography/LabeledText';

interface Props {
  person: Person;
}

const EmployeeSearchCard = ({ person }: Props) => {
  const empClass =
    person.empClass && person.empClassDesc
      ? `${person.empClass}-${person.empClassDesc}`
      : '---';
  return (
    <Card>
      <Link
        as={NavLink}
        className="block cursor-pointer"
        key={person.ussId}
        to={`${person.ussId}`}
      >
        <Card.Body>
          <div className="sm:flex w-full h-full flex-col">
            <div className="flex items-center w-full lg:w-2/5 2xl:w-3/12 relative lg:pr-6">
              <Avatar color={avatarColor(person.ussId)} size="medium">
                {getInitials(person.firstName, person.lastName)}
              </Avatar>
              <div className="pl-3 w-full">
                <Title as="h2">{person.displayName}</Title>

                <SeparatedLabels
                  size="md"
                  items={[
                    { label: 'USS ID', value: person.ussId, width: 'w-20' },
                    {
                      label: 'Primary Crew',
                      value: person.primaryCrew,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="bg-gray-200 w-full h-0.5 mx-0 my-3"></div>
            <div className="flex flex-wrap justify-between lg:flex-nowrap w-full lg:w-full pr-6">
              <LabeledText
                label="Plant"
                lineClamp={1}
                className="w-1/4 mb-4 lg:mb-0 mr-36"
              >
                {person.hrLocation}
              </LabeledText>
              <LabeledText
                label="Payroll Location"
                lineClamp={1}
                className="w-1/4 mb-4 lg:mb-0 mr-8"
              >
                {person.payrollLocation} - {person.payrollLocationDescription}
              </LabeledText>
              <LabeledText
                label="Employee Class"
                lineClamp={1}
                className="w-1/5 mb-4 lg:mb-0 pr-12 mr-8"
              >
                {empClass}
              </LabeledText>
              <LabeledText
                label="Job Title"
                lineClamp={1}
                className="w-full lg:w-5/12 mb-4 last-of-type:mb-0 lg:mb-0"
              >
                {person.jobTitle}
              </LabeledText>
            </div>
          </div>
        </Card.Body>
      </Link>
    </Card>
  );
};

export default EmployeeSearchCard;
