import { SortDirection } from 'types/common';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { PaycodeOptions, SORT_BY } from '../api/getPaycodes';
import {
  SortBy,
  ApplicableEmpType,
  PaycodeStatus,
  PaycodeType,
} from 'features/paycode';

interface PaycodeListState {
  params: PaycodeOptions;
  setType: (value: PaycodeType | 'all') => void;
  setApplicableEmpType: (value: ApplicableEmpType | 'all') => void;
  setPage: (value: number) => void;
  setPageSize: (value: number) => void;
  setSortBy: (value: SortBy) => void;
  setSort: (val: { type: SortBy; direction: SortDirection }) => void;
  setSortType: (value: SortDirection) => void;
  setStatus: (value: PaycodeStatus | 'all') => void;
  setSearch: (value: string) => void;
  setClearSearch: () => void;
}

export const usePaycodeListStore = create<PaycodeListState>()(
  devtools(
    immer((set) => ({
      params: {
        page: 1,
        pageSize: 10,
        sortBy: SORT_BY.CODE,
        sortType: SortDirection.ASC,
      },
      setType: (value) =>
        set(
          (state) => {
            if (value.length > 0 && value !== 'all') {
              state.params.type = value;
            } else delete state.params.type;
            state.params.page = 1;
          },
          false,
          'set-paycode-type'
        ),
      setApplicableEmpType: (value) =>
        set(
          (state) => {
            if (value.length > 0 && value !== 'all') {
              state.params.applicableEmpType = value;
            } else delete state.params.applicableEmpType;
            state.params.page = 1;
          },
          false,
          'set-paycode-applicableEmpType'
        ),
      setPage: (value) =>
        set(
          (state) => {
            state.params.page = value;
          },
          false,
          'set-paycode-page'
        ),
      setPageSize: (value) =>
        set(
          (state) => {
            state.params.pageSize = value;
            state.params.page = 1;
          },
          false,
          'set-paycode-pageSize'
        ),
      setSortBy: (value) =>
        set(
          (state) => {
            state.params.sortBy = value;
          },
          false,
          'set-paycode-pageSize'
        ),
      setSort: (value) =>
        set(
          (state) => {
            state.params.sortBy = value.type;
            state.params.sortType = value.direction;
            state.params.page = 1;
          },
          false,
          'set-sort'
        ),
      setSortType: (value) =>
        set(
          (state) => {
            state.params.sortType = value;
          },
          false,
          'set-paycode-pageSize'
        ),
      setStatus: (value) =>
        set(
          (state) => {
            if (value === 'all') {
              delete state.params.status;
            } else {
              state.params.status = value;
            }
          },
          false,
          'set-paycode-status'
        ),
      setSearch: (value) =>
        set(
          (state) => {
            if (value.length > 0) {
              state.params.search = value;
            } else delete state.params.search;
            state.params.page = 1;
          },
          false,
          'set-paycode-search'
        ),
      setClearSearch: () =>
        set(
          (state) => {
            delete state.params.search;
            state.params.page = 1;
          },
          false,
          'set-paycode-clearsearch'
        ),
    }))
  )
);
