import { Button, Datepicker, Drawer, Input } from '@uss/react-components';
import { useEffect, useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import { useCreateMileage } from '../api/mutation';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { format } from 'date-fns';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateMileageEntrySchema } from '../schemas/create-mileage-entry';
import { CreateMileageRequest } from '../types/mileageEntry';
import useUserRoles from 'hooks/useUserRoles';
import { notify } from 'components/Notification/useNotification';
import { useEmployeeSecurity } from 'features/security';
import { queryClient } from 'services/api/react-query';

const AddMileage = () => {
  //Getting Roles
  const { uss_id } = useUserRoles();
  const [isOpen, setIsOpen] = useState(false);
  const { mutateAsync, isPending } = useCreateMileage();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CreateMileageRequest>({
    resolver: zodResolver(CreateMileageEntrySchema),
    defaultValues: { ussID: uss_id, miles: 0, travelDate: '' },
  });

  const milesVal = useWatch({ control, name: 'miles' });
  const travelDateVal = useWatch({ control, name: 'travelDate' });

  //Security API call to restrict users with no security records from creating new data
  const { data: securityRecord } = useEmployeeSecurity(uss_id);

  //On closing drawer reset all valuesS
  useEffect(() => {
    if (!isOpen) {
      resetValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const resetValues = () => {
    //resetting the values to default
    setValue('miles', 0);
    setValue('travelDate', '');
  };

  const handleAdd = async (data: CreateMileageRequest) => {
    await mutateAsync(
      {
        ussID: data.ussID,
        miles: data.miles,
        travelDate: data.travelDate,
      },
      {
        onSuccess: async () => {
          setIsOpen(false);
          resetValues();
          notify.success({
            message: 'New mileage entry has been submitted successfully.',
          });
          await queryClient.resetQueries({
            queryKey: ['mileage-details'],
            type: 'active',
          });
        },
      }
    );
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const num = Number(event.target.value);
    if (Number.isNaN(num)) {
      return;
    } else {
      setValue('miles', num);
    }
  };

  return (
    <>
      <div className="flex w-full sm:justify-end ">
        <Button
          className="flex flex-row justify-center items-center text-primary-400"
          variant="text"
          onClick={(): void => setIsOpen(true)}
          disabled={securityRecord && securityRecord.items.length === 0}
        >
          <BsPlus size="1.5rem" />
          <span>Add Mileage Entry</span>
        </Button>
      </div>
      <Drawer
        placement={window.innerWidth > 768 ? 'right' : 'bottom'}
        title="Add New Mileage Entry"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        closeButton={window.innerWidth > 768 ? false : true}
        className="w-full sm:w-1/2 md:w-1/3 xl:w-1/4 2xl:w-1/5 justify-between md:top-0 top-18 md:rounded-t-none rounded-t-md"
      >
        <form
          className="@container flex flex-1 flex-col justify-between"
          onSubmit={handleSubmit((e) => handleAdd(e))}
        >
          <div className="grid grid-rows-1 @xl:grid-rows-2 gap-5 mt-3">
            <Controller
              control={control}
              name="ussID"
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  id="ussId"
                  label="USS ID"
                  disabled={true}
                />
              )}
            />

            <Controller
              control={control}
              name="miles"
              render={({ field }) => (
                <Input
                  {...field}
                  id="miles"
                  label="Miles"
                  type="text"
                  inputMode="decimal"
                  disabled={false}
                  error={!!errors.miles?.message}
                  errorMessage={errors.miles?.message}
                  onChange={handleInputChange}
                />
              )}
            />

            <div>
              <Controller
                control={control}
                name="travelDate"
                render={({ field, fieldState: { invalid, error } }) => (
                  <Datepicker
                    isLabel={true}
                    ariaLabelledBy={'Date'}
                    onDateChange={(date) => {
                      field.onChange(format(date, 'yyyy-MM-dd'));
                    }}
                    calendarType={'date'}
                    id="travel-date"
                    isError={invalid}
                    errormessage={errors.travelDate?.message}
                  />
                )}
              />
            </div>
          </div>
          <div className="mt-4 sticky b-0">
            <Drawer.Controls>
              <Button
                className="md:mb-0 mb-2  w-full"
                variant="outline"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="md:ml-4 w-full"
                type="submit"
                disabled={!milesVal || !travelDateVal || isPending}
              >
                Add
              </Button>
            </Drawer.Controls>
          </div>
        </form>
      </Drawer>
    </>
  );
};

export default AddMileage;
