import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { WMSError } from 'types/common';
import { queryClient } from 'services/api/react-query';
import { notify } from 'components/Notification/useNotification';
import { axios } from 'services/api/axios';
import { Paycode, PayrollGroupSchema } from '../types';
import {
  CUSTOM_ERROR_MESSAGE,
  ZodPaycodeType,
} from 'features/paycode/constants';
import { useNavigate } from 'react-router-dom';
import { PayableOvertimeCheck } from '../validations/payable-overtime';
import { ReportableOvertimeCheck } from '../validations/reportable-overtime';
import { PayrollGroupRequiredCheck } from '../validations/payrollGroupRequiredCheck';
import { ShiftAssignmentRequiredCheck } from '../validations/shiftAssignmentReqiredCheck';

export const PaycodeUpdateBodySchema = z
  .object({
    id: z.string().nullable(),
    code: z
      .string({
        required_error: CUSTOM_ERROR_MESSAGE.emptyCode,
        invalid_type_error: CUSTOM_ERROR_MESSAGE.emptyCode,
      })
      .max(2, { message: CUSTOM_ERROR_MESSAGE.longerCode })
      .min(2, { message: CUSTOM_ERROR_MESSAGE.shorterCode })
      .min(1, { message: CUSTOM_ERROR_MESSAGE.emptyCode }),
    description: z
      .string({
        required_error: CUSTOM_ERROR_MESSAGE.emptyDescription,
        invalid_type_error: CUSTOM_ERROR_MESSAGE.emptyDescription,
      })
      .min(1, { message: CUSTOM_ERROR_MESSAGE.emptyDescription }),
    useCaseDescription: z
      .string({
        required_error: CUSTOM_ERROR_MESSAGE.emptyUseCaseDescription,
        invalid_type_error: CUSTOM_ERROR_MESSAGE.emptyUseCaseDescription,
      })
      .min(1, { message: CUSTOM_ERROR_MESSAGE.emptyUseCaseDescription }),
    type: ZodPaycodeType,
    earningsCode: z.string().nullable(),
    payrollGroups: PayrollGroupSchema.array(),
    comments: z
      .string({
        required_error: CUSTOM_ERROR_MESSAGE.emptyComments,
        invalid_type_error: CUSTOM_ERROR_MESSAGE.emptyComments,
      })
      .min(1, { message: CUSTOM_ERROR_MESSAGE.emptyComments }),
  })

  .refine((data) => PayableOvertimeCheck(data.payrollGroups, data.type), {
    message: CUSTOM_ERROR_MESSAGE.payableOvertimeCheck,
    path: ['payableOvertimeCheck'], // path of error
  })
  .refine((data) => ReportableOvertimeCheck(data.payrollGroups, data.type), {
    message: CUSTOM_ERROR_MESSAGE.reportableOvertimeCheck,
    path: ['reportableOvertimeCheck'], // path of error
  })
  .refine((data) => PayrollGroupRequiredCheck(data.payrollGroups), {
    message: CUSTOM_ERROR_MESSAGE.payrollGroupRequiredCheck,
    path: ['payrollGroupRequiredCheck'], // path of error
  })
  .refine((data) => ShiftAssignmentRequiredCheck(data.payrollGroups), {
    message: CUSTOM_ERROR_MESSAGE.shiftAssignmentRequiredCheck,
    path: ['shiftAssignmentRequiredCheck'], // path of error
  });

/* paycode PUT request*/
export type PaycodeUpdateBody = z.infer<typeof PaycodeUpdateBodySchema>;

const updatePaycode = (
  paycodeId: string,
  body: PaycodeUpdateBody
): Promise<Paycode> => {
  return axios.put(`/v3/paycodes/${paycodeId}`, body);
};

export const useUpdatePaycode = (
  paycodeId: string,
  action:
    | 'create'
    | 'request'
    | 'archive'
    | 'restore'
    | 'save'
    | 'approve'
    | 'deny'
) => {
  const navigate = useNavigate();

  return useMutation<Paycode, WMSError, PaycodeUpdateBody>({
    mutationFn: (body: PaycodeUpdateBody) => updatePaycode(paycodeId, body),
    meta: {
      errorMessage: 'Unable to update paycode.',
    },
    onSuccess: async () => {
      let msg = '';
      switch (action) {
        case 'approve':
          msg = 'You have successfully approved this pay code.';
          break;
        case 'deny':
          msg = 'You have denied this pay code.';
          break;
        default:
          msg = 'You have successfully saved the changes.';
      }
      await queryClient.refetchQueries({
        queryKey: [`get-paycodes-${paycodeId}`],
      });
      notify.success({ message: msg });
      navigate('/admin/paycodes');
    },
  });
};
