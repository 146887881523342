import Card from 'components/Card/Card';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';

interface Props {
  locationCode: string;
  locationName: string;
  probEmpCount: number | null;
}

export const BargainingUnitCard = ({
  locationCode,
  locationName,
  probEmpCount,
}: Props) => {
  return (
    <Card>
      <Card.Header>
        <div className="flex flex-col">
          <Title as="h2" aria-label="Payroll Location code">
            {locationCode ? locationCode : '-'}
          </Title>
          <LabeledText className="w-full" label="Payroll Location name">
            {locationName ? locationName : '-'}
          </LabeledText>
        </div>
      </Card.Header>
      <Card.Footer>
        <div className="flex justify-between items-center">
          <div className="text-sm text-gray-900 font-medium">
            Probationary Employees
          </div>
          <div
            className="text-xl text-gray-900"
            title="Probationary Employees"
            aria-label="Probationary Employees"
          >
            {probEmpCount !== null ? probEmpCount : '-'}
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
};
