import { OrgSecurityRecord } from 'features/org';
import { SecurityRecord } from 'features/security/types';
import React from 'react';
import { format } from 'date-fns';

interface EffExpDateProps {
  member: OrgSecurityRecord | SecurityRecord;
  isFutureEffectiveDate: boolean | null;
}

export const EffExpDate = ({
  member,
  isFutureEffectiveDate,
}: EffExpDateProps) => {
  return (
    <div data-testid="eff-exp-dt">
      {isFutureEffectiveDate && (
        <p aria-label="Effective Date" className="text-green-600 text-sm">
          Effective{' '}
          {format(
            member.effectiveDate ? new Date(member.effectiveDate) : new Date(),
            'MMM dd, yyyy'
          )}{' '}
        </p>
      )}
      {member.expirationDate && (
        <p aria-label="Expiring Date" className="text-red-600 text-sm">
          Expiring {format(new Date(member.expirationDate), 'MMM dd, yyyy')}
        </p>
      )}
    </div>
  );
};
