import { useReducer } from 'react';
import { UseCrewMembersOptions } from 'types/common';

export type AssignmentsFilterAction =
  | { type: 'page'; payload: string }
  | { type: 'pageSize'; payload: string };
export const useAssignmentsParams = (initialParams: UseCrewMembersOptions) => {
  const filterReducer = (
    state: UseCrewMembersOptions,
    action: AssignmentsFilterAction
  ): UseCrewMembersOptions => {
    switch (action.type) {
      case 'page': {
        state.page = action.payload;
        return { ...state };
      }
      case 'pageSize': {
        state.pageSize = action.payload;
        return { ...state };
      }

      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(filterReducer, initialParams);

  return { state, dispatch };
};
