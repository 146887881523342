import React, { useEffect } from 'react';
import Page from 'components/Layouts/Page';

import { Person } from 'features/people/types';
import { usePeople } from 'features/people/api';
import { Select, Link } from '@uss/react-components';
import CardsLoader from 'components/Loaders/CardsLoader';
import CardList from 'components/Card/CardList';
import { NavLink } from 'react-router-dom';
import Pagination from 'components/Pagination/Pagination';
import Error from 'components/Error/Error';
import { Org, ORG_TYPE } from 'features/org';
import { useEmployeeOrgs } from 'features/org/api';
import { SearchSortFilter, EmployeeCard } from 'features/crew-maintenance';
import { useCrewMaintenanceEmployeeStore } from 'features/crew-maintenance/state/store';
import NavLinkTabs from 'components/Tabs/NavLinkTabs';
import { findLocation, findPlant } from 'features/org/utilities/findOrg';

const EmpCardLink = ({
  emp,
  payrollLocation,
  plant,
}: {
  emp: Person;
  payrollLocation?: string;
  plant?: string;
}) => {
  return (
    <Link
      as={NavLink}
      to={emp.ussId.toString()}
      className="block cursor-pointer"
      key={emp.ussId}
    >
      {' '}
      {/* Add payrollLocation prop and set it to the payrollLocation value if the full payroll location description is needed, it should start working automatically */}
      <EmployeeCard
        employee={emp}
        payrollLocation={payrollLocation}
        plant={plant}
      />
    </Link>
  );
};

const Employee = () => {
  //Context ( Search and Sort )

  const store = useCrewMaintenanceEmployeeStore();

  // Plant Selection
  const { data: plantList, status: plantStatus } = useEmployeeOrgs(
    'me',
    { type: ORG_TYPE.PLANT, sortBy: 'plant', parentOrgs: 'y' },
    { refetchOnWindowFocus: false }
  );

  const { data: payrollLocations, status: locationStatus } = useEmployeeOrgs(
    'me',
    {
      type: ORG_TYPE.LOCATION,
      sortBy: 'location',
      parentOrgs: 'y',
      parentOrgId:
        store.selectedPlant && store.selectedPlant !== 'all'
          ? store.selectedPlant.id
          : undefined,
    }
  );

  // const plLabel = plantList?.items.find((p) => p.code === store.params.plant);

  if (
    store.selectedPlant !== 'all' &&
    store.selectedPlant?.plantType === 'mini_mill'
  ) {
    store.setExcludeEmpClass('4');
  } else {
    store.setExcludeEmpClass('');
  }

  //People Data
  const { data: employees, status } = usePeople(store.params, {
    refetchOnWindowFocus: false,
    enabled: plantStatus === 'success',
  });

  //  Setting the initial plant
  useEffect(() => {
    if (plantStatus === 'success') {
      if (!store.params.plant && store.selectedPlant === null) {
        store.setPlant(plantList.items[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantList, plantStatus]);

  // Handle change of the plant dropdown
  const handlePlantChange = (value: Org | 'all') => {
    store.setPlant(value);
  };

  const tabs = [
    {
      label: 'Employee',
      to: '/admin/crew-maintenance',
      isRootUrl: false,
    },
    {
      label: 'Crew',
      to: '/admin/crew-maintenance/crew',
      isRootUrl: true,
    },
  ];
  return (
    <Page
      heading={'Crew Maintenance'}
      breadcrumbs={[
        <NavLink key="bc-1" to="/admin">
          Admin
        </NavLink>,
      ]}
    >
      <Page.Section>
        <NavLinkTabs tabLinks={tabs} />
      </Page.Section>
      <Page.Section>
        <h1 className="sr-only">Crew Maintenance by Employee</h1>
        <SearchSortFilter />
        <div className="grid grid-cols-1 gap-2 mt-2 sm:grid-cols-3 sm:gap-4 sm:mt-4">
          <Select
            selected={store.params.plant ? store.params.plant : 'all'}
            onSelect={handlePlantChange}
            className="w-full"
          >
            <Select.Button
              label={
                store.selectedPlant !== 'all' &&
                store.selectedPlant &&
                store.selectedPlant.description
                  ? store.selectedPlant.description
                  : 'All Plants'
              }
            />
            <Select.Options>
              {<Select.Option value="all">All Plants</Select.Option>}
              {plantStatus === 'success' && plantList.items.length ? (
                plantList.items.map((item: Org, index: number) => (
                  <Select.Option truncate key={index} value={item}>
                    <span>{item.plant}</span>
                  </Select.Option>
                ))
              ) : (
                <span>No plants available</span>
              )}
            </Select.Options>
          </Select>
          <Select
            selected={
              store.selectedPayrollLocation &&
              store.selectedPayrollLocation !== 'all'
                ? store.selectedPayrollLocation.id
                : 'all'
            }
            onSelect={store.setPayrollLocation}
            className="w-full"
          >
            <Select.Button
              label={
                store.selectedPayrollLocation !== 'all' &&
                store.selectedPayrollLocation &&
                store.selectedPayrollLocation.description
                  ? `${store.selectedPayrollLocation.code} - ${store.selectedPayrollLocation.description}`
                  : 'All Payroll Locations'
              }
            />
            <Select.Options>
              <Select.Option value="all">All Payroll Locations</Select.Option>
              {locationStatus === 'success' && payrollLocations.items.length ? (
                payrollLocations.items.map((item: Org, index: number) => (
                  <Select.Option truncate key={index} value={item}>
                    <span>
                      {item.location} - {item.locationDescription}
                    </span>
                  </Select.Option>
                ))
              ) : (
                <span>No locations available</span>
              )}
            </Select.Options>
          </Select>
        </div>
      </Page.Section>
      <Page.Section>
        {status === 'pending' && (
          <CardsLoader count={9} hasAvatar contentRows={3} />
        )}
        {status === 'error' && <Error />}
        {status === 'success' && (
          <>
            <CardList<Person>
              items={employees.items}
              renderItem={(emp) => (
                <EmpCardLink
                  emp={emp}
                  payrollLocation={findLocation(
                    emp.payrollLocation,
                    payrollLocations?.items
                  )}
                  plant={findPlant(
                    emp.payrollLocation,
                    payrollLocations?.items
                  )}
                  key={emp.ussId}
                />
              )}
            />
            <div className="py-4">
              <Pagination
                pageOptions={[9, 18, 27, 36, 45, 54]}
                totalCount={employees.totalCount}
                page={employees.page}
                pageSize={employees.pageSize}
                onPageChange={(s) => store.setPage(`${s.selected + 1}`)}
                onPageSizeChange={(ps) => store.setPageSize(ps.toString())}
              />
            </div>
          </>
        )}
      </Page.Section>
    </Page>
  );
};

export default Employee;
