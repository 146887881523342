import {
  useAssignmentsParams,
  AssignmentsFilterAction,
} from 'features/crew-maintenance';
import React, { useContext, createContext } from 'react';

import { SortBy, SortDirection, UseCrewMembersOptions } from 'types/common';

//Creating Contexts
const AssignmentsContext = createContext<UseCrewMembersOptions>({});
const AssignmentsDispatchContext = createContext<
  React.Dispatch<AssignmentsFilterAction>
>(() => {
  return;
});

export const CrewDetailsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const initialParams: UseCrewMembersOptions = {
    page: '1',
    pageSize: '9',
    sortBy: SortBy.LASTNAME,
    sortType: SortDirection.ASC,
  };

  const { state: Assignments, dispatch: AssignmentsDispatch } =
    useAssignmentsParams({
      ...initialParams,
    });

  return (
    <AssignmentsContext.Provider value={Assignments}>
      <AssignmentsDispatchContext.Provider value={AssignmentsDispatch}>
        {children}
      </AssignmentsDispatchContext.Provider>
    </AssignmentsContext.Provider>
  );
};
// Custom hook to return the crew assignments context in details page
export function useAssignmentsPageParams() {
  return useContext(AssignmentsContext);
}

// Custom hook to return the crew assignments params update dispatch function
export function useAssignmentsParamsDispatch() {
  return useContext(AssignmentsDispatchContext);
}
