import { Avatar, Button } from '@uss/react-components';
import Card from 'components/Card/Card';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';
import { OrgSecurityRecord } from 'features/org';
import { BsPersonCheck } from 'react-icons/bs';
import { avatarColor, getInitials } from 'utils';

type ApproverCardSecurityRecord = Pick<
  OrgSecurityRecord,
  | 'ussId'
  | 'firstName'
  | 'lastName'
  | 'displayName'
  | 'crew'
  | 'department'
  | 'division'
  | 'location'
  | 'plant'
  | 'supervisorDisplayName'
>;

interface ApproversProps<T> {
  data: T;
  approverType: string;
  handleConfirm: (approverData: T) => void;
}

export function ApproverCard<T extends ApproverCardSecurityRecord>({
  data,
  approverType,
  handleConfirm,
}: ApproversProps<T>) {
  const getSecurityLevel = (data: ApproverCardSecurityRecord) => {
    if (data.crew) return 'Crew';
    else if (data.department) return 'Department';
    else if (data.division) return 'Division';
    else if (data.location) return 'Location';
    else if (data.plant) return 'Plant';
  };

  return (
    <Card>
      <div data-testid="employee-card">
        <Card.Header>
          <div className="flex items-center w-full">
            <Avatar color={avatarColor(data.ussId)} size="medium">
              {getInitials(data.firstName, data.lastName)}
            </Avatar>
            <div className="ml-3 flex-grow">
              <Title as="h2">{data.displayName}</Title>
              <p aria-label="ussId" className="text-gray-700 text-sm text-left">
                {data.ussId}
              </p>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="grid grid-cols-2 gap-4">
            <LabeledText label="Supervisor">
              {data.supervisorDisplayName}
            </LabeledText>
            <LabeledText label="Security Level">
              {getSecurityLevel(data)}
            </LabeledText>
          </div>
        </Card.Body>
        {approverType === 'secondary' && (
          <Card.Footer>
            <div className="flex justify-center">
              <Button
                variant="text"
                className="text-md text-primary-400 cursor-pointer"
                onClick={() => {
                  handleConfirm(data);
                }}
              >
                <BsPersonCheck
                  data-testid="primaryButtonIcon"
                  className="text-xl"
                />
                <span data-testid="primaryButtonText" className="text-md ml-2">
                  {' '}
                  Set as Primary
                </span>
              </Button>
            </div>
          </Card.Footer>
        )}
      </div>
    </Card>
  );
}
