import { useState } from 'react';
import Page from 'components/Layouts/Page';
import { format, parse } from 'date-fns';
import Calendar from 'components/CalendarV2/Calendar';
import { Holiday, useHoliday } from 'features/holidays';
import { SortDirection } from 'types/common';

import { appNewDate } from 'utils/appNewDate';
import {
  useGetCrewPtoByFilter,
  useGetCrewPtoTotalsByFilter,
} from 'features/paid-time-off/api/queries';
import {
  CrewPtoTotals,
  usePtoCalendarHolidays,
  useCrewPtoCalendarDates,
  PtoStatus,
} from 'features/paid-time-off';

import {
  BsArrowCounterclockwise,
  BsCheckLg,
  BsClockHistory,
  BsGear,
  BsSlashCircle,
  BsXLg,
} from 'react-icons/bs';
import IconBadge from 'components/Badge/IconBadge';
import { useCrewPtoStore } from 'features/paid-time-off/state';
import CalendarLoader from 'components/CalendarV2/CalendarLoader';
import { ORG_TYPE } from 'features/org';
import useUserRoles from 'hooks/useUserRoles';
import { useEmployeeOrgs } from 'features/org/api';
import useCrewPTORequests from 'features/paid-time-off/hooks/useCrewPTORequests';

import { CalendarEvent } from 'components/CalendarV2/types';
import Error from 'components/Error/Error';

import CrewPtoFilter from 'features/paid-time-off/components/Filters/crew-pto/CrewPtoFilter';
import CrewPtoMonthContent from 'features/paid-time-off/components/Calendar/crew-pto/CrewPtoMonthContent';
import PTORecordsList from 'features/paid-time-off/components/List/PTORecordsList';
import useOrgFilters from 'components/OrgFilters/use-org-filters';
import { NavLink } from 'react-router-dom';
import { PTO_STATUS } from 'features/paid-time-off/constants';
import CrewBlackoutActions from 'features/paid-time-off/components/CrewPto/CrewBlackoutActions';

const CrewPto = () => {
  //check current selected year/month and parse it to date
  const store = useCrewPtoStore();

  const { isSuperAdmin } = useUserRoles();
  const [employeeCount, setEmployeeCount] = useState(0);

  /* multiselect data */

  const role = isSuperAdmin
    ? 'super_admin,super_payroll_admin'
    : 'pay_approver,t_a_plant_coordinator,payroll_admin';
  const plants = useEmployeeOrgs('me', {
    type: ORG_TYPE.PLANT,
    sortBy: 'plant',
    sortType: SortDirection.ASC,
    role: role,
    context: 'summary',
  });

  const orgs = useEmployeeOrgs('me', {
    sortType: SortDirection.ASC,
    parentOrgId: store.selectedPlant?.id ?? undefined,
    role: role,
    context: 'summary',
  });

  const { payrollLocations, divisions, departments } = useOrgFilters({
    orgs: orgs.data?.items ? orgs.data.items : [],
    selectedPlant: store.selectedPlant,
    selectedPayrollLocation: store.selectedPayrollLocation,
    selectedDivision: store.selectedDivision,
    selectedDepartment: store.selectedDepartment,
  });

  const { totalsRequest, detailsRequest } = useCrewPTORequests({
    ussids: store.ussids,
    date: store.date,
    departments: departments,
    selectedCrew: store.selectedCrew,
    selectedDepartment: store.selectedDepartment,
    selectedDivision: store.selectedDivision,
    selectedDate: store.selectedDate,
    status: store.status,
  });

  const {
    data,
    status,
    fetchStatus: ptoFetchStatus,
  } = useGetCrewPtoTotalsByFilter(totalsRequest);

  const {
    data: orgDetails,
    status: orgDetailsStatus,
    fetchStatus,
  } = useGetCrewPtoByFilter(detailsRequest);

  //get events
  const events: CalendarEvent[] = useCrewPtoCalendarDates(
    data ? data.items : ([] as CrewPtoTotals[])
  );

  // get holiday list based on selected location type
  const { data: holidayData } = useHoliday(
    store.selectedPayrollLocation?.holidayType ?? []
  );
  //get holiday data to show on calendar
  const holidays: Holiday[] = [];
  if (holidayData.length > 0) {
    for (const holiday of holidayData) {
      holiday?.items.map((e) => holidays.push(e));
    }
  }

  //create calendar holiday object array from holiday list
  const holidayList = usePtoCalendarHolidays(holidays);

  //calendar month/year click event
  const handleDateClick = (date: Date) => {
    store.setSelectedDate(format(date, 'yyyy-MM-dd'));
  };

  const getStatusList = (date: string) => {
    let selectedPtoStatus: PtoStatus[] = [];

    let allStatus: PtoStatus[] = Object.values(PTO_STATUS);
    if (data && data.items.length > 0) {
      const selectedPto = data.items.filter((item) => item.date === date);

      if (selectedPto.length > 0) {
        Object.keys(selectedPto[0].totals).map((key: string) => {
          const _totals = selectedPto[0].totals;
          const keyTyped = key;
          if (_totals[keyTyped as PtoStatus] === 0) {
            allStatus = allStatus.filter((s) => s !== keyTyped);
            selectedPtoStatus = allStatus;
          }
          return 0;
        });
      }
    }
    return {
      allStatus: allStatus.sort(),
      selectedPtoStatus,
    };
  };

  const setEmpCount = (val: number) => {
    setEmployeeCount(val);
  };

  //function to get blackout count for selected date from calender
  const getCountForBlackouts = () => {
    if (data && data.items.length > 0 && store.selectedDate) {
      const record = data.items.filter(
        (entry) => entry.date === store.selectedDate
      );
      if (record.length > 0) return record[0]?.totals?.blackout;
      else return 0;
    } else return 0;
  };

  return (
    <>
      <Page
        heading="Crew PTO - Vacation"
        breadcrumbs={[
          <NavLink
            key="bc-1"
            className="flex flex-row justify-center items-center"
            to={`/paid-time-off`}
          >
            PTO - Vacation
          </NavLink>,
        ]}
      >
        <Page.Section>
          <CrewPtoFilter
            plants={plants}
            orgs={orgs}
            payrollLocations={payrollLocations}
            divisions={divisions}
            setEmpCount={setEmpCount}
          />

          {plants.status === 'success' &&
          (status === 'success' || ptoFetchStatus === 'idle') ? (
            <>
              <Calendar
                date={parse(store.date, 'yyyy-MM', appNewDate())}
                view="month"
                events={events}
                holidays={holidayList}
                selected={
                  store.selectedDate !== ''
                    ? parse(store.selectedDate, 'yyyy-MM-dd', appNewDate())
                    : appNewDate()
                }
                handleDateClick={handleDateClick}
                monthContent={CrewPtoMonthContent}
              />
              <div className="hidden sm:flex justify-between text-sm w-4/5">
                <span>
                  <IconBadge
                    color={'blue'}
                    badgeIcon={<BsArrowCounterclockwise />}
                    onlyIcon
                  />
                  <span className="ml-1">Taken</span>
                </span>
                <span>
                  <IconBadge color={'yellow'} badgeIcon={<BsGear />} onlyIcon />
                  <span className="ml-1">Adjustment</span>
                </span>
                <span>
                  <IconBadge
                    color={'purple'}
                    badgeIcon={<BsClockHistory />}
                    onlyIcon
                  />
                  <span className="ml-1">Requested</span>
                </span>
                <span>
                  <IconBadge
                    color={'green'}
                    badgeIcon={<BsCheckLg />}
                    onlyIcon
                  />
                  <span className="ml-1"> Approved</span>
                </span>
                <span>
                  <IconBadge color={'red'} badgeIcon={<BsXLg />} onlyIcon />
                  <span className="ml-1">Denied</span>
                </span>
                <span>
                  <IconBadge
                    color={'gray'}
                    badgeIcon={<BsSlashCircle />}
                    onlyIcon
                  />
                  <span className="ml-1">Blackout</span>
                </span>
              </div>
              <CrewBlackoutActions
                detailsRequest={detailsRequest}
                totalsRequest={totalsRequest}
                selectedDatePtoList={
                  getStatusList(store.selectedDate).selectedPtoStatus
                }
                blackoutCount={getCountForBlackouts()}
                totalEmpCount={employeeCount}
              />
            </>
          ) : (
            <>
              {(status === 'error' || plants.status === 'error') && <Error />}
            </>
          )}
          {((ptoFetchStatus === 'fetching' && status === 'pending') ||
            plants.status === 'pending') && <CalendarLoader />}
        </Page.Section>
        <Page.Section>
          {store.selectedDate !== '' && (
            <PTORecordsList
              selectedDate={store.selectedDate}
              orgDetails={orgDetails?.items}
              orgDetailsStatus={orgDetailsStatus}
              statusList={getStatusList(store.selectedDate).allStatus}
              fetchStatus={fetchStatus}
              detailsRequest={detailsRequest}
              totalsRequest={totalsRequest}
            />
          )}
        </Page.Section>
      </Page>
    </>
  );
};

export default CrewPto;
