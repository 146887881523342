import { format, isAfter, parseISO } from 'date-fns';
import {
  PaycodeOptions,
  ShiftAssignment,
  PLANT_TYPE,
  PAYCODE_STATUS,
  PAYROLL_GROUP,
  YES_NO,
} from 'features/paycode';
import { ROLES } from 'features/roles';
import { isEqual } from 'lodash';

const getTimecardPaycodeParams = (
  plantType: string | undefined,
  shiftAssignment: ShiftAssignment | null,
  roles: string[],
  paidSickTimeEligibility: string | null,
  empClass?: string | null, // '0' | '3' | '4' | '6' | undefined | null,
  scheduledVarianceHours?: number,
  date?: string,
  paidSickEligibilityDate?: string | null
): PaycodeOptions => {
  const paycode_type =
    scheduledVarianceHours && scheduledVarianceHours < 0
      ? 'absentee'
      : 'overtime,other';

  /* Restricted Usage Paycode visible for> 
  Payroll Admin, Super Payroll Admin, and Super Admin.  
  Restricted Usage Paycode not visible for>
  T&A Plant Coordinator, Pay Approver, and Crew Member can't use */
  const hasRestricted =
    !roles.includes(ROLES.PAYROLL_ADMIN) &&
    !roles.includes(ROLES.SUPER_PAYROLL_ADMIN) &&
    !roles.includes(ROLES.SUPER_ADMIN);
  /* 20252 : Paid Sick - PS is listed.  peopleResponse for the given user returns the flag paidSickTimeEligibility and this needs to be passed as a query param to the paycode api as a query parameter (paidSick) */
  const psTimeEligibility =
    paidSickTimeEligibility === YES_NO.YES &&
    date &&
    paidSickEligibilityDate &&
    (isAfter(
      parseISO(format(new Date(date), 'yyyy-MM-dd')),
      parseISO(format(new Date(paidSickEligibilityDate), 'yyyy-MM-dd'))
    ) ||
      isEqual(
        format(new Date(date), 'yyyy-MM-dd'),
        format(new Date(paidSickEligibilityDate), 'yyyy-MM-dd')
      ))
      ? 'y'
      : 'n';

  if (plantType) {
    /*If the employee is assigned to Mini Mill location 
    and a Non Exempt Employee (class 3 or 6), then pass on the pto flag as N.
    PS, PD, PH, VD, VH, and P1 through P0 are not listed - they use the ellipses instead */
    if (
      plantType === PLANT_TYPE.MINI_MILL &&
      (empClass === '3' || empClass === '6')
    ) {
      return {
        status: PAYCODE_STATUS.ACTIVE,
        shifts: shiftAssignment ?? undefined,
        payrollGroup: PAYROLL_GROUP.HOURLY,
        type: paycode_type,
        restrictedUsage: hasRestricted ? false: undefined,
        paidSickTimeEligibility: psTimeEligibility,
        plantType: PLANT_TYPE.MINI_MILL,
        pto: 'n',
      };
    } else if (
      /* Mini Mill (Plant Type) Part-time (Emp Class 0)
      Within the timecard, the PTO (P1-P8, P0) / Vacation (VH, VD) / Personal Day (PD, PH) 
      pay codes are not listed in dropdown */
      plantType === PLANT_TYPE.MINI_MILL &&
      empClass === '0'
    ) {
      return {
        status: PAYCODE_STATUS.ACTIVE,
        shifts: shiftAssignment ?? undefined,
        payrollGroup: PAYROLL_GROUP.NON_EXEMPT_PART_TIME,
        type: paycode_type,
        restrictedUsage: hasRestricted ? false: undefined,
        paidSickTimeEligibility: psTimeEligibility,
        plantType: PLANT_TYPE.MINI_MILL,
        pto: 'n',
      };
    } else if (
      /*If the employee is assigned to Mini Mill location,
      they will see the pay codes with active status for their shift assignment that 
      are under Payroll Group Non Union Hourly. */
      plantType === PLANT_TYPE.MINI_MILL
    ) {
      return {
        status: PAYCODE_STATUS.ACTIVE,
        shifts: shiftAssignment ?? undefined,
        payrollGroup: PAYROLL_GROUP.HOURLY,
        type: paycode_type,
        restrictedUsage: hasRestricted ? false: undefined,
        paidSickTimeEligibility: psTimeEligibility,
        plantType: PLANT_TYPE.MINI_MILL,
      };
    } else if (
      /* If the employee is assigned to Integrated Mill/Office location 
    and a Non Exempt Employee (class 3 or 6), 
    they will see the pay codes with active status for their shift assignment 
    under Payroll Group Non Union Non exempt FT.
    Integrated & Office (Plant Type) Non-exempt (Emp Class 3 and 6) employees Within the timecard, 
    the PTO pay codes are not listed in the Pay Code dropdown */
      (plantType === PLANT_TYPE.INTEGRATED_EXEMPT ||
        plantType === PLANT_TYPE.OFFICE) &&
      (empClass === '3' || empClass === '6')
    ) {
      return {
        status: PAYCODE_STATUS.ACTIVE,
        shifts: shiftAssignment ?? undefined,
        payrollGroup: PAYROLL_GROUP.NON_EXEMPT_FULL_TIME,
        type: paycode_type,
        restrictedUsage: hasRestricted ? false: undefined,
        paidSickTimeEligibility: psTimeEligibility,
        plantType: plantType,
        pto: 'n',
      };
    } else if (
      /* If the employee is assigned to Integrated & Office (Plant Type) 
      and a Part Time Employee (class 0),
      they will see the pay codes with active status for their shift assignment
      under Payroll Group Non Union Non exempt PT.
      Within the timecard, the PTO (P1-P8, P0) / Vacation (VH, VD) / Personal Day (PD, PH) 
      pay codes are not listed in dropdown */
      (plantType === PLANT_TYPE.INTEGRATED_EXEMPT ||
        plantType === PLANT_TYPE.OFFICE) &&
      empClass === '0'
    ) {
      return {
        status: PAYCODE_STATUS.ACTIVE,
        shifts: shiftAssignment ?? undefined,
        payrollGroup: PAYROLL_GROUP.NON_EXEMPT_PART_TIME,
        type: paycode_type,
        restrictedUsage: hasRestricted ? false: undefined,
        paidSickTimeEligibility: psTimeEligibility,
        plantType: plantType,
        pto: 'n',
      };
    } else if (
      /* If the employee is assigned to Integrated & Office (Plant Type) Exempt (Emp Class 4),
      they will see the pay codes with active status for their shift assignment 
      under Payroll Group Non Union Exempt.
      Within the timecard, the absentee pay codes are listed as follows:
      8 Hr - PD, PH, VD, VH
      12 Hr AWS - PD, VD */
      (plantType === PLANT_TYPE.INTEGRATED_EXEMPT ||
        plantType === PLANT_TYPE.OFFICE) &&
      empClass === '4'
    ) {
      return {
        status: PAYCODE_STATUS.ACTIVE,
        shifts: shiftAssignment ?? undefined,
        payrollGroup: PAYROLL_GROUP.EXEMPT,
        type: paycode_type,
        restrictedUsage: hasRestricted ? false: undefined,
        paidSickTimeEligibility: psTimeEligibility,
        plantType: plantType,
      };
    }
  }
  return {
    shifts: shiftAssignment ?? undefined,
    type: paycode_type,
    restrictedUsage: hasRestricted ? false: undefined,
    paidSickTimeEligibility: psTimeEligibility,
  } as PaycodeOptions;
};
export default getTimecardPaycodeParams;
