import { CrewPtoDetail } from '../types';
import { PtoRequest } from '../types/pto-requests';

export const getButtonDisabled = (pto: CrewPtoDetail | PtoRequest) => {
  const details = pto.ptoDetails.details[0];
  const buttons = {
    approve: false,
    deny: false,
    removed: false,
  };
  if (pto.ptoDetails.details.length > 0) {
    if (
      details.payPeriodStatus === 'open' ||
      details.payPeriodStatus === 'not-open' ||
      (details.payPeriodStatus === 'processed' && details.adjustment > 1)
    ) {
      if (details.status === 'approved') {
        buttons.approve = true;
        buttons.deny = false;
        buttons.removed = false;
      } else if (details.status === 'denied') {
        buttons.approve = false;
        buttons.deny = true;
        buttons.removed = false;
      } else if(details.status==='blackout') {
        buttons.approve = true;
        buttons.deny = true;
        buttons.removed = false;
      } else {
        buttons.approve = false;
        buttons.deny = false;
        buttons.removed = false;
      }
    } else {
      buttons.approve = true;
      buttons.deny = true;
      buttons.removed = true;
    }
  }
  return buttons;
};
