import {
  PTO_STATUS,
  PTO_RECORD_SORT_OPTIONS,
  YES_NO,
  PTO_UNIT_OF_MEASURE,
  PTO_CLASS_NAME,
  PTO_UPDATE_CONTEXT,
  PTO_ADD_CONTEXT,
} from 'features/paid-time-off/constants';
import { PAY_PERIOD_STATUS } from 'features/pay-periods';
import { TimecardStatusCodeSchema } from 'features/timecards/schemas/timecardSchema';
import { z } from 'zod';

export const ptoStatus = z.union([
  z.literal(PTO_STATUS.ADJUSTMENT),
  z.literal(PTO_STATUS.APPROVED),
  z.literal(PTO_STATUS.COMPANY_BLACKOUT),
  z.literal(PTO_STATUS.DENIED),
  z.literal(PTO_STATUS.TAKEN),
  z.literal(PTO_STATUS.REQUESTED),
  z.literal(PTO_STATUS.REMOVED),
]);

const payPeriodStatus = z.union([
  z.literal(PAY_PERIOD_STATUS.NOT_OPEN),
  z.literal(PAY_PERIOD_STATUS.CLOSED),
  z.literal(PAY_PERIOD_STATUS.IN_PROGRESS),
  z.literal(PAY_PERIOD_STATUS.LOCKED),
  z.literal(PAY_PERIOD_STATUS.OPEN),
  z.literal(PAY_PERIOD_STATUS.PROCESSED),
]);

export const ptoSortOptions = z.union([
  z.literal(PTO_RECORD_SORT_OPTIONS.SHIFT_DATE),
  z.literal(PTO_RECORD_SORT_OPTIONS.STATUS),
  z.literal(PTO_RECORD_SORT_OPTIONS.CREW),
  z.literal(PTO_RECORD_SORT_OPTIONS.PAYCODE),
]);

export const ptoClassSchema = z.object({
  pkPtoClassId: z.string(),
  name: z.nativeEnum(PTO_CLASS_NAME),
  unitOfMeasure: z.nativeEnum(PTO_UNIT_OF_MEASURE),
  personalEligible: z.nativeEnum(YES_NO),
  sellbackEligible: z.nativeEnum(YES_NO),
  rolloverEligible: z.nativeEnum(YES_NO),
  vacationPurchaseEligible: z.nativeEnum(YES_NO),
  miscVal1Description: z.string(),
  takenEligible: z.string().optional(),
  approvedEligible: z.string().optional(),
  requestedEligible: z.string().optional(),
  adjustmentEligible: z.string().optional(),
  remainingEligible: z.string().optional(),
  workedHoursEligible: z.string().optional(),
});

export const ptoDetailsSchema = z.object({
  id: z.string(),
  pkWfmEmpPtoDetailsId: z.string(),
  fkParentWfmEmpPtoDetailsId: z.string(),
  ptoClass: ptoClassSchema,
  adjustment: z.number(),
  type: z.union([z.literal('pto'), z.literal('blackout')]),
  crewKey: z.string().nullable(),
  chargeYear: z.number(),
  units: z.number(),
  ussId: z.string(),
  paycode: z.string(),
  status: ptoStatus,
  startDate: z.string(),
  endDate: z.string(),
  notes: z.string().nullable(),
  currentStatusInd: z.nativeEnum(YES_NO),
  requestId: z.string().nullable(),
  fkPayPeriodId: z.string(),
  lastUpdateBy: z.string(),
  lastUpdateDate: z.string(),
  approvedOrDeniedOn: z.date().nullable(),
  requestedOn: z.date(),
  paycodeDescription: z.string().nullable(),
  payPeriodStatus: payPeriodStatus.nullable(),
  timecardStatus: TimecardStatusCodeSchema,
});

export const ptoRecordsOptions = z.object({
  chargeYear: z.string().length(4).optional(),
  year: z.string().length(4).optional(),
  month: z.string().length(2).optional(),
  day: z.string().length(2).optional(),
  status: ptoStatus.optional(),
  sortBy: ptoSortOptions.optional(),
  sortType: z.union([z.literal('asc'), z.literal('desc')]).optional(),
  page: z.string().optional(),
  pageSize: z.string().optional(),
});

/** PTO SUMMARY */

export const ptoSummarySchema = z.object({
  ptoClass: ptoClassSchema,
  unitOfMeasure: z.nativeEnum(PTO_UNIT_OF_MEASURE),
  totalEligible: z.object({
    total: z.number(),
    rollOver: z.number(),
    purchased: z.number(),
    requestedYear: z.number(),
  }),
  taken: z.object({
    total: z.number(),
  }),
  approved: z.object({
    total: z.number(),
  }),
  requested: z.object({
    total: z.number(),
  }),
  adjustment: z.object({
    total: z.number(),
  }),
  personal: z.object({
    total: z.number(),
    remaining: z.number(),
  }),
  sellBackEligible: z.boolean(),
  soldBack: z.number(),
  remaining: z.number(),
  workedHours: z.number(),
  sellBackStart: z.string().nullable(),
  sellBackEnd: z.string().nullable(),
  outOfSync: z.nativeEnum(YES_NO),
});

/** END PTO SUMMARY */

/**BLACKOUT PTO */

export const ptoBlackoutSchema = z.object({
  ussId: z.string(),
  paycode: z.string(),
  date: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  chargeYear: z.number(),
  notes: z.string().nullable(),
  status: z.string(),
});

export const ptoBlackoutOptions = z.object({
  ussId: z.string(),
  year: z.string().length(4).optional(),
  month: z.string().length(2).optional(),
  day: z.string().length(2).optional(),
  page: z.string().optional(),
  pageSize: z.string().optional(),
});

export const updatePtoContext = z.union([
  z.literal(PTO_UPDATE_CONTEXT.EMP_PTO),
   z.literal(PTO_UPDATE_CONTEXT.CREW_PTO),
    z.literal(PTO_UPDATE_CONTEXT.REQUESTS_PTO),
])

export const addPtoContext = z.union([
  z.literal(PTO_ADD_CONTEXT.APPROVALS),
  z.literal(PTO_ADD_CONTEXT.CREW_PTO),
     z.literal(PTO_ADD_CONTEXT.EMP_PTO),
      z.literal(PTO_ADD_CONTEXT.MY_PTO),
  z.literal(PTO_ADD_CONTEXT.MY_SCHEDULE),
       z.literal(PTO_ADD_CONTEXT.SCHEDULES),
])

