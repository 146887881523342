import { StateCreator } from 'zustand';
import { ProbationaryEmployeesStore, toggleEmployee } from '../store';
export interface UnassignedSlice {
  page?: string;
  pageSize?: string;
  setPage: (value: number) => void;
  setPageSize: (value: number) => void;
  selectedEmployees: string[];
  toggleEmployee: (value: string) => void;
  toggleAllEmployees: (value: string[]) => void;
  clearEmployees: () => void;
  search?: string;
  setSearch: (value: string) => void;
  clearSearch: () => void;
}

export const UnAssignedTabSlice: StateCreator<
  ProbationaryEmployeesStore,
  [],
  [],
  UnassignedSlice
> = (set) => {
  return {
    page: '1',
    pageSize: '10',
    selectedEmployees: [],
    setPage: (value) =>
      set((state) => {
        state.areaManager.unassigned.page = value.toString();
        return state;
      }),
    setPageSize: (value) =>
      set((state) => {
        state.areaManager.unassigned.pageSize = value.toString();
        state.areaManager.unassigned.page = '1';
        return state;
      }),
    toggleEmployee: (value) =>
      set((state) => {
        state.areaManager.unassigned.selectedEmployees = toggleEmployee(value, [
          ...state.areaManager.unassigned.selectedEmployees,
        ]);
        return state;
      }),
    toggleAllEmployees: (value) => {
      set((state) => {
        state.areaManager.unassigned.selectedEmployees = value;
        return state;
      });
    },
    clearEmployees: () => {
      set((state) => {
        state.areaManager.unassigned.selectedEmployees = [];
        return state;
      });
    },
    setSearch: (value) =>
      set((state) => {
        if (value.length > 0) {
          state.areaManager.unassigned.search = value;
        } else {
          delete state.areaManager.unassigned.search;
        }
        state.areaManager.unassigned.page = '1';
        return state;
      }),
    clearSearch: () =>
      set((state) => {
        delete state.areaManager.unassigned.search;
        state.areaManager.unassigned.page = '1';
        return state;
      }),
  };
};
