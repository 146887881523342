import { useMutation, useQuery } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import {
  PayPeriodList,
  PayPeriodListOptions,
  PAY_PERIOD_SORT,
  PAY_PERIOD_STATUS,
  PayPeriodStatus,
} from 'features/pay-periods/types';
import { queryClient } from 'services/api/react-query';
import { SortDirection } from 'types/common';
interface PayPeriodStatusUpdate {
  status: Extract<PayPeriodStatus, 'open' | 'locked'>;
  comments?: string | null;
}

/**
 *
 * @param payrollName name of the payroll
 * @param params API Options
 * @returns Axios Promise
 *
 * @link https://cappsq.psc.uss.com/SBWM-DOCUMENT/swagger-ui/index.html?urls.primaryName=sbwm-schedulepay#/pay-period-controller
 */
const get = (
  payrollName: string,
  params?: PayPeriodListOptions
): Promise<PayPeriodList> => {
  return axios.get(`/v1/payperiods/${payrollName}`, {
    params,
  });
};

const update = (
  payPeriodId: string,
  body: PayPeriodStatusUpdate
): Promise<{ success: boolean; message: string }> => {
  return axios.patch(`/v1/payperiods/${payPeriodId}`, body);
};

// Pay Period

/**
 *
 * @param payrollName name of the payroll
 * @param params API parameters for the pay periods
 * @returns
 *
 * @link https://cappsq.psc.uss.com/SBWM-DOCUMENT/swagger-ui/index.html?urls.primaryName=sbwm-schedulepay#/pay-period-controller
 */

export const usePayPeriods = (
  payrollName: string,
  params: PayPeriodListOptions
) => {
  return useQuery({
    queryFn: () => get(payrollName, params),
    queryKey: ['pay-periods', { ...params, payrollName }],
    enabled:
      params.startDate !== '' &&
      Boolean(params.endDate) &&
      params.endDate !== '' &&
      payrollName.length > 0,
  });
};

export const usePayPeriodsShiftAssignment = (payrollName: string) => {
  return useQuery({
    queryFn: () => get(payrollName),
    queryKey: ['get-pay-periods'],
  });
};

export const usePayPeriodsOpenStatus = (
  payrollName: string,
  params?: PayPeriodListOptions
) => {
  const paramsObj = {
    ...params,
    status: params?.status ?? PAY_PERIOD_STATUS.OPEN,
    sortBy: params?.sortBy ?? PAY_PERIOD_SORT.PAYDATE,
    sortType: params?.sortType ?? SortDirection.ASC,
  };
  return useQuery({
    queryFn: () => get(payrollName, paramsObj),
    queryKey: ['pay-periods-open-status', paramsObj],
    enabled: !!payrollName,
    select: (data) => {
      return data.items.sort((a, b) => {
        return a.startDate > b.startDate
          ? 1
          : b.startDate > a.startDate
          ? -1
          : 0;
      })[0]?.startDate;
    },
  });
};

export const usePayPeriodStatus = (
  payrollName: string,
  startDate: string,
  endDate: string
) => {
  return useQuery({
    queryFn: () => get(payrollName, { startDate, endDate }),
    queryKey: ['pay-period-status', { startDate, endDate }],
    enabled:
      startDate !== '' &&
      Boolean(endDate) &&
      endDate !== '' &&
      payrollName !== '',
    select: (data) => (data.items[0] ? data.items[0].status : undefined),
  });
};

export const usePayPeriodStatusUpdate = (payPeriodId: string) => {
  return useMutation({
    mutationFn: (body: PayPeriodStatusUpdate) => update(payPeriodId, body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['pulloff'] });
    },
  });
};

export const usePayPeriod = (payrollName: string) => {
  return useQuery({
    queryFn: () => get(payrollName),
    queryKey: ['pay-periods', { payrollName }],
    enabled: payrollName !== '',
  });
};

export const usePayPeriodWithDateRange = (
  payrollName: string,
  params: PayPeriodListOptions
) => {
  return useQuery({
    queryFn: () => get(payrollName, params),
    queryKey: ['pay-periods', { payrollName }],
    enabled: payrollName !== '',
  });
};
