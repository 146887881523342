import React from 'react';
import { Avatar, Button } from '@uss/react-components';
import { BsArrow90DegRight, BsTrash, BsXLg } from 'react-icons/bs';
import Card from 'components/Card/Card';

import { avatarColor, getInitials } from 'utils';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';
import { OrgSecurityRecord } from 'features/org';
import { EffExpDate } from 'features/crew-maintenance';
import { isFuture } from 'date-fns';
import getFormattedShiftAssignment from 'utils/getFormattedShiftAssignment';

interface MemberCardProps {
  member: OrgSecurityRecord;
  handleRemoveMember: (value: OrgSecurityRecord) => void;
  handleMoveMember: (value: OrgSecurityRecord) => void;
  handleCancelMove: (value: OrgSecurityRecord) => void;
  totalCount: number;
}

export const MemberCard = ({
  member,
  handleRemoveMember,
  handleMoveMember,
  handleCancelMove,
}: MemberCardProps) => {
  const isFutureEffectiveDate = isFuture(
    member.effectiveDate ? new Date(member.effectiveDate) : new Date()
  );

  return (
    <>
      <Card>
        <div data-testid="employee-card">
          <Card.Header>
            <div className="flex items-center w-full">
              <Avatar color={avatarColor(member.ussId)} size="medium">
                {getInitials(member.firstName, member.lastName)}
              </Avatar>
              <div className="flex flex-col flex-wrap flex-grow ml-4">
                <Title as="h3" className="line-clamp-1">
                  {member.displayName}
                </Title>
                <div className="justify-between flex">
                  <p className="text-gray-700 text-sm text-left line-clamp-1">
                    {member.ussId}
                  </p>
                  <EffExpDate
                    isFutureEffectiveDate={isFutureEffectiveDate}
                    member={member}
                  />
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="flex flex-wrap items-center w-full mb-4">
              <LabeledText
                className="w-1/2 pr-1"
                label="Job Title"
                lineClamp={1}
              >
                {member.jobTitle}
              </LabeledText>
              <LabeledText className="w-1/2" label="Employee Class">
                {member.empClass
                  ? `${member.empClass}-${member.empClassDesc ?? ''}`
                  : '-'}
              </LabeledText>
            </div>
            <div className="flex flex-wrap items-center w-full mb-4">
              <LabeledText
                className="w-1/2"
                label="Shift Assignment"
                lineClamp={1}
              >
                {member.shiftAssignment
                  ? getFormattedShiftAssignment(member.shiftAssignment)
                  : ''}
              </LabeledText>
              <LabeledText className="w-1/2" label="Status">
                {member.status.charAt(0).toUpperCase() + member.status.slice(1)}
              </LabeledText>
            </div>
          </Card.Body>

          <Card.Footer>
            {!(member.expirationDate && member.newCrew) ? (
              <div className="flex w-full">
                <div className="flex w-1/2 justify-center">
                  <Button
                    variant="text"
                    className="text-sm text-primary-400 cursor-pointer"
                    onClick={(e) => {
                      handleMoveMember(member);
                    }}
                    disabled={
                      isFutureEffectiveDate ||
                      (member.expirationDate !== null &&
                        member.newCrew === null)
                    }
                  >
                    <BsArrow90DegRight className="text-sm" />
                    <span className="text-xs ml-2"> Move</span>
                  </Button>
                </div>
                <div className="bg-gray-300 w-0.5 mx-6"></div>
                <div className="flex w-1/2  justify-center">
                  <Button
                    variant="text"
                    className="text-sm  text-red-600 cursor-pointer"
                    onClick={(e) => {
                      handleRemoveMember(member);
                    }}
                    disabled={
                      isFutureEffectiveDate ||
                      (member.expirationDate !== null &&
                        member.newCrew === null)
                    }
                  >
                    <BsTrash id="delete" className="text-sm" />
                    <span className="text-xs ml-2"> Remove</span>
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex w-full justify-center">
                <Button
                  variant="text"
                  className="text-sm text-primary-400 cursor-pointer"
                  onClick={(e) => {
                    handleCancelMove(member);
                  }}
                >
                  <BsXLg
                    id="delete"
                    className="text-sm mx-auto my-0 text-red-600"
                  />
                  <span className="text-xs ml-2 text-red-600">
                    {' '}
                    Cancel Move
                  </span>
                </Button>
              </div>
            )}
          </Card.Footer>
        </div>
      </Card>
    </>
  );
};
