import { ObjectValues } from 'types/utils';

export const CREW_BUTTON_REF = {
  ADD: 'Add',
  APPROVE: 'Approve',
  ARCHIVE: 'Archive',
  SAVE: 'Save',
  DENY: 'Deny',
  RESTORE: 'Restore',
  REQUEST: 'Request',
} as const;

export type CrewButtonRef = ObjectValues<typeof CREW_BUTTON_REF>;
