import React from 'react';
import { Avatar } from '@uss/react-components';

import Card from 'components/Card/Card';
import { Person } from 'features/people/types';
import { avatarColor } from 'utils/avatarColors';
import { getInitials } from 'utils';
import LabeledText from 'components/Typography/LabeledText';
import SeparatedLabels from 'components/SeparatedLabels/SeparatedLabels';
import Title from 'components/Typography/Title';
import getFormattedShiftAssignment from 'utils/getFormattedShiftAssignment';
import getFormattedPersonStatus from '../../../people/utilities/getFormattedPersonStatus';

type EmployeeCardPerson = Pick<
  Person,
  | 'ussId'
  | 'firstName'
  | 'lastName'
  | 'payrollLocation'
  | 'status'
  | 'corpServDate'
  | 'plantServDate'
  | 'jobTitle'
  | 'unionInd'
  | 'empClass'
  | 'empClassDesc'
  | 'shiftAssignment'
  | 'displayName'
>;

interface EmployeeCardProps {
  employee: EmployeeCardPerson;
  payrollLocation?: string;
  plant?: string;
}

export const EmployeeCard = ({
  employee: emp,
  payrollLocation,
  plant,
}: EmployeeCardProps) => {
  const empClass =
    emp.empClass && emp.empClassDesc
      ? `${emp.empClass}-${emp.empClassDesc}`
      : '---';
  return (
    <Card>
      <Card.Header>
        <div className="flex items-center">
          <Avatar color={avatarColor(emp.ussId)} size="medium">
            {getInitials(emp.firstName, emp.lastName)}
          </Avatar>
          <div className="pl-3">
            <Title as={'h2'} aria-label="Employee Name">
              {emp.displayName ? emp.displayName : '-'}
            </Title>
            <SeparatedLabels
              size="md"
              items={[
                {
                  label: 'Employee Type',
                  value: emp.unionInd === 'y' ? 'Union' : 'Non Union',
                },
                { label: 'USS ID', value: emp.ussId },
                {
                  label: 'Employee Class',
                  value: empClass,
                },
              ]}
            />
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="grid grid-cols-2 gap-4">
          <LabeledText label="Plant">{plant ? plant : '-'}</LabeledText>
          <LabeledText label="Payroll Location">
            {payrollLocation ? payrollLocation : emp.payrollLocation}
          </LabeledText>
          <LabeledText label="Corp Svc Date">{emp.corpServDate}</LabeledText>
          <LabeledText label="Plant Svc Date">{emp.plantServDate}</LabeledText>
          <LabeledText label="Job Title">{emp.jobTitle}</LabeledText>
          <LabeledText label="Shift Assignment">
            {emp.shiftAssignment
              ? getFormattedShiftAssignment(emp.shiftAssignment)
              : ''}
          </LabeledText>
          <LabeledText label="Status">
            {getFormattedPersonStatus(emp.status)}
          </LabeledText>
        </div>
      </Card.Body>
    </Card>
  );
};
