import { RadioToggleIcon } from '@uss/react-components';
import { PtoDetailsView } from 'features/paid-time-off/types';
import React, { useState } from 'react';
import { BsCalendar, BsFillGridFill } from 'react-icons/bs';
 interface RadioPTODetailsViewParams {
  setPtoDetailsView: (value: PtoDetailsView) => void;
}

const RadioPTODetailsView = ({
  setPtoDetailsView,
}: RadioPTODetailsViewParams) => {
  const [selected, setSelected] = useState<'option1' | 'option2'>('option2');

  const optionList = (
    <BsCalendar
      className={`${
        selected === 'option1' ? '  uss-text-white ' : ' uss-text-primary-400 '
      } uss-fill-current uss-text-xl uss-w-8 h-4 `}
    />
  );

  const option2 = (
    <BsFillGridFill
      className={`${
        selected === 'option2'
          ? '   uss-text-white '
          : '  uss-text-primary-400 '
      } uss-fill-current  uss-text-xl uss-w-8 h-4 `}
    />
  );
  const onClickIcon = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const setValue: 'option1' | 'option2' =
      e.currentTarget.name === 'option1' ? 'option1' : 'option2';
    setPtoDetailsView(setValue === 'option1' ? 'calendar' : 'grid');
    setSelected(setValue);
  };
  return (
    <div className="h-8 flex">
      <RadioToggleIcon
        option1Title="Calendar View"
        option2Title="List View"
        onClick={onClickIcon}
        Option1={optionList}
        Option2={option2}
        optionSelected={selected}
      />
    </div>
  );
};

export default RadioPTODetailsView;
