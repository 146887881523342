import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { BsPlus, BsTrash } from 'react-icons/bs';
import { useCrewPtoStore } from 'features/paid-time-off/state';
import { AddBlackouts } from 'features/paid-time-off/components/CrewPto/AddBlackouts';
import { Button } from '@uss/react-components';
import { useRemovePtoBlackout } from 'features/paid-time-off/api/mutations';
import useModal from 'components/Modals/use-modal';
import { useOrgMembers } from 'features/org/api/getOrgMembers';
import {
  GetCrewParamsBody,
  GetOrgDetailsParamsBody,
} from 'features/paid-time-off/types/crew-pto';
import { PtoStatus } from 'features/paid-time-off/types';

interface CrewBlackoutActionsProps {
  selectedDatePtoList: PtoStatus[] | undefined;
  detailsRequest?: GetOrgDetailsParamsBody;
  totalsRequest?: GetCrewParamsBody;
  blackoutCount?: number;
  totalEmpCount?: number;
}

const CrewBlackoutActions = ({
  selectedDatePtoList,
  detailsRequest,
  totalsRequest,
  blackoutCount,
  totalEmpCount,
}: CrewBlackoutActionsProps) => {
  /**
   * @var blackoutCount - gives the number of blackouts present for that day
   * @var totalEmpCount - total employees present for that crew in dropdown
   * @constant store.ussids - gives particlualry selected employess from dropdown, if All Employees option selected then gives []
   * @returns true/false
   */

  //check current selected year/month and parse it to date
  const store = useCrewPtoStore();

  function membersToFetch(): string {
    if (store.selectedCrew !== 'all' && store.selectedCrew !== null) {
      return store.selectedCrew.id;
    }

    if (
      store.selectedDepartment !== 'all' &&
      store.selectedDepartment !== null
    ) {
      return store.selectedDepartment.id;
    }
    if (store.selectedDivision !== null) {
      return store.selectedDivision.id;
    }
    return '';
  }

  /** useOrgMembers TEST ***************************** */
  const { data: members } = useOrgMembers({
    orgId: membersToFetch(),
    options: {
      sortBy: 'lastName',
    },
  });

  const ussids =
    members && members.items.length > 0
      ? members.items
          .filter((e) => e.empClass === '3' || e.empClass === '6')
          .map(({ ussId }) => ussId.toString())
      : [];

  const { mutateAsync: removePtoBlackout } = useRemovePtoBlackout(
    detailsRequest,
    totalsRequest,
    store.ussids.length > 0 ? store.ussids : ussids
  );
  const modal = useModal();
  const handleRemoveBlackout = () => {
    modal.openModal({
      title: 'Remove Blackout',
      type: 'danger',
      onConfirm: () => handleRemoveBlackoutConfirm(),
      onCancel: () => handleRemoveModalClose(),
      confirmVariant: 'danger',
      labels: {
        cancel: 'Cancel',
        confirm: 'Remove',
      },
      children: (
        <span>
          Are you sure you want to remove Blackout for{' '}
          {format(parseISO(store.selectedDate), 'MMM dd, yyyy')} for the
          selected employees?
        </span>
      ),
    });
  };

  const handleRemoveModalClose = () => {
    modal.closeModal();
  };

  const handleRemoveBlackoutConfirm = async () => {
    handleRemoveModalClose();
    const payload = {
      ussIds: store.ussids.length > 0 ? store.ussids : ussids,
      date: store.selectedDate,
    };
    await removePtoBlackout(payload);
  };

  /* add blackout */
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="w-full flex justify-start mt-6">
        <Button
          variant="icon"
          onClick={() => {
            setOpen(true);
          }}
          disabled={
            blackoutCount === totalEmpCount ||
            (blackoutCount !== 0 && blackoutCount === store.ussids.length)
          }
          className="text-primary-400 font-semibold flex items-center mx-0 lg:mx-2"
        >
          <BsPlus size="2rem" className="mx-2" />
          <span>Add Blackout</span>
        </Button>

        <Button
          variant="icon"
          disabled={
            selectedDatePtoList?.length === 0 ||
            !selectedDatePtoList?.includes('blackout')
          }
          onClick={handleRemoveBlackout}
          className="text-red-600 font-semibold flex items-center mx-2"
        >
          <BsTrash size="1rem" className="mx-2" />
          <span>Remove Blackout</span>
        </Button>
      </div>

      {store.selectedDate !== '' && (
        <AddBlackouts
          ussids={store.ussids.length > 0 ? store.ussids : ussids}
          open={open}
          setOpen={setOpen}
          blackoutDate={store.selectedDate}
          detailsRequest={detailsRequest}
          totalsRequest={totalsRequest}
        />
      )}
    </>
  );
};

export default CrewBlackoutActions;
