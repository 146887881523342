import Card from 'components/Card/Card';
import LabeledText from 'components/Typography/LabeledText';
import Title from 'components/Typography/Title';
import React from 'react';
import { Org } from 'features/org';
import { OrgCardBadge } from './OrgStatusBadge';

interface Props {
  crew: Org;
}

export const CrewCard = ({ crew: c }: Props) => {
  return (
    <Card>
      <Card.Header>
        <div>
          <Title as="h2" aria-label="Crew Name">
            {c.crew ? c.crew : '-'}
          </Title>
          <p className="text-gray-700 text-sm text-left line-clamp-1">
            {c.crewDescription ? c.crewDescription : '-'}
          </p>
        </div>
        <div className="flex flex-col  justify-center">
          {
            <OrgCardBadge value={c.status.code}>
              <span className="capitalize">{c.status.code}</span>
            </OrgCardBadge>
          }
        </div>
      </Card.Header>
      <Card.Body>
        <LabeledText label="Crew Count" className="w-1/2">
          {c.childOrgsCount ?? '-'}
        </LabeledText>
      </Card.Body>
    </Card>
  );
};
