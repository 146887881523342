import { useQuery } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { SwipeTimes, SwipeTimesParams } from '../types/swipeTimes';

const get_swipeTimes = (
  ussId: string,
  params?: SwipeTimesParams
): Promise<SwipeTimes> => {
  return axios.get(`/v1/user/swipes/${ussId}/swipeDetails`, {
    params,
  });
};

export const useSwipeDetails = (ussId: string, params?: SwipeTimesParams) => {
  return useQuery({
    queryFn: () => get_swipeTimes(ussId, params),
    queryKey: ['swipetimes', { ...params, ussId }],
    gcTime: 3000000,
  });
};
