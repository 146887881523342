import { SortDirection } from 'types/common';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { PERSON_STATUS, PeopleSort, PersonStatus } from 'features/people';
import { Org } from 'features/org';
import { UserCrewMemberOptions } from 'features/user/types';

interface EmployeeListState {
  params: UserCrewMemberOptions;
  selectedPlant: Org | null | 'all';
  selectedPayrollLocation: Org | null | 'all';
  setPage: (value: string) => void;
  setPageSize: (value: string) => void;
  setSortBy: (value: PeopleSort) => void;
  setSort: (val: { type: PeopleSort; direction: SortDirection }) => void;
  setSortType: (value: SortDirection) => void;
  setStatus: (value: PersonStatus | 'all') => void;
  setSearch: (value: string) => void;
  setClearSearch: () => void;
  setPlant: (value: Org | 'all') => void;
  setPayrollLocation: (value: Org | 'all') => void;
  removePlant: () => void;
  setExcludeEmpClass: (value: string) => void;
}

export const useEmployeeListStore = create<EmployeeListState>()(
  devtools(
    immer((set) => ({
      params: {
        page: '1',
        pageSize: '9',
        primaryCrew: 'y',
        excludeEmpClass: '4',
        status: `${PERSON_STATUS.ACTIVE}, ${PERSON_STATUS.INACTIVE}, ${PERSON_STATUS.TERMINATEASSIGNMENT}`,
      },
      selectedPlant: null,
      selectedPayrollLocation: null,
      setPage: (value) =>
        set(
          (state) => {
            state.params.page = value;
          },
          false,
          'set-employee-page'
        ),
      setPageSize: (value) =>
        set(
          (state) => {
            state.params.pageSize = value;
            state.params.page = '1';
          },
          false,
          'set-employee-pageSize'
        ),
      setSortBy: (value) =>
        set(
          (state) => {
            state.params.sortBy = value;
          },
          false,
          'set-employee-pageSize'
        ),
      setSort: (value) =>
        set(
          (state) => {
            state.params.sortBy = value.type;
            state.params.sortType = value.direction;
            state.params.page = '1';
          },
          false,
          'set-sort'
        ),
      setSortType: (value) =>
        set(
          (state) => {
            state.params.sortType = value;
          },
          false,
          'set-employee-pageSize'
        ),
      setStatus: (value) =>
        set(
          (state) => {
            if (value === 'all') {
              delete state.params.status;
            } else {
              state.params.status = value;
            }
          },
          false,
          'set-employee-status'
        ),
      setSearch: (value) =>
        set(
          (state) => {
            if (value.length > 0) {
              state.params.search = value;
            } else delete state.params.search;
            state.params.page = '1';
          },
          false,
          'set-employee-search'
        ),
      setClearSearch: () =>
        set(
          (state) => {
            delete state.params.search;
            state.params.page = '1';
          },
          false,
          'set-employee-clearsearch'
        ),
      setPayrollLocation: (value) => {
        set(
          (state) => {
            if (value === 'all') {
              if (state.selectedPlant !== 'all' && state.selectedPlant) {
                state.params.payrollLocations = state.selectedPlant.id;
              } else {
                delete state.params.payrollLocations;
              }
              state.selectedPayrollLocation = 'all';
            } else {
              state.params.payrollLocations = value.code;
              state.selectedPayrollLocation = value;
            }
          },
          false,
          'set-plant'
        );
      },
      setPlant: (value) => {
        set(
          (state) => {
            if (value === 'all') {
              delete state.params.plant;
              state.selectedPlant = 'all';
            } else {
              state.params.plant = value.code;
              state.selectedPlant = value;
              state.params.page = '1';
            }
            state.selectedPayrollLocation = 'all';
            delete state.params.payrollLocations;
          },
          false,
          'set-plant'
        );
      },
      removePlant: () => {
        set(
          (state) => {
            delete state.params.plant;
            state.selectedPlant = 'all';
          },
          false,
          'remove-plant'
        );
      },
      setExcludeEmpClass: (value) =>
        set(
          (state) => {
            if (value.length > 0) {
              state.params.excludeEmpClass = value;
            } else {
              delete state.params.excludeEmpClass;
            }
          },
          false,
          'set-excludeEmpClass'
        ),
    }))
  )
);
