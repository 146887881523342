import {
  Timecard,
  TimecardActionType,
  TIMECARD_UPDATE_ACTIONS,
} from 'features/timecards/types';

export const updateTimecard = (
  state: Timecard[],
  action: TimecardActionType
): Timecard[] => {
  switch (action.type) {
    case TIMECARD_UPDATE_ACTIONS.TURNS: {
      const selectedColumn = action.payload.columnName;
      const selectedRowIndex = action.payload.rowIndex;
      const turn = action.payload.turn;
      if (selectedColumn === 'approvedTurn') {
        state[selectedRowIndex].approved.turn = turn;
      } else if (selectedColumn === 'scheduledTurn') {
        state[selectedRowIndex].scheduled.turn = turn;
      } else state[selectedRowIndex].worked.turn = turn;

      return [...state];
    }
    case TIMECARD_UPDATE_ACTIONS.HOURS: {
      const selectedColumn = action.payload.columnName;
      const selectedRowIndex = action.payload.rowIndex;
      const hours = action.payload.hours;
      const newScheduleVariance = action.payload.scheduleVariance;
      const scheduleVariance =
        state[selectedRowIndex].scheduleVariance.variance;
      const doesTimecardExist = state[selectedRowIndex].id !== 'temp';
      state[selectedRowIndex].id = doesTimecardExist
        ? state[selectedRowIndex].id
        : '';
      if (selectedColumn === 'approvedHours') {
        state[selectedRowIndex].approved.hours = hours;
      } else if (selectedColumn === 'scheduledHours') {
        state[selectedRowIndex].scheduled.hours = hours;
      } else state[selectedRowIndex].worked.hours = hours;

      const scheduleVariancesDoNotMatch =
        Math.sign(Number(scheduleVariance)) !== Math.sign(newScheduleVariance);
      if (scheduleVariancesDoNotMatch) {
        state[selectedRowIndex].scheduleVariance.payCode = '';
      }
      state[selectedRowIndex].scheduleVariance.variance = newScheduleVariance;

      return [...state];
    }
    case TIMECARD_UPDATE_ACTIONS.PAYCODE: {
      const selectedRowIndex = action.payload.rowIndex;
      const paycode = action.payload.paycode;
      const doesTimecardExist = state[selectedRowIndex].id !== 'temp';
      state[selectedRowIndex].id = doesTimecardExist
        ? state[selectedRowIndex].id
        : '';
      state[selectedRowIndex].scheduleVariance.payCode = paycode;
      return [...state];
    }
    case TIMECARD_UPDATE_ACTIONS.RESET_INITIAL_DATA: {
      return [...action.payload];
    }
    case TIMECARD_UPDATE_ACTIONS.CLEAR_SHIFT: {
      const selectedColumn = action.payload.columnName;
      const selectedRowIndex = action.payload.rowIndex;
      const scheduleVariance = action.payload.scheduleVariance;
      if (selectedColumn === 'approvedHours') {
        state[selectedRowIndex].approved.turn = '';
        state[selectedRowIndex].approved.hours = 0;
      } else if (selectedColumn === 'scheduledHours') {
        state[selectedRowIndex].scheduled.turn = '';
        state[selectedRowIndex].scheduled.hours = null;
        if (state[selectedRowIndex].id === '') {
          state[selectedRowIndex].id = 'temp';
        }
      } else {
        state[selectedRowIndex].worked.turn = '';
        state[selectedRowIndex].worked.hours = null;
        state[selectedRowIndex].scheduled.turn = '';
        state[selectedRowIndex].scheduled.hours = null;
        if (state[selectedRowIndex].id === '') {
          state[selectedRowIndex].id = 'temp';
        }
      }
      state[selectedRowIndex].scheduleVariance.payCode = '';
      state[selectedRowIndex].scheduleVariance.variance = scheduleVariance;
      state[selectedRowIndex].paidHoursNotWorked = null;

      return [...state];
    }
    case TIMECARD_UPDATE_ACTIONS.COMMENTS: {
      const selectedRowIndex = action.payload.rowIndex;
      const comment = action.payload.comment;
      const doesTimecardExist = state[selectedRowIndex].id !== 'temp';
      state[selectedRowIndex].id = doesTimecardExist
        ? state[selectedRowIndex].id
        : '';
      state[selectedRowIndex].comments = comment;
      return [...state];
    }

    default:
      throw new Error();
  }
};
