import Loader from 'components/Skeleton/Loader';

const SecurityRecordLoader = () => {
  return (
    <div>
      <div className="mb-4">
        <div className="grid grid-cols-2 gap-x-2">
          <div className="flex flex-col justify-start">
            <span className="text-xs text-gray-600 ">Role</span>
            <span>
              <Loader rows={1} />
            </span>
          </div>
          <div className="flex flex-col justify-start">
            <span className="text-xs text-gray-600 ">Crew</span>
            <span>
              <span>
                <Loader rows={1} />
              </span>
            </span>
          </div>
          <div className="flex flex-col justify-start">
            <span className="text-xs text-gray-600 ">
              Provide PTO Blackout Permission
            </span>
            <span>
              <Loader rows={1} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SecurityRecordLoader;
