import { z } from 'zod';

const PolicySummaryAllHours = z.object({
  regular: z.number(),
  overtime: z.number(),
  holidayWorked: z.number(),
  trainingRegular: z.number(),
  trainingOvertime: z.number(),
  pto: z.number(),
  workedTimeOff: z.number(),
  juryDuty: z.number(),
  funeralLeave: z.number(),
  paidFamilyLeave: z.number(),
});

const PolicySummaryCrewWiseHours = z.object({
  crewId: z.string(),
  crewKey: z.string(),
  primary: z.string().nullable(),
  workedHours: z.number().nullable(),
  unworkedHours: z.number().nullable(),
});

const PolicySummaryAdjustmentWiseHours = z.object({
  adjustment: z.number(),
  payProcessingDate: z.string(),
  totalApprovedHours: z.number().nullable(),
  holidayPayHours: z.number(),
  allHours: PolicySummaryAllHours,
  crewWise: z.array(PolicySummaryCrewWiseHours),
});
const PolicySummaryTotalHours = z.object({
  adjustment: z.number(),
  payProcessingDate: z.string(),
  totalApprovedHours: z.number(),
  holidayPayHours: z.number(),
  allHours: PolicySummaryAllHours,
  crewWise: z.array(PolicySummaryCrewWiseHours),
});
const EmployeePolicySummarySchema = z
  .object({
    items: z.array(PolicySummaryAdjustmentWiseHours),
    totalHours: z.array(PolicySummaryTotalHours),
  })
  .superRefine((data, ctx) => {
    if (
      !(
        data.items.length === data.totalHours.length &&
        data.items.every(
          (item, index) => item.adjustment === data.totalHours[index].adjustment
        )
      )
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          'Each adjustment in items array should have a corresponding item in totalHours array',
        path: ['invalid_data'],
        fatal: true,
      });
      return z.NEVER;
    }
  })

  .refine(
    (data) =>
      data.items.every((item, index) => {
        return (
          item.payProcessingDate === data.totalHours[index].payProcessingDate
        );
      }),
    {
      message:
        'Pay processing date of every adjustment in items array should match with the Pay processing dates in totalHours array',
      path: ['invalid_data'],
    }
  );

export default EmployeePolicySummarySchema;
