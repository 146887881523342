import { StateCreator } from 'zustand';
import { ProbationaryEmployeesStore } from '../store';
export interface CounselingSlice {
  page?: string;
  pageSize?: string;
  setPage: (value: number) => void;
  setPageSize: (value: number) => void;
  search?: string;
  setSearch: (value: string) => void;
  clearSearch: () => void;
}
export const CounselingTabSlice: StateCreator<
  ProbationaryEmployeesStore,
  [],
  [],
  CounselingSlice
> = (set) => {
  return {
    page: '1',
    pageSize: '10',
    setPage: (value) =>
      set((state) => {
        state.reviewer.counseling.page = value.toString();
        return state;
      }),
    setPageSize: (value) =>
      set((state) => {
        state.reviewer.counseling.pageSize = value.toString();
        state.reviewer.counseling.page = '1';
        return state;
      }),
    setSearch: (value) =>
      set((state) => {
        if (value.length > 0) {
          state.reviewer.counseling.search = value;
        } else {
          delete state.reviewer.counseling.search;
        }
        state.reviewer.counseling.page = '1';
        return state;
      }),
    clearSearch: () =>
      set((state) => {
        delete state.reviewer.counseling.search;
        state.reviewer.counseling.page = '1';
        return state;
      }),
  };
};
