import Page from 'components/Layouts/Page';
import { NavLink, useParams } from 'react-router-dom';
import { useEmployeePtoStore } from 'features/paid-time-off/state/mypto-store';
import EmployeeProfile from 'features/people/components/EmployeeProfile';
import { AnnualTotals } from 'features/paid-time-off/components/AnnualTotals/AnnualTotals';
import { EmployeePtoDetails } from 'features/paid-time-off/components/EmployeePto/EmployeePtoDetails';
import Tabs from 'components/Tabs/Tabs';
import PtoRemovedRecords from 'features/paid-time-off/components/MyPto/PtoRemovedRecords';
import { usePersonDetails } from 'features/people/api';
import { UserView as IneligibleForPTO } from 'features/paid-time-off/components/UnauthorizedMsg/UnauthorizedMsg';
import { BsPlus } from 'react-icons/bs';
import useUserRoles from 'hooks/useUserRoles';
import { isEligibleToAddPTO } from 'features/paid-time-off/utility/isEligibleToAddPTO';

const EmployeePto = () => {
  const { selectedYear } = useEmployeePtoStore();
  const { id = '' } = useParams<'id'>();
  const { data: employeeData } = usePersonDetails(id);
  const { roles } = useUserRoles();

  const ptoTabLinks = [
    {
      label: 'PTO Records',
      element: <EmployeePtoDetails />,
    },
    {
      label: 'Removed Records',
      element: <PtoRemovedRecords ussId={id} year={selectedYear} />,
    },
  ];

  //condition to show/hide Add PTO/Blackout button
  const isUserEligibleToAddPTO =
    employeeData &&
    isEligibleToAddPTO(
      roles,
      employeeData.plantType,
      employeeData.empClass,
      employeeData.ptoClass
    );

  return (
    <Page
      breadcrumbs={[
        <NavLink
          key="bc-1"
          className="flex flex-row justify-center items-center"
          to={`/paid-time-off`}
        >
          PTO - Vacation
        </NavLink>,
        <NavLink key="bc-2" to="/paid-time-off/employees">
          Employee Search (NU)
        </NavLink>,
      ]}
      heading="Employee PTO (NU)"
    >
      <div className="flex w-full 2xl:justify-end xl:justify-end lg:justify-end md:justify-end pl-4">
        {isUserEligibleToAddPTO && (
          <NavLink
            className="flex items-center pr-5"
            key={employeeData.ussId}
            to={'add-pto'}
          >
            <div className="text-primary-400 font-semibold flex items-center ">
              <BsPlus size="1.5rem" />
              <span className="ml-2">Add PTO/Blackout</span>
            </div>
          </NavLink>
        )}
      </div>
      <IneligibleForPTO user={employeeData}>
        <Page.Section>
          <div className="grid grid-cols-1 gap-4 mt-4 ">
            <EmployeeProfile id={id} />
            <AnnualTotals year={selectedYear} id={id} />
            <Tabs tabLinks={ptoTabLinks}></Tabs>
          </div>
        </Page.Section>
      </IneligibleForPTO>
    </Page>
  );
};

export default EmployeePto;
