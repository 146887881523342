import { CalendarBlock } from '../types';
import { ComponentType, ElementType } from 'react';

type DayCardProps<P = Record<string, unknown>> = {
  calendardata: CalendarBlock;
  handleClick?: (date: Date) => void;
  children: React.ReactNode;
  as?: ComponentType<P> | ElementType;
} & P;

const DayCard = ({
  as: Component = 'div',
  calendardata,
  handleClick,
  children,
}: DayCardProps) => {
  const getMonthCardStyles = (): string => {
    if (calendardata.selected) {
      return 'border border-accent1-400 bg-white h-14 hover:transparent ';
    }
    if (calendardata.text && !calendardata.disabled) {
      return 'bg-gray-100';
    }
    if (calendardata.disabled) {
      return 'text-gray-300 cursor-not-allowed';
    } else return 'bg-gray-50';
  };

  const handleDateClick = (item: CalendarBlock) => {
    if (item.disabled) {
      return;
    } else {
      if (handleClick) handleClick(item.date);
    }
  };

  return (
    <Component
      className={`flex flex-col rounded-[6px] sm:rounded-lg w-10 sm:w-auto h-8 sm:h-36 p-[2px] sm:p-2 ${getMonthCardStyles()}`}
      data-testid={`${calendardata.dateText}-card-${
        calendardata.disabled ? 'disabled' : 'enabled'
      }`}
      {...(handleClick ? { onClick: () => handleDateClick(calendardata) } : {})}
    >
      <span className="w-full  flex justify-end sm:justify-between">
        {calendardata.events &&
        calendardata.events.length > 0 &&
        calendardata.events[0].status === 'blackout' ? (
          <span className=" text-sm hidden sm:block ">Blackout</span>
        ) : (
          <span className=" text-sm hidden sm:block ">{calendardata.text}</span>
        )}
        <span className="text-sm sm:text-xl">{calendardata.dateText}</span>
      </span>
      {children}
    </Component>
  );
};

export default DayCard;
