import React, { useState } from 'react';
import Page from 'components/Layouts/Page';
import { RadioGroup } from '@uss/react-components';
import { HISTORY_ENTITY, History } from 'features/history';

type HistoryFilterTypes = 'All Changes' | 'Status Changes';
interface Props {
  id: string;
}

const TimecardHistory = ({ id }: Props) => {
  const [selectedFilter, setSelectedFilter] =
    useState<HistoryFilterTypes>('All Changes');

  const handleFilterChange = (value: HistoryFilterTypes) => {
    setSelectedFilter(value);
  };
  return (
    <>
      <Page.Filters>
        <h3 className="sr-only">Timecard History Filters</h3>
        <Page.FiltersSection className="sm:grid sm:grid-cols-3 sm:gap-4 flex w-full flex-wrap">
          <div className="col-span-2 flex flex-row flex-wrap my-4">
            <RadioGroup
              hideLabel
              label="search"
              selected={selectedFilter}
              onSelect={(value: HistoryFilterTypes) =>
                handleFilterChange(value)
              }
            >
              <RadioGroup.Option
                variant="badges"
                value="All Changes"
                id="all-changes"
              >
                All Changes
              </RadioGroup.Option>
              <RadioGroup.Option
                variant="badges"
                value="Status Changes"
                id="status-changes"
              >
                Status Changes
              </RadioGroup.Option>
            </RadioGroup>
          </div>
        </Page.FiltersSection>
      </Page.Filters>
      <History
        id={id}
        entity={HISTORY_ENTITY.EMPLOYEE_TIMECARD}
        expand={selectedFilter === 'All Changes' ? 'y' : 'n'}
      />
    </>
  );
};
export default TimecardHistory;
