import { Table } from '@uss/react-components';
import Page from 'components/Layouts/Page';
import Loader from 'components/Skeleton/Loader';
import React from 'react';
import TableHeader from '../TimecardTable/TableHeader';

interface TimecardDetailsTableLoaderProps {
  numberOfRows: number;
  numberOfColumns: number;
}
const TimecardDetailsTableLoader = ({
  numberOfRows,
  numberOfColumns,
}: TimecardDetailsTableLoaderProps) => {
  const columnsArray = Array.from(Array(numberOfColumns));
  return (
    <Page.Section padding className="bg-white">
      <div className=" my-6 w-full"></div>
      <Table>
        <TableHeader />
        <Table.Body>
          {Array.from(Array(numberOfRows)).map((n, i) => (
            <Table.BodyRow key={i}>
              {columnsArray.map((num, index) => (
                <Table.BodyCell className="px-2" key={index}>
                  <Loader rows={1} />
                </Table.BodyCell>
              ))}
            </Table.BodyRow>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.FooterRow className="h-10">
            <Table.FooterCell>
              <span className="line-clamp-2">Total Hours</span>
            </Table.FooterCell>
            {columnsArray.map((num, index) => (
              <Table.FooterCell key={index}></Table.FooterCell>
            ))}
          </Table.FooterRow>
        </Table.Footer>
      </Table>
    </Page.Section>
  );
};
const TimecardDetailsCardLoader = () => {
  const CardSkeleton = (
    <>
      <hr className="border" />
      <div className="px-3 py-1 my-4">
        <div className="mb-4 py-2">
          <Loader rows={1} />
        </div>
        <div className="grid grid-cols-2  mb-4">
          <div className="flex flex-col justify-start">
            <span className="text-xs text-gray-600 ">Scheduled Hrs</span>
            <span className="pr-2">
              <Loader rows={1} />
            </span>
          </div>
          <div className="flex flex-col justify-start">
            <span className="text-xs text-gray-600 ">Worked Hrs</span>
            <span className="pr-2">
              <Loader rows={1} />
            </span>
          </div>
          <div className="flex flex-col justify-start">
            <span className="text-xs text-gray-600 ">Approved Hrs</span>
            <span className="pr-2">
              <Loader rows={1} />
            </span>
          </div>
          <div className="flex flex-col justify-start">
            <span className="text-xs text-gray-600 ">Paid Not Worked Hrs</span>
            <span className="pr-2">
              <Loader rows={1} />
            </span>
          </div>
        </div>
      </div>
    </>
  );
  return (
    <div className="bg-white p-2 mb-4 border-b block">
      <div className="mb-2">
        <span>
          <Loader rows={1} />
        </span>
      </div>
      <div>
        {Array.from(Array(10)).map((val, index) => (
          <div key={index} className="mb-4">
            {CardSkeleton}
          </div>
        ))}
      </div>
    </div>
  );
};

//Display loader based on screen size
const TimecardDetailsLoader = ({ width }: { width: number | undefined }) => {
  return !!width && width < 768 ? (
    <TimecardDetailsCardLoader />
  ) : (
    <TimecardDetailsTableLoader numberOfRows={7} numberOfColumns={13} />
  );
};

export default TimecardDetailsLoader;
