import { Accordion } from '@uss/react-components';
import clsx from 'clsx';
import { format } from 'date-fns';
import useWindowSize from 'hooks/useWindowSize';
import { Org, ORG_TYPE, OrgType } from 'features/org';

interface OrgDetailsCardProps {
  data: Org | undefined;
  type: OrgType;
}
export const OrgDetailsCard = ({ data, type }: OrgDetailsCardProps) => {
  const { width } = useWindowSize();
  const DetailsHeader = () => {
    return (
      <div className="flex flex-wrap  sm:flex-nowrap w-full relative lg:pr-6 lg:w-7/12 lg:after:w-0.5 lg:after:absolute lg:after:right-0 lg:after:h-full lg:after:bg-gray-200">
        <div className="w-5/12 md:w-2/6 pr-4 flex items-center ">
          <span>
            <p className="text-gray-600 text-sm text-left">Location</p>
            <p className="text-gray-900 text-base md:text-xl text-left font-semibold line-clamp-1">
              {data?.location} - {data?.locationDescription}
            </p>
          </span>
        </div>
        <div
          className={clsx(
            'w-5/12 flex items-center pr-4',
            type === ORG_TYPE.DEPARTMENT ? 'md:w-5/12' : 'md:w-2/5'
          )}
        >
          <span>
            <p className="text-gray-600 text-sm text-left">Division</p>
            <p className="text-gray-900 text-base md:text-xl text-left font-semibold line-clamp-1">
              {data?.division} - {data?.divisionDescription}
            </p>
          </span>
        </div>
        {type === ORG_TYPE.DEPARTMENT && (
          <div className={clsx('w-5/12 md:w-2/6 mt-4 lg:mt-0')}>
            <span>
              <p className="text-gray-600 text-sm text-left">Department</p>
              <p className="text-gray-900 text-base md:text-xl text-left font-semibold line-clamp-1">
                {data?.department} - {data?.departmentDescription}
              </p>
            </span>
          </div>
        )}
      </div>
    );
  };
  const DetailsBody = () => {
    const countLabel =
      type === ORG_TYPE.DIVISION ? 'Dept. Count' : 'Crew Count';
    return (
      <>
        <div className="flex flex-wrap flex-grow sm:flex-nowrap w-full lg:pl-6 lg:w-5/12">
          <div className="flex justify-between w-full md:w-2/5 mb-4 sm:mb-0">
            <span className="w-3/12 sm:w-auto lg:pr-2 flex-grow  ">
              <p className="text-gray-600 text-xs text-left">Status</p>
              <p className="text-gray-900 text-sm text-left font-semibold line-clamp-1 capitalize">
                {data?.status.code ? data.status.code : '-'}
              </p>
            </span>
            <span className="w-3/12 sm:w-auto lg:pr-2 flex-grow">
              <p className="text-gray-600 text-xs text-left">{countLabel}</p>
              <p className="text-gray-900  text-sm  text-left font-semibold line-clamp-1">
                {data?.childOrgsCount ?? '-'}
              </p>
            </span>
          </div>
          <div className="flex justify-between w-full md:w-3/5">
            <span className="w-3/12 sm:w-auto lg:pr-2 flex-grow">
              <p className="text-gray-600 text-xs text-left">
                Last Action Taken By
              </p>
              <p className="text-gray-900 text-sm text-left font-semibold line-clamp-1">
                {data?.status.actionTakenBy ? data.status.actionTakenBy : '-'}
              </p>
            </span>
            <span className="w-3/12 sm:w-auto lg:pr-2 flex-grow">
              <p className="text-gray-600 text-xs text-left">
                Last Action Taken On
              </p>
              <p className="text-gray-900 text-sm text-left font-semibold line-clamp-1">
                {format(
                  data?.status.actionTakenOn
                    ? new Date(data.status.actionTakenOn)
                    : new Date(),
                  'LLL dd, yyyy'
                )}
              </p>
            </span>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {width &&
        (width < 1024 ? (
          <Accordion
            variant="default"
            className="shadow-md bg-white rounded-lg border border-blue-600"
            data-testid="details-card"
          >
            <Accordion.Button variant="default">
              <DetailsHeader />
            </Accordion.Button>
            <Accordion.Panel variant="default">
              <div className="flex flex-row flex-wrap w-full items-center border-t border-gray-200 pt-4">
                <DetailsBody />
              </div>
            </Accordion.Panel>
          </Accordion>
        ) : (
          <div
            data-testid="details-card"
            className="flex items-center shadow-md w-full p-4 bg-white rounded-lg h-24 border border-blue-600"
          >
            <DetailsHeader />
            {/* <div className="bg-gray-200 w-0.5 h-full mx-6"></div> */}
            <DetailsBody />
          </div>
        ))}
    </>
  );
};
