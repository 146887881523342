import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  FieldHelperText,
  Label,
  NumberField,
  Radio,
  RadioGroup,
  Select,
  SelectOptions,
} from '@uss/react-core';
import { format, parseISO } from 'date-fns';
import { useModelDetails, useModelList } from 'features/model-listing/api/queries';
import { MODEL_STATUS } from 'features/model-listing/constants';
import { useGetModelDetailItems } from 'features/model-listing/hooks/useGetModelDetailItems';
import { ApplyModelSchema } from 'features/model-listing/schemas/apply-model-schema';
import { ApplyModel, ApplyModelReqBody, ModelListParams, ModelNameList } from 'features/model-listing/types';
import { getModelNameList } from 'features/model-listing/utility/getModelNameList';
import { useLoggedInUser } from 'features/people/api';
import { Key, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

export interface Dropdown<T> {
  id: T,
  value: T;
}
interface ApplyModelProps {
  defaultValues: ApplyModel;
  handleClose: () => void;
  isSchedulingDetails: boolean;
  handleApplyModel: (data: ApplyModelReqBody) => void;
  payrollLocation: string;
}

export default function ApplyModelForm({
  defaultValues, handleClose, isSchedulingDetails, handleApplyModel, payrollLocation
}: ApplyModelProps) {
  const initialState: ModelListParams = { sortBy: 'modelName', sortType: 'asc', modelStatus: MODEL_STATUS.ACTIVE + ',' + MODEL_STATUS.CHECKED_OUT, payrollLocation: payrollLocation }

  const { data: user } = useLoggedInUser();
  //getting logged-in user id
  const ussId = user ? user.ussId.toString() : '';

  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<ApplyModel>({
    mode: 'onSubmit',
    resolver: zodResolver(ApplyModelSchema),
    defaultValues: defaultValues,
  });

  //watch states
  const model = useWatch({ control, name: 'model' });
  const crewRotations = useWatch({ control, name: 'crewRotation' });
  const numOfHours = useWatch({ control, name: 'noOfHours' });
  const startWeek = useWatch({ control, name: 'startWeekNo' });
  const endWeek = useWatch({ control, name: 'endWeekNo' });
  const selectedHrsOption = useWatch({ control, name: 'hoursType' })

  //get model list
  const { data: modelList } = useModelList(ussId, initialState);
  //get selected model-details
  const { data: modelDetails } = useModelDetails(model ?? '');
  //prepare and get model-name list
  const modelNameList: ModelNameList[] = getModelNameList(modelList?.items ?? []);
  //prepare and get crewRotation and totalWeek val for startWeek/endWeek
  const { crewRotationItems, totalWeeksArr } = useGetModelDetailItems(modelDetails)
  const startWeekItems = totalWeeksArr;
  const endWeekItems = totalWeeksArr;
  //button state for disable/enable
  const [disableButton, setDisableButton] = useState(false);

  //to disable button if fields are empty
  useEffect(() => {
    if (!model || !crewRotations ||
      !startWeek || !endWeek || (!isSchedulingDetails && !numOfHours) || (isSchedulingDetails && selectedHrsOption === 'custom' && !numOfHours)) {
      setDisableButton(true)
    } else setDisableButton(false);
  }, [model, crewRotations, numOfHours, startWeek, endWeek, isSchedulingDetails, selectedHrsOption]);


  useEffect(() => {
    setValue('noOfHours', defaultValues.noOfHours)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNumOfWeeks = (e: Key) => {
    setValue('hoursType', String(e))
  };

  const handleApply = () => {
    const payload = getValues();
    payload.week = format(parseISO(defaultValues.week), 'yyyy-MM-dd');
    const body: ApplyModelReqBody = {
      payload: payload,
      id: payload.model ?? ''
    }
    //removing model id from payload
    delete payload.model;
    if (isSchedulingDetails && selectedHrsOption === 'shiftAssignment') {
      payload.noOfHours = 0;
    }
    handleApplyModel(body)
  }

  const handleEndDateChange = (value: Key) => {
    setValue('endWeekNo', Number(value));
    if (Number(value) > startWeek) clearErrors('startWeekNo');
  }

  const getNumOfHrField = () => {
    return <Controller
      control={control}
      name="noOfHours"
      render={({ field, fieldState: { invalid } }) => (
        <div>
          <Label>
            <p className="text-xs text-black">Number of Hours</p>
          </Label>
          <NumberField
            label=""
            defaultValue={numOfHours}
            placeholder="Enter number of hours"
            onChange={field.onChange}
            isInvalid={invalid}
            errorMessage={errors.noOfHours?.message}
            aria-label={'Number of hours'}
            isDisabled={!model || modelErrorMsg !== ''}
          />
        </div>
      )}
    />
  }

  //check for checked-out status models
  let modelErrorMsg = '';
  if (modelDetails && modelDetails.modelStatus === MODEL_STATUS.CHECKED_OUT) {
    modelErrorMsg = `This model was checked-out by ${modelDetails.lastCheckoutBy} on 
    ${format(parseISO(modelDetails.lastCheckoutOn), 'PP, h:mm a')}`
  }

  return (
    <form
      className="flex flex-col flex-grow mt-4"
      onSubmit={handleSubmit(() => handleApply())}
    >
      <div className="w-full flex flex-col flex-grow gap-5 ">
        <Controller
          control={control}
          name="model"
          render={({ field }) => (
            <div>
              <Select
                items={modelNameList}
                selectedKey={model}
                onSelectionChange={field.onChange}
                label="Model"
                className="w-full"
                errorMessage={modelErrorMsg ? modelErrorMsg : errors.model?.message}
              >
                {(item) => (
                  <SelectOptions id={item.id}>{item.modelName}</SelectOptions>
                )}
              </Select>
            </div>
          )}
        />
        <Controller
          control={control}
          name="crewRotation"
          render={({ field }) => (
            <div>
              <Select
                items={crewRotationItems}
                selectedKey={crewRotations}
                onSelectionChange={field.onChange}
                label="Crew Rotations"
                className="w-full"
                isDisabled={!model || modelErrorMsg !== ''}
              >
                {(item) => (
                  <SelectOptions id={item.id}>{item.value}</SelectOptions>
                )}
              </Select>
            </div>
          )}
        />
        {!isSchedulingDetails && getNumOfHrField()}
        <Controller
          control={control}
          name="startWeekNo"
          render={({ field, fieldState: { invalid } }) => (

            <div>
              <Select
                items={startWeekItems}
                selectedKey={startWeek}
                onSelectionChange={field.onChange}
                label="Start Week"
                className="w-full"
                isInvalid={invalid && startWeek > endWeek}
                errorMessage={errors.startWeekNo?.message}
                isDisabled={!model || modelErrorMsg !== ''}
              >
                {(item) => (
                  <SelectOptions id={item.id}>{item.value}</SelectOptions>
                )}
              </Select>
              {defaultValues.week && <FieldHelperText>
                {`Start Week Begining date ${format(parseISO(defaultValues.week), 'PP')}`}
              </FieldHelperText>}
            </div>

          )}
        />
        <Controller
          control={control}
          name="endWeekNo"
          render={() => (
            <div>
              <Select
                items={endWeekItems}
                selectedKey={endWeek}
                onSelectionChange={(val) => handleEndDateChange(val)}
                label="End Week"
                className="w-full"
                isDisabled={!model || modelErrorMsg !== ''}
              >
                {(item) => (
                  <SelectOptions id={item.id}>{item.value}</SelectOptions>
                )}
              </Select>
            </div>
          )}
        />
        {isSchedulingDetails &&
          <>
            <Controller
              control={control}
              name="hoursType"
              render={({ field }) => (
                <RadioGroup
                  label={'Number of Hours'}
                  value={field.value}
                  orientation={'vertical'}
                  onChange={handleNumOfWeeks}
                  isDisabled={!model || modelErrorMsg !== ''}
                >
                  <Radio value={'shiftAssignment'} >
                    Default to shift assignment
                  </Radio>
                  <Radio value={'custom'}>Custom</Radio>
                </RadioGroup>
              )}
            />
            {selectedHrsOption === 'custom' && getNumOfHrField()}
          </>
        }
      </div>

      <div className="flex justify-between gap-2 pt-6">
        <Button
          variant="outline"
          color={'primary'}
          full={true}
          type="button"
          onPressChange={handleClose}
        >
          Cancel
        </Button>
        <Button variant="filled" color={'primary'} full={true} type={'submit'}
          isDisabled={disableButton}>
          Apply
        </Button>
      </div>
    </form>
  );
}
