import { z } from 'zod';

/* constant values */
export const PAYROLL_INDICATORS = {
  PTO: 'pto',
  INC_ELIGIBLE: 'incEligible',
  PAYABLE: 'payable',
  REPORT: 'reportable',
  VACATION: 'vacation',
  INCENTIVE: 'incentive',
  PRODUCTION_BONUS: 'production-bonus',
} as const;

export const NONUNION_EMPLOYEE = {
  HOURLY: 'hourly',
  NON_EXEMPT: 'non-exempt',
  EXEMPT: 'exempt',
} as const;

export const UNION_EMPLOYEE = {
  HOURLY: 'hourly',
  BIWEEKLY_SALARY: 'biweekly-Salary',
} as const;

export const PAYCODE_TYPE = {
  ABSENTEE: 'absentee',
  OVERTIME: 'overtime',
  OTHER: 'other',
};

export const PAYCODE_STATUS = {
  ACTIVE: 'active',
  PENDING: 'pending',
  ARCHIVED: 'archived',
  DENIED: 'denied',
} as const;

export const EMPLOYEE_TYPE = {
  UNION: 'union',
  NONUNION: 'nonUnion',
} as const;

export const SHIFT_ASSIGNMENT = {
  EIGHT_HOUR: '8hr',
  EIGHT_DAY_HOUR: '8dh',
  TEN_HOUR: '10hr',
  TWELVE_HOUR: '12hr',
} as const;

export const PAYROLL_GROUP_TYPE = {
  UNION: 'union',
  NON_UNION: 'nonunion',
} as const;

export const YES_NO = {
  YES: 'y',
  NO: 'n',
} as const;

export const PLANT_TYPE = {
  MINI_MILL: 'mini_mill',
  INTEGRATED_EXEMPT: 'integrated_mill',
  INTEGRATED_NON_EXEMPT: 'integrated_non_exempt',
  PART_TIME_PAID_SICK: 'part_time_paid_sick',
  OFFICE: 'office',
} as const;

export const CUSTOM_ERROR_MESSAGE = {
  emptyCode: 'Code cannot be empty',
  shorterCode: 'Code needs to be atleast 2 characters long',
  longerCode: 'Code needs to be atmost 2 characters long',
  emptyDescription: 'Description cannot be empty',
  emptyUseCaseDescription: 'Use case description cannot be empty',
  emptyComments: 'Comments cannot be empty',
  productionBonusCheck:
    'Hourly must be selected if Payroll Indicator Production Bonus is checked',
  incentiveCheck:
    'Union Hourly must be selected if Payroll Indicator Incentive is checked',
  payableOvertimeCheck:
    'Must not be Overtime pay code if Payroll Indicator Payable is checked',
  reportableOvertimeCheck:
    'Must not be Overtime pay code if Payroll Indicator Reportable is checked',
  brs8HrShiftCheck:
    'Hourly must be selected if Shift Indicator 8 hrs - DH is checked',
  payrollGroupRequiredCheck: 'Please select atleast one Payroll group',
  shiftAssignmentRequiredCheck:
    'Please select atleast one shift indicator for the payroll group',
} as const;

/* zod constants */
export const ZodShiftAssignment = z.nativeEnum(SHIFT_ASSIGNMENT);
export const ZodEmployeeType = z.nativeEnum(EMPLOYEE_TYPE);
export const ZodUnionEmployee = z.nativeEnum(UNION_EMPLOYEE);
export const ZodNonUnionEmployee = z.nativeEnum(NONUNION_EMPLOYEE);
export const ZodPaycodeType = z.nativeEnum(PAYCODE_TYPE);
export const ZodPaycodeStatus = z.nativeEnum(PAYCODE_STATUS);
export const ZodPayrollGroupType = z.nativeEnum(PAYROLL_GROUP_TYPE);
export const ZodPayrollIndicators = z.nativeEnum(PAYROLL_INDICATORS);
export const ZodPlantType = z.nativeEnum(PLANT_TYPE);
