import {
  ProbEmployeesList,
  ProbationaryEmployeesListOptions,
  EmployeeCardProps,
} from 'features/probationary-tracking/types';
import { Checkbox } from '@uss/react-components';
import { useEffect, useState } from 'react';
export interface EmployeeListProps {
  data: ProbEmployeesList;
  selectable?: boolean;
  clickable?: boolean;
  collapsible?: boolean;
  params?: ProbationaryEmployeesListOptions;
  selectedEmployees?: string[];
  toggleEmployee?: (value: string) => void;
  toggleAllEmployees?: (value: string[]) => void;
  clearEmployees?: () => void;
  renderItem: (item: EmployeeCardProps) => React.ReactNode;
}

function EmployeeList({
  data,
  selectable,
  clickable,
  collapsible,
  params,
  selectedEmployees,
  toggleEmployee,
  toggleAllEmployees,
  clearEmployees,
  renderItem,
}: EmployeeListProps) {
  // Row Selection
  const [allActive, setAllActive] = useState(
    selectedEmployees &&
      selectedEmployees.length > 0 &&
      selectedEmployees.length === data.items.length
      ? true
      : false
  );
  //Deselects select all when selectedEmployees is cleared
  useEffect(() => {
    if (allActive && selectedEmployees?.length === 0) {
      setAllActive(false);
    }
  }, [allActive, selectedEmployees]);

  // Unchecks all rows when filter state changes
  useEffect(() => {
    setAllActive(false);
    clearEmployees?.();
  }, [clearEmployees, params?.page, params?.pageSize, params?.search]);

  // Handle Checkbox
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const allEmployees = data.items.map((e) => {
      return e.ussId.toString();
    });
    const selectedCheckbox = e.target.id;
    if (selectedCheckbox === 'select-all' && !allActive) {
      setAllActive(true);
      toggleAllEmployees?.(allEmployees);
    } else if (selectedCheckbox === 'select-all') {
      setAllActive(false);
      clearEmployees?.();
    } else {
      const selectedEmployee = allEmployees.find(
        (ussId) => ussId === selectedCheckbox
      );
      setAllActive(false);
      if (selectedEmployee) {
        toggleEmployee?.(selectedEmployee);
      }
    }
  };
  const isEmployeeSelected = (
    selectedEmployeeList: string[],
    ussId: string
  ) => {
    const employeeResult = selectedEmployeeList.find(
      (employeeId) => employeeId === ussId
    );
    return !!employeeResult;
  };
  return (
    <>
      {selectable && (
        <Checkbox
          label="Select All"
          name="Select All"
          id="select-all"
          checked={
            allActive ||
            (selectedEmployees?.length === data.items.length &&
              data.items.length !== 0)
          }
          onChange={handleOnChange}
        />
      )}

      {data.items.map((employee) => (
        <div key={employee.ussId}>
          {renderItem({
            employee: employee,
            isEmployeeSelected:
              allActive ||
              isEmployeeSelected(
                selectedEmployees ?? [],
                employee.ussId.toString()
              ),
            handleOnChange: handleOnChange,
            selectable: selectable,
            clickable: clickable,
            collapsible: collapsible,
          })}
        </div>
      ))}
    </>
  );
}
export default EmployeeList;
