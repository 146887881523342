import React from 'react';

interface SeparatedLabelsProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  items: {
    label: string;
    value: string | number | undefined | null;
    width?: 'w-20';
  }[];
  size?: 'sm' | 'md';
}

const SeparatedLabels = ({
  items,
  size = 'sm',
}: SeparatedLabelsProps): JSX.Element => {
  const sizes = {
    sm: 'text-xs pr-1.5',
    md: 'text-sm pr-2',
  };

  return (
    <div className="flex flex-wrap gap-x-2">
      {items.map((item) => {
        return (
          <span
            key={item.label}
            aria-label={item.label}
            className={`text-gray-700 ${
              sizes[size]
            } first-of-type:pl-0 text-left border-r border-gray-300 last-of-type:border-0 ${
              item.width ? item.width : ''
            }`}
          >
            {item.value ? item.value : '-'}
          </span>
        );
      })}
    </div>
  );
};

export default SeparatedLabels;
