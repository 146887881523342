type EmployeeNameType = string | null | undefined;

export const getInitials = (
  firstName: EmployeeNameType,
  lastName: EmployeeNameType
) => {
  const firstInitial = firstName?.slice(0, 1) ?? '';
  const lastInitial = lastName?.slice(0, 1) ?? '';
  return `${firstInitial}${lastInitial}`;
};

export const getInitialsByDisplayName = (displayName: EmployeeNameType) => {
  const displayNameArr = displayName?.split(',') ?? [];
  const firstName = displayNameArr[1].trim();
  const lastName = displayNameArr[0].trim();
  const firstInitial = firstName.slice(0, 1);
  const lastInitial = lastName.slice(0, 1);

  return `${firstInitial}${lastInitial}`;
};
