import { Drawer } from '@uss/react-components';
import useWindowSize from 'hooks/useWindowSize';
import { Timecard } from 'features/timecards/types';
import EllipsisForm from 'features/paid-time-off/components/Forms/EllipsisForm';
import { Person } from 'features/people';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import { PTO_ADD_CONTEXT } from 'features/paid-time-off/constants';

interface RequestPTODrawerProps {
  rowData: Timecard;
  openPTODrawer: boolean;
  onClose: () => void;
  setOpenPTODrawer: React.Dispatch<React.SetStateAction<boolean>>;
  employee: Person;
}

export const RequestPTODrawer = ({
  rowData,
  openPTODrawer,
  onClose,
  setOpenPTODrawer,
  employee,
}: RequestPTODrawerProps) => {
  let isDevice = false;
  const { width } = useWindowSize();
  if (width && width > 768) {
    isDevice = true;
  }

  //Getting current screen feature out of the state
  const feature = useNonUnionStore((state) => state.feature);

  const isEditPTO = rowData.ptoDetails !== null ? true : false;

  //get adjustment value
  const adjustment = useNonUnionStore((state) => state.adjustment);

  const fnGetPTOContext = () => {
  if (feature === 'my-schedule') return PTO_ADD_CONTEXT.MY_SCHEDULE;
  else if (feature === 'pay') return PTO_ADD_CONTEXT.APPROVALS;
    else if (feature === 'scheduling') return PTO_ADD_CONTEXT.SCHEDULES;
    else return PTO_ADD_CONTEXT.MY_SCHEDULE
  } 

  return (
    <Drawer
      placement={isDevice ? 'right' : 'bottom'}
      title={
        isEditPTO
          ? 'Edit PTO'
          : feature === 'my-schedule'
          ? 'Request PTO'
          : 'Add PTO'
      }
      isOpen={openPTODrawer}
      onClose={onClose}
      closeButton={isDevice ? false : true}
      className="sm:w-[25%] md:top-0 top-18 md:rounded-t-none rounded-t-md"
    >
      <EllipsisForm
        defaultValues={{
          paycode: '',
          status: 'requested',
          chargeYear: new Date(rowData.date).getFullYear().toString(),
          ussId: '',
          ptoDate: '',
          notes: '',
          excludeWeekends: false,
          excludeHolidays: false,
          startDate: rowData.date,
          endDate: null,
          paycodeDescription: '',
          context: fnGetPTOContext()
        }}
        setOpenPTODrawer={setOpenPTODrawer}
        employee={employee}
        ptoId={
          rowData.ptoDetails ? rowData.ptoDetails.pkWfmEmpPtoDetailsId : ''
        }
        isAdjustmentOpen={adjustment && adjustment > 0 ? true : false}
      />
    </Drawer>
  );
};
