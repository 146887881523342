import { QueryStatus } from '@tanstack/react-query';
import { Select } from '@uss/react-components';
import { PerformanceCriteriaType } from 'features/probationary-tracking/types/employeeReviewDetails';
import { getPerformanceCriteriaTypeText } from 'features/probationary-tracking/utilities/getPerformanceCriteriaTypeText';

interface Props {
  selectedValue: PerformanceCriteriaType | null;
  onSelect: (value: PerformanceCriteriaType) => void;
  disabled: boolean;
  label?: string;
  error?: boolean | undefined;
  status?: QueryStatus;
}

const PerformanceReviewSelect = ({
  selectedValue,
  onSelect,
  disabled,
  label,
  error,
  status,
}: Props) => {
  return (
    <Select selected={selectedValue} onSelect={onSelect} disabled={disabled}>
      <Select.Label>{label}</Select.Label>
      <Select.Button
        label={
          status === 'pending'
            ? 'Loading'
            : selectedValue
            ? getPerformanceCriteriaTypeText(selectedValue)
            : 'Choose'
        }
        error={error}
      />
      <Select.Options error={error}>
        {/* <Select.Option value="">Choose</Select.Option> */}
        {Object.values(PerformanceCriteriaType).map((item, itemIdx) => (
          <Select.Option key={itemIdx} value={item}>
            <span>{getPerformanceCriteriaTypeText(item)}</span>
          </Select.Option>
        ))}
      </Select.Options>
    </Select>
  );
};

export default PerformanceReviewSelect;
