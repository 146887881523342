import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import NoResults from 'components/NoResults/NoResults';
import Error from 'components/Error/Error';
import {
  CardsLoader,
  ApproverCard,
  UpdatePayApproverBodyWithUssId,
} from 'features/crew-maintenance';
import useUserRoles from 'hooks/useUserRoles';
import { PAGE_ROLES } from 'roles';
import { Link } from '@uss/react-components';
import { OrgSecurityRecord } from 'features/org';
import { useOrgPayApprovers } from 'services/api/common';
import useNotification from 'components/Notification/useNotification';
import { useUpdatePrimaryApprover } from 'features/crew-maintenance/api';
import useModal from 'components/Modals/use-modal';
import { queryClient } from 'services/api/react-query';

const PayApprovers = () => {
  const { crewId = '' } = useParams<'crewId'>();

  const { data: payApprovers, status } = useOrgPayApprovers(crewId);
  const primaryApprover = payApprovers?.items.filter(
    (item) => item.primary === 'y'
  );
  const secondaryApprovers = payApprovers?.items.filter(
    (item) => item.primary !== 'y'
  );

  const notify = useNotification();

  const { mutateAsync: editPrimaryApprover } = useUpdatePrimaryApprover(crewId);
  const updatePrimaryApprover = async (
    payload: UpdatePayApproverBodyWithUssId[]
  ) => {
    await editPrimaryApprover(payload, {
      onSuccess: async () => {
        await queryClient.refetchQueries({ queryKey: [`org-pay-approvers`] });
        notify.success({ message: 'Primary approver updated successfully' });
      },
    });
  };

  const { compareRoles } = useUserRoles();

  const ConfirmModalMessage = ({ data }: { data: OrgSecurityRecord }) => {
    return (
      <div>
        Are you sure you want to set {data.firstName} {data.middleName ?? ''}{' '}
        {data.lastName} as Primary Approver?
      </div>
    );
  };

  const modal = useModal();

  const handleConfirm = (approverData: OrgSecurityRecord) => {
    const payload: UpdatePayApproverBodyWithUssId[] = [
      {
        ussId: approverData.ussId,
        orgRoleId: approverData.orgRoleId,
        primary: 'y',
      },
    ];
    modal.openModal({
      title: 'Confirm Primary Approver',
      type: 'regular',
      onConfirm: async () => {
        await updatePrimaryApprover(payload);
        modal.closeModal();
      },
      onCancel: () => modal.closeModal(),
      labels: {
        cancel: 'Cancel',
        confirm: 'Confirm',
      },
      children: <ConfirmModalMessage data={approverData} />,
    });
  };

  return (
    <>
      {status === 'error' && <Error />}
      <div data-testid="primary-approver" className="py-4">
        <div className="mb-3 flex items-center w-full bg-gray-200 rounded-sm px-4 py-3">
          <h2 className="text-gray-700 font-semibold">Primary Approver</h2>
        </div>
        {status === 'pending' && <CardsLoader />}
        {status === 'success' && primaryApprover?.length ? (
          <div className="grid md:grid-cols-2 xl:grid-cols-3  gap-4 py-4">
            <ApproverCard
              key={primaryApprover[0].id}
              data={primaryApprover[0]}
              approverType="primary"
              handleConfirm={handleConfirm}
            />
          </div>
        ) : (
          <NoResults
            title="No Records Found"
            description="No primary approver available for this crew"
            width={'100'}
            height={'100'}
          />
        )}
      </div>
      <div data-testid="secondary-approver" className="py-4">
        <div className="mb-3 flex items-center justify-between w-full bg-gray-200 rounded-sm px-4 py-3">
          <h2 className="text-gray-700 font-semibold">Secondary Approver</h2>
          {compareRoles(PAGE_ROLES.SECURITY).length > 0 && (
            <Link
              as={NavLink}
              className="inline-block text-primary-400 font-semibold"
              to="/admin/security"
            >
              Add
            </Link>
          )}
        </div>
        {status === 'pending' && <CardsLoader />}
        {status === 'success' &&
        secondaryApprovers &&
        secondaryApprovers.length ? (
          <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4 py-4">
            {secondaryApprovers.map(
              (rowData: OrgSecurityRecord, index: number) => {
                return (
                  <ApproverCard
                    key={rowData.id}
                    data={rowData}
                    approverType="secondary"
                    handleConfirm={handleConfirm}
                  />
                );
              }
            )}
          </div>
        ) : (
          <NoResults
            width={'100'}
            height={'100'}
            title="No Records Found"
            description="No secondary approvers available for this crew"
          />
        )}
      </div>
    </>
  );
};

export default PayApprovers;
