import { Button, Modal, UnstyledButton } from "@uss/react-core";
import { BsX } from "react-icons/bs";

interface Props {
  isOpen: boolean;
  title: string;
  message: string;
  submitButtonText: string;
  handleAction: () => void;
  setIsModelOpen: (val: boolean) => void;
  secondaryMsg?: string;
}

export default function ConfirmationPopup({ isOpen, title, message, submitButtonText, handleAction, setIsModelOpen, secondaryMsg }: Props) {
  return <Modal
    ariaLabel={'confirmation-popup'}
    isDismissable
    isOpen={isOpen}
    size="default"
  >
    <Modal.Header>
      <div className="flex flex-grow items-center ">
        <h2>{title}</h2>
      </div>
      <UnstyledButton>
        <BsX
          size="2rem"
          onClick={() => setIsModelOpen(false)}
          className="cursor-pointer absolute sm:relative right-2 sm:right-0"
        />
      </UnstyledButton>
    </Modal.Header>
    <Modal.Content>
      <div>{message}</div>
      {secondaryMsg && <div>{secondaryMsg}</div>}
    </Modal.Content>
    <Modal.Footer>
      <div className="flex gap-2">
        <Button
          variant={'outline'}
          onPress={() => setIsModelOpen(false)}
        >
          Cancel
        </Button>
        <Button variant={'filled'} onPress={handleAction}>
          {submitButtonText}
        </Button>
      </div>
    </Modal.Footer>
  </Modal>
}
