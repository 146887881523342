import EmployeeList, {
  EmployeeListProps,
} from '../common/EmployeeList/EmployeeList';
import EmployeeCard from '../common/EmployeeList/EmployeeCard/EmployeeCard';

const Employees = ({
  data,
  selectable,
  clickable,
  collapsible,
  selectedEmployees,
  params,
  toggleEmployee,
  toggleAllEmployees,
  clearEmployees,
}: Omit<EmployeeListProps, 'renderItem'>) => {
  return (
    <EmployeeList
      data={data}
      selectable={selectable}
      clickable={clickable}
      collapsible={collapsible}
      selectedEmployees={selectedEmployees}
      params={params}
      toggleEmployee={toggleEmployee}
      toggleAllEmployees={toggleAllEmployees}
      clearEmployees={clearEmployees}
      renderItem={(args) => {
        return (
          <EmployeeCard {...args}>
            <EmployeeCard.StartDate />
            <EmployeeCard.EstEndDate />
            <EmployeeCard.Supervisor />
            <EmployeeCard.Reviewer />
            <EmployeeCard.BargainingUnit />
          </EmployeeCard>
        );
      }}
    />
  );
};
export default Employees;
