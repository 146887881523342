import { format } from 'date-fns';
import { usePayPeriodWithDateRange } from 'features/pay-periods';

export const useCurrentPayPeriodStartDate = (payrolls: string | null) => {
  const currentPayPeriodArr = useCurrentPayPeriod(payrolls ?? '');
  //if payroll is null, return null
  if (payrolls === null) return null;
  //else return result from payperiods api
  if (currentPayPeriodArr && currentPayPeriodArr.length > 0) {
    let earliestStartDate = 0;
    currentPayPeriodArr.forEach((entry, index) => {
      if (index === 0) earliestStartDate = Date.parse(entry.startDate);
      else {
        if (earliestStartDate > Date.parse(entry.startDate))
          earliestStartDate = Date.parse(entry.startDate);
      }
    });
    return format(new Date(earliestStartDate), 'yyyy-MM-dd');
  } else return '';
};

const useCurrentPayPeriod = (payrolls: string) => {
  const arr = payrolls.split(',');
  //Calling API with first payroll-name
  const data1 = useCallPayPeriodsAPI(arr[0]);
  //Calling API with second payroll-name
  const data2 = useCallPayPeriodsAPI(arr.length > 1 ? arr[1] : '');
  //if payrolls contains 2 payrollLocation, the combine result from 2 API calls
  if (arr.length > 1 && data2) return data1?.concat(data2);
  else if (arr.length === 1) return data1;
};

const useCallPayPeriodsAPI = (payrollName: string) => {
  const { data: result } = usePayPeriodWithDateRange(payrollName, {
    status: 'open',
    payYear: new Date().getFullYear(),
  });
  return result?.items;
};
