import { AxiosResponse } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { WMSError } from 'types/common';
import { axios } from 'services/api/axios';
import { CopyScheduleDifferentEmpPayload } from 'features/timecards/types';

/**
 * Function to copy api for the different employee
 * @Link http://cappsq6.psc.uss.com:8382/SBWM-DOCUMENT/swagger-ui/index.html?urls.primaryName=sbwm-schedulepay#/schedule-pay-controller/copyDifferentEmployeeTimecard
 */

const copyScheduleDifferentEmp = (
  ussId: string,
  payload: CopyScheduleDifferentEmpPayload
) => axios.post(`/v1/people/${ussId}/timecard-details/copy`, { ...payload });

export const useCopyScheduleDifferentEmp = ({ ussId }: { ussId: string }) => {
  return useMutation<AxiosResponse, WMSError, CopyScheduleDifferentEmpPayload>({
    mutationFn: (body: CopyScheduleDifferentEmpPayload) => {
      return copyScheduleDifferentEmp(ussId, body);
    },
  });
};
