import { Select } from '@uss/react-components';
import { PTO_STATUS } from 'features/paid-time-off/constants';
import { PtoStatus, PtoFilterStatusType } from 'features/paid-time-off/types';
import { getPtoStatusText } from 'features/paid-time-off/utility/getPtoStatusText';

export const PtoStatusSelect = ({
  status,
  onSelect,
  statusValues,
}: {
  status: PtoFilterStatusType | undefined;
  onSelect: (value: PtoFilterStatusType) => void;
  statusValues: PtoStatus[];
}) => {
  const handleStatusChange = (value: PtoFilterStatusType) => {
    onSelect(value);
  };

  const statusValue =
    status && status !== 'all' ? getPtoStatusText(status) : 'All Statuses';
  return (
    <Select
      selected={status ? status : 'all'}
      onSelect={handleStatusChange}
      className="w-full"
    >
      <Select.Button label={statusValue} />
      <Select.Options>
        {statusValues.length > 1 && (
          <Select.Option value="all">All Statuses</Select.Option>
        )}
        {statusValues
          .filter((s) => s !== PTO_STATUS.REMOVED)
          .map((item) => {
            return (
              <Select.Option key={item} value={item}>
                {getPtoStatusText(item)}
              </Select.Option>
            );
          })}
      </Select.Options>
    </Select>
  );
};
