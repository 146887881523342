/**
 * @description Must not be Overtime pay code if Payroll Indicator Payable is checked
 * @returns
 */

import { PaycodeType, PayrollGroups } from '../types';

export const ReportableOvertimeCheck = (
  data: PayrollGroups[],
  type: PaycodeType
) => {
  let isreportableOvertimeCheck = true;

  data.map((payroll) =>
    payroll.payrollIndicators.map((e) => {
      if (e.name === 'reportable' && e.value && type === 'overtime') {
        isreportableOvertimeCheck = false;
      }
      return isreportableOvertimeCheck;
    })
  );
  return isreportableOvertimeCheck;
};
