import {
  Button,
  MenuTrigger,
  Modal,
  Popover,
  Radio,
  RadioGroup,
  UnstyledButton,
} from '@uss/react-core';
import Pagination from 'components/Pagination/Pagination';
import { EditModelValidationSchema } from 'features/model-listing/schemas/edit-model';
import { useEditModelStore } from 'features/model-listing/state/edit-model-store';
import {
  EditModelReqBody,
  ModelDetailsResponse,
} from 'features/model-listing/types';
import useValidateForm from 'hooks/useValidateForm';
import { useState } from 'react';
import { BiChevronUp } from 'react-icons/bi';
import { BsExclamationCircle, BsX } from 'react-icons/bs';
import { ZodErrorType } from 'types/common';

import { ModelWeekEditCard } from './ModelWeekEditCard';
import { useNavigate } from 'react-router-dom';
import {
  useCheckoutModel,
  useEditModel,
} from 'features/model-listing/api/mutations';
import { notify } from 'components/Notification/useNotification';
import { MODEL_STATUS } from 'features/model-listing/constants';
import EmptyIllustrationsSvg from 'components/svgs/EmptyIllustrationsSvg';
export interface ModelDetailProps {
  data: ModelDetailsResponse;
}
export const EditModelDetails = ({ data }: ModelDetailProps) => {
  const navigate = useNavigate();
  //edit-model api hook
  const { mutateAsync } = useCheckoutModel(data.id);
  const { mutateAsync: editModelMutateAsync } = useEditModel(data.id);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isCancelOpen, setIsCancelOpen] = useState<boolean>(false);
  const [isUndoCheckoutOpen, setIsUndoCheckoutOpen] = useState<boolean>(false);
  const [isPublishOpen, setIsPublishOpen] = useState<boolean>(false);
  const [isSaveAndPublishOpen, setIsSaveAndPublishOpen] =
    useState<boolean>(false);
  const [selected, setSelected] = useState<string>('option1');
  const store = useEditModelStore();
  const [, setErrors] = useState<ZodErrorType>({});
  const { validateForm } = useValidateForm(
    EditModelValidationSchema,
    setErrors
  );
  const handleSave = async () => {
    const payload: EditModelReqBody = {
      modelDescription: data.modelDescription,
      action: 'save',
      weeks: store.weeks,
    };
    await editModelMutateAsync(payload, {
      onSuccess: () => {
        navigateToModelDetail();
        notify.success({
          message: `You have successfully saved the model.`,
        });
      },
    });
  };
  const handlePublish = async () => {
    const payload: EditModelReqBody = {
      modelDescription: data.modelDescription,
      action: 'save-publish',
      weeks: store.weeks,
    };
    await editModelMutateAsync(payload, {
      onSuccess: () => {
        navigateToModelDetail();
        notify.success({
          message: `You have successfully published the model.`,
        });
      },
    });
    setIsPublishOpen(false);
  };
  const handleSaveAndPublish = async () => {
    if (selected === 'option1') {
      await handleSave();
      setIsSaveAndPublishOpen(false);
    } else {
      const payload = {
        modelDescription: data.modelDescription,
        action: 'save-publish',
        weeks: store.weeks,
      };
      await editModelMutateAsync(payload, {
        onSuccess: () => {
          setIsSaveAndPublishOpen(false);
          navigateToModelDetail();
          notify.success({
            message: `You have successfully Published the model.`,
          });
        },
      });
    }
  };

  const handleOpenPublish = () => {
    const payload = {
      modelDescription: data.modelDescription,
      action: 'save-publish',
      weeks: store.weeks,
    };

    if (!validateForm(payload)) {
      setIsPublishOpen(!isPublishOpen);
    } else {
      setIsSaveAndPublishOpen(!isSaveAndPublishOpen);
    }
  };

  const handleCancelContinueClick = () => {
    setIsCancelOpen(false);
    navigateToModelDetail();
  };

  const navigateToModelDetail = () => {
    navigate(`/non-union-scheduling/modeling/${data.id}`);
  };

  const handleUndoCheckout = async () => {
    await mutateAsync(
      {
        action: 'undo-checkout',
      },
      {
        onSuccess: () => {
          navigateToModelDetail();
        },
      }
    );
  };

  const handleClose = () => {
    setIsOpen(false);
    return true
  }

  return (
    <>
      <div>
        {data.totalWeeks === 0 && (
          <div className=" flex flex-col justify-center items-center">
            <EmptyIllustrationsSvg />
            <div className=" text-sm font-normal">{`Model has no weeks or crew rotations entered. Select ‘Add Week(s) / Add Crew(s)’ to build the model.`}</div>
          </div>
        )}
      </div>
      <div className="grid md:grid-cols-2 xl:grid-cols-2 gap-4 mb-4">
        {data.weeks.map((week) => {
          return (
            <ModelWeekEditCard
              weekData={week}
              modelName={data.modelName}
              weekBeginIndex={data.weekBeginIndex}
              totalWeeks={data.totalWeeks}
              key={week.weekNo}
            />
          );
        })}
      </div>

      <Pagination
        pageOptions={[6, 12, 18, 24, 30]}
        totalCount={data.totalWeeks}
        page={data.page}
        pageSize={data.pageSize}
        onPageChange={(s) => store.setPage(s.selected + 1)}
        onPageSizeChange={(ps) => store.setPageSize(ps)}
      />

      <div className="flex justify-end flex-wrap gap-2 mt-5">
        {data.modelStatus === MODEL_STATUS.CHECKED_OUT ? (
          <MenuTrigger isOpen={isOpen}>
            <Button variant={'outline'} onPress={() => setIsOpen(!isOpen)}>
              More <BiChevronUp size="1.25rem" className="ml-2" />
            </Button>
            <Popover placement="top" isOpen={isOpen} shouldCloseOnInteractOutside={handleClose}>
              <div className="flex flex-col">
                <Button
                  variant={'subtle'}
                  onPress={() => {
                    setIsUndoCheckoutOpen(true);
                    setIsOpen(false);
                  }}
                >
                  Undo Checkout
                </Button>

                <Button
                  variant={'subtle'}
                  onPress={() => {
                    setIsCancelOpen(true);
                    setIsOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Popover>
          </MenuTrigger>
        ) : (
          <>
            <Button variant={'outline'} onPress={() => setIsCancelOpen(true)}>
              Cancel
            </Button>
          </>
        )}
        <Button variant={'outline'} onPress={handleSave}>
          Save
        </Button>
        <Button
          variant={'filled'}
          onPress={handleOpenPublish}
          isDisabled={data.totalWeeks === 0}
        >
          Save & Publish
        </Button>
        <Modal
          ariaLabel={'Undo Checkout'}
          isDismissable
          isOpen={isUndoCheckoutOpen}
          size="default"
        >
          <Modal.Header>
            <div className="flex flex-grow items-center ">
              <BsExclamationCircle className="text-red-500 mr-2 text-3xl" />{' '}
              <h2>Alert</h2>
            </div>
            <UnstyledButton>
              <BsX
                size="2rem"
                onClick={() => setIsUndoCheckoutOpen(false)}
                className="cursor-pointer absolute sm:relative right-2 sm:right-0"
              />
            </UnstyledButton>
          </Modal.Header>
          <Modal.Content>
            Any changes made while this model is checked out will be deleted if
            not Saved and Published. Are you sure you want to continue?
          </Modal.Content>
          <Modal.Footer>
            <div className="flex gap-2">
              <Button
                variant={'outline'}
                onPress={() => setIsUndoCheckoutOpen(false)}
              >
                Cancel
              </Button>
              <Button variant={'filled'} onPress={handleUndoCheckout}>
                Continue
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          ariaLabel={'Cancel-modal'}
          isDismissable
          isOpen={isCancelOpen}
          size="default"
        >
          <Modal.Header>
            <div className="flex flex-grow items-center ">
              <BsExclamationCircle className="text-red-500 mr-2 text-3xl" />{' '}
              <h2>Alert</h2>
            </div>
            <UnstyledButton>
              <BsX
                size="2rem"
                onClick={() => setIsCancelOpen(false)}
                className="cursor-pointer absolute sm:relative right-2 sm:right-0"
              />
            </UnstyledButton>
          </Modal.Header>
          <Modal.Content>
            <div>
              Any unsaved changes will be deleted.
              <div>Are you sure you want to continue?</div>
            </div>
          </Modal.Content>
          <Modal.Footer>
            <div className="flex gap-2">
              <Button
                variant={'outline'}
                onPress={() => setIsCancelOpen(false)}
              >
                Cancel
              </Button>
              <Button variant={'filled'} onPress={handleCancelContinueClick}>
                Continue
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          ariaLabel={'save-and-publish'}
          isDismissable
          isOpen={isSaveAndPublishOpen}
          size="default"
        >
          <Modal.Header>
            <div className="flex flex-grow items-center ">
              <BsExclamationCircle className="text-red-500 mr-2 text-3xl" />{' '}
              <h2>Alert</h2>
            </div>
            <UnstyledButton>
              <BsX
                size="2rem"
                onClick={() => setIsSaveAndPublishOpen(false)}
                className="cursor-pointer absolute sm:relative right-2 sm:right-0"
              />
            </UnstyledButton>
          </Modal.Header>
          <Modal.Content>
            There are week(s)/crew(s) which do not have rotations scheduled.
            <div>Choose one of the following to continue:</div>
            <RadioGroup
              aria-label="SaveAndPublishModal"
              value={selected}
              orientation={'vertical'}
              onChange={setSelected}
            >
              <Radio value={'option1'}>Keep model in pending status</Radio>
              <Radio value={'option2'}>
                Delete the week(s)/crew(s) which do not have rotations scheduled
              </Radio>
            </RadioGroup>
          </Modal.Content>
          <Modal.Footer>
            <div className="flex gap-2">
              <Button
                variant={'outline'}
                onPress={() => setIsSaveAndPublishOpen(false)}
              >
                Cancel
              </Button>
              <Button variant={'filled'} onPress={() => handleSaveAndPublish()}>
                Continue
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          ariaLabel={'save-and-publish'}
          isDismissable
          isOpen={isPublishOpen}
          size="default"
        >
          <Modal.Header>
            <div className="flex flex-grow items-center ">
              <BsExclamationCircle className="text-red-500 mr-2 text-3xl" />{' '}
              <h2>Confirmation</h2>
            </div>
            <UnstyledButton>
              <BsX
                size="2rem"
                onClick={() => setIsPublishOpen(false)}
                className="cursor-pointer absolute sm:relative right-2 sm:right-0"
              />
            </UnstyledButton>
          </Modal.Header>
          <Modal.Content>
            Are you sure you want to Publish this model?
          </Modal.Content>
          <Modal.Footer>
            <div className="flex gap-2">
              <Button
                variant={'outline'}
                onPress={() => setIsPublishOpen(false)}
              >
                Cancel
              </Button>
              <Button variant={'filled'} onPress={() => handlePublish()}>
                Publish
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
