import Page from 'components/Layouts/Page';
import { ErrorBoundary } from 'react-error-boundary';
import Error from 'components/Error/Error';
import { useProbationaryEmployeesStore } from 'features/probationary-tracking/state/store';
import { useProbEmpBargainingUnits } from 'features/probationary-tracking/api/getProbEmpBargainingUnits';
import { SearchInput } from '@uss/react-components';
import CardsLoader from 'components/Loaders/CardsLoader';
import CardList from 'components/Card/CardList';
import Pagination from 'components/Pagination/Pagination';
import NoResults from 'components/NoResults/NoResults';
import { BargainingUnitCard } from 'features/probationary-tracking/components/hdq-hr/BargainingUnitCard';
import { BargUnitTotals } from 'features/probationary-tracking/types';
import useDebounceSearch from 'features/timecards/hooks/useDebounceSearch';

const BargainingUnitTotal = () => {
  return (
    <Page heading={'Bargaining Unit Total'}>
      <ErrorBoundary
        fallbackRender={({ error }) => {
          return (
            <Error
              width="300"
              height="300"
              title="Something went wrong!"
              description={error.message}
            />
          );
        }}
      >
        <Content />
      </ErrorBoundary>
    </Page>
  );
};
const Content = () => {
  const { bargainingUnitTotal } = useProbationaryEmployeesStore();
  const search = bargainingUnitTotal.search;
  const setSearch = bargainingUnitTotal.setSearch;
  const clearSearch = bargainingUnitTotal.clearSearch;
  const page = bargainingUnitTotal.page;
  const pageSize = bargainingUnitTotal.pageSize;
  const setPage = bargainingUnitTotal.setPage;
  const setPageSize = bargainingUnitTotal.setPageSize;
  const debounceSearch = useDebounceSearch(search, 500);

  const { data, status } = useProbEmpBargainingUnits({
    params: {
      search: debounceSearch,
      page: page,
      pageSize,
    },
  });

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handleClose = () => {
    clearSearch();
  };

  return (
    <>
      <Page.Section>
        <div className="w-full md:w-[30%] mb-4">
          <SearchInput
            id="search-input"
            label="search"
            placeholder="Search location"
            role="searchbox"
            autoCompleteFlag={search ? true : false}
            selectedFlag={search ? true : false}
            value={search ?? ''}
            onChange={handleSearchChange}
            onClose={handleClose}
          />
        </div>

        {status === 'pending' && <CardsLoader count={9} contentRows={1} />}
        {status === 'error' && <Error />}
        {status === 'success' &&
          (data.items.length > 0 ? (
            <>
              <div className="my-4">
                <CardList<BargUnitTotals>
                  items={data.items}
                  renderItem={(bargUnit) => (
                    <BargainingUnitCard
                      locationCode={bargUnit.payrollLocation}
                      locationName={bargUnit.bargUnit}
                      probEmpCount={bargUnit.probEmployeesCount}
                    />
                  )}
                />
              </div>
              <Pagination
                pageOptions={[9, 18, 27, 36, 45, 54]}
                totalCount={data.totalCount}
                page={data.page}
                pageSize={data.pageSize}
                onPageChange={(s) => setPage(s.selected + 1)}
                onPageSizeChange={(ps) => setPageSize(ps)}
              />
            </>
          ) : (
            <NoResults
              width={'300'}
              height={'300'}
              description="No bargaining unit totals available"
            />
          ))}
      </Page.Section>
    </>
  );
};
export default BargainingUnitTotal;
