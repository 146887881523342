import { Button } from '@uss/react-components';
import React from 'react';

export type OrgActionButton = 'create' | 'save' | 'archive' | 'restore';
interface OrgActionComponentProps {
  buttonRef: React.MutableRefObject<OrgActionButton>;
  showModal: () => void;
  handleCancel: () => void;
  handleArchiveRestore: () => void;
  orgId: string;
  buttonState: {
    canSave: boolean;
    isSaveDisabled: boolean;
    canArchive: boolean;
    canRestore: boolean;
  };
  validateForm: () => boolean;
  setEnableValidator: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OrgAction = ({
  buttonRef,
  showModal,
  handleCancel,
  orgId,
  buttonState,
  handleArchiveRestore,
  validateForm,
  setEnableValidator,
}: OrgActionComponentProps) => {
  return (
    <>
      <Button
        onClick={handleCancel}
        variant="outline"
        className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
        type="button"
      >
        Cancel
      </Button>
      {buttonState.canArchive && (
        <Button
          onClick={() => {
            buttonRef.current = 'archive';
            handleArchiveRestore();
          }}
          variant="outline"
          className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
          type="button"
        >
          Archive
        </Button>
      )}
      {buttonState.canRestore && (
        <Button
          onClick={() => {
            buttonRef.current = 'restore';
            handleArchiveRestore();
          }}
          variant="primary"
          className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
          type="button"
        >
          Restore
        </Button>
      )}
      <Button
        onClick={() => {
          setEnableValidator(true);
          buttonRef.current = orgId === '0' ? 'create' : 'save';
          if (validateForm()) {
            setEnableValidator(false);
            showModal();
          } else {
            return;
          }
        }}
        variant="primary"
        className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
        type="submit"
        disabled={buttonState.isSaveDisabled}
      >
        {orgId === '0' ? 'Add' : 'Save'}
      </Button>
    </>
  );
};
