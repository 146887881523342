import { ErrorOption } from 'react-hook-form';
import { z } from 'zod';

export const validateForm = (
  data: unknown,
  zodSchema: unknown,
  setError: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    name: any,
    error: ErrorOption,
    options?: {
      shouldFocus: boolean;
    }
  ) => void
): boolean => {
  if (zodSchema instanceof z.ZodObject || zodSchema instanceof z.ZodEffects) {
    try {
      zodSchema.parse(data);
      return true;
    } catch (error: unknown) {
      if (error instanceof z.ZodError) {
        let err = {};
        error.errors.forEach((acc: z.ZodIssue) => {
          const nameField = acc.path[0];
          err = { ...err, [nameField]: acc.message };
          setError(nameField.toString(), {
            type: acc.code,
            message: acc.message,
          });
        });
      }
      return false;
    }
  }
  return true;
};
