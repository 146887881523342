import { PayrollGroups, PayrollIndicators, ShiftAssignment } from '../types';

type PayrollGroupsFilterAction =
  | { type: 'active'; payload: boolean }
  | { type: 'restricted'; payload: boolean }
  | {
      type: 'shifts';
      payload: {
        name: ShiftAssignment;
        value: boolean;
      };
    }
  | {
      type: 'payrollIndicators';
      payload: {
        name: PayrollIndicators;
        value: boolean;
      };
    };

export const updatePayrollGroupData = (
  state: PayrollGroups,
  action: PayrollGroupsFilterAction
): PayrollGroups => {
  switch (action.type) {
    case 'active': {
      state.active = action.payload;
      return { ...state };
    }
    case 'restricted': {
      state.restricted = action.payload;
      return { ...state };
    }

    case 'shifts': {
      const tempShift = state.shifts.map((option) => {
        if (option.name === action.payload.name) {
          return action.payload;
        }
        return option;
      });

      return {
        ...state,
        shifts: tempShift,
      };
    }
    case 'payrollIndicators': {
      const tempPayableIndicators = state.payrollIndicators.map((option) => {
        if (option.name === action.payload.name) {
          return action.payload;
        }
        return option;
      });

      return {
        ...state,
        payrollIndicators: tempPayableIndicators,
      };
    }

    default:
      return { ...state };
  }
};
