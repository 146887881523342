import { Select } from '@uss/react-components';
import { EMPLOYEE_CLASSES, EmpClasses } from 'features/timecards/types';
import getFormattedEmpClassFilter from 'features/timecards/utilities/getFormattedEmpClassFilter';

interface EmployeeClassSelectProps {
  selectedEmpClass: EmpClasses | undefined;
  setEmpClass: (value: EmpClasses) => void;
  disabled?: boolean;
  plantType?: string | null;
}

export const EmployeeClassSelect = ({
  selectedEmpClass,
  setEmpClass,
  disabled = false,
  plantType,
}: EmployeeClassSelectProps) => {
  const empClassDropdownValues =
    plantType === 'integrated_mill'
      ? Object.values(EMPLOYEE_CLASSES)
      : Object.values(EMPLOYEE_CLASSES).filter(
          (a) => a !== EMPLOYEE_CLASSES.EXEMPT
        );

  return (
    <Select
      selected={selectedEmpClass ? selectedEmpClass : 'all'}
      onSelect={(value: EmpClasses) => {
        setEmpClass(value);
      }}
      className="w-full"
      disabled={disabled}
      data-testid="status-select-filter"
    >
      <Select.Button
        label={
          selectedEmpClass === undefined
            ? 'All Employee Classes'
            : getFormattedEmpClassFilter(selectedEmpClass)
        }
      />
      <Select.Options>
        <Select.Option value={'all'}>All Employee Classes</Select.Option>
        {empClassDropdownValues.map((item, index) => {
          return (
            <Select.Option key={index} value={item}>
              {getFormattedEmpClassFilter(item)}
            </Select.Option>
          );
        })}
      </Select.Options>
    </Select>
  );
};
