import Badge from 'components/Badge/Badge';
import { BsXLg } from 'react-icons/bs';
import { CalendarBlock } from 'components/CalendarV2/types';

export const PtoMonthContent = ({
  calendardata,
}: {
  calendardata?: CalendarBlock | null;
}) => {
  return (
    <>
      <span
        className="sm:flex items-end flex-wrap w-full h-full hidden"
        data-testid="pto-event"
      >
        {calendardata?.events &&
          calendardata.events.map(
            (item, i) =>
              item.status !== 'blackout' && (
                <span key={i} data-testid="pto-event-text">
                  <Badge variant={item.color}>{item.text}</Badge>
                </span>
              )
          )}
      </span>

      <span className="flex items-end justify-start sm:justify-end w-full  flex-grow sm:hidden">
        {calendardata?.events &&
          calendardata.disabled !== true &&
          calendardata.events.length > 0 && (
            <span className="bg-gray-900  h-1.5 w-1.5 mr-0.5 rounded-full"></span>
          )}
      </span>

      {calendardata?.events &&
        calendardata.events.map((item, i) =>
          item.status === 'blackout' ? (
            <span
              key={i}
              className="flex justify-start sm:justify-end items-end h-full w-full"
              data-testid="blackout-icon"
            >
              <BsXLg className="hidden sm:inline-block" />
              <BsXLg size={'0.5rem'} className="inline-block sm:hidden" />
            </span>
          ) : (
            <></>
          )
        )}
    </>
  );
};
