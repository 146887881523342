// import api from "./api/Schedules";
import {
  InteractionRequiredAuthError,
  SilentRequest,
} from '@azure/msal-browser';
import { AUTH_CONFIG } from 'config';
import { msalInstance } from 'msal-init';

export const getWFMToken = async () => {
  const account = msalInstance.getActiveAccount();
  const request: SilentRequest = {
    account: account ? account : undefined,
    scopes: [AUTH_CONFIG.API_SCOPE],
  };
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }
  try {
    const { accessToken } = await msalInstance.acquireTokenSilent(request);
    return accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      return msalInstance.acquireTokenRedirect(request);
    }
  }
};
export const getMSToken = async () => {
  const account = msalInstance.getActiveAccount();
  const request = {
    account: account ? account : undefined,
    scopes: ['User.Read', 'profile'],
  };
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }
  try {
    const { accessToken } = await msalInstance.acquireTokenSilent(request);
    return accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      return msalInstance.acquireTokenRedirect(request);
    }
  }
};
