import clsx from 'clsx';
import useWindowSize from 'hooks/useWindowSize';
import React from 'react';
import Loader from './Loader';
interface DetailsSkeletonProps {
  hasAvatar?: boolean;
}
const DetailsSkeleton = ({ hasAvatar }: DetailsSkeletonProps) => {
  const { width } = useWindowSize();

  const loaderDivClass = hasAvatar ? 'w-full ml-4' : 'w-full';
  return (
    <>
      {width &&
        (width >= 768 ? (
          <div
            data-testid="details-loader"
            className="flex items-center shadow-md w-full p-4 bg-white rounded-lg h-24 border border-blue-600"
          >
            <div className="w-full sm:w-1/3 2xl:w-1/6">
              <div className="flex items-center w-full">
                {hasAvatar && (
                  <div>
                    <Loader avatarType />
                  </div>
                )}
                <div className={clsx(loaderDivClass)}>
                  <p>
                    <Loader rows={2} />
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-gray-200 w-0.5 h-full mx-6"></div>
            <div className="flex justify-between sm:w-10/12">
              <span className="w-1/4 ">
                <p>
                  <Loader rows={2} />
                </p>
              </span>
              <span className="w-1/5 ">
                <p>
                  <Loader rows={2} />
                </p>
              </span>
              <span className="w-1/5 ">
                <p>
                  <Loader rows={2} />
                </p>
              </span>
              <span className="w-1/5 ">
                <p>
                  <Loader rows={2} />
                </p>
              </span>
            </div>
          </div>
        ) : (
          <div
            data-testid="details-loader"
            className="flex items-center shadow-md w-full p-4 bg-white rounded-lg h-24 border border-blue-600"
          >
            <div className="w-full sm:w-1/3 2xl:w-1/6">
              <div className="flex items-center w-full">
                {hasAvatar && (
                  <div>
                    <Loader avatarType />
                  </div>
                )}
                <div className={clsx(loaderDivClass)}>
                  <p>
                    <Loader rows={2} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default DetailsSkeleton;
