import Tabs from 'components/Tabs/Tabs';
import Detailscard from 'features/crew-maintenance/components/EmployeeDetails/DetailsCard';
import CrewAssignments from './assignments';
import { NavLink, useParams } from 'react-router-dom';
import { usePersonDetails } from 'features/people/api';
import DetailsSkeleton from 'components/Skeleton/DetailsSkeleton';
import Page from 'components/Layouts/Page';
import { HISTORY_ENTITY, History } from 'features/history';
import { ShiftAssignment } from 'features/crew-maintenance/components/EmployeeDetails/Assignments/Shift/ShiftAssignment';
import { useEmployeeSecurity } from 'features/security';
import { ROLECODES } from 'features/roles';
import { useState } from 'react';
import { useCrewMaintenanceEmployeeStore } from 'features/crew-maintenance/state/store';

const EmployeeDetails = () => {
  const [week, setWeek] = useState<string>('');
  const { employeeId = '' } = useParams<'employeeId'>();
  const { data, status } = usePersonDetails(employeeId.toString(), {
    week: week,
  });
  const store = useCrewMaintenanceEmployeeStore();

  const { data: crews } = useEmployeeSecurity(employeeId, {
    role: ROLECODES.CREW_MEMBER,
    retrieveFutureDatedRecords: 'Y',
  });

  const isCrewSpecotMember = crews?.items.some(
    (crew) => crew.crew === 'SPECOT'
  );

  /* This below condition is for checking if the user has previously landed on the page.

  1. The below code snippet runs once per ussId
  2. If the ussId changes, the below snippet is fired 
  3. It sets the current EmployeeId in global store
  4. Verifies if the EmployeeId in the store is same as the current ussId, 
      if not it runs the snippet
  5. This condition is in place because the `week` state changes from the child
      `ShiftAssignment` component. This will cause a re-render of the ShiftAssignment tab 
      as it is dependent on the Person details response
  P.S. usePersonDetails is being called when the week state changes

  */
  if (store.employeeId !== employeeId) {
    const isShiftAssignment =
      data?.empClass === '4' &&
      data.payrollNameId === 'monthly' &&
      (data.plantType === 'integrated_mill' || data.plantType === 'office') &&
      !isCrewSpecotMember;
    isShiftAssignment
      ? store.setEmployeeId(employeeId)
      : store.setEmployeeId('');
  }
  const tabLinks = [
    {
      label: 'Assigned Crew',
      element: <CrewAssignments />,
    },
    {
      label: 'History',
      element: (
        <History
          id={employeeId}
          entity={HISTORY_ENTITY.CREW_BY_EMP}
          noRecordError="No history records found for the selected employee"
        />
      ),
    },
  ];

  /* Conditionally enables Shift Assignment tab */
  store.employeeId !== '' &&
    tabLinks.push({
      label: 'Shift Assignment',
      element: (
        <ShiftAssignment
          id={employeeId}
          payrollName={'monthly'}
          setWeek={setWeek}
        />
      ),
    });

  return (
    <Page
      heading={'Employee Details'}
      breadcrumbs={[
        <NavLink key="bc-1" to="/admin">
          Admin
        </NavLink>,
        <NavLink key="bc-2" to="/admin/crew-maintenance">
          Crew Maintenance
        </NavLink>,
      ]}
    >
      <Page.Section>
        {status === 'pending' && <DetailsSkeleton />}
        {status === 'success' && <Detailscard employeeData={data} />}
      </Page.Section>
      <Page.Section>
        <Tabs tabLinks={tabLinks} />
      </Page.Section>
    </Page>
  );
};

export default EmployeeDetails;
