import React from 'react';
import { Button } from '@uss/react-components';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import LoginSvg from 'components/svgs/LoginSvg';

interface Props {
  action: () => void;
}

const Login = ({ action }: Props) => {
  return (
    <div className="bg-white flex justify-center items-center border-t-2 border-gray-100">
      <AuthenticatedTemplate>
        <div className="m-6 flex flex-col justify-center items-center">
          <h1 className="text-3xl">You are currently logged in!</h1>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="rounded-md m-4 sm:m-18 flex flex-wrap text-gray-700 w-full">
          <div className="w-full pt-11 pb-18 sm:w-1/2">
            <p className="text-2xl sm:text-4xl mb-2">Welcome To</p>
            <h1 className="text-4xl sm:text-6xl mb-6 font-semibold">
              Workforce Management System
            </h1>
            {/* <p className="text-lg mb-5 sm:mb-24">Add text here</p> */}
            <Button
              className="mb-6 mt-6 sm:mt-32"
              variant="primary"
              onClick={action}
            >
              Login
            </Button>
          </div>
          <div className="w-full  sm:w-1/2 h-96">
            <LoginSvg />
          </div>
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default Login;
