import { PersonStatus } from 'features/people';
import { EmployeeDetail, ReviewStatus, ReviewerDetail } from './employeeList';
import { z } from 'zod';
import {
  ReviewGetSchema,
  ReviewPatchSchema,
  ReviewPutSchema,
} from '../schemas/PerformanceReview';

export enum PerformanceCriteriaType {
  DOES_NOT_MEET = 'Does Not Meet', //'DOES_NOT_MEET', //
  MEETS = 'Meet', //'MEET', //
  EXCEEDS = 'Exceeds', //'EXCEEDS', //
}

export const performanceCriteriaTypeObj = z.nativeEnum(PerformanceCriteriaType);

export interface ReviewPerformanceDetail {
  abilityToWorkSafely: PerformanceCriteriaType | null;
  abilityToWorkWithOthers: PerformanceCriteriaType | null;
  qualityOfWork: PerformanceCriteriaType | null;
  quantityOfWork: PerformanceCriteriaType | null;
  undOfWrittenAndVerbalInst: PerformanceCriteriaType | null;
  willingnessAndCooperation: PerformanceCriteriaType | null;
  overallEmpValue: PerformanceCriteriaType | null;
  overallRating: PerformanceCriteriaType | null;
}

export interface EditReviewPerformanceDetail {
  abilityToWorkSafely: PerformanceCriteriaType;
  abilityToWorkWithOthers: PerformanceCriteriaType;
  qualityOfWork: PerformanceCriteriaType;
  quantityOfWork: PerformanceCriteriaType;
  undOfWrittenAndVerbalInst: PerformanceCriteriaType;
  willingnessAndCooperation: PerformanceCriteriaType;
  overallEmpValue: PerformanceCriteriaType;
  overallRating: PerformanceCriteriaType;
}

export enum AttendanceType {
  EXCUSED = 'Excused',
  UNEXCUSED = 'Unexcused',
}

export interface AttendanceDetail {
  absentDate: string;
  tardy: boolean;
  type: AttendanceType | null;
}

export enum CommentRole {
  REVIEWER = 'Reviewer',
  SUPERVISOR = 'Supervisor',
  HR_LR = 'HR/LR',
}

export interface Comment {
  id: string;
  comment: string;
  date: string;
  lastName: string;
  firstName: string;
  displayName: string;
  role: CommentRole;
}

export enum UserWarnedFlag {
  Y = 'Y',
  N = 'N',
}

export interface ProbEmployeeReview {
  id: string;
  ussId: number;
  lastName: string;
  firstName: string;
  middleName: string | null;
  displayName: string;
  status: PersonStatus;
  reviewStatus: ReviewStatus | null;
  active: boolean;
  reviewNumber: number;
  workedHours: number | null;
  reviewPeriodStart: string | null;
  reviewPeriodEnd: string | null;
  approvedDate: string | null;
  reviewer: ReviewerDetail;
  supervisor: EmployeeDetail;
  performance: ReviewPerformanceDetail;
  attendance: AttendanceDetail[];
  comments: Comment[] | null;
  userWarnedFlag: UserWarnedFlag;
}

export enum AreaManagerRetainModalOptions {
  RE_EVALUATE = 'Performance needs to be Re-evaluated',
  COUNSELLING = 'Employee counseling needed',
}

export interface HrLrRetainModalOptions {
  UNTIL_NEXT_REVIEW: string;
  COUNSELLING: string;
}

export enum PerformanceFormLabel {
  ABILITY_TO_WORK_SAFELY = 'abilityToWorkSafely',
  ABILITY_TO_WORK_WITH_OTHERS = 'abilityToWorkWithOthers',
  QUANTITY_OF_WORK = 'quantityOfWork',
  QUALITY_OF_WORK = 'qualityOfWork',
  UNDERSTANDING_OF_WRITTEN_AND_VERBAL_INST = 'undOfWrittenAndVerbalInst',
  WILLINGNESS_AND_COOPERATION = 'willingnessAndCooperation',
  OVERALL_EMP_VALUE = 'overallEmpValue',
  OVERALL_RATING = 'overallRating',
}

export enum ReviewActionLabel {
  ACKNOWLEDGE = 'Acknowledge',
  COMPLETE = 'Complete',
  ADD = 'Add',
  RETAIN = 'Retain',
  CANCEL = 'Cancel',
  TERMINATION = 'Terminate',
}

export enum Actions {
  ADD_COMMENT = 'add-comment',
  EMP_ACKNOWLEDGEMENT = 'emp-acknowledgement',
  REVIEWER_TERMINATED = 'reviewer-terminated',
  SUPERVISOR_TERMINATED = 'supervisor-terminated',
  HR_LR_TERMINATED = 'hr-lr-terminated',
  REVIEWER_RETAINED = 'reviewer-retained',
  SUPERVISOR_RETAINED = 'supervisor-retained',
  HR_LR_RETAINED = 'hr-lr-retained',
  FINAL_REVIEW = 'final-review',
  COUNSELLING = 'counselling',
  SUPERVISOR_TERM_ACKNOWLEDGEMENT = 'supervisor-term-acknowledgement',
}

// ********* Review GET ***********
export type ReviewGet = z.infer<typeof ReviewGetSchema>;

// ********* Review PUT ***********
export type ReviewPut = z.infer<typeof ReviewPutSchema>;

// ********* Review PATCH ***********
export type ReviewPatch = z.infer<typeof ReviewPatchSchema>;
