import { PolicySummaryFields } from '../types';

interface ShouldDisplayPolicyFields {
  employeePolicySummarydata: PolicySummaryFields;
}

const shouldDisplayPolicyFields = ({
  employeePolicySummarydata,
}: ShouldDisplayPolicyFields) => {
  const data = {
    brs_payroll: false,
    empClassZero: false,
    empClassZero_brs: false,
    empClassThree_Six: false,
    empClassThreeSix_brs: false,
    empClassFour_Exempt_8: false,
    empClassFour_Exempt_12: false,
    empClassFour_Exempt_8_Specot: false,
  };

  if (
    employeePolicySummarydata.employeeClass === '0' &&
    employeePolicySummarydata.payrollNameId !== 'big_river_steel_weekly'
  ) {
    data.empClassZero = true;
    return data;
  } else if (
    employeePolicySummarydata.employeeClass === '0' &&
    employeePolicySummarydata.payrollNameId === 'big_river_steel_weekly'
  ) {
    data.empClassZero_brs = true;
    return data;
  } else if (
    (employeePolicySummarydata.employeeClass === '3' ||
      employeePolicySummarydata.employeeClass === '6') &&
    employeePolicySummarydata.payrollNameId !== 'big_river_steel_weekly'
  ) {
    data.empClassThree_Six = true;
    return data;
  } else if (
    (employeePolicySummarydata.employeeClass === '3' ||
      employeePolicySummarydata.employeeClass === '6') &&
    employeePolicySummarydata.payrollNameId === 'big_river_steel_weekly'
  ) {
    data.empClassThreeSix_brs = true;
    return data;
  } else if (
    employeePolicySummarydata.employeeClass === '4' &&
    employeePolicySummarydata.shiftAssignment === '12hr'
  ) {
    data.empClassFour_Exempt_12 = true;
    return data;
  } else if (
    employeePolicySummarydata.employeeClass === '4' &&
    employeePolicySummarydata.shiftAssignment === '8hr' &&
    !employeePolicySummarydata.specotCrew
  ) {
    data.empClassFour_Exempt_8 = true;
    return data;
  } else if (
    employeePolicySummarydata.employeeClass === '4' &&
    employeePolicySummarydata.specotCrew
  ) {
    data.empClassFour_Exempt_8_Specot = true;
    return data;
  }
  data.brs_payroll = true;
  return data;
};
export default shouldDisplayPolicyFields;
