import { Checkbox, Input } from '@uss/react-components';
import { format, parseISO } from 'date-fns';
import {
  AttendanceDetail,
  AttendanceType,
} from 'features/probationary-tracking/types/employeeReviewDetails';
import { RadioComponent } from './RadioComponent';

interface Props {
  attendance: AttendanceDetail[];
}
export const AttendanceSection = ({ attendance }: Props) => {
  const attendanceRadioOptions: string[] = Object.values(AttendanceType);

  return (
    <>
      <div className="mb-2 mt-4">
        <span>Attendance</span>
      </div>
      <div className="lg:grid lg:grid-cols-2 gap-x-10 gap-y-4 w-full">
        {attendance.map((obj, index) => (
          <div
            className="mb-4 lg:mb-0"
            key={index}
            data-testid={`attendance-${index}`}
          >
            <Input
              id={`form__input__absent__date__${index}`}
              data-testid={`input-absent-date-${index}`}
              label="Absent date"
              type="text"
              placeholder=""
              value={format(parseISO(obj.absentDate), 'MMM dd, yyyy')}
              disabled={true}
            />
            <Checkbox
              id={`tardy__${index}`}
              name="tardy"
              label="Tardy"
              checked={obj.tardy}
              data-testid={`attendance-tardy-${index}`}
              disabled={true}
            />
            <div className="my-4">
              <RadioComponent
                selected={
                  attendanceRadioOptions.find(
                    (option) => (option as AttendanceType) === obj.type
                  ) ?? ''
                }
                name={index.toString()}
                radioOptions={attendanceRadioOptions}
                readOnly={true}
                handleChange={() => void 0}
                label={`type-${index}`}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="bg-gray-200 h-0.5 col-span-2 my-6 lg:my-2"></div>
    </>
  );
};
