import React from 'react';
import { Person } from 'features/people/types';
import { PeopleResponse } from 'features/people';
import LabeledText from 'components/Typography/LabeledText';
import { QueryStatus } from '@tanstack/react-query';
interface SuggestionsListProps {
  numberOfSearchChars: number;
  options: PeopleResponse | undefined;
  onSelect: (option: Person) => void;
  status: QueryStatus;
}

export const SuggestionsList = ({
  numberOfSearchChars,
  options,
  onSelect,
  status,
}: SuggestionsListProps) => {
  return (
    <div>
      {status === 'success' &&
        (!options?.items || !options.items.length ? (
          <span data-testid="no-result" className="flex justify-center">
            No results found!!
          </span>
        ) : (
          options.items.map((option) => {
            return (
              <div key={option.ussId}>
                <div
                  data-testid="list-item"
                  className="cursor-pointer py-2 px-4  hover:bg-blue-50"
                  role="button"
                  aria-hidden="true"
                  onClick={() => onSelect(option)}
                >
                  <div className=" col-span-2 flex flex-col">
                    <span className="text-base font-semibold text-gray-700">
                      {option.displayName}
                    </span>
                    <span className="text-xs text-gray-700 flex">
                      {option.hrLocation}
                      {option.primaryCrew && (
                        <span className="bg-gray-300 w-0.5 mx-2"></span>
                      )}
                      {option.primaryCrew}
                    </span>
                  </div>
                  <div className="grid grid-cols-2">
                    <LabeledText label="Employee Class">
                      {option.empClass}
                      {'-'}
                      {option.empClassDesc}
                    </LabeledText>
                    <LabeledText label="Status">
                      {option.status.charAt(0).toUpperCase() +
                        option.status.slice(1)}
                    </LabeledText>
                  </div>
                  <div>
                    <LabeledText label="Job Title">
                      {option.jobTitle}
                    </LabeledText>
                  </div>
                </div>
                <div className="uss-h-0.5 uss-bg-gray-200"></div>
              </div>
            );
          })
        ))}
      {status === 'pending' &&
        (numberOfSearchChars < 3 ? (
          <span className="flex justify-center">
            Enter at least 3 characters
          </span>
        ) : (
          <span className="flex justify-center">Loading Results!!!</span>
        ))}
      {status === 'error' && (
        <span className="flex justify-center">Error occured!!!</span>
      )}
    </div>
  );
};
