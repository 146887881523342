import LabeledText from 'components/Typography/LabeledText';
import {
  PolicySummaryAllHours,
  PolicySummaryFields,
} from 'features/timecards/types';
import { getFormattedPolicySummaryHrs as formatHours } from 'features/timecards/utilities/getFormattedPolicySummaryData';
import shouldDisplayPolicyFields from 'features/timecards/utilities/shouldDisplayPolicyField';

const PolicySummaryCardBody = ({
  allHours,
  isAdjustmentTimecard,
  employeePolicySummarydata,
  shouldDisplayWorkedHrsTurns,
}: {
  allHours: PolicySummaryAllHours;
  isAdjustmentTimecard: boolean;
  employeePolicySummarydata: PolicySummaryFields;
  shouldDisplayWorkedHrsTurns: boolean;
}) => {
  const {
    brs_payroll,
    empClassZero,
    empClassZero_brs,
    empClassThree_Six,
    empClassThreeSix_brs,
    empClassFour_Exempt_8,
    empClassFour_Exempt_12,
    empClassFour_Exempt_8_Specot,
  } = shouldDisplayPolicyFields({
    employeePolicySummarydata,
  });
  return (
    <div className="w-full md:w-11/12 mt-4 ">
      <div
        id="worked-hours"
        className="flex flex-col justify-center w-full mb-4"
      >
        {shouldDisplayWorkedHrsTurns && (
          <p
            className="text-gray-920 text-sm text-left font-semibold"
            data-testid="worked-hrs"
          >
            Worked Hours/ Turns
          </p>
        )}
        {!shouldDisplayWorkedHrsTurns && (
          <p
            className="text-gray-920 text-sm text-left font-semibold"
            data-testid="worked-hrs"
          >
            Worked Hours
          </p>
        )}

        {empClassZero && (
          <div className="flex flex-wrap  gap-y-4">
            <div data-testid="regular" className="w-1/2 md:w-1/4 ">
              <LabeledText label="Regular" lineClamp={1}>
                {formatHours(allHours.regular, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="overtime" className="w-1/2 md:w-1/4">
              <LabeledText label="Overtime" lineClamp={1}>
                {formatHours(allHours.overtime, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
          </div>
        )}
        {empClassThree_Six && (
          <div className="flex flex-wrap  gap-y-4">
            <div data-testid="regular" className="w-1/2 md:w-1/6">
              <LabeledText label="Regular" lineClamp={1}>
                {formatHours(allHours.regular, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>

            <div data-testid="overtime" className="w-1/2 md:w-1/6">
              <LabeledText label="Overtime" lineClamp={1}>
                {formatHours(allHours.overtime, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="holiday-worked" className="w-1/2 md:w-1/6">
              <LabeledText label="Holiday Worked" lineClamp={1}>
                {formatHours(
                  allHours.holidayWorked,
                  isAdjustmentTimecard,
                  true
                )}
              </LabeledText>
            </div>
          </div>
        )}
        {empClassFour_Exempt_12 && (
          <div className="flex flex-wrap  gap-y-4">
            <div data-testid="overtime" className="w-1/2 md:w-1/6">
              <LabeledText label="Overtime Hours" lineClamp={1}>
                {formatHours(allHours.overtime, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="sunday-turn" className="w-1/2 md:w-1/6">
              <LabeledText label="Sunday Turns" lineClamp={1}>
                {formatHours(allHours.sundayTurn, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="shift-one-turn" className="w-1/2 md:w-1/6">
              <LabeledText label="Shift 1 Turns" lineClamp={1}>
                {formatHours(allHours.shiftOneTurn, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="shift-three-turn" className="w-1/2 md:w-1/6">
              <LabeledText label="Shift 3 Turns" lineClamp={1}>
                {formatHours(
                  allHours.shiftThreeTurn,
                  isAdjustmentTimecard,
                  true
                )}
              </LabeledText>
            </div>
            <div data-testid="holiday-worked-turn" className="w-1/2 md:w-1/6">
              <LabeledText label="Holiday Worked Turns" lineClamp={1}>
                {formatHours(
                  allHours.holidayWorkedTurn,
                  isAdjustmentTimecard,
                  true
                )}
              </LabeledText>
            </div>
            <div data-testid="sixth-seventh-days" className="w-1/2 md:w-1/6">
              <LabeledText label="6th/7th Days" lineClamp={1}>
                {formatHours(
                  allHours.sixthOrSeventhDays,
                  isAdjustmentTimecard,
                  true
                )}
              </LabeledText>
            </div>
          </div>
        )}
        {empClassFour_Exempt_8_Specot && (
          <div className="flex flex-wrap  gap-y-4">
            <div data-testid="straight-overtime" className="w-1/2 md:w-1/4">
              <LabeledText label="Straight Overtime" lineClamp={1}>
                {formatHours(
                  allHours.straightOvertime,
                  isAdjustmentTimecard,
                  true
                )}
              </LabeledText>
            </div>
            <div data-testid="overtime" className="w-1/2 md:w-1/4">
              <LabeledText label="Overtime Hours" lineClamp={1}>
                {formatHours(allHours.overtime, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
          </div>
        )}
        {empClassFour_Exempt_8 && (
          <div className="flex flex-wrap  gap-y-4">
            <div data-testid="overtime" className="w-1/2 md:w-1/6">
              <LabeledText label="Overtime Hours" lineClamp={1}>
                {formatHours(allHours.overtime, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="sunday-turn" className="w-1/2 md:w-1/6">
              <LabeledText label="Sunday Turns" lineClamp={1}>
                {formatHours(allHours.sundayTurn, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="shift-one-turn" className="w-1/2 md:w-1/6">
              <LabeledText label="Shift 1 Turns" lineClamp={1}>
                {formatHours(allHours.shiftOneTurn, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="shift-three-turn" className="w-1/2 md:w-1/6">
              <LabeledText label="Shift 3 Turns" lineClamp={1}>
                {formatHours(
                  allHours.shiftThreeTurn,
                  isAdjustmentTimecard,
                  true
                )}
              </LabeledText>
            </div>
            <div data-testid="holiday-worked-turn" className="w-1/2 md:w-1/6">
              <LabeledText label="Holiday Worked Turns" lineClamp={1}>
                {formatHours(
                  allHours.holidayWorkedTurn,
                  isAdjustmentTimecard,
                  true
                )}
              </LabeledText>
            </div>
            <div data-testid="sixth-seventh-days" className="w-1/2 md:w-1/6">
              <LabeledText label="6th/7th Days" lineClamp={1}>
                {formatHours(
                  allHours.sixthOrSeventhDays,
                  isAdjustmentTimecard,
                  true
                )}
              </LabeledText>
            </div>
          </div>
        )}
        {empClassThreeSix_brs && (
          <div className="flex flex-wrap  gap-y-4">
            <div data-testid="regular" className="w-1/2 md:w-1/6">
              <LabeledText label="Regular" lineClamp={1}>
                {formatHours(allHours.regular, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="overtime" className="w-1/2 md:w-1/6">
              <LabeledText label="Overtime" lineClamp={1}>
                {formatHours(allHours.overtime, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="holiday" className="w-1/2 md:w-1/6">
              <LabeledText label="Holiday" lineClamp={1}>
                {formatHours(
                  allHours.holidayWorked,
                  isAdjustmentTimecard,
                  true
                )}
              </LabeledText>
            </div>
            <div data-testid="training" className="w-1/2 md:w-1/6">
              <LabeledText label="Training" lineClamp={1}>
                {formatHours(
                  allHours.trainingRegular,
                  isAdjustmentTimecard,
                  true
                )}
              </LabeledText>
            </div>
            <div data-testid="training-ot" className="w-1/2 md:w-1/6">
              <LabeledText label="Training OT" lineClamp={1}>
                {formatHours(
                  allHours.trainingOvertime,
                  isAdjustmentTimecard,
                  true
                )}
              </LabeledText>
            </div>
          </div>
        )}

        {(brs_payroll || empClassZero_brs) && (
          <div className="flex flex-wrap  gap-y-4">
            <div data-testid="regular" className="w-1/2 md:w-1/5">
              <LabeledText label="Regular" lineClamp={1}>
                {formatHours(allHours.regular, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="overtime" className="w-1/2 md:w-1/5">
              <LabeledText label="Overtime" lineClamp={1}>
                {formatHours(allHours.overtime, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="holiday" className="w-1/2 md:w-1/5">
              <LabeledText label="Holiday" lineClamp={1}>
                {formatHours(
                  allHours.holidayWorked,
                  isAdjustmentTimecard,
                  true
                )}
              </LabeledText>
            </div>
            <div data-testid="training" className="w-1/2 md:w-1/5">
              <LabeledText label="Training" lineClamp={1}>
                {formatHours(
                  allHours.trainingRegular,
                  isAdjustmentTimecard,
                  true
                )}
              </LabeledText>
            </div>
            <div data-testid="training-ot" className="w-1/2 md:w-1/5">
              <LabeledText label="Training OT" lineClamp={1}>
                {formatHours(
                  allHours.trainingOvertime,
                  isAdjustmentTimecard,
                  true
                )}
              </LabeledText>
            </div>
          </div>
        )}
      </div>

      <div id="unworked-hours" className="flex flex-col justify-center w-full">
        {(empClassZero ||
          empClassZero_brs ||
          empClassThree_Six ||
          empClassThreeSix_brs ||
          empClassFour_Exempt_8_Specot ||
          empClassFour_Exempt_8 ||
          brs_payroll) && (
          <p
            className="text-gray-920 text-sm text-left font-semibold"
            data-testid="unworked-hrs"
          >
            Unworked Hours
          </p>
        )}
        {empClassZero && (
          <div className="flex flex-wrap gap-y-4">
            <div data-testid="paid-sick-time" className="w-1/2 md:w-1/5 ">
              <LabeledText label="Paid Sick Leave" lineClamp={1}>
                {formatHours(allHours.paidSick, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
          </div>
        )}
        {empClassThree_Six && (
          <div className="flex flex-wrap gap-y-4">
            <div data-testid="vacation" className="w-1/2 md:w-1/6">
              <LabeledText label="Vacation" lineClamp={1}>
                {formatHours(allHours.vacation, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="personal-day" className="w-1/2 md:w-1/6">
              <LabeledText label="Personal Day" lineClamp={1}>
                {formatHours(allHours.personalDay, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="paid-leave" className="w-1/2 md:w-1/6">
              <LabeledText label="Paid Leave " lineClamp={1}>
                {formatHours(allHours.paidLeave, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="paid-sick-time" className="w-1/2 md:w-1/6">
              <LabeledText label="Paid Sick Time" lineClamp={1}>
                {formatHours(allHours.paidSick, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="holiday-unworked" className="w-1/2 md:w-1/6">
              <LabeledText label="Holiday Unworked" lineClamp={1}>
                {formatHours(
                  allHours.holidayUnworked,
                  isAdjustmentTimecard,
                  true
                )}
              </LabeledText>
            </div>
            <div data-testid="unpaid" className="w-1/2 md:w-1/6">
              <LabeledText label="Unpaid Leave" lineClamp={1}>
                {formatHours(allHours.unpaidLeave, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
          </div>
        )}

        {empClassFour_Exempt_8_Specot && (
          <div className="flex flex-wrap gap-y-4">
            <div data-testid="unpaid-leave" className="w-1/2 md:w-1/5 ">
              <LabeledText label="Unpaid Leave" lineClamp={1}>
                {formatHours(allHours.unpaidLeave, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
          </div>
        )}

        {empClassFour_Exempt_8 && (
          <div className="flex flex-wrap  gap-y-4">
            <div data-testid="unpaid-leave" className="w-1/2 md:w-1/2">
              <LabeledText label="Unpaid Leave" lineClamp={1}>
                {formatHours(allHours.unpaidLeave, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
          </div>
        )}

        {empClassZero_brs && (
          <div className="flex flex-wrap gap-y-4">
            <div data-testid="ptl" className="w-1/2 md:w-1/5">
              <LabeledText label="Paid State Leave" lineClamp={1}>
                {formatHours(allHours.paidSick, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="funeral-leave" className="w-1/2 md:w-1/5">
              <LabeledText label="Funeral Leave" lineClamp={1}>
                {formatHours(allHours.funeralLeave, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="jury-duty" className="w-1/2 md:w-1/5">
              <LabeledText label="Jury Duty" lineClamp={1}>
                {formatHours(allHours.juryDuty, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="worked-time-off" className="w-1/2 md:w-1/5">
              <LabeledText label="Worked Time Off" lineClamp={1}>
                {formatHours(
                  allHours.workedTimeOff,
                  isAdjustmentTimecard,
                  true
                )}
              </LabeledText>
            </div>
            <div data-testid="paid-family-leave" className="w-1/2 md:w-1/5">
              <LabeledText label="Paid Family Leave" lineClamp={1}>
                {formatHours(
                  allHours.paidFamilyLeave,
                  isAdjustmentTimecard,
                  true
                )}
              </LabeledText>
            </div>
          </div>
        )}
        {(brs_payroll || empClassThreeSix_brs) && (
          <div className="flex flex-wrap gap-y-4">
            <div data-testid="pto" className="w-1/2 md:w-1/6">
              <LabeledText label="PTO" lineClamp={1}>
                {formatHours(allHours.pto, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="funeral-leave" className="w-1/2 md:w-1/6">
              <LabeledText label="Funeral Leave" lineClamp={1}>
                {formatHours(allHours.funeralLeave, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="jury-duty" className="w-1/2 md:w-1/6">
              <LabeledText label="Jury Duty" lineClamp={1}>
                {formatHours(allHours.juryDuty, isAdjustmentTimecard, true)}
              </LabeledText>
            </div>
            <div data-testid="worked-time-off" className="w-1/2 md:w-1/6">
              <LabeledText label="Worked Time Off" lineClamp={1}>
                {formatHours(
                  allHours.workedTimeOff,
                  isAdjustmentTimecard,
                  true
                )}
              </LabeledText>
            </div>
            <div data-testid="paid-family-leave" className="w-1/2 md:w-1/6">
              <LabeledText label="Paid Family Leave" lineClamp={1}>
                {formatHours(
                  allHours.paidFamilyLeave,
                  isAdjustmentTimecard,
                  true
                )}
              </LabeledText>
            </div>
            {empClassThreeSix_brs && (
              <div data-testid="maternity-leave" className="w-1/2 md:w-1/6">
                <LabeledText label="Maternity Leave" lineClamp={1}>
                  {formatHours(
                    allHours.maternityLeave,
                    isAdjustmentTimecard,
                    true
                  )}
                </LabeledText>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default PolicySummaryCardBody;
