import useNotification from 'components/Notification/useNotification';
import { useMutation } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import { queryClient } from 'services/api/react-query';
import { YesNo } from 'types/common';

interface RunPullOffResponseStatus {
  success: boolean;
  message: string;
}

export interface RunPulloffBody {
  dryRun: YesNo;
}

// api/v2/payrolls/{payrollName}/pay-pulloff
const post = (
  body: RunPulloffBody,
  payrollName: string
): Promise<RunPullOffResponseStatus> => {
  return axios.post(`/v2/payrolls/${payrollName}/pay-pulloff`, body);
};

export const useRunPulloff = (body: RunPulloffBody, payrollName: string) => {
  const notify = useNotification();

  return useMutation({
    mutationFn: () => post(body, payrollName),
    onSuccess: async (response) => {
      await queryClient.refetchQueries({
        queryKey: ['payPullOffType'],
        type: 'active',
      });
      await queryClient.refetchQueries({
        queryKey: ['pulloff'],
        type: 'active',
      });
      notify.success({
        message: 'Your request has been submitted successfully.',
      });
    },
  });
};
