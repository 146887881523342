import { MileageApprovalCard } from '../MileageApprovalCard';
import { Button } from '@uss/react-components';
import { BsCheck2 } from 'react-icons/bs';
import useUserRoles from 'hooks/useUserRoles';
import { useMileageApprovalDetails } from 'features/mileage-approval/api/queries';
import CardsLoader from 'components/Loaders/CardsLoader';
import NoResults from 'components/NoResults/NoResults';
import { useEffect } from 'react';
import { UpdateMileageField } from 'features/mileage-approval/types/mileageEntry';
import { useUpdateMileage } from 'features/mileage-approval/api/mutation';
import useModal from 'components/Modals/use-modal';
import { useMileageApprovalStore } from 'features/mileage-approval/state/mileage-store';
import { ROLES } from 'features/roles';
import Pagination from 'components/Pagination/Pagination';

interface PendingdMileageProps {
  setRecords: (count: string | null) => void;
}

const PendingCard = ({ setRecords }: PendingdMileageProps) => {
  const { uss_id, roles } = useUserRoles();
  const store = useMileageApprovalStore();
  store.setContext('all-mileage');
  //Approve All
  const { data: mileageDetails, status } = useMileageApprovalDetails(
    uss_id,
    store.params,
    'PENDING'
  );
  //Approve/Deny Mileage Entry
  const { mutateAsync: mutateAction } = useUpdateMileage(
    uss_id,
    'APPROVED',
    'all',
    mileageDetails?.items ? mileageDetails.items.length : 0
  );

  //for remove
  const modal = useModal();
  const openModal = ({
    title,
    message,
    type,
  }: {
    title: string;
    message: string;
    type: 'regular';
  }) => {
    modal.openModal({
      title: title,
      type: type,
      children: <span>{message}</span>,
      labels: {
        confirm: 'Approve',
        cancel: 'Cancel',
      },
      confirmVariant: 'primary',
      onCancel: () => modal.closeModal(),
      onConfirm: async () => await handleApproveAllConfirm(),
    });
  };
  const handleApproveAll = () => {
    let count = 0;
    if (mileageDetails?.items) count = mileageDetails.items.length;
    openModal({
      title: `Approve all the ${count} requests?`,
      message: `Are you sure you want to approve all the Mileage Entry requests for ${count} records?`,
      type: 'regular',
    });
  };

  useEffect(() => {
    //setting status=pending into store
    store.setStatus('PENDING');
    //sending mileage-records data to parent
    if (mileageDetails) {
      setRecords(`(${mileageDetails.totalCount})`);
    } else setRecords(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, mileageDetails]);

  const handleApproveAllConfirm = async () => {
    if (mileageDetails) {
      const employeeData: UpdateMileageField[] = mileageDetails.items.map(
        (record) => {
          return {
            status: 'APPROVED',
            mileageDetailsId: record.mileageDetailsId,
          };
        }
      );
      await mutateAction(
        {
          actionStatus: 'APPROVED',
          payload: {
            items: employeeData,
          },
        },
        {
          onSuccess: () => {
            modal.closeModal();
          },
        }
      );
    }
  };

  //For Schedule-Pay Viewer this page is read-only
  const isReadOnly = roles[0] === ROLES.SCHEDULE_PAY_VIEWER;

  return (
    <div className="grid gap-4">
      <div className="w-full sm:w-auto flex justify-end items-center mr-2 sm:mt-0 ">
        {status === 'success' &&
          mileageDetails &&
          mileageDetails.items.length > 0 && (
            <Button
              variant="text"
              className={`text-sm flex sm:mt-0 item-center justify-center mt-4 ${
                isReadOnly
                  ? 'pointer-events-none cursor-not-allowed text-gray-500'
                  : 'text-blue-800 cursor-pointer'
              } `}
              onClick={handleApproveAll}
              disabled={isReadOnly}
            >
              <BsCheck2 id="approve-all" className="text-lg mt-1" />
              <span className="text-base ml-2">Approve All</span>
            </Button>
          )}
      </div>
      <div>
        {status === 'pending' && (
          <CardsLoader variant="row-grid" count={9} hasAvatar />
        )}
        {status === 'success' && mileageDetails?.items.length === 0 ? (
          <NoResults
            title="No Records Found!"
            description="No employee records found."
            width="300"
            height="300"
          />
        ) : (
          <>
            {mileageDetails && (
              <MileageApprovalCard mileageDetails={mileageDetails} />
            )}
          </>
        )}
      </div>
      {mileageDetails && mileageDetails.items.length > 0 && (
        <div>
          <Pagination
            pageOptions={[9, 18, 27, 36, 45, 54]}
            totalCount={mileageDetails.totalCount}
            page={Number(store.params.page)}
            pageSize={Number(store.params.pageSize)}
            onPageChange={(s) => store.setPage((s.selected + 1).toString())}
            onPageSizeChange={(ps) => store.setPageSize(ps.toString())}
          />
        </div>
      )}
    </div>
  );
};

export default PendingCard;
