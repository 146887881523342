import Tabs from 'components/Tabs/Tabs';
import { useEmpPTODetailsByPtoId } from 'features/paid-time-off/api/queries';
import PTODetailsCard from 'features/paid-time-off/components/Cards/crew-pto/PTODetailsCard';
import { AnnualTotals } from 'features/paid-time-off/components/AnnualTotals/AnnualTotals';
import PTODetailsForm from '../Forms/PTODetailsForm';
import { PtoScreenType } from 'features/paid-time-off/types/pto-requests';
import { PTOHistory } from '../PTOHistory/PTOHistory';

const PtoRecord = ({
  ptoId,
  ptoType,
}: {
  ptoId: string;
  ptoType: PtoScreenType;
}) => {
  const { data: ptoDetails, isLoading: isPtoDetailsLoading } =
    useEmpPTODetailsByPtoId(ptoId);

  const tabLinks = [
    {
      label: 'Details',
      element: (
        <>
          {ptoDetails && (
            <PTODetailsForm
              ptoId={ptoId}
              ptoType={ptoType}
              ptoDetails={ptoDetails}
            />
          )}
        </>
      ),
    },
    {
      label: 'History',
      element: (
        <>
          {ptoDetails && (
            <PTOHistory
              ptoId={ptoDetails.pkWfmEmpPtoDetailsId}
              noRecordError="No pto history records found"
            />
          )}
        </>
      ),
    },
  ];

  ptoDetails?.status !== 'blackout' &&
    tabLinks.push({
      label: `Annual Totals`,
      element: (
        <>
          {ptoDetails && (
            <AnnualTotals
              year={
                ptoDetails.chargeYear ? ptoDetails.chargeYear.toString() : '-'
              }
              id={ptoDetails.ussId}
            />
          )}
        </>
      ),
    });

  return (
    <>
      <PTODetailsCard
        data={ptoDetails}
        isPtoDetailsLoading={isPtoDetailsLoading}
      />
      <Tabs tabLinks={tabLinks} />
    </>
  );
};

export default PtoRecord;
