import { Button, Modal } from '@uss/react-components';
import Alert from 'components/Alert/Alert';
import React, { createContext, useReducer } from 'react';

interface Props {
  children: React.ReactElement;
}
export interface alertType {
  type: 'info' | 'alert';
  message: React.ReactNode | string;
}

export interface ModalOptions {
  onConfirm?: () => void | Promise<void>;
  onCancel?: () => void;
  title: string;
  type: 'success' | 'danger' | 'regular';
  children: React.ReactElement;
  labels?: {
    confirm: string;
    cancel: string;
    secondayAction?: string;
  };
  confirmVariant?: 'primary' | 'danger';
  size?: 'sm' | 'md';
  open: boolean;
  alert?: alertType[] | undefined;
  hideAction?: boolean;
}

const initialParams: ModalOptions = {
  title: 'Title',
  type: 'success',
  children: <div>Content</div>,
  labels: {
    confirm: 'Confirm',
    cancel: 'Cancel',
  },
  size: 'md',
  confirmVariant: 'primary',
  open: false,
  hideAction: false,
  onCancel: () => {
    return;
  },
  onConfirm: () => {
    return;
  },
};

interface OpenAction {
  type: 'OPEN';
  payload: Omit<ModalOptions, 'open'>;
}

interface CloseAction {
  type: 'CLOSE';
}

const ModalContext = createContext<ModalOptions>(initialParams);
export const ModalUpdateContext = createContext<
  React.Dispatch<OpenAction | CloseAction>
>(() => ({}));

const ModalProvider = ({ children }: Props) => {
  const reducer = (state: ModalOptions, action: OpenAction | CloseAction) => {
    switch (action.type) {
      case 'OPEN': {
        return { ...action.payload, open: true };
      }
      case 'CLOSE': {
        state.open = false;
        return { ...state };
      }

      default: {
        return { ...state };
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, initialParams);

  return (
    <ModalContext.Provider value={state}>
      <ModalUpdateContext.Provider value={dispatch}>
        {children}
        <Modal
          variant={state.type}
          size={state.size}
          title={state.title}
          onClose={() => dispatch({ type: 'CLOSE' })}
          isOpen={state.open}
        >
          <Modal.Body>
            <div className="w-full">
              {state.alert?.map((alert, index) => {
                return (
                  <div className="mb-4" key={index}>
                    <Alert variant={alert.type}>{alert.message}</Alert>
                  </div>
                );
              })}

              {state.children}
            </div>
          </Modal.Body>
          {!state.hideAction && state.labels && (
            <Modal.Actions>
              <Button
                onClick={state.onCancel}
                variant="outline"
                className="w-full sm:w-auto"
                data-testid="modal-cancel"
              >
                {state.labels.cancel}
              </Button>
              <Button
                onClick={state.onConfirm}
                variant={state.confirmVariant}
                className="ml-0 sm:ml-4 w-full sm:w-auto mt-3 sm:mt-0"
                data-testid="modal-confirm"
              >
                {state.labels.confirm}
              </Button>
            </Modal.Actions>
          )}
        </Modal>
      </ModalUpdateContext.Provider>
    </ModalContext.Provider>
  );
};

export default ModalProvider;
