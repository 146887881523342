import Page from 'components/Layouts/Page';
import AddMileage from '../../features/mileage-approval/components/AddMileage';
import { MileageEntryCard } from '../../features/mileage-approval/components/MileageEntryCard';
import useUserRoles from 'hooks/useUserRoles';
import { useMileageDetails } from 'features/mileage-approval/api/queries';
import CardsLoader from 'components/Loaders/CardsLoader';
import NoResults from 'components/NoResults/NoResults';
import Pagination from 'components/Pagination/Pagination';
import { useMileageApprovalStore } from 'features/mileage-approval/state/mileage-store';

export const Mileage = () => {
  const store = useMileageApprovalStore();
  store.setContext('my-mileage');
  //Getting Roles
  const { uss_id } = useUserRoles();
  //Get Mileage Details
  const { data: mileageDetails, status } = useMileageDetails(uss_id, {
    ...store.params,
    page: store.MyMileagePage,
    pageSize: store.MyMileagePageSize,
  });
  return (
    <>
      <Page heading="My Mileage (NU)">
        <Page.Section>
          <AddMileage />
        </Page.Section>
        <Page.Section>
          <>
            {status === 'pending' && (
              <CardsLoader variant="row-grid" count={9} hasAvatar />
            )}
            {status === 'success' && mileageDetails?.items.length === 0 ? (
              <NoResults
                title="No Records Found!"
                description="No employee records found."
                width="300"
                height="300"
              />
            ) : (
              mileageDetails?.items.map((details, index) => {
                return (
                  <div
                    key={index}
                    className="mb-5"
                    data-testid="employee-mileage-card"
                  >
                    <MileageEntryCard mileageRecord={details} />
                  </div>
                );
              })
            )}
          </>
        </Page.Section>
        {mileageDetails && mileageDetails.items.length > 0 && (
          <Page.Section>
            <Pagination
              pageOptions={[9, 18, 27, 36, 45, 54]}
              totalCount={mileageDetails.totalCount}
              page={Number(store.MyMileagePage)}
              pageSize={Number(store.MyMileagePageSize)}
              onPageChange={(s) => store.setMyMileagePage(s.selected + 1)}
              onPageSizeChange={(ps) => store.setMyMileagePageSize(ps)}
            />
          </Page.Section>
        )}
      </Page>
    </>
  );
};
