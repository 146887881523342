import { parseISO } from 'date-fns';
import { CalendarEvent } from 'components/CalendarV2/types';
import { CrewPtoTotals, PtoStatus } from '../types';
import { getColors } from '../utility/ptoStatusColor';
import { PTO_STATUS } from '../constants';

const statusLength = Object.values(PTO_STATUS);

//create events object for calendar
export const useCrewPtoCalendarDates = (CrewPtoList: CrewPtoTotals[]) => {
  const eventList: CalendarEvent[] = [];

  const ptoData: CrewPtoTotals[] = Object.assign(
    CrewPtoList
  ) as CrewPtoTotals[];
  ptoData.forEach((item) => {
    statusLength.forEach((status) => {
      const _status: PtoStatus = status;
      if (item.totals[status] > 0) {
        eventList.push({
          date: new Date(parseISO(item.date)),
          text: `${item.totals[status]}`,
          color: getColors(status),
          status: _status,
        });
        return true;
      }
    });
  });
  return eventList;
};
