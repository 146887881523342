import { z } from "zod";

export const ApplyModelSchema = z.object({
  crewId: z.string().nonempty(),
  ussIds: z.string().nonempty(),
  model: z.string().nonempty(),
  crewRotation: z.string().nonempty(),
  noOfHours: z.optional(z.coerce.number().multipleOf(0.25)
    .gt(0.24)
  .lte(24)).or(z.literal(0)),
  startWeekNo: z.coerce.number({
    required_error : 'Start week is required'
  }),
  endWeekNo: z.coerce.number({
    required_error : 'End week is required'
  }),
  hoursType: z.union([z.literal('shiftAssignment'), z.literal('custom')]).default('shiftAssignment'),
  week: z.string().nonempty(),
})
.refine(
  (val) => {
      if (val.endWeekNo === val.startWeekNo || val.endWeekNo < val.startWeekNo) return false;
    return true;
    },
    {
      message: 'Start Week should never be equal or greater than the End Week.',
      path: ['startWeekNo'],
    }
)
 