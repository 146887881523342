import { useQuery } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import {
  EmployeeTimecardList,
  EmployeeTimecardOptions,
} from 'features/timecards/types';
import { Org } from 'features/org';

/**
 * Endpoint to get all timecards for a given week and person.  It will return all available crews and the latest adjustment.
 *
 * @param ussId USS ID of the person you wish to get timecards for
 * @param params API options for the employee timecard endpoint
 * @returns Promise
 */
const get_timecard = (
  ussId: string,
  params: EmployeeTimecardOptions
): Promise<EmployeeTimecardList> => {
  return axios.get(`/v1/people/${ussId}/timecard-details`, {
    params,
  });
};

//Employee Timecard Hooks
/**
 * Fetch timecards for an employee
 *
 * @param ussId USS ID of the employee
 * @param params API Options
 * @link https://cappsq.psc.uss.com/SBWM-DOCUMENT/swagger-ui/index.html?urls.primaryName=sbwm-schedulepay#/schedule-pay-controller/getEmployeeTimecard
 * @returns React Query useQuery Results
 *
 */
export const useEmployeeTimecard = (
  ussId: string,
  params: EmployeeTimecardOptions,
  employeeCrews: Org[] = []
) => {
  const isValidCrew = !!employeeCrews.find((crew) => crew.id === params.crewId);
  return useQuery({
    queryKey: ['employee-timecard', { ussId, ...params }],
    queryFn: () => get_timecard(ussId, params),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!params.crewId && !!params.week && isValidCrew,
  });
};

//hook to call timecard-details api without crewId
export const useEmployeeTimecardWithoutCrew = (
  ussId: string,
  params: EmployeeTimecardOptions
) => {
  return useQuery({
    queryKey: ['employee-timecard-no-crew', { ussId, ...params }],
    queryFn: () => get_timecard(ussId, params),
    refetchOnMount: 'always',
    staleTime: 0,
    refetchOnWindowFocus: true,
    enabled: !!params.week,
  });
};
