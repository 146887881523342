import { ModelList, ModelOwner } from '../types';

export const useGetModelOwners = (list: ModelList[]) => {
  const ownerNameArr: ModelOwner[] = [];
  list.forEach((item, index) => {
    if (item.owner) {
       if (index === 0) {
      ownerNameArr.push({
        ussId: item.owner.ussId,
        displayName: item.owner.displayName,
      });
    } else {
      const itemIndex = ownerNameArr.findIndex(
        (e) => e.ussId === item.owner?.ussId
      );
      //logic to ignore adding repeted records to arr
      if (itemIndex === -1) {
        ownerNameArr.push({
          ussId: item.owner.ussId,
          displayName: item.owner.displayName,
        });
      }
    }
    }
  });
  return ownerNameArr;
};
