import React from 'react';
import { format, parseISO } from 'date-fns';
import Card from 'components/Card/Card';
import LabeledText from 'components/Typography/LabeledText';
import { PayPeriodData } from 'features/paypulloff/types';

interface Props {
  payPeriod: PayPeriodData | undefined;
  isTotalProcessingCard: boolean;
}

const ProcessingCard = ({ payPeriod, isTotalProcessingCard }: Props) => {
  return (
    <Card>
      <Card.Body>
        <div className="sm:flex items-stretch w-full h-full">
          <div className="flex items-center w-full lg:w-1/3 2xl:w-1/6 relative lg:pr-6 lg:after:w-1 lg:after:absolute lg:after:right-0 lg:after:h-full lg:after:bg-gray-100">
            <div>
              {isTotalProcessingCard ? (
                <div className="text-gray-900 text-base text-left font-semibold line-clamp-1 mb-4">
                  Total
                </div>
              ) : (
                <>
                  <div className="text-gray-900 text-base text-left font-semibold line-clamp-1 mb-1">
                    {payPeriod?.locationName}
                  </div>
                  <div className="text-gray-900 text-base text-left font-semibold line-clamp-1 mb-4">
                    {payPeriod?.locationDescription}
                  </div>
                </>
              )}
              <div className="text-gray-700 text-sm text-left font-semibold">
                Pay Period
              </div>
              <p
                aria-label="Pay Period"
                data-testid="pay-period-range"
                className="text-gray-900 text-base text-left font-semibold line-clamp-1"
              >
                {payPeriod &&
                  format(
                    parseISO(payPeriod.payPeriodStartDate),
                    'MMM dd, yyyy'
                  )}
                {payPeriod ? ' - ' : 'Loading..'}
                {payPeriod &&
                  format(parseISO(payPeriod.payPeriodEndDate), 'MMM dd, yyyy')}
              </p>
            </div>
          </div>
          <div className="bg-gray-100 w-full h-1 mx-0 my-3 sm:hidden"></div>
          <div className="w-full lg:w-4/5 lg:pl-6">
            <div
              id="current-pay-period"
              className="flex flex-col justify-center w-full"
            >
              <p className="text-gray-920 text-sm text-left font-semibold">
                Current Pay Period
              </p>
              <div className="flex flex-wrap md:flex-nowrap">
                <div
                  data-testid="approved-employee-count"
                  className="w-1/2 lg:w-1/4 mb-4 lg:mb-0"
                >
                  <LabeledText label="Approved Employees" lineClamp={1}>
                    {payPeriod?.approvedEmployeeCount}
                  </LabeledText>
                </div>
                <div
                  data-testid="approved-timecards-count"
                  className="w-1/2 lg:w-1/4 mb-4 lg:mb-0"
                >
                  <LabeledText label="Approved Timecards" lineClamp={1}>
                    {payPeriod?.approvedTimecardsCount}
                  </LabeledText>
                </div>

                <div
                  data-testid="total-approved-hours"
                  className="w-1/2 lg:w-1/4 "
                >
                  <LabeledText label="Total Approved Hours" lineClamp={1}>
                    {payPeriod?.totalApprovedHours}
                  </LabeledText>
                </div>
                <div
                  data-testid="approved-percentage"
                  className="w-1/2 lg:w-1/4 "
                >
                  <LabeledText label="% Approved" lineClamp={1}>
                    {payPeriod?.approvedPercentage}
                  </LabeledText>
                </div>
              </div>
            </div>
            <div className="bg-gray-300 w-full h-[1px] mx-0 my-4 "></div>
            <div
              id="prior-pay-period"
              className="flex flex-col justify-center w-full"
            >
              <p className="text-gray-920 text-sm text-left font-semibold">
                Prior Pay Period Adjustment(s)
              </p>
              <div className="flex">
                <div
                  data-testid="approved-adj-employee-count"
                  className="w-1/2 lg:w-1/4 "
                >
                  <LabeledText label="Approved Employees" lineClamp={1}>
                    {payPeriod?.approvedAdjEmployeeCount}
                  </LabeledText>
                </div>
                <div
                  data-testid="approved-adjustments-count"
                  className="w-1/2 lg:w-1/4 "
                >
                  <LabeledText label="Approved Timecards" lineClamp={1}>
                    {payPeriod?.approvedAdjustmentsCount}
                  </LabeledText>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
export default ProcessingCard;
